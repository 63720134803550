import { FC, PropsWithChildren } from 'react'

import styled from 'styled-components'

import { colors, device } from 'src/Styled/variables'

import { Irrelevant } from './Irrelevant'

const ContentBlock = styled.div<{ last?: boolean }>`
  margin-bottom: 10px;
  padding: 0px 16px;
  background-color: ${colors.white};
  border-radius: 4px;
  @media ${device.mobileMax} {
    padding: 0px 16px 24px;
    margin-bottom: 112px;
  }
`

type ShowIrrelevantProps = PropsWithChildren & {
  meetCondition: boolean
}

export const ShowIrrelevant: FC<ShowIrrelevantProps> = ({
  meetCondition,
  children,
}) => {
  if (!meetCondition) {
    return (
      <ContentBlock>
        <Irrelevant />
      </ContentBlock>
    )
  }
  return children
}
