import { FC } from 'react'

import styled from 'styled-components'
import { Skeleton } from 'antd'
import { SkeletonNodeProps } from 'antd/es/skeleton/Node'

import { MAX_MOBILE_WIDTH } from 'src/Styled/variables'

const StyledSkeleton = styled(({ className, ...rest }: SkeletonNodeProps) => (
  <Skeleton.Input {...rest} rootClassName={className} active />
))`
  &.ant-skeleton-block {
    border-radius: 16px;
  }
  &.ant-skeleton {
    width: 100% !important;
    flex-grow: 1 !important;
    @media (max-width: ${MAX_MOBILE_WIDTH}) {
      width: 100% !important;
    }
  }
  & > .ant-skeleton-input {
    width: 100% !important;
    height: 64px !important;
    border-radius: 16px !important;
  }
`

const LOADING_ITEMS_NUMBER = 7

export const Loading: FC = () => {
  const arrayFromCount = Array.from({ length: LOADING_ITEMS_NUMBER })
    .fill(0)
    .map((_, index) => index)

  return (
    <>
      {arrayFromCount.map((item) => (
        <StyledSkeleton key={item} />
      ))}
    </>
  )
}
