import i18n from 'src/Localization/i18n'
import { CampaignPlatformTypeEnum } from 'src/Types'

export const MAP_PLATFORM_TO_TITLE = {
  [CampaignPlatformTypeEnum.SPOTIFY]: i18n.t(
    'createCampaignPage.newSpotifyCampaign',
  ),
  [CampaignPlatformTypeEnum.TIKTOK]: i18n.t(
    'createCampaignPage.addingTikTokCampaign',
  ),
  [CampaignPlatformTypeEnum.SPOTIFY_TIKTOK]: i18n.t(
    'createCampaignPage.newSpotifyCampaign',
  ),
}

export const MAP_PLATFORM_TO_PLACEHOLDER = {
  [CampaignPlatformTypeEnum.SPOTIFY]: i18n.t(
    'createCampaignPage.typeYourTrackNameOrInsertALinkSpotify',
  ),
  [CampaignPlatformTypeEnum.TIKTOK]: i18n.t(
    'createCampaignPage.typeYourTrackNameOrInsertALinkTiktok',
  ),
  [CampaignPlatformTypeEnum.SPOTIFY_TIKTOK]: i18n.t(
    'createCampaignPage.typeYourTrackNameOrInsertALinkSpotify',
  ),
}
