import { createDraftSafeSelector } from '@reduxjs/toolkit'

import { RootState } from 'src/Redux'
import { SliceStatus } from 'src/Redux/types'

import { CuratorListState } from '.'

const getState = (state: RootState): CuratorListState =>
  state.adminPanel.curators
export const getCuratorsSelector = createDraftSafeSelector(
  getState,
  ({ curators }) => curators,
)

export const getTotalCurators = createDraftSafeSelector(
  getState,
  ({ totalCurators }) => totalCurators,
)

export const getIsCuratorListLoading = createDraftSafeSelector(
  getState,
  ({ status }) => status === SliceStatus.Loading,
)
