import { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'

import Button from 'src/Components/Buttons/Button'

import { CenteredBlock, StyledHintText, Wrapper, WrapperInner } from '../styles'

interface HintProps {
  handleSetVisibilityHint?: () => void
  text: string
  subtext?: string
}

export const HintText: FunctionComponent<HintProps> = ({
  handleSetVisibilityHint,
  text,
  subtext,
}) => {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <WrapperInner>
        <StyledHintText>{text}</StyledHintText>
        {subtext && <br />}
        {subtext && <StyledHintText>{subtext}</StyledHintText>}
      </WrapperInner>
      <CenteredBlock>
        <Button type='white' onClick={handleSetVisibilityHint}>
          {t('scheduleCampaignPage.gotIt')}
        </Button>
      </CenteredBlock>
    </Wrapper>
  )
}
