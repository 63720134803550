import { FC } from 'react'

import { useTranslation } from 'react-i18next'

import { useAppSelector } from 'src/Hooks/redux'
import {
  BudgetSliderWrapper,
  BudgetSliderSideContainer,
  BudgetInput,
} from 'src/Containers/ScheduleSpotifyWrapper/components/SpotifySettings/SettingsBudget/styles'
import {
  BudgetSlider,
  SliderHandleStyles,
  SliderTrackStyles,
} from 'src/Containers/ui/BudgetSlider'
import { APP } from 'src/Configs/App'
import { ScheduleSubscription } from 'src/Components/ScheduleSubscription/ScheduleSubscription'
import { getHasActiveOrCanceledSubscriptionSelector } from 'src/Redux/subscription-process/subscription/selectors'
import { useGetTikTokCampaignBudgetRangeQuery } from 'src/Redux/artistPaymentSettingsApi'

import { TiktokEstimation } from '../TiktokEstimation/TiktokEstimation'
import { useTiktokPrice } from '../helpers'

import {
  TiktokBudgetContainer,
  SliderContainer,
  BudgetContainer,
  Title,
} from './styles'

export const TiktokBudget: FC = () => {
  const { data: budgetRange } = useGetTikTokCampaignBudgetRangeQuery()
  const MIN = Number(budgetRange?.campaignMinBudget)
  const MAX = Number(budgetRange?.campaignMaxBudget)

  const { t } = useTranslation()
  const {
    rate,
    currencySymbol,
    valueInput,
    valueSlider,
    setValueInput,
    setValueSlider,
    savePrice,
  } = useTiktokPrice()

  const marks = {
    [MIN]: `${currencySymbol}${rate && Math.round(MIN * rate).toLocaleString('en-US')}`,
    [MAX]: `${currencySymbol}${rate && Math.round(MAX * rate).toLocaleString('en-US')}`,
  }
  const handleSliderChange = (value: number): void => {
    rate && setValueInput(Math.round(value * rate))
    setValueSlider(value)
    savePrice(value)
  }
  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const substr =
      currencySymbol &&
      event.target.value.substring(
        currencySymbol?.length,
        event.target.value.length,
      )
    const numberValue = Number(substr)
    if (isNaN(numberValue)) {
      return
    }
    setValueInput(numberValue)
    rate && savePrice(Math.round(numberValue / rate))
  }

  const hasActivePlan = useAppSelector(
    getHasActiveOrCanceledSubscriptionSelector,
  )
  return (
    <TiktokBudgetContainer>
      <Title>{t('scheduleCampaignPage.SetUp')}</Title>
      <BudgetSliderWrapper>
        <SliderContainer hasActivePlan={hasActivePlan}>
          <BudgetSlider
            value={valueSlider}
            vertical
            marks={marks}
            tooltip={{ formatter: null }}
            step={APP.REACH_STEP_BUDGET_TIKTOK_CAMPAIGN}
            max={MAX}
            min={MIN}
            styles={{
              track: SliderTrackStyles,
              handle: SliderHandleStyles,
            }}
            onChange={handleSliderChange}
          />
        </SliderContainer>
        <BudgetSliderSideContainer>
          <BudgetContainer>
            <BudgetInput
              role='budget-input'
              value={`${currencySymbol}${valueInput}`}
              onChange={handleInputChange}
            />
            <ScheduleSubscription symbol={currencySymbol} price={valueInput} />
          </BudgetContainer>
          <TiktokEstimation />
        </BudgetSliderSideContainer>
      </BudgetSliderWrapper>
    </TiktokBudgetContainer>
  )
}
