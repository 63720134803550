import { useGrowthBook } from '@growthbook/growthbook-react'

import { getIsNewUserForHideLanguageMatchingExp } from 'src/Redux/auth-process/userSlice/selectors'

import { useAppSelector } from '../redux'

export enum HideLanguageMatchingExperimentVariants {
  Variant0 = 0,
  Variant1 = 1,
}

interface HideLanguageMatchingExperimentInterface {
  isHideLanguageExperiment: boolean
}

export const HIDE_LANGUAGE_EXPERIMENT_VALUE = 'hide_language_matching'

export const useHideLanguageMatchingExperiment =
  (): HideLanguageMatchingExperimentInterface => {
    const isNewUser = useAppSelector(getIsNewUserForHideLanguageMatchingExp)

    const growthBook = useGrowthBook()

    let hideLanguageMatchingExperimentValue =
      HideLanguageMatchingExperimentVariants.Variant0

    if (isNewUser) {
      hideLanguageMatchingExperimentValue = growthBook?.getFeatureValue(
        HIDE_LANGUAGE_EXPERIMENT_VALUE,
        HideLanguageMatchingExperimentVariants.Variant0,
      ) as HideLanguageMatchingExperimentVariants
    }

    return {
      isHideLanguageExperiment: Boolean(hideLanguageMatchingExperimentValue),
    }
  }
