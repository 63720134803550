import styled from 'styled-components'
import ReactLoading from 'react-loading'

import { colors, device, variables } from 'src/Styled/variables'

export const Wrapper = styled.div`
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  @media ${device.largeScreen} {
    flex: 0 0 50%;
    max-width: 50%;
  }
`
export const RowWrapper = styled.div`
  padding: 0;
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  display: flex;
  flex-wrap: wrap;
`

export const FormGroup = styled.div`
  margin-bottom: 24px;
  width: 100%;
  position: relative;
`
export const FormGroupLabel = styled.span`
  margin-bottom: 8px;
  display: inline-block;
  color: ${colors.mainText};
`

export const RedText = styled.p`
  color: ${colors.messegesRed};
`
export const FormGroupField = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
  input {
    border: 1px solid ${colors.greyStroke};
    border-radius: 8px;
    height: 40px;
    background-color: ${colors.white};
    padding: 13px 11px;
    font-size: ${variables.fontSize14};
    line-height: 21px;
  }
`
export const FormGroupSelect = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;

  .ant-select {
    .ant-select-focused {
      .ant-select-selector {
        border-color: ${colors.greyStroke};
        color: ${colors.mainText};
        box-shadow: none;
      }
    }
    .ant-select-focused {
      .ant-select-selector {
        border-color: ${colors.greyStroke};
        color: ${colors.mainText};
      }
      .ant-select-open {
        border-color: ${colors.greyStroke};
        color: ${colors.mainText};
        box-shadow: none !important;
      }
    }
    .ant-select-selector {
      font-size: 14px;
      color: ${colors.mainText};
      border-radius: 8px;
      box-shadow: none !important;
      border-color: ${colors.greyStroke} !important;
      input {
        height: 40px;
      }
      &:hover,
      &:focus,
      &:active {
        border-color: ${colors.greyStroke} !important;
        box-shadow: none !important;
      }
    }
  }
`

export const Form = styled.form`
  max-width: 2000px;
  margin-top: 0;
  display: flex;
  flex-wrap: wrap;
`
export const ButtonWrapper = styled.div`
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
  div {
    width: fit-content;
    .ant-btn.ant-btn-default {
      padding: 0 40px;
    }
  }
`

export const Loading = styled(ReactLoading)`
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: 24px;
    width: 24px;
    margin: 0;
  }
`
