import styled from 'styled-components'
import ReactLoading from 'react-loading'

import { colors, device } from 'src/Styled/variables'

export const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 24px;

  @media ${device.mobileMax} {
    position: fixed;
    bottom: 0;
    left: 0;
    background: ${colors.white};
    box-shadow: 0 6px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: row-reverse;
    padding: 12px 16px;
    z-index: 100;
    justify-content: space-between;
    align-items: center;
  }
`

export const Loader = styled(ReactLoading)`
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: 24px;
    width: 24px;
    margin: 0;
  }
`

export const MinRequestText = styled.div`
  font-size: 14px;
  @media ${device.mobileMax} {
    min-width: 78px;
    margin-right: 34px;
  }
`

export const RequestButtonWrapper = styled.div`
  margin-bottom: 5px;
  width: 100%;
  min-width: 120px;
  max-width: 176px;

  @media ${device.mobileMax} {
    margin-bottom: 0;
  }
`

export const MobileRequestPayout = styled.div`
  position: fixed;
  z-index: 5;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${colors.white};
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  gap: 35px;
  padding: 12px 16px;
`
