import { FC, PropsWithChildren, useEffect, useRef, useState } from 'react'

import styled from 'styled-components'

import { MAX_MOBILE_WIDTH, MAX_TABLET_WIDTH } from 'src/Styled/variables'

import { CreateCampaignHeader } from '../CreateCampaignHeader'

const Container = styled.div`
  display: grid;
  grid-template-areas:
    'header header'
    'content content';
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  height: 100vh;
`

const Content = styled.div`
  grid-area: content;
  height: 100%;
  overflow: scroll;
  padding: 24px 40px;
  margin: 0 auto;
  max-width: 1200px;
  width: 100%;

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;

  @media (max-width: ${MAX_TABLET_WIDTH}) {
    padding: 24px 24px;
  }

  @media (max-width: ${MAX_MOBILE_WIDTH}) {
    padding: 24px 16px;
  }
`
const logoLinkUrl = 'https://soundcamps.com/'

export type PageLayoutProps = PropsWithChildren

export const NoAuthPageLayout: FC<PageLayoutProps> = ({ children }) => {
  const contentRef = useRef<HTMLDivElement>(null)
  const [isContentScrolled, setIsContentScrolled] = useState(false)

  useEffect(() => {
    const currentContent = contentRef.current

    const handleScroll = (): void => {
      currentContent && setIsContentScrolled(currentContent.scrollTop > 0)
    }
    contentRef.current?.addEventListener('scroll', handleScroll)
    return () => {
      currentContent &&
        currentContent.removeEventListener('scroll', handleScroll)
    }
  }, [children])

  return (
    <Container>
      <CreateCampaignHeader
        withShadow={isContentScrolled}
        logoLinkUrl={logoLinkUrl}
      />

      <Content ref={contentRef}>{children}</Content>
    </Container>
  )
}
