import React from 'react'

import styled from 'styled-components'

import Arrow from 'src/Assets/Svg/common/down-arrow.svg?react'
import {
  SquareButton,
  SquareButtonProps,
} from 'src/Components/Buttons/SquareButton'

const StyledButton = styled(SquareButton)`
  padding: 0 2px;
  height: 16px;
  border-radius: 4px;
  border: none;

  &:hover {
    box-shadow: none;
    background-color: ${({ theme }) => theme.button.secondaryBg};
  }

  &:active {
    background-color: ${({ theme }) => theme.button.secondaryBg};

    svg {
      path {
        fill: ${({ theme }) => theme.button.fifthText};
      }
    }
  }
`

export const RotateIconWrapper = styled.div<{ $isDown: boolean }>`
  transform: rotate(${({ $isDown }) => ($isDown ? '0deg' : '180deg')});
  scale: -1;
  transition: transform 0.3s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export type ArrowButtonProps = Pick<
  SquareButtonProps,
  'onClick' | 'disabled'
> & {
  isDown?: boolean
}

export const ArrowButton: React.FC<ArrowButtonProps> = ({
  onClick,
  disabled,
  isDown,
}) => (
  <StyledButton disabled={disabled} onClick={onClick}>
    <RotateIconWrapper $isDown={Boolean(isDown)}>
      <Arrow />
    </RotateIconWrapper>
  </StyledButton>
)
