import { createSlice } from '@reduxjs/toolkit'

import { setApiError } from 'src/Hooks/redux'
import { ArtistSpotify } from 'src/Types'

import { ApiError, NameSpace } from '../types'

import {
  ArtistConnection,
  getArtistGenres,
  getArtistSpotifyConnections,
  getSpotifyArtists,
  removeTikTokAlert,
  setSpotifyConnectionForArtist,
} from './api-actions'

export interface ArtistsState {
  artists: ArtistSpotify[]
  loading: boolean
  error: ApiError | null
  connections: Array<ArtistConnection>
  sfa_email: string | null
}

const initialState: ArtistsState = {
  artists: [],
  loading: false,
  error: null,
  sfa_email: null,
  connections: [],
}

export const artistsProcess = createSlice({
  initialState,
  name: NameSpace.Artists,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSpotifyArtists.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getSpotifyArtists.rejected, (state, action) => {
      state.loading = false
      state.error = setApiError(action)
    })
    builder.addCase(getSpotifyArtists.fulfilled, (state, action) => {
      state.artists = action.payload
      state.error = null
      state.loading = false
    })

    builder.addCase(getArtistGenres.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getArtistGenres.rejected, (state, action) => {
      state.loading = false
      state.error = setApiError(action)
    })
    builder.addCase(getArtistGenres.fulfilled, (state) => {
      state.error = null
      state.loading = false
    })

    builder.addCase(removeTikTokAlert.pending, (state) => {
      state.loading = true
    })
    builder.addCase(removeTikTokAlert.rejected, (state, action) => {
      state.loading = false
      state.error = setApiError(action)
    })
    builder.addCase(removeTikTokAlert.fulfilled, (state) => {
      state.error = null
      state.loading = false
    })

    builder.addCase(getArtistSpotifyConnections.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getArtistSpotifyConnections.rejected, (state, action) => {
      state.loading = false
      state.error = setApiError(action)
    })
    builder.addCase(getArtistSpotifyConnections.fulfilled, (state, action) => {
      state.connections = action.payload.connections
      state.sfa_email = action.payload.sfa_email
      state.error = null
      state.loading = false
    })

    builder.addCase(setSpotifyConnectionForArtist.pending, (state) => {
      state.loading = true
    })
    builder.addCase(setSpotifyConnectionForArtist.rejected, (state, action) => {
      state.loading = false
      state.error = setApiError(action)
    })
    builder.addCase(
      setSpotifyConnectionForArtist.fulfilled,
      (state, action) => {
        state.connections = [...state.connections, action.payload.connection]
        state.error = null
        state.loading = false
      },
    )
  },
})
