import { FC } from 'react'

import IconOrangeAlert from 'src/Assets/Svg/orangeAlert.svg?react'

import { PropsType } from './propsType'
import {
  MatchingInfoContainerDesktop,
  MatchingInfoContainerMobile,
  MatchingInfoIcon,
  MatchingInfoText,
  MatchingInfoWrapper,
} from './style'

export const AlertErrorMessageWithIcon: FC<PropsType> = ({
  type,
  isShow,
  errorText,
}) => {
  if (type === 'mobile' && isShow) {
    return (
      <MatchingInfoContainerMobile>
        <MatchingInfoIcon>
          <IconOrangeAlert width={16} height={13} />
        </MatchingInfoIcon>
        <div>{errorText}</div>
      </MatchingInfoContainerMobile>
    )
  }
  if (type === 'desktop' && isShow) {
    return (
      <MatchingInfoWrapper>
        <MatchingInfoContainerDesktop>
          <MatchingInfoIcon>
            <IconOrangeAlert width={16} height={13} />
          </MatchingInfoIcon>
          <MatchingInfoText>{errorText}</MatchingInfoText>
        </MatchingInfoContainerDesktop>
      </MatchingInfoWrapper>
    )
  }
  return null
}
