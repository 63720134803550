import { createSlice } from '@reduxjs/toolkit'

import { ApiError, NameSpace, SliceStatus } from 'src/Redux/types'
import { setApiError } from 'src/Hooks/redux'

import { getUserCountry } from './api-actions'

export interface CountryState {
  country: string
  error: ApiError | null
  status: SliceStatus
}

const initialState: CountryState = {
  country: '',
  error: null,
  status: SliceStatus.Idle,
}

export const countrySlice = createSlice({
  initialState,
  name: NameSpace.COUNTRY,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getUserCountry.pending, (state) => {
        state.status = SliceStatus.Loading
      })
      .addCase(getUserCountry.fulfilled, (state, action) => {
        state.status = SliceStatus.Succeeded
        state.country = action.payload
        state.error = null
      })
      .addCase(getUserCountry.rejected, (state, action) => {
        state.status = SliceStatus.Failed
        state.error = setApiError(action)
      })
  },
})
