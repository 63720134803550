import styled from 'styled-components'

import { colors, variables, device } from 'src/Styled/variables'

export const TrackInfoBlockContainer = styled.div`
  width: 100%;
  border-radius: 4px;
  color: ${colors.mainText};
  display: grid;
  gap: 16px;
  @media ${device.mobileMin} {
    padding: 16px;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
  }
`

export const TrackInfoBlockContainerInner = styled.div`
  color: ${colors.mainText};
  display: grid;
  grid-template-columns: 70px 1fr;
  gap: 8px;
`

export const CoverContainer = styled.div`
  width: 70px;
  height: 70px;
  border-radius: 8px;
  overflow: hidden;
  flex-shrink: 0;
`
export const InfoWrapper = styled.div`
  display: grid;
`
export const ParamsWrapper = styled.div`
  display: grid;
  height: fit-content;
  width: 100%;
`
export const ParamsWrapperText = styled.div`
  margin-bottom: 16px;
  display: grid;
  grid-template-columns: 1fr 16px;
  align-items: center;
  & svg {
    width: 16px;
    margin-top: -4px;
  }
`

export const NamesWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  white-space: nowrap;

  @media ${device.mobileMax} {
    width: 100%;
  }
`
export const LinkButton = styled.a`
  width: 219px;
  display: grid;
  align-items: center;
  justify-content: center;
  background-color: ${colors.badgeRed};
  color: ${colors.white};
  padding: 9.5px 12px;
  border-radius: 27px;
  margin: 0 auto;

  &:hover,
  &:active,
  &:visited {
    color: ${colors.white};
  }
  font-size: 14px;
`
export const TrackName = styled.span`
  font-weight: ${variables.fontWeight600};
  font-size: ${variables.fontSize14};
  line-height: 150%;
  white-space: nowrap;
  @media ${device.mobileMax} {
    overflow: hidden;
  }
`
export const TrackAuthorName = styled.span`
  font-weight: ${variables.fontWeight400};
  font-size: ${variables.fontSize12};
  line-height: 150%;
`
export const IconWrapper = styled.div`
  display: grid;
  align-self: start;
  margin-top: 4px;
`

export const Gradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 80%, white 90%);
  width: 100%;
  height: 100%;
`
