import { FC, useMemo } from 'react'

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { variables } from 'src/Styled/variables'
import Alert from 'src/Components/Alert'
import dayjs from 'src/Helpers/dayjs'
import { getValueOrZero } from 'src/Constants/functions'
import { useCampaignVideo } from 'src/Redux/campaignVideoApi'

import { AlertWrapper } from './styles'

export const AlertTitle = styled.div`
  font-weight: ${variables.fontWeight600};
`

export const WontChargedAlerts: FC = () => {
  const { t } = useTranslation()

  const { video, isVideoDeclinedOrRejected, isVideoDeleted } =
    useCampaignVideo()

  const isViewsMilestoneDayPassed = dayjs().isAfter(video?.minViewsDeadline)
  const isViewsAchieved =
    getValueOrZero(video?.viewsCount) > getValueOrZero(video?.minViews)

  const isMilestoneDayNotSuccessfullyPassed =
    isViewsMilestoneDayPassed && !isViewsAchieved

  const wontChargedText = t('campaignResultsPage.wontCharged')

  const alertMessage = useMemo(() => {
    if (isVideoDeclinedOrRejected) {
      return t('campaignResultsPage.moderatorReviewed')
    }

    if (isMilestoneDayNotSuccessfullyPassed) {
      return t('campaignResultsPage.videoDidNotGetViewsText')
    }

    if (isVideoDeleted) {
      return t('campaignResultsPage.videoIsNotAvailable')
    }

    return null
  }, [
    isMilestoneDayNotSuccessfullyPassed,
    isVideoDeclinedOrRejected,
    isVideoDeleted,
    t,
  ])

  if (!alertMessage) {
    return null
  }

  return (
    <AlertWrapper>
      <Alert color='danger' icon unclosable>
        <AlertTitle>{wontChargedText}</AlertTitle>
        <div>{alertMessage}</div>
      </Alert>
    </AlertWrapper>
  )
}
