import { FC } from 'react'

import { useTranslation } from 'react-i18next'

import { PrimaryDottedButton } from '../Buttons/PrimaryDottedButton'

export const TimeSaveButton: FC = (props) => {
  const { t } = useTranslation()
  return (
    <PrimaryDottedButton {...props}>
      {t('targetGenres.save')}
    </PrimaryDottedButton>
  )
}
