import styled from 'styled-components'

import { colors, variables } from 'src/Styled/variables'

export const AlertTitle = styled.div`
  font-weight: ${variables.fontWeight600};
`
export const AlertText = styled.div`
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
`
export const AlertButton = styled.button`
  background-color: transparent;
  cursor: pointer;
  color: ${colors.messegesGreen1};
  font-weight: ${variables.fontWeight600};
  padding-left: 0;
  cursor: pointer;
  &:hover {
    color: ${colors.messegesGreen1};
  }
`
