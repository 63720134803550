import { ReactNode } from 'react'

import { capitalizeFirstLetter } from 'src/Helpers/layoutHelpers'

export const renderLanguageOrGenreName = (
  name: string,
  search: string,
): ReactNode => {
  const capitalizeName = capitalizeFirstLetter(name)
  if (!search) {
    return capitalizeName
  }
  const searchValue = search.trim().toLowerCase()
  const index = name.toLowerCase().indexOf(searchValue)
  const inputLength = searchValue.length
  const firstPart = capitalizeName?.slice(0, index)
  const secondPart = capitalizeName?.slice(index, index + inputLength)
  const thirdPart = capitalizeName?.slice(index + inputLength)
  return (
    <span>
      {firstPart}
      <strong>{secondPart}</strong>
      {thirdPart}
    </span>
  )
}
