import { createTransform } from 'redux-persist'

import { PaymentState } from './index'

export const paymentTransform = createTransform(
  (inboundState: PaymentState) => ({
    paymentMethod: inboundState.paymentMethod,
  }),
  (outboundState: { paymentMethod: string }) => ({
    paymentMethod: outboundState.paymentMethod,
    loading: false,
    error: null,
    alertError: null,
  }),
  { whitelist: ['payment'] },
)
