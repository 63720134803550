import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import Button from 'src/Components/Buttons/Button'
import { ScheduleSteps } from 'src/Components/ScheduleTabs'
import { useAppSelector } from 'src/Hooks/redux'
import { getScheduledTikTokCampaign } from 'src/Redux/schedule-process/tiktokCampaign/selectors'
import {
  getIsTiktokAudienceLoading,
  getTiktokPrice,
} from 'src/Redux/schedule-process/audienceTiktokSlice/selectors'
import { useCheckoutEvents } from 'src/Hooks/useCheckoutEvents'
import { CampaignPlatformTypeEnum } from 'src/Types'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'

const WrapperContinueBtnTiktok = styled.div`
  display: flex;
  padding: 24px 0px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  & > div {
    width: fit-content;
    .ant-btn.ant-btn-default {
      padding: 0px 40px;
    }
  }
`

export const SettingsTiktokButton: FC = () => {
  const { t } = useTranslation()

  const { handleCheckoutStartEvent } = useCheckoutEvents()

  const { setSearch } = useCustomSearchParams()
  const scheduledCampaign = useAppSelector(getScheduledTikTokCampaign)
  const price = useAppSelector(getTiktokPrice)
  const isUpdateCampaignLoading = useAppSelector(getIsTiktokAudienceLoading)

  const handleClick = (): void => {
    const query = {
      step: ScheduleSteps.CHECKOUT,
    }
    setSearch(query)

    handleCheckoutStartEvent({
      platformName: CampaignPlatformTypeEnum.TIKTOK,
      id: Number(scheduledCampaign.id),
      campaignType: scheduledCampaign.type,
      startDate: scheduledCampaign?.startDate?.toString() || '',
      resultPrice: price,
      isFeed: false,
      genres: scheduledCampaign.genres.map((genre) => genre.label || ''),
      languages: [],
    })
  }
  const isDisabled =
    !scheduledCampaign.genres.length || !price || isUpdateCampaignLoading

  return (
    <WrapperContinueBtnTiktok>
      <Button disabled={isDisabled} onClick={handleClick} type='green'>
        {t('scheduleCampaignPage.continue')}
      </Button>
    </WrapperContinueBtnTiktok>
  )
}
