import { FC } from 'react'

import { useTranslation } from 'react-i18next'

import Button from 'src/Components/Buttons/Button'
import { playlistRequirementsLink } from 'src/Constants/links'
import { Desktop, Mobile } from 'src/Components/Responsive'
import track from 'src/Assets/Png/track.png'
import { isEmpty } from 'src/Helpers/layoutHelpers'

import { PlayListCardProps } from './props'
import { PlaylistGenres } from './PlaylistGenres'
import { PlaylistStatus } from './PlaylistStatus'
import { PlaylistStats } from './PlaylistStats'

import {
  PlaylistCover,
  MainInfo,
  Text,
  TableRow,
  TableData,
  TableDataMobile,
  MobileCard,
  MobileCardRightContent,
  ButtonWrapper,
  ContactSupportBtn,
} from './styles'

const PlayListCard: FC<PlayListCardProps> = ({
  playListName,
  followers,
  onGenresEdit,
  playlistID,
  selectedGenres,
  meetConditions,
  playlistCover,
  score,
  stats,
  extraScore,
  status,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <Desktop>
        <TableRow>
          <TableData width='56px'>
            <PlaylistCover
              alt='playlist-cover'
              src={playlistCover}
              fallbackSrc={track}
            />
          </TableData>
          <TableData fillProp>
            <MainInfo>
              <Text>{playListName}</Text>
              <Text>
                <span>{t('playlistsPage.playlistScore')}: </span>
                {score + extraScore}
              </Text>
            </MainInfo>
          </TableData>
          <TableData width='152px'>
            <PlaylistStats stats={stats} followers={followers} />
          </TableData>
          <TableData fillProp>
            <PlaylistGenres
              selectedGenres={selectedGenres}
              meetConditions={meetConditions}
            />
          </TableData>
          <TableData width='104px'>
            <PlaylistStatus
              meetConditions={meetConditions}
              selectedGenres={selectedGenres}
              status={status}
            />
          </TableData>
          <TableData width='120px' center>
            {meetConditions ? (
              <Button
                type='white'
                onClick={() => onGenresEdit(playlistID, false)}
              >
                {t('playlistsPage.edit')}
              </Button>
            ) : (
              <ContactSupportBtn
                href={playlistRequirementsLink}
                target='_blank'
              >
                {t('playlistsPage.learnMore')}
              </ContactSupportBtn>
            )}
          </TableData>
        </TableRow>
      </Desktop>
      <Mobile>
        <MobileCard>
          <PlaylistCover
            alt='playlist-cover'
            src={playlistCover}
            fallbackSrc={track}
          />
          <MobileCardRightContent>
            <TableDataMobile>
              <Text>{playListName}</Text>
              <Text>
                <span>{t('playlistsPage.playlistScore')}: </span>
                {score + extraScore}
              </Text>
            </TableDataMobile>
            <TableDataMobile>
              <PlaylistStats stats={stats} followers={followers} />
            </TableDataMobile>
            <TableDataMobile>
              <PlaylistGenres
                selectedGenres={selectedGenres}
                meetConditions={meetConditions}
              />
            </TableDataMobile>
            {!isEmpty(selectedGenres) && meetConditions && (
              <TableDataMobile>
                <PlaylistStatus
                  meetConditions={meetConditions}
                  selectedGenres={selectedGenres}
                  status={status}
                />
              </TableDataMobile>
            )}
            <TableDataMobile>
              {meetConditions ? (
                <ButtonWrapper>
                  <Button
                    type='white'
                    onClick={() => onGenresEdit(playlistID, false)}
                  >
                    {t('playlistsPage.edit')}
                  </Button>
                </ButtonWrapper>
              ) : (
                <ContactSupportBtn
                  href={playlistRequirementsLink}
                  target='_blank'
                >
                  {t('playlistsPage.contactSupport')}
                </ContactSupportBtn>
              )}
            </TableDataMobile>
          </MobileCardRightContent>
        </MobileCard>
      </Mobile>
    </>
  )
}

export default PlayListCard
