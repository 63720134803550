import React, { PropsWithChildren } from 'react'

import styled from 'styled-components'

import { variables } from 'src/Styled/variables'

const StyledLink = styled.a`
  color: ${({ theme }) => theme.button.primaryText};
  font-family: 'Segoe UI';
  font-size: ${variables.fontSize14};
  font-style: normal;
  font-weight: ${variables.fontWeight400};
  line-height: 150%;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    color: ${({ theme }) => theme.button.primaryText};

    &:active {
      color: ${({ theme }) => theme.button.fifthText};
    }
  }

  &:active {
    color: ${({ theme }) => theme.button.fifthText};
  }

  &:visited {
    color: ${({ theme }) => theme.button.primaryText};
    text-decoration: none;
  }
`

export type TextLinkProps = PropsWithChildren &
  React.AnchorHTMLAttributes<HTMLAnchorElement>

export const TextLink: React.FC<TextLinkProps> = ({ children, ...props }) => (
  <StyledLink {...props}>{children}</StyledLink>
)
