import { FC } from 'react'

import { useTranslation } from 'react-i18next'

import { OrangeAlert } from './styles'

interface AcceptTrackErrorProps {
  message: string
}

const MAP_MESSAGE_TO_TEXT: Record<string, string> = {
  'campaign-does-not-exists': 'influencer.canceledMessage',
  default: 'influencer.oopsThisTrackIsNoLongerAvailable',
}
export const AcceptTrackError: FC<AcceptTrackErrorProps> = ({ message }) => {
  const { t } = useTranslation()
  const text = MAP_MESSAGE_TO_TEXT[message] || MAP_MESSAGE_TO_TEXT.default
  return <OrangeAlert>{t(text)}</OrangeAlert>
}
