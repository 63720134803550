import styled from 'styled-components'

export const ScheduleBudgetInput = styled.input`
  width: 100%;
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.common.primaryBorder};
  background: ${({ theme }) => theme.common.primaryBg};
  color: ${({ theme }) => theme.common.primaryText};
  display: flex;
  height: 48px;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  text-align: center;
  font-family: 'Segoe UI';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  &:focus-visible {
    outline: none;
  }
`
