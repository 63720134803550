import { FunctionComponent } from 'react'

import { LoadingOutlined } from '@ant-design/icons'

import { colors } from 'src/Styled/variables'

import { LoadingSpinnerProps } from './props'

import { Spinner } from './styles'

export const LoadingSpinner: FunctionComponent<LoadingSpinnerProps> = ({
  color = colors.white,
  size = 'default',
  className,
}) => (
  <Spinner
    data-testid='loading-spinner'
    size={size}
    rootClassName={className}
    indicator={<LoadingOutlined style={{ color }} />}
  />
)
