import { useEffect, useState } from 'react'

import { useGrowthBook } from '@growthbook/growthbook-react'

import {
  getIsSubscriptionsUpsellAvailableSelector,
  useMetadataQuery,
} from 'src/Redux/metadataApi'

export const useShowSubscriptionUpsell = (): boolean => {
  const { data } = useMetadataQuery()
  const IsSubscriptionsUpsellAvailable =
    getIsSubscriptionsUpsellAvailableSelector(data)
  const growthBook = useGrowthBook()
  const [showUpsell, setShowUpsell] = useState<boolean>(false)

  useEffect(() => {
    if (IsSubscriptionsUpsellAvailable && growthBook) {
      const featureValue = growthBook.getFeatureValue(
        'subscription_upsell',
        false,
      )
      setShowUpsell(featureValue)
    }
  }, [IsSubscriptionsUpsellAvailable, growthBook])

  return showUpsell
}
