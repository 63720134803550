import { createDraftSafeSelector } from '@reduxjs/toolkit'

import { RootState } from '..'

import { ArtistsState } from '.'

const getState = (state: RootState): ArtistsState => state.artists

export const getSimilarGenresArtists = createDraftSafeSelector(
  getState,
  ({ artists }) =>
    artists?.filter((item) => item !== null && item.genres.length !== 0),
)

export const getSfaEmail = createDraftSafeSelector(
  getState,
  ({ sfa_email }) => sfa_email,
)
