import { createDraftSafeSelector } from '@reduxjs/toolkit'

import { RootState } from 'src/Redux'

import { CuratorDeleteAccountState } from '.'

const getState = (state: RootState): CuratorDeleteAccountState =>
  state.settings.curatorDeleteAccount

export const getErrorCuratorDeleteAccount = createDraftSafeSelector(
  getState,
  (state) => state.error,
)
