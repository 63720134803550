import React from 'react'

import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { useSelector } from 'react-redux'

import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { deleteCuratorAccount } from 'src/Redux/settings-process/curatorDeleteAccount/api-actions'
import { getErrorCuratorDeleteAccount } from 'src/Redux/settings-process/curatorDeleteAccount/selectors'
import Button from 'src/Components/Buttons/Button'
import { SmartInput } from 'src/Components/SmartInput'
import { DeleteAccountSchema } from 'src/Containers/Settings/components/DeleteAccountModal/helpers'
import { deleteArtistAccount } from 'src/Redux/settings-process/artistDeleteAccount/api-actions'
import { getErrorArtistDeleteAccount } from 'src/Redux/settings-process/artistDeleteAccount/selectors'
import { getIsArtist } from 'src/Redux/auth-process/credentialsSlice/selectors'
import { ErrorMessage } from 'src/Containers/CreateCampaignWithoutSignup/components/UserRegisterForm/ErrorMessage'

import {
  BoldText,
  ModalBody,
  Loader,
  FormGroup,
  FormGroupLabel,
  FormGroupField,
  ButtonWrapper,
  StyledModal,
} from './styles'

interface ConfirmationModalProps {
  toggleModalVisible: () => void
  visible: boolean
}

interface FormState {
  deletion: string
}

const DeleteAccountModal: React.FunctionComponent<ConfirmationModalProps> = ({
  toggleModalVisible,
  visible,
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const isArtist = useSelector(getIsArtist)
  const curatorError = useAppSelector(getErrorCuratorDeleteAccount)
  const artistError = useAppSelector(getErrorArtistDeleteAccount)

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormState>({
    mode: 'onSubmit',
    resolver: yupResolver(DeleteAccountSchema),
  })

  const watchDelete = watch('deletion', '')

  const handleDeleteAccount = (): void => {
    if (isArtist) {
      void dispatch(deleteArtistAccount())
    } else {
      void dispatch(deleteCuratorAccount())
    }
  }
  const isError = artistError || curatorError
  return (
    <StyledModal
      open={visible}
      title={t('settingsPage.deleteYourAccount')}
      footer={null}
      onCancel={toggleModalVisible}
    >
      {!isSubmitting && !isError && (
        <form
          data-testid='delete-form'
          onSubmit={handleSubmit(handleDeleteAccount)}
        >
          <ModalBody>
            <p>
              {t('settingsPage.areYouSure')}
              <br />
              <BoldText>{t('settingsPage.note')}</BoldText>
              &nbsp;
              {t('settingsPage.ifYouDelete')}
            </p>
            <br />
            <FormGroup>
              <FormGroupLabel>{t('settingsPage.typeDelete')}</FormGroupLabel>
              <FormGroupField>
                <SmartInput
                  {...register('deletion')}
                  name='deletion'
                  data-testid='delete-account-form'
                  type='text'
                />
                <ErrorMessage message={errors.deletion?.message} />
              </FormGroupField>
            </FormGroup>
          </ModalBody>
          <ButtonWrapper>
            <Button type='white' onClick={toggleModalVisible}>
              {t('settingsPage.cancel')}
            </Button>
            <Button
              disabled={!watchDelete?.length || isSubmitting}
              type='red'
              htmlType='submit'
            >
              {t('settingsPage.delete')}
            </Button>
          </ButtonWrapper>
        </form>
      )}
      {isSubmitting && (
        <Loader data-testid='loader' height={24} type='spinningBubbles' />
      )}
      {isError && (
        <ModalBody>
          <p>{t('settingsPage.contact')}</p>
        </ModalBody>
      )}
    </StyledModal>
  )
}

export default DeleteAccountModal
