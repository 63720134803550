import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { setApiError } from 'src/Hooks/redux'

import { ApiError, NameSpace } from '../types'

import { chargeBillingAgreement } from './api-actions'

export interface PayPalTransactionsState {
  rememberPayPalForFutureFlag: boolean
  isProcessingPage: boolean

  loading: boolean
  error: ApiError | null
}

const initialState: PayPalTransactionsState = {
  rememberPayPalForFutureFlag: true,
  isProcessingPage: false,

  loading: false,
  error: null,
}

export const paypalTransactionsProcess = createSlice({
  initialState,
  name: NameSpace.PayPalTransactions,
  reducers: {
    changeRememberPayPalForFutureFlag(state, action: PayloadAction<boolean>) {
      state.rememberPayPalForFutureFlag = action.payload
    },
    setIsProcessingPage(state, action: PayloadAction<boolean>) {
      state.isProcessingPage = action.payload
    },
  },
  extraReducers: (builder) => {
    // chargeBillingAgreement
    builder.addCase(chargeBillingAgreement.pending, (state) => {
      state.loading = true
    })
    builder.addCase(chargeBillingAgreement.rejected, (state, action) => {
      state.loading = false
      state.error = setApiError(action)
    })
    builder.addCase(chargeBillingAgreement.fulfilled, (state) => {
      state.error = null
      state.loading = false
    })
  },
})

export const { changeRememberPayPalForFutureFlag, setIsProcessingPage } =
  paypalTransactionsProcess.actions
