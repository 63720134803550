import { useNavigate } from 'react-router-dom'

import {
  CampaignPlatformTypeEnum,
  SpotifyCampaign,
  TiktokCampaign,
} from 'src/Types/index'
import { setPlatform } from 'src/Redux/campaign-process/spotifyCampaignSchedule-process'
import { activeTabStorage } from 'src/Constants/constants'
import {
  campaignsList,
  scheduleCampaign,
  scheduleCampaignTikTok,
} from 'src/Router/routes'

import { useAppDispatch } from './redux'

interface UseOpenCampaignScheduleI {
  activeTab: string
  campaign: SpotifyCampaign | TiktokCampaign
}

export const useOpenCampaignSchedule = ({
  activeTab,
  campaign,
}: UseOpenCampaignScheduleI): {
  handleScheduleCampaign: () => void
} => {
  const { platformType } = campaign
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const storeActiveTab = (): void => {
    window.sessionStorage.setItem(activeTabStorage, activeTab)
  }

  const handleScheduleCampaign = (): void => {
    storeActiveTab()

    platformType && dispatch(setPlatform(platformType))

    const schedule =
      platformType === CampaignPlatformTypeEnum.SPOTIFY
        ? scheduleCampaign
        : scheduleCampaignTikTok
    navigate(`${campaignsList}${schedule}/${campaign.id}`)

    window.scrollTo(0, 0)
  }

  return {
    handleScheduleCampaign,
  }
}
