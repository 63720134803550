export enum TabKeys {
  payout = 'payout',
  password = 'password',
  email = 'email',
  delete = 'delete',
}

export const tabs = [
  {
    showForArtist: false,
    showForCurator: true,
    key: TabKeys.payout,
    title: 'settingsPage.payout',
  },
  {
    showForArtist: true,
    showForCurator: false,
    key: TabKeys.password,
    title: 'settingsPage.changePassword',
  },
  {
    showForArtist: true,
    showForCurator: false,
    key: TabKeys.email,
    title: 'settingsPage.changeEmail',
  },
  {
    showForArtist: true,
    showForCurator: true,
    key: TabKeys.delete,
    title: 'settingsPage.delete',
  },
]

export const getTabs = (isArtist: boolean): typeof tabs =>
  tabs.filter((item) => {
    if (isArtist && item.showForArtist) {
      return true
    }
    return Boolean(!isArtist && item.showForCurator)
  })
