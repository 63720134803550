import React, { FC } from 'react'

import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Breadcrumb } from 'src/Components/Breadcrumb'
import { ArtistRoutesEnum } from 'src/Router/ArtistComponentsNewUI'

export const TikTokDetailsBreadcrumbs: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const breadcrumbItems = [
    {
      title: t('campaignDetailsPage.campaigns'),
      path: ArtistRoutesEnum.CampaignsList,
    },
    {
      title: t('campaignDetailsPage.campaignsDetails'),
      path: '',
    },
  ]

  const onBreadcrumbButtonClick = (): void => {
    navigate(ArtistRoutesEnum.CampaignsList)
  }

  return (
    <Breadcrumb
      items={breadcrumbItems}
      onButtonClick={onBreadcrumbButtonClick}
    />
  )
}
