import { FC } from 'react'

import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { login } from 'src/Router/routes'
import { Desktop, Mobile } from 'src/Components/Responsive'
import { useQuickLoginUTMParameters } from 'src/Hooks/useQuickLoginUTMParameters'
import { signupErrorToLogin } from 'src/Helpers/TagManager/AuthEvents'
import { colors, variables } from 'src/Styled/variables'

interface EmailExistProps {
  emailExist: boolean
}

export const ExistingEmailBlock = styled.p`
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight400};
  margin-top: 0px;
  text-align: left;
`

export const ExistingEmailText = styled.span`
  color: ${colors.messegesRed};
  text-align: left;
`

export const RegisterLogin = styled.span`
  color: ${colors.green};
  border-bottom: 1px dotted ${colors.green};
  line-height: 21px;
`

export const EmailExist: FC<EmailExistProps> = ({ emailExist }) => {
  const { t } = useTranslation()

  const utmParameters = useQuickLoginUTMParameters()
  const params = new URLSearchParams(utmParameters).toString()
  const loginWithParams = `${login}?${params}`

  if (!emailExist) {
    return null
  }
  return (
    <ExistingEmailBlock>
      <ExistingEmailText>
        {t('createCampaignPage.useAnotherEmailToRegisterOr')}
      </ExistingEmailText>
      &nbsp;
      <Link onClick={signupErrorToLogin} to={loginWithParams}>
        <Desktop>
          <RegisterLogin>
            {t('createCampaignPage.loginWithYourAccount')}
          </RegisterLogin>
        </Desktop>
        <Mobile>
          <RegisterLogin>{t('createCampaignPage.login')}</RegisterLogin>
        </Mobile>
      </Link>
    </ExistingEmailBlock>
  )
}
