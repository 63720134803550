import React, { FunctionComponent } from 'react'

import track from 'src/Assets/Png/track.png'

import {
  NormalText,
  SmallText,
  SoundInfoContainer,
  TrackCoverContainer,
  TrackNameContainer,
} from '../../styles'

interface TrackCoverProps {
  trackCoverUrl: string
  trackTitle: string
  trackAuthorName: string
}

export const TrackCover: FunctionComponent<TrackCoverProps> = ({
  trackCoverUrl,
  trackTitle,
  trackAuthorName,
}) => (
  <SoundInfoContainer>
    <TrackCoverContainer
      src={trackCoverUrl}
      alt='Track cover'
      fallbackSrc={track}
    />
    <TrackNameContainer>
      <NormalText>{trackTitle}</NormalText>
      <SmallText>{trackAuthorName}</SmallText>
    </TrackNameContainer>
  </SoundInfoContainer>
)
