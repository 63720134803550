import styled from 'styled-components'

import { variables, colors, device } from 'src/Styled/variables'

export const PlayListComponentEmptyPageContainer = styled.div`
  @media ${device.mobileMin} {
    padding-right: 15px;
    padding-left: 15px;
  }
`

export const PlayListEmptyPageContainer = styled.div`
  border-radius: 4px;
  line-height: 21px;
  font-size: ${variables.fontSize14};
  color: ${colors.mainText};
  @media ${device.mobileMin} {
    padding: 24px;
    background: ${colors.white};
    border-radius: 4px;
  }
`

export const PlayListEmptyPageText = styled.div`
  display: grid;
  margin-bottom: 26px;
  @media ${device.mobileMin} {
    margin-bottom: 24px;
  }
`
