export const errorsList = {
  tiktok_oauth_failed: 'influencer.authorizationFailedPleaseTryAgain',
  country_unavailable: 'influencer.serviceIsCurrentlyUnavailableInYourCountry',
  too_few_followers: 'influencer.minimalRequirementsTooFewFollowers',
  too_few_videos: 'influencer.minimalRequirementsTooFewFollowers',
  internal_server_error: 'influencer.internalServerError',
  ip_blocked_by_admins:
    'influencer.yourIPAddressHasBeenBlockedForSecurityReasons',
  default_error:
    'influencer.registrationIsCurrentlyUnavailablePleaseContactSupportTeam',
}
