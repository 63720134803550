import { FC } from 'react'

import { useTranslation } from 'react-i18next'

import AlertComponent from 'src/Components/Alert'
import { useAppSelector } from 'src/Hooks/redux'
import { getIsReachSettingsError } from 'src/Redux/schedule-process/audienceTiktokSlice/selectors'
import { getIsErrorTikTokCampaign } from 'src/Redux/schedule-process/tiktokCampaign/selectors'

export const ScheduleTiktokAlerts: FC = () => {
  const { t } = useTranslation()
  const richSettingsError = useAppSelector(getIsReachSettingsError)
  const isErrorUpdateTiktokCampaign = useAppSelector(getIsErrorTikTokCampaign)
  if (richSettingsError || isErrorUpdateTiktokCampaign) {
    return (
      <AlertComponent smallGap icon unclosable color='danger'>
        {t('PleaseTryAgainLater')}
      </AlertComponent>
    )
  }
  return null
}
