import { FC } from 'react'

import { VideoStatus } from 'src/Constants/enums'
import { useCampaignVideo } from 'src/Redux/campaignVideoApi'

import { RateForm } from './RateForm'
import { RateFeedback } from './RateFeedBack'

const ratedStatuses = [
  VideoStatus.APPROVED_BY_ARTIST,
  VideoStatus.APPROVED_BY_ADMIN,
  VideoStatus.APPROVED_AUTOMATICALLY,
  VideoStatus.AWAITING_MODERATOR_REVIEW,
  VideoStatus.DECLINED,
  VideoStatus.DISMISSED,
]

export const RateContainer: FC = () => {
  const { video } = useCampaignVideo()

  if (video?.status && ratedStatuses.includes(video?.status)) {
    return <RateFeedback />
  }

  return <RateForm />
}
