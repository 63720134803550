import React, { FC } from 'react'

import { useTranslation } from 'react-i18next'

import { StatsBlock } from 'src/Components/StatsSectionBlock'
import { CampaignPlatformTypeEnum, CampaignStatusEnum } from 'src/Types'
import { numberFormatCommas } from 'src/Constants/functions'

interface StatsBlockContainerProps {
  viewsCount?: number
  commentsCount?: number
  likesCount?: number
  videosCount?: number
  campaignStatus?: CampaignStatusEnum
}

export const StatsBlockContainer: FC<StatsBlockContainerProps> = ({
  viewsCount,
  commentsCount,
  likesCount,
  videosCount,
  campaignStatus,
}) => {
  const { t } = useTranslation()

  if (!campaignStatus) {
    return null
  }

  const sections = [
    {
      title: t('campaignDetailsPage.views'),
      value: numberFormatCommas(viewsCount),
    },
    {
      title: t('campaignDetailsPage.comments'),
      value: numberFormatCommas(commentsCount),
    },
    {
      title: t('campaignDetailsPage.likes'),
      value: numberFormatCommas(likesCount),
    },
    {
      title: t('campaignDetailsPage.videos'),
      value: videosCount,
      hintText: t('campaignDetailsPage.rejectedVideosHint'),
    },
  ]

  const isStatsBlockHidden =
    [
      CampaignStatusEnum.AWAITING_REVIEW,
      CampaignStatusEnum.PENDING,
      CampaignStatusEnum.SCHEDULED,
    ].includes(campaignStatus) ||
    ([CampaignStatusEnum.RUNNING, CampaignStatusEnum.STOPPED].includes(
      campaignStatus,
    ) &&
      !videosCount)

  if (isStatsBlockHidden) {
    return null
  }

  return (
    <StatsBlock
      sections={sections}
      platform={CampaignPlatformTypeEnum.TIKTOK}
    />
  )
}
