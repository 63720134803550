import React, { FC } from 'react'

import { useTranslation } from 'react-i18next'

import {
  TiktokCampaign,
  SpotifyCampaign,
  CampaignStatusEnum,
  TiktokCampaignStatus,
} from 'src/Types/index'

import { CampaignEndedShowDate } from './CampaignEndedShowDate'
import { CampaignInProgressShowDate } from './CampaignInProgressShowDate'
import { TiktokCanceled } from './TiktokCanceled'

import { DateWrapper } from './styles'

interface CampaignPeriodProps {
  campaign: SpotifyCampaign | TiktokCampaign
}

const RUNNING_STATUSES = [
  CampaignStatusEnum.RUNNING,
  TiktokCampaignStatus.IN_PROCESS,
]

const ENDED_STATUSES = [CampaignStatusEnum.ENDED, TiktokCampaignStatus.FINISHED]
const STOPPED_STATUSES = [
  CampaignStatusEnum.STOPPED,
  TiktokCampaignStatus.STOPPED,
  TiktokCampaignStatus.STOPPED_BY_ADMIN,
  TiktokCampaignStatus.CANCELED_BY_ADMIN,
  TiktokCampaignStatus.CANCELED,
]

export const CampaignPeriod: FC<CampaignPeriodProps> = ({ campaign }) => {
  const { t } = useTranslation()
  const { status, type, startDate, endDate } = campaign

  if (RUNNING_STATUSES.includes(status)) {
    return <CampaignInProgressShowDate campaign={campaign} />
  }
  if (ENDED_STATUSES.includes(status)) {
    return (
      <CampaignEndedShowDate
        type={type}
        startDate={startDate}
        endDate={endDate}
      />
    )
  }
  if (STOPPED_STATUSES.includes(status)) {
    return <TiktokCanceled campaign={campaign} />
  }
  if (CampaignStatusEnum.PAYMENT_PENDING === status) {
    return <DateWrapper>{t('campaignsPage.notSetYet')}</DateWrapper>
  }

  return null
}
