import { FC } from 'react'

import { useTranslation } from 'react-i18next'

import { SmartImage } from 'src/Components/SmartImage'
import track from 'src/Assets/Png/track.png'
import { addSpaceToNumber } from 'src/Helpers/layoutHelpers'

import { PlaylistIconData } from '../../types'

import {
  Container,
  CoverImageWrapper,
  InfoContainer,
  ScoreInfo,
} from './styles'

export const PlaylistCoverTooltipContent: FC<{
  playlist: PlaylistIconData
}> = ({ playlist }) => {
  const { t } = useTranslation()

  const { imageUrl, playlistName, score } = playlist
  const scoreValue = `${t('levelsPage.score')} ${addSpaceToNumber(score, ',')}`
  return (
    <Container>
      <CoverImageWrapper>
        <SmartImage src={imageUrl} alt='playlistCover' fallbackSrc={track} />
      </CoverImageWrapper>
      <InfoContainer>
        <span>{playlistName}</span>
        <ScoreInfo>{scoreValue}</ScoreInfo>
      </InfoContainer>
    </Container>
  )
}
