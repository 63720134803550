import React, { useState } from 'react'

import { Icon } from 'src/Components/Alert/Icon'
import { AlertComponentProps } from 'src/Components/Alert/AlertComponentProps'

import {
  AlertContent,
  AlertContentChildren,
  AlertIcon,
  AlertStyled,
  AlertWrapper,
  StyledCloseIcon,
} from './styles'

const AlertComponent: React.FC<AlertComponentProps> = ({
  color,
  icon,
  children,
  onClose,
  unclosable,
  rowDirection,
  noGap,
  smallGap,
  middleGap,
  noBorder,
  textColor,
  ...rest
}) => {
  const [visible, setVisible] = useState(true)

  const handleClose = (): void => {
    setVisible(false)
    onClose?.()
  }

  if (visible) {
    return (
      <AlertStyled
        noBorder={noBorder}
        noGap={Boolean(noGap)}
        smallGap={Boolean(smallGap)}
        middleGap={Boolean(middleGap)}
        message=''
        color={color}
        description={
          <AlertWrapper>
            {icon && !rowDirection && (
              <AlertIcon>{color && <Icon icon={color} />}</AlertIcon>
            )}
            <AlertContent rowDirection={rowDirection}>
              {icon && rowDirection && (
                <AlertIcon>{color && <Icon icon={color} />}</AlertIcon>
              )}
              <AlertContentChildren textColor={textColor}>
                {children}
              </AlertContentChildren>
            </AlertContent>
          </AlertWrapper>
        }
        closable={!unclosable}
        closeIcon={<StyledCloseIcon data-testid={'close-icon'} />}
        onClose={handleClose}
        {...rest}
      />
    )
  }
  return null
}

export default AlertComponent
