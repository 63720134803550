import { FC, useMemo, useState } from 'react'

import dayJs from 'dayjs'
import { Popover } from 'antd'
import { useTranslation } from 'react-i18next'

import { formatDate } from 'src/Constants/formatDate'
import { CampaignStatusEnum } from 'src/Types/index'
import { ProgressByBudget } from 'src/Components/ProgressByBudget'
import QuestionMark from 'src/Assets/Svg/questionMark.svg?react'
import { HintText } from 'src/Components/CommonHint/HintText/HintText'
import { Desktop, Mobile } from 'src/Components/Responsive'
import ModalLayout from 'src/Components/ModalLayout'
import { HUNDRED_PERCENT } from 'src/Constants/constants'
import { CampaignDetails } from 'src/Redux/campaignDetailsApi'

import {
  EndedStatusLabel,
  EndedStatusReviews,
  GlobalStyled,
  HintTitle,
  HintWrapper,
  ProgressContainer,
  ProgressInfoItem,
} from './styles'

interface ProgressBarByBudgetProps {
  campaignDetails: CampaignDetails
}
export const ProgressBarByBudget: FC<ProgressBarByBudgetProps> = ({
  campaignDetails,
}) => {
  const { t } = useTranslation()
  const [isOpenHint, setOpenHint] = useState<boolean>(false)
  const startDate = useMemo(
    () =>
      dayJs(campaignDetails.start_date).format(formatDate.formatDateMonthFull),
    [campaignDetails],
  )

  if (
    campaignDetails.status !== CampaignStatusEnum.ENDED &&
    campaignDetails.status !== CampaignStatusEnum.STOPPED
  ) {
    const percent = Math.floor(
      (campaignDetails.spent * HUNDRED_PERCENT) /
        Number(campaignDetails.budget),
    )
    const text = `${t('campaignResultsPage.currentSpending')} ${percent}${t(
      'campaignResultsPage.currentSpending2',
    )}`
    return (
      <>
        <ProgressContainer
          isFocused={isOpenHint}
          onClick={() => setOpenHint(true)}
        >
          <HintWrapper>
            <HintTitle>{t('campaignResultsPage.campaignProgress')}</HintTitle>
            <QuestionMark />
            <Desktop>
              <GlobalStyled />
              <Popover
                overlayClassName='overlayClassName'
                overlayInnerStyle={{ borderRadius: '8px' }}
                open={isOpenHint}
                trigger={['click', 'focus']}
                placement='rightBottom'
                content={
                  <HintText
                    text={text}
                    handleSetVisibilityHint={() => setOpenHint(false)}
                  />
                }
                onOpenChange={() => setOpenHint(false)}
              />
            </Desktop>
          </HintWrapper>
          <ProgressByBudget
            spent={Number(campaignDetails.spent)}
            budget={Number(campaignDetails.budget)}
          />
          <ProgressInfoItem>
            {t('campaignResultsPage.fromProgress')} {startDate}
          </ProgressInfoItem>
        </ProgressContainer>
        <Mobile>
          <ModalLayout
            visible={isOpenHint}
            width={288}
            handleClose={() => setOpenHint(false)}
            header
            hint
          >
            <HintText
              text={text}
              handleSetVisibilityHint={() => setOpenHint(false)}
            />
          </ModalLayout>
        </Mobile>
      </>
    )
  }
  return (
    <ProgressContainer>
      <EndedStatusLabel>{campaignDetails.status}</EndedStatusLabel>
      <EndedStatusReviews>
        {`${campaignDetails.reviewed_count} ${t(
          'campaignResultsPage.completed',
        )}`}
      </EndedStatusReviews>
    </ProgressContainer>
  )
}
