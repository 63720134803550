import { FC } from 'react'

import { useTranslation } from 'react-i18next'

import { TransactionTypeEnum } from 'src/Types'

import {
  Amount,
  MobileCardFooterWrapper,
  MobileCardInfoWrapper,
  MobileParamItem,
  MobileParamTitle,
  Title,
} from '../styles'

import { MobileCardInfoText } from './MobileCardInfoText'

interface MobileCardInfoProps {
  transactionType: TransactionTypeEnum
  amount: string
  date: string
  firstName?: string
  packageName?: string
}

export const MobileCardInfo: FC<MobileCardInfoProps> = ({
  transactionType,
  amount,
  date,
  firstName,
  packageName,
}) => {
  const { t } = useTranslation()

  return (
    <MobileCardInfoWrapper>
      <MobileCardInfoText
        transactionType={transactionType}
        firstName={firstName}
        packageName={packageName}
      />
      <MobileCardFooterWrapper>
        <MobileParamItem>
          <MobileParamTitle>{t('balancePage.amount')}</MobileParamTitle>
          <Amount
            isRed={
              transactionType ===
              TransactionTypeEnum.SUBSCRIPTION_PAYMENT_FAILED
            }
          >
            {amount}
          </Amount>
        </MobileParamItem>
        <MobileParamItem>
          <MobileParamTitle>{t('balancePage.date')}</MobileParamTitle>
          <Title>{date}</Title>
        </MobileParamItem>
      </MobileCardFooterWrapper>
    </MobileCardInfoWrapper>
  )
}
