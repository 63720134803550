import React, { FC } from 'react'

import styled from 'styled-components'

import { MAX_MOBILE_WIDTH } from 'src/Styled/variables'

import { SkeletonLoader } from './SkeletonLoader'

const StyledSkeleton = styled(SkeletonLoader)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 120px 240px;
  grid-column-gap: 16px;
  grid-row-gap: 16px;

  :first-child {
    grid-area: 1 / 1 / 2 / 3;
    .ant-skeleton-image {
      height: 120px !important;
    }
  }
  :nth-child(2) {
    .ant-skeleton-image {
      height: 240px !important;
    }
    grid-area: 2 / 1 / 3 / 2;
  }
  :last-child {
    .ant-skeleton-image {
      height: 240px !important;
    }
    grid-area: 2 / 2 / 3 / 3;
  }
  @media screen and (max-width: ${MAX_MOBILE_WIDTH}) {
    display: flex;
    flex-direction: column;
    :first-child {
      .ant-skeleton-image {
        height: 240px !important;
      }
    }
  }
`
export const ScheduleSettingsPageSkeleton: FC = () => (
  <StyledSkeleton count={3} />
)
