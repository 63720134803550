import { createSlice } from '@reduxjs/toolkit'

import { setApiError } from 'src/Hooks/redux'

import { ApiError, NameSpace } from '../types'

import {
  sendScheduleStartEvent,
  sendScheduleStartPlatformEvent,
  sendPaymentStartEvent,
  sendPaymentStartPlatformEvent,
  sendPaymentCompletedPlatformEvent,
  sendPaymentInfoEvent,
  sendPaymentInfoPlatformEvent,
  createTiktokCampaignEvent,
} from './api-actions'

export interface GoogleAnalyticsState {
  loading: boolean
  error: ApiError | null
}

const initialState: GoogleAnalyticsState = {
  loading: false,
  error: null,
}

export const googleAnalyticsProcess = createSlice({
  initialState,
  name: NameSpace.GoogleAnalytics,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(sendScheduleStartEvent.pending, (state) => {
      state.loading = true
    })
    builder.addCase(sendScheduleStartEvent.rejected, (state, action) => {
      state.loading = false
      state.error = setApiError(action)
    })
    builder.addCase(sendScheduleStartEvent.fulfilled, (state) => {
      state.error = null
      state.loading = false
    })

    builder.addCase(sendScheduleStartPlatformEvent.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      sendScheduleStartPlatformEvent.rejected,
      (state, action) => {
        state.loading = false
        state.error = setApiError(action)
      },
    )
    builder.addCase(sendScheduleStartPlatformEvent.fulfilled, (state) => {
      state.error = null
      state.loading = false
    })

    builder.addCase(sendPaymentStartEvent.pending, (state) => {
      state.loading = true
    })
    builder.addCase(sendPaymentStartEvent.rejected, (state, action) => {
      state.loading = false
      state.error = setApiError(action)
    })
    builder.addCase(sendPaymentStartEvent.fulfilled, (state) => {
      state.error = null
      state.loading = false
    })

    builder.addCase(sendPaymentStartPlatformEvent.pending, (state) => {
      state.loading = true
    })
    builder.addCase(sendPaymentStartPlatformEvent.rejected, (state, action) => {
      state.loading = false
      state.error = setApiError(action)
    })
    builder.addCase(sendPaymentStartPlatformEvent.fulfilled, (state) => {
      state.error = null
      state.loading = false
    })

    builder.addCase(sendPaymentCompletedPlatformEvent.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      sendPaymentCompletedPlatformEvent.rejected,
      (state, action) => {
        state.loading = false
        state.error = setApiError(action)
      },
    )
    builder.addCase(sendPaymentCompletedPlatformEvent.fulfilled, (state) => {
      state.error = null
      state.loading = false
    })

    builder.addCase(sendPaymentInfoEvent.pending, (state) => {
      state.loading = true
    })
    builder.addCase(sendPaymentInfoEvent.rejected, (state, action) => {
      state.loading = false
      state.error = setApiError(action)
    })
    builder.addCase(sendPaymentInfoEvent.fulfilled, (state) => {
      state.error = null
      state.loading = false
    })

    builder.addCase(sendPaymentInfoPlatformEvent.pending, (state) => {
      state.loading = true
    })
    builder.addCase(sendPaymentInfoPlatformEvent.rejected, (state, action) => {
      state.loading = false
      state.error = setApiError(action)
    })
    builder.addCase(sendPaymentInfoPlatformEvent.fulfilled, (state) => {
      state.error = null
      state.loading = false
    })

    builder.addCase(createTiktokCampaignEvent.pending, (state) => {
      state.loading = true
    })
    builder.addCase(createTiktokCampaignEvent.rejected, (state, action) => {
      state.loading = false
      state.error = setApiError(action)
    })
    builder.addCase(createTiktokCampaignEvent.fulfilled, (state) => {
      state.error = null
      state.loading = false
    })
  },
})
