import { FC } from 'react'

import styled from 'styled-components'
import { useIntercom } from 'react-use-intercom'

import { TextLink } from 'src/Components/TextLink'
import { useAppSelector } from 'src/Hooks/redux'
import { PrimaryAlert } from 'src/Components/PrimaryAlert'
import { PrimaryDottedButton } from 'src/Components/Buttons/PrimaryDottedButton'
import i18n from 'src/Localization/i18n'
import { getUserEmail } from 'src/Redux/auth-process/userSlice/selectors'

import { CHANGE_EMAIL_LINK } from '../index'

const StyledPrimaryDottedButton = styled(PrimaryDottedButton)`
  padding-left: 0;
  padding-right: 0;
`
const StyledTextLink = styled(TextLink)`
  text-align: center;
  font-family: 'Segoe UI';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  border-bottom: 1px dashed ${({ theme }) => theme.button.redText};
  color: ${({ theme }) => theme.button.redText} !important;
  & > div {
    border-bottom: 1px dashed ${({ theme }) => theme.button.redText};
  }
  &:hover {
    border-bottom: 1px dashed transparent;
  }
  &:focus-visible {
    outline: none;
  }
`

const DottedButtonWrapper = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`

export const EmailConfirmationError: FC = () => {
  const email = useAppSelector(getUserEmail)
  const { show } = useIntercom()

  const handleSupportClick = (): void => {
    show()
  }

  return (
    <PrimaryAlert
      type='danger'
      hasCross={false}
      title={i18n.t('campaignsPage.emailConfirmationFailed')}
      message={`${i18n.t('campaignsPage.theEmailAddressDoesNotExist', { email })}`}
      renderButtons={() => (
        <DottedButtonWrapper>
          <StyledPrimaryDottedButton onClick={handleSupportClick}>
            {i18n.t('campaignsPage.contactSupport')}
          </StyledPrimaryDottedButton>
          <StyledTextLink href={CHANGE_EMAIL_LINK}>
            {i18n.t('campaignsPage.updateEmail')}
          </StyledTextLink>
        </DottedButtonWrapper>
      )}
    />
  )
}
