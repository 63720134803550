import { useNavigate, useParams } from 'react-router-dom'

import { useSendFeedbackMutation } from 'src/Redux/sendFeedbackApi'
import { useAppSelector } from 'src/Hooks/redux'
import {
  getUserEmail,
  getUserId,
} from 'src/Redux/auth-process/userSlice/selectors'
import { getValueOrEmptyString } from 'src/Helpers/layoutHelpers'
import { getCountry } from 'src/Redux/auth-process/countrySlice/selectors'
import { collectDataFromSourceBuster } from 'src/Redux/sourcebuster-process/api-actions'
import { campaignsList } from 'src/Router/routes'

import { SurveyFormValues } from './helpers'

interface SendFeedback {
  loading: boolean
  onSendFeedback: (data: SurveyFormValues) => Promise<void>
}

export const useSendFeedBack = (): SendFeedback => {
  const navigate = useNavigate()
  const userId = useAppSelector(getUserId)
  const country = useAppSelector(getCountry)
  const { campaignType } = useParams<{ campaignType: string }>()
  const email = useAppSelector(getUserEmail)
  const [send, { isLoading }] = useSendFeedbackMutation()

  const onSendFeedback = async (data: SurveyFormValues): Promise<void> => {
    const sourcebusterData = collectDataFromSourceBuster()
    await send({
      country,
      userId: Number(userId || 0),
      userEmail: email,
      answerSelected: data.resource,
      subAnswerSelected: data.other || '',
      campaign_platform: getValueOrEmptyString(campaignType),
      first_utm_source: sourcebusterData.firstUtmSource,
      first_utm_medium: sourcebusterData.firstUtmMedium,
      first_utm_campaign: sourcebusterData.firstUtmCampaign,
      first_utm_content: sourcebusterData.firstUtmContent,
      first_utm_term: sourcebusterData.firstUtmTerm,
      first_landing_page: sourcebusterData.firstLandingPage,
      last_landing_page: sourcebusterData.lastLandingPage,
      last_utm_source: sourcebusterData.lastUtmSource,
      last_utm_medium: sourcebusterData.lastUtmMedium,
      last_utm_campaign: sourcebusterData.lastUtmCampaign,
      last_utm_content: sourcebusterData.lastUtmContent,
      last_utm_term: sourcebusterData.lastUtmTerm,
    })
    navigate(campaignsList)
  }
  return { loading: isLoading, onSendFeedback }
}
