import React, { FC } from 'react'

import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { getUploadVideoError } from 'src/Redux/influencer-process/selectors'
import { ErrorCode } from 'src/Redux/types'
import Alert from 'src/Components/Alert'

import { AlertContainer } from '../CreatorDetailsModal/styles'

export const TrackModalAlerts: FC = () => {
  const { t } = useTranslation()

  const uploadVideoError = useSelector(getUploadVideoError)

  return (
    <div>
      {uploadVideoError?.code === ErrorCode.InternalServerError && (
        <Alert color='danger' icon unclosable>
          <AlertContainer>
            <span>{t('influencer.uploadVideoError')}</span>
          </AlertContainer>
        </Alert>
      )}
    </div>
  )
}
