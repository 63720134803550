import React, { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'

import { useStatusView } from 'src/Containers/Tracks/helpers'
import { colors } from 'src/Styled/variables'

import { TrackDetailsProps } from './types'

import {
  InfoItemWrapper,
  InfoItemWrapperTitle,
  TrackDetailsTitle,
} from './styles'

export const TrackDetails: FunctionComponent<TrackDetailsProps> = ({
  genres,
  status,
  vocal,
}) => {
  const { t } = useTranslation()
  const { text, color: statusColor } = useStatusView(status)

  const trackInfo = [
    {
      name: 'influencer.genres',
      values: genres?.join(', '),
      color: colors.mainText,
    },
    {
      name: 'influencer.vocal',
      values: vocal?.join(', '),
      color: colors.mainText,
    },
    {
      name: 'influencer.status',
      values: text,
      color: statusColor,
    },
  ]

  return (
    <div>
      <TrackDetailsTitle>{t('influencer.tracksDetails')}</TrackDetailsTitle>
      <div>
        {trackInfo.map(({ name, values, color }) => (
          <InfoItemWrapper key={name}>
            <InfoItemWrapperTitle style={{ color: colors.greyTextWhiteBg }}>
              {t(name)}:
            </InfoItemWrapperTitle>
            <div style={{ color }}>{values}</div>
          </InfoItemWrapper>
        ))}
      </div>
    </div>
  )
}
