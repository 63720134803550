import { FC, useEffect } from 'react'

import styled from 'styled-components'
import { Input } from 'antd'
import { useIntercom } from 'react-use-intercom'

import SearchIcon from 'src/Assets/Svg/search-icon.svg?react'
import { colors } from 'src/Styled/variables'

import { TiktokWidgetList } from './TiktokWidgetList'
import { useTiktokWidgetSearch } from './useTiktokWidgetSearch'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledInput = styled(Input)`
  border-radius: 80px;
  margin-bottom: 10px;
  padding: 16.5px 32px;
  border-color: ${colors.greyTextLight};
  color: #000;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  &.ant-input-affix-wrapper .ant-input-prefix {
    margin-inline-end: 16px;
  }
  &.ant-input-affix-wrapper-focused,
  &.ant-input-outlined:hover,
  &.ant-input-outlined:active,
  &.ant-input-outlined:focus {
    border-color: ${colors.greyTextLight};
    box-shadow: none;
  }
`

const TiktokWidgetSearch: FC = () => {
  const { onTrackSearchHandler, tracks, error, loading } =
    useTiktokWidgetSearch()
  const { hardShutdown } = useIntercom()

  useEffect(() => {
    hardShutdown()
  }, [hardShutdown])
  return (
    <Wrapper>
      <StyledInput
        size='large'
        placeholder='Type your track name or URL from TikTok'
        prefix={<SearchIcon width={'16px'} height={'16px'} />}
        onChange={(event) =>
          onTrackSearchHandler({ searchInput: event.target.value })
        }
      />
      <TiktokWidgetList tracks={tracks} error={error} loading={loading} />
    </Wrapper>
  )
}

export default TiktokWidgetSearch
