import styled from 'styled-components'

import { colors, variables } from 'src/Styled/variables'

export const SubPageTitle = styled.h3`
  margin: 0;
  font-size: ${variables.fontSize18};
  font-weight: ${variables.fontWeight600};
  color: ${colors.mainText};
  line-height: 150%;
`

export const CommentModalWrapper = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
  padding: 24px 16px;
  flex-direction: column;
  align-items: center;
  background-color: ${colors.white};
  height: calc(100vh - 60px);

  @media (min-width: 768px) {
    width: 600px;
    padding: 64px 0;
    margin: 0 auto;
    height: 100%;
  }
`
export const TitleContainer = styled.div`
  display: grid;
  width: 100%;
  height: 30px;
  justify-content: center;
  align-content: center;
  grid-template-columns: 1fr 40px;
  gap: 16px;
  line-height: 150%;
`
export const BackButton = styled.div`
  margin: 0;
  width: 16px;
  height: 16px;
`
export const PageTitle = styled.h3`
  color: ${colors.mainText};
  font-size: ${variables.fontSize18};
  font-style: normal;
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
`
export const Container = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-radius: 4px;
  @media (min-width: 768px) {
    padding: 0;
  }
`
export const Description = styled.div`
  color: ${colors.mainText};
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight400};
  line-height: 150%;
`
export const TextareaContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2px;
`
export const Example = styled.div`
  width: 100%;
  color: ${colors.mainText};
  font-size: ${variables.fontSize10};
  font-style: normal;
  line-height: 150%;
  padding-right: 24px;
`
export const ExampleTitle = styled.span`
  font-weight: ${variables.fontWeight600};
  margin-right: 4px;
`
export const ExampleText = styled.span`
  font-weight: ${variables.fontWeight400};
`

export const FooterContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 16px;
  position: fixed;
  bottom: 0;
  background-color: ${colors.white};
  padding: 8px 16px;
  border-top: 1px solid ${colors.greyStroke};
  div:last-child {
    width: fit-content;
    .ant-btn.ant-btn-default {
      padding: 0 40px;
    }
  }
`
export const CloseButton = styled.button`
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  svg {
    height: 16px;
    width: 16px;
  }
  @media (min-width: 768px) {
    position: absolute;
    top: 28px;
    right: 19px;
  }
`
