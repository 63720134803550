import React, { FunctionComponent, useMemo, useState } from 'react'

import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { yupResolver } from '@hookform/resolvers/yup'
import { object, string } from 'yup'

import Button from 'src/Components/Buttons/Button'
import { calcTimeLeft } from 'src/Containers/Tracks/helpers'
import Input from 'src/Containers/ui/Input'
import { creatorUploadedVideo } from 'src/Helpers/TagManager'
import TikTokIcon from 'src/Assets/Svg/tiktok.svg?react'
import {
  getEventsStatistics,
  getInProgressTikTokInfluencerEvents,
  getNewTikTokInfluencerEvents,
  getSubmittedTikTokInfluencerEvents,
  uploadVideo,
} from 'src/Redux/influencer-process/api-actions'
import {
  getEventId,
  getInProgressEvents,
} from 'src/Redux/influencer-process/selectors'
import { StyledButton } from 'src/Containers/Tracks/components/Footer/Awaiting/styles'
import checkedCircle from 'src/Assets/Png/checked-circle.png'
import { UploadedErrorMessage, VideoUploadError } from 'src/Types/index'
import { useAppDispatch } from 'src/Hooks/redux'
import { ApiError } from 'src/Redux/types'
import { getInfluencerId } from 'src/Redux/auth-process/userSlice/selectors'

import { CloseWrapper, Success } from '../styles'

import { FormValues, UploadVideoProps } from './types'
import { UploadButton } from './UploadButton'
import { useValidationText } from './useValidationText'

import {
  Container,
  ErrorMessage,
  inlineStyles,
  InsertAlertText,
  SuccessIcon,
  ButtonsGroups,
  InputContainer,
} from './styles'

const schema = object().shape({
  url: string().required().url(),
})

export const UploadVideo: FunctionComponent<UploadVideoProps> = ({
  t,
  onClose,
  isSuccessAcceptedPage,
}) => {
  const dispatch = useAppDispatch()

  const {
    handleSubmit,
    setValue,
    register,
    getValues,
    formState: { isValid },
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: { url: '' },
    resolver: yupResolver(schema),
  })

  const { onChange, onBlur, name } = register('url')

  const eventsInProgress = useSelector(getInProgressEvents)
  const userId = useSelector(getInfluencerId)
  const eventId = useSelector(getEventId)

  const message = useValidationText()

  const [uploaded, setUploaded] = useState(false)
  const [uploadErrorMessage, setUploadErrorMessage] =
    useState<UploadedErrorMessage>(null)

  const submitVideo = (values: FormValues): void => {
    if (eventId) {
      void dispatch(
        uploadVideo({
          videoTiktokUrl: values.url,
          eventId,
        }),
      )
        .unwrap()
        .then((res) => {
          void dispatch(getEventsStatistics())

          void dispatch(getNewTikTokInfluencerEvents({ pageNumber: 1 }))
          void dispatch(getInProgressTikTokInfluencerEvents({ pageNumber: 1 }))
          void dispatch(getSubmittedTikTokInfluencerEvents({ pageNumber: 1 }))

          setUploaded(true)
          eventId &&
            creatorUploadedVideo({
              userID: userId,
              reviewId: eventId,
              campaignId: res.campaignId,
              trackId: res.trackId,
              amountPaid: res.paidAmount,
            })
          return res
        })
        .catch((error) => {
          const { message: errorMessage } = error as ApiError
          setUploadErrorMessage(errorMessage)
        })
    }
  }

  const handleChangeUrl = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setUploadErrorMessage(null)
    setValue('url', e.currentTarget.value)
    void onChange(e)
  }

  const currentEvent = useMemo(
    () =>
      eventsInProgress?.events.find((event) => event.id === eventId) || null,
    [eventId, eventsInProgress],
  )

  const timeLeft = currentEvent?.deadlineToLoadVideo
    ? calcTimeLeft(currentEvent?.deadlineToLoadVideo)
    : ''

  const showButtons =
    !isSuccessAcceptedPage &&
    uploadErrorMessage !== VideoUploadError.CAMPAIGN_DOES_NOT_EXIST

  return (
    <form onSubmit={handleSubmit(submitVideo)}>
      <Container>
        {uploaded && (
          <div>
            <Success>
              <SuccessIcon src={checkedCircle} alt='CheckedIcon' />

              <span>{t('influencer.videoAdded')}</span>
            </Success>
            <ButtonsGroups>
              <CloseWrapper>
                <Button type='white' onClick={onClose}>
                  {t('influencer.closeBtn')}
                </Button>
              </CloseWrapper>
              <Link to={getValues('url')} target='_blank'>
                <CloseWrapper>
                  <Button type='whiteWithGreenBorder'>
                    <StyledButton>
                      <TikTokIcon />
                      {t('influencer.videoOnTiktok')}
                    </StyledButton>
                  </Button>
                </CloseWrapper>
              </Link>
            </ButtonsGroups>
          </div>
        )}
        {!uploaded && (
          <>
            {!isSuccessAcceptedPage && (
              <InputContainer>
                <InsertAlertText>
                  {t('influencer.insertTiktokUrl')}
                </InsertAlertText>
                <Input
                  withborder='withborder'
                  style={
                    uploadErrorMessage ? inlineStyles.errorInputHighlight : {}
                  }
                  placeholder={t('influencer.tikTokURL')}
                  onChange={handleChangeUrl}
                  onBlur={onBlur}
                  name={name}
                />
                {uploadErrorMessage &&
                  (message?.[uploadErrorMessage] || (
                    <ErrorMessage>
                      {t('influencer.notTiktokLinkErrorMessage')}
                    </ErrorMessage>
                  ))}
              </InputContainer>
            )}

            <ButtonsGroups>
              <CloseWrapper>
                <Button type='white' onClick={onClose}>
                  {t('influencer.closeBtn')}
                </Button>
              </CloseWrapper>
              <UploadButton
                showButtons={showButtons}
                disabled={uploaded || !isValid}
                timeLeft={timeLeft}
              />
            </ButtonsGroups>
          </>
        )}
      </Container>
    </form>
  )
}
