import { FC } from 'react'

import { SmartImage } from 'src/Components/SmartImage'
import { useCreateNewCampaign } from 'src/Containers/SearchNewCampaignByPlatform/SelectedNewCampaign/useCreateNewCampaign'
import { PromoteButton } from 'src/Components/Buttons/PromoteButton'

import {
  ImageInfo,
  ImageWrapper,
  InnerImageInfo,
  Container,
  Name,
  Artist,
  ButtonWrapper,
} from '../../styles'

export const ReleaseTrackInfo: FC<{
  trackName: string
  artistName: string
  trackImageUrl: string
  handleCreateAndStartSchedule?: () => void
}> = ({
  trackName,
  artistName,
  trackImageUrl,
  handleCreateAndStartSchedule,
}) => {
  const { loading } = useCreateNewCampaign()
  return (
    <ImageInfo>
      <InnerImageInfo>
        <ImageWrapper>
          <SmartImage
            alt='trackCoverUrl'
            width='48'
            src={trackImageUrl}
            fallbackSrc={trackImageUrl}
          />
        </ImageWrapper>

        <Container>
          <Name>{trackName}</Name>
          <Artist>{artistName}</Artist>
        </Container>
      </InnerImageInfo>
      <ButtonWrapper>
        <PromoteButton
          loading={loading}
          onClick={handleCreateAndStartSchedule}
        />
      </ButtonWrapper>
    </ImageInfo>
  )
}
