import { FunctionComponent, useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useDebounce } from 'use-debounce'

import { playlistAddedEvent } from 'src/Helpers/TagManager'
import { CampaignPlatformTypeEnum, Playlist } from 'src/Types/index'
import { meetConditionsLink } from 'src/Constants/links'
import { AlertErrorMessageWithIcon } from 'src/Components/AlertErrorMessageWithIcon'
import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { getUserId } from 'src/Redux/auth-process/userSlice/selectors'
import { DEBOUNCE_DELAY_500 } from 'src/Constants/constants'
import {
  cutPlaylistId,
  findPlaylist,
} from 'src/Redux/searchPlaylist-process/api-actions'
import {
  getSearchPlaylistError,
  getSearchPlaylistLoading,
} from 'src/Redux/searchPlaylist-process/selectors'
import { resetSearchPlaylist } from 'src/Redux/searchPlaylist-process'
import { checkPlaylist } from 'src/Redux/playlists-process/api-actions'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import { ApiError } from 'src/Redux/types'

import { PlaylistModalButton } from '../PlaylistModalButton'

import { LABELS_ERROR_BUTTON_DISABLE } from './helpers'

import {
  AlertWrapper,
  ButtonContainer,
  FieldContainer,
  FormContainer,
  InputField,
  Label,
  MeetConditionsBtn,
  Placeholder,
} from './styles'
interface AddPlaylistFormProps {
  toggle: () => void
}

const AddPlaylistForm: FunctionComponent<AddPlaylistFormProps> = ({
  toggle,
}) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const userId = useSelector(getUserId)
  const { setSearch } = useCustomSearchParams()
  const loading = useAppSelector(getSearchPlaylistLoading)
  const error = useAppSelector(getSearchPlaylistError)

  const [searchValue, setSearchValue] = useState<string>('')

  const [debounceValue] = useDebounce(searchValue, DEBOUNCE_DELAY_500)

  const eventHandler = (response: Playlist): void => {
    userId &&
      playlistAddedEvent(
        response.meets_conditions,
        CampaignPlatformTypeEnum.SPOTIFY,
        response.id,
        String(userId),
      )
  }

  const onClickHandler = async (
    callback: (error: ApiError | null) => void,
  ): Promise<void> => {
    const playlistId = cutPlaylistId(searchValue)
    const apiURL = `https://api.spotify.com/v1/playlists/${playlistId}`

    try {
      await dispatch(checkPlaylist(apiURL))
        .unwrap()
        .then((response) => {
          eventHandler && eventHandler(response)

          setSearch({ editPlaylist: String(response.id), new: 'true' })

          return response
        })

      toggle()
    } catch (err) {
      callback(err as ApiError)
    }
  }

  useEffect(() => {
    if (debounceValue) {
      void dispatch(findPlaylist({ playlistId: debounceValue }))
    } else {
      dispatch(resetSearchPlaylist())
    }
  }, [debounceValue, dispatch])

  return (
    <form>
      <FormContainer>
        <div>
          <Label>{t('playlistsPage.enterPlaylist')}</Label>
          <FieldContainer>
            <Placeholder>
              {t('playlistsPage.spotifyPlaylistUrlOrURI')}
            </Placeholder>
            <InputField
              error={
                LABELS_ERROR_BUTTON_DISABLE.includes(error?.message || '') ||
                Boolean(error)
              }
              value={searchValue}
              role='input '
              type='text'
              onChange={(e) => setSearchValue(e.target.value)}
            />
            <AlertWrapper>
              <AlertErrorMessageWithIcon
                type='desktop'
                isShow={!loading && Boolean(error)}
                errorText={t(error?.message ?? '')}
              />
              {error?.message === 'validation.notRequirementsPlaylist' && (
                <MeetConditionsBtn href={meetConditionsLink} target='_blank'>
                  {t('validation.minimalPlatformRequirements')}
                </MeetConditionsBtn>
              )}
            </AlertWrapper>
          </FieldContainer>
        </div>
        <ButtonContainer>
          <PlaylistModalButton
            isLoading={loading}
            noValue={!debounceValue}
            labels={LABELS_ERROR_BUTTON_DISABLE.includes(error?.message || '')}
            onClickHandler={onClickHandler}
          />
        </ButtonContainer>
      </FormContainer>
    </form>
  )
}

export default AddPlaylistForm
