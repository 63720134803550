import { createDraftSafeSelector } from '@reduxjs/toolkit'

import { RootState } from 'src/Redux'
import { SliceStatus } from 'src/Redux/types'

import { ArtistPasswordState } from '.'

const getState = (state: RootState): ArtistPasswordState =>
  state.settings.artistPassword

export const getIsSuccessChangedPassword = createDraftSafeSelector(
  getState,
  (state) => state.status === SliceStatus.Succeeded,
)
