import React, { FC } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { capitalizeFirstLetter } from 'src/Helpers/layoutHelpers'
import { colors, variables } from 'src/Styled/variables'
import { Genre } from 'src/Types'

export const Status = styled.span<{
  color: string
  leftAlign?: boolean
}>`
  display: inline-block;
  width: 100%;
  color: ${({ color }) => color};
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight400};
  text-align: ${({ leftAlign }) => (leftAlign ? 'left' : 'center')};

  @media (max-width: 576px) {
    text-align: left;
  }
`

export const Genres = styled.span`
  color: ${colors.mainText};
  font-size: ${variables.fontSize10};
`

interface Props {
  meetConditions: boolean
  selectedGenres: Genre[]
}

export const PlaylistGenres: FC<Props> = ({
  meetConditions,
  selectedGenres,
}) => {
  const { t } = useTranslation()
  if (!meetConditions) {
    return (
      <Status color={colors.messegesRed} leftAlign>
        {t('playlistsPage.doesNotMeetConditions')}
      </Status>
    )
  }
  if (selectedGenres.length === 0) {
    return (
      <Status color={colors.messegesRed} leftAlign>
        {t('playlistsPage.emptyGenres')}
      </Status>
    )
  }
  return (
    <Genres>
      {selectedGenres.map(
        (item, key) =>
          `${capitalizeFirstLetter(item?.Genre?.genre_name || '')}${
            key < selectedGenres.length - 1 ? ', ' : ''
          }`,
      )}
    </Genres>
  )
}
