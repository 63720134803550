import { combineReducers } from '@reduxjs/toolkit'

import { audienceTiktokSlice } from './audienceTiktokSlice'
import { couponSlice } from './couponSlice'
import { tiktokCampaignSlice } from './tiktokCampaign'

export const scheduleProcess = combineReducers({
  audienceTiktok: audienceTiktokSlice.reducer,
  coupon: couponSlice.reducer,
  tiktok: tiktokCampaignSlice.reducer,
})
