import styled from 'styled-components'

import { variables, device, colors } from 'src/Styled/variables'

export const WhatArtistsThinkHeader = styled.h3`
  font-style: normal;
  color: ${colors.mainText};
  font-weight: ${variables.fontWeight600};
  font-size: ${variables.fontSize20};
  line-height: 150%;
  margin-bottom: 40px;
  margin-top: 56px;

  @media ${device.mobileMin} {
    font-size: ${variables.fontSize24};
    margin-bottom: 66px;
    margin-top: 96px;
  }
`

export const WhatArtistsThinkList = styled.ul<Props>`
  list-style: none;
  margin-bottom: 80px;
  padding: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow-x: ${(props: Props) => (props.isActive ? 'none' : 'scroll')};
  white-space: nowrap;
  row-gap: 40px;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
  white-space: break-spaces;
  position: relative;
`

interface Props {
  isActive?: boolean
}

export const WhatArtistsThinkItem = styled.li<Props>`
  height: fit-content;
  padding: ${(props: Props) => (props.isActive ? '24px' : '0')};
  background-color: ${(props: Props) =>
    props.isActive ? '#fff' : 'transparent'};
  display: inline-block;
  margin-right: ${(props: Props) => (props.isActive ? '0px' : '40px')};
  transition: all 0.5s ease-in-out;
  left: ${(props: Props) => (props.isActive ? '0px' : 'auto')};
  right: ${(props: Props) => (props.isActive ? '0px' : 'auto')};
  z-index: ${(props: Props) => (props.isActive ? '0' : 'auto')};
  opacity: 1;
  border-radius: 4px;
  @media ${device.mobileMin} {
    margin-right: ${(props: Props) => (props.isActive ? '0px' : '64px')};
  }

  &.nonactive {
    opacity: 0;
    display: none;
  }
`

export const WhatArtistsThinkAvatarWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const WhatArtistsThinkAvatar = styled.img`
  width: 64px;
  height: 64px;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 16px;
`

export const WhatArtistsThinkTitle = styled.div`
  color: ${colors.mainText};
  font-weight: ${variables.fontWeight600};
  font-size: ${variables.fontSize18};
  line-height: 150%;
  align-items: center;
  margin-bottom: 12px;
`

export const WhatArtistsThinkText = styled.div<Props>`
  color: ${colors.mainText};
  font-weight: ${variables.fontWeight400};
  font-size: ${variables.fontSize14};
  line-height: 150%;
  margin-bottom: 9.5px;
  margin-top: 12px;
  display: -webkit-box;
  -webkit-line-clamp: ${(props: Props) => (props.isActive ? 'auto' : '3')};
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: ${(props: Props) => (props.isActive ? 'auto' : '63px')};
`

export const WhatArtistsThinkShowFullButton = styled.button`
  background-color: transparent;
  border: none;
  width: 101px;
  cursor: pointer;
  padding: 0;
  color: ${colors.mainText};
  font-weight: ${variables.fontWeight400};
  font-size: ${variables.fontSize14};
`

export const WhatArtistsThinkShowFullButtonWrapper = styled.div`
  border-bottom: 1px dashed ${colors.mainText};
  margin-bottom: 40.5px;
  display: inline-block;
  line-height: 150%;
`

export const WhatArtistsThinkReadOnTrustPilot = styled.a`
  border: 1px solid ${colors.greyStroke};
  width: 224px;
  border-radius: 8px;
  background: ${colors.white};
  width: 224px;
  height: 40px;
  color: ${colors.mainText};
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
  justify-content: center;
  cursor: pointer;
  &:hover,
  &:visited {
    color: ${colors.mainText};
  }
`

export const TrustPilotRead = styled.span`
  margin-right: 8px;
`

export const TrustPilotImg = styled.div`
  width: 96px;
  height: 27px;
`

export const CloseButton = styled.button`
  width: 40px;
  height: 40px;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  img {
    height: 10px;
  }
`

export const StarWrapper = styled.div`
  .react-stars {
    display: inline-block;
  }

  .react-stars > span {
    margin-right: 2px;
  }
`
