import styled from 'styled-components'

import { colors, variables } from 'src/Styled/variables'

export const FooterContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 16px;
  position: fixed;
  bottom: 0;
  background-color: ${colors.white};
  padding: 8px 16px;
  border-top: 1px solid #eee;
  div:last-child {
    width: fit-content;
    .ant-btn.ant-btn-default {
      padding: 0 40px;
    }
  }
`
export const TextWrapper = styled.div`
  div:first-child {
    font-size: 10px;
    color: ${colors.mainText};
    white-space: nowrap;
    line-height: 150%;
  }
  div:last-child {
    font-size: 14px;
    color: ${colors.mainText};
    white-space: nowrap;
  }
`

export const MobileInputWrapper = styled.div`
  position: fixed;
  top: 0;
  background: ${colors.white};
  padding: 0 5px;
  width: 100%;
  @media (min-width: 768px) {
    padding: 40px 84px 16px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  @media (min-width: 1280px) {
    padding: 40px 350px 16px;
  }
`

export const ListWrapper = styled.div`
  padding: 65px 16px 65px;
  @media (min-width: 768px) {
    padding: 0px 84px 65px;
  }
  @media (min-width: 1280px) {
    padding: 0px 350px 65px;
  }
`

export const Title = styled.h3`
  font-size: ${variables.fontSize20};
  line-height: 150%;
  font-weight: ${variables.fontWeight600};
  color: ${colors.mainText};
`
export const DesktopInputWrapper = styled.div<{ isVisible: boolean }>`
  ${({ isVisible }) =>
    isVisible
      ? `
      position: fixed;
      top: 60px;
      left: 0;
      width: 100%;
      z-index: 1;
      padding: 40px 84px 16px;
      background: ${colors.white};
      @media (min-width: 1280px) {
        padding: 40px 350px 16px;
      }
      `
      : ''}
`
export const LanguageListContainer = styled.ul`
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`
export const LanguageListLabel = styled.div`
  color: ${colors.mainText};
  margin-bottom: 16px;
  margin-top: 6px;
`
export const RadioTitle = styled.div`
  color: ${colors.mainText};
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight600};
  line-height: 21px;
`
export const List = styled.ul`
  padding: 0;
`
