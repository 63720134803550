import React, { FC } from 'react'

import styled from 'styled-components'
import ReactLoading from 'react-loading'

import PauseIcon from 'src/Assets/Svg/player/pause.svg?react'
import PlayIcon from 'src/Assets/Svg/player/play.svg?react'
import { variables } from 'src/Styled/variables'
import TrackCoverDefault from 'src/Assets/Svg/common/track-cover.svg'
import { SmartImage } from 'src/Components/SmartImage'

const Container = styled.div`
  display: flex;
  height: 64px;
  padding: 8px 12px;
  align-items: center;
  gap: 16px;
  border-radius: 16px;
  align-self: stretch;
  border: 1px solid transparent;
  background: ${({ theme }) => theme.common.primaryBg};
  transition: border 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    border: 1px solid ${({ theme }) => theme.common.primaryBorder};
    transition: border 0.2s ease-in-out;
  }

  &:focus {
    border: 1px solid ${({ theme }) => theme.common.secondaryBorder};
  }
`

const InfoContainer = styled.div`
  display: flex;
  height: 42px;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
`

const IconWrapper = styled.div`
  position: absolute;
  top: 16px;
  left: 16px;
`

const TrackImageWrapper = styled.div`
  cursor: pointer;
  position: relative;
  border-radius: 12px;
  overflow: hidden;
`

const TrackName = styled.span`
  font-family: 'Segoe UI';
  font-style: normal;
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
  color: ${({ theme }) => theme.common.primaryText};
`

const Text = styled.span`
  font-family: 'Segoe UI';
  font-style: normal;
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight400};
  line-height: 150%;
  color: ${({ theme }) => theme.common.primaryText};
`

export const TrackCoverImage = styled(SmartImage)`
  display: flex;
  width: 48px;
  height: 48px;
  align-items: center;
`

const Loader = styled(ReactLoading)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

interface TrackItemProps {
  onItemClick: () => void
  trackName: string
  artistName: string
  trackDuration: string
  trackCoverUrl: string
  loading: boolean
  onTrackControlClick: (
    e: React.MouseEvent<HTMLDivElement>,
    trackUrl?: string,
  ) => void
  isPlaying: boolean
  show: boolean
}

export const TrackItem: FC<TrackItemProps> = ({
  trackName,
  onItemClick,
  artistName,
  trackDuration,
  trackCoverUrl,
  loading,
  onTrackControlClick,
  isPlaying,
  show = true,
}) => (
  <Container onClick={onItemClick}>
    {show ? (
      <TrackImageWrapper onClick={onTrackControlClick}>
        <IconWrapper>
          {loading && <Loader type='spin' width={'30px'} height={'30px'} />}
          {isPlaying ? <PauseIcon /> : <PlayIcon />}
        </IconWrapper>

        <TrackCoverImage src={trackCoverUrl} fallbackSrc={TrackCoverDefault} />
      </TrackImageWrapper>
    ) : (
      <TrackImageWrapper onClick={onTrackControlClick}>
        <TrackCoverImage src={trackCoverUrl} fallbackSrc={TrackCoverDefault} />
      </TrackImageWrapper>
    )}

    <InfoContainer>
      <TrackName>{trackName}</TrackName>
      <Text>{artistName}</Text>
    </InfoContainer>
    <Text>{trackDuration}</Text>
  </Container>
)
