import React, { FC } from 'react'

import { useTranslation } from 'react-i18next'

import PageTitle from './components/PageTitle'
import LevelsTable from './components/LevelsTable'
import { useLevels } from './useLevels'

import { PageContainer, PageContentWrapper } from './styles'

const Rank: FC = () => {
  const { t } = useTranslation()

  useLevels()

  return (
    <PageContainer>
      <PageTitle title={t('rankPage.pageTitle')} />
      <PageContentWrapper>
        <LevelsTable />
      </PageContentWrapper>
    </PageContainer>
  )
}

export default Rank
