import styled from 'styled-components'

import { device, colors, variables } from 'src/Styled/variables'

export const WithdrawText = styled.div`
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  color: ${colors.messegesRed};
`

export const StyledDate = styled.div``
export const SupportButton = styled.span`
  cursor: pointer;
  text-decoration: underline;
`
export const EmptyDiv = styled.div`
  width: 16px;
  height: 16px;
`

export const Description = styled.div<{ isTop?: boolean }>`
  display: flex;
  gap: 8px;
  align-items: ${({ isTop }) => (isTop ? 'flex-start' : 'center')};
  @media ${device.mobileMax} {
    gap: 4px;
    flex-direction: column;
    align-items: flex-start;
  }
`
export const Amount = styled.div<{ isGreen?: boolean }>`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  white-space: nowrap;
  color: ${({ isGreen }) => (isGreen ? colors.green : colors.mainText)};
`
export const EmptyBalance = styled.div`
  padding: 20px !important;
  display: grid;
  justify-content: center;
  font-size: 14px;
`
export const BalanceTableContainer = styled.div`
  width: 100%;
  padding: 24px;
  background-color: ${colors.white};
  border-radius: 4px;
  position: relative;
  .ant-table-cell {
    font-weight: ${variables.fontWeight400};
    font-size: ${variables.fontSize14};
    line-height: 150%;
    color: ${colors.mainText};
    background-color: transparent;
  }
  .ant-table-thead .ant-table-cell {
    font-weight: ${variables.fontWeight400};
    background-color: ${colors.white};
    color: ${colors.mainText};
    line-height: 21px;
    border: none;
    &::before {
      display: none;
    }
  }
  .ant-table-tbody {
    border-spacing: 0 2px;
  }
  .ant-table-row .ant-table-cell {
    border: none;
    background-color: ${colors.greyBg};
  }
`

export const AmountHeaderCell = styled.div`
  text-align: right;
`
export const Wrapper = styled.li`
  padding: 16px;
  width: 100%;
  background-color: ${colors.white};
  display: flex;
  border-radius: 4px;
  gap: 8px;
`
export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`
export const AmountWrapper = styled.div`
  display: flex;
  gap: 16px;
  justify-content: space-between;
`
export const Title = styled.div`
  font-size: ${variables.fontSize10};
`
export const StatusWrapper = styled.div``
export const DateWrapper = styled.div``
export const AmountContainer = styled.div`
  text-align: right;
`
