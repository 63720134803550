import React, { useState, FunctionComponent, useEffect, useRef } from 'react'

import { NavLink } from 'react-router-dom'

import { DropdownMenuProps } from './props'

import {
  Trigger,
  Menu,
  MenuItem,
  Separator,
  MenuItemIcon,
  MenuItemText,
  DropdownContainer,
} from './styles'

export const DropdownMenu: FunctionComponent<DropdownMenuProps> = ({
  topSeparated,
  getItems,
  renderContent,
  renderTrigger,
  menuStyle,
  triggerStyle,
  isRightOriented = false,
}) => {
  const triggerRef = useRef(null)
  const menuRef = useRef(null)
  const [isOpen, setIsOpen] = useState(false)

  const toggleMenu = (): void => {
    setIsOpen((prevValue) => !prevValue)
  }

  const documentClickCallback = (e: MouseEvent): void => {
    const dropdownMenu = menuRef.current
    const dropdownMenuTrigger = triggerRef.current
    const isMenuClicked = e.composedPath().find((el) => el === dropdownMenu)
    const isTriggerClicked = e
      .composedPath()
      .find((el) => el === dropdownMenuTrigger)
    if (!isMenuClicked && !isTriggerClicked) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', documentClickCallback)
    return () => {
      document.removeEventListener('click', documentClickCallback)
    }
  }, [])

  const items = getItems && getItems(toggleMenu)

  const renderMenuContent = (): React.ReactNode => {
    if (items) {
      return items.map(
        ({ label, key, icon, isNav, onClick, navigateTo }, id) => (
          <React.Fragment key={key}>
            {isNav ? (
              <NavLink to={navigateTo}>
                <MenuItem key={key} onClick={onClick}>
                  <MenuItemIcon>{icon}</MenuItemIcon>
                  <MenuItemText>{label}</MenuItemText>
                </MenuItem>
              </NavLink>
            ) : (
              <MenuItem key={key} onClick={onClick}>
                <MenuItemIcon>{icon}</MenuItemIcon>
                <MenuItemText>{label}</MenuItemText>
              </MenuItem>
            )}

            {items.length - 1 > id && <Separator />}
          </React.Fragment>
        ),
      )
    }
    return renderContent && renderContent(toggleMenu)
  }

  return (
    <DropdownContainer>
      <Trigger ref={triggerRef} style={triggerStyle} onClick={toggleMenu}>
        {renderTrigger(isOpen)}
      </Trigger>
      {isOpen && (
        <Menu
          ref={menuRef}
          style={menuStyle}
          topSeparated={topSeparated}
          isRightOriented={isRightOriented}
        >
          {renderMenuContent()}
        </Menu>
      )}
    </DropdownContainer>
  )
}
