import styled from 'styled-components'

import { colors, device } from 'src/Styled/variables'

export const TextRed = styled.span`
  text-align: center;

  p {
    color: red;
    margin-bottom: 0;
  }
`
export const TextBlack = styled.span`
  text-align: center;

  p {
    color: black;
    margin-bottom: 20px;
  }
`
export const ButtonsWrapper = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  justify-content: space-around;
  div {
    width: 100%;
    .ant-btn {
      padding: 0 40px;
    }
  }
  @media ${device.mobileMin} {
    flex-direction: row;
    div {
      width: fit-content;
    }
  }
`
export const AccountOr = styled.div`
  text-align: center;
  margin-bottom: 10px;
  position: relative;

  p {
    color: black !important;
  }

  &:before,
  &:after {
    content: '';
    height: 1px;
    width: calc(50% - 90px);
    background: ${colors.greyText};
    position: absolute;
    top: 10px;
  }

  &:before {
    left: 0;
  }

  &:after {
    right: 0;
  }
`
