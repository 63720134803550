import React, {
  FunctionComponent,
  useState,
  useRef,
  useEffect,
  useCallback,
} from 'react'

import { useMediaQuery } from 'react-responsive'
import { Popover } from 'antd'
import { useTranslation } from 'react-i18next'

import { DottedBtn } from 'src/Components/DottedBtn/DottedBtn'
import ModalLayout from 'src/Components/ModalLayout'
import IconQuestionMark from 'src/Assets/Svg/questionMark.svg?react'
import IconQuestionMarkGreen from 'src/Assets/Svg/questionMarkGreen.svg?react'

import { HintText } from './HintText/HintText'
import { CommonHintProps } from './CommonHintProps'

import { GlobalStyled, StyledHintSignInner } from './styles'

export const CommonHint: FunctionComponent<CommonHintProps> = ({
  text,
  subtext,
  details,
  languagesHintOpenedEventHandler,
  languagesHintClosedEventHandler,
}) => {
  const { t } = useTranslation()
  const [isVisibleHint, setVisibleHint] = useState(false)
  const [isHoveredHint, setIsHoveredHint] = useState(false)

  const popoverRef = useRef<HTMLDivElement>(null)

  const handleVisibleChange = (visible: boolean): void => {
    setVisibleHint(visible)
  }

  const handleClickOutside = useCallback((e: MouseEvent): void => {
    if (popoverRef.current && !popoverRef.current.contains(e.target as Node)) {
      setVisibleHint(false)
    }
  }, [])

  const handleMouseEnter = (): void => {
    setIsHoveredHint(true)
  }

  const handleMouseLeave = (): void => {
    setIsHoveredHint(false)
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [handleClickOutside])

  const handleSetVisibilityHint = (): void => {
    setVisibleHint(!isVisibleHint)
    if (!isVisibleHint && languagesHintOpenedEventHandler) {
      languagesHintOpenedEventHandler()
    }
    if (isVisibleHint && languagesHintClosedEventHandler) {
      languagesHintClosedEventHandler()
    }
  }

  const renderIcon = (): React.ReactElement =>
    isVisibleHint || isHoveredHint ? (
      <IconQuestionMarkGreen width={7} height={14} />
    ) : (
      <IconQuestionMark width={7} height={14} />
    )

  const isTablet = useMediaQuery({ maxWidth: 1023 })

  return (
    <div>
      <StyledHintSignInner
        details={details}
        onClick={handleSetVisibilityHint}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {details ? (
          <DottedBtn content={t('scheduleCampaignPage.details')} />
        ) : (
          renderIcon()
        )}
      </StyledHintSignInner>

      <GlobalStyled />

      {isTablet ? (
        <ModalLayout
          visible={isVisibleHint}
          width={288}
          handleClose={handleSetVisibilityHint}
          header
          hint
        >
          <HintText
            text={text}
            subtext={subtext}
            handleSetVisibilityHint={handleSetVisibilityHint}
          />
        </ModalLayout>
      ) : (
        <Popover
          overlayClassName='overlayClassName'
          overlayInnerStyle={{ borderRadius: '8px' }}
          open={isVisibleHint}
          trigger={['click', 'focus']}
          placement='top'
          content={
            <HintText
              text={text}
              subtext={subtext}
              handleSetVisibilityHint={handleSetVisibilityHint}
            />
          }
          onOpenChange={handleVisibleChange}
        />
      )}
    </div>
  )
}
