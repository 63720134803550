import React, { FC, PropsWithChildren } from 'react'

import ReactLoading from 'react-loading'
import styled from 'styled-components'

import { colors } from 'src/Styled/variables'

export const Loading = styled(ReactLoading)`
  margin: 50px auto;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    fill: ${colors.green};
    height: 24px;
    width: 24px;
    margin: 0;
  }
`
interface ShowLoadingProps {
  loading: boolean
}

type Props = PropsWithChildren & ShowLoadingProps
export const ShowLoading: FC<Props> = ({ loading, children }) => {
  if (loading) {
    return <Loading height={24} type='spinningBubbles' />
  }
  return children
}
