import styled from 'styled-components'

import { colors } from 'src/Styled/variables'

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 68px 1fr;
  text-align: left;
  line-height: 21px;

  > span {
    color: ${colors.greyTextWhiteBg};
    margin-right: 8px;
  }
`

export const Text = styled.div`
  color: ${colors.mainText};
`
