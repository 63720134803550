// TODO add clean store logic

import { FunctionComponent } from 'react'

import { useNavigate } from 'react-router-dom'

export const ErrorPage: FunctionComponent = () => {
  const navigate = useNavigate()

  return (
    <div>
      <div>
        <p>
          An error has occurred in this component.{' '}
          <span
            style={{ cursor: 'pointer', color: '#0077FF' }}
            onClick={() => {
              navigate('/')
              window.location.reload()
            }}
          >
            Go to main page
          </span>{' '}
        </p>
      </div>
    </div>
  )
}
