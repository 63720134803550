import { useCallback } from 'react'

import { useNavigate } from 'react-router-dom'

export const useResetQueryParams = (paramsList: string[]): (() => void) => {
  const navigate = useNavigate()

  return useCallback(() => {
    const queryParams = new URLSearchParams(window.location.search)
    let hasChanges = false
    paramsList.forEach((param) => {
      if (queryParams.has(param)) {
        queryParams.delete(param)
        hasChanges = true
      }
    })

    if (hasChanges) {
      navigate(`?${queryParams.toString()}`, { replace: true })
    }
  }, [navigate, paramsList])
}
