import { FC, useCallback, useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { ChosenTrack } from 'src/Components/ChosenTrack'
import { ALBUM_IMAGES } from 'src/Constants/constants'
import { convertTimeHandler } from 'src/Constants/functions'
import { useCreateNewCampaign } from 'src/Containers/SearchNewCampaignByPlatform/SelectedNewCampaign/useCreateNewCampaign'
import { onPlayAddedTrack } from 'src/Helpers/TagManager/TrackEvent'
import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { getUserRole } from 'src/Redux/auth-process/credentialsSlice/selectors'
import { getUserId } from 'src/Redux/auth-process/userSlice/selectors'
import { setTiktokTrackToInitialState } from 'src/Redux/tiktokTrack-process'
import { getTikTokTrackSelector } from 'src/Redux/tiktokTrack-process/selectors'
import { setTrackToInitialState } from 'src/Redux/track-process'
import { getSpotifyTrackSelector } from 'src/Redux/track-process/selectors'
import { CampaignPlatformTypeEnum, CampaignTypeEnum } from 'src/Types'

export const SelectedNewTrack: FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const userId = useAppSelector(getUserId)
  const role = useAppSelector(getUserRole)
  const { platform } = useParams<{ platform: CampaignPlatformTypeEnum }>()
  const tikTokTrack = useAppSelector(getTikTokTrackSelector)

  const spotifyTrack = useAppSelector(getSpotifyTrackSelector)

  const { loading, createNewSpotifyCampaign, createNewTiktokCampaign } =
    useCreateNewCampaign()

  const handleCreate = (): void => {
    if (spotifyTrack) {
      createNewSpotifyCampaign(spotifyTrack)
    }
    if (tikTokTrack) {
      createNewTiktokCampaign(tikTokTrack)
    }
  }
  const handleChangeTrack = useCallback((): void => {
    dispatch(setTrackToInitialState())
    dispatch(setTiktokTrackToInitialState())
  }, [dispatch])

  // in strict mode, useEffect cleanup function is called before
  // for local creation of campaign please remove strict mode
  useEffect(
    () => (): void => {
      handleChangeTrack()
    },
    [handleChangeTrack],
  )
  if (!tikTokTrack && !spotifyTrack) {
    return null
  }

  const handleClick = (): void => {
    onPlayAddedTrack(
      CampaignTypeEnum.BASIC,
      role,
      platform ?? CampaignPlatformTypeEnum.SPOTIFY,
      userId,
    )
  }

  return (
    <>
      {tikTokTrack && (
        <ChosenTrack
          createLoading={loading}
          artist={tikTokTrack?.trackAuthorName}
          title={t('createNewCampaignPage.newTikTok')}
          duration={convertTimeHandler({
            value: tikTokTrack?.trackDuration || 0,
            type: 's',
          })}
          trackName={tikTokTrack?.trackTitle}
          trackUrl={tikTokTrack?.trackPlayUrl}
          image={tikTokTrack?.trackCoverUrl}
          onChangeClick={handleChangeTrack}
          onCreateClick={handleCreate}
          onPlayEvent={handleClick}
        />
      )}
      {spotifyTrack && (
        <ChosenTrack
          createLoading={loading}
          artist={spotifyTrack?.artists[0]?.name}
          title={t('createNewCampaignPage.newSpotify')}
          duration={convertTimeHandler({
            value: spotifyTrack?.duration_ms || 0,
            type: 'ms',
          })}
          trackName={spotifyTrack?.name}
          trackUrl={spotifyTrack.preview_url}
          image={spotifyTrack?.album?.images[ALBUM_IMAGES]?.url}
          onChangeClick={handleChangeTrack}
          onCreateClick={handleCreate}
          onPlayEvent={handleClick}
        />
      )}
    </>
  )
}
