import { FunctionComponent } from 'react'

import { useNavigate } from 'react-router-dom'
import { Col, Row } from 'antd'
import { useTranslation } from 'react-i18next'

import ArrowIcon from 'src/Assets/Svg/arrow.svg?react'
import { campaignsList } from 'src/Router/routes'

import { ReferAndEarnTitle, BackButton } from './styles'

export const PageTitle: FunctionComponent = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const goToCampaignsList = (): void => {
    navigate(campaignsList)
  }

  return (
    <Col lg={12} md={12} xl={12}>
      <Row>
        <Col md={12}>
          <ReferAndEarnTitle>
            <BackButton onClick={goToCampaignsList}>
              <ArrowIcon />
            </BackButton>

            {t('referAndEarn.referAndEarn')}
          </ReferAndEarnTitle>
        </Col>
      </Row>
    </Col>
  )
}
