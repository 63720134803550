import { APP } from 'src/Configs/App'
import { rtkApi } from 'src/Services/rtkApi'

interface BudgetRange {
  min: number
  max: number
}

const spotifyBudgetRangeApi = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    getBudgetRange: builder.query<BudgetRange, string | undefined>({
      query: (campaignId) =>
        `${APP.SERVER}/campaign/budget-range/${campaignId}`,
    }),
  }),
  overrideExisting: false,
})

export const { useGetBudgetRangeQuery, useLazyGetBudgetRangeQuery } =
  spotifyBudgetRangeApi
