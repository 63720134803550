import { createDraftSafeSelector } from '@reduxjs/toolkit'

import { RootState } from '..'

import { ReviewsState } from '.'
const getState = (state: RootState): ReviewsState => state.reviews
export const getAwaitingTracksSelector = createDraftSafeSelector(
  getState,
  ({ awaitingTracks }) => awaitingTracks,
)

export const getReviewedTracksSelector = createDraftSafeSelector(
  getState,
  ({ reviewedTracks }) => reviewedTracks,
)

export const getMissedTracksSelector = createDraftSafeSelector(
  getState,
  ({ missedTracks }) => missedTracks,
)

export const getAwaitingTracksTotalSelector = createDraftSafeSelector(
  getState,
  ({ totalAwaitingTracks }) => totalAwaitingTracks,
)

export const getTotalReviewedTracksSelector = createDraftSafeSelector(
  getState,
  ({ totalReviewedTracks }) => totalReviewedTracks,
)

export const getTotalMissedTracksSelector = createDraftSafeSelector(
  getState,
  ({ totalMissedTracks }) => totalMissedTracks,
)

export const getTotalAwaitingTracksSelector = createDraftSafeSelector(
  getState,
  ({ totalAwaitingTracks }) => totalAwaitingTracks,
)

export const getBlacklist = createDraftSafeSelector(
  getState,
  ({ wordsBlacklist }) => wordsBlacklist,
)

export const getRejectReasons = createDraftSafeSelector(
  getState,
  ({ rejectReasons }) => [
    ...rejectReasons,
    // [3, "Track quality doesn't meet expectations"],
  ],
)

export const getReviewsLoader = createDraftSafeSelector(
  getState,
  ({ loading }) => loading,
)

export const getCuratorStatsSelector = createDraftSafeSelector(
  getState,
  ({ curatorStats }) => curatorStats,
)

export const getReviewDetailsSelector = createDraftSafeSelector(
  getState,
  ({ reviewDetails }) => reviewDetails,
)
