import {
  PayloadAction,
  createDraftSafeSelector,
  createSlice,
} from '@reduxjs/toolkit'

import { RootState } from 'src/Redux'

interface SubscriptionFromLocation {
  location: string
}
const initialState: SubscriptionFromLocation = {
  location: '',
}
export const subscriptionFromLocationSlice = createSlice({
  initialState,
  name: 'subscriptionFromLocation',
  reducers: {
    setSubscriptionFromLocation: (state, action: PayloadAction<string>) => {
      state.location = action.payload
    },
    clearSubscriptionFromLocation: (state) => {
      state.location = ''
    },
  },
})

export const { setSubscriptionFromLocation, clearSubscriptionFromLocation } =
  subscriptionFromLocationSlice.actions

const getState = (state: RootState): SubscriptionFromLocation =>
  state.subscriptions.subscriptionFromLocationSlice

export const getSubscriptionFromLocation = createDraftSafeSelector(
  getState,
  (state) => state.location,
)
