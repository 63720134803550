import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'

import { api } from 'src/Services/api'
import { APP } from 'src/Configs/App'
import { CuratorStats, RejectReason, Review, ReviewDetails } from 'src/Types'

import { NameSpace } from '../types'

export const getRejectReasons = createAsyncThunk<RejectReason[]>(
  `${NameSpace.Reviews}/getRejectReasons`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await api.get<RejectReason[]>(
        `${APP.SERVER}/review/reject-reasons`,
      )
      return data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      return rejectWithValue({
        message: response?.data.message,
        status: response?.status,
      })
    }
  },
)

interface TracksData {
  total: number
  reviews: Array<Review>
}

export const getAwaitingTracks = createAsyncThunk<TracksData, number>(
  `${NameSpace.Reviews}/getAwaitingTracks`,
  async (page, { rejectWithValue }) => {
    try {
      const { data } = await api.get<TracksData>(
        `${APP.SERVER}/curator/reviews/awaiting_review/${page}`,
      )
      return data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      return rejectWithValue({
        message: response?.data.message,
        status: response?.status,
      })
    }
  },
)

export const getReviewedTracks = createAsyncThunk<TracksData, number>(
  `${NameSpace.Reviews}/getReviewedTracks`,
  async (page, { rejectWithValue }) => {
    try {
      const { data } = await api.get<TracksData>(
        `${APP.SERVER}/curator/reviews/reviewed/${page}`,
      )
      return data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      return rejectWithValue({
        message: response?.data.message,
        status: response?.status,
      })
    }
  },
)

export const getMissedTracks = createAsyncThunk<TracksData, number>(
  `${NameSpace.Reviews}/getMissedTracks`,
  async (page, { rejectWithValue }) => {
    try {
      const { data } = await api.get<TracksData>(
        `${APP.SERVER}/curator/reviews/missed/${page}`,
      )
      return data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      return rejectWithValue({
        message: response?.data.message,
        status: response?.status,
      })
    }
  },
)

export const getReviewDetails = createAsyncThunk<ReviewDetails, number>(
  `${NameSpace.Reviews}/getReviewDetails`,
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await api.get<ReviewDetails>(
        `${APP.SERVER}/review/details/${id}`,
      )
      return data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      return rejectWithValue({
        message: response?.data.message,
        status: response?.status,
      })
    }
  },
)

export const getWordsBlacklist = createAsyncThunk<string[]>(
  `${NameSpace.Reviews}/getWordsBlacklist`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await api.get<string[]>(`${APP.SERVER}/review/blacklist`)
      return data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      return rejectWithValue({
        message: response?.data.message,
        status: response?.status,
      })
    }
  },
)

export const getCuratorStats = createAsyncThunk<CuratorStats>(
  `${NameSpace.Reviews}/getCuratorStats`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await api.get<CuratorStats>(
        `${APP.SERVER}/review/curators-stats`,
      )
      return data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      return rejectWithValue({
        message: response?.data.message,
        status: response?.status,
      })
    }
  },
)

interface SkipReviewBody {
  review_id: number
  campaign_id: number
}

interface SkipReviewResponse {
  review_id: number
}

export const skipReview = createAsyncThunk<SkipReviewResponse, SkipReviewBody>(
  `${NameSpace.Reviews}/skipReview`,
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await api.post<SkipReviewResponse>(
        `${APP.SERVER}/review/skip`,
        body,
      )
      return data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      return rejectWithValue({
        message: response?.data.message,
        status: response?.status,
      })
    }
  },
)

interface SendReviewBody {
  campaign_id: number | string
  comment: string
  recommendations: string | null
  value: {
    type: string
    id: number | string
    position: number
  }
}
interface SendReviewResponse {
  review: {
    id: number
  }
}

export const sendReview = createAsyncThunk<SendReviewResponse, SendReviewBody>(
  `${NameSpace.Reviews}/sendReview`,
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await api.put<SendReviewResponse>(
        `${APP.SERVER}/review`,
        body,
      )
      return data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      return rejectWithValue({
        message: response?.data.message,
        status: response?.status,
      })
    }
  },
)

interface RespondToReviewBody {
  respond: string
  review_id: number | string
  rating: number
}

export const respondToReview = createAsyncThunk<unknown, RespondToReviewBody>(
  `${NameSpace.Reviews}/respondToReview`,
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await api.post<unknown>(
        `${APP.SERVER}/review/respond`,
        body,
      )
      return data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      return rejectWithValue({
        message: response?.data.message,
        status: response?.status,
      })
    }
  },
)
