import styled from 'styled-components'

import { device, variables } from 'src/Styled/variables'

export const PlaylistsContainer = styled.div<{ isDetailsMode: boolean }>`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 8px;
  justify-content: center;
  @media ${device.mobileMax} {
    flex-direction: ${({ isDetailsMode }) =>
      isDetailsMode ? 'column' : 'row'};
  }
`
export const HideButtonWrapper = styled.div`
  width: fit-content;
  margin: 30px auto 0;

  @media ${device.mobileMax} {
    margin: 12px auto 0;
  }
`
export const NoPlaylistsMessage = styled.div`
  display: block;
  font-size: ${variables.fontSize14};

  text-align: center;
`
