import { FC, ReactNode } from 'react'

import styled from 'styled-components'

import { CampaignPlatformTypeEnum } from 'src/Types'
import SpotifyIcon from 'src/Assets/Svg/platform-icons/Spotify.svg?react'
import TikTokIcon from 'src/Assets/Svg/platform-icons/TikTok.svg?react'

export interface CampaignPlatformLabelProps {
  platform: CampaignPlatformTypeEnum
  className?: string
}

const Container = styled.div`
  display: inline-flex;
  height: 24px;
  padding: 0px 6px 0px 4px;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  border-radius: 16px;
  background: ${({ theme }) => theme.common.primaryBorder};
  overflow: hidden;
  color: ${({ theme }) => theme.common.primaryText};
  text-overflow: ellipsis;

  font-family: 'Segoe UI';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`

const platformIconMap: Partial<Record<CampaignPlatformTypeEnum, ReactNode>> = {
  [CampaignPlatformTypeEnum.SPOTIFY]: <SpotifyIcon />,
  [CampaignPlatformTypeEnum.TIKTOK]: <TikTokIcon />,
}
const platformTextMap: Partial<Record<CampaignPlatformTypeEnum, ReactNode>> = {
  [CampaignPlatformTypeEnum.SPOTIFY]: 'Spotify',
  [CampaignPlatformTypeEnum.TIKTOK]: 'TikTok',
}
export const CampaignPlatformLabel: FC<CampaignPlatformLabelProps> = ({
  platform,
  className,
}) => {
  if (platformIconMap[platform]) {
    return (
      <Container className={className}>
        {platformIconMap[platform]}
        <span>{platformTextMap[platform] ?? ''}</span>
      </Container>
    )
  }
  return null
}
