import React, { FC } from 'react'

import { Select as AntdSelect, SelectProps as AntdSelectProps } from 'antd'
import styled, { createGlobalStyle } from 'styled-components'

import DownArrow from 'src/Assets/Svg/common/down-arrow.svg?react'
import ArrowLeftIcon from 'src/Assets/Svg/common/check-mark.svg?react'
import { variables } from 'src/Styled/variables'

const dropdownClassName = 'custom-select-dropdown'

const GlobalStyled = createGlobalStyle`
  .${dropdownClassName} {
    margin-top: 15px !important;
    border: ${({ theme }) => `1px solid ${theme.common.primaryBorder}`};
    border-radius: 16px;
    padding: 8px !important;

    .ant-select-item {
      border-bottom: none;
    }

    .ant-select-item-option-content {
      color: ${({ theme }) => theme.common.primaryText};
      font-family: 'Segoe UI';
      line-height: 150%;
      font-size: ${variables.fontSize14};
      font-weight: ${variables.fontWeight400};
    }

    .ant-select-item-option-selected {
      position: relative;
      padding: 10px !important;
      background-color: unset !important;
      &:hover {
        background-color: unset !important;
      }
    }

    .ant-select-item-option {
      padding: 10px !important;
      border: ${() => '1px solid transparent'};
      border-radius: 8px;
      &:hover {
        background-color: unset;
        border: ${({ theme }) => `1px solid ${theme.common.primaryBorder}`};
      }
    }

    .ant-select-item-option-active{
      background-color: unset !important;
    }
      
    &::before {
      display: none !important;
    }
  }
`

const Container = styled.div`
  width: 100%;
`

const StyledSelect = styled(AntdSelect)<{
  $isError?: boolean
}>`
  width: 100%;
  border-radius: 16px;
  height: 56px !important;

  .ant-select-arrow {
    transition: transform 0.3s !important;
    svg {
      path {
        fill: ${({ theme }) => theme.common.primaryPlaceholder};
      }
    }
  }

  &.ant-select-open {
    .ant-select-arrow {
      transform: rotate(180deg) !important;
      transition: transform 0.3s !important;
    }
  }

  .ant-select-outlined {
    outline: none !important;
  }

  .ant-select-selector {
    outline: none !important;
    border-radius: 16px;
    height: 56px !important;
    padding-left: 15px;
    border: ${({ theme, $isError }) =>
      $isError
        ? `1px solid ${theme.common.dangerBorder}`
        : `1px solid ${theme.common.primaryBorder}`} !important;

    &:hover {
      border: ${({ theme, $isError }) =>
        $isError
          ? `1px solid ${theme.common.dangerBorder}`
          : `1px solid ${theme.common.thirdBorder}`} !important;
    }
  }

  .ant-select-selection-placeholder {
    color: ${({ theme }) => theme.common.primaryPlaceholder} !important;
    font-family: 'Segoe UI';
    line-height: 150%;
    font-size: ${variables.fontSize14};
    font-weight: ${variables.fontWeight400};
  }
`

const ErrorMessage = styled.div`
  font-family: 'Segoe UI';
  color: ${({ theme }) => theme.common.dangerText};
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight400};
  line-height: 150%;
  margin-top: 5px;
`

export type SelectProps = AntdSelectProps & {
  error?: string
}

export const PrimarySelect: FC<SelectProps> = (props) => {
  const { error } = props

  return (
    <Container>
      <StyledSelect
        {...props}
        popupClassName={dropdownClassName}
        menuItemSelectedIcon={<ArrowLeftIcon />}
        suffixIcon={<DownArrow />}
        $isError={Boolean(error)}
      />
      <ErrorMessage>{error}</ErrorMessage>
      <GlobalStyled />
    </Container>
  )
}
