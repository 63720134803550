import { Radio } from 'antd'
import { RadioProps } from 'antd/lib'
import styled from 'styled-components'

import { device } from 'src/Styled/variables'

export const PrimaryRadio = styled(({ className, ...rest }: RadioProps) => (
  <Radio {...rest} rootClassName={className} />
))`
  &.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
    border-color: ${({ theme }) => theme.common.secondaryBorder};
    background: ${({ theme }) => theme.common.fourthBg};
    &:after {
      background-color: ${({ theme }) => theme.common.secondaryBorder};
      box-shadow: none;
    }
  }
  &.ant-radio-wrapper .ant-radio-inner {
    border-color: ${({ theme }) => theme.common.primaryText};
  }
  &.ant-radio-wrapper-disabled {
    opacity: 0.5;
  }

  &.ant-radio-input {
    animation-duration: 0s !important;
  }
  &.ant-radio-inner {
    border-color: ${({ theme }) => theme.common.primaryText};
    background: transparent;
    animation-duration: 0s !important;

    &:after {
      background-color: ${({ theme }) => theme.common.secondaryBorder};
    }
  }

  &.ant-radio-input:focus + .ant-radio-inner,
  .ant-radio-input:hover + .ant-radio-inner {
    box-shadow: none;

    &:after {
      background-color: ${({ theme }) => theme.common.secondaryBorder};
      box-shadow: none;
    }
  }

  &:hover {
    .ant-radio-checked .ant-radio-inner {
      box-shadow: none;

      &:after {
        background-color: ${({ theme }) => theme.common.secondaryBorder};
        box-shadow: none;
      }
    }
  }

  @media ${device.mobileMax} {
    height: unset;
  }
`
