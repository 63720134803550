import { FC } from 'react'

import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { message } from 'antd'
import { yupResolver } from '@hookform/resolvers/yup'

import { FullWideNotification } from 'src/Components/FullWideNotification'
import Button from 'src/Components/Buttons/Button'
import { colors } from 'src/Styled/variables'
import { sendEmailToResetPassword } from 'src/Redux/auth-process/emailPasswordSlice/api-actions'
import { useAppDispatch } from 'src/Hooks/redux'

import { schema, FormData } from './helpers'

import { ButtonWrapper, StyledInput, ErrorText } from './styles'

export const ForgotPasswordForm: FC = () => {
  const { t } = useTranslation()
  const [messageApi, contextHolder] = message.useMessage()
  const dispatch = useAppDispatch()
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
  })

  const onSubmit = handleSubmit(({ email }) => {
    void dispatch(sendEmailToResetPassword(email))
      .unwrap()
      .then((res) => {
        void messageApi.open({
          content: (
            <FullWideNotification
              message={t('forgotPasswordPage.checkEmail')}
            />
          ),
          duration: 3,
          style: { backgroundColor: colors.badgeGreen },
        })
        return res
      })
      .catch(() => {
        void messageApi.open({
          content: (
            <FullWideNotification
              message={t('forgotPasswordPage.userNotFound')}
            />
          ),
          duration: 3,
          style: { backgroundColor: colors.badgeRed },
        })
      })
  })

  return (
    <form onSubmit={onSubmit}>
      <Controller
        name='email'
        control={control}
        defaultValue=''
        render={({ field: { onChange } }) => (
          <StyledInput
            {...register('email')}
            name='email'
            type='email'
            autoComplete='off'
            error={Boolean(errors?.email?.message)}
            placeholder={String(t('influencer.email'))}
            onChange={onChange}
          />
        )}
      />
      {errors.email?.message ? (
        <ErrorText>{errors.email?.message}</ErrorText>
      ) : null}
      <ButtonWrapper>
        <Button type='green' htmlType='submit'>
          {t('forgotPasswordPage.resetPassword')}
        </Button>
      </ButtonWrapper>
      {contextHolder}
    </form>
  )
}
