import { FC, useState } from 'react'

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { CollapsedSearchInput } from 'src/Components/CollapsedSearchInput'
import { Filter } from 'src/Components/Filter'
import { FilterByPlatformContent } from 'src/Components/Filter/FilterByPlatformContent'
import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import {
  CampaignsActionsProcessState,
  getCampaignsFilters,
  getCampaignsSearch,
  setCampaignsFilters,
  setCampaignsSearch,
} from 'src/Redux/campaignsActions-process'
import { MAX_MOBILE_WIDTH } from 'src/Styled/variables'

const ActionBlock = styled.div<{ $hasPadding: boolean }>`
  display: flex;
  align-items: center;
  gap: 4px;
  @media screen and (max-width: ${MAX_MOBILE_WIDTH}) {
    padding-right: ${({ $hasPadding }) => ($hasPadding ? '15px' : '0')};
  }
`

export const CampaignListHeaderActionBlock: FC = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const filters = useAppSelector(getCampaignsFilters)
  const search = useAppSelector(getCampaignsSearch)
  const [hasPadding, setHasPadding] = useState(false)

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    dispatch(setCampaignsSearch(e.target.value))
  }

  const handleSetFilters = (
    data: CampaignsActionsProcessState['filters'],
  ): void => {
    dispatch(setCampaignsFilters(data))
  }
  const count = Object.values(filters).filter((item) => item).length

  const handleFocusChange = (): void => {
    setHasPadding((prev) => !prev)
  }
  return (
    <ActionBlock $hasPadding={hasPadding}>
      <Filter
        count={count}
        renderContent={(hide) => (
          <FilterByPlatformContent
            filterState={filters}
            onApplyFilters={(data) => {
              hide()
              handleSetFilters(data)
            }}
          />
        )}
      />
      <CollapsedSearchInput
        onFocus={handleFocusChange}
        onBlur={handleFocusChange}
        value={search}
        placeholder={t('campaignsListPage.searchCampaign')}
        onChange={handleSearchChange}
      />
    </ActionBlock>
  )
}
