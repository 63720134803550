import { FunctionComponent, ReactElement } from 'react'

import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import StarYellowIcon from 'src/Assets/Svg/star-yellow.svg?react'
import StarGreyIcon from 'src/Assets/Svg/star-grey.svg?react'
import Button from 'src/Components/Buttons/Button'
import { getEventDetails } from 'src/Redux/influencer-process/selectors'
import { InfluencerEventStatus } from 'src/Types'

import { Container } from '../styles'

import { CanceledProps } from './types'

import {
  ButtonsWrapper,
  Title,
  RatingWrapper,
  Text,
  ReviewWrapper,
  Claimed,
} from './styles'

const renderRating = (rating: number): ReactElement[] => {
  const ratingArray = Array.from({ length: 5 })
  for (let i = 0; i < Number(rating); i += 1) {
    ratingArray[i] = true
  }
  return ratingArray?.map((el, index) =>
    el ? (
      <StarYellowIcon key={`${index.toString()}`} />
    ) : (
      <StarGreyIcon key={`${index.toString()}`} />
    ),
  )
}

export const Canceled: FunctionComponent<CanceledProps> = ({ onClose }) => {
  const { t } = useTranslation()
  const eventDetails = useSelector(getEventDetails)
  const stars = renderRating(eventDetails?.stars || 0)

  return (
    <Container>
      <ReviewWrapper>
        <div>
          <Title>{t('influencer.respondFromArtist')}</Title>
          <RatingWrapper>{stars}</RatingWrapper>
        </div>
        <div>
          <Title>{t('influencer.feedback')}</Title>
          <Text>{eventDetails?.feedback}</Text>
        </div>
      </ReviewWrapper>
      <Claimed>
        {eventDetails?.status === InfluencerEventStatus.DECLINED &&
          t('influencer.theArtistClaimedThisVideo')}
      </Claimed>
      <ButtonsWrapper>
        <Button type='white' onClick={onClose}>
          {t('influencer.closeBtn')}
        </Button>
      </ButtonsWrapper>
    </Container>
  )
}
