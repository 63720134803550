import { FunctionComponent, PropsWithChildren } from 'react'

import { useSelector } from 'react-redux'
import { IntercomProvider } from 'react-use-intercom'

import { APP } from 'src/Configs/App'
import { getUser } from 'src/Redux/auth-process/userSlice/selectors'

import { IntercomSetup } from '../IntercomSetup'

export const IntercomWrapper: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const user = useSelector(getUser)

  return (
    <IntercomProvider appId={APP.INTERCOM_APP_ID} initializeDelay={1000}>
      <IntercomSetup name={user.first_name || 'there'} />
      <div>{children}</div>
    </IntercomProvider>
  )
}
