import styled from 'styled-components'

import { colors } from 'src/Styled/variables'

export const MobileLoadingWrapper = styled.div`
  margin-top: 100px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`
export const MobileItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`
export const MobileInfoText = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: ${colors.greyTextWhiteBg};
  margin-top: 20px;
  text-align: center;
`
export const MobileCard = styled.div`
  background-color: ${colors.white};
  padding: 16px;
  border-radius: 4px;
`
export const MobileCardHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
`

export const IconWrapper = styled.div`
  margin-right: 8px;
  display: flex;
  margin-top: 3px;
  img {
    width: 16px;
    height: 16px;
  }
`
export const Title = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: ${colors.mainText};
`
export const MobileCardInfoWrapper = styled.div`
  padding-left: 24px;
`

export const InfoText = styled.span<{ color?: string }>`
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: ${({ color }) => (color ? color : colors.greyTextWhiteBg)};
`
export const MobileCardFooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  justify-content: space-between;
`
export const MobileParamItem = styled.div`
  display: flex;
  flex-direction: column;
`
export const MobileParamTitle = styled.span`
  font-weight: 400;
  font-size: 10px;
  line-height: 150%;
  color: ${colors.mainText};
`

export const Amount = styled.span<{ isRed?: boolean }>`
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: ${({ isRed }) => (isRed ? colors.badgeRed : colors.mainText)};
`
