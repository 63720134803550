import styled from 'styled-components'

import { variables, colors, device } from 'src/Styled/variables'

export const RateTableContainer = styled.div`
  width: 100%;
  padding-bottom: 30px;
  border-radius: 4px;
  position: relative;

  @media ${device.mobileMin} {
    background-color: ${colors.white};
    padding-bottom: 0px;
  }

  colgroup {
    width: 33.33%;
  }

  .ant-table-container {
    padding-left: 24px;
    padding-right: 24px;
  }

  .ant-table-thead .ant-table-cell {
    background-color: transparent;
    color: ${colors.mainText};
    padding: 16px 0px;
    font-weight: ${variables.fontWeight600};
  }

  .ant-table-cell {
    font-weight: ${variables.fontWeight400};
    font-size: ${variables.fontSize14};
    line-height: 150%;
    color: ${colors.mainText};
    height: 88px;
    padding: 16px 0px !important;
  }

  .ant-table-thead .ant-table-cell:nth-child(2) {
    text-align: center;
  }

  .ant-table-thead .ant-table-cell:last-child {
    text-align: center;
  }

  .ant-table-cell:nth-child(2) {
    text-align: center;
  }

  .ant-table-cell:last-child {
    text-align: center;
  }

  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(
      .ant-table-row-expand-icon-cell
    ):not([colspan])::before {
    display: none;
  }
`
