import { FC, useEffect } from 'react'

import { useSelector } from 'react-redux'
import { Col, message } from 'antd'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { FullWideNotification } from 'src/Components/FullWideNotification'
import IconLinkExpired from 'src/Assets/Svg/icon-link-expired.svg?react'
import { colors } from 'src/Styled/variables'
import Button from 'src/Components/Buttons/Button'
import { getUser } from 'src/Redux/auth-process/userSlice/selectors'
import {
  sendEmailToConfirmAccount,
  sendQuickLoginLink,
} from 'src/Redux/auth-process/emailPasswordSlice/api-actions'
import { useAppDispatch } from 'src/Hooks/redux'
import { useQuickLoginUTMParameters } from 'src/Hooks/useQuickLoginUTMParameters'

import { Link, Title, Body, ButtonWrapper, Container } from './style'

export enum ErrorPageTypeEnum {
  QuickLink = 'quick',
}

export const ExpiredLinkPage: FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [messageApi, contextHolder] = message.useMessage()
  const navigate = useNavigate()
  const utmParameters = useQuickLoginUTMParameters()

  const user = useSelector(getUser)

  const queryParams = new URLSearchParams(location.search)
  const errorType = queryParams.get('type')
  const userEmail = queryParams.get('email')

  useEffect(() => {
    if (errorType === ErrorPageTypeEnum.QuickLink && user.id) {
      navigate('/')
    }
  }, [errorType, navigate, user.id])

  const handleSendEmail = (): void => {
    if (errorType === ErrorPageTypeEnum.QuickLink) {
      userEmail &&
        void dispatch(
          sendQuickLoginLink({
            email: userEmail,
            utmParameters,
          }),
        )
    } else {
      void dispatch(sendEmailToConfirmAccount())
    }
    void messageApi.open({
      content: <FullWideNotification message={t('notification.checkEmail')} />,
      duration: 3,
      style: { backgroundColor: colors.badgeGreen },
      icon: null,
    })
  }

  return (
    <Container>
      {contextHolder}
      <Col>
        <Body>
          <IconLinkExpired />
          <Title>{t('errorsPage.linkHasExpired')}</Title>
          {t('errorsPage.tryingToUseExpiredLink')}
          <br />
          {t('errorsPage.expiresIn24Hours')}
          <br />
          {t('errorsPage.receiveNewLink')}
          <ButtonWrapper>
            <Button type='green' onClick={handleSendEmail}>
              {t('errorsPage.requestNewLink')}
            </Button>
          </ButtonWrapper>
          {t('errorsPage.ifIssuePersist')}{' '}
          <Link
            href='https://help.soundcamps.com/'
            rel='noopener noreferrer'
            target='_blank'
          >
            {t('errorsPage.thenLetUsKnow')}
          </Link>
        </Body>
      </Col>
    </Container>
  )
}
