import { FC, ReactElement, useState } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Popover } from 'antd'

import Button from 'src/Components/Buttons/Button'
import { Hint } from 'src/Components/Hint/Hint'
import { HintGuide } from 'src/Components/HintGuide/HintGuide'
import { Desktop, Mobile } from 'src/Components/Responsive'
import { useAppSelector } from 'src/Hooks/redux'
import {
  getIsArtist,
  getIsCreator,
} from 'src/Redux/auth-process/credentialsSlice/selectors'
import { getVerified } from 'src/Redux/auth-process/userSlice/selectors'
import IconQuestionMark from 'src/Assets/Svg/questionMark.svg?react'
import { colors, variables } from 'src/Styled/variables'

import AccountValidationModal from '../../AccountValidationModal'
import AccountValidation from '../../AccountValidation'

const ValidationBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
const ValidationText = styled.div`
  color: ${colors.messegesYellow1};
  margin-right: 8px;
`
const HintValidationPortal = styled(Popover)`
  .ant-popover-inner-content {
    background-color: red !important;
  }
`

const Content = styled.div`
  padding: 10px 0;
  color: ${colors.mainText};
  background: ${colors.white};
  font-size: ${variables.fontSize14};
  font-family: sans-serif;
  line-height: 1;
  width: 320px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
`

const GotItContainer = styled.div`
  margin: 0 auto;
  & > div {
    width: 160px;
  }
`

export const CuratorValidation: FC = () => {
  const { t } = useTranslation()
  const isArtist = useAppSelector(getIsArtist)
  const isCreator = useAppSelector(getIsCreator)
  const isVerified = useAppSelector(getVerified)

  const [modal, setModalValue] = useState(false)
  const [hintGuide, setHint] = useState<{
    component: ReactElement | null
    name: string | null
  }>({ component: null, name: null })

  const isOnValidation = !isArtist && !isVerified && !isCreator

  if (!isOnValidation) {
    return null
  }

  const handleSetHint = (hint: string): void => {
    if (hint === 'hintValidation') {
      setHint({ component: <AccountValidation />, name: 'hintValidation' })
    }

    if (hint === 'clearHint') {
      setHint({ component: null, name: null })
    }
  }
  const isHintGuide = Boolean(hintGuide && hintGuide.component)
  return (
    <ValidationBlock>
      <ValidationText>{t('sidebar.accountOnValidation')}</ValidationText>
      <Desktop>
        <HintGuide
          hintName='hintValidation'
          setHintName={handleSetHint}
          currentHint={hintGuide && hintGuide.name}
        />
        {isHintGuide && (
          <HintValidationPortal
            placement='rightTop'
            content={
              <Content>
                {hintGuide.component}
                <GotItContainer>
                  <Button
                    type='whiteWithGreenBorder'
                    onClick={() => handleSetHint('clearHint')}
                  >
                    {t('scheduleCampaignPage.gotIt')}
                  </Button>
                </GotItContainer>
              </Content>
            }
            open={isHintGuide}
          />
        )}
      </Desktop>

      <Mobile>
        <Hint onClick={() => setModalValue(!modal)}>
          <IconQuestionMark width={7} height={14} />
        </Hint>
        <AccountValidationModal isOpen={modal} toggle={setModalValue} />
      </Mobile>
    </ValidationBlock>
  )
}
