import { createDraftSafeSelector } from '@reduxjs/toolkit'

import { RootState } from 'src/Redux'

import { GetPremiumState } from '.'

const getState = (state: RootState): GetPremiumState => state.getPremium

export const getIsAlertClosed = createDraftSafeSelector(
  getState,
  ({ isAlertClosed }) => isAlertClosed,
)
