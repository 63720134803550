import { createDraftSafeSelector } from '@reduxjs/toolkit'

import i18n from 'src/Localization/i18n'

import { RootState } from '..'

import { RateProcessState } from '.'

const getState = (state: RootState): RateProcessState => state.rate

export const getRatesSelector = createDraftSafeSelector(getState, ({ rates }) =>
  rates.map((item) => ({
    rank: item.rank,
    rewardPerVideo: item.rewardPerVideo,
    followersRequired: item?.maxFollowersRequired
      ? `${item?.minFollowersRequired?.toLocaleString()} - ${item?.maxFollowersRequired?.toLocaleString()}`
      : `${item?.minFollowersRequired?.toLocaleString()} ${i18n.t(
          'rate.andMore',
        )}`,
    isUserPosition: item.isUserPosition,
  })),
)
export const getLevel = createDraftSafeSelector(getState, ({ rates }) => {
  if (rates.length) {
    return Number(rates.find((el) => el.isUserPosition)?.rank)
  }
  return 0
})
