import styled, { createGlobalStyle, css } from 'styled-components'

import { device, colors } from 'src/Styled/variables'

interface Props {
  isErrorOnMobile?: boolean
  isSame?: boolean
  show?: boolean
  isFAQ?: boolean
}

export const BtnWrapper = styled.div<Props>`
  display: ${(props: Props) => (props.show && props.isFAQ ? 'grid' : 'none')};
  justify-content: center;
  width: 40px;
  height: 40px;
  align-self: center;
  align-content: center;
  @media ${device.mobileMin} {
    display: none;
  }
`

export const BackButton = styled.div`
  cursor: pointer;
  width: 12px;
  height: 10px;
  &img {
    align-self: center;
  }
`

export const InputWrapper = styled.div<Props>`
  display: flex;
  gap: 8px;
  height: 58px;
  border-bottom: ${(props: Props) =>
    props.isFAQ ? '1px solid #f3f4f7' : 'none'};
  padding: 8px 0 8px 0;
  @media ${device.mobileMin} {
    grid-template-columns: none;
    border-bottom: none;
    padding: 0px;
    height: auto;
  }
`

export const GlobalStyled = createGlobalStyle<Props>`
  .ant-input {
    border: none !important;

    ${(props) =>
      !props.isFAQ &&
      css`
        border: 1px solid ${colors.greyStroke} !important;
      `}

    @media ${device.mobileMin} {
      border: 1px solid ${colors.greyStroke} !important;
    }
  }
`
