import { FC } from 'react'

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { variables } from 'src/Styled/variables'
import Alert from 'src/Components/Alert'

export const AlertText = styled.div`
  display: flex;
  flex-direction: column;
`
export const AlertTitle = styled.div`
  font-weight: ${variables.fontWeight600};
`

interface PayoutAlertsProps {
  isSubmitSuccessAlert: boolean
  isSubmitFailedAlert: boolean
  setIsSubmitSuccessAlert: (state: boolean) => void
  setIsSubmitFailedAlert: (state: boolean) => void
  errorMessage: string
}

export const PayoutAlerts: FC<PayoutAlertsProps> = (props) => {
  const { t } = useTranslation()
  const {
    errorMessage,
    isSubmitSuccessAlert,
    isSubmitFailedAlert,
    setIsSubmitFailedAlert,
    setIsSubmitSuccessAlert,
  } = props
  return (
    <>
      {isSubmitSuccessAlert && (
        <Alert
          color='success'
          icon
          onClose={() => setIsSubmitSuccessAlert(false)}
        >
          <AlertText>
            <AlertTitle>{t('settingsPage.transferwiseIsSetUp')}</AlertTitle>
            <div>{t('settingsPage.canRequestPayouts')}</div>
          </AlertText>
        </Alert>
      )}
      {isSubmitFailedAlert && (
        <Alert
          color='danger'
          icon
          onClose={() => setIsSubmitFailedAlert(false)}
        >
          <AlertTitle>{t('errorsPage.error')}</AlertTitle>
          <div>{errorMessage}</div>
        </Alert>
      )}
    </>
  )
}
