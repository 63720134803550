import { useState, useEffect } from 'react'

interface WindowDimensions {
  isMobile: boolean
  width: number
  height: number
}

const MAX_MOBILE_WIDTH = 768
export const getWindowDimensions = (): WindowDimensions => {
  const { innerWidth: width, innerHeight: height } = window
  return {
    isMobile: width < MAX_MOBILE_WIDTH,
    width,
    height,
  }
}

export const useWindowDimensions = (): WindowDimensions => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  )

  useEffect(() => {
    const handleResize = (): void => {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowDimensions
}
