import { FC, ReactNode } from 'react'

import styled from 'styled-components'
import dayJs from 'dayjs'
import { useTranslation } from 'react-i18next'

import { MAX_MOBILE_WIDTH } from 'src/Styled/variables'
import { formatDate } from 'src/Constants/formatDate'
import { CampaignPlatformTypeEnum, CampaignStatusEnum } from 'src/Types'
import { capitalizeFirstLetter } from 'src/Helpers/layoutHelpers'

import { CampaignTrackDetails } from '../CampaignListItem/CampaignTrackDetails'
import { CampaignStatus } from '../CampaignStatus'

const StyledSummarySection = styled.div`
  padding: 24px;
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.common.thirdText};
  background-color: ${({ theme }) => theme.common.primaryBg};
  box-shadow: 0px 0px 32px 0px rgba(51, 51, 51, 0.08);
  width: 100%;

  @media (max-width: ${MAX_MOBILE_WIDTH}) {
    padding: 0px;
    max-width: 358px;
    background-color: transparent;
    box-shadow: none;
    border-radius: 0;
    border: none;
  }
`
const SummarySectionBlock = styled.div`
  display: grid;
  grid-template-columns: 68px 1fr;
  column-gap: 16px;
  margin-top: 24px;
`

const SummaryDateInfo = styled.div`
  font-family: 'Segoe UI';
  color: ${({ theme }) => theme.common.primaryText};
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
`

const RightBlock = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`

const GenresSection = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.common.primaryText};
`

const Title = styled.div`
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  color: ${({ theme }) => theme.common.primaryText};
  display: -webkit-box;
  flex: 1;
  font-family: 'Segoe UI';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  margin-top: -1px;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
`

const GenresList = styled.span`
  word-break: break-word;
  max-width: 190px;
`

const ButtonsBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 272px;
  margin-top: 24px;
  @media (max-width: ${MAX_MOBILE_WIDTH}) {
    max-width: 358px;
  }
`
export interface TrackInfoProps {
  platform: CampaignPlatformTypeEnum
  trackImage?: string
  trackName?: string
  artistName?: string
  status?: CampaignStatusEnum
  statusText?: string
  startDate?: string
  endDate?: string
  genres?: Array<string>
  children?: ReactNode
}
const pendingStatuses = [
  CampaignStatusEnum.PENDING_APPROVAL,
  CampaignStatusEnum.AWAITING_REVIEW,
]

const approvedStatuses = [
  CampaignStatusEnum.APPROVED,
  CampaignStatusEnum.PENDING,
]

const scheduledStatuses = [CampaignStatusEnum.SCHEDULED]

const runningStatuses = [
  CampaignStatusEnum.RUNNING,
  CampaignStatusEnum.INPROCESS,
]

export const endedStatuses = [
  CampaignStatusEnum.ENDED,
  CampaignStatusEnum.FINISHED,
  CampaignStatusEnum.CANCELED,
  CampaignStatusEnum.STOPPED,
  CampaignStatusEnum.CANCELED_BY_ADMIN,
  CampaignStatusEnum.STOPPED_BY_ADMIN,
]

const getStatusText = (
  status: CampaignStatusEnum,
  startDate?: string,
  endDate?: string,
): string => {
  if (pendingStatuses.includes(status)) {
    return 'Takes up to 45 min'
  }
  if (approvedStatuses.includes(status)) {
    return ''
  }
  if (scheduledStatuses.includes(status)) {
    return `Will start on ${dayJs(startDate).format(formatDate.formatDateMonthFull)}`
  }
  if (runningStatuses.includes(status)) {
    return `Started on ${dayJs(startDate).format(formatDate.formatDateMonthFull)}`
  }
  if (endedStatuses.includes(status)) {
    return `${dayJs(startDate).format(formatDate.formatDateMonthFull)} - ${dayJs(endDate).format(formatDate.formatDateMonthFull)}`
  }
  return ''
}

export const TrackInfo: FC<TrackInfoProps> = (props) => {
  const {
    platform,
    trackImage,
    trackName,
    artistName,
    status,
    startDate,
    endDate,
    genres,
    children,
  } = props

  const { t } = useTranslation()

  const statusText = status && getStatusText(status, startDate, endDate)

  const genresList = genres?.map(
    (el, id) =>
      `${capitalizeFirstLetter(el)}${id === genres.length - 1 ? '' : ', '}`,
  )
  return (
    <StyledSummarySection>
      <CampaignTrackDetails
        platform={platform}
        trackName={trackName}
        trackImage={trackImage}
        artistName={artistName}
      />
      <SummarySectionBlock>
        <div />

        <RightBlock>
          {genres && genres?.length > 0 ? (
            <GenresSection>
              <Title>{t('components.genres')}</Title>
              <GenresList>{genresList}</GenresList>
            </GenresSection>
          ) : null}

          {status && (
            <div>
              <CampaignStatus status={status} />
              <SummaryDateInfo>{statusText}</SummaryDateInfo>
            </div>
          )}
        </RightBlock>
      </SummarySectionBlock>
      {children && <ButtonsBlock>{children}</ButtonsBlock>}
    </StyledSummarySection>
  )
}
