import styled from 'styled-components'

import { variables, colors } from 'src/Styled/variables'

export const StyledInfoCard = styled.div`
  width: 100%;
  height: 65px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  background-color: ${colors.white};
  box-sizing: border-box;
`

export const Title = styled.span`
  color: ${colors.mainText};
  font-size: ${variables.fontSize14};
  line-height: 150%;
  font-weight: ${variables.fontWeight400};
`

export const Value = styled.span<{ type: 'regular' | 'green' }>`
  color: ${({ type }) =>
    type === 'green' ? colors.messegesGreen1 : colors.mainText};
  font-size: ${variables.fontSize20};
  line-height: 150%;
  font-weight: ${variables.fontWeight600};
  white-space: nowrap;
`
