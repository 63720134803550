import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'

import { APP } from 'src/Configs/App'
import { ErrorCode, NameSpace } from 'src/Redux/types'
import { api } from 'src/Services/api'
import { User } from 'src/Types'

interface SearchUsersResponse {
  users: Array<User>
}

export const searchUsers = createAsyncThunk<SearchUsersResponse, string>(
  `${NameSpace.SearchUsers}/searchUsers`,
  async (searchString, { rejectWithValue }) => {
    try {
      const { data } = await api.post<SearchUsersResponse>(
        `${APP.SERVER}/admin/users/search`,
        {
          search_string: searchString,
        },
      )

      return data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      return rejectWithValue({
        message: response?.data.message,
        code: response?.status || ErrorCode.InternalServerError,
      })
    }
  },
)
