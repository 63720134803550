import styled from 'styled-components'

import { variables, colors, device } from 'src/Styled/variables'

export const CheckoutMobileFooterButtonsContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  background: ${colors.white};
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.25);
  display: grid;
  grid-template-columns: 1fr 170px;

  @media ${device.tabletMin} {
    display: none;
  }
`

export const TotalContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const TotalLabel = styled.span`
  font-size: ${variables.fontSize10};
  color: ${colors.mainText};
`

export const TotalSum = styled.span`
  font-size: ${variables.fontSize14};
  line-height: 150%;
  font-weight: ${variables.fontWeight600};
  color: ${colors.mainText};
`
