import { createDraftSafeSelector, createSlice } from '@reduxjs/toolkit'

import { NameSpace } from '../types'
import { RootState } from '..'

export interface SessionNotificationState {
  languageModalShown: boolean
}

const initialState: SessionNotificationState = {
  languageModalShown: false,
}

export const sessionNotificationsProcess = createSlice({
  initialState,
  name: NameSpace.SessionNotifications,
  reducers: {
    markLangModalAsShown: (state) => {
      state.languageModalShown = true
    },
  },
})

const getState = (state: RootState): SessionNotificationState =>
  state.sessionNotifications

export const getIsLangModalShown = createDraftSafeSelector(
  getState,
  ({ languageModalShown }) => languageModalShown,
)
export const { markLangModalAsShown } = sessionNotificationsProcess.actions
