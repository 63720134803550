import { FC } from 'react'

import { useTranslation } from 'react-i18next'

import {
  payment,
  payout,
  rewardWithPlacement,
  rewardWithoutPlacement,
  withdraw,
} from 'src/Constants/constants'

interface StatusCellProps {
  type: string
}

export const StatusCell: FC<StatusCellProps> = ({ type }) => {
  const { t } = useTranslation()

  const rewardReceived = t('balancePage.rewardReceived')

  const MAP_STATUS_CELL: Record<string, string> = {
    [payment]: rewardReceived,
    [rewardWithPlacement]: rewardReceived,
    [rewardWithoutPlacement]: rewardReceived,
    [payout]: t('balancePage.payoutApproved'),
    [withdraw]: t('balancePage.rewardCanceled'),
    default: t('balancePage.payoutCanceled'),
  }

  return <span>{MAP_STATUS_CELL[type] || MAP_STATUS_CELL.default}</span>
}
