import { createAsyncThunk } from '@reduxjs/toolkit'
import axios, { AxiosError } from 'axios'

import { CampaignPlatformTypeEnum, TikTokTrack } from 'src/Types'
import { api } from 'src/Services/api'
import { APP } from 'src/Configs/App'
import { API_TIMEOUT_15000 } from 'src/Constants/constants'

import { ErrorCode, NameSpace } from '../types'

import { getTiktokTrackErrorMessage } from './utils'

interface SubmitTikTokSearchTrackErrorBody {
  platformType: CampaignPlatformTypeEnum
  pageType: string
  trackUrl: string
  email?: string
  errorMessage: string
}

export const submitTikTokSearchTrackError = createAsyncThunk<
  null,
  SubmitTikTokSearchTrackErrorBody
>(
  `${NameSpace.TikTokTrack}/submitTikTokSearchTrackError`,
  async (
    { platformType, trackUrl, pageType, email, errorMessage },
    { rejectWithValue },
  ) => {
    try {
      await axios.post(
        'https://hook.eu1.make.com/hejbyeyajt27jg5u14rgxff7gtq6w4oi',
        {
          platformType,
          trackUrl,
          pageType,
          email,
          errorMessage,
        },
      )

      return null
    } catch (error) {
      const { message, response } = error as AxiosError
      return rejectWithValue({
        message,
        code: response?.status || ErrorCode.InternalServerError,
      })
    }
  },
)

interface GetTikTokTrackBody {
  search: string
}

export const getTikTokTrack = createAsyncThunk<TikTokTrack, GetTikTokTrackBody>(
  `${NameSpace.TikTokTrack}/getTikTokTrack`,
  async ({ search }, { rejectWithValue }) => {
    try {
      const { data } = await api.get<TikTokTrack>(
        `${APP.TIKTOK_SERVER}/artist/tiktok/tiktok-track-info`,
        {
          params: {
            search,
          },
        },
      )

      if (data.isOriginalSound) {
        throw new Error('notTheOfficial')
      }
      return data
    } catch (error) {
      const { message, response } = error as AxiosError
      return rejectWithValue({
        message: getTiktokTrackErrorMessage(message, response?.status),
        code: response?.status || ErrorCode.InternalServerError,
      })
    }
  },
)
interface SearchTikTokTrackByNameBody {
  searchText: string
}

export const searchTikTokTrackByName = createAsyncThunk<
  TikTokTrack,
  SearchTikTokTrackByNameBody
>(
  `${NameSpace.TikTokTrack}/searchTikTokTrackByName`,
  async ({ searchText }, { rejectWithValue }) => {
    try {
      const { data } = await api.get<TikTokTrack>(
        `${APP.TIKTOK_SERVER}/no_auth/artist/tiktok/music/search`,
        {
          params: {
            searchText,
          },
          timeout: API_TIMEOUT_15000,
        },
      )

      if (data.music && data.music.length === 0) {
        throw new Error('noTracksWereFound')
      }
      return data
    } catch (error) {
      const { message, response } = error as AxiosError
      return rejectWithValue({
        message:
          searchText && getTiktokTrackErrorMessage(message, response?.status),
        code: response?.status || ErrorCode.InternalServerError,
      })
    }
  },
)

interface GetTikTokTrackWithoutAuthBody {
  searchText: string
}

export const getTikTokTrackWithoutAuth = createAsyncThunk<
  TikTokTrack,
  GetTikTokTrackWithoutAuthBody
>(
  `${NameSpace.TikTokTrack}/getTikTokTrackWithoutAuth`,
  async ({ searchText }, { rejectWithValue }) => {
    try {
      const { data } = await api.get<TikTokTrack>(
        `${APP.TIKTOK_SERVER}/no_auth/artist/tiktok/tiktok-track-info`,
        {
          params: {
            search: searchText,
          },
        },
      )

      if (data.isOriginalSound) {
        throw new Error('notTheOfficial')
      }
      return data
    } catch (error) {
      const { message, response } = error as AxiosError
      return rejectWithValue({
        message: getTiktokTrackErrorMessage(message, response?.status),
        code: response?.status || ErrorCode.InternalServerError,
      })
    }
  },
)
