import React, { FC } from 'react'

import Skeleton from 'react-loading-skeleton'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import {
  LanguagesGenresList,
  ParametersButton,
  SubTitle,
} from 'src/Containers/ScheduleSpotifyWrapper/components/SpotifySettings/TrackParametrs/styles'
import { useAppSelector } from 'src/Hooks/redux'
import PlusIcon from 'src/Assets/Svg/plus.svg?react'
import { getScheduledTikTokCampaign } from 'src/Redux/schedule-process/tiktokCampaign/selectors'
import { ScheduleSteps } from 'src/Components/ScheduleTabs'
import { ModalType } from 'src/Containers/ScheduleSpotifyWrapper/components/ModalsContainer'
import { capitalizeFirstLetter } from 'src/Helpers/layoutHelpers'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import { device } from 'src/Styled/variables'

const GenresParametersContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
  padding-left: 16px;
  @media ${device.tabletMin} {
    display: grid;
    grid-template-columns: 1fr 72px;
    align-items: center;
    gap: 16px;
    justify-content: center;
  }
`

export const GenresParameters: FC = () => {
  const { t } = useTranslation()
  const scheduledCampaign = useAppSelector(getScheduledTikTokCampaign)
  const { setSearch } = useCustomSearchParams()

  const handleClick = (): void => {
    const query = {
      step: ScheduleSteps.SETTINGS,
      modal: ModalType.Genres,
    }
    setSearch(query)
  }
  if (!scheduledCampaign.genres.length) {
    return (
      <ParametersButton onClick={handleClick}>
        <PlusIcon />
        {t('scheduleCampaignPage.addTrackGenres')}
      </ParametersButton>
    )
  }

  const genresString = scheduledCampaign.genres
    .map((genre) => capitalizeFirstLetter(genre.label || ''))
    .join(', ')

  return (
    <GenresParametersContainer>
      <div>
        <SubTitle>{t('scheduleCampaignPage.trackGenres')}</SubTitle>
        {!genresString ? (
          <Skeleton count={30} height={50} />
        ) : (
          <LanguagesGenresList translate='no'>
            {genresString}
          </LanguagesGenresList>
        )}
      </div>
      <ParametersButton onClick={handleClick}>
        {t('scheduleCampaignPage.edit')}
      </ParametersButton>
    </GenresParametersContainer>
  )
}
