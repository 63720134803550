import { FC, useEffect } from 'react'

import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { closeCampaignAlert } from 'src/Redux/canceledAlerts'
import { getCampaignsCanceledAlertsToShow } from 'src/Redux/canceledAlerts/selectors'
import Alert from 'src/Components/Alert'
import { getTikTokCanceledEvents } from 'src/Redux/canceledAlerts/api-actions'

import { TrackAlertTitle, TrackAlertDescription } from './styles'

export const TracksAlerts: FC = () => {
  const dispatch = useAppDispatch()

  const canceledAlerts = useAppSelector(getCampaignsCanceledAlertsToShow)

  const handleClose = (id: number): void => {
    dispatch(closeCampaignAlert(id))
  }
  useEffect(() => {
    void dispatch(getTikTokCanceledEvents())
  }, [dispatch])

  return (
    <>
      {canceledAlerts.map((alert) => {
        const { trackTitle, trackAuthorName, id } = alert
        const title = `Campaign for “${trackTitle}” canceled`
        const description = `Unfortunately, the artist ${trackAuthorName} requested the cancellation of the “${trackTitle}” campaign before its completion. As a result, the campaign has been canceled by an admin.`

        return (
          <Alert key={id} color='warning' icon onClose={() => handleClose(id)}>
            <TrackAlertTitle>{title}</TrackAlertTitle>
            <TrackAlertDescription>{description}</TrackAlertDescription>
          </Alert>
        )
      })}
    </>
  )
}
