import styled from 'styled-components'

import { variables, colors, device } from 'src/Styled/variables'

export const RewardDetailsSubTitle = styled.h4`
  margin-bottom: 10px;
  color: ${colors.mainText};
  font-weight: ${variables.fontWeight600};
  font-size: ${variables.fontSize18};
  line-height: 27px;
  @media ${device.mobileMin} {
    margin-bottom: 23px;
  }
`

export const PageContentContainer = styled.div`
  width: 100%;
  padding: 24px 24px 8px 24px;
  background-color: red;
  @media ${device.mobileMin} {
    padding: 24px 24px 8px 24px;
  }

  margin-bottom: 16px;
  border-radius: 16px;
  border: 1px solid ${(props) => props.theme.common.primaryBorder};
  background-color: ${(props) => props.theme.common.primaryBg};
  box-shadow: 0px 0px 32px 0px rgba(51, 51, 51, 0.08);
`
export const OuterWrapper = styled.div`
  display: grid;
  gap: 8px;
  grid-template-columns: auto 1fr;

  @media ${device.mobileMin} {
    grid-template-columns: none;
  }
`

export const Wrapper = styled.div`
  display: grid;
  gap: 16px;

  @media ${device.mobileMin} {
    grid-auto-flow: column;
  }
`

export const DotsBlock = styled.div`
  display: grid;
  justify-items: center;
  grid-template-rows: auto 1fr auto 1fr auto;
  gap: 4px;
  padding: 16px 0px;
  @media ${device.mobileMin} {
    grid-template-columns: auto 1fr auto 1fr auto;
    align-items: center;
    order: 999;
    padding: 0px 8px;
    gap: 4px;
  }
`
export const DotOuter = styled.div<{ $isActive: boolean }>`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  position: relative;
  display: flex;
  background-color: ${({ $isActive }) =>
    $isActive ? colors.green1Light : colors.greyBg};
  align-items: center;
  justify-content: center;
`

export const DotInner = styled.div<{ $isActive: boolean }>`
  width: 6px;
  height: 6px;
  background-color: ${({ $isActive }) =>
    $isActive ? colors.green : colors.greyTextWhiteBg};
  border-radius: 50%;
`

export const Arrow = styled.div`
  width: 12px;
  height: 12px;
  transform: rotate(45deg);
  background-color: #f9f9f9;
  position: absolute;
  right: -16px;

  @media ${device.mobileMin} {
    right: -0px;
    top: -16px;
  }
`

export const Stick = styled.div<{ $percent: number }>`
  width: 1px;
  height: 100%;
  background: rgb(28, 185, 84);
  background: linear-gradient(
    180deg,
    rgba(28, 185, 84, 1) ${({ $percent }) => $percent}%,
    rgba(213, 214, 217, 1) ${({ $percent }) => $percent}%
  );
  @media ${device.mobileMin} {
    width: 100%;
    height: 1px;
    background: linear-gradient(
      90deg,
      rgba(28, 185, 84, 1) ${({ $percent }) => $percent}%,
      rgba(213, 214, 217, 1) ${({ $percent }) => $percent}%
    );
  }
`

export const InnerWrapperFirst = styled.div`
  background-color: ${colors.greyBg};
  border-radius: 4px;
  padding: 2px 8px 4px 8px;
  @media ${device.mobileMin} {
    justify-self: start;
  }
`
export const InnerWrapperSecond = styled.div`
  background-color: ${colors.greyBg};
  border-radius: 4px;
  padding: 2px 8px 4px 8px;
  @media ${device.mobileMin} {
    justify-self: center;
  }
`
export const InnerWrapperLast = styled.div`
  background-color: ${colors.greyBg};
  border-radius: 4px;
  padding: 2px 8px 4px 8px;
  @media ${device.mobileMin} {
    justify-self: end;
  }
`

export const ReferAndEarnDetailTitle = styled.div`
  font-size: ${variables.fontSize10};
  font-weight: ${variables.fontWeight400};
  line-height: 15px;
`

export const ReferAndEarnDetailText = styled.div`
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight400};
  line-height: 21px;
`
export const InviteANDBonusBlocks = styled.div`
  @media (min-width: 1279px) {
    display: flex;
    gap: 16px;
  }
`
