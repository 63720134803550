import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'

import { APP } from 'src/Configs/App'
import { ErrorCode, NameSpace } from 'src/Redux/types'
import { api } from 'src/Services/api'

import { ReferralUser } from '.'

export const getReferralUserList = createAsyncThunk(
  `
${NameSpace.ReferAndEarn}/getReferralList`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await api.get<ReferralUser[]>(
        `${APP.TIKTOK_SERVER}/user/referral/statistics`,
      )
      return data
    } catch (error) {
      const { message, response } = error as AxiosError
      return rejectWithValue({
        message,
        code: response?.status || ErrorCode.InternalServerError,
      })
    }
  },
)
