import { FC, useState } from 'react'

import { useTranslation } from 'react-i18next'

import Button from 'src/Components/Buttons/Button'
import PlusIcon from 'src/Assets/Svg/plus.svg?react'

import { SearchPlaylistMobileModal } from './SearchPlaylistMobileModal/SearchPlaylistMobileModal'

import { ButtonBody, IconContainer, Wrapper } from './styles'

const FindPlaylistMobile: FC = () => {
  const [modal, setModalValue] = useState<boolean>(false)
  const toggle = (): void => {
    setModalValue(!modal)
  }
  const { t } = useTranslation()

  return (
    <Wrapper>
      <Button type='white' onClick={toggle}>
        <ButtonBody>
          <IconContainer>
            <PlusIcon data-testid='plus-icon' />
          </IconContainer>
          {t('playlistsPage.addManually')}
        </ButtonBody>
      </Button>
      {modal && <SearchPlaylistMobileModal modal={modal} toggle={toggle} />}
    </Wrapper>
  )
}

export default FindPlaylistMobile
