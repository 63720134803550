import React, { FC, useMemo } from 'react'

import styled from 'styled-components'
import { Rate } from 'antd'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { colorPalette, MAX_MOBILE_WIDTH, variables } from 'src/Styled/variables'
import dayJs from 'src/Helpers/dayjs'
import { formatDate } from 'src/Constants/formatDate'
import { PrimaryButton } from 'src/Components/Buttons/PrimaryButton'
import { TextArea } from 'src/Components/TextArea'

const Container = styled.div`
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;

  border-radius: 16px;
  border: 1px solid ${(props) => props.theme.common.primaryBorder};
  background-color: ${(props) => props.theme.common.primaryBg};
  box-shadow: 0px 0px 32px 0px rgba(51, 51, 51, 0.08);

  @media (max-width: ${MAX_MOBILE_WIDTH}) {
    box-shadow: none;
    border: none;
    padding: 0;
  }
`

const HeaderTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`

const HeaderWrapper = styled.div`
  display: flex;
  gap: 16px;
  align-self: stretch;
  align-items: flex-end;
  justify-content: space-between;
`

const Title = styled.span`
  font-family: 'Segoe UI';
  color: ${({ theme }) => theme.common.primaryText};
  text-align: center;
  font-size: ${variables.fontSize18};
  font-style: normal;
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
`

const Text = styled.span`
  font-family: 'Segoe UI';
  color: ${({ theme }) => theme.common.primaryText};

  font-size: ${variables.fontSize14};
  font-style: normal;
  font-weight: ${variables.fontWeight400};
  line-height: 150%;
`

const StyledButton = styled(PrimaryButton)`
  align-self: center;
  padding-left: 48px;
  padding-right: 48px;
`
export interface RateFormData {
  rate: number
  comment: string
}

export interface RateBlockProps {
  rate?: number
  rateDeadline: string
  comment?: string
  onSubmit: (value: RateFormData) => void
  isRateLoading?: boolean
  loading?: boolean
}

export const RateBlock: FC<RateBlockProps> = ({
  rate,
  comment,
  rateDeadline,
  onSubmit,
  isRateLoading,
  loading,
}) => {
  const { t } = useTranslation()
  const { control, handleSubmit, watch } = useForm<RateFormData>()
  const currentRate = watch('rate')
  const currentComment = watch('comment')
  const isRateDatePassed = dayJs().isAfter(rateDeadline)

  const isReadyToRate = useMemo(
    () => Boolean(currentRate) && !rate,
    [currentRate, rate],
  )

  const dateText = useMemo(() => {
    if (rate) {
      return `${t('campaignDetailsPage.videoRated')}`
    }

    if (!rate && isRateDatePassed) {
      return `${t('campaignDetailsPage.ratingDeadlineWas')} ${dayJs(rateDeadline).format(formatDate.formatDateFirst2)}`
    }

    if (!rate && !isRateDatePassed) {
      return `${t('campaignDetailsPage.RateThisVideoTill')} ${dayJs(rateDeadline).format(formatDate.formatDateFirst2)}`
    }

    return ''
  }, [isRateDatePassed, rate, rateDeadline, t])

  const currentRateStars = useMemo(
    () =>
      rate ? (
        <Rate
          defaultValue={rate}
          disabled
          style={{
            fontSize: '14px',
            color: colorPalette.mediumOrange,
          }}
        />
      ) : null,
    [rate],
  )

  const rateStars = useMemo(
    () =>
      !rate ? (
        <Controller
          name='rate'
          control={control}
          render={({ field }) => (
            <Rate
              {...field}
              style={{ color: colorPalette.mediumOrange }}
              onChange={(value) => {
                field.onChange(value)
              }}
              allowClear={false}
            />
          )}
        />
      ) : null,
    [control, rate],
  )

  const placeholder =
    currentRate > 2
      ? t('campaignDetailsPage.ratePositivePlaceholder')
      : t('campaignDetailsPage.rateNegativePlaceholder')

  if (loading) {
    return null
  }

  return (
    <Container>
      <HeaderWrapper>
        <HeaderTextWrapper>
          <Title>{t('campaignDetailsPage.rate')}</Title>
          <Text>{dateText}</Text>
        </HeaderTextWrapper>

        {currentRateStars}
      </HeaderWrapper>
      {rateStars}
      {comment && <Text>{comment}</Text>}
      {isReadyToRate && (
        <Controller
          name='comment'
          control={control}
          render={({ field }) => (
            <TextArea
              style={{ height: 180, resize: 'none' }}
              placeholder={placeholder}
              count={{
                show: true,
                max: 500,
              }}
              {...field}
              onChange={(value) => {
                field.onChange(value)
              }}
            />
          )}
        />
      )}
      {isReadyToRate && onSubmit && (
        <StyledButton
          onClick={handleSubmit(onSubmit)}
          disabled={!currentComment}
          loading={isRateLoading}
        >
          {t('campaignDetailsPage.confirmRate')}
        </StyledButton>
      )}
    </Container>
  )
}
