import { Middleware, PayloadAction } from '@reduxjs/toolkit'

import { campaignSentToReviewEvent } from 'src/Helpers/TagManager'
import { CampaignPlatformTypeEnum, CampaignTypeEnum } from 'src/Types'

import rootReducer from '../root-reducer'
import { RootState } from '../index'

type Reducer = ReturnType<typeof rootReducer>

type MetaType = {
  requestStatus: 'pending' | 'fulfilled' | 'rejected'
  arg?: {
    trackSpotifyId?: string
    trackTiktokId?: string
  }
}

type PayloadType = {
  code: number
  message: string
  type: CampaignTypeEnum
  tiktok_campaign?: boolean
}

const campaignSendToReviewActions = [
  'addNewCampaign',
  'createCampaignWithoutSignup',
  'addNewTikTokCampaign',
]

export const sentToReviewMiddleware: Middleware<unknown, Reducer> =
  (store) =>
  (next) =>
  (action: PayloadAction<PayloadType, string, MetaType>) => {
    const { authentication, campaigns } = store.getState() as RootState
    const { user } = authentication.user
    const { amountOfCampaigns } = campaigns.main
    const campaignNumber = amountOfCampaigns + 1
    const userId = user?.id ? user.id : user?.curatorId
    const isFirstCampaign = user?.is_first_campaign || amountOfCampaigns === 0
    const campaignType = isFirstCampaign ? 'first' : 'basic'

    const isCampaignSentToReviewEventAction = (actionType: string): boolean =>
      campaignSendToReviewActions.some((sendToReviewActions) =>
        actionType.includes(sendToReviewActions),
      )

    if (
      action.meta?.requestStatus === 'fulfilled' &&
      isCampaignSentToReviewEventAction(action.type)
    ) {
      const isTikTokPlatform =
        action.type.split('/')[1] === 'addNewTikTokCampaign'

      campaignSentToReviewEvent({
        userId,
        campaignNumber,
        campaignType: action.payload?.type || campaignType,
        platformName:
          isTikTokPlatform || Boolean(action.payload.tiktok_campaign)
            ? CampaignPlatformTypeEnum.TIKTOK
            : CampaignPlatformTypeEnum.SPOTIFY,
      })
    }

    return next(action)
  }
