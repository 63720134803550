import styled from 'styled-components'

import { colors, device } from 'src/Styled/variables'

export const CalculationWrapper = styled.div`
  display: flex;
  gap: 2px;
  justify-content: space-between;
  @media ${device.mobileMax} {
    flex-direction: column;
    justify-content: baseline;
  }
`
export const Block = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2px;
`

export const Label = styled.span`
  color: ${colors.mainText};
`
export const Value = styled.span`
  color: ${colors.greyTextWhiteBg};
`

export const Card = styled.div`
  display: flex;
  padding: 9.5px 16px;
  align-items: center;
  justify-content: space-between;
  background-color: #f9f9f9;
`
