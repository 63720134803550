import styled from 'styled-components'

import { variables, colors } from 'src/Styled/variables'

export const StyledCampaignEndedShowDate = styled.div`
  font-size: ${variables.fontSize10};
  font-weight: ${variables.fontWeight400};
  line-height: 150%;
  padding-top: 4px;
  color: ${colors.mainText};
`
