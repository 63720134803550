import { FC, useState } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { variables } from 'src/Styled/variables'
import artist1 from 'src/Assets/Png/artist1.png'
import artist2 from 'src/Assets/Png/artist2.png'
import artist3 from 'src/Assets/Png/artist3.png'
import i18n from 'src/Localization/i18n'

import { Review } from './Components/Review'

export const Container = styled.div`
  display: flex;
  max-width: 800px;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  width: 100%;
`

export const Header = styled.h3`
  margin: 0;
  padding: 0;
  color: ${({ theme }) => theme.common.primaryText};
  font-size: ${variables.fontSize20};
  font-style: normal;
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
  align-self: flex-start;
`
export const ReviewsList = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  gap: 32px;
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
`

const whatArtistsThinkItems = [
  {
    id: 1,
    userImageUrl: artist1,
    name: 'Alessio Cappello',
    stars: 5,
    comment: i18n.t('whatArtistsThink.firstComment'),
    link: 'https://www.trustpilot.com/reviews/63bc43e32338b6d417b48894',
  },
  {
    id: 2,
    userImageUrl: artist2,
    name: 'Annie Davidson',
    stars: 5,
    comment: i18n.t('whatArtistsThink.secondComment'),
    link: 'https://www.trustpilot.com/reviews/6442b64d44f903622819a0f5',
  },
  {
    id: 3,
    userImageUrl: artist3,
    name: 'Andy Fox',
    stars: 5,
    comment: i18n.t('whatArtistsThink.thirdComment'),
    link: 'https://www.trustpilot.com/reviews/63cc09ee4b01fb3f5488dd23 ',
  },
]

export const WhatArtistsThink: FC = () => {
  const { t } = useTranslation()

  const [activeComment, setActiveComment] = useState<null | number>(null)

  const onShowFullReview = (reviewId: number): void => {
    if (activeComment === reviewId) {
      setActiveComment(null)
      return
    }
    setActiveComment(reviewId)
  }

  return (
    <Container>
      <Header>{t('whatArtistsThink.whatArtistsThink')}</Header>
      <ReviewsList>
        {whatArtistsThinkItems.map((item) => (
          <Review
            key={item.id}
            id={item.id}
            userImageUrl={item.userImageUrl}
            name={item.name}
            comment={item.comment}
            link={item.link}
            isActive={activeComment === item.id}
            isHidden={activeComment !== item.id && activeComment !== null}
            onShowFullReview={onShowFullReview}
          />
        ))}
      </ReviewsList>
    </Container>
  )
}
