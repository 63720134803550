import { FC } from 'react'

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { CampaignPlatformTypeEnum } from 'src/Types'
import { MAX_MOBILE_WIDTH, MAX_TABLET_WIDTH } from 'src/Styled/variables'

import { ScheduleSummaryDetails } from './ScheduleSummaryDetails'
import { ScheduleSummaryPromocode } from './ScheduleSummaryPromocode'
import { ScheduleSummaryButton } from './ScheduleSummaryButton'
import { ArtistProtectionProgram } from './ArtistProtectionProgram'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.common.primaryBorder};
  background: ${({ theme }) => theme.common.primaryBg};
  box-shadow: 0px 0px 32px 0px rgba(51, 51, 51, 0.08);
  @media screen and (min-width: ${MAX_TABLET_WIDTH}) {
    width: 400px;
  }
`

const Title = styled.div`
  color: ${({ theme }) => theme.common.primaryText};
  font-family: 'Segoe UI';
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
`

const Wrapper = styled.div<{ $showTooltip: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  @media screen and (max-width: ${MAX_MOBILE_WIDTH}) {
    padding-bottom: ${({ $showTooltip }) => ($showTooltip ? '200px' : '50px')};
  }
`

export interface ScheduleSummaryProps {
  artistName: string
  trackName: string
  platform: CampaignPlatformTypeEnum
  price: string
  vat?: string
  discount?: string
  discountPercentage?: string
  total: string
  balance: string
  showPromocode: boolean
  paymentLoading: boolean
  onApplyPromocode: (cupon: string) => Promise<void>
  onConfirm: () => void
  showTooltip: boolean
  countdown: string
}

export const ScheduleSummary: FC<ScheduleSummaryProps> = (props) => {
  const {
    onApplyPromocode,
    onConfirm,
    showPromocode,
    showTooltip,
    countdown,
    paymentLoading,
    ...restProps
  } = props
  const { t } = useTranslation()
  return (
    <Wrapper $showTooltip={showTooltip}>
      <Container>
        <Title>{t('scheduleSummary.title')}</Title>
        <ScheduleSummaryDetails {...restProps} />
        {showPromocode && (
          <ScheduleSummaryPromocode onApplyPromocode={onApplyPromocode} />
        )}
        <ScheduleSummaryButton
          paymentLoading={paymentLoading}
          total={restProps.total}
          countdown={countdown}
          showTooltip={showTooltip}
          onConfirm={onConfirm}
        />
      </Container>
      <ArtistProtectionProgram />
    </Wrapper>
  )
}
