import React, { FC } from 'react'

import SpotifyIcon from 'mdi-react/SpotifyIcon'
import { t } from 'i18next'
import { useMediaQuery } from 'react-responsive'

import { payment, rewardWithPlacement, withdraw } from 'src/Constants/constants'
import { colors } from 'src/Styled/variables'
import { getValueOrEmptyString } from 'src/Helpers/layoutHelpers'

import { ErrorPayoutDescriptionCell } from '../ErrorPayoutDescriptionCell/ErrorPayoutDescriptionCell'
import { Description, WithdrawText } from '../styles'

interface DescriptionCellProps {
  type?: string
  description?: string

  reviewDetails: {
    trackName: string
    artistName: string
    isAddedToPlaylist: boolean
  } | null
}

export const DescriptionCell: FC<DescriptionCellProps> = ({
  description,
  type,
  reviewDetails,
}) => {
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' })
  const isWithdraw = type === withdraw
  const isDescriptionShowing =
    type === payment || type === rewardWithPlacement || isWithdraw

  if (isDescriptionShowing) {
    const reviewType = t('balancePage.reviewFor')

    const text = `${reviewType} “${getValueOrEmptyString(
      reviewDetails?.trackName,
    )}” by ${getValueOrEmptyString(reviewDetails?.artistName)}`

    const story = reviewDetails ? text : description
    return (
      <Description isTop={isWithdraw}>
        {!isMobile && (
          <SpotifyIcon
            data-testid='spotify-icon'
            color={colors.badgeGreen}
            size={'16px'}
          />
        )}
        <div>
          <div>{story}</div>
          {isWithdraw && (
            <WithdrawText>{t('balancePage.theArtistClaimed')}</WithdrawText>
          )}
        </div>
      </Description>
    )
  }
  if (type === 'canceled') {
    return (
      <ErrorPayoutDescriptionCell
        description={getValueOrEmptyString(description)}
        reviewDetails={reviewDetails}
      />
    )
  }
  return (
    <Description>
      <div>{description}</div>
    </Description>
  )
}
