import { FC, useEffect } from 'react'

import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'

import { InfoForNotAuthUser } from 'src/Components/CreateCampaignLayout/InfoForNotAuthUser'
import { FaqComponent } from 'src/Components/FaqComponent'
import { HowToGetYourTrackURLModal } from 'src/Components/HowToGetYourTrackURLModal'
import { PlatformHeader } from 'src/Components/PlatformModal/components/PlatformHeader'
import { colors, device } from 'src/Styled/variables'
import { CampaignPlatformTypeEnum, CampaignTypeEnum } from 'src/Types'
import { useAppDispatch } from 'src/Hooks/redux'
import { setTracksToInitialState } from 'src/Redux/spotifyTracks-process'
import { setTiktokTracksToInitialState } from 'src/Redux/tiktokTrack-process'
import { NAVIGATE_BACK_INDEX } from 'src/Constants/constants'
import { FirstTrackSubHeader } from 'src/Components/FirstTrackSubHeader/FirstTrackSubHeader'
import { HelmetTitle } from 'src/Components/CreateCampaignLayout/components/HelmetTitle'
import { toggleActivatedEvent } from 'src/Helpers/TagManager'

import { TrackSearchForm } from './TrackSearchForm'

export type SearchTrackByPlatformParams = {
  platform: CampaignPlatformTypeEnum
}

export const StyledPlatformModal = styled.div`
  overflow-y: auto;
  background-color: ${colors.greyBlue};
  width: 100%;
  height: 100vh;
`
export const InnerContainer = styled.div`
  width: 100%;
  padding-top: 22px;
  padding-left: 16px;
  padding-right: 16px;
  margin: 0 auto;

  @media ${device.tabletMin} {
    padding-right: 0px;
    padding-left: 0px;
    max-width: 800px;
  }
`

export const SearchTrackByPlatform: FC = () => {
  const { platform } = useParams<SearchTrackByPlatformParams>()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (platform) {
      toggleActivatedEvent({
        platformName: platform,
        campaignType: CampaignTypeEnum.FIRST,
        campaignNumber: 1,
      })
    }
  }, [platform])

  const handleBackClick = (): void => {
    navigate(NAVIGATE_BACK_INDEX)
    dispatch(setTracksToInitialState())
    dispatch(setTiktokTracksToInitialState())
  }

  return (
    <StyledPlatformModal id='scroll'>
      <HowToGetYourTrackURLModal />
      <PlatformHeader onBackClick={handleBackClick} />

      <InnerContainer>
        <HelmetTitle />

        <FirstTrackSubHeader onBackClick={handleBackClick} />
        <TrackSearchForm />
        <InfoForNotAuthUser />
        <FaqComponent platform={platform} />
      </InnerContainer>
    </StyledPlatformModal>
  )
}
