import styled from 'styled-components'

import { colors, variables } from 'src/Styled/variables'

export const StyledDotedButton = styled.div`
  .ant-btn {
    width: fit-content;
    outline: none;
    box-sizing: border-box;
    border-radius: 0;
    border: none;
    box-shadow: none;
    padding: 0;
    color: ${({ color }) => color};
    cursor: pointer;
    font-size: ${variables.fontSize14};
    background-color: transparent;
    transition: all 0.2s;
    position: relative;

    ::after {
      transition: all 0.2s;
      content: '';
      display: block;
      position: absolute;
      bottom: 5px;
      border-bottom: 1px dashed ${({ color }) => color};
      width: 100%;
      opacity: 1;
    }

    &:hover {
      ::after {
        opacity: 0;
      }
    }
    &:active {
      outline: none;
      box-shadow: none;
      color: ${({ color }) => color};

      ::after {
        border-bottom: 1px solid ${({ color }) => color};
      }
    }
    &:focus {
      box-shadow: none;
      outline: none;
      color: ${({ color }) => color};
    }
  }
  [ant-click-animating-without-extra-node='true']::after {
    display: none;
  }
  .ant-btn[disabled],
  .ant-btn[disabled]:hover,
  .ant-btn[disabled]:focus,
  .ant-btn[disabled]:active {
    background: transparent;
    color: ${colors.greyTextBlackBg};

    ::after {
      border-bottom: 1px dashed ${colors.greyTextBlackBg};
    }
  }

  .ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    color: ${({ color }) => color};
  }
`
