import { FC } from 'react'

import { Modal, ModalProps } from 'antd'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import { object as yupObject, string as yupString } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import { PrimaryDottedButton } from 'src/Components/Buttons/PrimaryDottedButton'
import { PrimaryInput } from 'src/Components/PrimaryInput'

import { ScheduleSummaryProps } from '..'

const StyledModal = styled(Modal)`
  width: fit-content !important;
  width: 280px;
  .ant-modal-content {
    display: flex;
    width: 280px;
    padding: 16px;
    flex-direction: column;
    gap: 16px;
    border-radius: 16px;
    border: 1px solid #e7e7e7;
    background: #fff;
    box-shadow: 0px 0px 12px 0px rgba(51, 51, 51, 0.12);
  }
`
const PromocodeForm = styled.form`
  display: flex;
  flex-direction: column;
  padding-top: 16px;
  gap: 16px;
`
const Title = styled.div`
  color: ${({ theme }) => theme.common.primaryText};
  font-family: 'Segoe UI';
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
`
interface FormDataProps {
  coupon: string
}

const schema = yupObject().shape({
  coupon: yupString().required(),
})

const StyledPrimaryDottedButton = styled(PrimaryDottedButton)`
  width: fit-content;
  margin: 0 auto;
`

type Props = ModalProps & Pick<ScheduleSummaryProps, 'onApplyPromocode'>
export const ScheduleSummaryPromocodePopup: FC<Props> = (props) => {
  const { onApplyPromocode, onCancel, ...restProps } = props
  const { t } = useTranslation()

  const { control, handleSubmit, formState, setError, resetField, reset } =
    useForm<FormDataProps>({
      resolver: yupResolver(schema),
      defaultValues: {
        coupon: '',
      },
    })

  const handleSubmitPromocode = async (
    values: FormDataProps,
  ): Promise<void> => {
    if (values.coupon) {
      if (values.coupon.includes('?') || values.coupon.includes('%')) {
        setError('coupon', {
          message: t('scheduleCampaignPage.couponDoesNotExist'),
        })
        return
      }
      try {
        await onApplyPromocode(values.coupon)
      } catch (error) {
        const errorMessage = (error as Error).message
        setError('coupon', {
          message: errorMessage,
        })
      }
    }
  }

  return (
    <StyledModal
      footer={null}
      centered
      {...restProps}
      onCancel={(e) => {
        onCancel?.(e)
        reset()
        resetField('coupon')
      }}
    >
      <Title>{t('scheduleSummary.promoCode')}</Title>
      <PromocodeForm onSubmit={handleSubmit(handleSubmitPromocode)}>
        <Controller
          name='coupon'
          control={control}
          render={({ field, fieldState: { error } }) => (
            <PrimaryInput
              {...field}
              autoComplete='off'
              placeholder={t('scheduleSummary.enter')}
              error={error?.message}
            />
          )}
        />
        <StyledPrimaryDottedButton disabled={!formState.isValid} type='submit'>
          {t('scheduleCampaignPage.applyPromocode')}
        </StyledPrimaryDottedButton>
      </PromocodeForm>
    </StyledModal>
  )
}
