import { FC, useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import Button from 'src/Components/Buttons/Button'
import { useGetNewReleaseQuery } from 'src/Redux/newReleaseApi'
import { SmartImage } from 'src/Components/SmartImage'
import IconSpotify from 'src/Assets/Svg/spotify.svg?react'
import { useAppSelector } from 'src/Hooks/redux'
import { newReleasedCampaignsListEvent } from 'src/Helpers/TagManager/CampaignsEvents'
import { getUserId } from 'src/Redux/auth-process/userSlice/selectors'
import { useCreateAndStartScheduleNewRelease } from 'src/Hooks/useCreateAndStartScheduleNewRelease'
import { getIsArtist } from 'src/Redux/auth-process/credentialsSlice/selectors'

import {
  Artist,
  IconWrapper,
  ImgWrapper,
  Name,
  NamesWrapper,
  StyledIcon,
  StyledReleaseItem,
  Status,
  BadgeWrapper,
  CardButtonWrapper,
  Block,
} from './styles'

interface NewReleaseItemProps {
  isDisplay?: boolean
  search?: string
}

export const NewReleaseItem: FC<NewReleaseItemProps> = ({
  isDisplay,
  search,
}) => {
  const { t } = useTranslation()
  const userId = useAppSelector(getUserId)
  const isArtist = useSelector(getIsArtist)
  const { data } = useGetNewReleaseQuery(null, {
    refetchOnMountOrArgChange: true,
    skip: !isArtist,
  })

  const isSearch = search?.toLowerCase()

  const matchesSearch = isSearch
    ? data?.trackName.toLowerCase().includes(isSearch) ||
      data?.artistName.toLowerCase().includes(isSearch)
    : true

  useEffect(() => {
    if (data?.id) {
      newReleasedCampaignsListEvent({ userId, trackId: data?.trackSpotifyId })
    }
  }, [data, userId])

  const { createAndStartSchedule, loading } =
    useCreateAndStartScheduleNewRelease()

  if (!data?.id || !isDisplay || !matchesSearch) {
    return null
  }

  return (
    <StyledReleaseItem>
      <StyledIcon>
        <ImgWrapper>
          <SmartImage
            alt='trackCoverUrl'
            width='40'
            src={data?.trackImageUrl}
            fallbackSrc={data?.trackImageUrl}
          />
        </ImgWrapper>

        <IconWrapper>
          <IconSpotify />
        </IconWrapper>
      </StyledIcon>

      <NamesWrapper>
        <div>
          <Name>{data?.trackName}</Name>
          <Artist>{data?.artistName}</Artist>
        </div>
        <BadgeWrapper>{t('campaignsPage.newRelease')}</BadgeWrapper>
      </NamesWrapper>

      <Status>{t('campaignsPage.approved')}</Status>

      <Block />

      <CardButtonWrapper>
        <Button
          type='green'
          loading={loading}
          onClick={() => createAndStartSchedule()}
        >
          {t('campaignsPage.startCampaign')}
        </Button>
      </CardButtonWrapper>
    </StyledReleaseItem>
  )
}
