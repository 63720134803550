import React from 'react'

import { InfoCardProps } from './infoCardProps'

import { StyledInfoCard, Title, Value } from './styles'

const InfoCard: React.FC<InfoCardProps> = ({ title, value, type }) => (
  <StyledInfoCard>
    <Title>{title}</Title>
    <Value type={type}>{value}</Value>
  </StyledInfoCard>
)

export default InfoCard
