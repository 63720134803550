import { FunctionComponent } from 'react'

import { Helmet } from 'react-helmet'

const LINK_HREF = 'https://app.soundcamps.com/create/'

export const HelmetTitle: FunctionComponent = () => {
  const titleText = 'SoundCampaign - Create the first campaign'
  return (
    <Helmet>
      <meta charSet='utf-8' />
      <title>{titleText}</title>
      <link rel='canonical' href={LINK_HREF} />
    </Helmet>
  )
}
