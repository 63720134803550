import { ReactElement } from 'react'

import { useTranslation } from 'react-i18next'

import { CampaignPlatformTypeEnum, TransactionTypeEnum } from 'src/Types/index'
import { GiftIcon, StarIcon } from 'src/Assets/Svg'
import DangerIcon from 'src/Assets/Svg/Danger.svg?react'
import IconSpotify from 'src/Assets/Svg/spotify.svg?react'
import TikTokIcon from 'src/Assets/Svg/tiktok.svg?react'
import { HUNDREDTHS } from 'src/Constants/numeric'
import { getValueOrEmptyString } from 'src/Helpers/layoutHelpers'
import { colors } from 'src/Styled/variables'
import { ArtistBalanceItem } from 'src/Redux/artistBalanceHistoryApi'

import {
  AmountValue,
  AmountWrapper,
  InfoText,
  InfoTitle,
  PrefixValue,
} from './styles'
interface GetIconParams {
  platformType: CampaignPlatformTypeEnum
  transactionType: TransactionTypeEnum
}

interface GetTrackTitleParams {
  track: ArtistBalanceItem['track']
  transactionType: TransactionTypeEnum
  platformType: CampaignPlatformTypeEnum
  referralData?: { firstName: string; trackName: string }
  invitedData?: { firstName: string }
  packageName?: string
}

interface UseArtistBalancePageResponse {
  getIcon: (params: GetIconParams) => ReactElement | null
  getAmountValue: (
    transactionType: TransactionTypeEnum,
    amount: number,
  ) => ReactElement
  getTrackTitle: (params: GetTrackTitleParams) => ReactElement | null
}

const MAP_TRANSACTION_TYPE_TO_ICON: Record<string, ReactElement | null> = {
  [TransactionTypeEnum.SUBSCRIPTION]: <StarIcon fill={colors.green} />,
  [TransactionTypeEnum.CASHBACK]: <StarIcon fill={colors.green} />,
  [TransactionTypeEnum.SUBSCRIPTION_PAYMENT_FAILED]: (
    <DangerIcon fill={colors.green} />
  ),
  [TransactionTypeEnum.BONUS]: <GiftIcon />,
  [TransactionTypeEnum.QUICK_CHECKOUT_BONUS_FUTURE]: <GiftIcon />,
  [TransactionTypeEnum.QUICK_CHECKOUT_BONUS_CURRENT]: <GiftIcon />,
  [TransactionTypeEnum.REFERRAL_BONUS]: <GiftIcon />,
  [TransactionTypeEnum.INVITED_BONUS]: <GiftIcon />,
}

export const useArtistBalancePage = (): UseArtistBalancePageResponse => {
  const { t } = useTranslation()

  const bonusDescriptionText = t('balancePage.bonusFromTheSoundCampaign')

  const getIcon = ({
    platformType,
    transactionType,
  }: GetIconParams): ReactElement | null => {
    const icon = MAP_TRANSACTION_TYPE_TO_ICON[transactionType]
    if (icon) {
      return icon
    }

    if (transactionType === TransactionTypeEnum.CHARGEBACK && !platformType) {
      return <StarIcon fill={colors.green} />
    }
    if (platformType === CampaignPlatformTypeEnum.SPOTIFY) {
      return <IconSpotify />
    }

    if (platformType === CampaignPlatformTypeEnum.TIKTOK) {
      return <TikTokIcon />
    }

    return null
  }

  const getAmountValue = (
    transactionType: TransactionTypeEnum,
    amount: number,
  ): ReactElement => {
    const prefixes: Record<TransactionTypeEnum, string> = {
      [TransactionTypeEnum.RECEIVED]: '+',
      [TransactionTypeEnum.BONUS]: '+',
      [TransactionTypeEnum.QUICK_CHECKOUT_BONUS_FUTURE]: '+',
      [TransactionTypeEnum.QUICK_CHECKOUT_BONUS_CURRENT]: '+',
      [TransactionTypeEnum.SUBSCRIPTION]: '+',
      [TransactionTypeEnum.REFERRAL_BONUS]: '+',
      [TransactionTypeEnum.CASHBACK]: '+',
      [TransactionTypeEnum.INVITED_BONUS]: '+',
      [TransactionTypeEnum.PAYMENT]: '',
      [TransactionTypeEnum.USED]: '-',
      [TransactionTypeEnum.CHARGEBACK]: '-',
      [TransactionTypeEnum.SUBSCRIPTION_PAYMENT_FAILED]: ' ',
    }

    let prefix: string

    if (transactionType === TransactionTypeEnum.PAYMENT) {
      prefix = ''
    } else if (transactionType in prefixes) {
      prefix = prefixes[transactionType]
    } else {
      prefix = amount > 0 ? '-' : ''
    }

    return (
      <AmountWrapper>
        <PrefixValue>{prefix}</PrefixValue>
        <AmountValue>{`$${Number(amount).toFixed(HUNDREDTHS)}`}</AmountValue>
      </AmountWrapper>
    )
  }

  const getTrackTitle = ({
    track,
    transactionType,
    platformType,
    referralData,
    invitedData,
    packageName,
  }: GetTrackTitleParams): ReactElement | null => {
    const footerTexts: Record<TransactionTypeEnum, string> = {
      [TransactionTypeEnum.RECEIVED]:
        platformType === CampaignPlatformTypeEnum.SPOTIFY
          ? 'Funds received for not completed reviews'
          : 'Funds received for not completed videos',
      [TransactionTypeEnum.CHARGEBACK]: packageName
        ? 'Payment has been canceled'
        : 'Transaction has been cancelled',
      [TransactionTypeEnum.PAYMENT]: t('balancePage.CampaignWasPaid'),
      [TransactionTypeEnum.QUICK_CHECKOUT_BONUS_FUTURE]: t(
        'balancePage.FundsForQuickCheckout',
      ),
      [TransactionTypeEnum.QUICK_CHECKOUT_BONUS_CURRENT]: t(
        'balancePage.FundsForQuickCheckout',
      ),
      [TransactionTypeEnum.USED]: t('balancePage.FundsUsedToPayCampaign'),
      [TransactionTypeEnum.REFERRAL_BONUS]: `${getValueOrEmptyString(
        referralData?.firstName,
      )} launched a campaign after your invitation`,
      [TransactionTypeEnum.INVITED_BONUS]: `Bonus for joining: invitation from ${getValueOrEmptyString(
        invitedData?.firstName,
      )}`,
      [TransactionTypeEnum.BONUS]: bonusDescriptionText,
      [TransactionTypeEnum.SUBSCRIPTION]: t('balancePage.creditsDeposited'),
      [TransactionTypeEnum.CASHBACK]: t('balancePage.cashbackFor'),
      [TransactionTypeEnum.SUBSCRIPTION_PAYMENT_FAILED]: t(
        'balancePage.subscriptionFailed',
      ),
    }

    const bonusDescriptions: Record<TransactionTypeEnum, string | null> = {
      [TransactionTypeEnum.INVITED_BONUS]: 'Bonus from referral program',
      [TransactionTypeEnum.QUICK_CHECKOUT_BONUS_FUTURE]: bonusDescriptionText,
      [TransactionTypeEnum.QUICK_CHECKOUT_BONUS_CURRENT]: bonusDescriptionText,
      [TransactionTypeEnum.REFERRAL_BONUS]: 'Bonus from referral program',
      [TransactionTypeEnum.BONUS]: bonusDescriptionText,
      [TransactionTypeEnum.SUBSCRIPTION]: `${packageName || ''} Plan`,
      [TransactionTypeEnum.SUBSCRIPTION_PAYMENT_FAILED]: `${
        packageName || ''
      } Plan`,
      [TransactionTypeEnum.USED]: null,
      [TransactionTypeEnum.RECEIVED]: null,
      [TransactionTypeEnum.CHARGEBACK]: packageName
        ? `${packageName || ''} Plan`
        : null,
      [TransactionTypeEnum.PAYMENT]: null,
      [TransactionTypeEnum.CASHBACK]: null,
    }

    const footerText = footerTexts[transactionType] || ''
    const bonusDescription = bonusDescriptions[transactionType] || ''

    return (
      <>
        {bonusDescription ? (
          <InfoTitle
            isRed={
              transactionType ===
              TransactionTypeEnum.SUBSCRIPTION_PAYMENT_FAILED
            }
          >
            {bonusDescription}
          </InfoTitle>
        ) : (
          <span>{`${track.trackName} by ${track.trackAuthorName}`}</span>
        )}
        <InfoText
          isRed={
            transactionType === TransactionTypeEnum.SUBSCRIPTION_PAYMENT_FAILED
          }
        >
          {footerText}
        </InfoText>
      </>
    )
  }

  return {
    getAmountValue,
    getIcon,
    getTrackTitle,
  }
}
