import styled from 'styled-components'

import { variables, colors } from 'src/Styled/variables'

export const TrackInfoContainerMobile = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  padding: 16px;
  width: 100%;
  background-color: ${colors.white};
`
export const TrackInfoWrapperMobile = styled.div`
  display: flex;
  margin-bottom: 24px;
`

export const TrackAvatarMobile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40px;
  margin-right: 16px;
`

export const TrackInfoColumnMobile = styled.div``

export const TrackInfoRowMobile = styled.div`
  display: flex;
  flex-direction: column;
`

export const TrackInfoTextMobile = styled.span`
  font-size: ${variables.fontSize14};
  color: ${colors.mainText};
`

export const SpotifyIconContainer = styled.div`
  display: flex;
  align-items: center;
  width: 24px;
  height: 100%;

  @media (max-width: 576px) {
    height: 24px;
  }
`

export const TrackAvatar = styled.img`
  margin-left: 16px;
  width: 40px;
  height: 40px;
  border-radius: 4px;

  @media (max-width: 576px) {
    margin-left: 0;
    margin-bottom: 16px;
  }
`

export const GenresAndLanguagesContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`
export const ToggleDetails = styled.span`
  width: fit-content;
  display: inline-block;
  margin: 9px auto 0;
  color: ${colors.mainText};
  border-bottom: 1px dashed ${colors.mainText};
`
