import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'

import { APP } from 'src/Configs/App'
import { api } from 'src/Services/api'

import { NameSpace, ErrorCode } from '../types'
import { GetTikTokEventData } from '../influencer-process/api-actions'

export const getTikTokCanceledEvents = createAsyncThunk<GetTikTokEventData>(
  `${NameSpace.CANCELED_ALERTS}/getTikTokCanceledEvents`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await api.get<GetTikTokEventData>(
        `${APP.TIKTOK_SERVER}/influencer/tiktok/events?pageNumber=1&pageSize=10&statuses=campaign_stopped&sortBy=createdAt.DESC`,
      )
      return data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      return rejectWithValue({
        message: response?.data.message,
        code: response?.status || ErrorCode.InternalServerError,
      })
    }
  },
)
