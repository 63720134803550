import React, { FC } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { useAppSelector } from 'src/Hooks/redux'
import { getSubscriptionSelector } from 'src/Redux/subscription-process/subscription/selectors'
import { capitalizeFirstLetter } from 'src/Helpers/layoutHelpers'
import { StarIcon } from 'src/Assets/Svg'

import { PrimaryHint } from '../PrimaryHint'

import { getCashback } from './helpers'
interface Props {
  price: number
  symbol: string
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex: 1 0 0;
  svg {
    fill: ${({ theme }) => theme.common.sixthText};
  }
`

export const PrimaryScheduleSubscriptionCashback: FC<Props> = ({
  price,
  symbol,
}) => {
  const { t } = useTranslation()
  const plan = useAppSelector(getSubscriptionSelector)
  const { name, cashbackPercentage } = plan?.package || {}
  if (!cashbackPercentage || !name) {
    return null
  }
  const cashback = getCashback({
    price,
    percentage: Number(cashbackPercentage) / 100,
  })
  const text = `${t('scheduleSubscription.cashback')} ${symbol}${cashback}`
  const hintText = `${t(
    'scheduleSubscription.withTheSubscriptionPlanYouReceiveCashbackOnTheCampaignBudget',
    {
      planName: capitalizeFirstLetter(name),
      cashbackPercentage,
    },
  )}`

  return (
    <>
      <Container>
        <StarIcon width={16} height={16} />
        <div>{text}</div>
      </Container>
      <PrimaryHint>{hintText}</PrimaryHint>
    </>
  )
}
