import { FC, ReactNode, useEffect, useMemo } from 'react'

import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import { campaignsList } from 'src/Router/routes'

import { Breadcrumb } from '../Breadcrumb'

export enum ScheduleSteps {
  SETTINGS = 'settings',
  CHECKOUT = 'checkout',
}
export interface PrimaryScheduleSteps {
  path: string
  title: string
}

interface ScheduleTabsProps {
  steps: PrimaryScheduleSteps[]
  children: [ReactNode, ReactNode]
}
const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const PrimaryScheduleTabs: FC<ScheduleTabsProps> = ({
  children,
  steps,
}) => {
  const navigate = useNavigate()
  const { search, setSearch } = useCustomSearchParams()
  const isCheckout = search.get('step') === ScheduleSteps.CHECKOUT

  const STEPS: PrimaryScheduleSteps[] = useMemo(() => {
    const currentSteps = [...steps]
    if (!isCheckout) {
      currentSteps.pop()
    }
    return currentSteps
  }, [isCheckout, steps])

  const handleGoBack = (): void => {
    if (search.get('step') === ScheduleSteps.CHECKOUT) {
      setSearch({ step: ScheduleSteps.SETTINGS })
      return
    }
    navigate(campaignsList)
  }

  const MAP_STEPS = useMemo(
    () => ({
      [ScheduleSteps.SETTINGS]: children[0],
      [ScheduleSteps.CHECKOUT]: children[1],
    }),
    [children],
  )

  useEffect(() => {
    const step = search.get('step')

    const paypalEmail = search.get('paypal_email')
    const paymentStatus = search.get('paymentStatus')

    const isNotFromPaymentPage = !paypalEmail && !paymentStatus

    const isStepValid = Object.keys(MAP_STEPS).includes(step as ScheduleSteps)

    if ((!step || !isStepValid) && isNotFromPaymentPage) {
      setSearch({ step: ScheduleSteps.SETTINGS })
    }
  }, [search, setSearch, MAP_STEPS])

  return (
    <Container>
      <div>
        <Breadcrumb items={STEPS} onButtonClick={handleGoBack} />
      </div>
      {MAP_STEPS[search.get('step') as ScheduleSteps] || children[0]}
    </Container>
  )
}
