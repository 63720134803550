import { FC, SVGProps } from 'react'

import EmailIcon from 'src/Assets/Svg/email.svg?react'
import MessageIcon from 'src/Assets/Svg/message-icon.svg?react'
import FacebookIcon from 'src/Assets/Svg/facebook.svg?react'
import WhatsAppIcon from 'src/Assets/Svg/whatsapp.svg?react'
import TwitterIcon from 'src/Assets/Svg/twitter.svg?react'
import i18n from 'src/Localization/i18n'
import { ReferralShareButtonTypeEnum } from 'src/Helpers/TagManager'

interface SocialLink {
  name: string
  id: ReferralShareButtonTypeEnum
  path: string
  icon: FC<SVGProps<SVGSVGElement>>
}

export const getSocialLinks = (link: string): SocialLink[] => [
  {
    name: i18n.t('referAndEarn.shareViaEmail'),
    id: 'email',
    path: `mailto:?subject=%2430%20referral%20bonus%20to%20start%20SoundCampaign%20promotion&body=Hey%21%20Discover%20an%20easy%20way%20to%20boost%20your%20tracks%20on%20Spotify%20and%20TikTok%20with%20SoundCampaign.%20Sign%20up%20with%20my%20invite%20link%20${link}%20and%20get%20a%20%2430%20bonus%20for%20your%20first%20campaign.%20Let%27s%20elevate%20your%20music%20together%21`,
    icon: EmailIcon as FC<SVGProps<SVGSVGElement>>,
  },
  {
    name: i18n.t('referAndEarn.shareViaSms'),
    id: 'sms',
    path: `sms:?body=Hey!%20🎶%20I've%20started%20using%20SoundCampaign%20to%20get%20my%20music%20on%20Spotify%20playlists%20and%20TikTok%20videos,%20and%20it's%20been%20really%20helpful.%20You%20get%20a%20%2430%20bonus%20for%20your%20first%20campaign%20if%20you%20sign%20up%20through%20this%20link:%20${link} Check it out if you're%20interested!%20🚀%20%23SoundCampaign`,
    icon: MessageIcon as FC<SVGProps<SVGSVGElement>>,
  },
  {
    name: i18n.t('referAndEarn.shareViaFacebook'),
    id: 'facebook',
    path: `https://www.facebook.com/sharer/sharer.php?u=${link}`,
    icon: FacebookIcon as FC<SVGProps<SVGSVGElement>>,
  },
  {
    name: i18n.t('referAndEarn.shareOnWhatsApp'),
    id: 'whatsapp',
    path: `https://wa.me/?text=Hey%21%20Discover%20an%20easy%20way%20to%20boost%20your%20music%20on%20Spotify%20and%20TikTok%20with%20SoundCampaign.%20Sign%20up%20with%20my%20invite%20link:%20${link}%20and%20get%20a%20%2430`,
    icon: WhatsAppIcon as FC<SVGProps<SVGSVGElement>>,
  },
  {
    name: i18n.t('referAndEarn.shareOnX'),
    id: 'twitter',
    path: `https://twitter.com/intent/tweet?text=Hey! 🎶 I've%20started%20using%20SoundCampaign%20to%20get%20my%20music%20on%20Spotify%20playlists%20and%20TikTok%20videos,%20and%20it's%20been%20really%20helpful.%20You%20get%20a%20%2430%20bonus%20for%20your%20first%20campaign%20if%20you%20sign%20up%20through%20this%20link:%20${link} Check it out!%20%F0%9F%9A%80%20%23SoundCampaign`,
    icon: TwitterIcon as FC<SVGProps<SVGSVGElement>>,
  },
]
