import { FC } from 'react'

import { useTranslation } from 'react-i18next'

import { useArtistBalancePage } from 'src/Containers/ArtistBalancePage/helpers'
import { colors } from 'src/Styled/variables'
import { LoadingSpinner } from 'src/Containers/ui'
import { ArtistBalanceItem } from 'src/Redux/artistBalanceHistoryApi'

import { BONUS_TYPES } from './halpers'
import { HeaderTitle } from './HeaderTitle/HeaderTitle'
import { MobileCardInfo } from './MobileCardInfo/MobileCardInfo'

import {
  IconWrapper,
  MobileCard,
  MobileCardHeaderWrapper,
  MobileInfoText,
  MobileItemsWrapper,
  MobileLoadingWrapper,
} from './styles'

interface MobileBalanceListProps {
  items: Array<ArtistBalanceItem>
  isLoading: boolean
}

export const MobileBalanceList: FC<MobileBalanceListProps> = (props) => {
  const { items, isLoading } = props
  const { t } = useTranslation()
  const { getAmountValue, getIcon } = useArtistBalancePage()

  if (isLoading) {
    return (
      <MobileLoadingWrapper>
        <LoadingSpinner color={colors.green} size='large' />
      </MobileLoadingWrapper>
    )
  }

  return (
    <MobileItemsWrapper>
      {items.length === 0 && !isLoading && (
        <MobileInfoText>
          {t('balancePage.YouHaveNoTransactions')}
        </MobileInfoText>
      )}
      {items.map((item) => {
        const isBonusType = BONUS_TYPES.includes(item.transactionType)

        return (
          <MobileCard key={item.id}>
            <MobileCardHeaderWrapper>
              <IconWrapper>
                {getIcon({
                  platformType: item.platformType,
                  transactionType: item.transactionType,
                })}
              </IconWrapper>
              <HeaderTitle
                isBonusType={isBonusType}
                packageName={item.packageName}
                trackAuthorName={item.track.trackAuthorName}
                trackName={item.track.trackName}
                firstName={
                  item.invitedData?.firstName || item.referralData?.firstName
                }
                transactionType={item.transactionType}
              />
            </MobileCardHeaderWrapper>
            <MobileCardInfo
              firstName={
                item.invitedData?.firstName || item.referralData?.firstName
              }
              transactionType={item.transactionType}
              amount={getAmountValue(item.transactionType, item.amount)}
              date={item.date}
              packageName={item.packageName}
            />
          </MobileCard>
        )
      })}
    </MobileItemsWrapper>
  )
}
