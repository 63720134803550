import TagManager from 'react-gtm-module'

import { CampaignPlatformTypeEnum, CampaignTypeEnum } from 'src/Types'

import { EventNameEnum, EventTypeEnum } from '../tagManagerEnums'

export const campaignScheduleStartEvent = ({
  campaignType,
  genreList,
  languageList,
  campaignId,
  platformName,
  userId,
  campaignNumber,
  subscriptionPlan,
}: {
  campaignType: string
  genreList?: string
  languageList?: string
  campaignId: number | string
  platformName?: string
  userId?: number | string
  campaignNumber?: number
  subscriptionPlan?: string
}): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventTypeEnum.CampaignScheduleStart,
      event_name: EventNameEnum.CampaignScheduleStart,
      'campaign-type': campaignType,
      'campaign-platform': platformName,
      'campaign-number':
        campaignType === CampaignTypeEnum.FIRST ? 1 : campaignNumber,
      'campaign-id': campaignId,
      userID: userId,
      ...(genreList ? { 'genre-list': genreList } : {}),
      ...(languageList ? { language_list: languageList } : {}),
      is_feed: platformName === CampaignPlatformTypeEnum.SPOTIFY,
      ...(subscriptionPlan ? { subscription_plan: subscriptionPlan } : {}),
    },
  })
}

export const changeStartDateEvent = (campaignType: string): void => {
  TagManager.dataLayer({
    dataLayer: {
      'campaign-type': campaignType,
      event: EventTypeEnum.StartDateUpdate,
    },
  })
}

export const couponAppliedEvent = (
  campaignType: string,
  coupon: { value: string; discount: number },
  discountAmount?: number,
): void => {
  TagManager.dataLayer({
    dataLayer: {
      'campaign-type': campaignType,
      'coupon-name': coupon.value,
      'coupon-value': coupon.discount,
      ...(discountAmount ? { 'discount-value': discountAmount } : {}),
      event: EventTypeEnum.CouponApplied,
    },
  })
}

interface MsgLowReachReceivedArgs {
  type: string
  campaignNumber: number
  campaignId: string
  userId: number | string
  genreList?: string
  languageList?: string
}

export const msgLowReachReceived = ({
  type,
  campaignNumber,
  campaignId,
  userId,
  genreList,
  languageList,
}: MsgLowReachReceivedArgs): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventTypeEnum.MsgLowReachReceived,
      event_name: EventNameEnum.MsgLowReachReceived,
      flow: 'scheduling_campaign',
      'campaign-type': type,
      'campaign-platform': CampaignPlatformTypeEnum.SPOTIFY,
      'campaign-number': type === CampaignTypeEnum.FIRST ? 1 : campaignNumber,
      'campaign-id': campaignId,
      ...(userId ? { userID: userId } : {}),
      'result-price': 0,
      'genre-list': genreList,
      language_list: languageList,
      is_feed: 'true',
    },
  })
}
