import { FC } from 'react'

import Router from 'src/Router/index'
import { useSentry } from 'src/Hooks/useSentry'

import { useRedirect } from './useRediect'
import { useInitApiCalls } from './useInitApiCalls'
import { useAnalyticsCookies } from './useAnalyticsCookies'

const App: FC = () => {
  useSentry()
  useRedirect()
  useInitApiCalls()
  useAnalyticsCookies()

  return (
    <div>
      <Router />
    </div>
  )
}

export default App
