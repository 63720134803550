import { FC, useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { useLocation } from 'react-router-dom'

import {
  changeMobileSidebarVisibility,
  changeSidebarVisibility,
  hideTabletSidebar,
  showTabletSidebar,
} from 'src/Redux/sidebar-process'
import { getIsAlertMode, getSidebar } from 'src/Redux/sidebar-process/selectors'
import { subscription } from 'src/Router/routes'

import SidebarContent from './components/SidebarContent'
import SideBarBottom from './components/SideBarBottom'
import SideBarTop from './components/SideBarTop'

import {
  Overlay,
  SidebarWrapper,
  StyledDrawer,
  StyledScrollbar,
} from './styles'

const tabletPagesWithoutSidebar = [subscription]

const Sidebar: FC = () => {
  const { pathname } = useLocation()

  const dispatch = useDispatch()
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1023 })
  const isDesktop = useMediaQuery({ minWidth: 1024 })

  const sidebar = useSelector(getSidebar)
  const isAlertMode = useSelector(getIsAlertMode)

  const onChangeMobileSidebarVisibility = (): void => {
    dispatch(changeMobileSidebarVisibility())
  }

  useEffect(() => {
    if (sidebar.collapse && isMobile) {
      dispatch(changeSidebarVisibility())
    }
  }, [sidebar.collapse, dispatch, isMobile])

  useEffect(() => {
    if (tabletPagesWithoutSidebar.includes(pathname)) {
      dispatch(hideTabletSidebar())
    } else {
      dispatch(showTabletSidebar())
    }
  }, [dispatch, pathname])

  return (
    <Overlay>
      {isMobile && (
        <StyledDrawer
          placement='left'
          forceRender
          open={Boolean(sidebar.show)}
          onClose={onChangeMobileSidebarVisibility}
        >
          <SidebarWrapper isMenuOpened={!sidebar.collapse}>
            <StyledScrollbar>
              <SideBarTop collapse={sidebar.collapse} />
              <SidebarContent collapse={sidebar.collapse} />
            </StyledScrollbar>
            <SideBarBottom collapse={sidebar.collapse} />
          </SidebarWrapper>
        </StyledDrawer>
      )}

      {isTablet && (
        <StyledDrawer
          placement='left'
          forceRender
          open={sidebar.tabletShow}
          mask={!sidebar.collapse}
          isMenuOpened={!sidebar.collapse}
        >
          <SidebarWrapper isMenuOpened={!sidebar.collapse}>
            <StyledScrollbar>
              <SideBarTop collapse={sidebar.collapse} />
              <SidebarContent collapse={sidebar.collapse} />
            </StyledScrollbar>
            <SideBarBottom collapse={sidebar.collapse} />
          </SidebarWrapper>
        </StyledDrawer>
      )}

      {isDesktop && (
        <SidebarWrapper
          isMenuOpened={!sidebar.collapse}
          isAlertMode={isAlertMode}
        >
          <StyledScrollbar>
            <SideBarTop collapse={sidebar.collapse} />
            <SidebarContent collapse={sidebar.collapse} />
          </StyledScrollbar>
          <SideBarBottom collapse={sidebar.collapse} />
        </SidebarWrapper>
      )}
    </Overlay>
  )
}

export default Sidebar
