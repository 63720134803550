import { createSlice } from '@reduxjs/toolkit'

import { setApiError } from 'src/Hooks/redux'

import { ApiError, NameSpace, SliceStatus } from '../types'

import { getRate } from './api-actions'

export interface Rate {
  rank: number
  rewardPerVideo: number
  minFollowersRequired: number
  maxFollowersRequired: number | null
  isUserPosition: boolean
}

export interface RateProcessState {
  rates: Rate[]
  status: SliceStatus
  error: ApiError | null
}

const initialState: RateProcessState = {
  rates: [],
  status: SliceStatus.Idle,
  error: null,
}

export const rateProcess = createSlice({
  initialState,
  name: NameSpace.RATE,
  reducers: {},
  extraReducers(builder) {
    // getRate
    builder.addCase(getRate.pending, (state) => {
      state.status = SliceStatus.Loading
    })
    builder.addCase(getRate.fulfilled, (state, { payload }) => {
      state.status = SliceStatus.Succeeded
      state.rates = payload
    })
    builder.addCase(getRate.rejected, (state, action) => {
      state.status = SliceStatus.Failed
      state.error = setApiError(action)
    })
  },
})
