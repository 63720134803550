import React, { FC } from 'react'

import { useFeatureValue } from '@growthbook/growthbook-react'

import { ScheduleCampaignHeader } from 'src/Components/ScheduleCampaignHeader'
import SpotifyIcon from 'src/Assets/Svg/spotify.svg?react'
import { getCampaignToSchedule } from 'src/Redux/campaign-process/spotifyCampaignSchedule-process/selectors'
import { useAppSelector } from 'src/Hooks/redux'
import { ScheduleSubscriptionAd } from 'src/Components/ScheduleSubscription/ScheduleSubscriptionAd'

import { ModalsContainer } from '../ModalsContainer'
import { useMinBudgetDecrease } from '../../helpers/useMinBudgetDecrease'

import { TrackParameters } from './TrackParametrs'
import { CampaignSteps } from './CampaignSteps/CampaignSteps'
import { SettingsContinueButton } from './SettingsContinueButton'
import { ScheduleAlerts } from './ScheduleAlerts'
import { PayPalProcessingModalSpotify } from './PayPalProcessingModal'

import { SpotifySettingsContainer, ParamsContainer, Wrapper } from './styles'

export const SpotifySettings: FC = () => {
  useMinBudgetDecrease()

  // need to be removed after the experiment will be finished
  const variantOfContinueButton = useFeatureValue('continue_button', 0)

  const campaignDetails = useAppSelector(getCampaignToSchedule)

  return (
    <>
      <PayPalProcessingModalSpotify />
      <ModalsContainer />
      <ScheduleAlerts />
      <SpotifySettingsContainer>
        <ScheduleCampaignHeader
          avatar={campaignDetails.track.cover}
          author={campaignDetails.track.author}
          title={campaignDetails.track.title}
          Icon={SpotifyIcon}
        />
        <Wrapper>
          <ParamsContainer>
            <TrackParameters />
            <ScheduleSubscriptionAd />
          </ParamsContainer>
          <CampaignSteps />
        </Wrapper>
        {!variantOfContinueButton && <SettingsContinueButton />}
      </SpotifySettingsContainer>
    </>
  )
}
