import styled from 'styled-components'

import { colors } from 'src/Styled/variables'

export const StyledButton = styled.div`
  width: auto;

  .ant-btn[disabled] {
    background: transparent;
  }

  button {
    width: 40px;
    height: 40px;
    border-radius: 24px;
    padding: 8px;
    border: 1px solid ${colors.greyStroke};

    outline: none;
    box-sizing: border-box;
    box-shadow: none;
    cursor: pointer;
    transition: all 0.2s;
    background: transparent;

    &:hover {
      border: 1px solid ${colors.greyStroke} !important;
      background: transparent;
    }
    &:active {
      border: 1px solid ${colors.greyStroke} !important;
      background: transparent;
    }
    &:focus {
      border: 1px solid ${colors.greyStroke} !important;
      background: transparent;
    }
  }
`
