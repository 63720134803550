import styled from 'styled-components'

import { variables, colors, device } from 'src/Styled/variables'

export const Card = styled.div`
  text-align: center;
  font-size: ${variables.fontSize14};
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  border-radius: 0.25rem;
  padding-top: 100px;
  padding-bottom: 100px;
`
export const Text = styled.div`
  margin-bottom: 30px;
  color: ${colors.mainText};
`
export const ButtonWrapper = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  @media ${device.mobileMax} {
    margin-right: 8px;
  }
`
