import styled from 'styled-components'

import { colors, variables } from 'src/Styled/variables'

export const FormWrapper = styled.div`
  margin-left: 20px;
`

export const AlertWrapper = styled.div`
  padding: 20px 20px 0;
`
export const AlertText = styled.div`
  display: flex;
  flex-direction: column;
`
export const FormGroupField = styled.div`
  width: 100%;
  display: flex;
  margin: auto;
  input {
    border: 1px solid ${colors.greyTextLight};
    border-radius: 8px;
    height: 47px;
    background-color: ${colors.white};
    padding: 13px 16px;
    font-size: ${variables.fontSize14};
    line-height: 21px;
  }
`
export const Form = styled.form`
  max-width: 400px;
  margin-top: 0;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 24px;
`
export const FormGroup = styled.div`
  margin-bottom: 24px;
  width: 100%;
  position: relative;
`
export const FormGroupLabel = styled.span`
  margin-bottom: 8px;
  display: inline-block;
  color: ${colors.mainText};
`
