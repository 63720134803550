import React, { FC } from 'react'

import TimeAgo from 'react-timeago'
import { useTranslation } from 'react-i18next'

import { NotificationType, UserNotification } from 'src/Types/index'

import {
  Accent,
  StyledDate,
  OtherNotificationWrapper,
  Message,
  MessageHeader,
  Name,
} from './styles'

export const OtherNotification: FC<UserNotification> = (props) => {
  const {
    type_id: type,
    extra_score,
    artist_name,
    track_name,
    created_at,
    playlist_name,
  } = props

  const { t } = useTranslation()

  const youReceivedText = t('notification.youReceived')
  const extraPointsText = t('notification.extraPoints')

  switch (type) {
    case NotificationType.STAY_IN_PLAYLIST:
      return (
        <OtherNotificationWrapper>
          <Message>
            <MessageHeader>
              {youReceivedText}&nbsp;
              {extra_score > 0 ? (
                <Accent>+{extra_score}</Accent>
              ) : (
                <Accent>0</Accent>
              )}
              &nbsp;{extraPointsText}
              &nbsp;<Accent>{playlist_name}</Accent>
            </MessageHeader>
            <Name>{track_name} </Name>
            {t('notification.stayedInPlaylist')}
          </Message>
          <StyledDate>
            <TimeAgo date={created_at} />
          </StyledDate>
        </OtherNotificationWrapper>
      )

    case NotificationType.STAY_ON_TOP:
      return (
        <OtherNotificationWrapper>
          <Message>
            <MessageHeader>
              {youReceivedText}&nbsp;
              {extra_score > 0 ? (
                <Accent>+{extra_score}</Accent>
              ) : (
                <Accent>0</Accent>
              )}
              &nbsp;{extraPointsText}
              &nbsp;<Accent>{playlist_name}</Accent>
            </MessageHeader>
            <Name>{track_name} </Name>
            {t('notification.stayedOnTheTop')}
          </Message>
          <StyledDate>
            <TimeAgo date={created_at} />
          </StyledDate>
        </OtherNotificationWrapper>
      )

    case NotificationType.POSITIVE_REVIEW:
      return (
        <OtherNotificationWrapper>
          <Message>
            <MessageHeader>
              {youReceivedText}&nbsp;
              {extra_score > 0 ? (
                <Accent>+{extra_score}</Accent>
              ) : (
                <Accent>0</Accent>
              )}
              &nbsp;{extraPointsText}
              &nbsp;<Accent>{playlist_name}</Accent>
            </MessageHeader>
            <Name>{artist_name} </Name>
            {t('notification.positiveReview')}
          </Message>
          <StyledDate>
            <TimeAgo date={created_at} />
          </StyledDate>
        </OtherNotificationWrapper>
      )

    case NotificationType.NEGATIVE_REVIEW:
      return (
        <OtherNotificationWrapper>
          <Message>
            <MessageHeader>
              {t('notification.youLost')}&nbsp;
              <span style={{ color: 'rgb(255, 72, 97' }}>{extra_score}</span>
              &nbsp;{extraPointsText}
              &nbsp;<Accent>{playlist_name}</Accent>
            </MessageHeader>
            <Name>{artist_name} </Name>
            {t('notification.negativeReview')}
          </Message>
          <StyledDate>
            <TimeAgo date={created_at} />
          </StyledDate>
        </OtherNotificationWrapper>
      )

    case NotificationType.SKIP_REVIEW:
      return (
        <OtherNotificationWrapper>
          <Message>
            <MessageHeader>
              {youReceivedText}&nbsp;
              <Accent>+{extra_score}</Accent>
              &nbsp;{extraPointsText}
              &nbsp;<Accent>{playlist_name}</Accent>
            </MessageHeader>
            {t('notification.skipReview')}
          </Message>
          <StyledDate>
            <TimeAgo date={created_at} />
          </StyledDate>
        </OtherNotificationWrapper>
      )
    default:
      return null
  }
}
