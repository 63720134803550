import { useEffect } from 'react'

import { useSelector } from 'react-redux'

import { getCollapse } from 'src/Redux/sidebar-process/selectors'
import { hideSidebar } from 'src/Redux/sidebar-process'

import { useAppDispatch } from './redux'

export const useSidebarVisibility = (): void => {
  const dispatch = useAppDispatch()

  const collapse = useSelector(getCollapse)

  useEffect(() => {
    if (!collapse) {
      dispatch(hideSidebar())
    }
    // must be empty array to run only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
