import { number, object, string } from 'yup'

export const rateSchema = object().shape({
  rate: number().required(),
  comment: string().required(),
})
export const MIN_POSITIVE_RATE = 3

export interface RateFormState {
  rate: number
  comment: string
}
