import styled from 'styled-components'

import { variables, colors, device } from 'src/Styled/variables'
import { SmartImage } from 'src/Components/SmartImage'

export const ReviewContainer = styled.div`
  display: flex;
  padding: 16px;
  padding-bottom: 20px;
  margin-bottom: 2px;
  background-color: ${colors.errorBg};
  &:last-child {
    margin-bottom: 0;
  }
  @media (max-width: 576px) {
    display: block;
    margin-bottom: 10px;
    background-color: ${colors.white};
    border-radius: 4px;
  }
`

export const ReviewContentContainer = styled.div`
  display: flex;
  flex: 1;
  @media (max-width: 576px) {
    flex-direction: column;
  }
`

export const AuthorContainer = styled.div`
  display: flex;
  margin-right: 24px;
  width: 256px;
  min-width: 256px;
  height: 100%;
  @media (max-width: 576px) {
    margin-bottom: 24px;
  }
`

export const AvatarContainer = styled.div`
  margin-right: 16px;
  width: 40px;
  height: 100%;
`

export const Avatar = styled(SmartImage)`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  object-fit: cover;
`

export const AuthorInfoContainer = styled.div``

export const AuthorName = styled.p`
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
  color: ${colors.mainText};
`

export const RatingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
  width: 68px;
  @media (max-width: 576px) {
    position: relative;
    left: -1px;
    margin-bottom: 24px;
  }
`

export const AuthorStatsContainer = styled.div`
  font-size: ${variables.fontSize14};
  color: ${colors.mainText};
`

export const ReviewMainContainer = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
`

export const ReviewMainSectionLabel = styled.p`
  font-size: ${variables.fontSize10};
  line-height: 15px;
  margin-top: 16px;
  color: ${colors.mainText};
`

export const ReviewMainSectionText = styled.span`
  padding-right: 100px;
  font-size: ${variables.fontSize14};
  line-height: 21px;
  color: ${colors.mainText};
  word-break: break-word;
  white-space: pre-wrap;
  @media ${device.mobileMax} {
    padding-right: 0px;
  }
`

export const ReviewSubSectionLabel = styled.span<{ short?: boolean }>`
  margin-right: 8px;
  ${({ short }) => (short ? 'width: 46px;' : '')}
  font-size: ${variables.fontSize14};
  line-height: 21px;
  color: ${colors.greyTextWhiteBg};
  flex-shrink: 0;
`

export const ReviewSubSectionText = styled.span`
  margin: 0;
  font-size: ${variables.fontSize14};
  line-height: 21px;
  color: ${colors.mainText};
`

export const MatchesAndResultContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 16px;
  @media (max-width: 576px) {
    flex-direction: column;
    margin-top: 0;
  }
`

export const MatchesAndResultCol = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 0;
  }
`

export const MatchesAndResultRow = styled.div`
  display: flex;
  align-items: center;
`

export const ResponseContainer = styled.div`
  display: flex;
  margin-top: 33px;
`

export const RespondedContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const RespondedTitle = styled.span`
  font-size: ${variables.fontSize10};
`

export const RespondedRating = styled.div`
  display: flex;
  margin: 2px 0;
  width: 68px;
`

export const RespondedFeedback = styled.span``

export const ResponseForm = styled.form`
  width: 100%;
  @media ${device.mobileMin} {
    display: grid;
    grid-column-gap: 16px;
    grid-template-columns: 149px 1fr 111px;
  }
`

export const MissedReviewContainer = styled.div``

export const MissedReviewDescription = styled.p`
  color: ${colors.mainText};
`

export const MissedReviewRefundContainer = styled.div`
  margin-top: 16px;
`

export const MissedReviewRefundLabel = styled.span`
  color: ${colors.greyTextWhiteBg};
`

export const MissedReviewRefundAmount = styled.span`
  color: ${colors.green};
`

export const OpenOnSpotify = styled.span`
  margin-top: 10px;
  color: ${colors.mainText};
`

export const OpenOnSpotifyLink = styled.a`
  margin-left: 4px;
  color: ${colors.green};
  border-bottom: 1px dashed ${colors.green};
  cursor: pointer;
  &:hover,
  &:visited {
    color: ${colors.green};
  }
`

export const ToggleMatches = styled.span`
  margin-top: 8px;
  cursor: pointer;
  width: fit-content;
  color: ${colors.mainText};
  border-bottom: 1px dashed ${colors.mainText};
`

export const HiddenPlaylistText = styled.span`
  color: ${colors.greyTextWhiteBg};
  font-size: ${variables.fontSize14};
  line-height: 150%;
`

export const ShareButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 91px;
  height: 40px;
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight400};
  line-height: 1.5;
  color: ${colors.mainText};
  border: 1px solid ${colors.greyStroke};
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    border-color: ${colors.green};
  }
  @media ${device.mobileMax} {
    position: static;
    margin-top: 16px;
    margin-bottom: 16px;
    width: 100%;
  }
`

export const ShareIconWrapper = styled.div`
  margin-right: 4px;
  width: 16px;
`
