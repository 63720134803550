import { FC, useRef } from 'react'

import styled from 'styled-components'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import ReCAPTCHA from 'react-google-recaptcha'

import { PrimaryInput } from 'src/Components/PrimaryInput'
import { PrimaryButton } from 'src/Components/Buttons/PrimaryButton'
import { PrimarySelect } from 'src/Components/PrimarySelect'
import { APP } from 'src/Configs/App'
import { PhoneNumberInput } from 'src/Components/PhoneInput'
import { MAX_MOBILE_WIDTH } from 'src/Styled/variables'

import { options, registerSchema } from './helper'
import { LoginLink } from './Components/LoginLink'
import { EmailExist } from './Components/EmailExist'

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;
  flex-basis: calc(50% - 48px);

  @media (max-width: ${MAX_MOBILE_WIDTH}) {
    flex-basis: unset;
    width: 100%;
  }
`

const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  align-self: stretch;
`

const StyledButton = styled(PrimaryButton)`
  padding: 0 48px;
  border: 1px solid ${({ theme }) => theme.button.primaryText};
`

export const CaptchaWrapper = styled.div`
  position: absolute;
`

export interface FormDataProps {
  firstName: string
  lastName: string
  role: string
  email: string
  phoneNumber?: string | null
}

interface ArtistRegisterFormProps {
  onSubmit: (data: FormDataProps, token: string) => void
  onStartFillingForm: () => void
  isEmailExist: boolean
  isRegisterLoading?: boolean
}

export const ArtistRegisterForm: FC<ArtistRegisterFormProps> = ({
  onSubmit,
  isEmailExist,
  onStartFillingForm,
  isRegisterLoading,
}) => {
  const captchaRef = useRef<ReCAPTCHA>(null)

  const {
    handleSubmit,
    control,
    formState: { isValid, isDirty },
  } = useForm<FormDataProps>({
    criteriaMode: 'firstError',
    reValidateMode: 'onBlur',
    mode: 'all',
    resolver: yupResolver(registerSchema),
  })

  const isButtonDisable = !isValid || !isDirty

  const onSubmitForm = handleSubmit(async (data) => {
    const token = await captchaRef.current?.executeAsync()
    onSubmit(data, token ?? 'broken token')
    captchaRef.current?.reset()
  })

  return (
    <FormContainer>
      <InputsContainer>
        <Controller
          name='firstName'
          control={control}
          render={({ field, fieldState: { error } }) => (
            <PrimaryInput
              {...field}
              placeholder={'First name'}
              error={error?.message}
              onFocus={onStartFillingForm}
            />
          )}
        />

        <Controller
          name='lastName'
          control={control}
          render={({ field, fieldState: { error } }) => (
            <PrimaryInput
              {...field}
              placeholder={'Last name'}
              error={error?.message}
            />
          )}
        />

        <>
          <Controller
            name='email'
            control={control}
            render={({ field, fieldState: { error } }) => (
              <PrimaryInput
                {...field}
                placeholder={'Email'}
                error={error?.message}
              />
            )}
          />
          <EmailExist emailExist={isEmailExist} />
        </>

        <Controller
          control={control}
          name='role'
          render={({ field, fieldState: { error } }) => (
            <PrimarySelect
              {...field}
              options={options}
              placeholder={'Role'}
              error={error?.message}
            />
          )}
        />

        <PhoneNumberInput control={control} name={'phone_number'} />
      </InputsContainer>
      <CaptchaWrapper>
        <ReCAPTCHA
          ref={captchaRef}
          size='invisible'
          sitekey={APP.RE_CAPTCHA_SITE_KEY}
          badge={'bottomright'}
        />
      </CaptchaWrapper>
      <ButtonsContainer>
        <StyledButton
          disabled={isButtonDisable}
          onClick={onSubmitForm}
          loading={isRegisterLoading}
        >
          Continue
        </StyledButton>
        <LoginLink isEmailExist={isEmailExist} />
      </ButtonsContainer>
    </FormContainer>
  )
}
