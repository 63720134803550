import styled from 'styled-components'

import { variables, colors } from 'src/Styled/variables'

export const AlertText = styled.div`
  font-size: ${variables.fontSize14};
  line-height: 21px;
  font-weight: ${variables.fontWeight400};
  color: ${colors.mainText};
`
