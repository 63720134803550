import { createSlice } from '@reduxjs/toolkit'

import { setApiError } from 'src/Hooks/redux'

import { ApiError, NameSpace, SliceStatus } from '../types'

import { getSimilarGenres } from './api-actions'

export interface SimilarGenre {
  id: number
  genreName: string
}

export interface SimilarGenresProcessState {
  status: SliceStatus
  error: ApiError | null
  genres: SimilarGenre[]
}

const initialState: SimilarGenresProcessState = {
  status: SliceStatus.Idle,
  error: null,
  genres: [],
}

export const similarGenresProcess = createSlice({
  initialState,
  name: NameSpace.SIMILAR_GENRES,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getSimilarGenres.pending, (state) => {
      state.status = SliceStatus.Loading
    })
    builder.addCase(getSimilarGenres.fulfilled, (state, action) => {
      state.status = SliceStatus.Succeeded
      state.genres = action.payload
    })
    builder.addCase(getSimilarGenres.rejected, (state, action) => {
      state.status = SliceStatus.Failed
      state.error = setApiError(action)
    })
  },
})
