import styled from 'styled-components'

import { colors, device, variables } from 'src/Styled/variables'

export const TrackSubmittedAlertModal = styled.div`
  z-index: 300;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const SuccessBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 552px;
  text-align: center;
  @media ${device.mobileMax} {
    width: 288px;
  }
`

export const SuccessTitle = styled.div`
  font-weight: ${variables.fontWeight600};
  font-size: ${variables.fontSize18};
  margin-bottom: 16px;
  color: ${colors.mainText};
  line-height: 150%;
  & img {
    margin-left: 5px;
    width: 16px;
    height: 16px;
  }
`

export const SuccessText = styled.div`
  line-height: 150%;
  font-weight: ${variables.fontWeight400};
  font-size: ${variables.fontSize14};
  color: ${colors.mainText};
`

export const SuccessButtonBlock = styled.div`
  margin-top: 40px;
  align-self: center;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 24px;
  align-items: center;

  @media ${device.mobileMax} {
    flex-direction: column;
  }
`

export const SuccessCircle = styled.div`
  margin: 0 auto 56px auto;
  margin-bottom: 56px;
  display: inline-block;
  width: 48px;
  height: 48px;
`
export const ButtonWrapper = styled.div`
  width: 228px;

  @media ${device.mobileMax} {
    width: 208px;
  }
`
