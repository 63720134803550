import { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'

import Alert from 'src/Components/Alert'
import { useOpenCampaignSchedule } from 'src/Hooks/useOpenCampaignSchedule'
import { launchCampaignNotificationEvent } from 'src/Helpers/TagManager/CampaignsEvents'
import { useAppSelector } from 'src/Hooks/redux'
import { getUserId } from 'src/Redux/auth-process/userSlice/selectors'

import { ApprovedCampaignAlertProps } from './ApprovedCampaignAlertProps'

import { AlertButton, AlertText, AlertTitle } from './styles'

export const ApprovedCampaignAlert: FunctionComponent<
  ApprovedCampaignAlertProps
> = ({ campaign, handleAddCloseApprovedAlertIds, activeTab }) => {
  const { t } = useTranslation()
  const userId = useAppSelector(getUserId)

  const { handleScheduleCampaign } = useOpenCampaignSchedule({
    activeTab,
    campaign,
  })

  const onLaunchCampaign = (): void => {
    launchCampaignNotificationEvent({ userId })
    handleScheduleCampaign()
  }

  return (
    <Alert
      color='success'
      icon
      onClose={() => handleAddCloseApprovedAlertIds(Number(campaign.id))}
    >
      <AlertText>
        <AlertTitle>{t('campaignsPage.yourCampaignIsApproved')}</AlertTitle>
        &quot;{campaign.trackTitle}&quot; {t('campaignsPage.isWaiting')}
      </AlertText>
      <AlertButton type='button' onClick={onLaunchCampaign}>
        {t('campaignsPage.launchCampaign')}
      </AlertButton>
    </Alert>
  )
}
