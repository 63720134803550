import { useEffect } from 'react'

import { useDebounce } from 'use-debounce'

import { locationEvent, pageViewEvent } from 'src/Helpers/TagManager'
import { pageInfo } from 'src/Helpers/TagManager/pageInfo'
import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { getUserId } from 'src/Redux/auth-process/userSlice/selectors'
import {
  getCampaigns,
  getCampaignsLoader,
} from 'src/Redux/campaignList-process'
import { getCampaignsList } from 'src/Redux/campaignList-process/api-actions'
import {
  CampaignPlatformTypeEnum,
  CampaignStatusEnum,
  SpotifyCampaign,
  TiktokCampaign,
} from 'src/Types'
import {
  getCampaignsFilters,
  getCampaignsSearch,
} from 'src/Redux/campaignsActions-process'
import { DEBOUNCE_DELAY_1000 } from 'src/Constants/constants'

import { getStatusText } from './helpers'

export interface CampaignUnionType {
  trackImage?: string
  trackName?: string
  artistName?: string
  platform: CampaignPlatformTypeEnum
  status: CampaignStatusEnum
  statusText?: string
  id: number
  relaunched?: boolean
}

interface CampaignsHook {
  campaigns: Array<CampaignUnionType>
  loading: boolean
}

const transformCampaigns = (
  campaigns: Array<SpotifyCampaign | TiktokCampaign>,
): Array<CampaignUnionType> =>
  campaigns.map((campaign) => ({
    trackImage: campaign.trackCoverUrl ?? '',
    trackName: campaign.trackTitle,
    artistName: campaign.trackAuthorName,
    platform: campaign.platformType,
    status: campaign.status as CampaignStatusEnum,
    id: Number(campaign.id),
    relaunched: campaign.relaunched,
    statusText: getStatusText({
      status: campaign.status,
      allReview: campaign.allReview,
      platformType: campaign.platformType,
      submittedVideosAmount: campaign.submittedVideosAmount,
      startDate: campaign.startDate,
    }),
  }))

export const useCampaigns = (): CampaignsHook => {
  const dispatch = useAppDispatch()
  const userID = useAppSelector(getUserId)
  const campaigns = useAppSelector(getCampaigns)
  const loading = useAppSelector(getCampaignsLoader)
  const filters = useAppSelector(getCampaignsFilters)
  const search = useAppSelector(getCampaignsSearch)
  const [searchValue] = useDebounce(search, DEBOUNCE_DELAY_1000)

  useEffect(() => {
    void dispatch(getCampaignsList())
  }, [dispatch])

  useEffect(() => {
    if (userID) {
      pageViewEvent({ userID, currentPageInfo: pageInfo.campaigns })
      locationEvent()
    }
  }, [userID])

  const filterCampaigns = (): CampaignUnionType[] => {
    let campaignsList = transformCampaigns(campaigns)
    if (searchValue) {
      campaignsList = campaignsList.filter(
        (campaign) =>
          campaign?.artistName
            ?.toLowerCase()
            .includes(searchValue?.toLowerCase()) ||
          campaign?.trackName
            ?.toLowerCase()
            .includes(searchValue?.toLowerCase()),
      )
    }
    if (filters.spotify && !filters.tiktok) {
      campaignsList = campaignsList.filter(
        (campaign) => campaign.platform === CampaignPlatformTypeEnum.SPOTIFY,
      )
    }
    if (!filters.spotify && filters.tiktok) {
      campaignsList = campaignsList.filter(
        (campaign) => campaign.platform === CampaignPlatformTypeEnum.TIKTOK,
      )
    }
    return campaignsList
  }

  return { campaigns: filterCampaigns(), loading }
}
