import { ReactNode, FC } from 'react'

import { DetailsContainer } from './ShowMoreContainer.styled'

export interface ShowMoreContainerProps {
  children: ReactNode
  isContentOpened: boolean
}

export const ShowMoreContainer: FC<ShowMoreContainerProps> = ({
  children,
  isContentOpened,
}) => (
  <DetailsContainer isDetailsOpened={isContentOpened}>
    {children}
  </DetailsContainer>
)
