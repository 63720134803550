import { FC } from 'react'

import styled from 'styled-components'

import { colors, variables } from 'src/Styled/variables'
import { useGetCurrencyInfoQuery } from 'src/Redux/userExchangeRateApi'

interface PlanPriceProps {
  price: number
}

const LocalPrice = styled.div`
  color: ${colors.greyTextWhiteBg};
  text-align: center;
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
`
const PriceWrapper = styled.div`
  margin-bottom: 32px;
`

const Currency = styled.span`
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
`
const Price = styled.span`
  font-size: ${variables.fontSize20};
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
`

export const Period = styled.span`
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
  color: ${colors.greyTextWhiteBg};
`
export const PlanPrice: FC<PlanPriceProps> = ({ price }) => {
  const { data: currantCurrency } = useGetCurrencyInfoQuery()
  const currencySymbol = currantCurrency?.currencySymbol

  const value =
    currantCurrency &&
    `~ ${currencySymbol} ${(price * currantCurrency?.currencyRate).toFixed()}`
  return (
    <PriceWrapper>
      <Currency>$ </Currency>
      <Price>{price} </Price>
      <Period>/mo</Period>
      <LocalPrice>{value}</LocalPrice>
    </PriceWrapper>
  )
}
