import { useCallback } from 'react'

import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { getUserId } from 'src/Redux/auth-process/userSlice/selectors'
import { getCampaignToSchedule } from 'src/Redux/campaign-process/spotifyCampaignSchedule-process/selectors'
import { scheduleStartMpEvent } from 'src/Redux/ga4-process/api-actions'
import { CampaignPlatformTypeEnum, CampaignTypeEnum } from 'src/Types'
import {
  campaignBudgetReceived,
  campaignScheduleStartEvent,
  campaignBudgetUpdateEvent,
} from 'src/Helpers/TagManager'
import {
  sendScheduleStartEvent,
  sendScheduleStartPlatformEvent,
} from 'src/Redux/googleAnalytics-process/api-actions'
import { getScheduledTikTokCampaign } from 'src/Redux/schedule-process/tiktokCampaign/selectors'
import { ANY_LANGUAGE_ID } from 'src/Constants/constants'
import {
  getIsPriceReceived,
  setPriceReceived,
} from 'src/Redux/analytics-process'
import { saveSourceBusterData } from 'src/Redux/sourcebuster-process'
import { getAccessToken } from 'src/Redux/auth-process/credentialsSlice/selectors'
import { sendSourceBusterEvent } from 'src/Redux/sourcebuster-process/api-actions'
import {
  getCookieByName,
  getUtmParameters,
  getValueOrZero,
} from 'src/Constants/functions'
import { getSubscriptionSelector } from 'src/Redux/subscription-process/subscription/selectors'
import { getValueOrEmptyString } from 'src/Helpers/layoutHelpers'
import { getAmountOfCampaigns } from 'src/Redux/campaignList-process'

interface ScheduleEvents {
  handleScheduleStartEvent: (params: {
    campaignType: string | CampaignTypeEnum
    platformName: string
    campaignId: string | number
    genreList?: string
    languageList?: string
  }) => void

  handleBudgetEvents: (params: {
    platformName: string
    resultPrice?: number
  }) => void
}

export const useScheduleEvents = (): ScheduleEvents => {
  const dispatch = useAppDispatch()
  const plan = useAppSelector(getSubscriptionSelector)
  const param = useParams()
  const campaignId = Number(param.number)
  const userId = useSelector(getUserId)
  const amountOfCampaigns = useSelector(getAmountOfCampaigns)
  const campaignDetails = useSelector(getCampaignToSchedule)
  const scheduledCampaign = useSelector(getScheduledTikTokCampaign)
  const isPriceReceived = useSelector(getIsPriceReceived)
  const token = useSelector(getAccessToken)

  const handleScheduleStartEvent = useCallback(
    (params: {
      campaignType: string | CampaignTypeEnum
      platformName: string
      campaignId: string | number
      genreList?: string
      languageList?: string
    }): void => {
      dispatch(saveSourceBusterData())
      campaignScheduleStartEvent({
        platformName: params.platformName,
        campaignId: params.campaignId || campaignId,
        campaignType: params.campaignType || CampaignTypeEnum.BASIC,
        userId: String(userId),
        campaignNumber: amountOfCampaigns || 1,
        genreList: getValueOrEmptyString(params.genreList),
        languageList: getValueOrEmptyString(params.languageList),
        subscriptionPlan: getValueOrEmptyString(plan?.package?.name),
      })
      void dispatch(
        scheduleStartMpEvent({
          campaignId: Number(params.campaignId) || campaignId,
          campaignPlatform: params.platformName,
        }),
      )

      params.platformName === CampaignPlatformTypeEnum.TIKTOK &&
        void dispatch(
          sendScheduleStartPlatformEvent({
            campaignID: Number(params.campaignId) || campaignId,
            platform: CampaignPlatformTypeEnum.TIKTOK,
          }),
        )

      params.platformName === CampaignPlatformTypeEnum.SPOTIFY &&
        void dispatch(sendScheduleStartEvent(Number(params.campaignId)))

      if (params.campaignType === CampaignTypeEnum.RELAUNCHED) {
        const utmObj = getUtmParameters(getCookieByName('soundCampaignUtm'))

        void dispatch(
          sendSourceBusterEvent({
            token,
            campaignId: Number(params.campaignId) || campaignId,
            campaignPlatform: params.platformName as CampaignPlatformTypeEnum,
            eventName: 'schedule_relaunch_campaign',
            gclid: getValueOrEmptyString(utmObj.gclid),
          }),
        )
      }
    },
    [
      amountOfCampaigns,
      campaignId,
      dispatch,
      plan?.package?.name,
      token,
      userId,
    ],
  )

  const handleBudgetReceivedEvent = (params: {
    platformName: string
    resultPrice?: number
  }): void => {
    params.platformName === CampaignPlatformTypeEnum.TIKTOK &&
      campaignBudgetReceived({
        campaignType: scheduledCampaign.type || CampaignTypeEnum.BASIC,
        platformName: CampaignPlatformTypeEnum.TIKTOK,
        campaignNumber: amountOfCampaigns,
        campaignId: Number(scheduledCampaign.id) || campaignId,
        userId: String(userId),
        resultPrice: getValueOrZero(params.resultPrice),
        genreList:
          scheduledCampaign?.genres.map((genre) => genre.label).join() || '',
        languageList:
          scheduledCampaign?.languages
            .map((language) => language.label)
            .join() || '',
        is_feed: false,
        isAnyLanguage: Boolean(
          scheduledCampaign?.languages
            .map((language) => language.value)
            .find((value) => value === ANY_LANGUAGE_ID),
        ),
        subscriptionPlan: getValueOrEmptyString(plan?.package?.name),
      })

    params.platformName === CampaignPlatformTypeEnum.SPOTIFY &&
      campaignBudgetReceived({
        campaignType: campaignDetails.type || CampaignTypeEnum.BASIC,
        platformName: CampaignPlatformTypeEnum.SPOTIFY,
        campaignNumber: amountOfCampaigns,
        campaignId: Number(campaignDetails.id) || campaignId,
        userId: String(userId),
        resultPrice: params.resultPrice || 0,
        genreList: getValueOrEmptyString(
          campaignDetails?.genres.map((genre) => genre.name).join(),
        ),
        languageList: getValueOrEmptyString(
          campaignDetails?.languages.map((language) => language.name).join(),
        ),
        is_feed: true,
        isAnyLanguage: Boolean(
          campaignDetails?.languages
            .map((language) => language.id)
            .find((id) => id === ANY_LANGUAGE_ID),
        ),
        subscriptionPlan: getValueOrEmptyString(plan?.package?.name),
      })
  }

  const handleBudgetUpdateEvent = (params: {
    platformName: string
    resultPrice?: number
  }): void => {
    params.platformName === CampaignPlatformTypeEnum.TIKTOK
      ? campaignBudgetUpdateEvent({
          campaignType: scheduledCampaign.type || CampaignTypeEnum.BASIC,
          platformName: CampaignPlatformTypeEnum.TIKTOK,
          campaignNumber: amountOfCampaigns,
          campaignId: Number(scheduledCampaign.id) || campaignId,
          userId: String(userId),
          resultPrice: params.resultPrice || 0,
          genreList: getValueOrEmptyString(
            scheduledCampaign?.genres.map((genre) => genre.label).join(),
          ),
          languageList: getValueOrEmptyString(
            scheduledCampaign?.languages
              .map((language) => language.label)
              .join(),
          ),
          is_feed: false,
          subscriptionPlan: getValueOrEmptyString(plan?.package?.name),
        })
      : campaignBudgetUpdateEvent({
          campaignType: campaignDetails.type || CampaignTypeEnum.BASIC,
          platformName: CampaignPlatformTypeEnum.SPOTIFY,
          campaignNumber: amountOfCampaigns,
          campaignId: Number(scheduledCampaign.id) || campaignId,
          userId: String(userId),
          resultPrice: params.resultPrice || 0,
          genreList: getValueOrEmptyString(
            campaignDetails?.genres.map((genre) => genre.name).join(),
          ),
          languageList: getValueOrEmptyString(
            campaignDetails?.languages.map((language) => language.name).join(),
          ),
          is_feed: true,
          subscriptionPlan: getValueOrEmptyString(plan?.package?.name),
        })
  }

  const handleBudgetEvents = (params: {
    platformName: string
    resultPrice?: number
  }): void => {
    if (!isPriceReceived) {
      handleBudgetReceivedEvent(params)
      dispatch(setPriceReceived(true))
    } else {
      handleBudgetUpdateEvent(params)
    }
  }

  return {
    handleScheduleStartEvent,
    handleBudgetEvents,
  }
}
