import { FunctionComponent, ReactNode } from 'react'

import { CampaignPlatformTypeEnum } from 'src/Types'

import { CheckTrackFromSearchProps } from './CheckTrackFromSearchProps'
import { SearchResultTiktokItem } from './SearchResultTiktokItem'
import { SearchResultSpotifyItem } from './SearchResultSpotifyItem'

import { SearchResults, TracksWrapper } from './styles'

const MAX_DISPLAYED_TRACKS = 4

const CheckTrackFromSearch: FunctionComponent<CheckTrackFromSearchProps> = ({
  platform,
  onCheckTrack,
  tracks = [],
}) => {
  if (tracks && tracks.length === 0) {
    return null
  }

  const tracksToReflect = (): ReactNode => {
    if (platform === CampaignPlatformTypeEnum.SPOTIFY) {
      return (
        <SearchResultSpotifyItem tracks={tracks} onCheckTrack={onCheckTrack} />
      )
    }

    return (
      <SearchResultTiktokItem tracks={tracks} onCheckTrack={onCheckTrack} />
    )
  }

  const isSmallTracksContainer = tracks?.length
    ? tracks?.length < MAX_DISPLAYED_TRACKS
    : false

  return (
    <SearchResults>
      <TracksWrapper
        isSmallTracksContainer={isSmallTracksContainer}
        platform={platform}
      >
        {tracksToReflect()}
      </TracksWrapper>
    </SearchResults>
  )
}

export default CheckTrackFromSearch
