import { FIXED_BY } from 'src/Constants/constants'

const DEFAULT_PERCENTAGE = 0.1
interface GetCashbackParams {
  price: number
  percentage?: number
}
export const getCashback = ({
  price,
  percentage = DEFAULT_PERCENTAGE,
}: GetCashbackParams): string => (price * percentage).toFixed(FIXED_BY)
