import { FC } from 'react'

import { useTranslation } from 'react-i18next'

import { Rating } from 'src/Components/Rating/Rating'
import { useCampaignVideo } from 'src/Redux/campaignVideoApi'

import { RateFeedBackDate } from './RateFeedBackDate'

import {
  Comment,
  CommentTitle,
  RateContainer,
  RateDateWrapper,
  RateWrapper,
  StyledReactStars,
} from './styles'

export const RateFeedback: FC = () => {
  const { t } = useTranslation()
  const { video } = useCampaignVideo()

  return (
    <RateWrapper>
      <RateContainer>
        <RateDateWrapper>
          <RateFeedBackDate />
        </RateDateWrapper>
        <StyledReactStars>
          <Rating rating={video?.stars ?? 0} />
        </StyledReactStars>
      </RateContainer>
      {Boolean(video?.feedback) && (
        <div>
          <CommentTitle>{t('campaignResultsPage.comment')}</CommentTitle>
          <Comment>{video?.feedback}</Comment>
        </div>
      )}
    </RateWrapper>
  )
}
