import React from 'react'

import styled from 'styled-components'

import HintIcon from 'src/Assets/Svg/question.svg?react'

const Container = styled.div`
  cursor: pointer;
`

export interface HintGuideProps {
  hintName: string
  setHintName: (hintName: string) => void
  currentHint: string | null
}

export const HintGuide: React.FC<HintGuideProps> = ({
  hintName = '',
  setHintName,
  currentHint,
}) => {
  const handleClick = (): void => {
    if (currentHint === hintName) {
      setHintName('clearHint')
    } else {
      setHintName(hintName)
    }
  }

  return (
    <Container data-testid='hint-guide' onClick={handleClick}>
      <HintIcon />
    </Container>
  )
}
