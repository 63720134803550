import React, { useState } from 'react'

import { CLEAR_HINT, HINT_GENRES, HINT_LANGUAGES } from 'src/Constants/hints'

import GenresSelectionGuide from '../GenresSelectionGuide'
import PlaylistVocalSettings from '../PlaylistVocalSettings'

interface HintGuide {
  component: React.ReactElement | null
  name: string | null
}
type OnSetHint = (hint: string) => void

export const usePlaylistEditHint = (): [HintGuide, OnSetHint] => {
  const [hintGuide, setHint] = useState<HintGuide>({
    component: null,
    name: null,
  })

  const onSetHint = (hint: string): void => {
    if (hint === HINT_GENRES) {
      setHint({ component: <GenresSelectionGuide />, name: HINT_GENRES })
    }

    if (hint === HINT_LANGUAGES) {
      setHint({ component: <PlaylistVocalSettings />, name: HINT_LANGUAGES })
    }

    if (hint === CLEAR_HINT) {
      setHint({ component: null, name: null })
    }
  }

  return [hintGuide, onSetHint]
}
