import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'

import { APP } from 'src/Configs/App'
import { ErrorCode, NameSpace } from 'src/Redux/types'
import { api } from 'src/Services/api'
import { Curator } from 'src/Types'
interface GetCuratorsBody {
  page: number
}

interface GetCuratorsResponse {
  curators: Array<Curator>
  usersCount: {
    totalCurators: number
  }
}

export const getCurators = createAsyncThunk<
  GetCuratorsResponse,
  GetCuratorsBody
>(
  `${NameSpace.CuratorList}/getCurators`,
  async ({ page }, { rejectWithValue }) => {
    try {
      const url = `${APP.SERVER}/admin/curators/${page}/?sortBy=verified&sorting_orders=ASC`
      const { data } = await api.get<GetCuratorsResponse>(url)
      return data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      return rejectWithValue({
        message: response?.data.message,
        code: response?.status || ErrorCode.InternalServerError,
      })
    }
  },
)
