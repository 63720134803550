import axios, { AxiosError, AxiosInstance } from 'axios'
import Cookies from 'js-cookie'

import { AppStore } from 'src/Redux'

const REQUEST_TIMEOUT = 60000

const apiConfig = import.meta.env as Record<string, string>

export const ga = [
  ['_ga', Cookies.get('_ga')],
  [
    'soundCampaignUtm',
    encodeURIComponent(Cookies.get('soundCampaignUtm') ?? ''),
  ],
  ['_fbp', Cookies.get('_fbp')],
  ['_fbc', Cookies.get('_fbc')],
  [`${apiConfig.GA_CONTAINER}`, Cookies.get(`${apiConfig.GA_CONTAINER}`)],
].reduce((acc, item) => {
  if (item[1]) {
    return `${acc}${item[0] || ''}=${item[1]}; `
  }
  return acc
}, '')

interface SuperAxiosInstance extends AxiosInstance {
  store?: AppStore['store']
  setStore?: (store: AppStore['store']) => void
  getToken?: () => string
}
export const api: SuperAxiosInstance = axios.create({
  timeout: REQUEST_TIMEOUT,
})
api.setStore = (store: AppStore['store']) => {
  api.store = store
}
api.getToken = () => {
  if (!api.store) {
    throw new Error('Call api.setStore before api.getToken')
  }
  const state = api.store.getState().authentication
  return state.credentials.AccessToken
}

api.interceptors.request.use((config) => {
  const isCookies = ga && !config?.url?.startsWith('https://api.spotify.com')
  // add token adding to header logic
  const token = api.getToken && api.getToken()
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`
  }
  if (isCookies) {
    config.headers['Cookies'] = ga
  }
  return config
})

api.interceptors.response.use(
  (response) => response,
  (error: AxiosError<{ error: string }>) => Promise.reject(error),
)
