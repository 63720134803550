import styled from 'styled-components'

import { colors, variables } from 'src/Styled/variables'

export type Position = 'top-right' | 'bottom-right' | 'bottom-center'

export const Countdown = styled.span`
  font-size: ${variables.fontSize18};
  font-weight: ${variables.fontWeight600};
  color: ${colors.messegesRed};
`

export const Container = styled.div`
  position: relative;
  background-color: ${colors.black};
  padding: 16px;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  color: ${colors.white};
`

const getPosition = (
  position: Position,
): {
  top: string
  right: string
  bottom: string
  transform: string
} => {
  switch (position) {
    case 'top-right':
      return {
        top: '-8px',
        right: '16px',
        bottom: '0',
        transform: 'unset',
      }
    case 'bottom-right':
      return {
        top: 'unset',
        right: '16px',
        bottom: '-8px',
        transform: 'rotate(180deg)',
      }
    case 'bottom-center':
    default:
      return {
        top: 'unset',
        right: 'calc(50% - 9px)',
        bottom: '-8px',
        transform: 'rotate(180deg)',
      }
  }
}
export const Arrow = styled.span<{
  position: 'top-right' | 'bottom-right' | 'bottom-center'
}>`
  position: absolute;
  right: ${({ position }) => getPosition(position).right};
  top: ${({ position }) => getPosition(position).top};
  bottom: ${({ position }) => getPosition(position).bottom};
  transform: ${({ position }) => getPosition(position).transform};
  width: 0;
  height: 0;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 9px solid ${colors.black};
`
