import React, { FC, useCallback, useEffect, useRef, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useDebounce } from 'use-debounce'
import { useSelector } from 'react-redux'

import ArrowLeftIcon from 'src/Assets/Svg/arrow-left.svg?react'
import CloseIcon from 'src/Assets/Svg/close-cross.svg?react'
import { AlertErrorMessageWithIcon } from 'src/Components/AlertErrorMessageWithIcon'
import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { DEBOUNCE_DELAY_500 } from 'src/Constants/constants'
import { resetSearchPlaylist } from 'src/Redux/searchPlaylist-process'
import {
  cutPlaylistId,
  findPlaylist,
} from 'src/Redux/searchPlaylist-process/api-actions'
import {
  getSearchPlaylistData,
  getSearchPlaylistError,
  getSearchPlaylistLoading,
} from 'src/Redux/searchPlaylist-process/selectors'
import { checkPlaylist } from 'src/Redux/playlists-process/api-actions'
import { playlistAddedEvent } from 'src/Helpers/TagManager'
import { CampaignPlatformTypeEnum, Playlist } from 'src/Types'
import { getUserId } from 'src/Redux/auth-process/userSlice/selectors'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'

import { NewPlaylist } from '../NewPlaylist'
import {
  AlertWrapper,
  BackButton,
  ClearButton,
  Container,
  Content,
  MeetConditionsBtn,
  SearchContainer,
  SearchInput,
  SearchingWrapper,
  StyledModal,
} from '../styles'

const MEET_CONDITIONS_LINK =
  'https://help.soundcamps.com/en/articles/5541325-requirements-for-your-playlists'

interface SearchPlaylistMobileModalProps {
  modal: boolean
  toggle: () => void
}

export const SearchPlaylistMobileModal: FC<SearchPlaylistMobileModalProps> = (
  props,
) => {
  const { modal, toggle } = props
  const { t } = useTranslation()
  const { setSearch } = useCustomSearchParams()
  const dispatch = useAppDispatch()
  const InputRef = useRef<HTMLInputElement | null>(null)
  const [searchValue, setSearchValue] = useState<string>()
  const userId = useSelector(getUserId)
  const newPlaylistToSet = useAppSelector(getSearchPlaylistData)
  const loading = useAppSelector(getSearchPlaylistLoading)
  const error = useAppSelector(getSearchPlaylistError)

  const clearInput = useCallback(() => {
    if (InputRef && InputRef.current?.value) {
      InputRef.current.value = ''
      setSearchValue('')
      dispatch(resetSearchPlaylist())
    }
  }, [dispatch])

  const eventHandler = (response: Playlist): void => {
    userId &&
      playlistAddedEvent(
        response.meets_conditions,
        CampaignPlatformTypeEnum.SPOTIFY,
        response.id,
        String(userId),
      )
  }

  const onClickHandler = (): void => {
    if (searchValue) {
      const playlistId = cutPlaylistId(searchValue)
      const apiURL = `https://api.spotify.com/v1/playlists/${playlistId}`

      void dispatch(checkPlaylist(apiURL))
        .unwrap()
        .then((response) => {
          eventHandler && eventHandler(response)
          setSearch({ editPlaylist: String(response.id), new: 'true' })
          return response
        })
      toggle()
    }
  }

  const [debounceValue] = useDebounce(searchValue, DEBOUNCE_DELAY_500)

  useEffect(() => {
    if (debounceValue) {
      void dispatch(findPlaylist({ playlistId: debounceValue }))
    } else {
      dispatch(resetSearchPlaylist())
    }
  }, [debounceValue, dispatch])

  return (
    <StyledModal open={modal} footer={null} closable={false}>
      <Container active={modal}>
        <Content active={modal}>
          <SearchContainer>
            <BackButton onClick={toggle}>
              <ArrowLeftIcon />
            </BackButton>
            <SearchInput
              ref={InputRef}
              placeholder={String(t('playlistsPage.mobileAddPlaylistInput'))}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setSearchValue(e.target.value)
              }}
            />

            <ClearButton onClick={clearInput}>
              <CloseIcon />
            </ClearButton>
          </SearchContainer>
          <AlertWrapper>
            <AlertErrorMessageWithIcon
              type='mobile'
              isShow={!loading && Boolean(error)}
              errorText={t(error?.message || '')}
            />
            {error?.message === 'validation.notRequirementsPlaylist' && (
              <MeetConditionsBtn href={MEET_CONDITIONS_LINK} target='_blank'>
                {t('validation.minimalPlatformRequirements')}
              </MeetConditionsBtn>
            )}
          </AlertWrapper>
          {loading && (
            <SearchingWrapper>{t('playlistsPage.searching')}</SearchingWrapper>
          )}
          <NewPlaylist
            newPlaylistToSet={newPlaylistToSet}
            onItemClickHandler={onClickHandler}
          />
        </Content>
      </Container>
    </StyledModal>
  )
}
