import { FC } from 'react'

import { useMediaQuery } from 'react-responsive'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import Button from 'src/Components/Buttons/Button'
import { CampaignPlatformTypeEnum } from 'src/Types'
import { usePaymentAmountsWithCurrency } from 'src/Hooks/usePaymentAnountsWithCurrency'
import { useCheckoutButton } from 'src/Hooks/useCheckoutButton'
import { useBonusTooltipTimer } from 'src/Hooks/useBonusTooltip'

import { BonusTooltip } from '../BonusTooltip'

import {
  CheckoutMobileFooterButtonsContainer,
  TotalContainer,
  TotalLabel,
  TotalSum,
} from './styles'

const StyledBonusTooltip = styled(BonusTooltip)`
  position: absolute;
  top: -85px;
  left: 16px;
  width: calc(100% - 32px);
`

export const CheckoutMobileFooter: FC<{
  platformType: CampaignPlatformTypeEnum
}> = ({ platformType }) => {
  const { t } = useTranslation()
  const { isNextTimeBonus } = useBonusTooltipTimer()
  const { isLoading, buttonType, text, onClickContinue } =
    useCheckoutButton(platformType)
  const { currencyTotal } = usePaymentAmountsWithCurrency(platformType)
  const isMobile = useMediaQuery({ maxWidth: 767 })

  if (!isMobile) {
    return null
  }

  return (
    <CheckoutMobileFooterButtonsContainer>
      <TotalContainer>
        <TotalLabel>Total</TotalLabel>
        <TotalSum>{currencyTotal}</TotalSum>
      </TotalContainer>

      <Button type={buttonType} loading={isLoading} onClick={onClickContinue}>
        {text}
      </Button>

      {isNextTimeBonus && (
        <StyledBonusTooltip
          description={t('scheduleCampaignPage.offerDescriptionBonus20Next')}
          position={'bottom-center'}
        />
      )}
    </CheckoutMobileFooterButtonsContainer>
  )
}
