import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  CampaignPlatformTypeEnum,
  CampaignStatusEnum,
  CampaignToSchedule,
  Payment,
} from 'src/Types'
import { setApiError } from 'src/Hooks/redux'
import { PaymentMethodEnum, PaymentStatusEnum } from 'src/Constants/enums'

import { ApiError, NameSpace, SliceStatus } from '../../types'

import { changeCampaign, getCampaignStatus } from './api-actions'

interface CampaignToScheduleState {
  campaign: CampaignToSchedule
  payment: Payment
}

export interface CampaignState {
  campaignToSchedule: CampaignToScheduleState
  loading: boolean
  updateCampaignLoading: SliceStatus
  error: ApiError | null
  platform: CampaignPlatformTypeEnum | null
  schedulePlatform: CampaignPlatformTypeEnum | null
}

const initialState: CampaignState = {
  platform: null,
  campaignToSchedule: {
    payment: {
      id: 0,
      paid_amount: '',
      transaction_id: '',
      campaigns_full_price: '',
      charged_back_amount: '',
      charged_back_at: '',
      confirmed_at: '',
      created_at: '',
      balance_used: '',
      updated_at: '',
      user_id: 0,
      type: PaymentMethodEnum.STRIPE,
      status: PaymentStatusEnum.CHARGED_BACK,
    },
    campaign: {
      id: 0,
      status: CampaignStatusEnum.PENDING_APPROVAL,
      start_date: '',
      genres: [],
      languages: [],
      is_feed: true,
      price: 0,
      type: '',
      track: {
        title: '',
        cover: '',
        author: '',
      },
    },
  },
  loading: false,
  updateCampaignLoading: SliceStatus.Idle,
  error: null,
  schedulePlatform: null,
}

export const mainCampaignProcess = createSlice({
  initialState,
  name: NameSpace.Campaign,
  reducers: {
    clearCampaignToSchedule(state) {
      state.campaignToSchedule = initialState.campaignToSchedule
    },
    setPlatform(state, action: PayloadAction<CampaignPlatformTypeEnum | null>) {
      state.platform = action.payload
    },
    setSchedulePlatform(
      state,
      action: PayloadAction<CampaignPlatformTypeEnum | null>,
    ) {
      state.schedulePlatform = action.payload
    },
    setStartDate(state, action: PayloadAction<string>) {
      state.campaignToSchedule.campaign.start_date = action.payload
    },
    setCampaignBudget(state, action: PayloadAction<number>) {
      state.campaignToSchedule.campaign.price = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCampaignStatus.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getCampaignStatus.rejected, (state, action) => {
      state.loading = false
      state.error = setApiError(action)
    })
    builder.addCase(getCampaignStatus.fulfilled, (state, action) => {
      const {
        price,
        start_date,
        maxCuratorsCount,
        maxPlaylistsCount,
        minCuratorsCount,
        minPlaylistsCount,
      } = state.campaignToSchedule.campaign
      state.campaignToSchedule = action.payload
      state.campaignToSchedule.campaign.price = price
      state.campaignToSchedule.campaign.start_date = start_date
      state.campaignToSchedule.campaign.maxCuratorsCount = maxCuratorsCount
      state.campaignToSchedule.campaign.maxPlaylistsCount = maxPlaylistsCount
      state.campaignToSchedule.campaign.minCuratorsCount = minCuratorsCount
      state.campaignToSchedule.campaign.minPlaylistsCount = minPlaylistsCount
      state.error = null
      state.loading = false
    })
    builder.addCase(changeCampaign.pending, (state) => {
      state.updateCampaignLoading = SliceStatus.Loading
    })
    builder.addCase(changeCampaign.rejected, (state, action) => {
      state.updateCampaignLoading = SliceStatus.Failed
      state.error = setApiError(action)
    })
    builder.addCase(changeCampaign.fulfilled, (state, action) => {
      const campaignToSchedule = {
        ...state.campaignToSchedule,

        payment: state.campaignToSchedule?.payment,
        campaign: {
          ...state?.campaignToSchedule?.campaign,
          genres: action.payload?.genres,
          languages: action.payload?.languages,
          moods: action.payload?.moods,
          playlistsCount: action.payload?.playlistsCount,
          curatorsCount: action.payload?.curatorsCount,
          minPlaylistsCount: action.payload?.minCuratorsCount,
          maxPlaylistsCount: action.payload?.maxPlaylistsCount,
          minCuratorsCount: action.payload?.minCuratorsCount,
          maxCuratorsCount: action.payload?.maxCuratorsCount,
          price: action.payload?.price,
        },
      }
      state.campaignToSchedule = campaignToSchedule as CampaignToScheduleState
      state.error = null
      state.updateCampaignLoading = SliceStatus.Succeeded
    })
  },
})

export const {
  setSchedulePlatform,
  setPlatform,
  setStartDate,
  setCampaignBudget,
  clearCampaignToSchedule,
} = mainCampaignProcess.actions
