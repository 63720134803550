import { createSlice } from '@reduxjs/toolkit'

import { NewPlaylistToSet } from 'src/Types'
import { setApiError } from 'src/Hooks/redux'

import { ApiError, NameSpace, SliceStatus } from '../types'

import { findPlaylist } from './api-actions'

export interface SearchPlaylistProcessState {
  status: SliceStatus
  error: ApiError | null
  data: NewPlaylistToSet | null
}
const initialState: SearchPlaylistProcessState = {
  status: SliceStatus.Idle,
  error: null,
  data: null,
}

export const searchPlaylistProcess = createSlice({
  initialState,
  name: NameSpace.SEARCH_PLAYLIST,
  reducers: {
    resetSearchPlaylist: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(findPlaylist.pending, (state) => {
        state.status = SliceStatus.Loading
      })
      .addCase(findPlaylist.fulfilled, (state, action) => {
        state.status = SliceStatus.Succeeded
        state.data = action.payload
      })
      .addCase(findPlaylist.rejected, (state, action) => {
        state.status = SliceStatus.Failed
        state.data = null
        state.error = setApiError(action)
      })
  },
})

export const { resetSearchPlaylist } = searchPlaylistProcess.actions
