import styled from 'styled-components'

import { colors, device } from 'src/Styled/variables'

export const PageContainer = styled.div`
  padding: 0;
  margin: 0 auto;
  overflow: hidden;
  padding-top: 4px;
`
export const PageContentWrapper = styled.div`
  background: ${colors.white};
  border-radius: 4px;
  padding: 24px;

  @media ${device.mobileMax} {
    background: transparent;
    padding: 0;
  }
`
