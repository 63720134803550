import { FC } from 'react'

import styled from 'styled-components'
import { Skeleton } from 'antd'
import { SkeletonNodeProps } from 'antd/es/skeleton/Node'

import { useAppSelector } from 'src/Hooks/redux'
import {
  getIsEmailBounced,
  getIsEmailConfirmed,
} from 'src/Redux/auth-process/userSlice/selectors'
import { settings } from 'src/Router/routes'
import { MAX_MOBILE_WIDTH } from 'src/Styled/variables'

import { CampaignApprovedAlert } from './CampaignApprovedAlert'
import { EmailConfirmationWarning } from './EmailConfirmationWarning'
import { EmailConfirmationError } from './EmailConfirmationError'

export const CHANGE_EMAIL_LINK = `${settings}/email`
const StyledSkeleton = styled(({ className, ...rest }: SkeletonNodeProps) => (
  <Skeleton.Input {...rest} rootClassName={className} active />
))`
  &.ant-skeleton-block {
    width: 120px;
    border-radius: 16px;
  }
  & > .ant-skeleton-input {
    min-width: 120px !important;
    width: 120px !important;
    height: 48px !important;
    border-radius: 16px !important;
    @media screen and (min-width: ${MAX_MOBILE_WIDTH}) {
      width: 240px !important;
    }
  }
`

interface AlertsBlockProps {
  loading: boolean
}

export const AlertsBlock: FC<AlertsBlockProps> = ({ loading }) => {
  const isEmailConfirmed = useAppSelector(getIsEmailConfirmed)
  const isEmailBounced = useAppSelector(getIsEmailBounced)

  if (loading) {
    return <StyledSkeleton />
  }

  if (!isEmailConfirmed) {
    return <EmailConfirmationWarning />
  }

  if (isEmailBounced) {
    return <EmailConfirmationError />
  }

  return <CampaignApprovedAlert />
}
