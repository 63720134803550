import { FC } from 'react'

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { numberFormatCommas } from 'src/Constants/functions'

import { Progress } from './Progress'

const Container = styled.div<{ hasValue: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  height: 48px;
  margin-top: ${({ hasValue }) => (hasValue ? '44px' : '10px')};
  margin-bottom: 40px;
`

export interface ViewsValue {
  price: number
  views: number
}

interface ViewsProgressChartProps {
  min: ViewsValue
  max: ViewsValue
  value?: ViewsValue
  completed?: boolean
}

export const ViewsProgressChart: FC<ViewsProgressChartProps> = ({
  min,
  max,
  value,
  completed,
}) => {
  const { t } = useTranslation()
  const minViews = `${numberFormatCommas(min.views)} ${t('influencer.views')}`
  const maxViews = `${numberFormatCommas(max.views)} ${t('influencer.views')}`
  const minPrice = `$${min.price.toFixed(2)}`
  const maxPrice = `$${max.price.toFixed(2)}`
  return (
    <Container hasValue={Boolean(value)}>
      <Progress
        completed={completed}
        views={minViews}
        price={minPrice}
        min={0}
        max={min.views}
        value={value}
      />
      <Progress
        completed={completed}
        views={maxViews}
        price={maxPrice}
        min={
          value?.views && value?.views < min.views
            ? min.views - Number(maxViews)
            : min.views
        }
        max={max.views}
        value={value}
      />
      <Progress
        completed={completed}
        hasRightRadius
        min={max.views}
        max={
          value?.views && value?.views >= max.views
            ? max.views + min.views
            : max.views
        }
        value={value}
      />
    </Container>
  )
}
