import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import Button from 'src/Components/Buttons/Button'
import { device } from 'src/Styled/variables'
import IconSpotify from 'src/Assets/Svg/spotify.svg?react'
import TikTokIcon from 'src/Assets/Svg/tiktok.svg?react'
import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { getTikTokTrackSelector } from 'src/Redux/tiktokTrack-process/selectors'
import { getSpotifyTrackSelector } from 'src/Redux/track-process/selectors'
import { ALBUM_IMAGES } from 'src/Constants/constants'
import { setTrackToInitialState } from 'src/Redux/track-process'
import { setTiktokTrackToInitialState } from 'src/Redux/tiktokTrack-process'
import { CampaignPlatformTypeEnum } from 'src/Types'

import { TrackCard } from './TrackCard/TrackCard'
import { useCreateNewCampaign } from './useCreateNewCampaign'

const StyledButton = styled(Button)`
  width: 184px;
  margin: 0 auto;
  margin-top: 16px;
  margin-bottom: 40px;
  @media ${device.mobileMin} {
    margin-bottom: 80px;
  }
`

export const PlatformCardContainer = styled.div`
  display: grid;
  text-align: center;
  padding: 0px 16px;
  grid-auto-flow: dense;
  @media ${device.mobileMin} {
    padding: 0px;
  }
`
export const SelectedNewCampaign: FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const tikTokTrack = useAppSelector(getTikTokTrackSelector)

  const spotifyTrack = useAppSelector(getSpotifyTrackSelector)

  const { loading, createNewSpotifyCampaign, createNewTiktokCampaign } =
    useCreateNewCampaign()

  const handleCreate = (): void => {
    if (spotifyTrack) {
      createNewSpotifyCampaign(spotifyTrack)
    }
    if (tikTokTrack) {
      createNewTiktokCampaign(tikTokTrack)
    }
  }
  const handleChangeTrack = (): void => {
    dispatch(setTrackToInitialState())
    dispatch(setTiktokTrackToInitialState())
  }
  if (!tikTokTrack && !spotifyTrack) {
    return null
  }
  return (
    <>
      <PlatformCardContainer>
        {spotifyTrack && (
          <TrackCard
            platform={CampaignPlatformTypeEnum.SPOTIFY}
            trackTitle={spotifyTrack?.name}
            trackPlayUrl={spotifyTrack.preview_url}
            trackDuration={spotifyTrack?.duration_ms}
            trackCoverUrl={spotifyTrack?.album?.images[ALBUM_IMAGES]?.url}
            trackAuthorName={spotifyTrack?.artists[0]?.name}
            title={t('createCampaignPage.spotifyCampaign')}
            Icon={IconSpotify}
            onChangeTrack={handleChangeTrack}
          />
        )}
        {tikTokTrack && (
          <TrackCard
            platform={CampaignPlatformTypeEnum.TIKTOK}
            trackTitle={tikTokTrack?.trackTitle}
            trackPlayUrl={tikTokTrack?.trackPlayUrl}
            trackDuration={tikTokTrack?.trackDuration}
            trackCoverUrl={tikTokTrack?.trackCoverUrl}
            trackAuthorName={tikTokTrack?.trackAuthorName}
            title={t('createCampaignPage.tikTokCampaign')}
            Icon={TikTokIcon}
            onChangeTrack={handleChangeTrack}
          />
        )}
      </PlatformCardContainer>
      <StyledButton loading={loading} type='green' onClick={handleCreate}>
        {t('createCampaignPage.createCampaign')}
      </StyledButton>
    </>
  )
}
