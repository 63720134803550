import { TikTokUpdateCampaign, TiktokCampaign } from 'src/Types/index'

export const mapScheduledSpotifyCampaignForUpdate = (
  campaign: TiktokCampaign,
): TikTokUpdateCampaign => {
  const genresIds = campaign.genres?.map((genre) => Number(genre.value))
  const languagesIds = campaign.languages?.map((language) =>
    Number(language.value),
  )

  return {
    id: Number(campaign.id),
    startDate: campaign.startDate,
    comment: campaign.comment,
    genres: genresIds,
    languages: languagesIds,
  }
}
