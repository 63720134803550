import { FC } from 'react'

import { useSelector } from 'react-redux'

import { useAppSelector } from 'src/Hooks/redux'
import { PaymentProcessingModal } from 'src/Components/PaymentProcessingModal'
import { getIsProcessingPage } from 'src/Redux/paypalTransactions-process/selectors'
import PaymentErrorsAlert from 'src/Components/PaymentErrorsAlert'
import { getPaymentAlertError } from 'src/Redux/payment-process/selectors'
import { PaymentMethods } from 'src/Components/PaymentMethods'
import { CampaignPlatformTypeEnum } from 'src/Types'
import { ScheduleCampaignSummary } from 'src/Components/ScheduleCampaignSummary'
import { CheckoutMobileFooter } from 'src/Components/CheckoutMobileFooter'
import { ProtectionAlertMobile } from 'src/Components/ProtectionAlertMobile'
import { getIsEnoughBalanceForTiktok } from 'src/Redux/schedule-process/audienceTiktokSlice/selectors'
import { usePaymentCancelledTikTokEvents } from 'src/Hooks/usePaymentCancelledTikTokEvents'
import { PaymentMethodsByStripeOnly } from 'src/Components/PaymentMethods/PaymentMethodsByStripeOnly'
import { RemovePaypalExperiment } from 'src/Hooks/Experiments/RemovePaypalExperiment'

import { useCheckTikTokCheckoutStepIsAllowed } from './helpers/useCheckTikTokCheckoutStepIsAllowed'

import { TiktokCheckoutContainer } from './styles'

export const TiktokCheckout: FC = () => {
  useCheckTikTokCheckoutStepIsAllowed()
  usePaymentCancelledTikTokEvents()

  const paymentError = useSelector(getPaymentAlertError)
  const isProcessingPageVisible = useAppSelector(getIsProcessingPage)
  const isEnoughBalance = useAppSelector(getIsEnoughBalanceForTiktok)

  return (
    <TiktokCheckoutContainer>
      {isProcessingPageVisible && <PaymentProcessingModal />}
      <div>
        {paymentError && <PaymentErrorsAlert name={paymentError} />}
        <RemovePaypalExperiment>
          <PaymentMethods
            isEnoughBalance={isEnoughBalance}
            currentPlatform={CampaignPlatformTypeEnum.TIKTOK}
          />
          {/* must be second child */}
          <PaymentMethodsByStripeOnly isEnoughBalance={isEnoughBalance} />
        </RemovePaypalExperiment>
      </div>
      <ScheduleCampaignSummary platformName={CampaignPlatformTypeEnum.TIKTOK} />
      <CheckoutMobileFooter platformType={CampaignPlatformTypeEnum.TIKTOK} />
      <ProtectionAlertMobile platform={CampaignPlatformTypeEnum.TIKTOK} />
    </TiktokCheckoutContainer>
  )
}
