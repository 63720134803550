import { FC, memo, useMemo } from 'react'

import styled, { useTheme } from 'styled-components'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

import { useAppSelector } from 'src/Hooks/redux'
import {
  getEstimatedTiktokViews,
  getIsTiktokAudienceLoading,
} from 'src/Redux/schedule-process/audienceTiktokSlice/selectors'
import { PrimaryHint } from 'src/Components/PrimaryHint'

const Container = styled.div`
  display: flex;
  padding: 16px;
  height: 90px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.common.primaryBorder};
  background: ${({ theme }) => theme.common.primaryBg};
`
const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const Title = styled.div`
  color: ${({ theme }) => theme.common.primaryText};
  font-family: 'Segoe UI';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`

const Value = styled(Title)`
  font-size: 18px;
  font-weight: 600;
`

const StyledSpin = styled(Spin)`
  margin: auto auto;
`

export const TiktokScheduleBudgetEstimation: FC = memo(() => {
  const theme = useTheme()
  const { t } = useTranslation()
  const { maxViews, minViews } = useAppSelector(getEstimatedTiktokViews)
  const loading = useAppSelector(getIsTiktokAudienceLoading)

  const value = useMemo(() => {
    if (!minViews && !maxViews) {
      return '-'
    }
    if (minViews === maxViews) {
      return `~${minViews}`
    }
    return `${minViews} - ${maxViews}`
  }, [maxViews, minViews])

  if (loading) {
    return (
      <Container>
        <StyledSpin
          style={{ color: theme.common.secondaryBorder }}
          indicator={<LoadingOutlined spin />}
          size='large'
        />
      </Container>
    )
  }

  return (
    <Container>
      <TitleContainer>
        <Title>{t('tiktokSchedulePage.potential')}</Title>
        <PrimaryHint>{t('tiktokSchedulePage.theRange')}</PrimaryHint>
      </TitleContainer>
      <Value>{value}</Value>
    </Container>
  )
})
