import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'

import { UserNotification } from 'src/Types'
import { api } from 'src/Services/api'
import { APP } from 'src/Configs/App'

import { ErrorCode, NameSpace } from '../types'

export const getArtistNotifications = createAsyncThunk<Array<UserNotification>>(
  `${NameSpace.Notifications}/getArtistNotifications`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await api.get<Array<UserNotification>>(
        `${APP.SERVER}/artist/notifications`,
      )
      return data
    } catch (error) {
      const { message, response } = error as AxiosError
      return rejectWithValue({
        message,
        code: response?.status || ErrorCode.InternalServerError,
      })
    }
  },
)

export const getCuratorNotifications = createAsyncThunk<
  Array<UserNotification>
>(
  `${NameSpace.Notifications}/getCuratorNotifications`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await api.get<Array<UserNotification>>(
        `${APP.SERVER}/curator/new-playlist-extra-scores`,
      )
      return data
    } catch (error) {
      const { message, response } = error as AxiosError
      return rejectWithValue({
        message,
        code: response?.status || ErrorCode.InternalServerError,
      })
    }
  },
)

export const markCuratorNotificationsAsRead = createAsyncThunk(
  `${NameSpace.Notifications}/markCuratorNotificationsAsRead`,
  async (_, { rejectWithValue }) => {
    try {
      await api.put(`${APP.SERVER}/curator/new-playlist-extra-scores`)

      return true
    } catch (error) {
      const { message, response } = error as AxiosError
      return rejectWithValue({
        message,
        code: response?.status || ErrorCode.InternalServerError,
      })
    }
  },
)

export const markArtistNotificationsAsRead = createAsyncThunk(
  `${NameSpace.Notifications}/markArtistNotificationsAsRead`,
  async (_, { rejectWithValue }) => {
    try {
      await api.put(`${APP.SERVER}/artist/notifications`)

      return true
    } catch (error) {
      const { message, response } = error as AxiosError
      return rejectWithValue({
        message,
        code: response?.status || ErrorCode.InternalServerError,
      })
    }
  },
)
