import { createSlice } from '@reduxjs/toolkit'

import { setApiError } from 'src/Hooks/redux'

import { ApiError, NameSpace } from '../types'

import { getFreeSpotifyAccessToken, getSpotifyAccessToken } from './api-actions'

export interface SpotifyTokenState {
  accessToken: string | null
  loading: boolean
  error: ApiError | null
}

const initialState: SpotifyTokenState = {
  accessToken: null,
  loading: false,
  error: null,
}

export const spotifyTokenProcess = createSlice({
  initialState,
  name: NameSpace.SpotifyToken,
  reducers: {},
  extraReducers: (builder) => {
    // getSpotifyAccessToken
    builder.addCase(getSpotifyAccessToken.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getSpotifyAccessToken.fulfilled, (state, action) => {
      state.loading = false
      state.accessToken = action.payload.access_token
      state.error = null
    })
    builder.addCase(getSpotifyAccessToken.rejected, (state, action) => {
      state.loading = false
      state.error = setApiError(action)
    })

    // getFreeSpotifyAccessToken
    builder.addCase(getFreeSpotifyAccessToken.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getFreeSpotifyAccessToken.fulfilled, (state, action) => {
      state.loading = false
      state.accessToken = action.payload.access_token
      state.error = null
    })
    builder.addCase(getFreeSpotifyAccessToken.rejected, (state, action) => {
      state.loading = false
      state.error = setApiError(action)
    })
  },
})
