import { FC } from 'react'

import { InfoHeader } from './components/InfoHeader'
import { PlansList } from './components/PlansList'
import { SubscriptionsError } from './components/SubscriptionsError'

import { PageContainer } from './styles'

export const Subscriptions: FC = () => (
  <PageContainer>
    <InfoHeader />
    <SubscriptionsError />
    <PlansList />
  </PageContainer>
)
