import { FC } from 'react'

import styled from 'styled-components'
import { Rate } from 'antd'
import { useTranslation } from 'react-i18next'

import avatar from 'src/Assets/Png/avatar.png'
import { SmartImage } from 'src/Components/SmartImage'
import { colorPalette, variables } from 'src/Styled/variables'
import { PrimaryDottedButton } from 'src/Components/Buttons/PrimaryDottedButton'
import TrustPilot from 'src/Assets/Svg/logo-trustpilot.svg?react'

const Container = styled.div<{
  $isActive: boolean
}>`
  display: flex;
  width: ${({ $isActive }) => ($isActive ? '100%' : '224px')};
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  transition: width 0.3s ease-in-out;
`

const Link = styled.a`
  font-family: Segoe UI;
  cursor: pointer;
  display: flex;
  height: 40px;
  max-width: 240px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  border: ${({ theme }) => `1px solid ${theme.button.primaryBorder}`};
  border-radius: 8px;
  background: ${({ theme }) => theme.button.primaryBg};
  color: ${({ theme }) => theme.button.thirdText};
  font-size: ${variables.fontSize14};
  font-style: normal;
  font-weight: ${variables.fontWeight400};
  line-height: 150%;

  &:hover {
    color: ${({ theme }) => theme.button.thirdText};
  }

  span {
    flex-shrink: 0;
  }
`

const StyledImage = styled(SmartImage)`
  width: 64px;
  height: 64px;
  object-fit: cover;
  border-radius: 50%;
`

export const Comment = styled.span<{
  $isActive: boolean
}>`
  font-family: Segoe UI;
  color: ${({ theme }) => theme.common.primaryText};
  font-size: ${variables.fontSize14};
  font-style: normal;
  font-weight: ${variables.fontWeight400};
  line-height: 150%;
  align-self: flex-start;

  display: ${({ $isActive }) => ($isActive ? 'block' : '-webkit-box')};
  -webkit-line-clamp: ${({ $isActive }) => ($isActive ? 'none' : '3')};
  -webkit-box-orient: ${({ $isActive }) => ($isActive ? 'none' : 'vertical')};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-line;
`

export const Name = styled.span`
  font-family: Segoe UI;
  color: ${({ theme }) => theme.common.primaryText};
  font-size: ${variables.fontSize14};
  font-style: normal;
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
`

const UserInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`

export interface ReviewProps {
  userImageUrl: string
  name: string
  comment: string
  link: string
  isActive: boolean
  onShowFullReview: (reviewId: number) => void
  id: number
  isHidden: boolean
}

export const Review: FC<ReviewProps> = ({
  userImageUrl,
  name,
  comment,
  link,
  isActive,
  isHidden,
  onShowFullReview,
  id,
}) => {
  const { t } = useTranslation()

  if (isHidden) {
    return null
  }

  return (
    <Container $isActive={isActive}>
      <UserInfoWrapper>
        <StyledImage fallbackSrc={avatar} src={userImageUrl} />
        <Name>{name}</Name>

        <Rate
          disabled
          defaultValue={5}
          style={{ color: colorPalette.mediumOrange }}
        />
        <div>
          <Comment $isActive={isActive}>{comment}</Comment>
          <PrimaryDottedButton
            onClick={() => {
              onShowFullReview(id)
            }}
          >
            {isActive
              ? t('whatArtistsThink.hideFullReview')
              : t('whatArtistsThink.showFullReview')}
          </PrimaryDottedButton>
        </div>
      </UserInfoWrapper>

      <Link href={link} target='_blank' rel='noopener noreferrer'>
        <span>{t('whatArtistsThink.readOn')}</span>

        <TrustPilot width={90} height={27} />
      </Link>
    </Container>
  )
}
