import { useCallback } from 'react'

import { DebouncedState, useDebouncedCallback } from 'use-debounce'
import { useParams } from 'react-router-dom'

import { useAppDispatch } from 'src/Hooks/redux'
import { CampaignPlatformTypeEnum } from 'src/Types'
import { setTracksToInitialState } from 'src/Redux/spotifyTracks-process'
import { setTiktokTracksToInitialState } from 'src/Redux/tiktokTrack-process'
import { DEBOUNCE_TIMER_1000 } from 'src/Constants/constants'
import {
  searchTikTokTrackByName,
  getTikTokTrackWithoutAuth,
} from 'src/Redux/tiktokTrack-process/api-actions'
import { getSpotifyTracks } from 'src/Redux/spotifyTracks-process/api-actions'
import { useGetFreeSpotifyTokenQuery } from 'src/Redux/freeSpotifyTokenApi'
import {
  enteredTrackUrlEvent,
  startedEnterTrackEvent,
} from 'src/Helpers/TagManager'

const isTikTokTrackUrl = (value?: string): boolean => {
  if (!value) {
    return false
  }

  return (
    value.startsWith('https://vm.tiktok.com/') ||
    value.startsWith('https://www.tiktok.com/music/') ||
    value.startsWith('https://vt.tiktok.com/') ||
    value.startsWith('https://www.tiktok.com/t/')
  )
}

export const useTrackSearchForm = (
  currentPlatform?: CampaignPlatformTypeEnum,
): {
  setTracksToInitialStateHandler: () => void
  debouncedFindTrack: DebouncedState<(currentText: string) => void>
  trackSearchHandler: ({ searchInput }: { searchInput: string }) => void
} => {
  const { data: tokenData } = useGetFreeSpotifyTokenQuery()
  const dispatch = useAppDispatch()
  const { platform: urlPlatform } = useParams<{
    platform: CampaignPlatformTypeEnum
  }>()

  const platform = currentPlatform || urlPlatform

  const setTracksToInitialStateHandler = useCallback((): void => {
    if (platform === CampaignPlatformTypeEnum.SPOTIFY) {
      dispatch(setTracksToInitialState())
    }
    if (platform === CampaignPlatformTypeEnum.TIKTOK) {
      dispatch(setTiktokTracksToInitialState())
    }
  }, [dispatch, platform])

  const trackSearchHandler = ({
    searchInput: searchInputValue,
  }: {
    searchInput: string
  }): void => {
    if (
      platform === CampaignPlatformTypeEnum.SPOTIFY &&
      tokenData?.accessToken &&
      searchInputValue
    ) {
      void dispatch(
        getSpotifyTracks({
          search: searchInputValue,
          token: tokenData.accessToken,
        }),
      )

      return
    }

    if (platform === CampaignPlatformTypeEnum.TIKTOK) {
      if (!isTikTokTrackUrl(searchInputValue)) {
        void dispatch(
          searchTikTokTrackByName({
            searchText: searchInputValue,
          }),
        )
      } else {
        void dispatch(
          getTikTokTrackWithoutAuth({
            searchText: searchInputValue,
          }),
        )
      }
    }
  }

  const debouncedFindTrack = useDebouncedCallback((currentText: string) => {
    if (currentText === '') {
      setTracksToInitialStateHandler()
    } else {
      trackSearchHandler({ searchInput: currentText })

      const eventsParams = {
        campaignPlatform: platform || CampaignPlatformTypeEnum.SPOTIFY,
        userId: null,
        isFirstCampaign: true,
        campaignNumber: 1,
      }
      if (currentText.includes('https://')) {
        enteredTrackUrlEvent(eventsParams)
      } else {
        startedEnterTrackEvent(eventsParams)
      }
    }
  }, DEBOUNCE_TIMER_1000)

  return {
    setTracksToInitialStateHandler,
    debouncedFindTrack,
    trackSearchHandler,
  }
}
