import styled from 'styled-components'

import { colors } from 'src/Styled/variables'

export const StyledCloseBtn = styled.button`
  width: 40px;
  height: 40px;
  background: ${colors.white};
  color: ${colors.greyTextWhiteBg};
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border: none;
  border-radius: 4px;
  cursor: pointer;
`
