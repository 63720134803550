import { createDraftSafeSelector } from '@reduxjs/toolkit'

import { RootState } from '..'
import { SliceStatus } from '../types'

import { TracksState } from './index'

const getTracksState = (state: RootState): TracksState => state.spotifyTracks

export const getTracks = createDraftSafeSelector(
  getTracksState,
  ({ tracks }) => tracks,
)

export const getIsLoadingSpotifyTracks = createDraftSafeSelector(
  getTracksState,
  ({ status }) => status === SliceStatus.Loading,
)

export const getSpotifyTracksError = createDraftSafeSelector(
  getTracksState,
  ({ error }) => error,
)
