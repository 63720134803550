import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import Button from 'src/Components/Buttons/Button'
import CheckedCircleIcon from 'src/Assets/Png/checked-circle.png'
import { colors, device, variables } from 'src/Styled/variables'

const SuccessContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 18px;
  padding-bottom: 18px;
  flex-direction: row;
  @media ${device.mobileMax} {
    padding-top: 70px;
    padding-bottom: 70px;
    flex-direction: column;
  }
`

const SuccessIcon = styled.img`
  margin-right: 16px;
  margin-bottom: 0px;
  width: 40px;
  height: 40px;
  @media ${device.mobileMax} {
    margin-bottom: 16px;
  }
`
const SuccessText = styled.p`
  font-size: ${variables.fontSize18};
  color: ${colors.mainText};
  text-align: center;
`
const ButtonContainer = styled.div`
  margin: 50px auto 24px;
  width: 137px;
  @media ${device.mobileMax} {
    margin: 0 auto;
  }
`
const Container = styled.div`
  @media ${device.mobileMax} {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    background-color: ${colors.white};
    top: 0;
    left: 0;
  }
`
interface Props {
  isNew: boolean
  onClose: () => void
}
export const SuccessEdit: FC<Props> = ({ isNew, onClose }) => {
  const { t } = useTranslation()
  const text = isNew
    ? t('scheduleCampaignPage.successTextAdd')
    : t('scheduleCampaignPage.successTextEdit')
  return (
    <Container>
      <SuccessContainer>
        <SuccessIcon src={CheckedCircleIcon} alt='CheckedIcon' />
        <SuccessText>{text}</SuccessText>
      </SuccessContainer>
      <ButtonContainer>
        <Button type='white' onClick={onClose}>
          {t('targetGenres.continue')}
        </Button>
      </ButtonContainer>
    </Container>
  )
}
