import styled, { createGlobalStyle } from 'styled-components'

import { colors } from 'src/Styled/variables'

export const StyledTooltip = styled.div``
export const GlobalStyles = createGlobalStyle`
  .ant-tooltip-inner {
    background: ${colors.white} !important;
    color: ${colors.greyTextWhiteBg} !important;
    padding: 8px 16px !important;
  }
  .ant-tooltip-arrow {
    display: none !important;
  }
`
