import { FC } from 'react'

import { PopupButton } from '@typeform/embed-react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import Chat from 'src/Assets/Svg/sidebar-icons/Chat.svg?react'
import Feedback from 'src/Assets/Svg/sidebar-icons/Feedback.svg?react'
import Plus from 'src/Assets/Svg/sidebar-icons/Plus.svg?react'

import { SidebarMenuItem } from '../SidebarMenuItem'
import { SidebarProps } from '../..'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
`

export type SidebarButtonsProps = Pick<
  SidebarProps,
  | 'collapsed'
  | 'showFeatureRequest'
  | 'showFeedback'
  | 'onFeedbackClick'
  | 'onChatClick'
  | 'onFeatureClick'
  | 'attributeValue'
  | 'email'
>

const StyledPopupButton = styled(PopupButton)`
  background-color: transparent;
  padding: 0;
  display: flex;
`

export const SidebarButtons: FC<SidebarButtonsProps> = (props) => {
  const {
    collapsed,
    showFeatureRequest,
    showFeedback,
    onChatClick,
    onFeatureClick,
    attributeValue,
    email,
  } = props
  const { t } = useTranslation()

  return (
    <Container>
      {showFeatureRequest && (
        <div
          onMouseDown={onFeatureClick}
          onTouchStart={onFeatureClick}
          id={attributeValue || ''}
          data-productlift-sidebar={attributeValue}
        >
          <SidebarMenuItem
            onClick={onFeatureClick}
            icon={Plus}
            collapsed={collapsed}
            type='button'
          >
            {t('sidebar.requestFeature')}
          </SidebarMenuItem>
        </div>
      )}

      <SidebarMenuItem
        onClick={onChatClick}
        icon={Chat}
        collapsed={collapsed}
        type='button'
      >
        {t('sidebar.liveChat')}
      </SidebarMenuItem>

      {showFeedback && (
        <StyledPopupButton
          id='Xh2CXR6m'
          hidden={{
            email: email || '',
            is_feed: 'yes',
          }}
        >
          <SidebarMenuItem icon={Feedback} collapsed={collapsed} type='button'>
            {t('sidebar.shareFeedback')}
          </SidebarMenuItem>
        </StyledPopupButton>
      )}
    </Container>
  )
}
