import { FunctionComponent } from 'react'

import { cropText } from 'src/Helpers/layoutHelpers'
import { SHARING_IMAGE_LENGTH_LIMIT } from 'src/Constants/numeric'
import QuotesIcon from 'src/Assets/Svg/quotes.svg?react'
import logoImg from 'src/Assets/Png/logo.png'
import { SharingImageDisplayModeEnum } from 'src/Types/index'
import { useSpotifyCampaignDetails } from 'src/Redux/campaignDetailsApi/useSpotifyCampaignDetails'

import { ImageToShareProps } from './props'

import {
  CustomImageContainer,
  ReviewText,
  TrackContainer,
  TrackAvatar,
  TrackInfoContainer,
  TrackName,
  TrackAuthor,
  CuratorContainer,
  CuratorAvatar,
  CuratorInfoContainer,
  CuratorName,
  CuratorRole,
  Logo,
} from './styles'

export const ImageToShare: FunctionComponent<ImageToShareProps> = ({
  imageWidth,
  isForDownload,
  review,
  displayMode,
}) => {
  const { campaignDetails } = useSpotifyCampaignDetails()

  const firstName = review.Curator.User.first_name || ''
  const lastName = review.Curator.User.last_name || ''
  return (
    <CustomImageContainer
      containerWidth={imageWidth}
      displayMode={displayMode}
      id={isForDownload ? 'downloadReviewImage' : ''}
    >
      <Logo src={logoImg} />
      <TrackContainer>
        <TrackAvatar src={campaignDetails?.Track.track_image} />
        <TrackInfoContainer>
          <TrackName>{campaignDetails?.Track.track_name}</TrackName>
          <TrackAuthor>{campaignDetails?.Track.artist_name}</TrackAuthor>
        </TrackInfoContainer>
      </TrackContainer>
      <ReviewText>
        {displayMode === SharingImageDisplayModeEnum.square
          ? cropText(review.comment, SHARING_IMAGE_LENGTH_LIMIT)
          : review.comment}
      </ReviewText>
      <QuotesIcon />
      <CuratorContainer>
        {review.Curator.User.user_photo_url && (
          <CuratorAvatar src={review.Curator.User.user_photo_url} />
        )}
        <CuratorInfoContainer>
          <CuratorName>{`${firstName} ${lastName}`}</CuratorName>
          <CuratorRole>Curator</CuratorRole>
        </CuratorInfoContainer>
      </CuratorContainer>
    </CustomImageContainer>
  )
}
