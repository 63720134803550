import React, { FunctionComponent, useMemo } from 'react'

import { useTranslation } from 'react-i18next'

import Button from 'src/Components/Buttons/Button'

interface PlaylistModalButtonProps {
  isLoading: boolean
  noValue: boolean
  labels: boolean
  onClickHandler: () => void
}

export const PlaylistModalButton: FunctionComponent<
  PlaylistModalButtonProps
> = ({ isLoading, noValue, labels, onClickHandler }) => {
  const { t } = useTranslation()

  const buttonText = useMemo(() => {
    if (isLoading) {
      return t('playlistsPage.searching')
    }
    if (noValue || labels) {
      return t('playlistsPage.searchPlaylist')
    }
    return t('playlistsPage.addPlaylist')
  }, [isLoading, noValue, labels, t])

  const isDisabled = isLoading || noValue || labels

  return (
    <Button type='green' disabled={isDisabled} onClick={onClickHandler}>
      {buttonText}
    </Button>
  )
}
