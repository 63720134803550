import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'

import { APP } from 'src/Configs/App'
import { ErrorCode, NameSpace } from 'src/Redux/types'
import { api } from 'src/Services/api'

interface GetReferralHashResponse {
  referralCode: string
}
export const getReferralHash = createAsyncThunk(
  `${NameSpace.REFERRAL_HASH}/getReferralHash`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await api.patch<GetReferralHashResponse>(
        `${APP.TIKTOK_SERVER}/user/referral/generate-hash`,
      )
      return data
    } catch (error) {
      const { message, response } = error as AxiosError
      return rejectWithValue({
        message,
        code: response?.status || ErrorCode.InternalServerError,
      })
    }
  },
)
