import React, { FC } from 'react'

import { t } from 'i18next'
import styled from 'styled-components'

import { PremoderationStatus } from 'src/Constants/enums'
import New from 'src/Assets/Svg/campaignDetails/new.svg?react'
import Confirmed from 'src/Assets/Svg/campaignDetails/confirmed.svg?react'
import Rejected from 'src/Assets/Svg/campaignDetails/rejected.svg?react'
import Monitoring from 'src/Assets/Svg/campaignDetails/monitoring.svg?react'

interface VideoStatusInfo {
  text: string
  icon: FC
}
export const getVideoStatusWithIcon = (
  preModerationStatus?: PremoderationStatus,
): VideoStatusInfo => {
  switch (preModerationStatus) {
    case PremoderationStatus.NEW_VIDEO:
      return {
        text: t('campaignResultsPage.newVideo'),
        icon: New,
      }

    case PremoderationStatus.MONITORING:
      return {
        text: t('campaignResultsPage.monitoring'),
        icon: Monitoring,
      }

    case PremoderationStatus.CONFIRMED:
      return {
        text: t('campaignResultsPage.confirmed'),
        icon: Confirmed,
      }

    case PremoderationStatus.REJECTED:
      return {
        text: t('campaignResultsPage.rejected'),
        icon: Rejected,
      }

    default:
      return {
        text: t('campaignResultsPage.unknownStatus'),
        icon: Rejected,
      }
  }
}

const Container = styled.div`
  font-family: 'Segoe UI';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.common.primaryText};
  gap: 8px;
  > span {
    display: flex;
    align-self: center;
  }
`

interface VideoStatusProps {
  videoPreModerationStatus?: PremoderationStatus
}

export const VideoStatusBadge: FC<VideoStatusProps> = ({
  videoPreModerationStatus,
}) => {
  const { text, icon: Icon } = getVideoStatusWithIcon(videoPreModerationStatus)

  return (
    <Container>
      <span>{<Icon />}</span>
      <span>{text}</span>
    </Container>
  )
}
