import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import {
  closeCampaignDetails,
  setEventDetails,
} from 'src/Redux/influencer-process'
import {
  getEventDetails,
  getOpenedCampaign,
} from 'src/Redux/influencer-process/selectors'
import { Desktop } from 'src/Components/Responsive'
import {
  EventDetails,
  InfluencerCampaign,
  InfluencerEventStatus,
} from 'src/Types/index'
import Modal from 'src/Components/Modal'
import checkedCircle from 'src/Assets/Png/checked-circle.png'
import { capitalizeFirstLetter } from 'src/Helpers/layoutHelpers'
import { TrackInfoBlock } from 'src/Containers/Tracks/components/TrackInfoBlock'
import { useAppDispatch } from 'src/Hooks/redux'

import { AcceptRules } from '../AcceptRules'
import { UploadVideo } from '../Footer/UploadVideo'
import { Track } from '../Track'
import { TrackDetails } from '../TrackDetails'
import { RewardCreatorComponent } from '../RewardCreatorComponent'
import { ArtistComment } from '../ArtistComment'
import { AcceptTrack } from '../Footer/AcceptTrack'
import { Awaiting } from '../Footer/Awaiting'
import { Canceled } from '../Footer/Canceled'
import { Complete } from '../Footer/Complete'
import { CanceledMessage } from '../CanceledMessage'
import { TrackModalAlerts } from '../TrackModalAlerts/TracksAlerts'

import {
  CreatorDetailsModalContent,
  AttentionText,
  AttentionWrapper,
  MainText,
  RulesWrapper,
  SuccessfullyMessageWrapper,
  SuccessIcon,
  TitleDetailsModal,
  UnderlinedText,
} from './styles'

const footers = {
  [InfluencerEventStatus.ACCEPTED]: UploadVideo,
  [InfluencerEventStatus.AWAITING_REVIEW]: Awaiting,
  [InfluencerEventStatus.AWAITING_MODERATOR_REVIEW]: Awaiting,
  [InfluencerEventStatus.APPROVED_BY_ARTIST]: Complete,
  [InfluencerEventStatus.APPROVED_AUTOMATICALLY]: Complete,
  [InfluencerEventStatus.APPROVED_BY_ADMIN]: Complete,
  [InfluencerEventStatus.CANCELED]: Canceled,
  [InfluencerEventStatus.CANCELED_BY_ARTIST]: Canceled,
  [InfluencerEventStatus.CAMPAIGN_STOPED]: Canceled,
  [InfluencerEventStatus.DECLINED]: Canceled,
  [InfluencerEventStatus.SUBMISSION_AWAITING]: Canceled,
  [InfluencerEventStatus.SUBMISSION_REJECTED]: Canceled,
}

export const CreatorDetailsModal: FunctionComponent = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const campaign = useSelector(getOpenedCampaign)
  const eventDetails = useSelector(getEventDetails)

  const Footer = (eventDetails && footers[eventDetails?.status]) || AcceptTrack

  const [actualEntity, setActualEntity] = useState<
    InfluencerCampaign | EventDetails
  >()

  const [isRulesPage, setIsRulesPage] = useState(false)
  const [isSuccessAcceptedPage, setIsSuccessAcceptedPage] = useState(false)

  const onClose = useCallback((): void => {
    if (isRulesPage) {
      setIsRulesPage(false)
    } else if (isSuccessAcceptedPage) {
      setIsSuccessAcceptedPage(false)
    } else {
      dispatch(closeCampaignDetails())
      dispatch(setEventDetails(null))
    }
  }, [dispatch, isRulesPage, isSuccessAcceptedPage])

  const onAcceptTrack = useCallback(() => {
    if (!isRulesPage) {
      setIsRulesPage(true)
    } else {
      setIsSuccessAcceptedPage(true)
      setIsRulesPage(false)
    }
  }, [isRulesPage])

  useEffect(() => {
    if (eventDetails) {
      setActualEntity(eventDetails)
    } else {
      setActualEntity(campaign)
    }
  }, [eventDetails, campaign])

  const isUploadOrDetailsState = !isRulesPage && !isSuccessAcceptedPage

  const modalTitle =
    actualEntity?.status === InfluencerEventStatus.ACCEPTED
      ? t('influencer.uploadVideo')
      : t('influencer.details')

  const modalContent = useMemo(() => {
    if (actualEntity) {
      return (
        <CreatorDetailsModalContent>
          {isUploadOrDetailsState && (
            <>
              <Desktop>
                <TitleDetailsModal>{modalTitle}</TitleDetailsModal>
              </Desktop>
              {actualEntity.status === InfluencerEventStatus.ACCEPTED ? (
                <>
                  <>
                    <Desktop>
                      <TrackModalAlerts />
                    </Desktop>

                    <TrackInfoBlock
                      audio={{
                        url: actualEntity?.trackPlayUrl,
                        cover: actualEntity?.trackCoverUrl,
                        authorName: actualEntity?.trackAuthorName,
                        trackName: actualEntity?.trackTitle,
                        link: actualEntity?.trackUrl,
                      }}
                    />
                  </>
                  <AttentionWrapper>
                    <AttentionText>{t('influencer.attention')}</AttentionText>{' '}
                    <MainText>
                      <span>{t('influencer.attentionDeclaimer1')} </span>
                      &quot;
                      <UnderlinedText>
                        {t('influencer.useThisSound')}
                      </UnderlinedText>
                      &quot;
                      <span> {t('influencer.attentionDeclaimer2')}</span>
                    </MainText>
                  </AttentionWrapper>
                </>
              ) : (
                <Track
                  audio={{
                    url: actualEntity?.trackPlayUrl,
                    cover: actualEntity?.trackCoverUrl,
                    authorName: actualEntity?.trackAuthorName,
                    trackName: actualEntity?.trackTitle,
                    link: actualEntity?.trackUrl,
                  }}
                />
              )}

              <TrackDetails
                genres={actualEntity.genres?.map((item) =>
                  capitalizeFirstLetter(item),
                )}
                status={actualEntity.status}
                vocal={actualEntity.languages}
                stars={actualEntity.stars}
                comment={actualEntity.comment}
              />

              {actualEntity.reward && (
                <RewardCreatorComponent
                  reward={actualEntity.reward}
                  status={actualEntity.status}
                  isPaymentReceived={actualEntity.isPaymentReceived}
                  paymentReceivedDate={actualEntity.paymentWillBeReceived}
                />
              )}

              <ArtistComment
                comment={
                  actualEntity.campaignArtistComment || actualEntity.comment
                }
              />
            </>
          )}
          {isRulesPage && (
            <RulesWrapper>
              <AcceptRules />
            </RulesWrapper>
          )}

          {isSuccessAcceptedPage && (
            <SuccessfullyMessageWrapper>
              <SuccessIcon src={checkedCircle} alt='CheckedIcon' />
              <span>{t('influencer.trackAccepted')}</span>
            </SuccessfullyMessageWrapper>
          )}

          <CanceledMessage status={actualEntity.status} />

          {Footer && (
            <Footer
              t={t}
              isRulesPage={isRulesPage}
              isSuccessAcceptedPage={isSuccessAcceptedPage}
              setIsSuccessAcceptedPage={setIsSuccessAcceptedPage}
              onClose={onClose}
              onAcceptTrack={onAcceptTrack}
            />
          )}
        </CreatorDetailsModalContent>
      )
    }
    return null
  }, [
    actualEntity,
    isUploadOrDetailsState,
    modalTitle,
    t,
    isRulesPage,
    isSuccessAcceptedPage,
    Footer,
    onClose,
    onAcceptTrack,
  ])

  if (Boolean(campaign) || Boolean(eventDetails)) {
    return (
      <Modal
        title={
          actualEntity?.status !== 'accepted'
            ? t('influencer.details')
            : t('influencer.uploadVideo')
        }
        isModalVisible={Boolean(campaign) || Boolean(eventDetails)}
        closeModal={onClose}
        fullScreenMobileMode={isRulesPage || isSuccessAcceptedPage}
      >
        {modalContent}
      </Modal>
    )
  }

  return null
}
