import React, { FC } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { getCashback } from 'src/Components/ScheduleSubscription/helpers'
import { useAppSelector } from 'src/Hooks/redux'
import { useShowSubscriptionUpsell } from 'src/Hooks/useShowSubscriptionUpsell'
import { getHasActiveOrCanceledSubscriptionSelector } from 'src/Redux/subscription-process/subscription/selectors'
import { PrimaryScheduleSubscriptionCashback } from 'src/Components/ScheduleSubscription/PrimaryScheduleSubscriptionCashback'

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 48px;
  padding: 8px 16px;
  align-items: center;
  gap: 8px;
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.common.primaryBorder};
  background: ${({ theme }) => theme.common.primaryBg};
`
interface Props {
  price: number
  symbol: string
}
export const PrimaryScheduleSubscription: FC<Props> = ({ price, symbol }) => {
  const hasActivePlan = useAppSelector(
    getHasActiveOrCanceledSubscriptionSelector,
  )
  const show = useShowSubscriptionUpsell()
  const { t } = useTranslation()

  if (hasActivePlan) {
    return (
      <Wrapper>
        <PrimaryScheduleSubscriptionCashback price={price} symbol={symbol} />
      </Wrapper>
    )
  }
  if (!show) {
    return null
  }

  const defaultCashback = getCashback({ price })
  const defaultText = `${t(
    'scheduleSubscription.potential',
  )} ${symbol}${defaultCashback}`

  return <Wrapper>{defaultText}</Wrapper>
}
