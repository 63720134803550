import { FC } from 'react'

import styled from 'styled-components'

import { MAX_MOBILE_WIDTH, MAX_TABLET_WIDTH } from 'src/Styled/variables'

import { CampaignStatus } from '../CampaignStatus'

import { CampaignListItemProps } from '.'

type CampaignListItemStatusProps = Pick<
  CampaignListItemProps,
  'status' | 'statusText'
>
const Container = styled.div`
  display: flex;
  padding-left: 84px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  @media screen and (min-width: ${MAX_MOBILE_WIDTH}) {
    padding-left: 0px;
    width: 200px;
  }
  @media screen and (min-width: ${MAX_TABLET_WIDTH}) {
    width: 240px;
  }
`

const Text = styled.div`
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  color: ${({ theme }) => theme.common.primaryText};
  display: -webkit-box;
  flex: 1;
  font-family: 'Segoe UI';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin-top: -1px;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
`
const StyledInfo = styled.div`
  align-items: center;
  display: flex;
  gap: 10px;
  justify-content: center;
  padding: 0px 0px 0px 24px;
  position: relative;
`
export const CampaignListItemStatus: FC<CampaignListItemStatusProps> = (
  props,
) => {
  const { status, statusText } = props
  return (
    <Container>
      <CampaignStatus status={status} />
      <StyledInfo>
        <Text>{statusText}</Text>
      </StyledInfo>
    </Container>
  )
}
