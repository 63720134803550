import { createAsyncThunk, unwrapResult } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'

import { api } from 'src/Services/api'
import { APP } from 'src/Configs/App'

import { NameSpace, ErrorCode } from '../types'
import { getPlaylists } from '../playlists-process/api-actions'

import { mapLevelsWithPlaylists } from './helpers'

export interface CuratorLevelResponse {
  rank: number
  score_required: number
  reward_with_placement: number
  reward_without_placement: number
  reward_per_review: number
}

export const getLevels = createAsyncThunk(
  `${NameSpace.CURATOR_LEVELS}/getLevels`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await api.get<CuratorLevelResponse[]>(
        `${APP.SERVER}/curator/ranks`,
      )
      return data
    } catch (error) {
      const { message, response } = error as AxiosError
      return rejectWithValue({
        message,
        code: response?.status || ErrorCode.InternalServerError,
      })
    }
  },
)

export const getLevelsWithPlaylists = createAsyncThunk(
  `${NameSpace.CURATOR_LEVELS}/getLevelsWithPlaylists`,
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const levels = await dispatch(getLevels())
      const playlists = await dispatch(getPlaylists())

      const playlistsData = unwrapResult(playlists)
      const levelsData = unwrapResult(levels)

      return mapLevelsWithPlaylists(levelsData, playlistsData)
    } catch (error) {
      const { message, response } = error as AxiosError
      return rejectWithValue({
        message,
        code: response?.status || ErrorCode.InternalServerError,
      })
    }
  },
)
