import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { APP } from 'src/Configs/App'
import { convertTimeHandler } from 'src/Constants/functions'

interface SpotifyArtist {
  name?: string
}

export interface SpotifyTrackResponse {
  id?: string
  name?: string
  duration_ms?: number
  artists: SpotifyArtist[]
  album: { images?: { url?: string }[] }
  preview_url?: string
}
export interface TiktokTrackResponse {
  isOriginalSound?: boolean
  trackAuthorName?: string
  trackCoverUrl?: string
  trackDuration?: number
  trackPlayUrl?: string
  trackTiktokId: string
  trackTitle?: string
  trackUrl?: string
}

export interface Track {
  trackId?: string
  trackName?: string
  trackDuration?: string
  artistName?: string
  trackCoverUrl?: string
  trackPreviewUrl?: string
  trackDurationInMs?: number
  originalSound?: TiktokTrackResponse | SpotifyTrackResponse
}

interface SpotifyTrackBody {
  trackId?: string
  token?: string
}

const MILLISECONDS_PER_SECOND = 1000

export const firstTrackApi = createApi({
  reducerPath: 'firstTrackApi',
  tagTypes: ['Track'],
  baseQuery: fetchBaseQuery({ baseUrl: '' }),
  endpoints: (builder) => ({
    getFirstSpotifyTrack: builder.query<Track, SpotifyTrackBody>({
      query: ({ trackId, token }) => ({
        url: `https://api.spotify.com/v1/tracks/${trackId}`,
        headers: { Authorization: `Bearer ${token}` },
      }),
      transformResponse: (response: SpotifyTrackResponse) => ({
        trackId: response.id,
        trackName: response.name,
        trackDuration: convertTimeHandler({
          value: response.duration_ms || 0,
          type: 'ms',
        }),
        artistName: response.artists[0].name,
        trackCoverUrl:
          (response.album.images &&
            response.album.images[0] &&
            response.album.images[0].url) ||
          '',
        trackPreviewUrl: response.preview_url,
        trackDurationInMs: response?.duration_ms,
        originalSound: response,
      }),
      providesTags: ['Track'],
    }),
    getFirstTikTokTrack: builder.query<Track, string>({
      query: (trackId) => ({
        url: `${APP.TIKTOK_SERVER}/no_auth/artist/tiktok/tiktok-track-info?search=https://www.tiktok.com/music/-${trackId}`,
      }),
      transformResponse: (response: TiktokTrackResponse) => ({
        trackId: response.trackTiktokId,
        trackName: response?.trackTitle || '',
        artistName: response?.trackAuthorName || '',
        trackCoverUrl: response?.trackCoverUrl || '',
        trackDuration: convertTimeHandler({
          value: response?.trackDuration || 0,
          type: 's',
        }),
        trackPreviewUrl: response?.trackPlayUrl || '',
        trackDurationInMs:
          (response?.trackDuration || 0) * MILLISECONDS_PER_SECOND,
        originalSound: response,
      }),
      providesTags: ['Track'],
    }),
  }),
})

export const { useGetFirstSpotifyTrackQuery, useGetFirstTikTokTrackQuery } =
  firstTrackApi
