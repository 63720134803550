import { createSlice } from '@reduxjs/toolkit'

import { NameSpace, SliceStatus } from '../types'

import { applicantUpdateEmail } from './api-actions'

export interface CreatorRegisterState {
  status: SliceStatus
}
const initialState: CreatorRegisterState = {
  status: SliceStatus.Idle,
}
export const creatorRegister = createSlice({
  initialState,
  name: NameSpace.CRETOR_REGISTER,
  reducers: {
    resetCreatorError: (state) => {
      state.status = SliceStatus.Idle
    },
  },
  extraReducers: (builder) => {
    builder.addCase(applicantUpdateEmail.pending, (state) => {
      state.status = SliceStatus.Loading
    })
    builder.addCase(applicantUpdateEmail.fulfilled, (state) => {
      state.status = SliceStatus.Succeeded
    })
    builder.addCase(applicantUpdateEmail.rejected, (state) => {
      state.status = SliceStatus.Failed
    })
  },
})

export const { resetCreatorError } = creatorRegister.actions
