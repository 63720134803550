import { APP } from 'src/Configs/App'
import { rtkApi } from 'src/Services/rtkApi'
import { Meta } from 'src/Types'
import { SoundStatus } from 'src/Containers/SoundsPage/components/SoundsList'

import { Sound } from '../types'

export interface StoppedSounds extends Sound {
  status: SoundStatus.STOPPED
}

interface NewSoundsResponse {
  data: StoppedSounds[]
  meta: Meta
}

const creatorStoppedSoundsApi = rtkApi.injectEndpoints({
  endpoints(build) {
    const pageSize = 10

    return {
      stoppedSounds: build.query<NewSoundsResponse, void>({
        query: () => ({
          url: `${APP.TIKTOK_SERVER}/creators/events?pageSize=${pageSize}&pageNumber=1&statuses=campaign_stopped`,
        }),
      }),
    }
  },
})

export const selectStoppedSoundsCount = ({
  data,
}: {
  data?: NewSoundsResponse
}): { stoppedSoundsSoundsCount: number } => ({
  stoppedSoundsSoundsCount: data?.meta.itemCount || 0,
})

export const { useStoppedSoundsQuery } = creatorStoppedSoundsApi
