import React, { ReactNode } from 'react'

import styled, { css, keyframes } from 'styled-components'

import PreLoader from 'src/Assets/Svg/preloader.svg?react'

const wave = keyframes`
  0%, 100% {
    transform: scaleY(1);
  }
  50% {
    transform: scaleY(0.5);
  }
`

const loadingStyle = css`
  path {
    transform-origin: center;
  }
  path:nth-of-type(1) {
    animation: ${wave} 1s ease-in-out infinite;
  }
  path:nth-of-type(2) {
    animation: ${wave} 1s ease-in-out infinite;
    animation-delay: 0.1s;
  }
  path:nth-of-type(3) {
    animation: ${wave} 1s ease-in-out infinite;
    animation-delay: 0.2s;
  }
  path:nth-of-type(4) {
    animation: ${wave} 1s ease-in-out infinite;
    animation-delay: 0.3s;
  }
  path:nth-of-type(5) {
    animation: ${wave} 1s ease-in-out infinite;
    animation-delay: 0.4s;
  }
  path:nth-of-type(6) {
    animation: ${wave} 1s ease-in-out infinite;
    animation-delay: 0.5s;
  }
  path:nth-of-type(7) {
    animation: ${wave} 1s ease-in-out infinite;
    animation-delay: 0.6s;
  }
`

const PreLoaderContainer = styled.div`
  display: grid;
  align-self: center;
  justify-self: center;
  justify-content: center;
  align-content: center;
  svg {
    ${loadingStyle}
  }
`

export const LoadingSVG = (): ReactNode => (
  <PreLoaderContainer>
    <PreLoader />
  </PreLoaderContainer>
)
