import { FC, useEffect, useState } from 'react'

import { useForm } from 'react-hook-form'
import styled, { css } from 'styled-components'
import { useMediaQuery } from 'react-responsive'

import { colors, device } from 'src/Styled/variables'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import { CampaignPlatformTypeEnum, TikTokTrack, Track } from 'src/Types'

import { HowToGetYourTrackURLButton } from '../HowToGetYourTrackURLButton'

import { SearchTrackFormResult } from './SearchTrackFormResult'
import { SearchTrackFormMessages } from './SearchTrackFormMessages'
import { SearchFormInput } from './SearchFormInput'

interface SearchTrackFormProps {
  placeholderText: string
  loading?: boolean
  errorMessage?: string
  onSubmit: (data: SearchTrackFormDataProps) => void
  tracks: TikTokTrack[] | Track[]
  platform: CampaignPlatformTypeEnum
  onClearTracks: () => void
  onCheckTrack: (url: string) => void
  onClickBack?: () => void
  isFAQ?: boolean
  show?: boolean
}

export interface SearchTrackFormDataProps {
  searchInput: string
}

interface Props {
  isErrorOnMobile?: boolean
  isSame?: boolean
  isFAQ?: boolean
  show?: boolean
}

export const StyledTrackSearch = styled.div<Props>`
  padding: 0px;
  padding-bottom: ${(props: Props) => props.isSame && '72px'};

  ${({ show }) => {
    if (show) {
      return css`
        @media ${device.mobileMax} {
          position: fixed;
          z-index: 1;
          overflow-y: auto;
          top: 0;
          left: 0;
          width: 100%;
          bottom: 0;
          right: 0;
        }
      `
    }
    return css`
      @media ${device.mobileMax} {
        padding: 0px;
        background-color: transparent;
      }
    `
  }}
`

export const SearchForm = styled.form<Props>`
  background-color: transparent;
  ${({ show }) => {
    if (show) {
      return css`
        -webkit-text-size-adjust: none;
        height: 100vh;
        position: relative;
        background-color: ${colors.white};
        @media ${device.mobileMin} {
          height: auto;
          background-color: transparent;
        }
      `
    }
    return css``
  }}
`

export const TrackSearchAuth: FC<SearchTrackFormProps> = ({
  placeholderText,
  errorMessage,
  onSubmit,
  tracks,
  platform,
  onCheckTrack,
  onClearTracks,
  loading,
  onClickBack,
}) => {
  const [isShowFullscreenInput, setShowFullscreenInput] = useState(false)

  const isMobile = useMediaQuery({ query: '(max-width: 567px)' })

  const { search, setSearch } = useCustomSearchParams()
  const urlTrackName = search.get('trackName')
  const { control, handleSubmit, setValue } = useForm<SearchTrackFormDataProps>(
    {
      mode: 'onChange',
    },
  )
  const clearInput = (): void => {
    setValue('searchInput', '')
  }

  useEffect(() => {
    if (urlTrackName) {
      setValue('searchInput', urlTrackName)
      void handleSubmit(onSubmit)().then((res) => {
        setSearch({
          trackName: null,
        })
        return res
      })
    }
  }, [handleSubmit, setValue, onSubmit, urlTrackName, setSearch])

  useEffect(
    () => () => {
      setShowFullscreenInput(false)
    },
    [],
  )

  const handleFormClick = (): void => {
    if (isMobile) {
      setShowFullscreenInput(true)
    }
  }

  return (
    <StyledTrackSearch
      onClick={handleFormClick}
      show={isShowFullscreenInput}
      isSame={Boolean(errorMessage)}
    >
      <SearchForm
        show={isShowFullscreenInput}
        isErrorOnMobile={Boolean(errorMessage)}
        onSubmit={handleSubmit(onSubmit)}
      >
        <>
          <SearchFormInput
            show={isShowFullscreenInput}
            isFAQ={isShowFullscreenInput}
            placeholderText={placeholderText}
            clearInput={clearInput}
            control={control}
            onClickBack={onClickBack}
            onInputChange={(value) => onSubmit({ searchInput: value })}
          />
          {!errorMessage && !loading && (
            <SearchTrackFormResult
              platform={platform}
              tracks={tracks}
              onCheckTrack={onCheckTrack}
              onClearTracks={onClearTracks}
            />
          )}
          <SearchTrackFormMessages
            error={errorMessage}
            loading={loading}
            platform={platform}
          />

          <HowToGetYourTrackURLButton platform={platform} />
        </>
      </SearchForm>
    </StyledTrackSearch>
  )
}
