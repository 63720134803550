import { FC } from 'react'

import { useTranslation } from 'react-i18next'

import { SearchingTrackDropdown } from 'src/Components/SearchingTrackDropdown'
import { CampaignPlatformTypeEnum } from 'src/Types'

interface SearchTrackFormMessagesProps {
  loading?: boolean
  error?: string
  platform: CampaignPlatformTypeEnum
}

export const SearchTrackFormMessages: FC<SearchTrackFormMessagesProps> = ({
  loading,
  error,
  platform,
}) => {
  const { t } = useTranslation()

  return (
    <>
      {loading && (
        <SearchingTrackDropdown
          text={t('createCampaignPage.searchingTrack')}
          platform={platform}
        />
      )}

      {error && (
        <SearchingTrackDropdown error text={error} platform={platform} />
      )}
    </>
  )
}
