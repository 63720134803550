import { FC } from 'react'

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { ScheduleSummaryProps } from '../..'

import { ScheduleSummaryPriceDetailsItem } from './ScheduleSummaryPriceDetailsItem'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  width: 100%;
`
const Accent = styled(ScheduleSummaryPriceDetailsItem)`
  :first-child {
    color: ${({ theme }) => theme.common.sixthText};
  }
`

type Props = Pick<
  ScheduleSummaryProps,
  'price' | 'vat' | 'discount' | 'discountPercentage' | 'balance'
>

export const ScheduleSummaryPriceDetails: FC<Props> = (props) => {
  const { price, vat, discount, discountPercentage, balance } = props
  const { t } = useTranslation()
  const showDiscount = discountPercentage && discount
  return (
    <Container>
      <ScheduleSummaryPriceDetailsItem
        title={t('scheduleSummary.subtotal')}
        price={price}
      />
      <ScheduleSummaryPriceDetailsItem
        title={t('scheduleSummary.balance')}
        price={balance}
      />
      {vat && (
        <ScheduleSummaryPriceDetailsItem
          title={t('scheduleSummary.vat')}
          price={vat}
        />
      )}
      {showDiscount && (
        <Accent
          title={t('scheduleSummary.discount', { value: discountPercentage })}
          price={discount}
        />
      )}
    </Container>
  )
}
