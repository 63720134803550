import { FC } from 'react'

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { FullScreenModal } from 'src/Components/FullScreenModal'
import { colors, device, variables } from 'src/Styled/variables'

import { SurveyForm } from './SurveyForm'

const Wrapper = styled.div`
  position: fixed;
  z-index: 1000;
  top: 60px;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${colors.white};
  height: calc(100vh - 60px);
  overflow-y: scroll;
  padding: 4px 16px 200px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  @media ${device.tabletMin} {
    padding: 4px 16px;
  }
`
const Title = styled.div`
  color: ${colors.mainText};
  text-align: center;
  font-size: ${variables.fontSize20};
  font-style: normal;
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
`

export const Survey: FC = () => {
  const { t } = useTranslation()
  return (
    <FullScreenModal open>
      <Wrapper>
        <Title>{t('feedBackPage.title')}</Title>
        <SurveyForm />
      </Wrapper>
    </FullScreenModal>
  )
}
