import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { colors, device } from 'src/Styled/variables'
import Alert from 'src/Components/Alert'

const AlertWrapper = styled.div`
  padding-left: 25px;
  padding-right: 24px;
  margin-bottom: 0px;

  @media ${device.mobileMax} {
    padding-left: 0;
    padding-right: 0;
  }
`
const AlertText = styled.div`
  margin-bottom: 9px;
  display: flex;
  flex-direction: column;
  color: ${colors.messegesYellow1};
`

interface Props {
  show: boolean
}

export const UpdateGenresLimitAlert: FC<Props> = ({ show }) => {
  const { t } = useTranslation()
  if (!show) {
    return null
  }
  return (
    <AlertWrapper>
      <Alert color='warning' icon unclosable>
        <AlertText>{t('targetGenres.updateOnlyOncePerDayAlert')}</AlertText>
      </Alert>
    </AlertWrapper>
  )
}
