import { FC } from 'react'

import styled from 'styled-components'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { colors, variables } from 'src/Styled/variables'
import { subscription } from 'src/Router/routes'
import { getHasActiveOrCanceledSubscriptionSelector } from 'src/Redux/subscription-process/subscription/selectors'
import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { useShowSubscriptionUpsell } from 'src/Hooks/useShowSubscriptionUpsell'
import { setSubscriptionFromLocation } from 'src/Redux/subscription-process/subscriptionFromLocation'
import { useUpsellButtonEvent } from 'src/Hooks/useUpsellButtonEvent'

import Button from '../Buttons/Button'

const Wrapper = styled.div`
  display: flex;
  padding: 24px;
  align-items: flex-end;
  align-content: flex-end;
  gap: 24px;
  border-radius: 4px;
  border: 1px solid ${colors.green};
  background-color: ${colors.white};
  @media (max-width: 820px) {
    gap: 16px;
    flex-direction: column;
    align-items: center;
    align-self: baseline;
  }
`
const Container = styled.div`
  display: flex;
  min-width: 240px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
`
const Title = styled.div`
  color: ${colors.green};
  font-size: ${variables.fontSize18};
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
`
const Description = styled.div`
  color: ${colors.mainText};
  white-space: normal;
  font-size: ${variables.fontSize14};
  line-height: 150%;
`
const StyledButton = styled(Button)`
  width: fit-content;
`

export const ScheduleSubscriptionAd: FC = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const showAd = useShowSubscriptionUpsell()
  const location = useLocation()
  const { onScheduleSubscriptionUpsellButtonClickEvent } =
    useUpsellButtonEvent()
  const hasActivePlan = useAppSelector(
    getHasActiveOrCanceledSubscriptionSelector,
  )

  const handleClick = (): void => {
    dispatch(setSubscriptionFromLocation(location.pathname))
    navigate(subscription)
    onScheduleSubscriptionUpsellButtonClickEvent()
  }

  if (hasActivePlan || !showAd) {
    return null
  }

  return (
    <Wrapper>
      <Container>
        <Title>{t('subscriptionAd.title')}</Title>
        <Description>{t('subscriptionAd.description')}</Description>
      </Container>
      <StyledButton onClick={handleClick} type='whiteWithGreenBorder'>
        {t('subscriptionAd.button')}
      </StyledButton>
    </Wrapper>
  )
}
