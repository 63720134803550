import { usePaymentMethodChanged } from 'src/Components/PaymentMethods/usePaymentMethodChanged'
import { SchedulePaymentMethodProps } from 'src/Components/SchedulePaymentMethod'
import { PaymentMethodEnum } from 'src/Constants/enums'
import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { useExistingBillingAgreement } from 'src/Hooks/useExistingBillingAgreement'
import { useGetBillingAgreementQuery } from 'src/Redux/billingAgreementApi'
import { setPaymentMethod } from 'src/Redux/payment-process'
import { getPaymentMethod } from 'src/Redux/payment-process/selectors'
import { changeRememberPayPalForFutureFlag } from 'src/Redux/paypalTransactions-process'
import { getRememberPayPalForFutureFlag } from 'src/Redux/paypalTransactions-process/selectors'
import { getIsEnoughBalanceForTiktok } from 'src/Redux/schedule-process/audienceTiktokSlice/selectors'
import { CampaignPlatformTypeEnum } from 'src/Types'

export const useTikTokSchedulePaymentMethod =
  (): SchedulePaymentMethodProps => {
    useExistingBillingAgreement()
    const dispatch = useAppDispatch()
    const { data: billingData } = useGetBillingAgreementQuery()
    const paymentMethod = useAppSelector(getPaymentMethod)
    const isEnoughBalance = useAppSelector(getIsEnoughBalanceForTiktok)
    const isRememberPayPalForFuture = useAppSelector(
      getRememberPayPalForFutureFlag,
    )

    const { setIsPaymentMethodChangedSent } = usePaymentMethodChanged()

    const handleChangePaymentMethod = (value: PaymentMethodEnum): void => {
      dispatch(setPaymentMethod(value))

      localStorage.setItem('currentPaymentMethod', value)

      setIsPaymentMethodChangedSent(value, CampaignPlatformTypeEnum.TIKTOK)
    }

    const handleChangeHasSavePaypal = (value: boolean): void => {
      dispatch(changeRememberPayPalForFutureFlag(value))
    }
    return {
      paymentMethod,
      isEnoughBalance,
      hasSavePaypal: isRememberPayPalForFuture,
      paypalEmail: billingData?.paypalEmail,
      onChangeHasSavePaypal: handleChangeHasSavePaypal,
      onChange: handleChangePaymentMethod,
    }
  }
