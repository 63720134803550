import { FC } from 'react'

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { ScheduleSummaryProps } from '..'

import { ScheduleSummaryDetailsHeader } from './ScheduleSummaryDetailsHeader'
import { ScheduleSummaryPriceDetails } from './ScheduleSummaryPriceDetails'
import { ScheduleSummaryPriceDetailsItem } from './ScheduleSummaryPriceDetails/ScheduleSummaryPriceDetailsItem'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`

const Divider = styled.div`
  background: ${({ theme }) => theme.common.thirdText};
  height: 1px;
  align-self: stretch;
`

const Total = styled(ScheduleSummaryPriceDetailsItem)`
  :first-child,
  :last-child {
    font-weight: 600;
  }
`

type Props = Omit<
  ScheduleSummaryProps,
  | 'onApplyPromocode'
  | 'onConfirm'
  | 'showPromocode'
  | 'showTooltip'
  | 'countdown'
  | 'paymentLoading'
>
export const ScheduleSummaryDetails: FC<Props> = ({
  artistName,
  trackName,
  platform,
  price,
  total,
  vat,
  discount,
  discountPercentage,
  balance,
}) => {
  const { t } = useTranslation()
  return (
    <Container>
      <ScheduleSummaryDetailsHeader
        artistName={artistName}
        trackName={trackName}
        platform={platform}
        price={price}
      />
      <Divider />
      <ScheduleSummaryPriceDetails
        vat={vat}
        price={price}
        discount={discount}
        discountPercentage={discountPercentage}
        balance={balance}
      />
      <Divider />
      <Total title={t('scheduleSummary.total')} price={total} />
    </Container>
  )
}
