import { FC } from 'react'

import { useTranslation } from 'react-i18next'

import Alert from 'src/Components/Alert'
import { useSubscriptionPackagesQuery } from 'src/Redux/subscriptionPackagesApi'

import { AlertWrapper } from './styles'

export const SubscriptionsError: FC = () => {
  const { t } = useTranslation()
  const { isError } = useSubscriptionPackagesQuery()

  if (!isError) {
    return null
  }

  return (
    <AlertWrapper>
      <Alert color='danger' icon unclosable>
        {t('getPremiumPage.error')}
      </Alert>
    </AlertWrapper>
  )
}
