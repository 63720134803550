import React from 'react'

import { useTranslation } from 'react-i18next'

import CheckInfluencerIcon from 'src/Assets/Svg/checkInfluencer.svg?react'
import { colors } from 'src/Styled/variables'
import { InfluencerRegisterEnum } from 'src/Types'

import { MainWrapper, Circle, InnerWrapper, Divider, Text } from './styles'

const CONNECTED_STATUSES = [
  InfluencerRegisterEnum.CONNECT,
  InfluencerRegisterEnum.ERROR,
  InfluencerRegisterEnum.CONNECTED,
]
const CONFIRMATION_STATUSES = [
  InfluencerRegisterEnum.FORM,
  InfluencerRegisterEnum.CONFIRMATION,
]
const CONNECT_STATUSES = [
  InfluencerRegisterEnum.CONNECT,
  InfluencerRegisterEnum.CONNECTED,
]
const REGISTER_STATUSES = [
  InfluencerRegisterEnum.CONNECT,
  InfluencerRegisterEnum.CONNECTED,
  InfluencerRegisterEnum.CONFIRMATION,
  InfluencerRegisterEnum.ERROR,
  InfluencerRegisterEnum.FORM,
]

interface StatusProps {
  status: InfluencerRegisterEnum
}

const Status: React.FC<StatusProps> = ({ status }) => {
  const { t } = useTranslation()

  const isConnected = CONNECTED_STATUSES.includes(status)

  if (!REGISTER_STATUSES.includes(status)) {
    return null
  }

  return (
    <MainWrapper>
      <div>
        <InnerWrapper>
          <Circle status>
            {isConnected ? 1 : <CheckInfluencerIcon width={16} height={10} />}
          </Circle>
          <Divider status />
        </InnerWrapper>

        <Text color={isConnected ? colors.green : colors.mainText} align='left'>
          {t('influencer.connectYourAccount')}
        </Text>
      </div>

      <div>
        <InnerWrapper>
          <Divider status={CONFIRMATION_STATUSES.includes(status)} />
          <Circle status={CONFIRMATION_STATUSES.includes(status)}>2</Circle>
        </InnerWrapper>

        <Text
          color={
            CONNECT_STATUSES.includes(status)
              ? colors.greyTextBlackBg
              : colors.green
          }
          align='right'
        >
          {t('influencer.setAccountAccess')}
        </Text>
      </div>
    </MainWrapper>
  )
}

export default Status
