import { combineReducers } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'

import { authTypeSlice } from './authTypeSlice'
import { credentialsSlice } from './credentialsSlice'
import { userSlice } from './userSlice'
import { emailPasswordSlice } from './emailPasswordSlice'
import { countrySlice } from './countrySlice'

const auth = combineReducers({
  authType: authTypeSlice.reducer,
  credentials: credentialsSlice.reducer,
  emailPassword: emailPasswordSlice.reducer,
  country: countrySlice.reducer,
  user: userSlice.reducer,
})
const persistConfig = {
  storage,
  key: 'credentials',
  blacklist: ['authType', 'emailPassword', 'country'],
}
export const authProcess = persistReducer(persistConfig, auth)
