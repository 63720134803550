import dayjs from 'src/Helpers/dayjs'
import { InfluencerEvent } from 'src/Types/index'

const CAMPAIGN_LIFE_TIME = 14

const checkIsValidAlert = (date: string): boolean => {
  const dateNow = dayjs()
  const dateAlert = dayjs(date)
  const diff = dateNow.diff(dateAlert, 'day')

  return diff < CAMPAIGN_LIFE_TIME
}

export const getRecentCampaignsCanceled = (
  campaignsCanceled: InfluencerEvent[],
): InfluencerEvent[] =>
  campaignsCanceled.filter(({ updatedAt }) => checkIsValidAlert(updatedAt))
