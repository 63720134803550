import styled from 'styled-components'

import { variables, colors, device } from 'src/Styled/variables'

export const PageSubTitle = styled.h2`
  margin: 0 0 21px;
  color: ${colors.mainText};
  font-weight: ${variables.fontWeight600};
  font-size: ${variables.fontSize20};
  @media ${device.mobileMin} {
    margin-top: 8px;
    line-height: 30px;
  }
`
