import { useCallback, useState } from 'react'

import { useNavigate } from 'react-router-dom'
import { useGrowthBook } from '@growthbook/growthbook-react'

import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import { useFirstChosenTrack } from 'src/Hooks/useFirstChosenTrack'
import { useAppDispatch } from 'src/Hooks/redux'
import { useViewSignup } from 'src/Containers/SignupWithFirstTrack/useViewSignup'
import { FormDataProps } from 'src/Containers/CreateCampaignWithoutSignup/components/UserRegisterForm/UserRegisterFormProps'
import {
  getCookieByName,
  getUtmParameters,
  handleDefinePlatform,
} from 'src/Constants/functions'
import { GA_LENGTH } from 'src/Constants/constants'
import {
  artistRegistrationCompleteEvent,
  fillingRegisterFormEvent,
  fistCampaignArtistDetailsEvent,
  locationEvent,
  pageViewEvent,
} from 'src/Helpers/TagManager'
import { createCampaignWithoutSignup } from 'src/Redux/auth-process/credentialsSlice/api-actions'
import { getTracksData } from 'src/Containers/SignupWithFirstTrack/helpers'
import {
  spotifyEventsHandler,
  tiktokEventsHandler,
} from 'src/Containers/CreateCampaignWithoutSignup/components/UserRegisterModal/helper'
import { pageInfo } from 'src/Helpers/TagManager/pageInfo'
import { CampaignPlatformTypeEnum } from 'src/Types'
import { createdFirst } from 'src/Router/routes'
import { signupLoginError } from 'src/Helpers/TagManager/AuthEvents'

export interface UseArtistRegisterPage {
  isEmailExist: boolean
  handleSubmit: (data: FormDataProps, token: string) => void
  loading?: boolean
  onStartFillingForm: () => void
}

export const useArtistRegisterPage = (): UseArtistRegisterPage => {
  const navigate = useNavigate()
  const growthbook = useGrowthBook()
  const { search } = useCustomSearchParams()

  const [isEmailExist, setIsEmailExist] = useState(false)

  const { track, platform, loading } = useFirstChosenTrack()

  const referralCode = search?.get('referralCode')
  const dispatch = useAppDispatch()

  useViewSignup()

  const onStartFillingForm = (): void => {
    locationEvent()
    fillingRegisterFormEvent()
  }

  const handleSubmit = useCallback(
    (data: FormDataProps, token: string): void => {
      const gcid = getCookieByName('_ga')?.slice(GA_LENGTH) || ''
      const utmObj = getUtmParameters(getCookieByName('soundCampaignUtm'))

      fistCampaignArtistDetailsEvent()

      void dispatch(
        createCampaignWithoutSignup({
          gcid,
          utmObj,
          artist: data,
          captchaToken: token,
          referral_code: utmObj.ref || '',
          internal_referral_code: referralCode || '',
          ...getTracksData(track),
        }),
      )
        .unwrap()
        .then((res) => {
          growthbook.setAttributeOverrides({
            id: res.user.id,
          })
          growthbook.setForcedVariations({
            new_ui_v1: 1,
          })
          res.tiktok_campaign
            ? tiktokEventsHandler({ res, dispatch })
            : spotifyEventsHandler({ res })

          pageViewEvent({ currentPageInfo: pageInfo.CreatedFirstCampaign })

          artistRegistrationCompleteEvent({
            platformName: handleDefinePlatform({
              isSpotifyTrack: platform === CampaignPlatformTypeEnum.SPOTIFY,
              isTikTokTrack: platform === CampaignPlatformTypeEnum.TIKTOK,
            }),
            userId: res.user.id,
            userRole: res.user.role,
            spotifyCampaignId: res.spotify_campaign?.id,
            tiktokCampaignId: res.tiktok_campaign?.id,
            isFeed: Boolean(res.spotify_campaign?.is_feed) || false,
          })
          const campaignType = res.tiktok_campaign ? 'tiktok' : 'spotify'
          navigate(`${createdFirst}/${campaignType}`)
          return res
        })
        .catch(() => {
          signupLoginError()
          setIsEmailExist(true)
        })
    },
    [dispatch, referralCode, track, growthbook, platform, navigate],
  )

  return {
    isEmailExist,
    handleSubmit,
    loading,
    onStartFillingForm,
  }
}
