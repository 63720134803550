import { useMemo } from 'react'

import { PaymentMethodEnum } from 'src/Constants/enums'

export const usePaymentMethod = (
  token: string | null,
  isPayPalSaved: string | null,
  defaultPaymentMethod: string,
): string =>
  useMemo(() => {
    if (isPayPalSaved === 'true') {
      return PaymentMethodEnum.PAYPAL_BILLING_AGREEMENT
    }
    if (token === 'true') {
      return PaymentMethodEnum.PAYPAL
    }
    return defaultPaymentMethod
  }, [isPayPalSaved, token, defaultPaymentMethod])
