import { FC } from 'react'

import { useTranslation } from 'react-i18next'

import { errorsList } from '../../helpers/errorsLIst'

interface ErrorReflectionProps {
  text: string | string[]
}
export const ErrorReflection: FC<ErrorReflectionProps> = ({ text }) => {
  const { t } = useTranslation()

  const textToString =
    typeof text === 'string'
      ? text
      : text.reduce((string, item) => string + item, '')

  if (!errorsList?.[textToString as keyof typeof errorsList]) {
    return <>{t(errorsList.default_error)}</>
  }
  return <>{t(errorsList[textToString as keyof typeof errorsList])}</>
}
