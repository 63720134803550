import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'

import { CampaignPlatformTypeEnum } from 'src/Types'
import { api } from 'src/Services/api'
import { APP } from 'src/Configs/App'

import { ErrorCode, NameSpace } from '../types'
import { getUserInfo } from '../auth-process/userSlice/api-actions'

interface ChargeBillingAgreementRequest {
  amount: number
  campaigns: Array<{
    type: CampaignPlatformTypeEnum
    genres: number[]
    languages: number[]
    amount: number
    startDate: string
    campaignId: number
    isMedianTest?: boolean
  }>
  country: string
  couponValue?: string
  usedBalance: number
  paypalEmail: string
}

const CHARGE_BILLING_AGREEMENT_TIMEOUT = 30000

export const chargeBillingAgreement = createAsyncThunk<
  boolean,
  ChargeBillingAgreementRequest
>(
  `${NameSpace.PayPalTransactions}/chargeBillingAgreement`,
  async (requestData, { rejectWithValue, dispatch }) => {
    try {
      await api.post(
        `${APP.TIKTOK_SERVER}/payment/paypal/charge/billing-agreement`,
        requestData,
        { timeout: CHARGE_BILLING_AGREEMENT_TIMEOUT },
      )
      await dispatch(getUserInfo())

      return true
    } catch (error) {
      const { message, response } = error as AxiosError
      return rejectWithValue({
        message,
        code: response?.status || ErrorCode.InternalServerError,
      })
    }
  },
)
