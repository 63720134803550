import React, { FC, useState } from 'react'

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { CampaignPlatformTypeEnum } from 'src/Types'

import { PremiumCheckbox } from '../PremiumCheckbox'
import { PrimaryDottedButton } from '../Buttons/PrimaryDottedButton'

type FiltersPlatformData = {
  [CampaignPlatformTypeEnum.SPOTIFY]: boolean
  [CampaignPlatformTypeEnum.TIKTOK]: boolean
}

interface FilterByPlatformContentProps {
  filterState: FiltersPlatformData
  onApplyFilters: (data: FiltersPlatformData) => void
}

const Wrapper = styled.div`
  display: flex;
  width: 240px;
  padding: 8px;
  flex-direction: column;
  align-items: flex-start;
`

const Title = styled.div`
  display: flex;
  height: 40px;
  padding: 0px 16px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  color: ${({ theme }) => theme.common.primaryText};
  font-family: 'Segoe UI';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
`
const StyledButton = styled(PrimaryDottedButton)`
  margin-left: auto;
  margin-right: auto;
`
export const FilterByPlatformContent: FC<FilterByPlatformContentProps> = (
  props,
) => {
  const { t } = useTranslation()
  const { onApplyFilters, filterState } = props
  const [state, setState] = useState<FiltersPlatformData>(filterState)
  const handleApply = (): void => {
    onApplyFilters(state)
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setState((prev) => ({
      ...prev,
      [e.target.name as CampaignPlatformTypeEnum]: e.target.checked,
    }))
  }
  return (
    <Wrapper>
      <Title>{t('campaignsListPage.platforms')}</Title>
      <PremiumCheckbox
        checked={state[CampaignPlatformTypeEnum.SPOTIFY]}
        name={CampaignPlatformTypeEnum.SPOTIFY}
        onChange={onChange}
      >
        {t('campaignsListPage.spotify')}
      </PremiumCheckbox>
      <PremiumCheckbox
        checked={state[CampaignPlatformTypeEnum.TIKTOK]}
        name={CampaignPlatformTypeEnum.TIKTOK}
        onChange={onChange}
      >
        {t('campaignsListPage.tiktok')}
      </PremiumCheckbox>
      <StyledButton onClick={handleApply}>
        {t('campaignsListPage.apply')}
      </StyledButton>
    </Wrapper>
  )
}
