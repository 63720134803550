import { FunctionComponent, ReactElement } from 'react'

import { Skeleton, Table } from 'antd'
import { SkeletonNodeProps } from 'antd/es/skeleton/Node'
import { ColumnsType } from 'antd/es/table'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { SkeletonLoader } from 'src/Components/Skeletons/SkeletonLoader'
import { colors, MAX_MOBILE_WIDTH } from 'src/Styled/variables'
import InfoCard from 'src/Components/InfoCard'
import { LoadingSpinner } from 'src/Containers/ui'
import { addSpaceToNumber } from 'src/Helpers/layoutHelpers'
import { Desktop, Mobile } from 'src/Components/Responsive'
import { TransactionTypeEnum } from 'src/Types'
import {
  ArtistBalanceItem,
  getArtistBalanceHistory,
  useGetArtistBalanceHistoryQuery,
} from 'src/Redux/artistBalanceHistoryApi'
import { useGetArtistBalanceStatsQuery } from 'src/Redux/artistBalanceStatsApi'

import { MobileBalanceList } from './components/MobileBalanceList'
import { useArtistBalancePage } from './helpers'
import { BalanceSubscriptionInfo } from './components/BalanceSubscriptionInfo/BalanceSubscriptionInfo'

import {
  Amount,
  HeaderTitle,
  IconWrapper,
  InfoCardsWrapper,
  InfoCardWrapper,
  InfoText,
  MobileInfoText,
  BalancePageContainer,
  PageContentContainer,
  PageSubTitle,
  TrackCard,
  TrackCardInfoWrapper,
} from './styles'

/**
 * Balance page. Artist side.
 */

const StyledSkeleton = styled(({ className, ...rest }: SkeletonNodeProps) => (
  <Skeleton.Input {...rest} rootClassName={className} active />
))`
  &.ant-skeleton-block {
    width: 120px;
    border-radius: 16px;
  }
  & > .ant-skeleton-input {
    min-width: 120px !important;
    width: 120px !important;
    height: 48px !important;
    border-radius: 16px !important;
    @media screen and (min-width: ${MAX_MOBILE_WIDTH}) {
      width: 240px !important;
    }
  }
`

export const ArtistBalancePage: FunctionComponent = (): ReactElement => {
  const { t } = useTranslation()

  const bonusText = t('balancePage.bonus')

  const { getIcon, getAmountValue, getTrackTitle } = useArtistBalancePage()

  const { data: artistBalanceStats } = useGetArtistBalanceStatsQuery(null, {
    refetchOnMountOrArgChange: true,
  })
  const {
    data: artistBalanceHistoryData,
    isFetching: isBalanceHistoryLoading,
  } = useGetArtistBalanceHistoryQuery(null, {
    refetchOnMountOrArgChange: true,
    selectFromResult: (result) => ({
      ...result,
      data: getArtistBalanceHistory(result.data),
    }),
  })

  const MAP: Record<TransactionTypeEnum, ReactElement> = {
    [TransactionTypeEnum.USED]: (
      <InfoText>{t('balancePage.creditsUsed')}</InfoText>
    ),
    [TransactionTypeEnum.QUICK_CHECKOUT_BONUS_CURRENT]: (
      <InfoText>{bonusText}</InfoText>
    ),
    [TransactionTypeEnum.QUICK_CHECKOUT_BONUS_FUTURE]: (
      <InfoText>{bonusText}</InfoText>
    ),
    [TransactionTypeEnum.SUBSCRIPTION]: (
      <InfoText>{t('balancePage.monthlyPayment')}</InfoText>
    ),
    [TransactionTypeEnum.SUBSCRIPTION_PAYMENT_FAILED]: (
      <InfoText>{t('balancePage.monthlyPayment')}</InfoText>
    ),
    [TransactionTypeEnum.CASHBACK]: (
      <InfoText>{t('balancePage.cashback')}</InfoText>
    ),
    [TransactionTypeEnum.RECEIVED]: (
      <InfoText>{t('balancePage.creditReceived')}</InfoText>
    ),
    [TransactionTypeEnum.CHARGEBACK]: (
      <InfoText>{t('balancePage.chargeback')}</InfoText>
    ),
    [TransactionTypeEnum.PAYMENT]: (
      <InfoText>{t('balancePage.payment')}</InfoText>
    ),
    [TransactionTypeEnum.INVITED_BONUS]: (
      <InfoText>{t('balancePage.referralCredits')}</InfoText>
    ),
    [TransactionTypeEnum.REFERRAL_BONUS]: (
      <InfoText>{t('balancePage.referralCredits')}</InfoText>
    ),
    [TransactionTypeEnum.BONUS]: <InfoText>{bonusText}</InfoText>,
  }

  const columns: ColumnsType<ArtistBalanceItem> = [
    {
      dataIndex: 'date',
      key: 'date',
      width: '14%',
      title: (
        <div>
          <HeaderTitle>{t('balancePage.date')}</HeaderTitle>
        </div>
      ),
    },
    {
      title: <HeaderTitle>{t('balancePage.transaction')}</HeaderTitle>,
      dataIndex: 'transactionType',
      key: 'transactionType',
      width: '20%',
      render: (transactionType: TransactionTypeEnum) => {
        const element = MAP[transactionType]
        if (element) {
          return element
        }

        return <span />
      },
    },
    {
      title: <HeaderTitle>{t('balancePage.details')}</HeaderTitle>,
      dataIndex: ['track'],
      key: 'track',
      render: (track: ArtistBalanceItem['track'], value) => (
        <TrackCard>
          <IconWrapper>
            {getIcon({
              platformType: value.platformType,
              transactionType: value.transactionType,
            })}
          </IconWrapper>
          <TrackCardInfoWrapper>
            {getTrackTitle({
              track,
              packageName: value.packageName,
              platformType: value.platformType,
              transactionType: value.transactionType,
              referralData: value.referralData,
              invitedData: value.invitedData,
            })}
          </TrackCardInfoWrapper>
        </TrackCard>
      ),
      width: '52%',
    },
    {
      title: <HeaderTitle>{t('balancePage.amount')}</HeaderTitle>,
      dataIndex: 'amount',
      key: 'amount',
      render: (amount: number, value) => (
        <Amount
          isRed={
            value.transactionType ===
            TransactionTypeEnum.SUBSCRIPTION_PAYMENT_FAILED
          }
        >
          {getAmountValue(value.transactionType, amount)}
        </Amount>
      ),
      width: '14%',
    },
  ]

  return (
    <BalancePageContainer>
      <PageSubTitle>{t('balancePage.pageTitle')}</PageSubTitle>
      <BalanceSubscriptionInfo />

      {
        <InfoCardsWrapper>
          {isBalanceHistoryLoading ? (
            <StyledSkeleton />
          ) : (
            <InfoCardWrapper>
              <InfoCard
                title='Current balance'
                value={`$ ${
                  artistBalanceStats?.currentBalance
                    ? addSpaceToNumber(
                        artistBalanceStats?.currentBalance,
                        ',',
                        true,
                      )
                    : 0
                }`}
                type='green'
              />
            </InfoCardWrapper>
          )}

          {isBalanceHistoryLoading ? (
            <StyledSkeleton />
          ) : (
            <InfoCardWrapper>
              <InfoCard
                title='Total paid'
                value={`$ ${
                  artistBalanceStats?.totalPaid
                    ? addSpaceToNumber(artistBalanceStats?.totalPaid, ',')
                    : 0
                }`}
                type='regular'
              />
            </InfoCardWrapper>
          )}
          {isBalanceHistoryLoading ? (
            <StyledSkeleton />
          ) : (
            <InfoCardWrapper>
              <InfoCard
                title='Total used'
                value={`$ ${
                  artistBalanceStats?.totalUsed
                    ? addSpaceToNumber(artistBalanceStats?.totalUsed, ',')
                    : 0
                }`}
                type='regular'
              />
            </InfoCardWrapper>
          )}
        </InfoCardsWrapper>
      }
      <Desktop>
        {isBalanceHistoryLoading ? (
          <SkeletonLoader count={5} />
        ) : (
          <PageContentContainer>
            <Table
              loading={{
                indicator: <LoadingSpinner color={colors.green} size='large' />,
                spinning: isBalanceHistoryLoading,
              }}
              rowKey='id'
              pagination={false}
              columns={columns}
              dataSource={artistBalanceHistoryData}
              locale={{
                emptyText: !isBalanceHistoryLoading && (
                  <MobileInfoText>
                    {t('balancePage.YouHaveNoTransactions')}
                  </MobileInfoText>
                ),
              }}
            />
          </PageContentContainer>
        )}
      </Desktop>
      <Mobile>
        {isBalanceHistoryLoading ? (
          <SkeletonLoader count={5} />
        ) : (
          <MobileBalanceList
            items={artistBalanceHistoryData}
            isLoading={isBalanceHistoryLoading}
          />
        )}
      </Mobile>
    </BalancePageContainer>
  )
}
