import styled from 'styled-components'

import { variables, colors, device } from 'src/Styled/variables'

export const RateTitle = styled.h3`
  margin-bottom: 27px;
  color: ${colors.mainText};
  font-weight: ${variables.fontWeight600};
  font-size: ${variables.fontSize20};
  @media ${device.mobileMin} {
    margin-top: 8px;
    line-height: 30px;
  }
`

export const RateContainer = styled.div`
  box-sizing: content-box;
  padding: 0;
  margin: 0 auto;
`

export const Container = styled.div`
  box-sizing: border-box;
  width: 100%;

  @media screen and (max-width: 576px) {
    padding-left: 0;
    padding-right: 0;
  }
`
