import { object, string as yupString } from 'yup'

export const CANCEL_PREMIUM_REASONS = [
  { value: 'getPremiumPage.cancelReason1' },
  { value: 'getPremiumPage.cancelReason2' },
  { value: 'getPremiumPage.cancelReason3' },
  { value: 'getPremiumPage.cancelReason4' },
  { value: 'getPremiumPage.cancelReason5' },
  { value: 'getPremiumPage.cancelReason6' },
]

export const cancelPremiumFormSchema = object().shape({
  reason: yupString().required(),
  reasonDetails: yupString().when('reason', (reason, schema) => {
    if (
      reason.includes(
        CANCEL_PREMIUM_REASONS[CANCEL_PREMIUM_REASONS.length - 1].value,
      )
    ) {
      return schema.required('Must enter a reason for cancellation')
    }
    return schema
  }),
})
