import { memo, useState } from 'react'

import { useDebouncedCallback } from 'use-debounce'
import { useMediaQuery } from 'react-responsive'

import { MobileSearchInput } from 'src/Components/MobileSearchInput'
import SearchInput from 'src/Containers/ui/Input/SearchInput'
import { DEBOUNCE_TIMER_300 } from 'src/Constants/constants'
import { ScheduleSteps } from 'src/Components/ScheduleTabs'
import { useElementOnScreen } from 'src/Helpers/useElementOnScreen'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'

import { DesktopInputWrapper, MobileInputWrapper, Title } from './styles'

interface Props {
  title: string
  placeholder: string
  setValue: (value: string) => void
}

export const ModalTitle = memo((props: Props) => {
  const { title, placeholder, setValue } = props
  const [searchValue, setSearchValue] = useState<string>('')
  const { isVisible, containerRef } = useElementOnScreen({
    root: null,
    rootMargin: '0px',
    threshold: 1.0,
  })
  const { setSearch } = useCustomSearchParams()

  const handleClose = (): void => {
    setSearch({ step: ScheduleSteps.SETTINGS, modal: null })
  }

  const debounceSetValue = useDebouncedCallback((value: string) => {
    setValue(value)
  }, DEBOUNCE_TIMER_300)

  const handleSearch = (value: string): void => {
    setSearchValue(value)
    debounceSetValue(value)
  }
  const isMobile = useMediaQuery({ maxWidth: 767 })
  return (
    <MobileInputWrapper>
      {isMobile ? (
        <MobileSearchInput
          placeholder={placeholder}
          value={searchValue}
          setValue={handleSearch}
          onClose={handleClose}
        />
      ) : (
        <>
          <Title ref={containerRef}>{title}</Title>
          <DesktopInputWrapper isVisible={isVisible}>
            <SearchInput
              value={searchValue}
              withborder='withborder'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleSearch(e.target.value)
              }
              placeholder={placeholder}
            />
          </DesktopInputWrapper>
        </>
      )}
    </MobileInputWrapper>
  )
})

ModalTitle.displayName = 'ModalTitle'
