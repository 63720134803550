import { FC } from 'react'

import SpotifyIcon from 'mdi-react/SpotifyIcon'
import { useTranslation } from 'react-i18next'

import { LoadingSpinner } from 'src/Containers/ui'
import { APP } from 'src/Configs/App'
import TiktokIcon from 'src/Assets/Svg/tiktok.svg?react'
import { colors } from 'src/Styled/variables'

import { Content, PlatformLink, PlatformLinkWrapper } from './styles'

export const PlatformLinks: FC = () => {
  const { t } = useTranslation()
  return (
    <PlatformLinkWrapper>
      <PlatformLink
        tabIndex={0}
        data-testid='spotify-link'
        data-cy='login-form-sign-in-with-spotify'
        href={APP.SPOTIFY_AUTH_API}
      >
        <Content id='content'>
          <SpotifyIcon widths={16} />
          <span>{t('login.loginAsSpotifyCurator')}</span>
        </Content>
        <LoadingSpinner color={colors.greyTextBlackBg} size='default' />
      </PlatformLink>
      <PlatformLink
        tabIndex={1}
        data-testid='tiktok-link'
        data-cy='login-form-sign-in-with-tiktok'
        href={APP.TIKTOK_AUTH_API}
      >
        <Content id='content'>
          <TiktokIcon width={16} height={16} />
          <span>{t('login.loginAsTikTokCreator')}</span>
        </Content>
        <LoadingSpinner color={colors.greyTextBlackBg} size='default' />
      </PlatformLink>
    </PlatformLinkWrapper>
  )
}
