import styled from 'styled-components'

import { colors, device, variables } from 'src/Styled/variables'

export const ArtistNotificationItemWrapper = styled.div`
  display: grid;
  position: relative;
  grid-template-columns: 40px 1fr 80px;
  gap: 8px;
  margin-bottom: 24px;
`

export const ImageWrapper = styled.div`
  img {
    border-radius: 50%;
    height: 40px;
    width: 40px;
    object-fit: cover;
  }
`

export const Message = styled.div`
  margin: 0;
  font-size: ${variables.fontSize14};
  line-height: 16px;
  color: ${({ theme }) => theme.common.primaryText};
  flex: 4 4;
`

export const HeaderMessage = styled.div`
  margin: 0;
  font-size: ${variables.fontSize14};
  line-height: 150%;
  font-weight: 600;
  display: block;
  a {
    color: ${colors.badgeGreen};
    line-height: 150%;
  }
`
export const TrackAddedToPlaylist = styled.div`
  margin-bottom: 4px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  line-height: 150%;
  font-size: ${variables.fontSize12};
  color: ${colors.mainText};
  @media ${device.mobileMin} {
    display: block;
  }
`

export const TextName = styled.p`
  font-size: ${variables.fontSize10};
  color: ${colors.greyTextWhiteBg};
  margin: 0;
  line-height: 150%;
`

export const DateWrapper = styled.div`
  font-size: ${variables.fontSize10};
  color: ${colors.greyTextWhiteBg};
  line-height: 150%;
`
