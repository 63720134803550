import styled from 'styled-components'

import { colors, variables } from 'src/Styled/variables'

export const ChooseTrackParametersContainer = styled.div`
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 4px;
  background-color: ${colors.white};
  & > button {
    margin-left: 16px;
  }
  @media (min-width: 1280px) {
    height: fit-content;
  }
`
export const SubTitle = styled.h4`
  font-family: Segoe UI;
  font-size: ${variables.fontSize14};
  font-style: normal;
  font-weight: ${variables.fontWeight600};
  line-height: 21px;
  color: ${colors.mainText};
  margin-bottom: 4px;
`
export const LanguagesGenresList = styled.div`
  font-family: Segoe UI;
  font-size: ${variables.fontSize14};
  font-style: normal;
  line-height: 21px;
  color: ${colors.mainText};
  line-height: 150%;
`
export const LanguagesGenresContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
  padding-left: 16px;

  @media (min-width: 1280px) {
    flex-direction: row;
    align-items: center;
    gap: 24px;
    justify-content: space-between;
  }
`

export const ParametersButton = styled.button`
  display: flex;
  height: 40px;
  width: fit-content;
  padding: 0px 24px;
  align-items: center;
  gap: 8px;
  border-radius: 20px;
  border: 1px solid ${colors.greyStroke};
  background-color: ${colors.white};
  font-family: Segoe UI;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  color: ${colors.green};

  cursor: pointer;
  svg {
    fill: ${colors.green};
  }

  &:disabled {
    cursor: not-allowed;
    color: ${colors.greyTextBlackBg};
    svg {
      fill: ${colors.greyTextBlackBg};
    }
  }
`

export const MatchingError = styled.div`
  margin-top: 8px;
  color: #f19236;
  font-size: ${variables.fontSize14};
  line-height: 150%;
`
