import TagManager from 'react-gtm-module'

import {
  CampaignPlatformTypeEnum,
  CampaignTypeEnum,
  FlowType,
  ID,
} from 'src/Types'

import { EventNameEnum, EventTypeEnum } from '../tagManagerEnums'

export const artistReviewSentEvent = (
  campaignType: string,
  campaignNumber: number,
  campaignId: number,
  userId: number | string,
  rateStars: number,
  responseText: string,
  reviewId: string | number,
): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventTypeEnum.ArtistReviewSent,
      event_name: EventNameEnum.ArtistReviewSent,
      flow: 'artist_review_section',
      'campaign-type': campaignType,
      'campaign-platform': CampaignPlatformTypeEnum.SPOTIFY,
      'campaign-number':
        campaignType === CampaignTypeEnum.FIRST ? 1 : campaignNumber,
      'campaign-id': campaignId,
      ...(userId ? { userID: userId } : {}),
      rate_stars: rateStars,
      response_text: responseText,
      review_id: reviewId,
      is_feed: true,
    },
  })
}

export const artistReviewedVideo = ({
  userID,
  reviewId,
  campaignId,
  trackId,
  amountPaid,
  videoId,
  rate,
}: {
  userID: number | string
  reviewId: number | string
  campaignId?: number | string
  trackId?: number | string
  amountPaid?: number | string
  videoId?: string
  rate?: number | string
}): void => {
  TagManager.dataLayer({
    dataLayer: {
      userID,
      rate,
      event: EventTypeEnum.ArtistReviewedVideo,
      event_name: EventNameEnum.ArtistReviewedVideo,
      'account-type': 'artist',
      'campaign-platform': 'tiktok',
      is_feed: false,
      'review-id': reviewId,
      'campaign-id': campaignId,
      'track-id': trackId,
      amount_paid: amountPaid,
      video_id: videoId,
    },
  })
}

export const artistAcceptedVideo = ({
  userID,
  reviewId,
  campaignId,
  trackId,
  amountPaid,
  videoId,
  rate,
}: {
  userID: number | string
  reviewId: number | string
  campaignId?: number | string
  trackId?: number | string
  amountPaid?: number | string
  videoId?: string
  rate?: number | string
}): void => {
  TagManager.dataLayer({
    dataLayer: {
      userID,
      rate,
      event: EventTypeEnum.ArtistAcceptedVideo,
      event_name: EventNameEnum.ArtistAcceptedVideo,
      'account-type': 'artist',
      'campaign-platform': 'tiktok',
      is_feed: false,
      'review-id': reviewId,
      'campaign-id': campaignId,
      'track-id': trackId,
      amount_paid: amountPaid,
      video_id: videoId,
    },
  })
}

export const artistRejectedVideo = ({
  userID,
  reviewId,
  campaignId,
  trackId,
  amountPaid,
  videoId,
  rate,
  rejectionReason,
}: {
  userID: number | string
  reviewId: number | string
  campaignId?: number | string
  trackId?: number | string
  amountPaid?: number | string
  videoId?: string
  rate?: number | string
  rejectionReason?: string
}): void => {
  TagManager.dataLayer({
    dataLayer: {
      userID,
      rate,
      event: EventTypeEnum.ArtistRejectedVideo,
      event_name: EventNameEnum.ArtistRejectedVideo,
      'account-type': 'artist',
      'campaign-platform': 'tiktok',
      is_feed: false,
      'review-id': reviewId,
      'campaign-id': campaignId,
      'track-id': trackId,
      amount_paid: amountPaid,
      video_id: videoId,
      rejection_reason: rejectionReason,
    },
  })
}

export const artistClickShareEvent = ({
  userId,
  campaignId,
}: {
  userId?: ID | null
  campaignId: string | number
}): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventTypeEnum.GA_EVENT,
      event_params: {
        event_name: EventNameEnum.ClickShareReview,
        flow: FlowType.share,
        campaign_platform: CampaignPlatformTypeEnum.SPOTIFY,
        campaign_id: campaignId,
      },
      user_properties: {
        ...(userId ? { user_id: userId } : {}),
        account_type: 'artist',
      },
    },
  })
}

export const artistViewSharePopupEvent = ({
  userId,
  campaignId,
}: {
  userId?: ID | null
  campaignId: string | number
}): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventTypeEnum.GA_EVENT,
      event_params: {
        event_name: EventNameEnum.ViewShareReviewPopUp,
        flow: FlowType.share,
        campaign_platform: CampaignPlatformTypeEnum.SPOTIFY,
        campaign_id: campaignId,
      },
      user_properties: {
        ...(userId ? { user_id: userId } : {}),
        account_type: 'artist',
      },
    },
  })
}

export const artistClickDownloadShareImageEvent = ({
  userId,
  campaignId,
}: {
  userId?: ID | null
  campaignId: string | number
}): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventTypeEnum.GA_EVENT,
      event_params: {
        event_name: EventNameEnum.ClickDownloadImage,
        flow: FlowType.share,
        campaign_platform: CampaignPlatformTypeEnum.SPOTIFY,
        campaign_id: campaignId,
      },
      user_properties: {
        ...(userId ? { user_id: userId } : {}),
        account_type: 'artist',
      },
    },
  })
}
