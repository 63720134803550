import styled from 'styled-components'
import { Progress } from 'antd'

import { variables, device, colors } from 'src/Styled/variables'

export const ListItemContainer = styled.div`
  display: grid;
  grid-template-columns: 40px 1fr;
  grid-template-rows: repeat(4, auto);
  grid-template-areas:
    'track-cover track-info'
    '. progress'
    '. status'
    '. details-btn';
  column-gap: 16px;
  row-gap: 16px;
  margin-bottom: 0.5rem;
  background-color: ${colors.white};
  padding: 16px;
  align-items: self-start;

  @media ${device.smallScreen} {
    grid-template-columns:
      40px minmax(11rem, 1fr) minmax(10rem, 1fr) minmax(5rem, 1fr)
      1fr;
    grid-template-areas: 'track-cover track-info progress status details-btn';
    grid-template-rows: none;
    align-items: center;
    row-gap: 10px;
    margin-bottom: 2px;
    background-color: ${colors.greyBg};
    margin-bottom: 2px;
    padding: 24px;
  }
`

export const TrackCoverWrapper = styled.div`
  grid-area: track-cover;
  height: 40px;
  width: 40px;
  border-radius: 4px;
  overflow: hidden;

  img {
    height: 40px;
    width: 40px;
    max-height: 40px;
  }
`

type TrackInfoProps = { status: string }

export const TrackInfo = styled.div`
  grid-area: track-info;
  display: grid;

  margin-bottom: ${(props: TrackInfoProps) =>
    props.status === 'Canceled' ? '8px' : '19px'};

  @media ${device.smallScreen} {
    margin-bottom: 0px;
  }
`

export const TrackName = styled.div`
  font-size: ${variables.fontSize14};
  line-height: 21px;
  color: ${colors.mainText};
`

export const ArtistName = styled.span`
  font-size: ${variables.fontSize10};
  line-height: 15px;
  color: ${colors.mainText};
`

export const DaysValue = styled.div`
  color: ${colors.mainText};
`

export const CampaignProgress = styled.div`
  grid-area: progress;
  display: flex;
  flex-direction: column;
  max-width: 70px;
  @media ${device.mobileMax} {
    margin-bottom: 5px;
  }
`

export const DateProgress = styled(Progress)`
  padding-top: 6px;
  width: 100%;

  .ant-progress-steps-outer {
    max-width: 150px;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-column-gap: 1px;
  }
  .ant-progress-steps-item {
    width: auto !important;
    margin-right: 0px;
  }
  & .ant-progress-steps-item:first-of-type {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }
  & .ant-progress-steps-item:last-of-type {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }
`

type StatusProps = { $color: string; status: string }

export const Status = styled.div.attrs((p: StatusProps) => ({
  style: { color: p.$color },
}))<StatusProps>`
  @media ${device.smallScreen} {
    margin-bottom: 0px;
    margin-top: 0px;
  }
`

export const StatusWrapper = styled.div`
  grid-area: status;
  display: flex;
  flex-direction: column;
`

export const DetailsButton = styled.div`
  max-width: 90px;
  grid-area: details-btn;
  .ant-btn.ant-btn-default {
    padding: 1px 51px;
    width: auto;
  }
  @media ${device.mobileMin} {
    margin-left: auto;
    .ant-btn.ant-btn-default {
      padding: 1px 40px;
      width: 100%;
    }
  }
`

export const Reward = styled.div`
  font-size: ${variables.fontSize14};
  line-height: 21px;
  color: ${colors.mainText};
`

export const PaidContainer = styled.div`
  font-size: ${variables.fontSize10};
  line-height: 15px;
  color: ${colors.mainText};
  margin-bottom: 24px;
  @media ${device.smallScreen} {
    margin-bottom: 0px;
  }
`
