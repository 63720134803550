import { createSlice } from '@reduxjs/toolkit'

import { setApiError } from 'src/Hooks/redux'
import { ApiError, NameSpace, SliceStatus } from 'src/Redux/types'

import { deleteCuratorAccount } from './api-actions'

export interface CuratorDeleteAccountState {
  status: SliceStatus
  error: ApiError | null
}
const initialState: CuratorDeleteAccountState = {
  status: SliceStatus.Idle,
  error: null,
}

export const curatorDeleteAccountSlice = createSlice({
  initialState,
  name: NameSpace.CuratorDeleteAccount,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(deleteCuratorAccount.pending, (state) => {
      state.status = SliceStatus.Loading
    })
    builder.addCase(deleteCuratorAccount.fulfilled, (state) => {
      state.status = SliceStatus.Succeeded
    })
    builder.addCase(deleteCuratorAccount.rejected, (state, action) => {
      state.status = SliceStatus.Failed
      state.error = setApiError(action)
    })
  },
})
