import React, { FC } from 'react'

import { useTranslation } from 'react-i18next'

import { CampaignPlatformTypeEnum } from 'src/Types'
import IconSpotify from 'src/Assets/Svg/spotify.svg?react'
import TikTokIcon from 'src/Assets/Svg/tiktok.svg?react'

import { StartCampaignButton } from '../StartCampaignButton'

import { CreateCampaignBody } from './styles'

interface StartButtonsProps {
  onClick: (platform: CampaignPlatformTypeEnum) => void
}

export const StartButtons: FC<StartButtonsProps> = ({ onClick }) => {
  const { t } = useTranslation()

  const spotifyText = 'createCampaignPage.spotifyCampaign'

  const tiktokText = 'createCampaignPage.tikTokCampaign'

  return (
    <CreateCampaignBody>
      <StartCampaignButton
        icon={<IconSpotify />}
        text={t(spotifyText)}
        platform={CampaignPlatformTypeEnum.SPOTIFY}
        openHandler={() => onClick(CampaignPlatformTypeEnum.SPOTIFY)}
      />

      <StartCampaignButton
        icon={<TikTokIcon />}
        text={t(tiktokText)}
        platform={CampaignPlatformTypeEnum.TIKTOK}
        openHandler={() => onClick(CampaignPlatformTypeEnum.TIKTOK)}
      />
    </CreateCampaignBody>
  )
}
