import React from 'react'

import styled from 'styled-components'

import {
  PrimaryButton,
  PrimaryButtonProps,
} from 'src/Components/Buttons/PrimaryButton'
import { MAX_MOBILE_WIDTH } from 'src/Styled/variables'

const StyledButton = styled(PrimaryButton)`
  height: 32px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;

  color: ${({ theme }) => theme.button.thirdText};

  svg {
    path {
      transition: all 0.2s;
      fill: ${({ theme }) => theme.button.thirdText};
    }
  }
  &:active {
    color: ${({ theme }) => theme.button.fifthText};

    svg {
      path {
        transition: all 0.2s;
        fill: ${({ theme }) => theme.button.fifthText};
      }
    }

    &:hover {
      box-shadow: none;
    }
  }

  @media (max-width: ${MAX_MOBILE_WIDTH}) {
    flex-grow: 1;
  }
`

const ChildrenWrapper = styled.div`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  display: flex;
  gap: 8px;
`

export type SquareButtonProps = Pick<
  PrimaryButtonProps,
  'loading' | 'onClick' | 'className' | 'disabled' | 'children'
>

export const SquareButton: React.FC<SquareButtonProps> = ({
  loading,
  onClick,
  className,
  disabled,
  children,
}) => (
  <StyledButton
    loading={loading}
    disabled={disabled}
    onClick={onClick}
    className={className}
  >
    <ChildrenWrapper>{children}</ChildrenWrapper>
  </StyledButton>
)
