import styled from 'styled-components'

export const Message = styled.p`
  margin: 0;
  font-size: 12px;
  font-weight: 600;
  line-height: 150%;
  color: #787985;
  flex: 4 4;
`
export const Text = styled.span`
  margin: 0;
  display: block;
  text-align: center;
`
