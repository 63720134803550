import React, { useEffect } from 'react'

import { getTransactionsDetails } from 'src/Redux/transactions-process/api-actions'
import { useAppDispatch } from 'src/Hooks/redux'
import { getAllTransactions } from 'src/Redux/transactions-process/curatorBalanceHistorySlice/api-actions'

import { BalanceCards } from './BalanceCards'
import { BalanceAlerts } from './BalanceAlerts'
import { BalanceTable } from './BalanceTable'

const Balance: React.FC = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    void dispatch(getAllTransactions(1))
    void dispatch(getTransactionsDetails())
  }, [dispatch])

  return (
    <>
      <BalanceAlerts />
      <BalanceCards />
      <BalanceTable />
    </>
  )
}

export default Balance
