import React, { FC } from 'react'

import { useTranslation } from 'react-i18next'

import { formatDate } from 'src/Constants/formatDate'
import dayjs from 'src/Helpers/dayjs'

import { ShowDateProps } from './ShowDateProps'

import { StyledShowDate } from './styles'

export const ShowDate: FC<ShowDateProps> = ({ startDate, isFeed }) => {
  const { t } = useTranslation()

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  if (isFeed) {
    return (
      <StyledShowDate>
        {t('campaignsPage.startedAt')}&nbsp;
        {dayjs(startDate).tz(timezone).format(formatDate.formatDateMonthFull)}
      </StyledShowDate>
    )
  }

  const startDateContent = dayjs(startDate)
    .tz(timezone)
    .format(formatDate.formatDateMonthFull)

  return (
    <StyledShowDate>
      {t('campaignsPage.startedAt')} {startDateContent}
    </StyledShowDate>
  )
}
