import { createDraftSafeSelector } from '@reduxjs/toolkit'

import { RootState } from '..'

import { NotificationState } from '.'

const getState = (state: RootState): NotificationState => state.notifications

export const getNotifications = createDraftSafeSelector(
  getState,
  ({ notifications }) => notifications,
)
