import { FC, useEffect } from 'react'

import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import ReactLoading from 'react-loading'

import { PlatformHeader } from 'src/Components/PlatformModal/components/PlatformHeader'
import { NAVIGATE_BACK_INDEX } from 'src/Constants/constants'
import { colors, device } from 'src/Styled/variables'
import { FirstTrackSubHeader } from 'src/Components/FirstTrackSubHeader/FirstTrackSubHeader'
import Button from 'src/Components/Buttons/Button'
import {
  FirstChosenTrackParams,
  useFirstChosenTrack,
} from 'src/Hooks/useFirstChosenTrack'
import { create, signUpArtist } from 'src/Router/routes'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import { HelmetTitle } from 'src/Components/CreateCampaignLayout/components/HelmetTitle'
import { toggleActivatedEvent } from 'src/Helpers/TagManager'
import { CampaignTypeEnum } from 'src/Types'

import { TrackCard } from './TrackCard'

const StyledPlatformModal = styled.div`
  overflow-y: auto;
  background-color: ${colors.greyBlue};
  width: 100%;
  height: 100vh;
`
const InnerContainer = styled.div`
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  margin: 0 auto;

  @media ${device.mobileMin} {
    padding-right: 0px;
    padding-left: 0px;
    max-width: 800px;
  }
`

const ButtonWrapper = styled.div`
  width: 184px;
  margin: 0 auto;
  margin-top: 16px;
  margin-bottom: 40px;
  @media ${device.mobileMin} {
    margin-bottom: 80px;
  }
`
const PlatformCardContainer = styled.div`
  display: grid;
  text-align: center;
  padding: 0px 16px;
  grid-auto-flow: dense;
  @media ${device.mobileMin} {
    padding: 0px;
  }
`

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`

export const FirstChosenTrack: FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { trackId } = useParams<FirstChosenTrackParams>()
  const { track, platform, loading } = useFirstChosenTrack()
  const { search } = useCustomSearchParams()

  const handleCreate = (): void => {
    navigate(
      `${create}/${platform}/${trackId}${signUpArtist}?${search.toString()}`,
    )
  }

  const handleBackClick = (): void => {
    navigate(NAVIGATE_BACK_INDEX)
  }
  useEffect(() => {
    if (platform) {
      toggleActivatedEvent({
        platformName: platform,
        campaignType: CampaignTypeEnum.FIRST,
        campaignNumber: 1,
      })
    }
  }, [platform])

  if (loading) {
    return (
      <LoaderContainer>
        <ReactLoading height={44} color={colors.green} type='bars' />
      </LoaderContainer>
    )
  }
  return (
    <StyledPlatformModal>
      <PlatformHeader onBackClick={handleBackClick} />
      <InnerContainer>
        <HelmetTitle />
        <FirstTrackSubHeader onBackClick={handleBackClick} />
        <PlatformCardContainer>
          <TrackCard
            trackCoverUrl={track?.trackCoverUrl}
            trackName={track?.trackName}
            artistName={track?.artistName}
            trackDuration={track?.trackDuration}
            trackPreviewUrl={track?.trackPreviewUrl}
            onChangeClick={handleBackClick}
            platform={platform}
          />
        </PlatformCardContainer>
        <ButtonWrapper>
          <Button type='green' onClick={handleCreate}>
            {t('createCampaignPage.createCampaign')}
          </Button>
        </ButtonWrapper>
      </InnerContainer>
    </StyledPlatformModal>
  )
}
