import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import { useDebouncedCallback } from 'use-debounce'
import styled from 'styled-components'
import { message as AntMessage } from 'antd'

import { FullWideNotification } from 'src/Components/FullWideNotification'
import { colors } from 'src/Styled/variables'
import { sendQuickLoginLink } from 'src/Redux/auth-process/emailPasswordSlice/api-actions'
import { useAppDispatch } from 'src/Hooks/redux'
import { ApiError } from 'src/Redux/types'
import { useQuickLoginUTMParameters } from 'src/Hooks/useQuickLoginUTMParameters'
import { quickLogin } from 'src/Helpers/TagManager/AuthEvents'

export const LoginLinkButton = styled.button`
  cursor: pointer;
  background-color: ${colors.badgeOrange};
  border-color: ${colors.badgeOrange};
  border: 1px solid transparent;
  line-height: 1.5;
  color: ${colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-right: 0;
  border-radius: 50px;
  padding: 9px 39px;
`
const DELAY = 300

export const SendLoginLink: FC<{ email: string }> = ({ email }) => {
  const { t } = useTranslation()
  const [messageApi, contextHolder] = AntMessage.useMessage()
  const dispatch = useAppDispatch()
  const utmParameters = useQuickLoginUTMParameters()

  const handleSendQuickLink = (): void => {
    if (!email) {
      return
    }
    void dispatch(
      sendQuickLoginLink({
        email,
        utmParameters,
      }),
    )
      .unwrap()
      .then((res) => {
        void messageApi.open({
          content: <FullWideNotification message={t('login.weHaveSent')} />,
          duration: 3,
          style: { backgroundColor: colors.badgeGreen },
          icon: null,
        })
        quickLogin()
        return res
      })
      .catch((err: ApiError) => {
        const { message } = err
        if (
          message === 'Curator in blacklist' ||
          message === 'Country in blacklist'
        ) {
          void messageApi.open({
            content: (
              <FullWideNotification
                message={t('login.authErrorBlacklistRegistered')}
              />
            ),
            duration: 3,
            style: { backgroundColor: colors.badgeRed },
            icon: null,
          })
        }
      })
  }

  const debouncedSendQuickLink = useDebouncedCallback(() => {
    handleSendQuickLink()
  }, DELAY)

  return (
    <>
      {contextHolder}
      <LoginLinkButton
        data-cy='login-form-quick-login-link'
        onClick={debouncedSendQuickLink}
      >
        {t('login.emailMe')}
      </LoginLinkButton>
    </>
  )
}
