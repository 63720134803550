import React, { FC } from 'react'

import styled from 'styled-components'
import { Control, Controller } from 'react-hook-form'
import OutsideClickHandler from 'react-outside-click-handler'
import { useMediaQuery } from 'react-responsive'

import { PrimaryInput } from 'src/Components/PrimaryInput'
import { TrackAudioPlayer } from 'src/Components/TrackAudioPlayer'
import { MAX_MOBILE_WIDTH } from 'src/Styled/variables'

import { Loading } from '../Loading'
import { TrackItem } from '../TrackItem'
import { SearchTrackFormData, TrackSearchProps } from '../../index'

const ResultContainer = styled.div`
  display: flex;
  box-sizing: unset;
  width: calc(100% - 16px);
  max-height: 480px;
  overflow-y: auto;
  padding: 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.common.primaryBorder};
  background: ${({ theme }) => theme.common.primaryBg};
  box-shadow: 0px 0px 32px 0px rgba(51, 51, 51, 0.08);
  position: absolute;
  top: 60px;
  z-index: 1;
`

const InputContainer = styled.div`
  width: 100%;
  position: relative;
`

const ErrorWrapper = styled.div`
  padding: 12px;
`

type DesktopTabletTrackSearchProps = Pick<
  TrackSearchProps,
  | 'isLoading'
  | 'onItemClick'
  | 'onPlayClick'
  | 'tracks'
  | 'onSearch'
  | 'error'
  | 'defaultSearchValue'
  | 'onOutsideClick'
> & {
  placeholder: string
  control: Control<SearchTrackFormData>
}

export const DesktopTabletTrackSearch: FC<DesktopTabletTrackSearchProps> = ({
  isLoading,
  onItemClick,
  onPlayClick,
  tracks = [],
  onSearch,
  error,
  defaultSearchValue,
  onOutsideClick,
  placeholder,
  control,
}) => {
  const isMobile = useMediaQuery({ maxWidth: MAX_MOBILE_WIDTH })

  if (isMobile) {
    return null
  }

  return (
    <InputContainer>
      <Controller
        name='searchInput'
        control={control}
        defaultValue={defaultSearchValue || ''}
        render={({ field: { onChange, onBlur, value } }) => (
          <PrimaryInput
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
              onSearch(e.target.value)
            }
          />
        )}
      />

      {isLoading && (
        <ResultContainer>
          <Loading />
        </ResultContainer>
      )}

      {tracks.length && !error ? (
        <OutsideClickHandler onOutsideClick={onOutsideClick}>
          <ResultContainer>
            <TrackAudioPlayer
              render={({ loading, isPlaying, onClick, currentTrackUrl }) => (
                <>
                  {tracks.map((item) => (
                    <TrackItem
                      key={item.id}
                      onItemClick={() => onItemClick(item.id)}
                      trackName={item.name}
                      artistName={item.artistName}
                      trackDuration={item.trackDuration}
                      trackCoverUrl={item.trackCoverUrl}
                      loading={loading && currentTrackUrl === item.previewUrl}
                      onTrackControlClick={(e) => {
                        onClick(e, item.previewUrl)
                        if (!isPlaying) {
                          onPlayClick && onPlayClick()
                        }
                      }}
                      isPlaying={
                        isPlaying && currentTrackUrl === item.previewUrl
                      }
                      show={Boolean(item.previewUrl)}
                    />
                  ))}
                </>
              )}
            />
          </ResultContainer>
        </OutsideClickHandler>
      ) : null}

      {error && (
        <OutsideClickHandler onOutsideClick={onOutsideClick}>
          <ResultContainer>
            <ErrorWrapper>{error}</ErrorWrapper>
          </ResultContainer>
        </OutsideClickHandler>
      )}
    </InputContainer>
  )
}
