import styled from 'styled-components'

import { colors } from 'src/Styled/variables'

export const StyledSoundLinkHint = styled.div`
  display: grid;
  grid-template-columns: 194px 36px;
  column-gap: 8px;
  background-color: ${colors.black};
  color: ${colors.white};
  border-radius: 8px;
  line-height: 150%;
`

export const StyledCloseBtn = styled.button`
  cursor: pointer;
  background-color: transparent;
  padding: 0;
`
