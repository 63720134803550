import React, { FC } from 'react'

import { useForm } from 'react-hook-form'
import { string as yupString, object } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useTranslation } from 'react-i18next'
import SpotifyIcon from 'mdi-react/SpotifyIcon'

import { APP } from 'src/Configs/App'
import Button from 'src/Components/Buttons/Button'
import { SmartInput } from 'src/Components/SmartInput'
import { setLoginTypeToInitialState } from 'src/Redux/auth-process/authTypeSlice'
import { getAuthEmail } from 'src/Redux/auth-process/authTypeSlice/selectors'
import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'

import { SendLoginLink } from '../SendLoginLink'

import {
  AccountOr,
  FieldWrapper,
  Form,
  SpotifyLink,
  TextBlack,
  TextRed,
  Wrapper,
} from './styles'

interface FormState {
  email: string
}

export const LoginSpotify: FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const defaultEmail = useAppSelector(getAuthEmail)

  const {
    watch,
    formState: { errors },
    register,
  } = useForm<FormState>({
    defaultValues: {
      email: defaultEmail,
    },
    mode: 'all',
    resolver: yupResolver(
      object({
        email: yupString().email().required(),
      }),
    ),
  })

  const handleLink = (): void => {
    dispatch(setLoginTypeToInitialState())
  }
  return (
    <>
      <Form>
        <Wrapper>
          <FieldWrapper>
            <SmartInput
              {...register('email')}
              error={errors.email}
              placeholder={String(t('login.email'))}
              type='email'
              data-cy='input-render-field-email'
              autoFocus
            />
          </FieldWrapper>
        </Wrapper>
      </Form>
      <TextRed data-cy='text_red-login-form-spotify-without-password'>
        <p data-cy='login-form-spotify-without-password'>
          {t('login.withoutPassword')}
        </p>
      </TextRed>
      <TextBlack data-cy='login-form-spotify-we-can-email'>
        <p data-cy='login-form-spotify-we-can-email'>{t('login.weCanEmail')}</p>
      </TextBlack>
      <SendLoginLink email={watch().email} />
      <AccountOr data-cy='account__or-spotify-easily-using'>
        <p data-cy='login-form-spotify-easily-using'>
          {t('login.easilyUsing')}
        </p>
      </AccountOr>
      <SpotifyLink
        data-cy='login-form-spotify-link'
        href={APP.SPOTIFY_AUTH_API}
      >
        <SpotifyIcon />
        &nbsp;
        {t('signup.logInWithSpotify')}
      </SpotifyLink>
      <AccountOr data-cy='account__or-login-easily-using'>
        <p data-cy='login-form-p'>{t('login.easilyUsing')}</p>
      </AccountOr>
      <Button
        type='green'
        htmlType='submit'
        data-cy='login-form-sign-another-account'
        onClick={handleLink}
      >
        {t('login.logInAnotherAccount')}
      </Button>
    </>
  )
}
