import styled from 'styled-components'

import { variables, colors, device } from 'src/Styled/variables'

export const ReferAndEarnTitle = styled.h3`
  color: ${colors.mainText};
  font-weight: ${variables.fontWeight600};
  font-size: ${variables.fontSize20};
  margin-top: 6px;
  margin-bottom: 20px;
  line-height: 30px;
  display: flex;
  justify-content: center;
  & img {
    width: 12px;
    height: 12px;
  }
  @media ${device.mobileMin} {
    display: block;
  }
`

export const BackButton = styled.div`
  cursor: pointer;
  margin-right: 20px;
  display: flex;
  align-self: center;

  @media ${device.mobileMin} {
    display: none;
  }
`
