import styled from 'styled-components'

import { device } from 'src/Styled/variables'

export const StyledButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 40px;

  img {
    margin-right: 0.625rem;
    width: 1rem;
    height: 1rem;
  }

  @media ${device.mobileMax} {
    width: 100%;
  }
`
export const StyledCloseButton = styled.div`
  width: fit-content;
  .ant-btn {
    padding: 0 40px;
  }
`
export const ButtonsGroups = styled.div`
  margin-top: 40px;
  margin-bottom: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 24px;

  @media ${device.mobileMin} {
    flex-direction: row;
    justify-content: center;
    column-gap: 1rem;
    align-items: flex-start;
  }
`
