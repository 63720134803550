import TagManager from 'react-gtm-module'

import { CampaignTypeEnum, FlowType } from 'src/Types'

import { EventNameEnum, EventTypeEnum } from '../tagManagerEnums'

export const genreAddedEvent = ({
  campaignType,
  genreId,
  genreName,
  platformName,
  userId,
  campaignId,
  campaignNumber,
  subscriptionPlan,
}: {
  campaignType: string | CampaignTypeEnum
  genreId: string | undefined
  genreName: string | undefined
  platformName?: string
  userId?: string
  campaignId?: number | string
  campaignNumber?: number
  subscriptionPlan?: string
}): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventTypeEnum.GenreAdded,
      event_name: EventNameEnum.GenreAdded,
      flow: FlowType.scheduling,
      'campaign-type': campaignType,
      'campaign-platform': platformName,
      'track-platform': platformName,
      'campaign-id': campaignId,
      userID: userId,
      'genre-name': genreName,
      'genre-id': genreId,
      'campaign-number':
        campaignType === CampaignTypeEnum.FIRST ? 1 : campaignNumber,
      ...(subscriptionPlan ? { subscription_plan: subscriptionPlan } : {}),
    },
  })
}

export const genreRemovedEvent = ({
  campaignType,
  genreId,
  genreName,
  platformName,
  userId,
  campaignId,
}: {
  campaignType: string
  genreId?: number
  genreName?: string
  platformName?: string
  userId?: string
  campaignId?: number
}): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventTypeEnum.GenreRemoved,
      event_name: EventNameEnum.GenreRemoved,
      'campaign-type': campaignType,
      'campaign-platform': platformName,
      'track-platform': platformName,
      'campaign-id': campaignId,
      userID: userId,
      'genre-name': genreName,
      'genre-id': genreId,
    },
  })
}
