import { FC } from 'react'

import { Input, InputProps } from 'antd'
import styled from 'styled-components'

import SearchIcon from 'src/Assets/Svg/pages-icons/Search.svg?react'
import CloseIcon from 'src/Assets/Svg/pages-icons/Close.svg?react'

const StyledInput = styled(Input)`
  border-radius: 40px;
  border: 1px solid transparent;
  padding: 12px 16px;
  background-color: ${({ theme }) => theme.common.primaryBg};
  width: 180px;
  color: ${({ theme }) => theme.common.primaryText};
  font-family: 'Segoe UI';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  @media screen and (max-width: 767px) {
    border-radius: 50%;
    width: 48px;
    input::placeholder {
      visibility: hidden;
    }
    .ant-input-clear-icon {
      visibility: hidden;
    }
  }

  input::placeholder {
    overflow: hidden;
    color: ${({ theme }) => theme.common.primaryText};
    text-overflow: ellipsis;
    font-family: 'Segoe UI';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
  &:hover {
    border: 1px solid ${({ theme }) => theme.common.primaryBorder};
    box-shadow: 0px 0px 12px 0px rgba(51, 51, 51, 0.12);
    cursor: text;
  }
  &:focus-visible,
  &:focus,
  &:focus-within {
    border-radius: 40px;
    border-color: ${({ theme }) => theme.common.thirdBorder};

    box-shadow: none;
    width: 320px;
    @media screen and (max-width: 767px) {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      input::placeholder {
        visibility: visible;
      }
      .ant-input-clear-icon {
        visibility: visible;
      }
    }
  }
  .ant-input-clear-icon,
  .ant-input-prefix {
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      fill: ${({ theme }) => theme.common.primaryText};
    }
  }
  &.ant-input-disabled {
    background-color: ${({ theme }) => theme.common.primaryBg};
    box-shadow: none;
    border: 1px solid transparent;
    input::placeholder {
      color: ${({ theme }) => theme.common.thirdText};
    }
    .ant-input-prefix svg {
      fill: ${({ theme }) => theme.common.thirdText};
    }
  }
  &.ant-input-disabled:hover:not([disabled]) {
    border: 1px solid transparent;
    background-color: ${({ theme }) => theme.common.primaryBg};
  }
`

export type CollapsedSearchInputProps = InputProps

export const CollapsedSearchInput: FC<CollapsedSearchInputProps> = (props) => (
  <StyledInput
    allowClear={{ clearIcon: <CloseIcon /> }}
    prefix={<SearchIcon />}
    {...props}
  ></StyledInput>
)
