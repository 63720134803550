import styled from 'styled-components'

import { variables, colors } from 'src/Styled/variables'

interface Props {
  align?: 'left' | 'right'
  status?: boolean
  color?: string
}

export const MainWrapper = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 24px;
`

export const InnerWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`

export const Divider = styled.div<Props>`
  height: 1px;
  width: calc(100% - 40px);
  background-color: ${(props: Props) =>
    props.status ? colors.green : colors.greyTextBlackBg};
`

export const Circle = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${(props: Props) =>
    props.status ? colors.green : colors.greyTextBlackBg};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.white};
  font-weight: ${variables.fontWeight600};
  font-size: ${variables.fontSize18};
  line-height: 27px;
`

export const Text = styled.div<Props>`
  color: ${(props: Props) => props.color};
  text-align: ${(props: Props) => props.align};
`
