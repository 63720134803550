import styled from 'styled-components'

import { colors } from 'src/Styled/variables'

export const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
`
export const Wrapper = styled.div`
  margin-bottom: 24px;
  width: 100%;
  position: relative;
`
export const FieldWrapper = styled.div`
  display: flex;
  margin-bottom: 24px;
  width: 100%;
  position: relative;
  flex-direction: column;
  width: 100%;
  margin: auto;

  input {
    height: 43px;
    background-color: ${colors.greyBg};
    border: 2px solid ${colors.greyTextLight} !important;
    border-radius: 5px;
    width: 100%;
    padding: 5px 10px;
    font-size: 12px;
    transition: border 0.3s;
    color: #646877;
    &::-webkit-input-placeholder {
      color: $color-additional;
    }
    &::-moz-placeholder {
      color: $color-additional;
    }
    /* Firefox 19+ */
    &:-moz-placeholder {
      color: $color-additional;
    }
    /* Firefox 18- */
    &:-ms-input-placeholder {
      color: $color-additional;
    }

    &:focus,
    &:active {
      outline: none;
    }
  }
`
