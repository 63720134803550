import styled from 'styled-components'

import { variables, colors } from 'src/Styled/variables'
import { SmartImage } from 'src/Components/SmartImage'

export const TableRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2px;
  padding: 0 16px;
  width: 100%;
  flex-basis: 95px;
  background: ${colors.greyBg};
`

export const TableData = styled.div<{
  fillProp?: boolean
  width?: string
  center?: boolean
}>`
  ${({ fillProp }) => fillProp && 'flex: 1;'}
  width: ${({ width }) => width || 'auto'};
  padding: 8px;
  display: flex;
  justify-content: ${({ center }) => (center ? 'center' : 'flex-start')};
`

export const TableDataMobile = styled.div`
  margin-bottom: 16px;
`
export const ButtonWrapper = styled.div`
  width: fit-content;
  .ant-btn.ant-btn-default {
    padding: 0 35px;
  }
`

export const PlaylistCover = styled(SmartImage)`
  width: 40px;
  height: 40px;
  border-radius: 4px;
  object-fit: cover;
`

export const Text = styled.p`
  margin: 0;
  font-size: ${variables.fontSize14};
  line-height: 21px;
  color: ${colors.mainText};

  span {
    font-size: ${variables.fontSize14};
    line-height: 21px;
    color: ${colors.greyTextWhiteBg};
  }
`

export const MainInfo = styled.div`
  display: flex;
  flex-direction: column;
`

export const MobileCard = styled.div`
  display: flex;
  margin-bottom: 16px;
  padding: 16px;
  background-color: ${colors.white};
  border-radius: 4px;
`

export const MobileCardRightContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 16px;
`

export const ContactSupportBtn = styled.a`
  color: ${colors.mainText} !important;
  border-bottom: 1px dashed ${colors.mainText};
  text-decoration: none;
  text-align: center;

  &:hover {
    color: ${colors.mainText};
  }
`
