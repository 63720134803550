import { FC } from 'react'

import { CampaignPlatformTypeEnum } from 'src/Types'
import { SpotifyIcon, TikTokIcon } from 'src/Assets/Svg'

import { IconWrapper, RowText, TrackNameRow } from './styles'

interface TrackInfoProps {
  platformType: CampaignPlatformTypeEnum
  trackAuthor: string
  trackTitle: string
}

export const TrackInfo: FC<TrackInfoProps> = ({
  platformType,
  trackAuthor,
  trackTitle,
}) => {
  const isSpotify = platformType === CampaignPlatformTypeEnum.SPOTIFY

  return (
    <TrackNameRow>
      <IconWrapper>
        {isSpotify ? (
          <SpotifyIcon data-testid='spotifyIcon' />
        ) : (
          <TikTokIcon data-testid='tikTokIcon' />
        )}
      </IconWrapper>

      <RowText>
        <RowText>{`${trackAuthor} - ${trackTitle}`}</RowText>
      </RowText>
    </TrackNameRow>
  )
}
