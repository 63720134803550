import { FC, useEffect } from 'react'

import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { CampaignPlatformTypeEnum } from 'src/Types'
import { useCreateCampaignEvents } from 'src/Hooks/Analytics/useCreateCampaignEvents'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import { createFirstCampaignStartEvent } from 'src/Helpers/TagManager'
import { ArtistRoutesEnum } from 'src/Router/ArtistComponentsNewUI'
import { variables } from 'src/Styled/variables'

import { NoAuthPageLayout } from '../../Components/NoAuthPageLayout'
import { HelmetTitle } from '../../Components/HelmetTitle'
import { PlatformButton } from '../../Components/PlatformButton'
import { WhatArtistsThink } from '../../Components/WhatArtistsThink'

const ContentContainer = styled.div`
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 80px;
`

const TopContentContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 960px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
`

const Title = styled.h2`
  margin: 0;
  padding: 0;
  color: ${({ theme }) => theme.common.primaryText};
  text-align: center;
  font-size: ${variables.fontSize28};
  font-style: normal;
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
`

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`

const Subtitle = styled.span`
  color: ${({ theme }) => theme.common.primaryText};
  font-family: 'Segoe UI';
  font-size: ${variables.fontSize18};
  font-style: normal;
  font-weight: ${variables.fontWeight400};
  line-height: 150%;
`

const PlatformsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`

export const ChoosePlatformNoAuthPage: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { search } = useCustomSearchParams()
  const { campaignCreationStartMpEventHandler } = useCreateCampaignEvents()

  useEffect(() => {
    createFirstCampaignStartEvent()
  }, [])

  const onSpotifyClick = (): void => {
    campaignCreationStartMpEventHandler({
      platformType: CampaignPlatformTypeEnum.SPOTIFY,
    })

    navigate(`${ArtistRoutesEnum.CreateSpotify}?${search.toString()}`)
  }
  const onTikTokClick = (): void => {
    campaignCreationStartMpEventHandler({
      platformType: CampaignPlatformTypeEnum.TIKTOK,
    })

    navigate(`${ArtistRoutesEnum.CreateTikTok}?${search.toString()}`)
  }

  return (
    <NoAuthPageLayout>
      <HelmetTitle />

      <ContentContainer>
        <TopContentContainer>
          <TitleContainer>
            <Title>{t('createNewCampaignPage.createNewCampaign')}</Title>
            <Subtitle>{t('createNewCampaignPage.choosePlatform')}</Subtitle>
          </TitleContainer>
          <PlatformsContainer>
            <PlatformButton
              platform={CampaignPlatformTypeEnum.SPOTIFY}
              onClick={onSpotifyClick}
            />
            <PlatformButton
              platform={CampaignPlatformTypeEnum.TIKTOK}
              onClick={onTikTokClick}
            />
          </PlatformsContainer>
        </TopContentContainer>

        <WhatArtistsThink />
      </ContentContainer>
    </NoAuthPageLayout>
  )
}
