import { useMemo } from 'react'

import { getCampaignToSchedulePrice } from 'src/Redux/campaign-process/spotifyCampaignSchedule-process/selectors'
import {
  getArtistBalance,
  getCountryName,
} from 'src/Redux/auth-process/userSlice/selectors'
import { getCoupon } from 'src/Redux/schedule-process/couponSlice/selectors'
import { CampaignPlatformTypeEnum } from 'src/Types'
import { getTiktokPrice } from 'src/Redux/schedule-process/audienceTiktokSlice/selectors'
import { FIXED_BY } from 'src/Constants/constants'
import { useGetCurrencyInfoQuery } from 'src/Redux/userExchangeRateApi'

import { usePayment } from './usePayment'
import { useAppSelector } from './redux'

interface UsePaymentAmountsWithCurrency {
  currencyDiscount: string
  campaignCurrencyBudget: string
  currencyVat: string
  currencyBalance: string
  currencyTotal: string
  isVATCountry: boolean
  dollarTotal?: number
}

const calculateCurrencyValue = (
  amount: number | undefined,
  currencyRate: number | undefined,
  currencySymbol?: string,
): string => {
  if (!amount || !currencyRate) {
    return `${currencySymbol} 0.00`
  }
  return `${currencySymbol} ${(amount * currencyRate).toFixed(FIXED_BY)}`
}
const FALLBACK_CURRENCY = {
  currencyRate: 1,
  currencySymbol: '$',
}

export const usePaymentAmountsWithCurrency = (
  platformType: CampaignPlatformTypeEnum,
): UsePaymentAmountsWithCurrency => {
  const { calculatePaymentAmounts } = usePayment()

  const { data: currantCurrency } = useGetCurrencyInfoQuery()

  const { currencyRate, currencySymbol } = useMemo(
    () => ({
      currencyRate:
        currantCurrency?.currencyRate || FALLBACK_CURRENCY.currencyRate,
      currencySymbol:
        currantCurrency?.currencySymbol || FALLBACK_CURRENCY.currencySymbol,
    }),
    [currantCurrency],
  )

  const spotifyCampaignPrice = useAppSelector(getCampaignToSchedulePrice) || 0
  const tikTokCampaignPrice = useAppSelector(getTiktokPrice) || 0

  const price =
    platformType === CampaignPlatformTypeEnum.SPOTIFY
      ? spotifyCampaignPrice
      : tikTokCampaignPrice

  const country = useAppSelector(getCountryName) || 'US'
  const coupon = useAppSelector(getCoupon)
  const balance = useAppSelector(getArtistBalance)

  const currentPaymentAmounts = calculatePaymentAmounts({
    price,
    balance,
    country,
    coupon,
  }) || {
    balanceUseAmount: 0,
    VATAmount: 0,
    couponDiscountAmount: 0,
    total: 0,
  }

  const isVATCountry = Boolean(currentPaymentAmounts.VATAmount)

  const currencyDiscount = calculateCurrencyValue(
    currentPaymentAmounts.couponDiscountAmount,
    currencyRate,
    currencySymbol,
  )

  const campaignCurrencyBudget = calculateCurrencyValue(
    price,
    currencyRate,
    currencySymbol,
  )

  const currencyBalance = calculateCurrencyValue(
    currentPaymentAmounts.balanceUseAmount,
    currencyRate,
    currencySymbol,
  )

  const currencyVat = calculateCurrencyValue(
    currentPaymentAmounts.VATAmount,
    currencyRate,
    currencySymbol,
  )

  const currencyTotal = calculateCurrencyValue(
    currentPaymentAmounts.total,
    currencyRate,
    currencySymbol,
  )

  return {
    currencyDiscount,
    campaignCurrencyBudget,
    currencyVat,
    currencyBalance,
    currencyTotal,
    isVATCountry,
    dollarTotal: currentPaymentAmounts.total,
  }
}
