import { FunctionComponent } from 'react'

import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import Button from 'src/Components/Buttons/Button'
import { getEventDetails } from 'src/Redux/influencer-process/selectors'
import TikTokIcon from 'src/Assets/Svg/tiktok.svg?react'

import { AwaitingProps } from './types'

import { StyledButton, ButtonsGroups, StyledCloseButton } from './styles'

export const Awaiting: FunctionComponent<AwaitingProps> = ({ t, onClose }) => {
  const eventDetails = useSelector(getEventDetails)

  return (
    <ButtonsGroups>
      <Link to={String(eventDetails?.tiktokVideoUrl)} target='_blank'>
        <StyledCloseButton>
          <Button type='whiteWithGreenBorder'>
            <StyledButton>
              <TikTokIcon />
              {t('influencer.videoOnTiktok')}
            </StyledButton>
          </Button>
        </StyledCloseButton>
      </Link>
      <StyledCloseButton>
        <Button type='white' onClick={onClose}>
          {t('influencer.closeBtn')}
        </Button>
      </StyledCloseButton>
    </ButtonsGroups>
  )
}
