import { FunctionComponent, ReactElement } from 'react'

import { useTranslation } from 'react-i18next'

import { PageSubTitle } from './styles'

export const InfluencerBalanceTitle: FunctionComponent = (): ReactElement => {
  const { t } = useTranslation()
  return <PageSubTitle>{t('balancePage.pageTitle')}</PageSubTitle>
}
