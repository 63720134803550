import { FC, InputHTMLAttributes } from 'react'

import styled, { css } from 'styled-components'

import CheckboxIcon from 'src/Assets/Svg/pages-icons/Checkbox.svg?react'
import CheckboxCheckedIcon from 'src/Assets/Svg/pages-icons/CheckboxChecked.svg?react'

export interface PremiumCheckboxProps
  extends InputHTMLAttributes<HTMLInputElement> {
  children?: React.ReactNode
}

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  .CheckboxCheckedIcon,
  .CheckboxIcon {
    z-index: 2;
    width: 16px;
    height: 16px;
  }
  .CheckboxIcon {
    stroke: ${({ theme }) => theme.common.primaryText};
  }
`

const Label = styled.label<{ $checked?: boolean; $disabled?: boolean }>`
  cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};
  color: ${({ theme }) => theme.common.primaryText};
  font-family: 'Segoe UI';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  display: flex;
  width: auto;
  height: 40px;
  padding: 0px 16px;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;
  background: ${({ theme }) => theme.common.primaryBg};

  &:focus-visible {
    color: ${({ theme }) => theme.button.primaryText};
    outline: none;
    .CheckboxIcon {
      stroke: ${({ theme }) => theme.button.primaryText};
    }
  }

  &:hover {
    ${IconWrapper} {
      &::before {
        content: ${({ $disabled }) => (!$disabled ? "''" : 'none')};
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background-color: ${({ $checked, theme }) =>
          $checked ? theme.common.fourthBg : theme.button.secondaryBg};
      }
    }
  }

  ${({ $disabled }) => {
    if ($disabled) {
      return css`
        color: ${({ theme }) => theme.common.thirdText};
        .CheckboxIcon,
        .CheckboxCheckedIcon {
          stroke: ${({ theme }) => theme.common.thirdText};
        }
        .CheckboxCheckedIcon {
          rect {
            fill: transparent;
          }
        }
      `
    }
    return css`
      &:active {
        .CheckboxCheckedIcon,
        .CheckboxIcon {
          stroke: ${({ theme }) => theme.common.primaryText};
          rect {
            fill: ${({ theme }) => theme.button.secondaryBg};
          }
        }
        ${IconWrapper}::before {
          background-color: transparent;
        }
      }
    `
  }}
`

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

export const PremiumCheckbox: FC<PremiumCheckboxProps> = ({
  children,
  ...restProps
}) => (
  <Label
    tabIndex={0}
    $disabled={restProps.disabled}
    $checked={restProps.checked}
  >
    <HiddenCheckbox {...restProps} />
    <IconWrapper>
      {restProps.checked ? (
        <CheckboxCheckedIcon className='CheckboxCheckedIcon' />
      ) : (
        <CheckboxIcon className='CheckboxIcon' />
      )}
    </IconWrapper>
    {children}
  </Label>
)
