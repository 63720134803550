import React, { ReactElement } from 'react'

import { Input as AntInput } from 'antd'
import { useDebouncedCallback } from 'use-debounce'

import { ScopeIcon } from 'src/Assets/Svg'
import { DEBOUNCE_DELAY_1000 } from 'src/Constants/constants'

import { SearchProps } from './SearchProps'

import { IconWrapper, StyledSearch } from './styles'

const Search: React.FC<SearchProps> = ({
  placeholder,
  onChange,
  disabled,
}): ReactElement => {
  const debouncedOnChange = useDebouncedCallback((e: string) => {
    onChange(e.trim())
  }, DEBOUNCE_DELAY_1000)

  const persistOnChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void => {
    e.persist()
    debouncedOnChange(e.target.value)
  }

  return (
    <StyledSearch>
      <IconWrapper>
        <ScopeIcon />
      </IconWrapper>
      <AntInput
        placeholder={placeholder}
        disabled={disabled}
        onChange={persistOnChange}
      />
    </StyledSearch>
  )
}

export default Search
