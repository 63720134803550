import { FC } from 'react'

import { useTranslation } from 'react-i18next'

import SandClock from 'src/Assets/Svg/soonInfluencerScreen.svg?react'

import { Container, TextWrapper } from './styles'

export const EmptyListItem: FC = () => {
  const { t } = useTranslation()

  return (
    <Container>
      <div>
        <SandClock />
      </div>
      <TextWrapper>
        <div>{t('campaignResultsPage.videosInProgressByCreators')}</div>
        <div> {t('campaignResultsPage.checkBack')}</div>
      </TextWrapper>
    </Container>
  )
}
