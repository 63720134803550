import { FC } from 'react'

import { useTranslation } from 'react-i18next'

import { CampaignStatusEnum } from 'src/Types'

import {
  CampaignCalculation,
  CampaignCalculationLabel,
  CampaignCalculationValue,
} from '../styles'

const FINISHED_STATUSES = [CampaignStatusEnum.ENDED, CampaignStatusEnum.STOPPED]

interface CreditsProps {
  status?: CampaignStatusEnum
  refund?: number
}
export const Credits: FC<CreditsProps> = ({ status, refund }) => {
  const { t } = useTranslation()
  if (status && !FINISHED_STATUSES.includes(status)) {
    return null
  }
  return (
    <CampaignCalculation>
      <CampaignCalculationLabel>
        {t('campaignResultsPage.creditsLabel')}:{' '}
      </CampaignCalculationLabel>
      <CampaignCalculationValue green>{`$${String(
        refund,
      )}`}</CampaignCalculationValue>
    </CampaignCalculation>
  )
}
