import { FC } from 'react'

import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

import { ArtistRoutesEnum } from 'src/Router/ArtistComponentsNewUI'
import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { PrimaryAlert } from 'src/Components/PrimaryAlert'
import { PrimaryDottedButton } from 'src/Components/Buttons/PrimaryDottedButton'
import i18n from 'src/Localization/i18n'
import { getCampaigns } from 'src/Redux/campaignList-process'
import {
  getApprovedAlertsIds,
  setApprovedAlertsList,
} from 'src/Redux/alert-process'
import { useSpotifyCampaignDetails } from 'src/Redux/campaignDetailsApi/useSpotifyCampaignDetails'

const StyledPrimaryDottedButton = styled(PrimaryDottedButton)`
  padding-left: 0;
  padding-right: 0;
`
const GreenPrimaryDottedButton = styled(StyledPrimaryDottedButton)`
  color: ${({ theme }) => theme.button.primaryText};
  & > div {
    border-bottom: 1px dashed ${({ theme }) => theme.button.primaryText};
  }
`
const GreenPrimaryDottedButtonWrapper = styled.div`
  display: flex;
  gap: 16px;
`

const APPROVED_STATUSES = ['pending', 'approved']

export const CampaignApprovedAlert: FC = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { campaignDetails, isLoading } = useSpotifyCampaignDetails()
  const campaigns = useAppSelector(getCampaigns)
  const approvedIdsAlertArray = useAppSelector(getApprovedAlertsIds)

  const campaign = campaigns?.find(
    ({ status, id }) =>
      APPROVED_STATUSES.includes(status) &&
      !approvedIdsAlertArray.includes(Number(id)),
  )

  const onLaunchCampaign = (): void => {
    const updatedAlertIds = [...approvedIdsAlertArray, campaignDetails?.id]
    dispatch(setApprovedAlertsList(updatedAlertIds))
    navigate(`${ArtistRoutesEnum.SpotifySchedule}/${campaign?.id}`)
  }

  if (!campaign) {
    return null
  }

  return (
    <PrimaryAlert
      type='success'
      hasCross={false}
      title={i18n.t('campaignsPage.yourCampaignIsApproved')}
      message={`${i18n.t('campaignsPage.greatNews', { trackName: campaign?.trackTitle })}`}
      renderButtons={() => (
        <GreenPrimaryDottedButtonWrapper>
          <GreenPrimaryDottedButton
            disabled={isLoading}
            onClick={onLaunchCampaign}
          >
            {i18n.t('campaignsPage.startCampaign')}
          </GreenPrimaryDottedButton>
        </GreenPrimaryDottedButtonWrapper>
      )}
    />
  )
}
