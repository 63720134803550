import { FC } from 'react'

import StarYellow from 'src/Assets/Svg/star-yellow.svg?react'
import StarGrey from 'src/Assets/Svg/star-grey.svg?react'

export const Rating: FC<{ rating: number }> = ({ rating }) => {
  const ratingArray = Array.from({ length: 5 })
  for (let i = 0; i < Number(rating); i += 1) {
    ratingArray[i] = true
  }
  return (
    <>
      {ratingArray.map((el, index) => {
        if (el) {
          return (
            <StarYellow
              width={16}
              height={16}
              key={`${index.toString()}}`}
              data-testid='yellow-star'
            />
          )
        }
        return (
          <StarGrey
            width={16}
            height={16}
            key={`${index.toString()}}`}
            data-testid='grey-star'
          />
        )
      })}
    </>
  )
}
