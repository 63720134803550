import { createAsyncThunk } from '@reduxjs/toolkit'
import axios, { AxiosError } from 'axios'

import { APP } from 'src/Configs/App'
import { ErrorCode, NameSpace } from 'src/Redux/types'
import { api } from 'src/Services/api'
import { RootState } from 'src/Redux'
import { getUserEmail } from 'src/Redux/auth-process/userSlice/selectors'
import { CancelPremiumFormValues } from 'src/Containers/Subscriptions/CancelSubscription/CancelSubscriptionForm/CancelSubscriptionForm'
import i18n from 'src/Localization/i18n'
import { getUserInfo } from 'src/Redux/auth-process/userSlice/api-actions'

import { Subscription } from '.'

export const getSubscription = createAsyncThunk(
  `${NameSpace.SUBSCRIPTION}/getSubscription`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await api.get<Subscription>(
        `${APP.TIKTOK_SERVER}/subscriptions/user-last`,
      )

      return data
    } catch (error) {
      const { message, response } = error as AxiosError
      return rejectWithValue({
        message,
        code: response?.status || ErrorCode.InternalServerError,
      })
    }
  },
)

interface SubscriptionPayload {
  fullPaymentAmount: number
  packageId: number
  paymentAmount: number
  paymentType: string
  userPaymentMethodId: number
  packageName: string
  cashback: number
}

type SubscriptionResponse = Omit<Subscription, 'package'>

export const createSubscription = createAsyncThunk<
  Subscription,
  SubscriptionPayload
>(
  `${NameSpace.SUBSCRIPTION}/createSubscription`,
  async (body, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await api.post<SubscriptionResponse>(
        `${APP.TIKTOK_SERVER}/subscriptions`,
        body,
      )

      await dispatch(getUserInfo())
      return {
        ...data,
        package: {
          id: body.packageId,
          cashbackPercentage: body.cashback,
          name: body.packageName,
          paymentAmount: body.paymentAmount,
        },
      }
    } catch (error) {
      const { message, response } = error as AxiosError
      return rejectWithValue({
        message,
        code: response?.status || ErrorCode.InternalServerError,
      })
    }
  },
)

export const changeSubscription = createAsyncThunk<
  Subscription,
  SubscriptionPayload
>(
  `${NameSpace.SUBSCRIPTION}/changeSubscription`,
  async (body, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await api.post<SubscriptionResponse>(
        `${APP.TIKTOK_SERVER}/subscriptions/change`,
        body,
      )
      await dispatch(getUserInfo())
      return {
        ...data,
        package: {
          id: body.packageId,
          cashbackPercentage: body.cashback,
          name: body.packageName,
          paymentAmount: body.paymentAmount,
        },
      }
    } catch (error) {
      const { message, response } = error as AxiosError
      return rejectWithValue({
        message,
        code: response?.status || ErrorCode.InternalServerError,
      })
    }
  },
)

interface CancelSubscriptionPayload extends CancelPremiumFormValues {
  id: number
  plan: string
}

export const cancelSubscription = createAsyncThunk<
  unknown,
  CancelSubscriptionPayload
>(
  `${NameSpace.CANCEL_SUBSCRIPTION}/cancelSubscription`,
  async (
    { id, reason, reasonDetails, plan },
    { rejectWithValue, getState },
  ) => {
    try {
      const state = getState() as RootState
      const email = getUserEmail(state)
      const { data } = await api.post<unknown>(
        `${APP.TIKTOK_SERVER}/subscriptions/${id}/cancel`,
      )

      await axios.post(
        'https://hook.eu1.make.com/qfz7w8ox5b6e4n7f1xgmi14d9uu94elf',
        {
          email,
          cancelled_plan: plan,
          cancelled_reason: `${i18n.t(reason, { lng: 'en' })} ${
            reasonDetails || ''
          }`,
        },
      )
      return data
    } catch (error) {
      const { message, response } = error as AxiosError
      return rejectWithValue({
        message,
        code: response?.status || ErrorCode.InternalServerError,
      })
    }
  },
)
