import { createSlice } from '@reduxjs/toolkit'

import {
  Recipient,
  TransferwiseRequirement,
  WiseCurrency,
} from 'src/Types/index'
import { setApiError } from 'src/Hooks/redux'

import { ApiError, NameSpace } from '../types'

import {
  addTransferwiseRecipient,
  getCurrencies,
  getRecipientBankDetails,
  getTransferwiseAccountRequirements,
  specifyTransferwiseAccountRequirements,
} from './api-actions'

export interface WiseState {
  currencies: Array<WiseCurrency>
  requirements: Array<TransferwiseRequirement>
  recipient: Recipient | null
  loading: boolean
  error: ApiError | null
}

const initialState: WiseState = {
  currencies: [],
  requirements: [],
  recipient: null,
  loading: false,
  error: null,
}

export const wiseProcess = createSlice({
  initialState,
  name: NameSpace.Wise,
  reducers: {
    setRequirementsToInitialState(state) {
      state.requirements = []
    },
  },
  extraReducers: (builder) => {
    // getCurrencies
    builder.addCase(getCurrencies.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getCurrencies.fulfilled, (state, { payload }) => {
      state.loading = false
      state.currencies = payload.data
    })
    builder.addCase(getCurrencies.rejected, (state, action) => {
      state.loading = false
      state.error = setApiError(action)
    })
    // getRecipientBankDetails
    builder.addCase(getRecipientBankDetails.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getRecipientBankDetails.fulfilled, (state, { payload }) => {
      state.loading = false
      state.recipient = payload
    })
    builder.addCase(getRecipientBankDetails.rejected, (state, action) => {
      state.loading = false
      state.error = setApiError(action)
    })

    // addTransferwiseRecipient
    builder.addCase(addTransferwiseRecipient.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      addTransferwiseRecipient.fulfilled,
      (state, { payload }) => {
        state.loading = false
        state.recipient = payload
      },
    )
    builder.addCase(addTransferwiseRecipient.rejected, (state, action) => {
      state.loading = false
      state.error = setApiError(action)
    })

    // getTransferwiseAccountRequirements
    builder.addCase(getTransferwiseAccountRequirements.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      getTransferwiseAccountRequirements.fulfilled,
      (state, { payload }) => {
        state.loading = false
        state.requirements = payload.data
      },
    )
    builder.addCase(
      getTransferwiseAccountRequirements.rejected,
      (state, action) => {
        state.loading = false
        state.error = setApiError(action)
      },
    )

    // specifyTransferwiseAccountRequirements
    builder.addCase(specifyTransferwiseAccountRequirements.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      specifyTransferwiseAccountRequirements.fulfilled,
      (state, { payload }) => {
        state.loading = false
        state.requirements = payload.data
      },
    )
    builder.addCase(
      specifyTransferwiseAccountRequirements.rejected,
      (state, action) => {
        state.loading = false
        state.error = setApiError(action)
      },
    )
  },
})

export const { setRequirementsToInitialState } = wiseProcess.actions
