import React, { FC } from 'react'

import { useTranslation } from 'react-i18next'

import { TransactionTypeEnum } from 'src/Types'
import { colors } from 'src/Styled/variables'

import { getBalanceItemDescription } from '../halpers'
import { InfoText } from '../styles'

interface MobileCardInfoTextProps {
  transactionType: TransactionTypeEnum
  firstName?: string
  packageName?: string
}

export const MobileCardInfoText: FC<MobileCardInfoTextProps> = (props) => {
  const { transactionType, firstName, packageName } = props
  const { t } = useTranslation()

  switch (transactionType) {
    case TransactionTypeEnum.RECEIVED:
      return <InfoText>{t('balancePage.RefundFrom')}</InfoText>
    case TransactionTypeEnum.SUBSCRIPTION:
      return <InfoText>{t('balancePage.monthlyPayment')}</InfoText>
    case TransactionTypeEnum.SUBSCRIPTION_PAYMENT_FAILED:
      return (
        <InfoText color={colors.badgeRed}>
          {t('balancePage.subscriptionFailed')}
        </InfoText>
      )
    case TransactionTypeEnum.QUICK_CHECKOUT_BONUS_FUTURE:
    case TransactionTypeEnum.QUICK_CHECKOUT_BONUS_CURRENT:
      return <InfoText>{t('balancePage.FundsForQuickCheckout')}</InfoText>
    case TransactionTypeEnum.CASHBACK:
      return <InfoText>{t('balancePage.cashbackFor')}</InfoText>
    case TransactionTypeEnum.CHARGEBACK:
      return packageName ? (
        <InfoText>Payment has been canceled</InfoText>
      ) : (
        <InfoText>{t('balancePage.TransactionHasBeenCanceled')}</InfoText>
      )
    case TransactionTypeEnum.PAYMENT:
      return <InfoText>{t('balancePage.CampaignWasPaid')}</InfoText>
    default:
      return (
        <InfoText>
          {getBalanceItemDescription(transactionType, firstName).footer}
        </InfoText>
      )
  }
}
