import { FC } from 'react'

import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  padding: 8px 0px;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  justify-content: space-between;
  width: 100%;
`
const Title = styled.div`
  color: ${({ theme }) => theme.common.primaryText};
  font-family: 'Segoe UI';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`
const Price = styled(Title)`
  text-align: right;
`

interface Props {
  title: string
  price: string
  className?: string
}
export const ScheduleSummaryPriceDetailsItem: FC<Props> = ({
  className,
  price,
  title,
}) => (
  <Wrapper className={className}>
    <Title>{title}</Title>
    <Price>{price}</Price>
  </Wrapper>
)
