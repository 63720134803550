import styled from 'styled-components'

import { SmartImage } from 'src/Components/SmartImage'

export const Avatar = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  border-radius: 0;
  border: none;
  transition: all 0.3s;
  box-shadow: none;
  padding: 0 15px;
  background-color: transparent;

  &:hover,
  &:focus,
  &:active,
  &:focus:active {
    background: rgba(77, 77, 77, 0.5);
  }

  &:focus {
    outline: none;
  }

  &:before {
    display: none;
  }
`

export const TopBarName = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 10px;
  @media screen and (max-width: 650px) {
    display: none;
  }
`
export const Profile = styled.div`
  margin-bottom: 0;
  position: relative;
  @media screen and (max-width: 576px) {
    margin-left: 0;
  }
`
export const AvatarName = styled.p`
  font-size: 13px;
  line-height: 18px;
  font-weight: 400;
  display: block;
  margin-left: 10px;
  color: white !important;
`
export const AvatarImage = styled(SmartImage)`
  border-radius: 50%;
  height: 36px;
  width: 36px;
  object-fit: cover;
`
export const TopBarRole = styled.p`
  text-align: left;
  font-size: 12px;
  color: #a9a9a9 !important;
  margin: 0 !important;
  text-transform: capitalize;
`
