import { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'

import { useAppSelector } from 'src/Hooks/redux'
import { getReferredArtists } from 'src/Redux/referral-process/referralList/selectors'
import { Desktop, Mobile } from 'src/Components/Responsive'

import { InviteBlock } from '../InviteBlock'
import { BonusBlocks } from '../BonusBlock'
import { RewardsDetails } from '../RewardsDetails'

import { ReferAndEarnProgress } from './ReferAndEarnProgress/ReferAndEarnProgress'

import {
  PageContentContainer,
  OuterWrapper,
  Wrapper,
  RewardDetailsSubTitle,
  InnerWrapperFirst,
  InnerWrapperSecond,
  InnerWrapperLast,
  ReferAndEarnDetailTitle,
  ReferAndEarnDetailText,
  InviteANDBonusBlocks,
} from './styles'

export const MainInfo: FunctionComponent = () => {
  const { t } = useTranslation()
  const referalArtists = useAppSelector(getReferredArtists)
  return (
    <>
      <BonusBlocks />
      <InviteANDBonusBlocks>
        <InviteBlock />
      </InviteANDBonusBlocks>
      <PageContentContainer>
        <RewardDetailsSubTitle>
          {t('referAndEarn.rewardsDetails')}
        </RewardDetailsSubTitle>

        <OuterWrapper>
          <ReferAndEarnProgress count={referalArtists || 0} />
          <Wrapper>
            <InnerWrapperFirst>
              <ReferAndEarnDetailTitle>
                {t('referAndEarn.13referredArtists')}
              </ReferAndEarnDetailTitle>
              <ReferAndEarnDetailText>
                {t('referAndEarn.10perArtists')}
              </ReferAndEarnDetailText>
            </InnerWrapperFirst>
            <InnerWrapperSecond>
              <ReferAndEarnDetailTitle>
                {t('referAndEarn.49referredArtists')}
              </ReferAndEarnDetailTitle>
              <ReferAndEarnDetailText>
                {t('referAndEarn.20perArtists')}
              </ReferAndEarnDetailText>
            </InnerWrapperSecond>
            <InnerWrapperLast>
              <ReferAndEarnDetailTitle>
                {t('referAndEarn.from10referredArtists')}
              </ReferAndEarnDetailTitle>
              <ReferAndEarnDetailText>
                {t('referAndEarn.50perArtists')}
              </ReferAndEarnDetailText>
            </InnerWrapperLast>
          </Wrapper>
        </OuterWrapper>
        <Desktop>
          <RewardsDetails />
        </Desktop>
      </PageContentContainer>
      <Mobile>
        <RewardsDetails />
      </Mobile>
    </>
  )
}
