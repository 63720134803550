import styled from 'styled-components'

export const ButtonWrapper = styled.div`
  .ant-btn.ant-btn-default {
    width: 176px;
  }
`

export const SubHead = styled.p`
  margin-top: 4px;
  line-height: 21px;
  font-size: 14px;
`
