import { createDraftSafeSelector } from '@reduxjs/toolkit'

import { RootState } from 'src/Redux'
import { SliceStatus } from 'src/Redux/types'

import { ArtistsListState } from '.'

const getState = (state: RootState): ArtistsListState =>
  state.adminPanel.artists

export const getArtistsSelector = createDraftSafeSelector(
  getState,
  ({ artists }) => artists,
)
export const getArtistsCount = createDraftSafeSelector(
  getState,
  ({ totalArtists }) => totalArtists,
)

export const getIsArtistListLoading = createDraftSafeSelector(
  getState,
  ({ status }) => status === SliceStatus.Loading,
)
