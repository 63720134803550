import React from 'react'

import { useTranslation } from 'react-i18next'

import InfinityLoader from '../InfinityLoader'

import { CustomModal, SuccessBlock, Text, TitleWrapper } from './styles'

export const PayPalTransactionProcessingModal: React.FC = () => {
  const { t } = useTranslation()

  return (
    <CustomModal>
      <SuccessBlock>
        <InfinityLoader isLoading={true} />
        <TitleWrapper>
          <span> {t('scheduleCampaignPage.processing')}</span>
        </TitleWrapper>

        <Text>{t('scheduleCampaignPage.PaypalProcessingModalText')}</Text>
      </SuccessBlock>
    </CustomModal>
  )
}
