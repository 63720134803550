import { FC, useState } from 'react'

import { t } from 'i18next'
import styled from 'styled-components'

import WisePayout from 'src/Components/WisePayout'
import { ChangeEmailForm } from 'src/Components/ChangeEmailForm'
import Button from 'src/Components/Buttons/Button'
import { colors } from 'src/Styled/variables'

import ChangePassword from '../ChangePassword'
import { TabKeys } from '../../halpers'
import DeleteAccountModal from '../DeleteAccountModal'

export const DeleteInfoContainer = styled.div`
  margin-top: 10px;
  margin-left: 10px;
`

export const DeleteInfoText = styled.div`
  margin: 0px;
  color: ${colors.mainText};
`
export const ButtonWrapper = styled.div`
  max-width: 300px;
  padding: 20px 0;
`
interface SettingsTabsProps {
  active: string
}

export const SettingsTabs: FC<SettingsTabsProps> = ({ active }) => {
  const [modal, setModal] = useState(false)

  const modalToggle = (): void => {
    setModal(!modal)
  }
  if (active === TabKeys.payout) {
    return <WisePayout />
  }
  if (active === TabKeys.password) {
    return <ChangePassword />
  }
  if (active === TabKeys.email) {
    return <ChangeEmailForm />
  }
  if (active === TabKeys.delete) {
    return (
      <>
        <DeleteInfoContainer>
          <p>{t('settingsPage.deleteInfo')}</p>
          <DeleteInfoText>{t('settingsPage.deleteInfo1')}</DeleteInfoText>
        </DeleteInfoContainer>
        <ButtonWrapper>
          <Button type='red' onClick={modalToggle}>
            {t('settingsPage.delete')}
          </Button>
        </ButtonWrapper>
        <DeleteAccountModal visible={modal} toggleModalVisible={modalToggle} />
      </>
    )
  }
  return null
}
