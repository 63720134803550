import React, { FC } from 'react'

import SpotifyIcon from 'mdi-react/SpotifyIcon'

import { colors } from 'src/Styled/variables'
import { formatDate } from 'src/Constants/formatDate'
import dayJs from 'src/Helpers/dayjs'
import { payment, withdraw } from 'src/Constants/constants'
import { CuratorTransaction } from 'src/Redux/transactions-process/curatorBalanceHistorySlice'

import { StatusCell } from './StatusCell/StatusCell'
import { AmountCell } from './AmountCell/AmountCell'
import { DescriptionCell } from './DescriptionCell/DescriptionCell'

import {
  AmountContainer,
  AmountWrapper,
  Container,
  DateWrapper,
  EmptyDiv,
  StatusWrapper,
  StyledDate,
  Title,
  Wrapper,
} from './styles'

interface MobileBalanceItemProps {
  transaction: CuratorTransaction
}

export const MobileBalanceItem: FC<MobileBalanceItemProps> = ({
  transaction,
}) => {
  const isWithdraw = transaction.type === withdraw
  const isSpotifyIcon = transaction.type === payment || isWithdraw

  const icon = isSpotifyIcon ? (
    <SpotifyIcon color={colors.badgeGreen} size={'16px'} />
  ) : (
    <EmptyDiv />
  )
  return (
    <Wrapper>
      {icon}
      <Container>
        <DescriptionCell
          description={transaction.description}
          type={transaction.type}
          reviewDetails={transaction.reviewDetails}
        />
        <DateWrapper>
          <Title>Date</Title>
          <StyledDate>
            {dayJs(transaction.createdAt).format(
              formatDate.formatDateMonthFull,
            )}
          </StyledDate>
        </DateWrapper>
        <AmountWrapper>
          <StatusWrapper>
            <Title>Status</Title>
            <StatusCell type={transaction.type} />
          </StatusWrapper>
          <AmountContainer>
            <Title>Amount</Title>
            <AmountCell
              type={transaction.type}
              amount={Number(transaction.amount)}
            />
          </AmountContainer>
        </AmountWrapper>
      </Container>
    </Wrapper>
  )
}
