import { APP } from 'src/Configs/App'
import { ANY_LANGUAGE_ID, NO_VOCAL } from 'src/Constants/constants'
import { capitalize } from 'src/Helpers/layoutHelpers'
import { rtkApi } from 'src/Services/rtkApi'
import { GroupedOption } from 'src/Types'

interface LanguageItem {
  id: number
  language_name: string
  playlists_count?: number
}

export interface PlaylistsLanguages {
  popular: LanguageItem[]

  all: LanguageItem[]
}

const languagesApi = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllLanguages: builder.query<LanguageItem[], void | null>({
      query: () => `${APP.SERVER}/user/languages`,
      transformResponse: (response: LanguageItem[]) => {
        const languagesFiltered = response.filter((el) => el.id !== 1)
        const elementsToMove = languagesFiltered.filter(
          (el) => el.id === NO_VOCAL || el.id === ANY_LANGUAGE_ID,
        )
        const elementsRest = languagesFiltered.filter(
          (el) => el.id !== NO_VOCAL && el.id !== ANY_LANGUAGE_ID,
        )
        return [...elementsToMove, ...elementsRest]
      },
    }),
    getLanguagesForExistingPlaylists: builder.query<
      GroupedOption[],
      void | null
    >({
      query: () => `${APP.SERVER}/campaign/languages`,
      transformResponse: (response: PlaylistsLanguages) => [
        {
          label: 'Most popular',
          options: (response?.popular || []).map((item) => ({
            value: item.id,
            label: capitalize(item.language_name || ''),
          })),
        },
        {
          label: 'A-Z',
          options: (response?.all || [])
            .map((item) => ({
              value: item.id,
              label: capitalize(item.language_name || ''),
            }))
            .filter((item) => item.value !== ANY_LANGUAGE_ID),
        },
      ],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetAllLanguagesQuery,
  useGetLanguagesForExistingPlaylistsQuery,
} = languagesApi
