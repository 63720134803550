import styled from 'styled-components'

import { variables, colors } from 'src/Styled/variables'

export const TabButton = styled.button<{ active: boolean }>`
  cursor: pointer;
  overflow: visible;
  background: none;
  border: none;
  font-size: ${variables.fontSize14};
  color: ${colors.mainText};
  transition: 0.5s;
  padding: 10px 20px;
  border-bottom: 3px solid
    ${({ active }) => (active ? colors.badgeGreen : colors.greyStroke)};
`

export const TabButtonCount = styled.span<{ green?: boolean; red?: boolean }>`
  margin-left: 8px;
  font-size: ${variables.fontSize14};
  color: ${({ green, red }) => {
    if (green) {
      return colors.messegesGreen1
    }
    if (red) {
      return colors.messegesRed
    }
    return colors.greyTextBlackBg
  }};
`

export const TabTitles = styled.div`
  display: flex;
  margin-bottom: 20px;

  @media (max-width: 576px) {
    position: relative;
    width: 100vw;
    overflow-x: scroll;

    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`
