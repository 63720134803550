import { FC } from 'react'

import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { subscription } from 'src/Router/routes'
import CloseCross from 'src/Assets/Svg/close-cross.svg?react'
import { FullScreenModal } from 'src/Components/FullScreenModal'

import { Unsubscribed } from '../Unsubscribed/Unsubscribed'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
`

const CloseButton = styled.button`
  width: 40px;
  height: 40px;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 16px;
`

interface SuccessCancelProps {
  date: string
}

export const SuccessCancel: FC<SuccessCancelProps> = ({ date }) => {
  const navigate = useNavigate()
  const onClose = (): void => {
    navigate(subscription)
  }
  return (
    <FullScreenModal open>
      <CloseButton onClick={onClose}>
        <CloseCross width='16px' height='16px' />
      </CloseButton>
      <Container>
        <Unsubscribed date={date} />
      </Container>
    </FullScreenModal>
  )
}
