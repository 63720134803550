import { FC, useMemo } from 'react'

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { filterAndSortGenres } from 'src/Constants/functions'
import { useAppSelector } from 'src/Hooks/redux'
import { SimilarGenre } from 'src/Redux/similarGenres-process'
import {
  GroupedGenre,
  getGroupedGenres,
} from 'src/Redux/similarGenres-process/selectors'
import { GenreListWrapper } from 'src/Components/GenresList/styles'
import { capitalizeFirstLetter } from 'src/Helpers/layoutHelpers'
import { PrimaryGenreOrLanguageItem } from 'src/Components/GenresList/PrimaryGenreOrLanguageItem'

import {
  GenresModalSpotifyGroupedGenres,
  GroupedGenresLabel,
} from './GenresModalSpotifyGroupedGenres'

const List = styled.ul<{ $hasMargin: boolean }>`
  padding: 0;
  ${GroupedGenresLabel}:first-child {
    margin-top: ${({ $hasMargin }) => ($hasMargin ? '16px' : '0')};
  }
`

const Text = styled.div`
  color: ${({ theme }) => theme.common.primaryText};
  text-align: center;
  font-family: 'Segoe UI';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`
const getFiltered = (
  groupedOptions: GroupedGenre[],
  searchValue: string,
): GroupedGenre[] =>
  groupedOptions.map((item) => ({
    ...item,
    options: filterAndSortGenres(item.options, searchValue, 'genreName'),
  }))
interface Props {
  searchValue: string
  selected: SimilarGenre[]
  onClick: (id: number) => void
}

export const GenresModalSpotifyList: FC<Props> = ({
  onClick,
  searchValue,
  selected,
}) => {
  const { t } = useTranslation()
  const groupedOptions = useAppSelector(getGroupedGenres)
  const filteredOptions = useMemo(
    () => getFiltered(groupedOptions, searchValue),
    [groupedOptions, searchValue],
  )
  const hasGenres =
    Boolean(filteredOptions?.[0]?.options?.length) &&
    Boolean(filteredOptions?.[1]?.options?.length)
  return (
    <>
      {Boolean(selected.length) && (
        <GenreListWrapper>
          {selected.map((genre) => (
            <PrimaryGenreOrLanguageItem
              onClick={() => onClick(Number(genre?.id))}
              hasCross
              name={capitalizeFirstLetter(genre.genreName || '')}
              key={genre.id}
            />
          ))}
        </GenreListWrapper>
      )}
      <List $hasMargin={Boolean(selected.length)}>
        {filteredOptions.map((item) => (
          <GenresModalSpotifyGroupedGenres
            key={item.label}
            selected={selected}
            searchValue={searchValue}
            item={item}
            onClick={onClick}
          />
        ))}
      </List>
      {!hasGenres && <Text>{t('campaignsListPage.noDataAvailable')}</Text>}
    </>
  )
}
