import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import {
  clearSubscriptionFromLocation,
  getSubscriptionFromLocation,
} from 'src/Redux/subscription-process/subscriptionFromLocation'

import { Container, Text, SubTitle } from '../../styles'
import { HeaderTitle } from '../HeaderTitle'

import { DescriptionWrapper, List, ListItem, NumberItem } from './styles'

export const InfoHeader: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const fromLocation = useAppSelector(getSubscriptionFromLocation)
  const handleClose = (): void => {
    if (fromLocation) {
      navigate(fromLocation)
      dispatch(clearSubscriptionFromLocation())
      return
    }
    navigate('/')
  }
  return (
    <Container>
      <HeaderTitle onClose={handleClose}>
        {t('getPremiumPage.title')}
      </HeaderTitle>

      <DescriptionWrapper>
        <Text>{t('getPremiumPage.mainDescription')}</Text>
      </DescriptionWrapper>

      <List>
        <ListItem>
          <NumberItem>1</NumberItem>
          <div>
            <SubTitle>{t('getPremiumPage.subtitle1')}</SubTitle>
            <Text>{t('getPremiumPage.description1')}</Text>
          </div>
        </ListItem>
        <ListItem>
          <NumberItem>2</NumberItem>
          <div>
            <SubTitle>{t('getPremiumPage.subtitle2')}</SubTitle>
            <Text>{t('getPremiumPage.description2')}</Text>
          </div>
        </ListItem>
        <ListItem>
          <NumberItem>3</NumberItem>
          <div>
            <SubTitle>{t('getPremiumPage.subtitle3')}</SubTitle>
            <Text>{t('getPremiumPage.description3')}</Text>
          </div>
        </ListItem>
      </List>
    </Container>
  )
}
