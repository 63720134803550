import { FC, useEffect } from 'react'

import { Radio, RadioProps } from 'antd'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { PaymentMethodEnum } from 'src/Constants/enums'
import { setPaymentMethod } from 'src/Redux/payment-process'
import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { getPaymentMethod } from 'src/Redux/payment-process/selectors'
import { colors, device } from 'src/Styled/variables'

import { StripeImages } from './StripeImages'

import {
  ContainerPayment,
  PaymentMethodsListWrapper,
  RadioGroupContainer,
  RadioLabelContainer,
  StyledSpan,
} from './styles'

export const StyledRadioExp = styled(({ className, ...rest }: RadioProps) => (
  <Radio {...rest} rootClassName={className} />
))`
  &.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
    border-color: ${colors.greyTextBlackBg};
    background: ${colors.errorBg};
    &:after {
      background-color: ${colors.green};
      box-shadow: none;
    }
  }
  &.ant-radio-wrapper-disabled {
    opacity: 0.5;
  }
  &.ant-radio-wrapper:hover {
    .ant-radio-inner {
      border-color: ${colors.greyTextBlackBg};
    }
  }

  &.ant-radio-input {
    animation-duration: 0s !important;
  }
  &.ant-radio-inner {
    border-color: ${colors.greyTextBlackBg};
    background: ${colors.errorBg};
    animation-duration: 0s !important;

    &:after {
      background-color: ${colors.green};
    }
  }

  &.ant-radio-input:focus + .ant-radio-inner,
  .ant-radio-input:hover + .ant-radio-inner {
    border-color: ${colors.greyTextBlackBg};
    background: ${colors.errorBg};
    box-shadow: none;

    &:after {
      background-color: ${colors.green};
      box-shadow: none;
    }
  }

  &:hover {
    .ant-radio-checked .ant-radio-inner {
      box-shadow: none;

      &:after {
        background-color: ${colors.green};
        box-shadow: none;
      }
    }
  }

  @media ${device.mobileMax} {
    height: unset;
  }
`

const Title = styled.h3`
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  text-align: left;
  color: ${colors.mainText};
`
interface PaymentMethodsProps {
  isEnoughBalance: boolean
}
export const PaymentMethodsByStripeOnly: FC<PaymentMethodsProps> = ({
  isEnoughBalance,
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const paymentMethod = useAppSelector(getPaymentMethod)

  useEffect(() => {
    if (!isEnoughBalance) {
      dispatch(setPaymentMethod(PaymentMethodEnum.STRIPE))
      localStorage.setItem('currentPaymentMethod', PaymentMethodEnum.STRIPE)
    } else {
      dispatch(setPaymentMethod(PaymentMethodEnum.Credits))
      localStorage.setItem('currentPaymentMethod', PaymentMethodEnum.Credits)
    }
  }, [isEnoughBalance, dispatch])
  return (
    <RadioGroupContainer>
      <Radio.Group value={paymentMethod}>
        <ContainerPayment>
          <Title>Payment method</Title>
          <PaymentMethodsListWrapper>
            {isEnoughBalance && (
              <StyledRadioExp value={PaymentMethodEnum.Credits}>
                <RadioLabelContainer>
                  <StyledSpan>
                    {t('scheduleCampaignPage.creditsFromBalance')}
                  </StyledSpan>
                </RadioLabelContainer>
              </StyledRadioExp>
            )}
            <StyledRadioExp
              disabled={isEnoughBalance}
              value={PaymentMethodEnum.STRIPE}
            >
              <RadioLabelContainer>
                <StyledSpan>
                  {t('scheduleCampaignPage.creditAndDebitCards')}
                </StyledSpan>
                <StripeImages />
              </RadioLabelContainer>
            </StyledRadioExp>
          </PaymentMethodsListWrapper>
        </ContainerPayment>
      </Radio.Group>
    </RadioGroupContainer>
  )
}
