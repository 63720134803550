import React, { FC } from 'react'

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { SkeletonNodeProps } from 'antd/es/skeleton/Node'
import { Skeleton } from 'antd'

import { MAX_MOBILE_WIDTH, variables } from 'src/Styled/variables'
import { CampaignPlatformTypeEnum } from 'src/Types'
import { SpotifyIcon, TikTokIcon } from 'src/Assets/Svg'

import { Preview } from './Components/Preview'

const Container = styled.div`
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;
  flex-basis: calc(50% - 48px);

  @media (max-width: ${MAX_MOBILE_WIDTH}) {
    border: 1px solid ${({ theme }) => theme.common.primaryBorder};
    border-radius: 16px;
    flex-basis: unset;
  }
`

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`

const Header = styled.span`
  color: ${({ theme }) => theme.common.primaryText};
  font-family: 'Segoe UI';
  font-style: normal;
  font-size: ${variables.fontSize18};
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
`

const Description = styled.div`
  color: ${({ theme }) => theme.common.primaryText};
  font-family: 'Segoe UI';
  font-style: normal;
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight400};
  line-height: 150%;
`

const StyledSkeleton = styled(({ className, ...rest }: SkeletonNodeProps) => (
  <Skeleton.Input {...rest} rootClassName={className} active />
))`
  &.ant-skeleton-block {
    width: 120px;
    border-radius: 16px;
  }
  & > .ant-skeleton-input {
    min-width: 120px !important;
    width: 120px !important;
    height: 48px !important;
    border-radius: 16px !important;
    @media screen and (min-width: ${MAX_MOBILE_WIDTH}) {
      width: 240px !important;
    }
  }
`

export interface TrackDetailsProps {
  platform?: CampaignPlatformTypeEnum
  coverUrl?: string
  trackName?: string
  trackAuthorName?: string
  duration?: string
  trackPreviewUrl?: string
  isTrackDataLoading?: boolean
}

export const TrackDetails: FC<TrackDetailsProps> = ({
  platform,
  coverUrl,
  trackName,
  trackAuthorName,
  duration,
  trackPreviewUrl,
  isTrackDataLoading,
}) => {
  const { t } = useTranslation()

  const TrackInfoIcon =
    platform === CampaignPlatformTypeEnum.SPOTIFY ? SpotifyIcon : TikTokIcon

  const trackInfoTitle =
    platform === CampaignPlatformTypeEnum.SPOTIFY
      ? t('createNewCampaignPage.spotifyDetailsTitle')
      : t('createNewCampaignPage.tiktokDetailsTitle')

  if (isTrackDataLoading) {
    return <StyledSkeleton />
  }

  return (
    <Container>
      <TrackInfoIcon />
      <DescriptionWrapper>
        <Header>{trackInfoTitle}</Header>
        <Description>
          {t('createNewCampaignPage.detailsDescription')}
        </Description>
      </DescriptionWrapper>
      <Preview
        coverUrl={coverUrl}
        trackName={trackName}
        trackAuthorName={trackAuthorName}
        duration={duration}
        trackPreviewUrl={trackPreviewUrl}
      />
    </Container>
  )
}
