import { FC } from 'react'

import MenuIcon from 'mdi-react/MenuIcon'
import { useMediaQuery } from 'react-responsive'

import {
  changeSidebarVisibility,
  changeMobileSidebarVisibility,
} from 'src/Redux/sidebar-process'
import { useAppDispatch } from 'src/Hooks/redux'

import { TopBarButtonDesktop } from './styles'

export const TopBarSidebarButton: FC = () => {
  const dispatch = useAppDispatch()
  const isMobile = useMediaQuery({ maxWidth: 767 })

  const handleOnClick = (): void => {
    if (isMobile) {
      dispatch(changeMobileSidebarVisibility())
      return
    }
    dispatch(changeSidebarVisibility())
  }

  return (
    <div>
      <TopBarButtonDesktop type='button' onClick={handleOnClick}>
        <MenuIcon />
      </TopBarButtonDesktop>
    </div>
  )
}
