import { rtkApi } from 'src/Services/rtkApi'

interface SendFeedbackBody {
  userId: number

  userEmail: string

  country: string

  answerSelected: string

  subAnswerSelected: string

  campaign_platform: string

  first_utm_source: string

  first_utm_medium: string

  first_utm_campaign: string

  first_utm_content: string

  first_utm_term: string

  first_landing_page: string

  last_utm_source: string

  last_utm_medium: string

  last_utm_campaign: string

  last_utm_content: string

  last_utm_term: string

  last_landing_page: string
}

const sendFeedbackApi = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    sendFeedback: builder.mutation<void, SendFeedbackBody>({
      query: (data) => ({
        url: 'https://hook.eu1.make.com/9m1k8qy6d3xokoeqcf2dxxvza8th4xbv',
        method: 'POST',
        body: data,
      }),
    }),
  }),
})
export const { useSendFeedbackMutation } = sendFeedbackApi
