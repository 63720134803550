export const FAQItems = [
  {
    id: 1,
    question: 'FAQs.newToTikTok',
    answer: 'FAQs.exploreTikTok',
    answer2: 'FAQs.createTasks',
    answer3: 'FAQs.chooseDate',
    name: 'new_to_tiktok',
  },
  {
    id: 2,
    question: 'FAQs.matchLyrics',
    answer: 'FAQs.notNecessary',
    name: 'creators_match_lyrics',
  },
  {
    id: 3,
    question: 'FAQs.boostAudience',
    answer: 'FAQs.affordablePromotion',
    answer2: 'FAQs.broaderAudience',
    answer3: 'FAQs.diverseContent',
    name: 'why_tiktok_promoting',
  },
  {
    id: 4,
    question: 'FAQs.goodFit',
    answer: 'FAQs.dontWorryGenre',
    name: 'tiktok_good_fit',
  },
  {
    id: 5,
    question: 'FAQs.trackOnTikTok',
    answer: 'FAQs.checkDistributor',
    answer3: ['FAQs.ifYes', 'FAQs.instruction', 'FAQs.toFindYourTrack'],
    answer4: 'FAQs.ifNo',
    name: 'find_track',
  },
]
