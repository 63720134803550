import { FC, useEffect, useMemo, useState } from 'react'

import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import {
  getEventsStatistics,
  getInProgressTikTokInfluencerEvents,
  getNewTikTokInfluencerEvents,
  getSubmittedTikTokInfluencerEvents,
} from 'src/Redux/influencer-process/api-actions'
import {
  getInProgressEvents,
  getInProgressTikTokEventsCount,
  getIsEventsLoading,
  getNewTikTokEvents,
  getNewTikTokEventsCount,
  getSubmittedEvents,
  getSubmittedTikTokEventsCount,
} from 'src/Redux/influencer-process/selectors'
import { getTabs, TabKeys } from 'src/Containers/Tracks/helpers'
import RefreshIcon from 'src/Assets/Svg/refresh.svg?react'
import { useAppDispatch } from 'src/Hooks/redux'
import { setCreatorPageActiveTab } from 'src/Redux/influencer-process'
import { Desktop } from 'src/Components/Responsive'
import { colors } from 'src/Styled/variables'

import { TracksList } from '../TracksList'

import {
  RoundBtn,
  StyledNumber,
  StyledTabs,
  TabName,
  TrackTableContainer,
} from './styles'

export const TracksTable: FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const [currentTubKey, setCurrentTubKey] = useState<TabKeys>(TabKeys.NEW)

  const newTikTokEvents = useSelector(getNewTikTokEvents)
  const inProgressEvents = useSelector(getInProgressEvents)
  const submittedEvents = useSelector(getSubmittedEvents)
  const newEventsCount = useSelector(getNewTikTokEventsCount)
  const inProgressEventsCount = useSelector(getInProgressTikTokEventsCount)
  const submittedEventsCount = useSelector(getSubmittedTikTokEventsCount)
  const isEventsLoading = useSelector(getIsEventsLoading)

  useEffect(() => {
    void dispatch(getEventsStatistics())

    if (currentTubKey === TabKeys.NEW) {
      void dispatch(getNewTikTokInfluencerEvents({ pageNumber: 1 }))
    }
    if (currentTubKey === TabKeys.IN_PROGRESS) {
      void dispatch(getInProgressTikTokInfluencerEvents({ pageNumber: 1 }))
    }
    if (currentTubKey === TabKeys.SUBMITTED) {
      void dispatch(getSubmittedTikTokInfluencerEvents({ pageNumber: 1 }))
    }
  }, [currentTubKey, dispatch])

  const tabs = useMemo(
    () =>
      getTabs({
        inProgressEvents: inProgressEventsCount,
        newEvents: newEventsCount,
        submittedEvents: submittedEventsCount,
      }),
    [inProgressEventsCount, newEventsCount, submittedEventsCount],
  )

  const loadMoreNewTikTokEvents = (): void => {
    if (newTikTokEvents.meta) {
      void dispatch(
        getNewTikTokInfluencerEvents({
          pageNumber: newTikTokEvents.meta.pageNumber + 1,
        }),
      )
    }
  }

  const loadMoreInProgressEvents = (): void => {
    if (inProgressEvents.meta) {
      void dispatch(
        getInProgressTikTokInfluencerEvents({
          pageNumber: inProgressEvents.meta.pageNumber + 1,
        }),
      )
    }
  }

  const loadMoreSubmittedEvents = (): void => {
    if (submittedEvents.meta) {
      void dispatch(
        getSubmittedTikTokInfluencerEvents({
          pageNumber: submittedEvents.meta.pageNumber + 1,
        }),
      )
    }
  }

  return (
    <TrackTableContainer>
      <StyledTabs
        items={tabs.map((tab) => ({
          label: (
            <>
              <TabName>{t(tab.title)}</TabName>
              <StyledNumber>{tab.numbers || 0}</StyledNumber>
            </>
          ),
          key: tab.key,
          children: (
            <>
              {tab.key === TabKeys.NEW && (
                <TracksList
                  loadMore={loadMoreNewTikTokEvents}
                  events={newTikTokEvents}
                  isNoItemsMessageVisible
                  isLoading={isEventsLoading}
                />
              )}

              {tab.key === TabKeys.IN_PROGRESS && (
                <TracksList
                  loadMore={loadMoreInProgressEvents}
                  events={inProgressEvents}
                  isLoading={isEventsLoading}
                />
              )}

              {tab.key === TabKeys.SUBMITTED && (
                <TracksList
                  loadMore={loadMoreSubmittedEvents}
                  events={submittedEvents}
                  isLoading={isEventsLoading}
                />
              )}
            </>
          ),
        }))}
        defaultActiveKey={TabKeys.NEW}
        onChange={(e: string) => {
          dispatch(setCreatorPageActiveTab(e as TabKeys))
          setCurrentTubKey(e as TabKeys)
        }}
      />
      <Desktop>
        <RoundBtn
          onClick={() => {
            void dispatch(getNewTikTokInfluencerEvents({ pageNumber: 1 }))
            void dispatch(
              getInProgressTikTokInfluencerEvents({ pageNumber: 1 }),
            )
            void dispatch(getSubmittedTikTokInfluencerEvents({ pageNumber: 1 }))
          }}
        >
          <RefreshIcon width={16} height={16} color={colors.greyTextBlackBg} />
        </RoundBtn>
      </Desktop>
    </TrackTableContainer>
  )
}
