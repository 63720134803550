import styled, { css } from 'styled-components'
import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form'

import { colors, variables } from 'src/Styled/variables'

const commonStyles = css`
  font-size: ${variables.fontSize12};
  opacity: 1;
  top: 8px;
  left: 16px;
  transition: all 0.2s ease;
  margin: 0;
`

export const Wrapper = styled.div`
  position: relative;
  width: 100%;

  &:focus-within div {
    ${commonStyles}

    .PhoneInputCountry {
      opacity: 100;
    }
  }

  &:has(input:not([value=''])) {
    div {
      ${commonStyles}
    }
  }
`
export const Label = styled.div<{
  disabled?: boolean
}>`
  font-family: 'Segoe UI';
  color: ${({ theme, disabled }) =>
    disabled ? theme.common.thirdText : theme.common.primaryPlaceholder};
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight400};
  line-height: 150%;
  transition: all 0.2s ease;
  position: absolute;
  top: 17px;
  left: 17px;
  z-index: 2;
  pointer-events: none;
`

export const Message = styled.div<{
  $isError?: boolean
}>`
  font-family: 'Segoe UI';
  color: ${({ theme, $isError }) =>
    $isError ? theme.common.dangerText : theme.common.primaryText};
  font-size: 14px !important;
  font-weight: ${variables.fontWeight400};
  line-height: 150%;
  margin-top: 4px !important;
`

export const StyledInput = styled(PhoneInputWithCountry)<{
  $isError?: boolean
  disabled?: boolean
}>`
  :root {
    --PhoneInputCountryFlag-aspectRatio: 1.333;
    --PhoneInput-color--focus: transparent;
  }
  .PhoneInputInput--focus {
    color: transparent;
    outline: none;
    -webkit-appearance: none;
  }
  .PhoneInputCountrySelectArrow {
    display: none;
  }
  .PhoneInputCountry {
    opacity: 0;
  }

  &:focus-within .PhoneInputCountry {
    display: block;
  }

  &:has(input:not([value=''])) .PhoneInputCountry {
    display: block;
  }
  .PhoneInputCountryIcon--border {
    background-color: transparent !important;
    box-shadow: none !important;
  }
  color: ${({ theme }) => theme.common.primaryText} !important;
  display: flex;
  gap: 14px;
  background: ${colors.white};
  height: 56px;
  z-index: 1;
  border: ${({ theme, $isError }) =>
    $isError
      ? `1px solid ${theme.common.dangerBorder}`
      : `1px solid ${theme.common.thirdText}`};
  border-radius: 16px;
  outline: none;
  padding: 23px 16px 16px 0px;
  width: 100%;
  margin-bottom: 8px;
  color: ${({ theme }) => theme.common.primaryPlaceholder};
  transition: border 0.3s;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  & input {
    font-size: 14px;
    font-family: 'Segoe UI';
    line-height: 21px;
    background: ${colors.white};
    border: none;
    outline: none;
    position: relative;
    top: 5px;
    -webkit-appearance: none;
    -moz-appearance: none;
    &:-webkit-autofill,
    -webkit-autofill:active,
    -webkit-autofill:focus,
    -webkit-autofill:hover {
      -webkit-text-fill-color: ${({ theme }) =>
        theme.common.primaryPlaceholder};
      color: ${({ theme }) => theme.common.primaryPlaceholder};
    }
  }

  &:focus {
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    color: ${colors.mainText};
    border: ${({ theme }) =>
      `1px solid ${theme.common.thirdBorder} !important`};
  }
  &:active,
  &:hover,
  &:focus-visible {
    border: ${({ theme }) =>
      `1px solid ${theme.common.thirdBorder} !important`};
  }

  &:-webkit-text-fill-color {
    color: ${({ theme }) => theme.common.primaryPlaceholder};
  }
  &:selected {
    color: ${({ theme }) => theme.common.primaryPlaceholder};
  }
  &:-webkit-autofill,
  -webkit-autofill:active,
  -webkit-autofill:focus,
  -webkit-autofill:hover {
    -webkit-text-fill-color: ${({ theme }) => theme.common.primaryPlaceholder};
    color: ${({ theme }) => theme.common.primaryPlaceholder};
  }

  width: 100%;
  border-radius: 16px;

  ::placeholder {
    color: ${({ theme, disabled }) =>
      disabled ? theme.common.primaryBorder : theme.common.primaryPlaceholder};
    font-family: 'Segoe UI';
    line-height: 150%;
    font-size: ${variables.fontSize14};
    font-weight: ${variables.fontWeight400};
    transition: all 0.2s ease;
  }

  &:focus-visible {
    box-shadow: none;
    &::placeholder {
      opacity: 0;
      transition: all 0.2s ease;
    }
  }

  &.ant-input-affix-wrapper-disabled {
    color: ${({ theme }) => theme.common.thirdText};
    border: ${({ theme }) =>
      `1px solid ${theme.common.primaryBorder}`} !important;
    background-color: ${({ theme }) => theme.common.primaryBg};

    &:hover {
      background-color: ${({ theme }) => theme.common.primaryBg};
    }
  }

  &.ant-input-affix-wrapper-disabled:hover {
    background-color: ${({ theme }) => theme.common.primaryBg} !important;
  }
`
