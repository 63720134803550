import { createDraftSafeSelector } from '@reduxjs/toolkit'

import { RootState } from '..'

import { LevelsState } from '.'

const getState = (state: RootState): LevelsState => state.levels

export const getLevels = createDraftSafeSelector(
  getState,
  ({ levels }) => levels,
)
export const getLevelsLoading = createDraftSafeSelector(
  getState,
  ({ loading }) => loading,
)
