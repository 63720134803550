import styled from 'styled-components'

import { colors, device, variables } from 'src/Styled/variables'

export const CampaignsContainer = styled.div`
  padding: 0;
`

export const Card = styled.div`
  width: 100%;
  font-size: ${variables.fontSize14};
  background-color: transparent;
  flex-direction: column;
  border-radius: 0.25rem;
`

export const CampaignsContainerPageSubHeader = styled.div`
  font-size: ${variables.fontSize18};
  line-height: 27px;
  font-weight: ${variables.fontWeight600};
  color: ${colors.mainText};
  margin-bottom: 22.5px;
  @media ${device.mobileMin} {
    margin-bottom: 30.5px;
  }
`
