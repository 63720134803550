import dayjs from './dayjs'

export const capitalizeFirstLetter = (test: string): string =>
  test && test.charAt(0).toUpperCase() + test.slice(1)

export const lowercaseFirstLetter = (text: string): string =>
  text && text.charAt(0).toLowerCase() + text.slice(1)

export const capitalize = (string: string): string =>
  string ? `${string.split('')[0].toUpperCase()}${string.substring(1)}` : ''

export const snakeCaseTransform = (string: string): string =>
  string ? string.replace('_', ' ') : ''

export const addSpaceToNumber = (
  value: number,
  separator: string,
  noRounding?: boolean,
): string => {
  const DIGITS = 3
  const ROUND = 2

  // Convert the number to a string with the desired number of decimal places.
  const numberString = (value || 0).toFixed(noRounding ? ROUND : 0)
  // Split the string into two parts: before and after the decimal point.
  const [integerPart, decimalPart] = numberString.split('.')
  // Reverse the integer part to make it easier to insert separators.
  const reversedIntegerPart = integerPart.split('').reverse().join('')
  // Insert the separator every three digits.
  let withSeparators = ''
  for (let i = 0; i < reversedIntegerPart.length; i++) {
    if (i > 0 && i % DIGITS === 0) {
      withSeparators += separator
    }
    withSeparators += reversedIntegerPart[i]
  }
  // Reverse the string back to its original order.
  const formattedIntegerPart = withSeparators.split('').reverse().join('')
  // Concatenate it back with the decimal part (if any) using the separator.
  return decimalPart
    ? `${formattedIntegerPart}.${decimalPart}`
    : formattedIntegerPart
}

export const cropText = (text: string, length: number): string => {
  if (text.length > length) {
    return `${text.slice(0, length)}...`
  }
  return text
}
export const getValueOrEmptyString = (value?: string): string =>
  value ? value : ''

export const obfuscateEmail = (email: string): string => {
  const [localPart, domain] = email.split('@')

  const obfuscatedLocalPart = `${localPart.charAt(0)}${'*'.repeat(
    localPart.length - 2,
  )}${localPart.charAt(localPart.length - 1)}`

  return `${obfuscatedLocalPart}@${domain}`
}

export const getTimeByDuration = (seconds: number): string => {
  if (!seconds) {
    return '00:00'
  }
  const timeAgo = dayjs().subtract(seconds, 'second')
  const durationSinceTimeAgo = dayjs.duration(dayjs().diff(timeAgo))
  return dayjs.utc(durationSinceTimeAgo.asMilliseconds()).format('mm:ss')
}

const HUNDRED = 100
const THOUSAND = 1000

export const formatNumberWithK = (numberValue: number): string => {
  if (numberValue >= THOUSAND && numberValue % HUNDRED === 0) {
    return (numberValue / THOUSAND).toString() + 'K'
  }
  return numberValue.toString()
}
type params = number | string | null | undefined
export const getValidNumber = (numberParam: params): number =>
  Number(numberParam || 0)
export const isEmpty = <T>(value: T): boolean => {
  const isNull = value === null
  const isObject =
    !isNull && typeof value === 'object' && Object.keys(value).length === 0
  const isEmptyString = typeof value === 'string' && value.trim().length === 0
  return isNull || isObject || isEmptyString
}

export const formatTimer = (totalSeconds: number): string => {
  const SECONDS_IN_HOUR = 3600
  const SECONDS_IN_MIN = 60
  const DIGITS_NUMBER = 2

  const seconds = Number(totalSeconds.toFixed())

  const minutesPassed = Math.floor((seconds % SECONDS_IN_HOUR) / SECONDS_IN_MIN)
    .toString()
    .padStart(DIGITS_NUMBER, '0')

  const secondsPassed = (seconds % SECONDS_IN_MIN)
    .toString()
    .padStart(DIGITS_NUMBER, '0')

  return `${minutesPassed}:${secondsPassed}`
}
