import styled from 'styled-components'

import { colors, device, variables } from 'src/Styled/variables'

export const RatingWrapper = styled.div`
  display: flex;
  width: fit-content;
  margin-bottom: 16px;
  text-align: left;
`

export const Title = styled.div`
  font-size: ${variables.fontSize10};
  color: ${colors.mainText};
  line-height: 15px;
  text-align: left;
`

export const Text = styled.div`
  font-weight: ${variables.fontWeight400};
  font-size: ${variables.fontSize14};
  line-height: 21px;
  color: ${colors.mainText};
  text-align: left;
`

export const StyledInnerButton = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  & > img {
    margin-right: 0.625rem;
    width: 1rem;
    height: 1rem;
  }

  @media ${device.mobileMax} {
    width: 100%;
  }
`
export const StyledButton = styled.div`
  width: fit-content;
  .ant-btn {
    padding: 0 40px;
  }

  /* @media ${device.mobileMax} {
    width: 100%;
  } */
`
export const ReviewWrapper = styled.div`
  margin-top: 16px;
`
