import { createSlice } from '@reduxjs/toolkit'

import { NameSpace } from 'src/Redux/types'

export interface GetPremiumState {
  isAlertClosed: boolean
}
const initialState: GetPremiumState = {
  isAlertClosed: false,
}

export const getPremium = createSlice({
  initialState,
  name: NameSpace.GET_PREMIUM,
  reducers: {
    setIsAlertClosed(state) {
      state.isAlertClosed = true
    },
  },
})

export const { setIsAlertClosed } = getPremium.actions
