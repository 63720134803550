import React, { FC } from 'react'

import styled from 'styled-components'

import { Track } from 'src/Redux/firstTrackApi'
import { CampaignPlatformTypeEnum } from 'src/Types'
import { MAX_MOBILE_WIDTH } from 'src/Styled/variables'

import { ArtistRegisterForm, FormDataProps } from '../ArtistRegisterForm'
import { TrackDetails } from '../TrackDetails'

const Container = styled.div`
  display: flex;
  padding: 24px 40px 24px 24px;
  justify-content: center;
  align-items: center;
  gap: 40px;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid ${(props) => props.theme.common.primaryBorder};
  background-color: ${(props) => props.theme.common.primaryBg};
  box-shadow: 0px 0px 32px 0px rgba(51, 51, 51, 0.08);

  @media (max-width: ${MAX_MOBILE_WIDTH}) {
    display: flex;
    border: none;
    box-shadow: none;
    padding: 0;
    flex-direction: column;
    align-items: center;
    gap: 40px;
  }
`

interface ArtistRegisterContentProps {
  isEmailExist: boolean
  isRegisterLoading?: boolean
  onSubmitRegisterForm: (data: FormDataProps, token: string) => void
  onStartFillingForm: () => void
  platform?: CampaignPlatformTypeEnum
  isTrackDataLoading?: boolean
  trackCoverUrl: Track['trackCoverUrl']
  trackName: Track['trackName']
  artistName: Track['artistName']
  trackDuration: Track['trackDuration']
  trackPreviewUrl: Track['trackPreviewUrl']
}

export const ArtistRegisterContent: FC<ArtistRegisterContentProps> = ({
  onSubmitRegisterForm,
  isRegisterLoading,
  onStartFillingForm,
  isEmailExist,
  platform,
  isTrackDataLoading,
  trackCoverUrl,
  trackName,
  artistName,
  trackDuration,
  trackPreviewUrl,
}) => (
  <Container>
    <ArtistRegisterForm
      onSubmit={onSubmitRegisterForm}
      isEmailExist={isEmailExist}
      onStartFillingForm={onStartFillingForm}
      isRegisterLoading={isRegisterLoading}
    />

    <TrackDetails
      platform={platform}
      coverUrl={trackCoverUrl}
      trackName={trackName}
      trackAuthorName={artistName}
      duration={trackDuration}
      trackPreviewUrl={trackPreviewUrl}
      isTrackDataLoading={isTrackDataLoading}
    />
  </Container>
)
