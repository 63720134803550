import artist1 from 'src/Assets/Png/artist1.png'
import artist2 from 'src/Assets/Png/artist2.png'
import artist3 from 'src/Assets/Png/artist3.png'

export const whatArtistsThinkItems = [
  {
    id: 1,
    avatar: artist1,
    name: 'Alessio Cappello',
    stars: 5,
    text: 'whatArtistsThink.firstComment',
    link: 'https://www.trustpilot.com/reviews/63bc43e32338b6d417b48894',
  },
  {
    id: 2,
    avatar: artist2,
    name: 'Annie Davidson',
    stars: 5,
    text: 'whatArtistsThink.secondComment',
    text2: 'whatArtistsThink.secondComment2',
    link: 'https://www.trustpilot.com/reviews/6442b64d44f903622819a0f5',
  },
  {
    id: 3,
    avatar: artist3,
    name: 'Andy Fox',
    stars: 5,
    text: 'whatArtistsThink.thirdComment',
    text2: 'whatArtistsThink.thirdComment2',
    link: 'https://www.trustpilot.com/reviews/63cc09ee4b01fb3f5488dd23 ',
  },
]
