import styled from 'styled-components'

import { variables, colors, device } from 'src/Styled/variables'

export const TracksTitle = styled.h3`
  margin-bottom: 2px;
  color: ${colors.mainText};
  font-weight: ${variables.fontWeight600};
  font-size: ${variables.fontSize20};
  @media ${device.mobileMin} {
    margin-bottom: 24px;
    margin-top: 8px;
    line-height: 30px;
  }
`

export const TracksContainer = styled.div`
  /* box-sizing: content-box; */
  //max-width: 1120px;
  padding: 0;
  margin: 0 auto;
  // @media ${device.mobileMin} {
  //   padding: 0 24px 0 30px;
  // }
`
