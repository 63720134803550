import React, { FC } from 'react'

import { HUNDRED } from 'src/Constants/numeric'

import { DotsBlock, DotOuter, DotInner, Arrow, Stick } from '../styles'

export const ReferAndEarnProgressTestId = {
  firstDot: 'first-dot',
  secondDot: 'second-dot',
  thirdDot: 'third-dot',
}

interface Props {
  count: number
}
const MIDDLE_COUNT = 4
const LAST_COUNT = 10

export const ReferAndEarnProgress: FC<Props> = ({ count }) => {
  const isStart = count > 0
  const isMiddle = count >= MIDDLE_COUNT
  const isLast = count >= LAST_COUNT
  const firstPercent = (HUNDRED * count) / MIDDLE_COUNT
  const secondPercent =
    (HUNDRED * (count - MIDDLE_COUNT)) / (LAST_COUNT - MIDDLE_COUNT)
  return (
    <DotsBlock>
      <DotOuter $isActive={isStart}>
        <DotInner
          data-testid={ReferAndEarnProgressTestId.firstDot}
          $isActive={isStart}
        />
        <Arrow />
      </DotOuter>
      <Stick $percent={firstPercent} />
      <DotOuter $isActive={isMiddle}>
        <DotInner
          data-testid={ReferAndEarnProgressTestId.secondDot}
          $isActive={isMiddle}
        />
        <Arrow />
      </DotOuter>
      <Stick $percent={secondPercent} />
      <DotOuter $isActive={isLast}>
        <DotInner
          data-testid={ReferAndEarnProgressTestId.thirdDot}
          $isActive={isLast}
        />
        <Arrow />
      </DotOuter>
    </DotsBlock>
  )
}
