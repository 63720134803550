import styled from 'styled-components'

import { variables, colors } from 'src/Styled/variables'

export const StyledDottedBtn = styled.button`
  font-family: 'Segoe UI', 'Roboto', sans-serif !important;
  border: none;
  line-height: 100%;
  background-color: transparent;
  color: ${colors.mainText};
  border-bottom: 1px dashed ${colors.mainText};
  padding-left: 0;
  padding-right: 0;
  cursor: pointer;
  display: block;
  font-size: ${variables.fontSize14};
  padding-bottom: 2.5px;
  width: fit-content;
  transition: border-bottom 0.3s linear;

  &:hover {
    border-bottom: 1px dashed transparent;
    transition: border-bottom 0.3s linear;
  }
`
