import { FC } from 'react'

import { useTranslation } from 'react-i18next'

import party from 'src/Assets/Png/party.png'

import { ReleaseInfoTitle, ReleaseInfoTitleSpan } from '../../styles'

export const CongratulatoryMessage: FC = () => {
  const { t } = useTranslation()

  return (
    <ReleaseInfoTitle>
      {t('campaignsPage.congratsOnYour')}&nbsp;
      <ReleaseInfoTitleSpan>
        {t('campaignsPage.newRelease')}!
      </ReleaseInfoTitleSpan>
      &nbsp;
      <img width='18px' src={party} alt='party' />
    </ReleaseInfoTitle>
  )
}
