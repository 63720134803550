import { createDraftSafeSelector } from '@reduxjs/toolkit'

import { RootState } from '..'

import { getRecentCampaignsCanceled } from './helpers'

import { CanceledAlertsState } from '.'

const getState = (state: RootState): CanceledAlertsState => state.canceledAlerts

export const getCampaignsCanceledAlertsToShow = createDraftSafeSelector(
  getState,
  ({ data, closedCanceledAlerts }) => {
    const arr = getRecentCampaignsCanceled(data)
    return arr.filter(({ id }) => !closedCanceledAlerts.includes(id))
  },
)
