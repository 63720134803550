import React, { FC, useMemo } from 'react'

import dayjs from 'src/Helpers/dayjs'

import { ShowDate } from '../ShowDate/index'

import { CampaignInProgressShowDateProps } from './CampaignInProgressShowDateProps'

import { DateWrapper } from './styles'

export const CampaignInProgressShowDate: FC<
  CampaignInProgressShowDateProps
> = ({ campaign }) => {
  const { endDate, startDate, is_feed } = campaign

  const daysLeft = useMemo(
    () => dayjs(endDate).diff(dayjs(), 'days') + 1 || 0,
    [endDate],
  )

  return (
    <DateWrapper>
      <ShowDate startDate={startDate} daysLeft={daysLeft} isFeed={is_feed} />
    </DateWrapper>
  )
}
