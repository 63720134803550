import React from 'react'

import styled from 'styled-components'

import PlusIcon from 'src/Assets/Svg/roundedPlus.svg?react'

import { IconButton, IconButtonProps } from '../IconButton'

export type FloatCreateCampaignButtonProps = Omit<IconButtonProps, 'children'>

const StyledIconButton = styled(IconButton)`
  background: ${({ theme }) => theme.button.thirdBg};

  svg {
    path {
      fill: ${({ theme }) => theme.button.fourthText};
    }
  }

  &:focus {
    outline: none;
    background: ${({ theme }) => theme.button.primaryBg};

    svg {
      path {
        fill: ${({ theme }) => theme.button.primaryText};
      }
    }
  }

  &:active {
    background: ${({ theme }) => theme.button.fourthBg};

    svg {
      path {
        fill: ${({ theme }) => theme.button.fourthText};
      }
    }
  }

  &:disabled {
    background: ${({ theme }) => theme.button.secondaryBg};
    svg {
      path {
        fill: ${({ theme }) => theme.button.fourthText};
      }
    }
  }
`

export const FloatCreateCampaignButton: React.FC<
  FloatCreateCampaignButtonProps
> = ({ disabled, onClick, className }) => (
  <StyledIconButton disabled={disabled} onClick={onClick} className={className}>
    <PlusIcon />
  </StyledIconButton>
)
