import { useEffect } from 'react'

import { useSelector } from 'react-redux'
import dayjs from 'dayjs'

import { useAppDispatch } from 'src/Hooks/redux'
import {
  getUserInfo,
  usersMe,
} from 'src/Redux/auth-process/userSlice/api-actions'
import {
  getIsAuth,
  getUserRole,
} from 'src/Redux/auth-process/credentialsSlice/selectors'
import { getRecipientBankDetails } from 'src/Redux/wise-process/api-actions'
import { clearUserState } from 'src/Redux/auth-process/userSlice'
import { removeCookiesHandler } from 'src/Constants/functions'
import {
  getRejectReasons,
  getWordsBlacklist,
} from 'src/Redux/reviews-process/api-actions'
import { Roles } from 'src/Constants/enums'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import { getRate } from 'src/Redux/rate-process/api-actions'
import { UsersMeResponse } from 'src/Types'
import { useLogOut } from 'src/Hooks/useLogOut'

export const useInitApiCalls = (): null => {
  const dispatch = useAppDispatch()
  const isAuth = useSelector(getIsAuth)
  const userRole = useSelector(getUserRole)
  const { setSearch } = useCustomSearchParams()
  const { handleLogOut } = useLogOut()

  const onGetUsersMe = (data: UsersMeResponse): UsersMeResponse => {
    const expiredDate = dayjs(data?.metaData?.tiktokRefreshTokenExpiresIn)
    if (dayjs().isAfter(expiredDate)) {
      handleLogOut()
    }
    return data
  }

  useEffect(() => {
    if (isAuth) {
      if (userRole === Roles.curator) {
        // Variants reject track for curator
        void dispatch(getRejectReasons())
        // Words in the blacklist review for curator
        void dispatch(getWordsBlacklist())
      }

      if (userRole === Roles.curator || userRole === Roles.influencer) {
        // Bank info for creator and curator
        void dispatch(getRecipientBankDetails())
      }

      if (userRole === Roles.influencer) {
        // user info for creator
        void dispatch(usersMe())
          .unwrap()
          .then(onGetUsersMe)
          .catch(() => {
            handleLogOut()
          })
        // rate info for creator
        void dispatch(getRate())
      }

      void dispatch(getUserInfo())
        .unwrap()
        .catch(() => {
          handleLogOut()
          dispatch(clearUserState())
          setSearch({})
          removeCookiesHandler()
        })
    }
    // don't add setSearch to dependencies it call rerender
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth, dispatch, userRole])

  return null
}
