import { FC, ReactNode, useState } from 'react'

import { Popover, PopoverProps } from 'antd'
import styled from 'styled-components'

import { FilterButton } from './FilterButton'

export interface FilterProps {
  disabled?: boolean
  count?: number
  renderContent: (onHide: () => void) => ReactNode
}

const StyledPopover = styled((props: PopoverProps) => (
  <Popover {...props} overlayClassName={props.className} />
))`
  .ant-popover-inner {
    padding: 0;
    border-radius: 16px;
    border: 1px solid ${({ theme }) => theme.common.primaryBorder};
    background: ${({ theme }) => theme.common.primaryBg};
    box-shadow: 0px 0px 12px 0px rgba(51, 51, 51, 0.12);
  }
`
export const Filter: FC<FilterProps> = (props) => {
  const { disabled, count, renderContent } = props
  const [open, setOpen] = useState(false)
  const hide = (): void => {
    setOpen(false)
  }

  const handleOpenChange = (newOpen: boolean): void => {
    setOpen(newOpen)
  }
  const content = renderContent(hide)
  return (
    <StyledPopover
      content={content}
      trigger='click'
      placement='bottom'
      arrow={false}
      open={open}
      onOpenChange={handleOpenChange}
    >
      <FilterButton count={count} disabled={disabled} />
    </StyledPopover>
  )
}
