import { FC } from 'react'

import { useTranslation } from 'react-i18next'

import Alert from 'src/Components/Alert'
import { useAppSelector } from 'src/Hooks/redux'
import { getSpotifyCampaignError } from 'src/Redux/campaign-process/spotifyCampaignSchedule-process/selectors'
import { useGetCurrencyInfoQuery } from 'src/Redux/userExchangeRateApi'

export const ScheduleAlerts: FC = () => {
  const { t } = useTranslation()
  const { isError } = useGetCurrencyInfoQuery()
  const isSpotifyCampaignError = useAppSelector(getSpotifyCampaignError)
  if (isError || isSpotifyCampaignError) {
    return (
      <Alert smallGap icon unclosable color='danger'>
        {t('PleaseTryAgainLater')}
      </Alert>
    )
  }
  return null
}
