import { combineReducers } from '@reduxjs/toolkit'

import { artistsListSlice } from './artistsListSlice'
import { curatorListSlice } from './curatorListSlice'
import { searchUsersSlice } from './searchUsersSlice'

export const adminPanelProcess = combineReducers({
  artists: artistsListSlice.reducer,
  curators: curatorListSlice.reducer,
  searchUsers: searchUsersSlice.reducer,
})
