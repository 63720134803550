import { createAsyncThunk } from '@reduxjs/toolkit'
import axios, { AxiosError } from 'axios'

import { APP } from 'src/Configs/App'
import { NameSpace } from 'src/Redux/types'
import { api } from 'src/Services/api'

export const sendEmailToResetPassword = createAsyncThunk<unknown, string>(
  `${NameSpace.EmailPassword}/sendEmailToResetPassword`,
  async (email, { rejectWithValue }) => {
    try {
      const { data } = await api.post<unknown>(
        `${APP.SERVER}/user/reset-password`,
        {
          email,
        },
      )
      return data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      return rejectWithValue({
        message: response?.data.message,
        code: response?.status,
      })
    }
  },
)
export const sendEmailToConfirmAccount = createAsyncThunk<unknown>(
  `${NameSpace.EmailPassword}/sendEmailToConfirmAccount`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await api.get<unknown>(`${APP.SERVER}/user/send-email`)
      return data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      return rejectWithValue({
        message: response?.data.message,
        code: response?.status,
      })
    }
  },
)

export const sendQuickLoginLink = createAsyncThunk<
  void,
  { email: string; utmParameters: Record<string, string> }
>(
  `${NameSpace.EmailPassword}/sendQuickLoginLink`,
  async ({ email, utmParameters }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<void>(
        `${APP.SERVER}/user/quick/${email}`,
        { params: utmParameters },
      )
      return data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      return rejectWithValue({
        message: response?.data.message,
        code: response?.status,
      })
    }
  },
)

interface ResetPasswordBody {
  password: string
  uuid: string
}

export const resetPassword = createAsyncThunk<unknown, ResetPasswordBody>(
  `${NameSpace.EmailPassword}/resetPassword`,
  async ({ password, uuid }, { rejectWithValue }) => {
    try {
      const { data } = await axios.put<unknown>(
        `${APP.SERVER}/user/reset-password`,
        {
          new_password: password,
          uuid,
        },
      )
      return data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      return rejectWithValue({
        message: response?.data.message,
        code: response?.status,
      })
    }
  },
)
