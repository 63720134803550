import { FunctionComponent } from 'react'

import { UserProtectedProps } from './props'

const CuratorProtected: FunctionComponent<UserProtectedProps> = ({
  component: Component,
  email,
  ...rest
}) => <Component user={email} {...rest} />

export default CuratorProtected
