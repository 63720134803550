import { useParams } from 'react-router-dom'

import { CampaignDetails, useGetCampaignDetailsQuery } from '.'

type SpotifyCampaignDetails = {
  campaignDetails: CampaignDetails | undefined
  isLoading: boolean
  isError: boolean
  isSuccess: boolean
}

export const useSpotifyCampaignDetails = (): SpotifyCampaignDetails => {
  const { campaignID } = useParams<{ campaignID: string }>()
  const { data: campaignDetails, ...restData } = useGetCampaignDetailsQuery(
    Number(campaignID),
  )

  return { campaignDetails, ...restData }
}
