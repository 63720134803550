import { FC } from 'react'

import { GenreOrLanguageItem } from 'src/Components/GenresList/GenreItem'
import { Separator } from 'src/Components/GenresList/styles'
import { renderLanguageOrGenreName } from 'src/Components/GenresList/helpers'
import { GroupedOption } from 'src/Types'

import { LanguageListLabel, LanguageListContainer } from '../styles'

import { SelectedLanguage } from '.'

interface Props {
  item: GroupedOption
  searchValue: string
  onClick: (item: SelectedLanguage) => void
}

export const GroupedItem: FC<Props> = ({ item, onClick, searchValue }) => {
  const { label, options } = item
  if (!options.length) {
    return null
  }
  return (
    <>
      <LanguageListLabel>{label}</LanguageListLabel>
      <LanguageListContainer>
        {options.map((language) => (
          <GenreOrLanguageItem
            key={language.value}
            name={renderLanguageOrGenreName(language.label, searchValue)}
            onClick={() => onClick(language)}
          />
        ))}
      </LanguageListContainer>
      <Separator />
    </>
  )
}
