import styled from 'styled-components'

import { colors } from 'src/Styled/variables'

export const Container = styled.div`
  text-align: center;
  color: ${colors.mainText};

  a {
    border-bottom: 1px dashed ${colors.messegesGreen1};
    color: ${colors.messegesGreen1};
  }
`

export const LinkContainer = styled.div`
  margin-top: 17px;
  font-size: 14px;
`
