import React, { FC } from 'react'

import { ScheduleCampaignHeader } from 'src/Components/ScheduleCampaignHeader'
import { useAppSelector } from 'src/Hooks/redux'
import TiktokIcon from 'src/Assets/Svg/tiktok.svg?react'
import { getScheduledTikTokCampaign } from 'src/Redux/schedule-process/tiktokCampaign/selectors'
import { ScheduleSubscriptionAd } from 'src/Components/ScheduleSubscription/ScheduleSubscriptionAd'

import { ModalsContainer } from './ModalsContainer/ModalsContainer'
import { TiktokTrackParams } from './TiktokTrackParametrs/TiktokTrackParametrs'
import { TiktokBudget } from './TiktokBudget'
import { SettingsTiktokButton } from './SettingsTiktokButton/SettingsTiktokButton'
import { ScheduleTiktokAlerts } from './ScheduleTiktokAlerts/ScheduleTiktokAlerts'
import { PayPalProcessingModalTikTok } from './PayPalProcessingModal'

import {
  TiktokSettingsContainer,
  ParamsContainer,
  TiktokSettingsWrapper,
} from './styles'

const NO_DATA = 'No data'

export const TiktokSettings: FC = () => {
  const scheduledCampaign = useAppSelector(getScheduledTikTokCampaign)

  const avatarLink =
    scheduledCampaign.track?.coverUrl || scheduledCampaign.trackCoverUrl

  return (
    <>
      <PayPalProcessingModalTikTok />
      <ModalsContainer />
      <ScheduleTiktokAlerts />
      <TiktokSettingsContainer>
        <ScheduleCampaignHeader
          avatar={avatarLink || NO_DATA}
          author={
            scheduledCampaign.track?.authorName ||
            scheduledCampaign.trackAuthorName ||
            NO_DATA
          }
          title={
            scheduledCampaign.track?.title ||
            scheduledCampaign.trackTitle ||
            NO_DATA
          }
          Icon={TiktokIcon}
        />
        <TiktokSettingsWrapper>
          <ParamsContainer>
            <TiktokTrackParams />
            <ScheduleSubscriptionAd />
          </ParamsContainer>
          <TiktokBudget />
        </TiktokSettingsWrapper>
        <SettingsTiktokButton />
      </TiktokSettingsContainer>
    </>
  )
}
