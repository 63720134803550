import { FC, ReactNode } from 'react'

import styled from 'styled-components'

import { PrimaryHint } from 'src/Components/PrimaryHint'
import { variables } from 'src/Styled/variables'

const StatsContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  row-gap: 4px;
  justify-content: space-between;
  font-family: 'Segoe UI', sans-serif;
  font-size: 14px;
  padding-left: 1px;
  color: ${({ theme }) => theme.common.primaryText};
  background-color: ${({ theme }) => theme.common.primaryBg};
  flex-grow: 1;
`

const Label = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const Value = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.common.primaryText};
  font-family: 'Segoe UI';
  font-size: ${variables.fontSize18};
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
`

const HintContainer = styled.div`
  position: absolute;
  right: 0;
`

export interface StatsSectionProps {
  title: string
  value?: string | number
  hintText?: ReactNode
}

export const StatsSection: FC<StatsSectionProps> = ({
  title,
  value,
  hintText,
}) => (
  <StatsContainer>
    <Label>
      <span>{title}</span>
      {hintText && (
        <HintContainer>
          <PrimaryHint children={hintText} />
        </HintContainer>
      )}
    </Label>
    <Value>{value}</Value>
  </StatsContainer>
)
