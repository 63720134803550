import styled from 'styled-components'

import { colors, device, variables } from 'src/Styled/variables'

export const PageTitleWrapper = styled.h1`
  font-size: 18px;
  line-height: 27px;
  font-weight: 600;
  color: ${colors.mainText};
  margin-top: 0;
  margin-bottom: 22.5px;

  @media ${device.mobileMin} {
    font-size: ${variables.fontSize20};
    line-height: 150%;
  }
`
