import { APP } from 'src/Configs/App'
import { rtkApi } from 'src/Services/rtkApi'

interface BalanceStats {
  currentBalance: number
  totalPaid: number
  totalUsed: number
}

const artistBalanceStatsApi = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    getArtistBalanceStats: builder.query<BalanceStats, void | null>({
      query: () => `${APP.TIKTOK_SERVER}/artist/balance`,
    }),
  }),
})

export const { useGetArtistBalanceStatsQuery } = artistBalanceStatsApi
