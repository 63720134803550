import { FC } from 'react'

import styled from 'styled-components'

import track from 'src/Assets/Png/track.png'
import { SmartImage } from 'src/Components/SmartImage'
import { getTimeByDuration } from 'src/Helpers/layoutHelpers'
import { TrackCoverWrapper } from 'src/Components/TrackAudioPlayer/TrackCoverWrapper'
import { CampaignPlatformTypeEnum } from 'src/Types'

const grayStroke = '#eee'

const WrapperLink = styled.a`
  display: flex;
  width: 100%;
  padding: 12px;
  align-items: center;
  gap: 16px;
  cursor: pointer;
  &:hover {
    background-color: #f9f9f9;
  }
  &:active {
    background: ${grayStroke};
  }
`
const ListItemImage = styled(SmartImage)`
  width: 48px;
  height: 48px;
  border-radius: 4px;
`

const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
`
const Title = styled.div`
  color: #000;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
`

const SubTitle = styled(Title)`
  font-weight: 400;
`

const Time = styled(SubTitle)`
  text-align: right;
`

interface Props {
  image?: string
  trackName?: string
  author?: string
  time?: number
  trackId?: string
  isPlaying: boolean
  loading: boolean
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void
}

export const TracksWidgetListItem: FC<Props> = ({
  trackName,
  author,
  image,
  time,
  trackId,
  isPlaying,
  loading,
  onClick,
}) => (
  <WrapperLink
    target='_parent'
    href={`${window.location.origin}/create/${CampaignPlatformTypeEnum.TIKTOK}/${trackId ?? ''}`}
  >
    <TrackCoverWrapper
      isPlaying={isPlaying}
      loading={loading}
      onClick={onClick}
    >
      <ListItemImage src={image} fallbackSrc={track} alt='tiktok-track-cover' />
    </TrackCoverWrapper>

    <Info>
      <Title>{trackName}</Title>
      <SubTitle>{author}</SubTitle>
    </Info>
    <Time>{getTimeByDuration(time ?? 0)}</Time>
  </WrapperLink>
)
