import styled, { createGlobalStyle } from 'styled-components'

import { colors, device, variables } from 'src/Styled/variables'

interface Props {
  details?: boolean
}

export const StyledHintSignInner = styled.div<Props>`
  display: ${(props: Props) => (props.details ? 'block' : 'flex')};
  align-items: center;
  justify-content: center;
  line-height: 14px;
  align-self: center;
  border: 1px solid ${colors.greyStroke};
  background: ${colors.greyBg};
  color: ${colors.greyTextBlackBg};
  width: 21px;
  height: 20px;
  border-radius: 50%;

  & img {
    width: 7px;
    height: 14px;
  }

  &:hover {
    border: 1px solid ${colors.green1Light};
    cursor: pointer;
  }
`

export const StyledHintText = styled.div`
  font-weight: ${variables.fontWeight400};
  color: ${colors.mainText};
`

export const Wrapper = styled.div`
  padding: 24px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  color: ${colors.mainText};
  font-size: ${variables.fontSize14};
  line-height: 150%;
  font-weight: ${variables.fontWeight400};

  @media ${device.mobileMax} {
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
  }

  @media ${device.smallScreen} {
    padding: 0px;
  }
`
export const WrapperInner = styled.div`
  margin-bottom: 24px;
`

export const CenteredBlock = styled.div`
  margin: 0 auto;
  & > div {
    width: 160px;
  }
`
export const GlobalStyled = createGlobalStyle`
.ant-modal{
  transform-origin: unset;
}
.overlayClassName .ant-popover-content {
  margin-bottom: 19px;
}
.overlayClassName .ant-popover-arrow{
  display: none !important;
 }
 .overlayClassName .ant-popover-inner-content{
    padding: 24px;
  }
  .overlayClassName .ant-popover-inner {
    width: 240px;
    padding: 0;
  }
  .ant-modal-mask {
    background-color: rgba(0, 0, 0, 0.5) ;
  }
  .ant-modal {
    top: 25%;
  }
  
`
