import { useParams } from 'react-router-dom'

import { GetCampaignVideosData, useGetCampaignVideosQuery } from './index'

type TikTokCampaignVideos = {
  campaignVideos: GetCampaignVideosData | undefined
  isLoading: boolean
  isError: boolean
  isSuccess: boolean
}

export const useTiktokCampaignVideos = (): TikTokCampaignVideos => {
  const { campaignID } = useParams<{ campaignID: string }>()

  const { data: campaignVideos, ...restData } = useGetCampaignVideosQuery({
    campaignId: campaignID,
    pageNumber: 1,
    pageSize: 999,
  })

  return { campaignVideos, ...restData }
}
