import React, { FC } from 'react'

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import Alert from 'src/Components/Alert'
import { colors } from 'src/Styled/variables'

export const AlertSubTitle = styled.div``

export const StyledAlert = styled(Alert)`
  margin-bottom: 0 !important;
  width: 100%;
`

export const EarningRangeUpdatedAlert: FC = () => {
  const { t } = useTranslation()

  return (
    <StyledAlert
      textColor={colors.yellow1}
      color={'warning'}
      icon
      unclosable={true}
      noBorder
      noGap
    >
      <AlertSubTitle>{t('soundsPage.updateRangeText')}</AlertSubTitle>
    </StyledAlert>
  )
}
