import styled from 'styled-components'

export const TopBarRight = styled.div`
  position: absolute;
  right: 0;
  display: flex;
  height: 100%;
  margin-right: 0;

  @media screen and (min-width: 376px) {
    margin-right: 15px;
  }
`
export const TopBarLeft = styled.div`
  display: flex;
`
export const TopBarContainer = styled.div`
  width: 100%;
  position: fixed;
  top: 0;
  height: 60px;
  z-index: 301;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  background: black;
`
export const TopBarLogo = styled.span`
  display: flex;
  align-items: center;
  width: 145px;
  height: 32px;
  margin: auto 0;
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: left;
  background-size: contain;

  @media screen and (min-width: 376px) {
    width: 150px;
  }
`
export const TopBarWrapper = styled.div`
  position: relative;
  display: flex;
  height: 60px;
  margin-right: calc(-1 * (100vw - 100%));
`
