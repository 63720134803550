import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import Button from 'src/Components/Buttons/Button'

import {
  FooterContainer,
  TextWrapper,
} from '../../Containers/ScheduleSpotifyWrapper/components/ModalsContainer/styles'

interface Props {
  isDisabled: boolean
  onClick: () => void
}

export const GenresModalFooter: FC<Props> = ({ isDisabled, onClick }) => {
  const { t } = useTranslation()

  const { pathname } = useLocation()
  const isSpotifyPlatform = pathname.split('/').includes('schedule')

  const bestResultGenresText = isSpotifyPlatform
    ? t('scheduleCampaignPage.upTo5Genres')
    : t('scheduleCampaignPage.minGenres')

  return (
    <FooterContainer translate='no'>
      <TextWrapper>
        <div>{t('scheduleCampaignPage.forBestResultChoose')}</div>
        <div>{bestResultGenresText}</div>
      </TextWrapper>
      <Button disabled={isDisabled} onClick={onClick} type='green'>
        {t('scheduleCampaignPage.addGenres')}
      </Button>
    </FooterContainer>
  )
}
