import i18n from 'src/Localization/i18n'

export const greenRules = [
  {
    title: i18n.t('soundsPage.followArtistTaskTitle'),
    description: i18n.t('soundsPage.followArtistTaskDescription'),
  },
  {
    title: i18n.t('soundsPage.videoDurationTitle'),
    description: i18n.t('soundsPage.videoDurationDescription'),
  },
  {
    title: i18n.t('soundsPage.suitableForTikTokTitle'),
    description: i18n.t('soundsPage.suitableForTikTokDescription'),
  },
  {
    title: i18n.t('soundsPage.captureMoodAndLyricsTitle'),
    description: i18n.t('soundsPage.captureMoodAndLyricsDescription'),
  },
]

export const redRules = [
  {
    title: i18n.t('soundsPage.avoidEasygoingApproachTitle'),
    description: i18n.t('soundsPage.avoidEasygoingApproachDescription'),
  },
  {
    title: i18n.t('soundsPage.noAdvertisingAllowedTitle'),
    description: i18n.t('soundsPage.noAdvertisingAllowedDescription'),
  },
  {
    title: i18n.t('soundsPage.avoidReusingContentTitle'),
    description: i18n.t('soundsPage.avoidReusingContentDescription'),
  },
]
