import { createDraftSafeSelector, createSlice } from '@reduxjs/toolkit'

import { NameSpace } from '../types'
import { RootState } from '../index'

export interface PageLayoutState {
  isSidebarCollapsed: boolean
}

const initialState: PageLayoutState = {
  isSidebarCollapsed: false,
}

export const pageLayoutProcess = createSlice({
  initialState,
  name: NameSpace.PageLayout,
  reducers: {
    toggleSidebarVisibility(state) {
      state.isSidebarCollapsed = !state.isSidebarCollapsed
    },
    hideSidebar(state) {
      state.isSidebarCollapsed = true
    },
    showSidebar(state) {
      state.isSidebarCollapsed = false
    },
  },
})

const getState = (state: RootState): PageLayoutState => state.layout

export const getSidebarVisibility = createDraftSafeSelector(
  getState,
  (layout) => layout.isSidebarCollapsed,
)

export const { toggleSidebarVisibility, hideSidebar, showSidebar } =
  pageLayoutProcess.actions
