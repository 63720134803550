import { FC, useState } from 'react'

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { toJpeg } from 'html-to-image'

import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import { MAX_MOBILE_WIDTH, variables } from 'src/Styled/variables'
import { PrimaryButton } from 'src/Components/Buttons/PrimaryButton'
import { FullScreenModal } from 'src/Components/FullScreenModal'
import { artistClickDownloadShareImageEvent } from 'src/Helpers/TagManager'

import { SharePreview } from '../SharePreview'

const Content = styled.div`
  display: flex;
  padding: 64px 0px 80px 0px;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  max-width: 600px;
  margin: 0 auto;

  @media (max-width: ${MAX_MOBILE_WIDTH}) {
    padding-left: 16px;
    padding-right: 16px;
  }
`

const Title = styled.h2`
  font-family: 'Segoe UI';
  margin: 0;
  padding: 0;
  align-self: flex-start;
  font-size: ${variables.fontSize20};
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
  color: ${({ theme }) => theme.common.primaryText};
`

const Text = styled.span`
  font-family: 'Segoe UI';
  align-self: flex-start;
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight400};
  line-height: 150%;
  color: ${({ theme }) => theme.common.primaryText};
`
const Tag = styled.span`
  font-family: 'Segoe UI';
  align-self: flex-start;
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
  color: ${({ theme }) => theme.common.sixthText};
`
interface ShareModalProps {
  userId: number
  campaignId?: number
  trackName?: string
  trackAuthorName?: string
  trackCoverUrl?: string
  userPhotoUrl: string
  firstName: string
  lastName: string
  comment: string
}

export const ShareModal: FC<ShareModalProps> = ({
  userId,
  campaignId,
  trackCoverUrl,
  trackName,
  trackAuthorName,
  userPhotoUrl,
  firstName,
  lastName,
  comment,
}) => {
  const { t } = useTranslation()

  const { search, setSearch } = useCustomSearchParams()

  const [isDownloadButtonLoading, setIsDownloadButtonLoading] = useState(false)

  if (search.get('modal') !== 'share') {
    return null
  }

  const onCloseClick = (): void => {
    setSearch({
      modal: null,
    })
  }

  const onDownloadImage = async (): Promise<void> => {
    const element = document.querySelector<HTMLElement>('#shareImage')
    if (element) {
      setIsDownloadButtonLoading(true)
      toJpeg(element, { quality: 0.7 }).then((dataUrl) => {
        setIsDownloadButtonLoading(false)
        const link = document.createElement('a')
        link.download = 'review.jpeg'
        link.href = dataUrl
        link.click()
      })

      campaignId &&
        artistClickDownloadShareImageEvent({
          userId,
          campaignId,
        })
    }
  }

  return (
    <FullScreenModal onCancel={onCloseClick} open showCloseButton>
      <Content>
        <Title>{t('campaignDetailsPage.preview')}</Title>
        <SharePreview
          trackCoverUrl={trackCoverUrl}
          userPhotoUrl={userPhotoUrl}
          firstName={firstName}
          lastName={lastName}
          trackName={trackName}
          trackAuthorName={trackAuthorName}
          comment={comment}
        />
        <div>
          <Text>{t('campaignDetailsPage.previewMessage')}</Text>
          <Tag> #soundcampaign</Tag>
        </div>
        <PrimaryButton
          onClick={onDownloadImage}
          loading={isDownloadButtonLoading}
        >
          {t('campaignDetailsPage.downloadImage')}
        </PrimaryButton>
      </Content>
    </FullScreenModal>
  )
}
