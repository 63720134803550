import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import PlusIcon from 'src/Assets/Svg/roundedPlus.svg?react'
import { PrimaryButton } from 'src/Components/Buttons/PrimaryButton'
import { ScheduleSteps } from 'src/Components/PrimaryScheduleTabs'
import { ModalType } from 'src/Containers/ScheduleSpotifyWrapper/components/ModalsContainer'
import { useScheduleGenres } from 'src/Containers/ScheduleSpotifyWrapper/components/SpotifySettings/TrackParametrs/useScheduleGenres'
import { capitalizeFirstLetter } from 'src/Helpers/layoutHelpers'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import { useGetBudgetRangeQuery } from 'src/Redux/spotifyBudgetRangeApi'
import { ProperGenreOrLanguage } from 'src/Types'
import { PrimaryDottedButton } from 'src/Components/Buttons/PrimaryDottedButton'

import { MatchingError } from './MatchingError'

const ParametersButton = styled(PrimaryButton)`
  display: flex;
  height: 48px;
  padding: 0px 24px;
  justify-content: center;
  align-items: center;
  gap: 4px;
`
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
`
const SubTitle = styled.div`
  color: ${({ theme }) => theme.common.primaryText};
  font-family: 'Segoe UI';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
`
const GenresList = styled(SubTitle)`
  font-weight: 400;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1 0 0;
`
const ContainerWithError = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: stretch;
`

interface ScheduleGenresProps {
  genres: ProperGenreOrLanguage[]
  hasSelectedLanguages?: boolean
}

export const SpotifyScheduleGenres: FC<ScheduleGenresProps> = ({
  genres,
  hasSelectedLanguages,
}) => {
  const { t } = useTranslation()
  const { setSearch } = useCustomSearchParams()
  const { number: campaignId } = useParams()
  const { isError: budgetRangeError } = useGetBudgetRangeQuery(campaignId, {
    skip: !campaignId,
  })

  const handleClick = (): void => {
    const query = {
      step: ScheduleSteps.SETTINGS,
      modal: ModalType.Genres,
    }
    setSearch(query)
  }

  useScheduleGenres()

  if (!genres.length) {
    return (
      <ParametersButton onClick={handleClick}>
        <PlusIcon width={10} height={10} />
        <span>{t('scheduleCampaignPage.addTrackGenres')}</span>
      </ParametersButton>
    )
  }

  const genresString = genres
    .map((genre) => capitalizeFirstLetter(genre.name || ''))
    .join(', ')

  const showError = hasSelectedLanguages && budgetRangeError
  return (
    <Container>
      <ContainerWithError>
        <Wrapper>
          <SubTitle>{t('scheduleCampaignPage.trackGenres')}</SubTitle>
          <GenresList translate='no'>{genresString}</GenresList>
        </Wrapper>
        <PrimaryDottedButton onClick={handleClick}>
          {t('scheduleCampaignPage.edit')}
        </PrimaryDottedButton>
      </ContainerWithError>
      {showError && <MatchingError />}
    </Container>
  )
}
