import { FC } from 'react'

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { colors } from 'src/Styled/variables'
import dayJs from 'src/Helpers/dayjs'
import { formatDate } from 'src/Constants/formatDate'
import { DotedButton } from 'src/Components/Buttons/DotedButton'
import { subscription } from 'src/Router/routes'
import { StarIcon } from 'src/Assets/Svg'
import { capitalizeFirstLetter } from 'src/Helpers/layoutHelpers'
import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { setSubscriptionFromLocation } from 'src/Redux/subscription-process/subscriptionFromLocation'
import {
  getHasActiveOrCanceledSubscriptionSelector,
  getSubscriptionSelector,
} from 'src/Redux/subscription-process/subscription/selectors'
import { SubscriptionStatus } from 'src/Redux/subscription-process/subscription'
import { getCountryName } from 'src/Redux/auth-process/userSlice/selectors'
import { checkIsVATCountry } from 'src/Hooks/usePayment'
import { FIXED_BY, VAT_PERCENT } from 'src/Constants/constants'

const Container = styled.div`
  min-width: 288px;
  padding: 24px;
  align-self: stretch;
  border-radius: 4px;
  background: ${colors.white};

  @media (max-width: 576px) {
    display: grid;
    grid-template-columns: 16px auto;
    grid-template-rows: repeat(3, auto);
    grid-column-gap: 8px;
    grid-row-gap: 16px;
  }

  @media (min-width: 576px) {
    display: grid;
    grid-template-columns: 16px auto auto auto;
    grid-column-gap: 8px;
    align-items: center;
  }
`
const PriceWrapper = styled.div`
  display: flex;
  width: 100%;
  align-content: center;
  flex-direction: column;
  justify-content: flex-start;
  @media (max-width: 576px) {
    grid-area: 2 / 2 / 3 / 3;
  }

  @media (min-width: 576px) {
    flex-direction: row;
    gap: 8px;
    width: fit-content;/
  }
`
const Text = styled.div`
  color: ${colors.mainText};
  font-size: 14px;
  line-height: 150%;
  text-align: start;
  white-space: nowrap;
  @media (max-width: 576px) {
    grid-area: 1 / 2 / 2 / 3;
  }
`

const Icon = styled(StarIcon)`
  @media (max-width: 576px) {
    grid-area: 1 / 1 / 2 / 3;
  }
`
const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 576px) {
    grid-area: 3 / 1 / 4 / 3;
  }
  @media (min-width: 576px) {
    justify-content: flex-end;
  }
`

export const BalanceSubscriptionInfo: FC = () => {
  const plan = useAppSelector(getSubscriptionSelector)
  const hasActiveOrCanceledSubscription = useAppSelector(
    getHasActiveOrCanceledSubscriptionSelector,
  )
  const country = useAppSelector(getCountryName)
  const isVATCountry = checkIsVATCountry(country)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useAppDispatch()

  if (!plan || !hasActiveOrCanceledSubscription) {
    return null
  }
  const price = plan.package?.paymentAmount || 0

  const preceWithVAT = (price + price * VAT_PERCENT).toFixed(FIXED_BY)
  const pricePerMonth = isVATCountry
    ? `$${preceWithVAT} ${t('getPremiumPage.includesVAT')} / ${t('getPremiumPage.month')}`
    : `$${price} / ${t('getPremiumPage.month')}`
  const nextDate = dayJs(plan.nextPaymentDate).format(formatDate.formatDate1)
  const nextPayment = `${t('getPremiumPage.nextPayment')} ${nextDate}`
  const activeUntil = `${t('getPremiumPage.activeUntil')}  ${nextDate}`

  const handleClick = (): void => {
    dispatch(setSubscriptionFromLocation(location.pathname))
    navigate(subscription)
  }
  const planName = plan.package?.name || ''
  const text = `${capitalizeFirstLetter(planName)} ${t('getPremiumPage.plan')}`

  const MAP_DESCRIPTION: Record<SubscriptionStatus, string> = {
    active: nextPayment,
    canceled: activeUntil,
    ended: '',
  }
  const description = MAP_DESCRIPTION[plan.status] || MAP_DESCRIPTION.ended

  return (
    <Container>
      <Icon width={16} height={16} fill={colors.green} />
      <Text>{text}</Text>
      <PriceWrapper>
        <Text>{pricePerMonth}</Text>
        <Text>{description}</Text>
      </PriceWrapper>
      <ButtonContainer>
        <DotedButton color={colors.mainText} onClick={handleClick}>
          {t('getPremiumPage.manageSubscription')}
        </DotedButton>
      </ButtonContainer>
    </Container>
  )
}
