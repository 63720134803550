export const campaignsPage = 'https://app.soundcamps.com/campaigns'

export const pageInfo = {
  campaignResults: {
    pageTitle: 'Campaign results',
    pagePath: `${campaignsPage}/view`,
  },
  scheduleCampaign: {
    pageTitle: 'Schedule campaign',
    pagePath: `${campaignsPage}/schedule`,
  },
  balance: {
    pageTitle: 'Balance',
    pagePath: 'https://app.soundcamps.com/balance',
  },
  settings: {
    pageTitle: 'Settings',
    pagePath: 'https://app.soundcamps.com/settings',
  },
  playlists: {
    pageTitle: 'Playlists',
    pagePath: 'https://app.soundcamps.com/playlists',
  },
  reviews: {
    pageTitle: 'Reviews',
    pagePath: 'https://app.soundcamps.com/reviews',
  },
  forgotPassword: {
    pageTitle: 'Forgot password',
    pagePath: 'https://app.soundcamps.com/forgot-password',
  },
  resetPassword: {
    pageTitle: 'Reset password',
    pagePath: 'https://app.soundcamps.com/reset-password',
  },
  rank: {
    pageTitle: 'Rank',
    pagePath: 'https://app.soundcamps.com/rank',
  },
  campaigns: {
    pageTitle: 'Campaigns',
    pagePath: campaignsPage,
  },
  createCampaign: {
    pageTitle: 'Create a new campaign',
    pagePath: `${campaignsPage}/new`,
  },
  createCampaignWithoutSignUp: {
    pageTitle: 'Create a new campaign',
    pagePath: 'https://app.soundcamps.com/create',
  },
  createCampaignDetails: {
    pageTitle: 'Artist registration ',
    pagePath: 'https://app.soundcamps.com/create/details',
  },
  Login: {
    pageTitle: 'Login',
    pagePath: 'https://app.soundcamps.com',
  },
  Checkout: {
    pageTitle: 'Checkout',
    pagePath: `${campaignsPage}/schedule/checkout`,
  },
  CheckoutConfirmed: {
    pageTitle: 'Campaign was scheduled',
    pagePath: `${campaignsPage}/schedule/checkout/confirmed`,
  },
  CreatedFirstCampaign: {
    pageTitle: 'Created First Campaign',
    pagePath: campaignsPage,
  },
  signupCreator: {
    pageTitle: 'Signup Creator',
    pagePath: 'https://app.soundcamps.com/signup-creator',
  },
  creator: {
    pageTitle: 'Influencer',
    pagePath: 'https://app.soundcamps.com/influencer',
  },
  rate: {
    pageTitle: 'Rate Page',
    pagePath: 'https://app.soundcamps.com/rate',
  },
}
