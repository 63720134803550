import styled from 'styled-components'

import { colors, variables } from 'src/Styled/variables'

import { ButtonType } from './ButtonProps'

interface StyledComponentsProps {
  type: ButtonType
  disabled?: boolean
}

type ColorSet = Record<string, string>

const greenColorSet = {
  border: colors.green,
  color: colors.white,
  background: colors.green,
  hoverBackground: colors.green,
  activeBackground: colors.green,
  activeColor: colors.white,
  focusBackground: colors.green,
  focusColor: colors.white,
  disabledBorder: colors.greyTextBlackBg,
  disabledBackground: colors.greyTextBlackBg,
  disabledColor: colors.white,
}

const whiteColorSet = {
  border: colors.mainText,
  color: colors.green,
  background: colors.white,
  hoverBackground: colors.white,
  activeBackground: colors.white,
  activeColor: colors.green,
  focusBackground: colors.white,
  focusColor: colors.green,
  disabledBorder: colors.greyTextBlackBg,
  disabledBackground: colors.white,
  disabledColor: colors.greyTextWhiteBg,
}

const whiteColorWithGreenBorderSet = {
  border: colors.green,
  color: colors.green,
  background: colors.white,
  hoverBackground: colors.white,
  activeBackground: colors.white,
  activeColor: colors.green,
  focusBackground: colors.white,
  focusColor: colors.green,
  disabledBorder: colors.greyTextBlackBg,
  disabledBackground: colors.white,
  disabledColor: colors.greyTextWhiteBg,
}

const redColorSet = {
  border: colors.red,
  color: colors.white,
  background: colors.red,
  hoverBackground: colors.red,
  activeBackground: colors.red,
  activeColor: colors.white,
  focusBackground: colors.red,
  focusColor: colors.white,
  disabledBorder: colors.greyTextBlackBg,
  disabledBackground: colors.greyTextBlackBg,
  disabledColor: colors.white,
}

const payPalColorsSet = {
  border: colors.blue,
  color: colors.white,
  background: colors.blue,
  hoverBackground: colors.blue,
  activeBackground: colors.blue,
  activeColor: colors.white,
  focusBackground: colors.blue,
  focusColor: colors.white,
  disabledBorder: colors.blue,
  disabledBackground: colors.blue,
  disabledColor: colors.white,
}

const getColors = (type: ButtonType): ColorSet => {
  switch (type) {
    case 'green':
      return greenColorSet
    case 'whiteWithGreenBorder':
      return whiteColorWithGreenBorderSet
    case 'white':
      return whiteColorSet
    case 'red':
      return redColorSet
    case 'paypal':
      return payPalColorsSet
    default:
      return greenColorSet
  }
}
export const StyledButton = styled.div<StyledComponentsProps>`
  width: 100%;
  .ant-btn.ant-btn-default {
    width: 100%;
    outline: none;
    box-sizing: border-box;
    border: 1px solid ${({ type }) => getColors(type).border};
    border-radius: 27px;

    height: 40px;
    color: ${({ type }) => getColors(type).color};
    cursor: pointer;
    font-size: ${variables.fontSize14};
    background-color: ${({ type }) => getColors(type).background};
    transition: all 0.2s;
    &:hover {
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
      background: ${({ type }) => getColors(type).hoverBackground};
      color: ${({ type }) => getColors(type).color};
      border: 1px solid ${({ type }) => getColors(type).border};
    }
    &:active {
      box-shadow: inset 0 4px 6px rgba(0, 0, 0, 0.1);
      border: 1px solid ${({ type }) => getColors(type).border};
      background: ${({ type }) => getColors(type).activeBackground};
      color: ${({ type }) => getColors(type).activeColor};
    }
    &:focus {
      border: 1px solid ${({ type }) => getColors(type).border};
      background: ${({ type }) => getColors(type).focusBackground};
      color: ${({ type }) => getColors(type).focusColor};
    }

    transition-delay: 0.3s;
  }

  .ant-btn[disabled],
  .ant-btn[disabled]:hover,
  .ant-btn[disabled]:focus,
  .ant-btn[disabled]:active {
    border: 1px solid ${({ type }) => getColors(type).disabledBorder};
    border-radius: 27px;
    background: ${({ type }) => getColors(type).disabledBackground};
    color: ${({ type }) => getColors(type).disabledColor};
    box-shadow: none;
    cursor: not-allowed;
  }
`
export const IconWithContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 8px;
  justify-content: center;

  svg {
    flex-shrink: 0;
  }
`
