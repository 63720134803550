import { FC } from 'react'

import { Separator } from 'src/Components/DropdownMenu/styles'
import { GenreOrLanguageItem } from 'src/Components/GenresList/GenreItem'
import { renderLanguageOrGenreName } from 'src/Components/GenresList/helpers'
import { GroupedGenre } from 'src/Redux/similarGenres-process/selectors'
import { SimilarGenre } from 'src/Redux/similarGenres-process'

import { LanguageListLabel, LanguageListContainer } from '../../../styles'

interface Props {
  item: GroupedGenre
  selected: SimilarGenre[]
  searchValue: string
  onClick: (id: number) => void
}

export const GroupedGenres: FC<Props> = ({
  item,
  searchValue,
  onClick,
  selected,
}) => {
  const { label, options } = item
  if (!options.length) {
    return null
  }
  return (
    <>
      <LanguageListLabel>{label}</LanguageListLabel>
      <LanguageListContainer>
        {options.map((genre) => (
          <GenreOrLanguageItem
            key={genre.id}
            selected={selected.some(
              (selectedGenre) => selectedGenre.id === genre.id,
            )}
            name={renderLanguageOrGenreName(genre.genreName, searchValue)}
            onClick={() => onClick(genre.id)}
          />
        ))}
      </LanguageListContainer>
      <Separator />
    </>
  )
}
