import { APP } from 'src/Configs/App'
import { rtkApi } from 'src/Services/rtkApi'
import { SpotifyCampaign } from 'src/Types'

interface CreateRelaunchCampaignBody {
  campaignId: number
  url: string
}

const relaunchCampaignApi = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    relaunchCampaign: builder.mutation<
      SpotifyCampaign,
      CreateRelaunchCampaignBody
    >({
      query: (body) => ({
        url: `${APP.SERVER}/campaign/relaunch`,
        method: 'POST',
        body: {
          campaign_id: body.campaignId,
          url: body.url,
        },
      }),
    }),
  }),
  overrideExisting: false,
})

export const { useRelaunchCampaignMutation } = relaunchCampaignApi
