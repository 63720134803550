import { createDraftSafeSelector } from '@reduxjs/toolkit'

import { SpotifyTokenState } from 'src/Redux/spotifyToken-process'

import { RootState } from '..'

const getState = (state: RootState): SpotifyTokenState => state.spotifyToken

export const getSpotifyToken = createDraftSafeSelector(
  getState,
  ({ accessToken }) => accessToken,
)
