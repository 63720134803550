import styled from 'styled-components'

import { colors, device } from 'src/Styled/variables'

import { BadgeType } from './index'

interface StyledComponentsProps {
  type: BadgeType
}

const getTextColor = (type: BadgeType): string => {
  switch (type) {
    case 'regular':
      return colors.green
    case 'danger':
      return colors.messegesRed
    case 'disabled':
      return colors.mainText
    case 'warning':
      return '#F19236'
    default:
      return colors.mainText
  }
}

const getBgColor = (type: BadgeType): string => {
  switch (type) {
    case 'regular':
      return colors.green1Light
    case 'danger':
      return colors.rejectedRed
    case 'disabled':
      return '#E0E0E0'
    case 'warning':
      return '#FCE9D7'
    default:
      return '#E0E0E0'
  }
}

export const PayoutCardWrapper = styled.div<StyledComponentsProps>`
  height: 17px;
  border-radius: 4px;
  background-color: ${({ type }) => getBgColor(type)};
  padding: 0px 4px 2px 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  @media ${device.smallScreen} {
    padding: 2px 4px;
  }
`

export const Title = styled.span<StyledComponentsProps>`
  font-size: 10px;
  font-weight: 600;
  line-height: 150%;
  color: ${({ type }) => getTextColor(type)};
`
