import styled from 'styled-components'

import { variables } from 'src/Styled/variables'

export const AccountValidationTitle = styled.div`
  font-style: normal;
  font-weight: ${variables.fontWeight600};
  font-size: ${variables.fontSize18};
  margin-bottom: 17px;
  z-index: 100;
`

export const AccountValidationTextBlock = styled.div`
  margin-bottom: 17px;
  padding: 16px;
`

export const AccountValidationText = styled.div`
  font-weight: normal;
  font-size: ${variables.fontSize14};
  line-height: 150%;
`
