import { FC } from 'react'

import { useTranslation } from 'react-i18next'

import { Message, Text } from '../../styles'

export const HaveNotNotifications: FC = () => {
  const { t } = useTranslation()
  return (
    <Message>
      <Text>{t('notification.empty')}</Text>
    </Message>
  )
}
