import { FC } from 'react'

import { useTranslation } from 'react-i18next'

import Button from 'src/Components/Buttons/Button'
import { DottedBtn } from 'src/Components/DottedBtn/DottedBtn'
import { PlanStatusEnum } from 'src/Containers/Subscriptions/helpers'

import { ButtonWrapper } from '../styles'

interface Props {
  onClick: () => void
  status: PlanStatusEnum
}

export const PlanButton: FC<Props> = ({ onClick, status }) => {
  const { t } = useTranslation()

  if (status === PlanStatusEnum.UPGRADE) {
    return (
      <ButtonWrapper>
        <Button type={'whiteWithGreenBorder'} onClick={onClick}>
          {t('getPremiumPage.upgradePlan')}
        </Button>
      </ButtonWrapper>
    )
  }
  if (status === PlanStatusEnum.DEACTIVATED) {
    return (
      <ButtonWrapper>
        <DottedBtn
          content={t('getPremiumPage.activateSubscription')}
          onClick={onClick}
        ></DottedBtn>
      </ButtonWrapper>
    )
  }
  if (status === PlanStatusEnum.DOWNGRADE) {
    return (
      <ButtonWrapper>
        <DottedBtn
          content={t('getPremiumPage.downgrade')}
          onClick={onClick}
        ></DottedBtn>
      </ButtonWrapper>
    )
  }
  if (status === PlanStatusEnum.ACTIVE) {
    return (
      <ButtonWrapper>
        <DottedBtn
          content={t('getPremiumPage.cancelSubscription')}
          onClick={onClick}
        ></DottedBtn>
      </ButtonWrapper>
    )
  }
  return (
    <ButtonWrapper>
      <Button type={'whiteWithGreenBorder'} onClick={onClick}>
        {t('getPremiumPage.subscribe')}
      </Button>
    </ButtonWrapper>
  )
}
