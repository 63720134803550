import styled from 'styled-components'

import { variables, colors, device } from 'src/Styled/variables'

export const PageTitleContainer = styled.div`
  line-height: 150%;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  // TODO delete margin-top after removing testing mode alert
  margin-top: 18px;
  @media ${device.mobileMin} {
    margin-bottom: 24px;
  }
`

export const PageTitle = styled.div`
  font-size: ${variables.fontSize20};
  font-weight: ${variables.fontWeight600};
  line-height: 30px;
  color: ${colors.mainText};
`

export const BackButton = styled.div`
  padding-right: 22px;
  padding-left: 14px;
  @media ${device.mobileMin} {
    padding-right: 4px;
    display: flex;
    align-items: center;
    width: 30px;
    height: 12px;
    cursor: pointer;
    & img {
      width: 12px;
      height: 10px;
    }
  }
`
