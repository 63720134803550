import React, { FC } from 'react'

import { useTranslation } from 'react-i18next'

import { APP } from 'src/Configs/App'

import { ShowMoreButton } from '../ShowMoreButton/ShowMoreButton'

import { MorePaymentTitleWrapper, StepTitle } from './styles'

interface MorePaymentButtonProps {
  isMorePaymentMethodsShowed: boolean
  onMorePaymentClick: () => void
  showpayPalTransactionEmail: boolean
}

export const MorePaymentButton: FC<MorePaymentButtonProps> = ({
  isMorePaymentMethodsShowed,
  onMorePaymentClick,
  showpayPalTransactionEmail,
}) => {
  const { t } = useTranslation()

  const isPayPalEnabled =
    APP.IS_PAYPAL_TRANSACTIONS_ENABLED && showpayPalTransactionEmail
  return (
    <MorePaymentTitleWrapper>
      <StepTitle>
        {isPayPalEnabled
          ? t('scheduleCampaignPage.morePaymentMethods')
          : t('scheduleCampaignPage.selectPaymentMethod')}
      </StepTitle>

      {isPayPalEnabled && (
        <ShowMoreButton
          isOpened={isMorePaymentMethodsShowed}
          openedTitle={t('scheduleCampaignPage.hide')}
          closedTitle={t('scheduleCampaignPage.show')}
          toggle={onMorePaymentClick}
        />
      )}
    </MorePaymentTitleWrapper>
  )
}
