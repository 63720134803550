import styled from 'styled-components'

import { variables, colors } from 'src/Styled/variables'

export const DetailsButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 9.5px 16px;
  font-size: ${variables.fontSize14};
  color: ${colors.greyTextWhiteBg};
  border: 1px solid ${colors.greyStroke};
  border-radius: 100px;
  cursor: pointer;
  width: fit-content;
  gap: 10px;
`

export const ChevronIconWrapper = styled.div<{ isDetailsOpened?: boolean }>`
  width: 12px;
  height: 12px;
  transform: ${({ isDetailsOpened }) =>
    isDetailsOpened ? 'rotate(180deg)' : null};
  transition: 0.15s all ease-in-out;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 3px;
`
