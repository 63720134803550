import styled from 'styled-components'

import { colors } from 'src/Styled/variables'

export const StyledSearch = styled.div`
  position: relative;

  input {
    border-radius: 20px;
    border: 1px solid ${colors.greyStroke};
    background: ${colors.greyStroke};
    padding: 15px 35px 15px 15px;
    height: 40px;
    color: ${colors.greyTextWhiteBg};
    &:focus {
      background-color: ${colors.white};
      outline: none;
      color: ${colors.mainText};
      border: 1px solid ${colors.greyStroke};
      box-shadow: none;
    }
    &:hover {
      background-color: ${colors.white};
      outline: none;
      color: ${colors.mainText};
      border: 1px solid ${colors.greyStroke};
      box-shadow: none;
    }

    &:active {
      background-color: ${colors.white};
      border: 1px solid ${colors.greyStroke};
      outline: none;
      color: ${colors.mainText};
    }
  }

  .ant-input:focus {
    border-color: ${colors.greyStroke};
  }
`
export const IconWrapper = styled.div`
  position: absolute;
  z-index: 2;
  right: 16px;
  top: 11px;
`
