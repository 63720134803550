import { APP } from 'src/Configs/App'
import { getCookieByName } from 'src/Constants/functions'

const RANDOM_MAX = 999900
const RANDOM_MIN = 100000
const SUBSTRING_GA = 6

const generateClientId = (): string => {
  const array = new Uint32Array(1)
  window.crypto.getRandomValues(array)
  const randomNumber = (array[0] % (RANDOM_MAX - RANDOM_MIN + 1)) + RANDOM_MIN
  const existingClientId = getCookieByName('_ga')

  if (existingClientId && existingClientId.startsWith('GA')) {
    return existingClientId.substring(SUBSTRING_GA)
  }
  return `${randomNumber}111.${Date.now()}`
}

export const clientId = generateClientId()
export const gaSession = getCookieByName(`${APP.GA_CONTAINER}`) || ''
