import { FC, useState } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { APP } from 'src/Configs/App'
import { useAppSelector } from 'src/Hooks/redux'
import { InfluencerRegisterEnum } from 'src/Types'
import TikTokIcon from 'src/Assets/Svg/tiktok.svg?react'
import { colors, variables } from 'src/Styled/variables'
import { getApplicantUpdateEmailError } from 'src/Redux/creatorRegister-process/selectors'

import { translateInfluencer } from '../..'
import { TikTokBtnLink } from '../../styles'

const Text2 = styled.div`
  font-size: ${variables.fontSize14};
  color: ${colors.mainText};
  line-height: 21px;
  display: grid;
  gap: 16px;
  margin-bottom: 24px;
  @media (min-width: 576px) {
    margin-bottom: 16px;
  }
`

interface LoginCreatorConnectProps {
  status: InfluencerRegisterEnum
}

export const LoginCreatorConnect: FC<LoginCreatorConnectProps> = ({
  status,
}) => {
  const { t } = useTranslation()
  const [isConnecting, setIsConnecting] = useState(false)
  const authError = useAppSelector(getApplicantUpdateEmailError)
  if (status !== InfluencerRegisterEnum.CONNECT) {
    return null
  }
  return (
    <>
      {!authError && <Text2>{t(translateInfluencer)}</Text2>}

      <TikTokBtnLink
        isConnecting={isConnecting}
        href={APP.TIKTOK_AUTH_API}
        onClick={() => setIsConnecting(true)}
      >
        <TikTokIcon width={'12.9px'} height={'14.55px'} />

        {isConnecting
          ? t('influencer.connecting')
          : t('influencer.connectWithTikTok')}
      </TikTokBtnLink>
    </>
  )
}
