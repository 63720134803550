import { createAsyncThunk } from '@reduxjs/toolkit'
import axios, { AxiosError } from 'axios'

import { ErrorCode, NameSpace } from 'src/Redux/types'

interface CountryResponse {
  country: string
  country_code: string
  country_code3: string
  ip: string
}

export const getUserCountry = createAsyncThunk<string>(
  `${NameSpace.COUNTRY}/getUserCountry`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<CountryResponse>(
        'https://get.geojs.io/v1/ip/geo.json',
      )
      return data.country_code
    } catch (error) {
      const { response } = error as AxiosError
      return rejectWithValue({
        message: response?.statusText || 'Error',
        code: response?.status || ErrorCode.InternalServerError,
      })
    }
  },
)
