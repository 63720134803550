import styled from 'styled-components'

import { colors, variables } from 'src/Styled/variables'

export const OtherNotificationWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 80px;
`

export const Message = styled.div`
  line-height: 150%;
  font-size: ${variables.fontSize12};
`

export const StyledDate = styled.div`
  font-size: ${variables.fontSize10};
  right: 16px;
  color: #787985;
  margin: 0px;
  line-height: 150%;
`

export const Accent = styled.span`
  color: ${colors.badgeGreen};
`

export const Name = styled.span`
  margin: 0;
  font-size: ${variables.fontSize10};
  line-height: 150%;
  color: ${colors.badgeGreen};
  line-height: 150%;
`

export const MessageHeader = styled.span`
  margin: 0;
  font-size: ${variables.fontSize12};
  line-height: 21px;
  font-weight: bold;
  display: block;
  a {
    color: ${colors.badgeGreen};
  }
`
