import { FunctionComponent } from 'react'

import Sidebar from './Sidebar'
import TopBar from './Topbar/index'

const Layout: FunctionComponent = () => (
  <div>
    <TopBar />
    <Sidebar />
  </div>
)

export default Layout
