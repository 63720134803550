import { useEffect, useMemo, useState } from 'react'

import { useSelector } from 'react-redux'

import { useAppDispatch } from 'src/Hooks/redux'
import {
  getArtistNotifications,
  markArtistNotificationsAsRead,
  markCuratorNotificationsAsRead,
} from 'src/Redux/notification-process/api-actions'
import { getNotifications } from 'src/Redux/notification-process/selectors'
import { getIsArtist } from 'src/Redux/auth-process/credentialsSlice/selectors'
import { getUserId } from 'src/Redux/auth-process/userSlice/selectors'
import {
  NewReleaseResponse,
  useGetNewReleaseQuery,
} from 'src/Redux/newReleaseApi'
import { Roles } from 'src/Constants/enums'
import { notificationClickEvent } from 'src/Helpers/TagManager'
import { UserNotification } from 'src/Types'
import { useCreateAndStartScheduleNewRelease } from 'src/Hooks/useCreateAndStartScheduleNewRelease'

export const useArtistNotifications = (): {
  sortedNotifications: UserNotification[]
  hasNewNotifications: boolean
  isHeaderNotificationsPopupVisible: boolean
  onNotificationsButtonClick: () => void
  releaseData: NewReleaseResponse | undefined
  handleCreateAndStartSchedule: () => void
} => {
  const dispatch = useAppDispatch()
  const userId = useSelector(getUserId)
  const notifications = useSelector(getNotifications)
  const isArtist = useSelector(getIsArtist)

  useEffect(() => {
    void dispatch(getArtistNotifications())
  }, [dispatch])

  const { data: releaseData } = useGetNewReleaseQuery(null, {
    refetchOnMountOrArgChange: true,
    skip: !isArtist,
  })

  const [
    isHeaderNotificationsPopupVisible,
    setHeaderNotificationsPopupVisible,
  ] = useState(false)

  const hasNewNotifications = Boolean(
    notifications.some(({ isNew }) => isNew) || releaseData,
  )

  const { createAndStartSchedule } = useCreateAndStartScheduleNewRelease()

  const handleCreateAndStartSchedule = (): void => {
    createAndStartSchedule(true)
    setHeaderNotificationsPopupVisible(false)
  }

  const sortedNotifications = useMemo(() => {
    const releaseNotification = releaseData
      ? [
          {
            id: 'new-release',
            date: releaseData.releaseDate,
            isRelease: true,
            trackName: releaseData.trackName,
            artistName: releaseData.artistName,
          },
        ]
      : []

    return [...notifications, ...releaseNotification].sort(
      (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime(),
    )
  }, [notifications, releaseData])

  const onNotificationsButtonClick = (): void => {
    if (!isHeaderNotificationsPopupVisible) {
      notificationClickEvent({
        userId,
        accountType: isArtist ? Roles.artist : Roles.curator,
      })
    }

    setHeaderNotificationsPopupVisible(!isHeaderNotificationsPopupVisible)

    if (isHeaderNotificationsPopupVisible && hasNewNotifications) {
      if (isArtist) {
        dispatch(markArtistNotificationsAsRead())
      } else {
        dispatch(markCuratorNotificationsAsRead())
      }
    }
  }

  return {
    sortedNotifications,
    hasNewNotifications,
    isHeaderNotificationsPopupVisible,
    onNotificationsButtonClick,
    releaseData,
    handleCreateAndStartSchedule,
  }
}
