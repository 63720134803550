import { useEffect } from 'react'

import { getDefaultStartDate } from 'src/Constants/functions'
import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { getScheduledTikTokCampaign } from 'src/Redux/schedule-process/tiktokCampaign/selectors'

import { useChangeStartDate } from './useChangeStartDate'

export const useDefaultTikTokCampaignStartDate = (): void => {
  const dispatch = useAppDispatch()
  const { handleChangeDate } = useChangeStartDate()

  const scheduledTikTokCampaign = useAppSelector(getScheduledTikTokCampaign)

  useEffect(() => {
    if (!scheduledTikTokCampaign.startDate) {
      const startDate = getDefaultStartDate()
      handleChangeDate(startDate)
    }
  }, [dispatch, scheduledTikTokCampaign.startDate, handleChangeDate])
}
