import { Review } from 'src/Types/index'

export enum Tabs {
  all = 'all',
  added = 'added',
  reviewed = 'reviewed',
  missed = 'missed',
}

export interface CampaignResultsTabsProps {
  setFilteredReviews: (reviews: Array<Review>) => void
}
