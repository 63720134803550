import { configureStore } from '@reduxjs/toolkit'
import { persistStore, persistReducer, PersistConfig } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { rtkApi } from 'src/Services/rtkApi'

import rootReducer from './root-reducer'
import { redirect } from './middlewares/redirect'
import { sendTikTokTrackEvents } from './middlewares/sendTikTokTrackEvents'
import { sendSpotifyTrackEvents } from './middlewares/sendSpotifyTrackEvents'
import { enterTrackMiddleware } from './middlewares/enterTrackEvents'
import { sentToReviewMiddleware } from './middlewares/sendToReviewEvent'
import { paymentTransform } from './payment-process/paymentTransform'
import { firstTrackApi } from './firstTrackApi'
import { spotifyApi } from './spotifyTracksApi'

const persistConfig: PersistConfig<ReturnType<typeof rootReducer>> = {
  storage,
  key: 'root',
  whitelist: [
    'sessionNotifications',
    'influencer',
    'alerts',
    'sourcebuster',
    'canceledAlerts',
    'getPremium',
    'sidebar',
    'typeform',
    'payment',
  ],
  transforms: [paymentTransform],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const setupStore = (
  preloadedState: Parameters<typeof rootReducer>[0] = {},
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
) => {
  const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }).concat(
        rtkApi.middleware,
        firstTrackApi.middleware,
        spotifyApi.middleware,
        redirect,
        sendTikTokTrackEvents,
        sendSpotifyTrackEvents,
        enterTrackMiddleware,
        sentToReviewMiddleware,
      ),
    preloadedState,
  })

  const persistor = persistStore(store)

  return { store, persistor }
}

export type RootState = ReturnType<
  ReturnType<typeof setupStore>['store']['getState']
>
export type AppStore = ReturnType<typeof setupStore>
