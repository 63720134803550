import { useCallback, useEffect, useRef } from 'react'

import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { paymentCancelledEvent } from 'src/Helpers/TagManager'
import { getUserSelector } from 'src/Redux/auth-process/userSlice/selectors'
import { getPaymentMethod } from 'src/Redux/payment-process/selectors'
import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { getCampaignTikTokCampaignToScheduleDetails } from 'src/Redux/schedule-process/tiktokCampaign/api-actions'
import { getTiktokPrice } from 'src/Redux/schedule-process/audienceTiktokSlice/selectors'
import { getAllCampaignsCount, useMetadataQuery } from 'src/Redux/metadataApi'
import { CampaignPlatformTypeEnum } from 'src/Types'
import { usePaymentMethod } from 'src/Helpers/usePaymentMethod'

import { useResetQueryParams } from '../Helpers/useResetQueryParams'

import { useCustomSearchParams } from './useCustomSearchParams'
interface PaymentCancelledEvents {
  handlePaymentCancelledTikTokEvent: (params: { platformName: string }) => void
}

export const usePaymentCancelledTikTokEvents = (): PaymentCancelledEvents => {
  const dispatch = useAppDispatch()
  const param = useParams()
  const { data } = useMetadataQuery()
  const campaignId = Number(param.number) || 0
  const user = useSelector(getUserSelector)
  const paymentMethod = useSelector(getPaymentMethod)
  const price = useAppSelector(getTiktokPrice)
  const amountOfCampaigns = getAllCampaignsCount(data)
  const { search } = useCustomSearchParams()
  const paymentCancelled = search.get('payment')
  const priceFromQuery = Number(search.get('price'))
  const isPayPalToken = search.get('token')
  const isPayPalSaved = search.get('isPayPalSaved')

  const initialized = useRef<boolean>(false)

  const resetQueryParams = useResetQueryParams([
    'payment',
    'token',
    'price',
    'isPayPalSaved',
  ])

  const paymentMethodFromFunction = usePaymentMethod(
    isPayPalToken,
    isPayPalSaved,
    paymentMethod,
  )

  const handlePaymentCancelledTikTokEvent = useCallback(
    async (params: { platformName: string }): Promise<void> => {
      await dispatch(getCampaignTikTokCampaignToScheduleDetails(campaignId))
        .unwrap()
        .then((res) => {
          paymentCancelledEvent({
            campaignId,
            paymentMethod: paymentMethodFromFunction,
            genresList: res.genres.map((genre) => genre.name).join(', ') || '',
            languagesList:
              res.languages.map((language) => language.name).join(', ') || '',
            userId: user.id,
            userRole: user.role,
            campaignNumber: amountOfCampaigns,
            platformName: params.platformName,
            campaignType: res.type,
            campaignValue: price ?? priceFromQuery ?? 0,
            paymentAmount: price ?? priceFromQuery ?? 0,
          })

          resetQueryParams()

          return null
        })
    },
    [
      dispatch,
      campaignId,
      user.id,
      user.role,
      amountOfCampaigns,
      price,
      priceFromQuery,
      resetQueryParams,
      paymentMethodFromFunction,
    ],
  )

  useEffect(() => {
    if (paymentCancelled === 'cancelled' && !initialized.current) {
      initialized.current = true

      void handlePaymentCancelledTikTokEvent({
        platformName: CampaignPlatformTypeEnum.TIKTOK,
      })
    }
  }, [handlePaymentCancelledTikTokEvent, paymentCancelled])

  return {
    handlePaymentCancelledTikTokEvent,
  }
}
