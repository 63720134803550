import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { colors, variables } from 'src/Styled/variables'

export const AlertText = styled.div`
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
`

export const DangerLink = styled(Link)`
  background-color: transparent;
  color: ${colors.messegesRed};
  font-weight: ${variables.fontWeight600};
  padding-left: 0;
  &:hover {
    color: ${colors.messegesRed};
  }
`
