import { useEffect, useRef, useState } from 'react'

interface Options {
  root: Element | null
  rootMargin: string
  threshold: number
}

interface UseElementOnScreen {
  containerRef: React.RefObject<HTMLDivElement>
  isVisible: boolean
}

const minIntersectionRatio = 0.98

export const useElementOnScreen = (options: Options): UseElementOnScreen => {
  const containerRef = useRef<HTMLDivElement | null>(null)
  const [isVisible, setIsVisible] = useState(false)

  const callbackFunction: IntersectionObserverCallback = (entries) => {
    const [entry] = entries
    if (
      entry.intersectionRatio === 1 ||
      entry.intersectionRatio < minIntersectionRatio
    ) {
      setIsVisible(!entry.isIntersecting)
    }
  }

  useEffect(() => {
    const currentTitleRef = containerRef.current
    const observer = new IntersectionObserver(callbackFunction, options)
    if (currentTitleRef) {
      observer.observe(currentTitleRef)
    }

    return () => {
      if (currentTitleRef) {
        observer.unobserve(currentTitleRef)
      }
    }
  }, [containerRef, options])

  return { containerRef, isVisible }
}
