import { FC } from 'react'

import { useTranslation } from 'react-i18next'

import {
  MissedReviewContainer,
  MissedReviewDescription,
  MissedReviewRefundContainer,
  MissedReviewRefundLabel,
  MissedReviewRefundAmount,
} from '../styles'

interface MissedReviewProps {
  reviewRefund: number
}

export const MissedReview: FC<MissedReviewProps> = ({ reviewRefund }) => {
  const { t } = useTranslation()
  return (
    <MissedReviewContainer>
      <MissedReviewDescription>
        {t('campaignResultsPage.missedReviewDescription')}
      </MissedReviewDescription>
      <MissedReviewRefundContainer>
        <MissedReviewRefundLabel>
          {`${t('campaignResultsPage.creditsLabel')}: `}
        </MissedReviewRefundLabel>
        <MissedReviewRefundAmount>{`$${reviewRefund}`}</MissedReviewRefundAmount>
      </MissedReviewRefundContainer>
    </MissedReviewContainer>
  )
}
