import { FC } from 'react'

import styled from 'styled-components'

import { SpotifyTrack } from 'src/Redux/spotifyTracksApi'
import { SmartImage } from 'src/Components/SmartImage'
import { getTimeByDuration } from 'src/Helpers/layoutHelpers'
import track from 'src/Assets/Png/track.png'
import { CampaignPlatformTypeEnum } from 'src/Types'
import { TrackCoverWrapper } from 'src/Components/TrackAudioPlayer/TrackCoverWrapper'
const grayStroke = '#eee'

const WrapperLink = styled.a`
  display: flex;
  width: 100%;
  padding: 12px;
  align-items: center;
  gap: 16px;
  cursor: pointer;
  &:hover {
    background-color: #f9f9f9;
  }
  &:active {
    background: ${grayStroke};
  }
`
const ListItemImage = styled(SmartImage)`
  width: 48px;
  height: 48px;
  border-radius: 4px;
`

const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
`
const Title = styled.div`
  color: #000;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
`

const SubTitle = styled(Title)`
  font-weight: 400;
`

const Time = styled(SubTitle)`
  text-align: right;
`

type SpotifyWidgetListItemProps = SpotifyTrack & {
  onClicked?: () => void
  isPlaying: boolean
  loading: boolean
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void
}

export const SpotifyWidgetListItem: FC<SpotifyWidgetListItemProps> = ({
  trackAuthor,
  trackDuration,
  id,
  trackImage,
  trackName,
  previewUrl,
  isPlaying,
  loading,
  onClick,
  onClicked,
}) => {
  const durationInSeconds = (trackDuration || 0) / 1000
  const duration = getTimeByDuration(durationInSeconds)
  return (
    <WrapperLink
      target='_parent'
      onClick={onClicked}
      href={`${window.location.origin}/create/${CampaignPlatformTypeEnum.SPOTIFY}/${id ?? ''}`}
    >
      <TrackCoverWrapper
        show={Boolean(previewUrl)}
        isPlaying={isPlaying}
        loading={loading}
        onClick={onClick}
      >
        <ListItemImage
          src={trackImage}
          fallbackSrc={track}
          alt='spotify-track-cover'
        />
      </TrackCoverWrapper>

      <Info>
        <Title>{trackName}</Title>
        <SubTitle>{trackAuthor}</SubTitle>
      </Info>
      <Time>{duration}</Time>
    </WrapperLink>
  )
}
