import { useAppSelector } from 'src/Hooks/redux'
import { getInfluencerId } from 'src/Redux/auth-process/userSlice/selectors'
import { useInProgressSoundsQuery } from 'src/Redux/creatorInProgressSoundsApi'
import { useNewSoundsQuery } from 'src/Redux/creatorNewSoundsApi'
import { useSubmittedSoundsQuery } from 'src/Redux/creatorSubmittedSoundsApi'

export const useRefreshData = (): [onRefreshClick: () => void] => {
  const influencerId = useAppSelector(getInfluencerId)

  const { refetch: refetchNewSounds } = useNewSoundsQuery({
    creatorId: influencerId,
    pageNumber: 1,
  })

  const { refetch: refetchInProgressSounds } = useInProgressSoundsQuery(1)

  const { refetch: refetchSubmittedSounds } = useSubmittedSoundsQuery(1)
  const onRefreshClick = (): void => {
    void refetchNewSounds()
    void refetchInProgressSounds()
    void refetchSubmittedSounds()
  }
  return [onRefreshClick]
}
