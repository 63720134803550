import { Middleware, PayloadAction } from '@reduxjs/toolkit'

import {
  defaultSearchErrorTagManagerEvent,
  errorAddTrackEvent,
} from 'src/Helpers/TagManager'
import { CampaignPlatformTypeEnum } from 'src/Types'
import { EventNameEnum } from 'src/Helpers/TagManager/tagManagerEnums'

import rootReducer from '../root-reducer'
import { RootState } from '../index'
import { ErrorCode } from '../types'

type Reducer = ReturnType<typeof rootReducer>

type MetaType = {
  requestStatus: 'pending' | 'fulfilled' | 'rejected'
}

type PayloadType = {
  code: number
  message: string
}

const spotifySearchTrackActions = [
  'getSpotifyTracks',
  'checkTrackToCreateCampaign',
]

const eventMapCodes: Record<number, EventNameEnum> = {
  [ErrorCode.Conflict]: EventNameEnum.ErrorSameTrack,
  [ErrorCode.Unauthorized]: EventNameEnum.ErrorAccessToken,
}

export const sendSpotifyTrackEvents: Middleware<unknown, Reducer> =
  (store) =>
  (next) =>
  (action: PayloadAction<PayloadType, string, MetaType>) => {
    const { authentication, campaigns } = store.getState() as RootState
    const { user } = authentication.user
    const { amountOfCampaigns } = campaigns.main

    const userId = user?.id ? user.id : user?.curatorId
    const isFirstCampaign = user?.is_first_campaign
    const campaignNumber = amountOfCampaigns + 1

    const isSpotifySearchTrackAction = (actionType: string): boolean =>
      spotifySearchTrackActions.some((searchTrackActions) =>
        actionType.includes(searchTrackActions),
      )

    const isRejectedResponse =
      action.meta?.requestStatus === 'rejected' &&
      isSpotifySearchTrackAction(action.type)

    if (isRejectedResponse) {
      const eventName = eventMapCodes[action.payload.code]

      eventName
        ? defaultSearchErrorTagManagerEvent({
            userId,
            eventName,
            campaignPlatform: CampaignPlatformTypeEnum.SPOTIFY,
          })
        : errorAddTrackEvent({
            userId,
            isFirstCampaign,
            campaignNumber,
            campaignPlatform: CampaignPlatformTypeEnum.SPOTIFY,
          })
    }

    return next(action)
  }
