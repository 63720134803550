import styled from 'styled-components'

import { variables, colors, device } from 'src/Styled/variables'

export const Container = styled.div<{ isCurrent: boolean; isPassed: boolean }>`
  display: flex;
  align-items: center;
  flex: 1;
  padding: 14px 0;
  margin-left: 2px;
  border-bottom: 2px solid
    ${({ isCurrent, isPassed }) =>
      isCurrent || isPassed ? colors.green : colors.greyTextWhiteBg};
  cursor: pointer;

  &:first-child {
    margin-left: 0px;
  }

  @media ${device.mobileMax} {
    width: fit-content;
    white-space: nowrap;
    padding-right: 24px;
  }
`

export const Title = styled.span<{ isCurrent: boolean; isPassed: boolean }>`
  color: ${({ isCurrent, isPassed }) =>
    isCurrent || isPassed ? colors.green : colors.greyTextWhiteBg};
  font-size: ${variables.fontSize18};
`

export const StepIndicator = styled.div<{
  isCurrent: boolean
  isPassed: boolean
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  width: 24px;
  height: 24px;
  background: ${({ isCurrent }) => (isCurrent ? colors.green : 'transparent')};
  color: ${({ isCurrent, isPassed }) =>
    isCurrent || isPassed ? colors.white : colors.greyTextWhiteBg};
  font-size: ${variables.fontSize14};
  border: 1px solid
    ${({ isCurrent, isPassed }) =>
      isCurrent || isPassed ? colors.green : colors.greyTextWhiteBg};
  border-radius: 12px;
`
