import { useSelector } from 'react-redux'

import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { getUser, getUserId } from 'src/Redux/auth-process/userSlice/selectors'
import { getCampaignToSchedule } from 'src/Redux/campaign-process/spotifyCampaignSchedule-process/selectors'
import { CampaignPlatformTypeEnum, CampaignTypeEnum } from 'src/Types'
import {
  checkoutViewEvent,
  checkoutConfirmedPayment,
} from 'src/Helpers/TagManager'
import { PaymentMethodEnum } from 'src/Constants/enums'
import {
  sendPaymentInfoEvent,
  sendPaymentInfoPlatformEvent,
  sendPaymentStartEvent,
  sendPaymentStartPlatformEvent,
} from 'src/Redux/googleAnalytics-process/api-actions'
import { checkoutStartMpEvent } from 'src/Redux/ga4-process/api-actions'
import { getTiktokPrice } from 'src/Redux/schedule-process/audienceTiktokSlice/selectors'
import { getScheduledTikTokCampaign } from 'src/Redux/schedule-process/tiktokCampaign/selectors'
import { getPaymentMethod } from 'src/Redux/payment-process/selectors'
import { getRememberPayPalForFutureFlag } from 'src/Redux/paypalTransactions-process/selectors'
import { getSubscriptionSelector } from 'src/Redux/subscription-process/subscription/selectors'
import { useSendPaymentDataMutation } from 'src/Redux/paymentWebhookDataApi'
import { getAmountOfCampaigns } from 'src/Redux/campaignList-process'

interface CheckoutEventsBody {
  platformName: string
  id: number
  campaignType: string
  genres: string[]
  languages: string[]
  resultPrice: number
  startDate: string
  isFeed: boolean
}

interface CheckoutEvents {
  handleCheckoutStartEvent: (params: CheckoutEventsBody) => void
  handleTiktokCheckoutConfirmedPaymentEvent: () => void
  handleSpotifyCheckoutConfirmedPaymentEvent: () => void
  sendPaymentDataToWebhook: ({
    platform,
    selectedPaymentMethod,
  }: {
    platform: CampaignPlatformTypeEnum
    selectedPaymentMethod: PaymentMethodEnum
  }) => void
}

export const useCheckoutEvents = (): CheckoutEvents => {
  const dispatch = useAppDispatch()
  const user = useSelector(getUser)
  const userId = useSelector(getUserId)
  const plan = useAppSelector(getSubscriptionSelector)
  const amountOfCampaigns = useSelector(getAmountOfCampaigns)
  const price = useAppSelector(getTiktokPrice)
  const campaignDetails = useSelector(getCampaignToSchedule)
  const scheduledCampaign = useSelector(getScheduledTikTokCampaign)
  const paymentMethod = useSelector(getPaymentMethod)
  const isRememberPayPalForFuture = useSelector(getRememberPayPalForFutureFlag)
  const [sendPaymentData] = useSendPaymentDataMutation()

  const handleCheckoutStartEvent = (params: CheckoutEventsBody): void => {
    checkoutViewEvent({
      platformName: params.platformName,
      campaignId: params.id,
      campaignType: params.campaignType,
      userId: String(userId),
      campaignNumber: amountOfCampaigns || 1,
      is_feed: params.isFeed,
      genreList: params.genres?.join(),
      languageList: params.languages.join(),
      resultPrice: params.resultPrice,
      paymentMethod: paymentMethod || PaymentMethodEnum.STRIPE,
      subscriptionPlan: plan?.package?.name || '',
    })

    void dispatch(
      checkoutStartMpEvent({
        campaignId: params.id,
        campaignPlatform: params.platformName,
        paymentMethod:
          paymentMethod === PaymentMethodEnum.STRIPE
            ? PaymentMethodEnum.STRIPE
            : PaymentMethodEnum.PAYPAL,
        price: params.resultPrice,
      }),
    )

    params.platformName === CampaignPlatformTypeEnum.SPOTIFY &&
      void dispatch(
        sendPaymentStartEvent({
          campaignId: params.id,
          transaction: {
            price: params.resultPrice,
            start_date: params.startDate,
          },
          url: window.location.origin,
        }),
      )

    params.platformName === CampaignPlatformTypeEnum.TIKTOK &&
      void dispatch(
        sendPaymentStartPlatformEvent({
          campaignId: params.id,
          platform: params.platformName,

          transaction: {
            price: params.resultPrice,
            start_date: params.startDate,
          },
          url: window.location.href,
        }),
      )
  }

  const handleSpotifyCheckoutConfirmedPaymentEvent = (): void => {
    checkoutConfirmedPayment({
      paymentMethod,
      isRememberPayPalForFuture,
      campaignId: Number(campaignDetails.id),
      campaignType: campaignDetails.type || CampaignTypeEnum.BASIC,
      platformName: CampaignPlatformTypeEnum.SPOTIFY,
      userId: String(userId),
      campaignNumber: amountOfCampaigns || 1,
      genreList:
        campaignDetails?.genres.map((genre) => genre.name).join() || '',
      languageList:
        campaignDetails?.languages.map((language) => language.name).join() ||
        '',
      resultPrice: campaignDetails.price || 0,
      subscriptionPlan: plan?.package?.name || '',
    })
    void dispatch(
      sendPaymentInfoEvent({
        campaignID: Number(campaignDetails.id),
        url: window.location.href,
      }),
    )
  }
  const handleTiktokCheckoutConfirmedPaymentEvent = (): void => {
    checkoutConfirmedPayment({
      paymentMethod,
      isRememberPayPalForFuture,
      campaignId: Number(scheduledCampaign.id),
      campaignType: scheduledCampaign.type || CampaignTypeEnum.BASIC,
      platformName: CampaignPlatformTypeEnum.TIKTOK,
      userId: String(userId),
      campaignNumber: amountOfCampaigns || 1,
      genreList:
        scheduledCampaign?.genres.map((genre) => genre.label).join() || '',
      languageList:
        scheduledCampaign?.languages.map((language) => language.label).join() ||
        '',
      resultPrice: price,
      subscriptionPlan: plan?.package?.name || '',
    })
    void dispatch(
      sendPaymentInfoPlatformEvent({
        campaignID: Number(scheduledCampaign.id),
        platform: CampaignPlatformTypeEnum.TIKTOK,
        url: window.location.href,
      }),
    )
  }

  const sendPaymentDataToWebhook = ({
    platform,
    selectedPaymentMethod,
  }: {
    platform: CampaignPlatformTypeEnum
    selectedPaymentMethod: PaymentMethodEnum
  }): void => {
    const campaignId =
      platform === CampaignPlatformTypeEnum.TIKTOK
        ? scheduledCampaign.id
        : campaignDetails.id

    void sendPaymentData({
      firstName: user.first_name,
      lastName: user.last_name,
      email: user.email,
      phone: user.phone_number,
      campaignId: Number(campaignId),
      paymentMethod: selectedPaymentMethod,
    })
  }

  return {
    handleCheckoutStartEvent,
    handleTiktokCheckoutConfirmedPaymentEvent,
    handleSpotifyCheckoutConfirmedPaymentEvent,
    sendPaymentDataToWebhook,
  }
}
