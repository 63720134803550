import i18n from 'src/Localization/i18n'
import { TransactionTypeEnum } from 'src/Types/index'

export const getMobileHeader = (
  transactionType: TransactionTypeEnum,
): string => {
  let message = ''
  if (transactionType === TransactionTypeEnum.USED) {
    message = 'Credits used for'
  }

  if (transactionType === TransactionTypeEnum.PAYMENT) {
    message = 'Payment for'
  }

  if (transactionType === TransactionTypeEnum.RECEIVED) {
    message = 'Credits received for'
  }

  if (transactionType === TransactionTypeEnum.CHARGEBACK) {
    message = 'Chargeback for'
  }

  return message
}

interface BalanceItemDescription {
  header: string
  footer: string
}

const BONUSES_TRANSLATION_TYPES = [
  TransactionTypeEnum.BONUS,
  TransactionTypeEnum.QUICK_CHECKOUT_BONUS_FUTURE,
  TransactionTypeEnum.QUICK_CHECKOUT_BONUS_CURRENT,
]
const SUBSCRIPTION_TRANSLATION_TYPES = [
  TransactionTypeEnum.SUBSCRIPTION,
  TransactionTypeEnum.SUBSCRIPTION_PAYMENT_FAILED,
]

export const getBalanceItemDescription = (
  transactionType: TransactionTypeEnum,
  firstName?: string,
  packageName?: string,
): BalanceItemDescription => {
  const description = {
    header: '',
    footer: '',
  }

  if (BONUSES_TRANSLATION_TYPES.includes(transactionType)) {
    description.header = i18n.t('balancePage.bonusFromTheSoundCampaign')
  }
  if (SUBSCRIPTION_TRANSLATION_TYPES.includes(transactionType)) {
    description.header = `${packageName || ''} Plan`
  }
  if (transactionType === TransactionTypeEnum.CHARGEBACK && packageName) {
    description.header = `${packageName} Plan`
  }

  if (transactionType === TransactionTypeEnum.INVITED_BONUS && firstName) {
    description.header = 'Bonus from referral program'
    description.footer = `Bonus for joining: invitation from ${firstName}`
  }

  if (transactionType === TransactionTypeEnum.REFERRAL_BONUS && firstName) {
    description.header = 'Bonus from referral program'
    description.footer = `${firstName} launched a campaign after your invitation`
  }

  return description
}

export const BONUS_TYPES = [
  TransactionTypeEnum.BONUS,
  TransactionTypeEnum.INVITED_BONUS,
  TransactionTypeEnum.REFERRAL_BONUS,
  TransactionTypeEnum.QUICK_CHECKOUT_BONUS_CURRENT,
  TransactionTypeEnum.QUICK_CHECKOUT_BONUS_FUTURE,
]
