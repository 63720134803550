import React, { ReactNode } from 'react'

import { useTranslation, Trans } from 'react-i18next'

import { VideoUploadError } from 'src/Types/index'
import Danger from 'src/Assets/Svg/Danger.svg?react'

import {
  ErrorMessage,
  UseThisSound,
  WrapperWithIcon,
  StyledImgWrapper,
  ErrorMessageText,
} from './styles'

export const useValidationText = (): Record<string, ReactNode> => {
  const { t } = useTranslation()

  return {
    [VideoUploadError.NOT_OWNER_TIKTOK_LINK]: (
      <ErrorMessage>
        {t('influencer.notTiktokVideoOwnerErrorMessage')}
      </ErrorMessage>
    ),
    [VideoUploadError.CAMPAIGN_DOES_NOT_EXIST]: (
      <ErrorMessageText>
        {t('influencer.campaignDoesNotExist')}
      </ErrorMessageText>
    ),
    [VideoUploadError.NOT_TIKTOK_LINK]: (
      <ErrorMessage>{t('influencer.notTiktokLinkErrorMessage')}</ErrorMessage>
    ),
    [VideoUploadError.NOT_TIKTOK_VIDEO_LINK]: (
      <ErrorMessage>
        {t('influencer.notTiktokVideoLinkErrorMessage')}
      </ErrorMessage>
    ),
    [VideoUploadError.SMALL_VIDEO_DURATION]: (
      <ErrorMessage>{t('influencer.theVideoIsTooShort')}</ErrorMessage>
    ),
    [VideoUploadError.VIDEO_NOT_AVAILABLE]: (
      <ErrorMessage>{t('influencer.uploadVideoError')}</ErrorMessage>
    ),
    [VideoUploadError.INCORRECT_TRACK_IN_VIDEO]: (
      <WrapperWithIcon>
        <StyledImgWrapper>
          <Danger height='16' width='16' />
        </StyledImgWrapper>
        <Trans
          t={t}
          i18nKey='influencer.videoContainsTheWrongTrack'
          components={{
            ErrorMessage: <ErrorMessage />,
            span: <UseThisSound />,
          }}
          defaults='<ErrorMessage>Video contains the wrong track. Please use the track from the current campaign. To do this, open the track on TikTok and press the <span>Use this sound</span> button.</ErrorMessage>'
        />
      </WrapperWithIcon>
    ),
  }
}
