import { FC, useEffect } from 'react'

import styled from 'styled-components'

import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { getReferralList } from 'src/Redux/referral-process/referralList/selectors'
import { getReferralUserList } from 'src/Redux/referral-process/referralList/api-actions'

import { RewardsDetailsItem } from './RewardsDetailsItem/RewardsDetailsItem'

const List = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  list-style: none;
  padding: 0;
  @media (min-width: 546px) {
    gap: 2px;
  }
`

export const RewardsDetails: FC = () => {
  const dispatch = useAppDispatch()
  const list = useAppSelector(getReferralList)

  useEffect(() => {
    void dispatch(getReferralUserList())
  }, [dispatch])
  return (
    <List>
      {list?.map((item) => (
        <RewardsDetailsItem
          key={item.id}
          name={`${item.firstName || ''} ${item.lastName || ''}`}
          date={item.createdAt}
          email={item.email}
          status={item.status}
          bonus={Number(item.amount)}
        />
      ))}
    </List>
  )
}
