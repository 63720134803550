import { FC, useEffect, useMemo } from 'react'

import { useTranslation } from 'react-i18next'

import CopyIcon from 'src/Assets/Svg/copy.svg?react'
import { colors } from 'src/Styled/variables'
import { copyInviteEvent, getReferralShareEvent } from 'src/Helpers/TagManager'
import {
  getReferralCode,
  getUserSelector,
} from 'src/Redux/auth-process/userSlice/selectors'
import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { getReferralHashValue } from 'src/Redux/referral-process/referralHash'
import { getReferralHash } from 'src/Redux/referral-process/referralHash/api-actions'
import { create } from 'src/Router/routes'

import { getSocialLinks } from './helpers'

import {
  ButtonsBlock,
  Description,
  InviteBlockContainer,
  InviteBlockTitle,
  ReferralLink,
  StyledCopyButton,
} from './styles'

export const InviteBlock: FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const user = useAppSelector(getUserSelector)
  const referralCode = useAppSelector(getReferralCode)
  const referralHash = useAppSelector(getReferralHashValue)

  useEffect(() => {
    if (!referralCode && !referralHash) {
      void dispatch(getReferralHash())
    }
  }, [dispatch, referralCode, referralHash])
  const code = referralCode || referralHash
  const link = `${window.location.origin}${create}?referralCode=${code}`

  const socialLinks = useMemo(() => getSocialLinks(link), [link])

  return (
    <InviteBlockContainer>
      <InviteBlockTitle>{t('referAndEarn.startReferringNow')}</InviteBlockTitle>
      <Description>{t('referAndEarn.give')}</Description>
      <ButtonsBlock>
        <StyledCopyButton
          fill={colors.green}
          disabled={code === ''}
          copiedText={t('referAndEarn.linkCopied')}
          value={link}
          onClick={() => {
            copyInviteEvent({ userId: user.id, eventName: 'link' })
          }}
        >
          <CopyIcon />
          {t('referAndEarn.copyInviteLink')}
        </StyledCopyButton>
        {socialLinks.map(({ name, id, path, icon: Icon }) => (
          <ReferralLink
            target='blank'
            key={name}
            href={path}
            onClick={() =>
              getReferralShareEvent({ userId: user.id, buttonType: id })
            }
          >
            <Icon />
            {name}
          </ReferralLink>
        ))}
      </ButtonsBlock>
    </InviteBlockContainer>
  )
}
