import { useEffect } from 'react'

import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { locationEvent, pageViewEvent } from 'src/Helpers/TagManager'
import { pageInfo } from 'src/Helpers/TagManager/pageInfo'
import { getUserId } from 'src/Redux/auth-process/userSlice/selectors'
import { CampaignPlatformTypeEnum, RelaunchInvokePlacement } from 'src/Types'
import { useRelaunchEvent } from 'src/Hooks/useRelaunchEvent'
import { useRelaunchCampaignMutation } from 'src/Redux/relaunchCampaignApi'
import { ArtistRoutesEnum } from 'src/Router/ArtistComponentsNewUI'
import { useUpsellButtonEvent } from 'src/Hooks/useUpsellButtonEvent'

export interface UseSpotifyCampaignDetailsPage {
  onRelaunchCampaign: (campaignId: number) => void
  onUpsellCampaign: (trackName: string) => void
}

export const useSpotifyCampaignDetailsPage =
  (): UseSpotifyCampaignDetailsPage => {
    const userId = useSelector(getUserId)
    const navigate = useNavigate()
    const { handleRelaunchEvent } = useRelaunchEvent()
    const [createRelaunchCampaign] = useRelaunchCampaignMutation()
    const { onDetailsTikTokUpsellButtonClickEvent } = useUpsellButtonEvent()

    useEffect(() => {
      if (userId) {
        pageViewEvent({
          userID: userId,
          currentPageInfo: pageInfo.campaignResults,
        })
        locationEvent()
      }
    }, [userId])

    const onRelaunchCampaign = (campaignId: number | string): void => {
      const url = window.location.href

      createRelaunchCampaign({ campaignId: Number(campaignId), url })
        .unwrap()
        .then((response) => {
          handleRelaunchEvent({
            campaignId: response.id,
            placement: RelaunchInvokePlacement.DETAILS,
          })
          navigate(`/campaigns/schedule/${response.id}`)
          return response
        })
    }

    const onUpsellCampaign = (trackName: string): void => {
      const tiktokCreateLink = `${ArtistRoutesEnum.CampaignsList}${ArtistRoutesEnum.New}/${
        CampaignPlatformTypeEnum.TIKTOK
      }?trackName=${encodeURIComponent(trackName || '')}`

      navigate(tiktokCreateLink)
      onDetailsTikTokUpsellButtonClickEvent()
    }

    return { onRelaunchCampaign, onUpsellCampaign }
  }
