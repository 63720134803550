import styled from 'styled-components'

import { colors, device, variables } from 'src/Styled/variables'

export const StyledReleaseItem = styled.li`
  display: grid;
  grid-template-columns: 40px 1fr;
  grid-template-rows: repeat(4, minmax(0, auto));
  grid-row-gap: 24px;
  grid-column-gap: 16px;
  align-items: start;
  padding: 16px 24px;
  margin-bottom: 8px;
  background-color: ${colors.white};
  border: 1px solid ${colors.green};

  @media ${device.smallScreen} {
    grid-template-columns: 80px 1fr 180px 1fr 194px;
    grid-template-rows: none;
    align-items: center;
    background-color: ${colors.greyBg};
    margin-bottom: 2px;
  }
`

export const StyledIcon = styled.div`
  display: grid;
  gap: 8px;
  justify-items: center;
  align-items: center;
  grid-column-start: 1;
  grid-row-start: 1;
  grid-row: 1 / -1;
  @media ${device.smallScreen} {
    grid-auto-flow: column;
    gap: 16px;
  }
`

export const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
  @media ${device.smallScreen} {
    grid-column-start: 1;
  }
`

export const Block = styled.div`
  display: none;
  @media ${device.smallScreen} {
    display: block;
  }
`

export const ImgWrapper = styled.div`
  padding-top: 3px;
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 4px;
  & img {
    border-radius: 4px;
  }
  @media ${device.smallScreen} {
    padding-top: 0px;
  }
`

export const NamesWrapper = styled.div`
  display: grid;
  font-size: ${variables.fontSize14};
  justify-content: start;
  gap: 4px;
  line-height: 150%;
  color: ${colors.mainText};
`

export const BadgeWrapper = styled.div`
  padding: 0 4px;
  border-radius: 4px;
  color: ${colors.green};
  font-size: 10px;
  line-height: 150%;
  background-color: ${colors.green1Light};
  width: 71px;
`
export const Status = styled.div`
  color: ${colors.green};
`

export const Name = styled.div`
  font-weight: ${variables.fontWeight400};
  line-height: 150%;
`

export const Artist = styled.div`
  font-size: ${variables.fontSize10};
  line-height: 150%;
`

export const CardButtonWrapper = styled.div`
  min-width: 75%;
  max-width: 75%;
  display: grid;
  grid-template-columns: 138px 40px;
  grid-column-gap: 16px;
`
