import { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'

import { Mobile } from 'src/Components/Responsive'
import TiktokIcon from 'src/Assets/Svg/tiktok.svg?react'
import { SmartImage } from 'src/Components/SmartImage'
import track from 'src/Assets/Png/track.png'

import { TrackProps } from './types'

import {
  TrackInfoBlockContainer,
  TrackInfoBlockContainerInner,
  CoverContainer,
  InfoWrapper,
  LinkButton,
  NamesWrapper,
  ParamsWrapper,
  TrackAuthorName,
  TrackName,
  IconWrapper,
  Gradient,
  ParamsWrapperText,
} from './styles'

export const TrackInfoBlock: FunctionComponent<TrackProps> = ({ audio }) => {
  const { t } = useTranslation()

  return (
    <TrackInfoBlockContainer>
      <TrackInfoBlockContainerInner>
        <CoverContainer>
          <SmartImage src={audio.cover} alt='Track cover' fallbackSrc={track} />
        </CoverContainer>
        <InfoWrapper>
          <ParamsWrapper>
            <ParamsWrapperText>
              <NamesWrapper>
                <TrackName>{audio.trackName}</TrackName>
                <TrackAuthorName>{audio.authorName}</TrackAuthorName>
                <Mobile>
                  <Gradient />
                </Mobile>
              </NamesWrapper>
              <IconWrapper>
                <TiktokIcon width={16} height={16} />
              </IconWrapper>
            </ParamsWrapperText>
          </ParamsWrapper>
        </InfoWrapper>
      </TrackInfoBlockContainerInner>
      <LinkButton target='_blank' href={audio.link}>
        {t('influencer.openTrack')}
      </LinkButton>
    </TrackInfoBlockContainer>
  )
}
