import { createDraftSafeSelector } from '@reduxjs/toolkit'

import { RootState } from 'src/Redux'

import { AuthTypeState } from '.'

const getState = (state: RootState): AuthTypeState =>
  state.authentication.authType

export const getAuthType = createDraftSafeSelector(
  getState,
  ({ authType }) => authType,
)

export const getIsAuthTypeLoading = createDraftSafeSelector(
  getState,
  ({ status }) => status === 'loading',
)

export const getAuthEmail = createDraftSafeSelector(
  getState,
  ({ email }) => email,
)
