import styled from 'styled-components'

import { variables, colors } from 'src/Styled/variables'

export const MobileHeaderTitle = styled.span`
  font-weight: ${variables.fontWeight400};
  font-size: ${variables.fontSize14};
  line-height: 150%;
  color: ${colors.mainText};
  display: grid;
  grid-template-columns: 14.4px 1fr;
  column-gap: 8px;
  & svg {
    width: 14.4px;
    height: 16px;
    margin-top: 4px;
  }
`

export const TableTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export const MobileCard = styled.div`
  background-color: ${colors.white};
  padding: 16px 16px 16px 40px;
  border-radius: 4px;
`

export const MobileCardHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
`

export const MobileCardInfoWrapper = styled.div``

export const MobileCardFooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  justify-content: space-between;
`

export const MobileParamTitle = styled.span`
  font-weight: ${variables.fontWeight400};
  font-size: ${variables.fontSize10};
  line-height: 150%;
  color: ${colors.mainText};
`
export const MobileParamItem = styled.div`
  display: flex;
  flex-direction: column;
`
export const MobileItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`

export const MobileLoadingWrapper = styled.div`
  margin-top: 100px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

export const MobileNoItemsTextWrapper = styled.div`
  display: block;
  text-align: center;
  margin-top: 16px;
`

export const MobileInfoText = styled.span`
  font-weight: ${variables.fontWeight400};
  font-size: ${variables.fontSize14};
  line-height: 150%;
  color: ${colors.greyTextWhiteBg};
  margin-top: 20px;
  text-align: center;
`
export const HeaderTitle = styled.span`
  font-weight: ${variables.fontWeight400};
  font-size: ${variables.fontSize14};
  line-height: 150%;
  color: ${colors.mainText};
`

export const InfoText = styled.span`
  font-weight: ${variables.fontWeight400};
  font-size: ${variables.fontSize14};
  line-height: 150%;
  color: ${colors.greyTextWhiteBg};
`

export const Amount = styled.span<{ green?: boolean }>`
  font-weight: ${variables.fontWeight400};
  font-size: ${variables.fontSize14};
  line-height: 150%;
  color: ${({ green }) => (green ? colors.green : colors.mainText)};
`
