import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'

import { ID, Playlist } from 'src/Types'
import { APP } from 'src/Configs/App'
import { api } from 'src/Services/api'

import { ErrorCode, NameSpace } from '../types'

export interface PlaylistData {
  Playlists: Array<Playlist>
}

export const getPlaylists = createAsyncThunk<PlaylistData>(
  `${NameSpace.Playlists}/getPlaylists`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await api.get<PlaylistData>(
        `${APP.SERVER}/curator/get-playlists`,
      )
      return data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      return rejectWithValue({
        message: response?.data.message,
        code: response?.status || ErrorCode.InternalServerError,
      })
    }
  },
)
export const checkPlaylist = createAsyncThunk<Playlist, string>(
  `${NameSpace.Playlists}/checkPlaylist`,
  async (playlistUrl, { rejectWithValue }) => {
    try {
      const { data } = await api.post<Playlist>(
        `${APP.SERVER}/curator/add-playlist`,
        {
          playlist_url: playlistUrl,
        },
      )
      return data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      return rejectWithValue({
        message: response?.data.message,
        code: response?.status || ErrorCode.InternalServerError,
      })
    }
  },
)
export interface PlaylistLanguagesBody {
  id: ID
  genre_ids: Array<number>
  language_ids: Array<number>
  moods_ids: Array<number>
}
export const editPlaylistsLanguages = createAsyncThunk<
  Playlist[],
  PlaylistLanguagesBody[]
>(
  `${NameSpace.Playlists}/editPlaylistsLanguages`,
  async (playlists, { rejectWithValue }) => {
    try {
      const { data } = await api.put<Playlist[]>(
        `${APP.SERVER}/curator/playlists`,
        {
          playlists,
        },
      )
      return data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      return rejectWithValue({
        message: response?.data.message,
        code: response?.status || ErrorCode.InternalServerError,
      })
    }
  },
)

interface PlaylistInformationBody {
  playlistID: number | string
  genres: Array<number>
  languages: Array<number>
  moods: Array<number>
}

export const changePlaylistInformation = createAsyncThunk<
  Playlist,
  PlaylistInformationBody
>(
  `${NameSpace.Playlists}/changePlaylistInformation`,
  async ({ playlistID, genres, languages, moods }, { rejectWithValue }) => {
    try {
      const { data } = await api.put<Playlist>(
        `${APP.SERVER}/curator/update-playlist-genres`,
        {
          playlist_id: playlistID,
          genre_ids: genres,
          language_ids: languages,
          moods_ids: moods,
        },
      )
      return data
    } catch (error) {
      const { response } = error as AxiosError<{ errorMsg: string[] }>
      return rejectWithValue({
        message: response?.data.errorMsg[0],
        code: response?.status || ErrorCode.InternalServerError,
      })
    }
  },
)
