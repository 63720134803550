import { FC, useMemo } from 'react'

import { useAppSelector } from 'src/Hooks/redux'
import {
  GroupedGenre,
  getGroupedGenres,
} from 'src/Redux/similarGenres-process/selectors'
import { SimilarGenre } from 'src/Redux/similarGenres-process'
import { GenreListWrapper, Separator } from 'src/Components/GenresList/styles'
import { GenreOrLanguageItem } from 'src/Components/GenresList/GenreItem'
import { capitalizeFirstLetter } from 'src/Helpers/layoutHelpers'
import { filterAndSortGenres } from 'src/Constants/functions'

import { List } from '../../../styles'

import { GroupedGenres } from './GroupedGenres'

const getFiltered = (
  groupedOptions: GroupedGenre[],
  searchValue: string,
): GroupedGenre[] =>
  groupedOptions.map((item) => ({
    ...item,
    options: filterAndSortGenres(item.options, searchValue, 'genreName'),
  }))

interface Props {
  searchValue: string
  selected: SimilarGenre[]
  onClick: (id: number) => void
}

export const GenresList: FC<Props> = ({ onClick, searchValue, selected }) => {
  const groupedOptions = useAppSelector(getGroupedGenres)
  const filteredOptions = useMemo(
    () => getFiltered(groupedOptions, searchValue),
    [groupedOptions, searchValue],
  )
  return (
    <>
      {Boolean(selected.length) && (
        <>
          <GenreListWrapper>
            {selected.map((genre) => (
              <GenreOrLanguageItem
                onClick={() => onClick(Number(genre?.id))}
                hasCross
                name={capitalizeFirstLetter(genre.genreName || '')}
                key={genre.id}
              />
            ))}
          </GenreListWrapper>
          <Separator />
        </>
      )}
      <List>
        {filteredOptions.map((item) => (
          <GroupedGenres
            key={item.label}
            selected={selected}
            searchValue={searchValue}
            item={item}
            onClick={onClick}
          />
        ))}
      </List>
    </>
  )
}
