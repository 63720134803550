import styled from 'styled-components'

import { variables, colors } from 'src/Styled/variables'

export const Container = styled.div`
  margin: 0 auto;
  @media (max-width: 576px) {
    padding: 0;
  }
`

export const CardsOverflow = styled.div`
  @media (max-width: 576px) {
    position: relative;
    margin-right: 26px;
    width: 100vw;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`

export const CardsContainer = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 16px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  overflow-x: scroll;
  @media (max-width: 576px) {
    width: 466px;
    margin-right: 26px;
  }
`

export const Card = styled.div`
  width: 24%;
  height: 128px;
  @media (max-width: 576px) {
    margin-bottom: 10px;
    width: 228px;
  }
`

export const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 14px 16px;
  background-color: ${colors.white};
  border-radius: 4px;
`

export const CardTitle = styled.div`
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight400};
  color: ${colors.greyTextWhiteBg};
`

export const CardMainValue = styled.h5`
  font-size: ${variables.fontSize20};
  font-weight: ${variables.fontWeight600};
  color: ${colors.mainText};
`

export const CardAdditionalInfo = styled.h5`
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight400};
  color: ${colors.green} !important;
`

export const DataNotReceivedContainer = styled.div`
  display: flex;
  align-items: center;
`

export const DataNotReceivedLabel = styled.div`
  margin: 0 0 0 8px;
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight400};
  color: ${colors.mainText};
`

export const ConnectSFA = styled.span`
  color: ${colors.mainText};
  border-bottom: 1px dashed ${colors.mainText};
  cursor: pointer;
`

export const ConnectedSFAContainer = styled.div`
  display: flex;
`

export const DotConnectedSFA = styled.div`
  margin: 3px 8px 0 0;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: ${colors.green};
`

export const ConnectedSFA = styled.span`
  color: ${colors.mainText};
  font-size: ${variables.fontSize10};
  line-height: 14px;
`

export const ReviewsContainer = styled.div`
  padding: 24px;
  background-color: ${colors.white};
  @media (max-width: 576px) {
    padding: 0px 0px 60px 0px;
    background-color: transparent;
  }
`
