import styled from 'styled-components'

import { colors } from 'src/Styled/variables'

export const Text = styled.p`
  color: ${colors.white} !important;
`
export const Wrapper = styled.div`
  width: 80px;
  height: 100%;
  padding: 0px 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 9px;
  border: solid #6b6b6b;
  border-width: 0px 1px;

  @media screen and (min-width: 360px) {
    min-width: 65px;
    padding: 0px 0px;
  }
`
