import { FC } from 'react'

import styled from 'styled-components'
import { useIntercom } from 'react-use-intercom'
import { useTranslation } from 'react-i18next'

import AlertComponent from 'src/Components/Alert'
import { DotedButton } from 'src/Components/Buttons/DotedButton'
import { useAppSelector } from 'src/Hooks/redux'
import { getIsBlockedCreatorSelector } from 'src/Redux/auth-process/userSlice/selectors'
import { colors, variables } from 'src/Styled/variables'
import { useCreatorOrderPayoutMutation } from 'src/Redux/orderPayoutApi'
import { ErrorCode } from 'src/Redux/types'

const Title = styled.div`
  color: ${colors.mainText};
  font-size: ${variables.fontSize14};
  font-style: normal;
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
`
const Description = styled(Title)`
  font-weight: ${variables.fontWeight400};
`

export const InfluencerBlockedError: FC = () => {
  const { t } = useTranslation()
  const isBlocked = useAppSelector(getIsBlockedCreatorSelector)
  const [_, { error }] = useCreatorOrderPayoutMutation({
    fixedCacheKey: 'CreatorOrderPayout',
  })
  const { show } = useIntercom()

  const hasProblemsWithTikTok =
    error && 'status' in error && error.status === ErrorCode.Forbidden
  const hasDefaultError =
    error && 'status' in error && error.status === ErrorCode.InternalServerError
  if (hasDefaultError) {
    return (
      <AlertComponent unclosable color='danger' icon>
        <Description>{t('balancePage.Oops')}</Description>
      </AlertComponent>
    )
  }
  const handleClickSupport = (): void => {
    show()
  }
  if (isBlocked || hasProblemsWithTikTok) {
    return (
      <AlertComponent unclosable icon color='danger'>
        <Title>{t('balancePage.accountBlocked')}</Title>
        <Description>{t('balancePage.blockedDesc')}</Description>
        <DotedButton onClick={handleClickSupport} color={colors.mainText}>
          {t('balancePage.contactSupport')}
        </DotedButton>
      </AlertComponent>
    )
  }
  return null
}
