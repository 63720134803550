import { rtkApi } from 'src/Services/rtkApi'
import { PaymentMethodEnum } from 'src/Constants/enums'

interface SendPaymentDataBody {
  firstName: string
  lastName: string
  email: string
  phone: string
  campaignId: number
  paymentMethod: PaymentMethodEnum
}

const sendPaymentWebhookApi = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    sendPaymentData: builder.mutation<void, SendPaymentDataBody>({
      query: ({
        firstName,
        lastName,
        email,
        phone,
        campaignId,
        paymentMethod,
      }) => ({
        url: 'https://hook.eu1.make.com/o7n37s3c2vxlkuc96byyr3aiukq2xxca',
        method: 'POST',
        body: {
          email,
          phone,
          payment_method: paymentMethod,
          first_name: firstName,
          last_name: lastName,
          campaign_id: campaignId,
        },
      }),
    }),
  }),
})
export const { useSendPaymentDataMutation } = sendPaymentWebhookApi
