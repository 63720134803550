import { createSlice } from '@reduxjs/toolkit'

import { setApiError } from 'src/Hooks/redux'
import { ApiError, NameSpace, SliceStatus } from 'src/Redux/types'
import { Pagination } from 'src/Types'

import { getAllTransactions } from './api-actions'

interface PayoutDetails {
  approvedAt: null | string
  createdAt: string
  status: string
}

export interface CuratorTransaction {
  id?: number
  amount: string
  createdAt: string
  description: string
  payoutDetails: null | PayoutDetails
  reviewDetails: {
    artistName: string
    trackImage: string
    trackName: string
    isAddedToPlaylist: boolean
  } | null
  type: string
}

export interface CuratorBalanceHistoryState {
  transactions: Array<CuratorTransaction>
  meta?: Pagination
  status: SliceStatus
  error: ApiError | null
}

const initialState: CuratorBalanceHistoryState = {
  transactions: [],
  meta: {
    pageNumber: 1,
    pageSize: 20,
    itemCount: 0,
    pageCount: 0,
    hasNextPage: false,
    hasPreviousPage: false,
  },
  status: SliceStatus.Idle,
  error: null,
}

export const curatorBalanceHistorySlice = createSlice({
  initialState,
  name: NameSpace.CuratorBalanceHistory,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getAllTransactions.pending, (state) => {
      state.status = SliceStatus.Loading
    })
    builder.addCase(getAllTransactions.fulfilled, (state, action) => {
      state.status = SliceStatus.Succeeded

      // added unique id for each item in array to correctly render list
      const data = action.payload.data.map((item, index) => ({
        ...item,
        id: Date.now() + index,
      }))
      if (action.payload.meta.pageNumber === 1) {
        state.transactions = data
      } else {
        state.transactions = [...state.transactions, ...data]
      }
      state.meta = action.payload.meta
      state.error = null
    })
    builder.addCase(getAllTransactions.rejected, (state, action) => {
      state.status = SliceStatus.Failed
      state.error = setApiError(action)
    })
  },
})
