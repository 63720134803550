import { createDraftSafeSelector } from '@reduxjs/toolkit'

import { PaymentMethodEnum } from 'src/Constants/enums'

import { RootState } from '..'

import { PaymentState } from '.'

const getState = (state: RootState): PaymentState => state.payment

export const getPaymentMethod = createDraftSafeSelector(
  getState,
  ({ paymentMethod }) => paymentMethod,
)

export const getIsCreditsMethod = createDraftSafeSelector(
  getState,
  ({ paymentMethod }) => paymentMethod === PaymentMethodEnum.Credits,
)
export const getPaymentAlertError = createDraftSafeSelector(
  getState,
  ({ alertError }) => alertError,
)
export const getPaymentLoading = createDraftSafeSelector(
  getState,
  ({ loading }) => loading,
)
