import styled from 'styled-components'

import { variables, colors } from 'src/Styled/variables'

export const TrackInfoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  padding: 24px;
  width: 100%;
  height: 111px;
  background-color: ${colors.white};
`

export const TrackInfoContainerMobile = styled.div`
  display: flex;
  margin-bottom: 16px;
  padding: 16px;
  width: 100%;
  background-color: ${colors.white};
`

export const TrackAvatarMobile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40px;
  margin-right: 16px;
`

export const TrackInfoColumnMobile = styled.div``

export const TrackInfoRowMobile = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`

export const TrackInfoLabelMobile = styled.span`
  font-size: ${variables.fontSize10};
  color: ${colors.mainText};
`

export const TrackInfoTextMobile = styled.span`
  font-size: ${variables.fontSize14};
  color: ${colors.mainText};
`

export const SpotifyIconContainer = styled.div`
  display: flex;
  align-items: center;
  width: 24px;
  height: 100%;

  @media (max-width: 576px) {
    height: 24px;
  }
`

export const TrackAvatar = styled.img`
  margin-left: 16px;
  width: 40px;
  height: 40px;
  border-radius: 4px;

  @media (max-width: 576px) {
    margin-left: 0;
    margin-bottom: 16px;
  }
`

export const TrackTitleContainer = styled.div`
  margin-left: 16px;
  padding: 2px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: 40px;
  flex: 1;
`

export const TrackTitle = styled.h5`
  margin: 0;
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight400};
  line-height: 21px;
  color: ${colors.mainText};
`

export const TrackAuthor = styled.div`
  font-size: ${variables.fontSize10};
  font-weight: ${variables.fontWeight400};
  color: ${colors.mainText};
  line-height: 15px;
`

export const CampaignCalculationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 152px;

  @media (max-width: 576px) {
    margin-bottom: 16px;
  }
`

export const CampaignCalculation = styled.div`
  height: 21px;
`

export const CampaignCalculationLabel = styled.span`
  display: inline-block;
  width: 64px;
  margin-left: 16px;
  color: ${colors.greyTextWhiteBg};

  @media (max-width: 576px) {
    margin-left: 0;
  }
`

export const CampaignCalculationValue = styled.span<{ green?: boolean }>`
  color: ${({ green }) => (green ? colors.green : colors.mainText)};
`

export const GenresAndLanguagesContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 224px;
`

export const ToggleDetails = styled.span`
  display: inline-block;
  margin-top: 9px;
  color: ${colors.mainText};
  border-bottom: 1px dashed ${colors.mainText};
`
