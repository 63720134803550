import React, { FC } from 'react'

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { useGrowthBook } from '@growthbook/growthbook-react'

import signupImage from 'src/Assets/Webp/signup-form-image.webp'
import signupImageMob from 'src/Assets/Webp/signup-form-image-mob.webp'
import { colors } from 'src/Styled/variables'

import {
  SIGNUP_VISUAL_BLOCK_FEATURE_NAME,
  VisualBlockVariant,
} from '../VisualBlockExperiment'

const ImageContainer = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  border-radius: 4px;
  background-color: ${colors.greyBg};
  @media (min-width: 767px) {
    justify-content: center;
    align-items: flex-start;
    gap: 40px;
    flex: 1 0 0;
  }

  @media (max-width: 767px) {
    width: 100%;
    height: 200px;
  }
`

const StyledImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 364px;
  height: 537px;

  @media (max-width: 767px) {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`

export const VisualBlock: FC = () => {
  const { t } = useTranslation()
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const growthBook = useGrowthBook()
  const variant = growthBook.getFeatureValue(
    SIGNUP_VISUAL_BLOCK_FEATURE_NAME,
    VisualBlockVariant.TrackDetailsVariant,
  ) as VisualBlockVariant
  const image = isMobile ? signupImageMob : signupImage
  const isImageHidden =
    variant === VisualBlockVariant.VisualBlockDesk && isMobile

  if (isImageHidden) {
    return null
  }

  return (
    <ImageContainer>
      <div>{t('createCampaignPage.signUpText')}</div>
      <StyledImage src={image} alt='visual block cover' />
    </ImageContainer>
  )
}
