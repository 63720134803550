import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'

import { api } from 'src/Services/api'
import { APP } from 'src/Configs/App'
import {
  SpotifyCampaign,
  CampaignStatusEnum,
  CampaignTypeEnum,
  TiktokCampaign,
  TiktokCampaignStatus,
} from 'src/Types'

import { ErrorCode, NameSpace } from '../types'

import { mapSpotifyCampaign, mapTiktokCampaign } from './helpers'

export type SpotifyCampaignsListResponse = Array<{
  id: number
  type: CampaignTypeEnum
  start_date: string
  end_date: string
  status: CampaignStatusEnum
  is_feed: boolean
  relaunched: boolean
  created_at: string
  reviewed_reviews: number
  all_review: number
  amount: string | null
  current_amount: string | null
  Track: {
    track_image: string
    track_name: string
    track_id: number
    artist_name: string
  }
}>

export interface TikTokCampaignsListResponse {
  campaigns: Array<{
    createdAt: string
    endDate: string
    genres: Array<{ id: number; genreName: string }>
    id: number
    influencersAcceptedAmount: number
    languages: Array<{ id: number; languageName: string }>
    startDate: string
    status: TiktokCampaignStatus
    submittedVideosAmount: number
    trackAuthorName: string
    trackCoverUrl: string
    trackPlayUrl: string
    trackTitle: string
    type: CampaignTypeEnum
    updatedAt: string
  }>
}

export const getCampaignsList = createAsyncThunk<{
  campaigns: Array<TiktokCampaign | SpotifyCampaign>
  spotifyCampaigns: Array<SpotifyCampaign>
  tiktokCampaigns: Array<TiktokCampaign>
}>(
  `${NameSpace.CampaignList}/getCampaignsList`,
  async (_, { rejectWithValue }) => {
    try {
      const { data: spotifyData } = await api.get<SpotifyCampaignsListResponse>(
        `${APP.SERVER}/campaign`,
      )
      const { data: tiktokData } = await api.get<TikTokCampaignsListResponse>(
        `${APP.TIKTOK_SERVER}/artist/tiktok/campaigns`,
      )

      const spotifyCampaigns = mapSpotifyCampaign(spotifyData)
      const tiktokCampaigns = mapTiktokCampaign(tiktokData)

      const campaigns = [...spotifyCampaigns, ...tiktokCampaigns].sort(
        (a, b) => {
          const dateA = new Date(String(a.createdAt))
          const dateB = new Date(String(b.createdAt))
          return dateB.getTime() - dateA.getTime()
        },
      )

      return {
        campaigns,
        spotifyCampaigns,
        tiktokCampaigns,
      }
    } catch (error) {
      const { message, response } = error as AxiosError
      return rejectWithValue({
        message,
        code: response?.status ?? ErrorCode.InternalServerError,
      })
    }
  },
)
