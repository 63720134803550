import { useSelector } from 'react-redux'

import { Roles } from 'src/Constants/enums'
import { Metadata, useMetadataQuery } from 'src/Redux/metadataApi'
import { useAppSelector } from 'src/Hooks/redux'
import {
  getIsArtist,
  getUserRole,
} from 'src/Redux/auth-process/credentialsSlice/selectors'

export const useShowFeedback = (): boolean => {
  const isArtist = useSelector(getIsArtist)
  const userRole = useAppSelector(getUserRole)
  const { data } = useMetadataQuery(null, {
    skip: userRole !== Roles.artist,
  })

  const checkConditions = (metadata: Metadata): boolean => {
    const hasSpotifyScheduledOrRunning =
      metadata.spotifyCampaignsCountByStatus?.ended > 0

    const hasTiktokScheduledOrRunning =
      metadata.tiktokCampaignsCountByStatus?.ended > 0

    return hasSpotifyScheduledOrRunning || hasTiktokScheduledOrRunning
  }

  const isAppropriateArtistStatus = data ? checkConditions(data) : false

  return isArtist && isAppropriateArtistStatus
}
