import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { SmartInput } from 'src/Components/SmartInput'
import Button from 'src/Components/Buttons/Button'

import { ResetPasswordSchema } from './helpers'

import { Container, FieldWrapper, Form, Label } from './styles'

interface FormState {
  password: string
  confirm: string
}

interface ResetPasswordFormProps {
  onSubmit: (data: FormState) => void
}

const ResetPasswordForm: FC<ResetPasswordFormProps> = ({ onSubmit }) => {
  const { t } = useTranslation()
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FormState>({
    resolver: yupResolver(ResetPasswordSchema),
  })

  return (
    <Form onSubmit={handleSubmit((data) => onSubmit(data))}>
      <Container>
        <Label>{t('settingsPage.choosePassword')}</Label>
        <FieldWrapper>
          <SmartInput
            {...register('password')}
            title='password'
            error={errors.password}
            type='password'
          />
        </FieldWrapper>
      </Container>
      <Container>
        <Label>{t('settingsPage.confirmPassword')}</Label>
        <FieldWrapper>
          <SmartInput
            {...register('confirm')}
            title='confirm'
            error={errors.confirm}
            type='password'
          />
        </FieldWrapper>
      </Container>
      <Button htmlType='submit' type='green'>
        {t('settingsPage.changePassword')}
      </Button>
    </Form>
  )
}

export default ResetPasswordForm
