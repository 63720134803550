import { FunctionComponent, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import Button from 'src/Components/Buttons/Button'
import Alert from 'src/Components/Alert'
import { changeEmail } from 'src/Redux/auth-process/credentialsSlice/api-actions'
import { useAppDispatch } from 'src/Hooks/redux'

import { FormDataProps } from './ChangeEmailFormProps'
import { schema } from './helpers/schema'

import {
  AlertTitle,
  ChangeEmailFormContainer,
  ChangeEmailFormHeader,
  ChangeEmailInput,
  ErrorText,
} from './styles'

export const ChangeEmailForm: FunctionComponent = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const [invalid, setInvalid] = useState('')
  const [isEmailUsed, setEmailUsed] = useState(false)
  const [isEmailChanged, setEmailChanged] = useState(false)

  const {
    register,
    control,
    watch,
    handleSubmit,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm<FormDataProps>({
    criteriaMode: 'firstError',
    reValidateMode: 'onChange',
    mode: 'onBlur',
    resolver: yupResolver(schema),
  })

  const values = getValues()
  const watchChangeEmail = watch('changeEmail', '')

  const handleClearErrors = (): void => {
    clearErrors()
    setInvalid('')
    setEmailUsed(false)
  }

  const handleChangeEmail = (): void => {
    if (errors && errors.changeEmail?.message?.length) {
      setInvalid(String(t('createCampaignPage.invalidEmailAddress')))
      return
    }
    void dispatch(changeEmail(values.changeEmail))
      .unwrap()
      .then((res) => {
        setEmailUsed(false)
        setEmailChanged(true)
        values.changeEmail = ''
        return res
      })
      .catch(() => {
        setEmailUsed(true)
        values.changeEmail = ''
      })
  }

  return (
    <>
      {isEmailChanged && (
        <Alert color='success' icon>
          <div>{t('settingsPage.emailChanged')}</div>
        </Alert>
      )}
      {isEmailUsed && (
        <Alert color='danger' icon>
          <AlertTitle>{t('errorsPage.error')}</AlertTitle>
          <div>{t('campaignsPage.emailIsUsed')}</div>
        </Alert>
      )}

      <ChangeEmailFormContainer onSubmit={handleSubmit(handleChangeEmail)}>
        <ChangeEmailFormHeader>
          {t('settingsPage.chooseEmail')}
        </ChangeEmailFormHeader>

        <Controller
          name='changeEmail'
          control={control}
          defaultValue=''
          render={({ field: { onChange } }) => (
            <ChangeEmailInput
              {...register('changeEmail')}
              invalid={Boolean(invalid)}
              name='changeEmail'
              type='email'
              placeholder='example@mail.com'
              onChange={onChange}
              onFocus={handleClearErrors}
              onTouchStart={handleClearErrors}
            />
          )}
        />

        <ErrorText>{invalid}</ErrorText>
        <Button
          type='green'
          disabled={
            !watchChangeEmail?.length || invalid.length > 0 || isEmailUsed
          }
          onClick={handleChangeEmail}
        >
          {t('settingsPage.updateEmail')}
        </Button>
      </ChangeEmailFormContainer>
    </>
  )
}
