export enum TabKeys {
  payout = 'payout',
}

export const getTabs = (): Array<{ key: TabKeys; title: string }> => [
  {
    key: TabKeys.payout,
    title: 'settingsPage.payout',
  },
]
