import { FC } from 'react'

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import IconSpotify from 'src/Assets/Svg/spotify.svg?react'
import TikTokIcon from 'src/Assets/Svg/tiktok.svg?react'
import { CampaignPlatformTypeEnum } from 'src/Types'
import { variables } from 'src/Styled/variables'
import RightArrowIcon from 'src/Assets/Svg/pages-icons/RightArrow.svg?react'
import { IconButton } from 'src/Components/Buttons/IconButton'

const Container = styled.div`
  display: flex;
  width: 100%;
  padding: 24px 32px;
  align-items: center;
  gap: 16px;
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.common.primaryBorder};
  background: ${({ theme }) => theme.common.primaryBg};
  box-shadow: 0px 0px 32px 0px rgba(51, 51, 51, 0.08);
  cursor: pointer;
`

const Text = styled.span`
  font-family: 'Segoe UI';
  font-size: ${variables.fontSize18};
  font-style: normal;
  font-weight: ${variables.fontWeight400};
  line-height: 150%;
  color: ${({ theme }) => theme.common.primaryText};
  flex-grow: 1;
  user-select: none;
`

export interface PlatformButtonProps {
  platform: CampaignPlatformTypeEnum
  onClick: () => void
}

export const PlatformButton: FC<PlatformButtonProps> = ({
  platform,
  onClick,
}) => {
  const { t } = useTranslation()

  const PlatformIcon =
    platform === CampaignPlatformTypeEnum.TIKTOK ? TikTokIcon : IconSpotify

  const platformName =
    platform === CampaignPlatformTypeEnum.TIKTOK
      ? t('createNewCampaignPage.tiktok')
      : t('createNewCampaignPage.spotify')

  return (
    <Container onClick={onClick}>
      <PlatformIcon />
      <Text>{`${platformName} ${t('createNewCampaignPage.campaign')}`}</Text>
      <IconButton>
        <RightArrowIcon />
      </IconButton>
    </Container>
  )
}
