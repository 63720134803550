import styled from 'styled-components'

import { colors, device, variables } from 'src/Styled/variables'
import Button from 'src/Components/Buttons/Button'

export const StyledTopBarNewRelease = styled.div`
  display: grid;
  grid-template-columns: 40px 1fr 80px;
  grid-column-gap: 8px;
  align-items: start;
  margin-top: 3px;
  margin-bottom: 16px;
  color: ${colors.mainText};
`

export const ImgWrapper = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  background-color: ${colors.black};
  &img {
    width: 11.6px;
  }
`
export const ReleaseInfo = styled.div`
  font-size: ${variables.fontSize12};
  color: #787985;
  line-height: 150%;
`
export const ReleaseInfoTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: ${variables.fontSize12};
  font-weight: 600;
  margin-bottom: 4px;
  & img {
    width: 16px;
    height: 16px;
  }
`
export const ReleaseInfoTitleSpan = styled.span`
  color: ${colors.badgeGreen};
`

export const ReleaseInfoText = styled.div`
  font-size: ${variables.fontSize10};
  margin-bottom: 8px;
  line-height: 150%;
`

export const ImageInfo = styled.div`
  padding: 8px;
  background: #f9f9f9;
  border-radius: 4px;
  display: flex;
  gap: 8px;
  flex-direction: column;
  justify-content: space-between;
  @media ${device.mobileMin} {
    flex-direction: row;
  }
`

export const InnerImageInfo = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`

export const Name = styled.div`
  font-weight: ${variables.fontWeight600};
  font-size: ${variables.fontSize10};
  line-height: 150%;
`

export const ImageWrapper = styled.div`
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 4px;
  & img {
    border-radius: 4px;
  }
`

export const ButtonWrapper = styled.div`
  width: 72px;
  display: flex;
  align-self: center;
  @media ${device.smallScreen} {
    align-self: self-start;
  }
`

export const DateWrapper = styled.div`
  font-size: ${variables.fontSize10};
  color: #787985;
  line-height: 150%;
`

export const PromoteBtn = styled.div`
  padding: 8px;
  background: #f9f9f9;
  border-radius: 4px;
  display: flex;
  gap: 8px;
  flex-direction: column;
  justify-content: space-between;
  @media ${device.mobileMin} {
    flex-direction: row;
  }
`
export const StyledPromoteButton = styled(Button)`
  > button {
    height: 24px !important;
    font-size: ${variables.fontSize10} !important;
  }
`
