import { FunctionComponent } from 'react'

import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Player } from 'src/Components/Player/index'

import { TrackProps } from './types'

import { Container, LinkContainer } from './styles'

export const Track: FunctionComponent<TrackProps> = ({ audio }) => {
  const { t } = useTranslation()

  return (
    <Container>
      <Player {...audio} />

      <LinkContainer>
        {t('influencer.orOpenOn')}{' '}
        <Link to={String(audio.link)} target='_blank'>
          {t('influencer.tikTok')}
        </Link>
      </LinkContainer>
    </Container>
  )
}
