import React, { FC, ReactNode, useEffect } from 'react'

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'

import { CampaignPlatformTypeEnum } from 'src/Types'
import { MAX_MOBILE_WIDTH, variables } from 'src/Styled/variables'

import { DesktopTabletTrackSearch } from './Components/DesktopTabletTrackSearch'
import { MobileTrackSearch } from './Components/MobileTrackSearch'
import { HowToGetTrackUrl } from './Components/HowToGetTrackUrl'

const Container = styled.div`
  display: flex;
  width: 100%;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.common.primaryBorder};
  background: ${({ theme }) => theme.common.primaryBg};
  box-shadow: 0px 0px 32px 0px rgba(51, 51, 51, 0.08);
  position: relative;

  @media (max-width: ${MAX_MOBILE_WIDTH}) {
    border: none;
    box-shadow: none;
    padding: 0;
  }
`

const Title = styled.span`
  font-family: 'Segoe UI';
  font-size: ${variables.fontSize18};
  font-style: normal;
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
  color: ${({ theme }) => theme.common.primaryText};
`

export type SearchedTrack = {
  id: string
  name: string
  artistName: string
  trackCoverUrl: string
  previewUrl: string
  trackDuration: string
}

export interface TrackSearchProps {
  platform: CampaignPlatformTypeEnum
  isLoading: boolean
  onItemClick: (trackId: string) => void
  onPlayClick?: () => void
  tracks: Array<SearchedTrack>
  onSearch: (search: string) => void
  error?: ReactNode
  defaultSearchValue?: string | null
  onOutsideClick: (e: MouseEvent) => void
}

export interface SearchTrackFormData {
  searchInput: string
}

export const TrackSearch: FC<TrackSearchProps> = ({
  platform,
  isLoading,
  onItemClick,
  onPlayClick,
  tracks = [],
  onSearch,
  error,
  defaultSearchValue,
  onOutsideClick,
}) => {
  const { t } = useTranslation()

  const { control } = useForm<SearchTrackFormData>({
    mode: 'onChange',
  })

  useEffect(() => {
    if (defaultSearchValue) {
      onSearch(defaultSearchValue)
    }
  }, [defaultSearchValue, onSearch])

  const platformTitle =
    platform === CampaignPlatformTypeEnum.TIKTOK
      ? t('createNewCampaignPage.newTikTok')
      : t('createNewCampaignPage.newSpotify')

  const placeholder =
    platform === CampaignPlatformTypeEnum.TIKTOK
      ? t('createNewCampaignPage.tiktokPlaceholder')
      : t('createNewCampaignPage.spotifyPlaceholder')

  return (
    <Container>
      <Title>{platformTitle}</Title>

      <DesktopTabletTrackSearch
        isLoading={isLoading}
        onItemClick={onItemClick}
        onPlayClick={onPlayClick}
        tracks={tracks}
        onSearch={onSearch}
        onOutsideClick={onOutsideClick}
        placeholder={placeholder}
        control={control}
        error={error}
        defaultSearchValue={defaultSearchValue}
      />

      <MobileTrackSearch
        isLoading={isLoading}
        onItemClick={onItemClick}
        onPlayClick={onPlayClick}
        tracks={tracks}
        onSearch={onSearch}
        placeholder={placeholder}
        control={control}
        error={error}
        defaultSearchValue={defaultSearchValue}
      />

      <HowToGetTrackUrl platform={platform} />
    </Container>
  )
}
