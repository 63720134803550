import { FC } from 'react'

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { colors, variables } from 'src/Styled/variables'
import { useAppSelector } from 'src/Hooks/redux'
import {
  getBonusPerCampaign,
  getInvitedArtists,
  getTotalBonus,
} from 'src/Redux/referral-process/referralList/selectors'

const Block = styled.div`
  display: flex;
  min-width: 224px;
  max-width: 224px;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 4px;
  background: ${colors.white};
  @media (min-width: 464px) {
    min-width: calc(33% - 8px);
    max-width: calc(33% - 8px);
  }
`

const Title = styled.div`
  color: ${colors.mainText};
  line-height: 150%;
`
const Count = styled.div`
  color: ${colors.mainText};
  font-size: ${variables.fontSize20};
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  margin-bottom: 16px;
  overflow: scroll;
  @media (min-width: 464px) {
    width: 100%;
    overflow: visible;
  }
`

export const BonusBlocks: FC = () => {
  const { t } = useTranslation()
  const total = useAppSelector(getTotalBonus)
  const bonusPerCampaign = useAppSelector(getBonusPerCampaign)
  const invitedArtists = useAppSelector(getInvitedArtists)
  return (
    <Wrapper>
      <Block>
        <Title>{t('referAndEarn.totalBonus')}</Title>
        <Count>{`$${total}`}</Count>
      </Block>
      <Block>
        <Title>{t('referAndEarn.totalArtistsInvited')}</Title>
        <Count>{invitedArtists}</Count>
      </Block>
      <Block>
        <Title>{t('referAndEarn.bonusPerCampaign')}</Title>
        <Count>{`$${bonusPerCampaign}`}</Count>
      </Block>
    </Wrapper>
  )
}
