import { useEffect } from 'react'

import { getSubscription } from 'src/Redux/subscription-process/subscription/api-action'
import { getSubscriptionSelector } from 'src/Redux/subscription-process/subscription/selectors'
import { Subscription } from 'src/Redux/subscription-process/subscription'

import { useAppDispatch, useAppSelector } from './redux'

export const useSubscription = (): Subscription | null => {
  const dispatch = useAppDispatch()
  const subscription = useAppSelector(getSubscriptionSelector)

  useEffect(() => {
    void dispatch(getSubscription())
  }, [dispatch])

  return subscription
}
