import { APP } from 'src/Configs/App'
import { rtkApi } from 'src/Services/rtkApi'

const orderPayoutApi = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    creatorOrderPayout: builder.mutation<unknown, void>({
      query: () => ({
        url: `${APP.TIKTOK_SERVER}/creator/payout/transferwise`,
        method: 'POST',
      }),
    }),
    curatorOrderPayout: builder.mutation<unknown, void>({
      query: () => ({
        url: `${APP.TIKTOK_SERVER}/curator/payout/transferwise`,
        method: 'POST',
      }),
    }),
  }),
})

export const { useCreatorOrderPayoutMutation, useCuratorOrderPayoutMutation } =
  orderPayoutApi
