import React from 'react'

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import Share from 'src/Assets/Svg/common/share.svg?react'
import {
  SquareButton,
  SquareButtonProps,
} from 'src/Components/Buttons/SquareButton'
import { MAX_MOBILE_WIDTH } from 'src/Styled/variables'

const StyledButton = styled(SquareButton)`
  padding: 0 12px;
  flex-grow: 0;

  @media (max-width: ${MAX_MOBILE_WIDTH}) {
    padding: 7px;
    span {
      display: none;
    }
  }
`

const ChildrenWrapper = styled.div`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  display: flex;
  gap: 8px;

  @media (max-width: ${MAX_MOBILE_WIDTH}) {
    span {
      display: none;
    }
  }
`

export type ShareButtonProps = Pick<
  SquareButtonProps,
  'loading' | 'onClick' | 'disabled'
>

export const ShareButton: React.FC<ShareButtonProps> = ({
  loading,
  onClick,
  disabled,
}) => {
  const { t } = useTranslation()

  return (
    <StyledButton loading={loading} disabled={disabled} onClick={onClick}>
      <ChildrenWrapper>
        {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
        <>
          <Share />
        </>
        <span>{t('components.shareButtonTitle')}</span>
      </ChildrenWrapper>
    </StyledButton>
  )
}
