import { FC, ButtonHTMLAttributes } from 'react'

import styled from 'styled-components'

import QuestionIcon from 'src/Assets/Svg/pages-icons/QuestionButton.svg?react'

const Button = styled.button`
  display: flex;
  justify-content: center;
  padding: 0;
  align-items: center;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.button.secondaryBg};
  border: none;
  outline: none;
  cursor: pointer;
  transition:
    transform 0.2s ease-in-out,
    background-color 0.2s ease-in-out;

  svg path {
    fill: ${({ theme }) => theme.button.thirdText};
  }

  &:hover:not(:disabled) {
    background-color: ${({ theme }) => theme.button.primaryBg};
  }

  &:focus-visible {
    outline: 2px solid ${({ theme }) => theme.button.thirdBg};
    background-color: ${({ theme }) => theme.button.primaryBg};
    transform: none;
  }

  &:active:not(:disabled) {
    svg path {
      fill: ${({ theme }) => theme.common.secondaryText};
    }
  }

  &:disabled {
    background-color: ${({ theme }) => theme.button.primaryBg};
    cursor: not-allowed;
    opacity: 0.5;
    svg path {
      fill: ${({ theme }) => theme.button.fourthText};
    }
  }
`

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  .QuestionIcon {
    width: 6px;
    height: 10px;
  }
`

export const QuestionButton: FC<ButtonHTMLAttributes<HTMLButtonElement>> = ({
  disabled,
  ...restProps
}) => (
  <Button disabled={disabled} {...restProps}>
    <IconWrapper>
      <QuestionIcon className='QuestionIcon' />
    </IconWrapper>
  </Button>
)
