import styled from 'styled-components'

import { variables, colors, device } from 'src/Styled/variables'

export const TracksListContainer = styled.div`
  display: grid;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 1.5rem;

  @media ${device.smallScreen} {
    padding-left: 0rem;
    padding-right: 0rem;
    grid-row-gap: 0px;
    padding-bottom: 24px;
  }
`

export const SoonContainer = styled.div`
  text-align: center;
  display: grid;
  grid-row-gap: 42.3px;
  padding-top: 24px;
  font-weight: ${variables.fontWeight400};
  font-size: ${variables.fontSize18};
  line-height: 150%;
  color: ${colors.mainText};
  justify-items: center;
  @media ${device.mobileMin} {
    padding-top: 45px;
    padding-bottom: 40px;
  }
`

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
