import React from 'react'

import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { campaignsList, balance } from 'src/Router/routes'
import Button from 'src/Components/Buttons/Button'
import { useHint } from 'src/Hooks/useHint'
import { Clock } from 'src/Assets/Svg'

import {
  ButtonBlock,
  CustomModal,
  SuccessBlock,
  SuccessCircle,
  Text,
  TitleWrapper,
} from './styles'

export const PaymentProcessingModal: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { setIsOpen } = useHint()

  return (
    <CustomModal>
      <SuccessBlock>
        <SuccessCircle>
          <Clock />
        </SuccessCircle>
        <TitleWrapper>
          <span> {t('scheduleCampaignPage.processing')}</span>
        </TitleWrapper>

        <Text>{t('scheduleCampaignPage.PaymentProcessingModalText')}</Text>

        <ButtonBlock>
          <Button
            type='white'
            onClick={() => {
              navigate(`${balance}`)
              setIsOpen()
            }}
          >
            {t('scheduleCampaignPage.goToBalance')}
          </Button>

          <Button
            type='green'
            onClick={() => {
              navigate(`${campaignsList}`)
              setIsOpen()
            }}
          >
            {t('scheduleCampaignPage.goToCampaigns')}
          </Button>
        </ButtonBlock>
      </SuccessBlock>
    </CustomModal>
  )
}
