import React, { FC } from 'react'

import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import party from 'src/Assets/Png/party.png'
import { PrimaryButton } from 'src/Components/Buttons/PrimaryButton'
import { FullScreenModal } from 'src/Components/FullScreenModal'
import { campaignsList } from 'src/Router/routes'
import SuccessIcon from 'src/Assets/Svg/pages-icons/Success.svg?react'
import { MAX_MOBILE_WIDTH } from 'src/Styled/variables'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
`

const Container = styled.div`
  display: flex;
  max-width: 358px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  @media screen and (min-width: ${MAX_MOBILE_WIDTH}) {
    max-width: 600px;
  }
`

const StyledImg = styled.img`
  width: 17px;
  height: 100%;
`
const Title = styled.div`
  color: ${({ theme }) => theme.common.primaryText};
  text-align: center;
  font-family: 'Segoe UI';
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
`

const Text = styled(Title)`
  font-size: 14px;
  font-weight: 400;
`

const StyledPrimaryButton = styled(PrimaryButton)`
  padding-left: 40px;
  padding-right: 40px;
`

export const SuccessCreatedCampaign: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const secondParagraph = t('createCampaignPage.weWillNotify')

  const handleCloseModal = (): void => {
    navigate(campaignsList)
  }
  return (
    <FullScreenModal open>
      <Wrapper>
        <Container>
          <SuccessIcon width={40} height={40} />
          <div>
            <Title>
              <span>{t('createCampaignPage.youCreatedYourCampaign!')}</span>
              <StyledImg src={party} alt='party' />
            </Title>

            <Text>
              {t(
                'createCampaignPage.ourA&RTeamWillReviewYourCampaignStayTuned',
              )}
            </Text>
            <Text>{secondParagraph}</Text>
          </div>

          <StyledPrimaryButton onClick={handleCloseModal}>
            {t('scheduleCampaignPage.goToCampaigns')}
          </StyledPrimaryButton>
        </Container>
      </Wrapper>
    </FullScreenModal>
  )
}
