import React, { forwardRef } from 'react'

import { FieldError } from 'react-hook-form'

import { StyledError, InputWrapper } from './styles'

interface SmartInputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  error?: FieldError
}

export const SmartInput = forwardRef<HTMLInputElement, SmartInputProps>(
  (props, ref) => {
    const { error, className, ...rest } = props
    return (
      <InputWrapper className={className}>
        <input {...rest} ref={ref} />
        {error && (
          <StyledError data-cy='login-form__form-group-error-render-field'>
            {error.message}
          </StyledError>
        )}
      </InputWrapper>
    )
  },
)

SmartInput.displayName = 'SmartInput'
