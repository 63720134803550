import { FC } from 'react'

import styled from 'styled-components'

import { colors, variables } from 'src/Styled/variables'

const Block = styled.div`
  background-color: ${colors.white};
  border-radius: 4px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  @media (max-width: 1023px) {
    min-width: 228px;
    max-width: 228px;
  }
  width: 100%;
  border-radius: 16px;
  border: 1px solid ${(props) => props.theme.common.primaryBorder};
  background-color: ${(props) => props.theme.common.primaryBg};
  box-shadow: 0px 0px 32px 0px rgba(51, 51, 51, 0.08);
`

const Title = styled.div`
  color: ${colors.mainText};
  font-family: 'Segoe UI';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`
const Count = styled.div`
  color: ${colors.mainText};
  font-size: ${variables.fontSize20};
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
`
export const BonusBlock: FC<{ title: string; value: string | number }> = ({
  title,
  value,
}) => (
  <Block>
    <Title>{title}</Title>
    <Count>{value}</Count>
  </Block>
)
