import { Modal } from 'antd'
import styled from 'styled-components'

import { colors, variables } from 'src/Styled/variables'
import { SmartImage } from 'src/Components/SmartImage'

export const Container = styled.div<{ active: boolean }>`
  flex: 1;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

export const Content = styled.div<{ active: boolean }>`
  width: 100%;
  height: 100%;
  visibility: ${({ active }) => (active ? 'visible' : 'hidden')};
  background-color: ${colors.white};
`

export const IconContainer = styled.div`
  display: flex;
  svg {
    fill: ${colors.green};
  }
`

export const SearchContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px;
  height: 56px;
  border-bottom: 1px solid ${colors.greyBlue};
`

export const BackButton = styled.div`
  padding: 14px;
  width: 40px;
  height: 40px;
`

export const ClearButton = styled.div`
  padding: 14px;
  width: 40px;
  height: 40px;
`

export const SearchInput = styled.input`
  display: flex;
  flex: 1;
  border: none;
  outline: none;
  padding: 10px 0;

  &::placeholder {
    color: ${colors.greyTextWhiteBg};
  }
`

export const Playlist = styled.div`
  display: flex;
  padding: 16px;
`

export const PlaylistAvatar = styled(SmartImage)`
  width: 40px;
  height: 40px;
  border-radius: 4px;
`

export const PlaylistInfo = styled.div`
  margin-left: 16px;
`

export const PlaylistTitle = styled.p`
  margin: 0;
  color: ${colors.mainText};
  font-size: ${variables.fontSize14};
`

export const PlaylistAuthor = styled.p`
  margin: 0;
  color: ${colors.mainText};
  font-size: ${variables.fontSize10};
`

export const AlertWrapper = styled.div`
  margin: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const SearchingWrapper = styled.div`
  margin: 24px;
  display: flex;
  justify-content: center;
  color: ${colors.greyTextWhiteBg};
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight400};
`

export const MeetConditionsBtn = styled.a`
  color: ${colors.messegesYellow1} !important;
  border-bottom: 1px dashed ${colors.messegesYellow1};
  text-decoration: none;
  text-align: center;
  margin-left: 12.5px;
  margin-top: -25px;

  &:hover {
    color: ${colors.messegesYellow1};
  }
`
export const ButtonBody = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`
export const Wrapper = styled.div`
  width: 70%;
`

export const StyledModal = styled(Modal)`
  position: fixed;
  top: 0 !important;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0;
  margin: 0;
  width: 100vw !important;
  max-width: 100vh !important;

  .ant-modal-content {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: ${colors.white};
    border-radius: 0;

    .ant-modal-close {
      display: none;
    }

    .ant-modal-body {
      padding: 0;
      width: 100%;
      border-radius: 0 0 8px 8px;
    }
    .ant-modal-footer {
      display: none;
    }
  }
`
