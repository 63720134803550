import { FC } from 'react'

import styled from 'styled-components'

import { colors, device } from 'src/Styled/variables'
import {
  CampaignPlatformTypeEnum,
  CampaignTypeEnum,
  TikTokTrack,
} from 'src/Types'
import { CustomScrollbarWrapper } from 'src/Components/CustomScrollbarWrapper'
import { TrackAudioPlayer } from 'src/Components/TrackAudioPlayer'
import { onPlaySearchedTrack } from 'src/Helpers/TagManager/TrackEvent'

import { WidgetListLoading } from '../WidgetListLoading'
import { WidgetListError } from '../WidgetListError'

import { TracksWidgetListItem } from './TracksWidgetListItem'
interface TiktokWidgetListProps {
  loading: boolean
  error?: string
  tracks: TikTokTrack[]
}

const WrapperList = styled(CustomScrollbarWrapper)<{ fitContent: boolean }>`
  height: ${({ fitContent }) => (fitContent ? 'fit-content' : '400px')};
`
const Wrapper = styled.div`
  border-radius: 8px;
  background: ${colors.white};
  padding: 4px 4px 0 4px;
  @media ${device.mobileMax} {
    margin-bottom: 50px;
  }
`
const LoadingWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  gap: 4px;
  height: 400px;
  overflow: hidden;
`
const MIN_TRACKS_COUNT = 5
export const TiktokWidgetList: FC<TiktokWidgetListProps> = ({
  loading,
  error,
  tracks,
}) => {
  if (loading) {
    return (
      <LoadingWrapper>
        <WidgetListLoading />
      </LoadingWrapper>
    )
  }
  if (error) {
    return (
      <Wrapper>
        <WidgetListError error={error} />
      </Wrapper>
    )
  }

  if (!tracks.length) {
    return null
  }
  return (
    <Wrapper>
      <TrackAudioPlayer
        render={({
          isPlaying,
          loading: innerLoading,
          currentTrackUrl,
          onClick,
        }) => (
          <WrapperList fitContent={tracks.length <= MIN_TRACKS_COUNT}>
            {tracks.map((track) => (
              <TracksWidgetListItem
                trackId={track.trackTiktokId}
                key={track.trackUrl}
                author={track.trackAuthorName}
                image={track.trackCoverUrl}
                trackName={track.trackTitle}
                time={track.trackDuration}
                isPlaying={isPlaying && currentTrackUrl === track.trackPlayUrl}
                loading={innerLoading && currentTrackUrl === track.trackPlayUrl}
                onClick={(e) => {
                  e.preventDefault()
                  onClick(e, track.trackPlayUrl)
                  if (!isPlaying) {
                    onPlaySearchedTrack(
                      CampaignTypeEnum.FIRST,
                      null,
                      CampaignPlatformTypeEnum.TIKTOK,
                    )
                  }
                }}
              />
            ))}
          </WrapperList>
        )}
      />
    </Wrapper>
  )
}
