import { FunctionComponent, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { Popover } from 'antd'

import Button from 'src/Components/Buttons/Button'
import ModalLayout from 'src/Components/ModalLayout'
import { Desktop, Mobile } from 'src/Components/Responsive'
import IconQuestionMark from 'src/Assets/Svg/questionMark.svg?react'
import { CampaignTypeEnum } from 'src/Types/index'

import { RelaunchedCampaignInfoHintProps } from './RelaunchedCampaignInfoHintProps'

import {
  CampaignType,
  CenteredBlock,
  GlobalStyled,
  HintText,
  InnerContainer,
  StyledHintSignInner,
  Wrapper,
  WrapperInner,
} from './styles'

export const RelaunchedCampaignInfoHint: FunctionComponent<
  RelaunchedCampaignInfoHintProps
> = ({ type }) => {
  const { t } = useTranslation()

  const [isVisibleCuratorsHint, setVisibleCuratorsHint] = useState(false)

  return (
    <InnerContainer>
      {type === CampaignTypeEnum.RELAUNCHED ? (
        <>
          <CampaignType>{t('campaignsPage.relaunched')}</CampaignType>
          <StyledHintSignInner
            data-testid='hint-sign'
            onClick={() => setVisibleCuratorsHint(!isVisibleCuratorsHint)}
          >
            <IconQuestionMark width={7} height={14} />
          </StyledHintSignInner>
        </>
      ) : null}
      <GlobalStyled />
      <Desktop>
        <Popover
          overlayClassName='overlayClassName'
          overlayInnerStyle={{ borderRadius: '8px' }}
          open={isVisibleCuratorsHint}
          trigger={['click', 'focus']}
          placement='rightBottom'
          content={
            <Wrapper>
              <WrapperInner>
                <HintText>
                  {t('campaignsPage.curatorsFromPreviousCampaignAreExcluded')}
                </HintText>
              </WrapperInner>
              <CenteredBlock>
                <Button
                  type='whiteWithGreenBorder'
                  onClick={() => {
                    setVisibleCuratorsHint(!isVisibleCuratorsHint)
                  }}
                >
                  {t('scheduleCampaignPage.gotIt')}
                </Button>
              </CenteredBlock>
            </Wrapper>
          }
          onOpenChange={() => setVisibleCuratorsHint(!isVisibleCuratorsHint)}
        />
      </Desktop>
      <Mobile>
        <ModalLayout
          visible={isVisibleCuratorsHint}
          width={288}
          handleClose={() => setVisibleCuratorsHint(!isVisibleCuratorsHint)}
          header
        >
          <Wrapper>
            <WrapperInner>
              {t('campaignsPage.curatorsFromPreviousCampaignAreExcluded')}
            </WrapperInner>
            <CenteredBlock>
              <Button
                type='whiteWithGreenBorder'
                onClick={() => setVisibleCuratorsHint(!isVisibleCuratorsHint)}
              >
                {t('scheduleCampaignPage.gotIt')}
              </Button>
            </CenteredBlock>
          </Wrapper>
        </ModalLayout>
      </Mobile>
    </InnerContainer>
  )
}
