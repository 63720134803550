import styled from 'styled-components'

import { variables, colors } from 'src/Styled/variables'

export const PolicyLinks = styled.div`
  margin-left: 0.6rem 0 0 1.5rem;
  margin-top: 9px;
  color: ${colors.mainText};
  text-align: left;
  a {
    border-bottom: 1px dashed ${colors.messegesGreen1};
    color: ${colors.messegesGreen1};
  }
  padding-left: 24px;
`
export const OrangeAlert = styled.div`
  font-weight: ${variables.fontWeight600};
  font-size: ${variables.fontSize14};
  line-height: 21px;
  color: ${colors.messegesYellow1};
  padding-top: 24px;
  text-align: center;
`
export const TimeLeftInfo = styled.span`
  color: ${colors.greyTextWhiteBg};
`
export const inlineStyles = {
  checkboxLabel: {
    textAlign: 'left',
  },
}
