import { APP } from 'src/Configs/App'
import { rtkApi } from 'src/Services/rtkApi'

interface GetBillingAgreementResponse {
  paypalEmail?: string
  id?: number
}
interface CreateBillingAgreementResponse {
  link: string
}

interface CreateBillingAgreementRequest {
  cancelUrl: string
  successUrl: string
}
const billingAgreementApi = rtkApi.injectEndpoints({
  endpoints: (build) => ({
    getBillingAgreement: build.query<GetBillingAgreementResponse, void | null>({
      query: () =>
        `${APP.TIKTOK_SERVER}/payment/paypal/saved-billing-agreement`,
    }),
    createBillingAgreement: build.mutation<
      CreateBillingAgreementResponse,
      CreateBillingAgreementRequest
    >({
      query: (body) => ({
        url: `${APP.TIKTOK_SERVER}/payment/paypal/billing-agreements/tokens`,
        method: 'POST',
        body,
      }),
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetBillingAgreementQuery,
  useCreateBillingAgreementMutation,
} = billingAgreementApi
