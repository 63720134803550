import { FunctionComponent } from 'react'

import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import CheckboxAntd from 'src/Containers/ui/CheckboxAntd'
import { tikTokVideoPolicy } from 'src/Constants/links'

import { PolicyLinks, inlineStyles } from '../AcceptTrack/styles'

import { PolicyProps } from './PolicyProps'

const AcceptPolicy: FunctionComponent<PolicyProps> = ({
  isRulesPage,
  isSuccessAcceptedPage,
  policyAccepted,
  toggleAcceptPolicy,
}) => {
  const { t } = useTranslation()

  if (isRulesPage && !isSuccessAcceptedPage) {
    return (
      <div>
        <CheckboxAntd
          checked={policyAccepted}
          title={String(t('influencer.acceptPolicy'))}
          style={inlineStyles.checkboxLabel as Record<string, string | number>}
          onChange={() => toggleAcceptPolicy()}
        />
        <PolicyLinks>
          <Link to={tikTokVideoPolicy} target='_blank'>
            {t('influencer.policy')}
          </Link>
          &nbsp;
          {t('influencer.beforeAccepting')}
        </PolicyLinks>
      </div>
    )
  }

  return null
}

export default AcceptPolicy
