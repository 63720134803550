import { createSlice } from '@reduxjs/toolkit'

import {
  Review,
  ReviewStatus,
  ArtistFeedback,
  NewTrack,
  FeedMatchedPlaylist,
  FeedDetailsTrack,
} from 'src/Types/index'
import { setApiError } from 'src/Hooks/redux'

import { ApiError, NameSpace } from '../types'

import {
  getFeedTracks,
  getReviewedFeedTracks,
  reserveReview,
  sendFeedReview,
  skipFeedReview,
} from './api-actions'

interface FeedDetails {
  id: number
  campaign_id: string
  rank: number
  review_status: ReviewStatus
  matched_playlists: Array<FeedMatchedPlaylist>
  potential_reward: number
  track: FeedDetailsTrack
  review_feedback: ArtistFeedback
  comment: string
  curator_rating: number
  addedTo: FeedMatchedPlaylist
  isRejectLastThree: boolean
  isReserved: boolean
}

export interface FeedCampaignsState {
  tracks: Array<NewTrack>
  reviewedTracks: Array<Review>
  tracksPageCount: number
  reviewedTracksPageCount: number
  reviewedCurrentPage: number
  totalFeed: number
  totalReviewed: number
  feedDetails: FeedDetails
  isTimeUp: boolean
  isLimitReached: boolean
  loading: boolean
  error: ApiError | null
}

const initialState: FeedCampaignsState = {
  tracks: [],
  reviewedTracks: [],
  tracksPageCount: 0,
  reviewedTracksPageCount: 0,
  reviewedCurrentPage: 0,
  totalFeed: 0,
  totalReviewed: 0,
  feedDetails: {
    isRejectLastThree: false,
    isReserved: false,
    campaign_id: '',
    rank: 0,
    id: 0,
    matched_playlists: [],
    potential_reward: 0,
    comment: '',
    curator_rating: 0,
    review_status: '' as ReviewStatus,
    track: {
      id: 0,
      track_name: '',
      artist_name: '',
      track_url: '',
      genres: [],
      languages: [],
    },
    review_feedback: '' as ArtistFeedback,
    addedTo: {
      id: 0,
      playlist_name: '',
      playlist_cover: '',
      matched_percent: 0,
      score: 0,
    },
  },

  isTimeUp: false,
  isLimitReached: false,
  loading: false,
  error: null,
}

export const feedProcess = createSlice({
  initialState,
  name: NameSpace.Feed,
  reducers: {},
  extraReducers: (builder) => {
    // getFeedTracks
    builder.addCase(getFeedTracks.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getFeedTracks.fulfilled, (state, action) => {
      state.loading = false
      state.error = null
      if (action.payload.meta.pageNumber === 1) {
        state.tracks = action.payload.data.reviews.map(
          (track) => track.campaign,
        )
      } else {
        state.tracks = [
          ...state.tracks,
          ...action.payload.data.reviews.map((track) => track.campaign),
        ]
      }
      state.tracksPageCount = action.payload.meta.pageCount
      state.totalFeed = action.payload.meta.itemCount
      state.isLimitReached = action.payload.data.isLimitReached
    })
    builder.addCase(getFeedTracks.rejected, (state, action) => {
      state.loading = false
      state.error = setApiError(action)
    })

    // getReviewedFeedTracks
    builder.addCase(getReviewedFeedTracks.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getReviewedFeedTracks.fulfilled, (state, action) => {
      state.loading = false
      state.error = null
      if (action.payload.currentPage === 1) {
        state.reviewedTracks = action.payload.reviews
      } else {
        state.reviewedTracks = [
          ...state.reviewedTracks,
          ...action.payload.reviews,
        ]
      }
      state.reviewedTracksPageCount = action.payload.pageCount
      state.reviewedCurrentPage = action.payload.currentPage
      state.totalReviewed = action.payload.total
    })
    builder.addCase(getReviewedFeedTracks.rejected, (state, action) => {
      state.loading = false
      state.error = setApiError(action)
    })

    // sendFeedReview
    builder.addCase(sendFeedReview.pending, (state) => {
      state.loading = true
    })
    builder.addCase(sendFeedReview.fulfilled, (state) => {
      state.loading = false
      state.error = null
    })
    builder.addCase(sendFeedReview.rejected, (state, action) => {
      state.loading = false
      state.error = setApiError(action)
    })

    // skipFeedReview
    builder.addCase(skipFeedReview.pending, (state) => {
      state.loading = true
    })
    builder.addCase(skipFeedReview.fulfilled, (state) => {
      state.loading = false
      state.error = null
    })
    builder.addCase(skipFeedReview.rejected, (state, action) => {
      state.loading = false
      state.error = setApiError(action)
    })

    // reserveReview
    builder.addCase(reserveReview.pending, (state) => {
      state.loading = true
    })
    builder.addCase(reserveReview.fulfilled, (state) => {
      state.loading = false
      state.error = null
    })
    builder.addCase(reserveReview.rejected, (state, action) => {
      state.loading = false
      state.error = setApiError(action)
    })
  },
})
