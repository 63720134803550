import React, { FC } from 'react'

import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { useHint } from 'src/Hooks/useHint'
import { balance, campaignsList } from 'src/Router/routes'
import { MAX_MOBILE_WIDTH } from 'src/Styled/variables'
import ExclamationIcon from 'src/Assets/Svg/pages-icons/ExclamationPayment.svg?react'

import { FullScreenModal } from '../FullScreenModal'
import { PrimaryDottedButton } from '../Buttons/PrimaryDottedButton'
import { PrimaryButton } from '../Buttons/PrimaryButton'

const SuccessBlock = styled.div`
  display: flex;
  max-width: 600px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  @media screen and (max-width: ${MAX_MOBILE_WIDTH}) {
    max-width: 358px;
  }
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
`
const Title = styled.div`
  color: ${({ theme }) => theme.common.primaryText};
  text-align: center;
  font-family: 'Segoe UI';
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
`
const Text = styled(Title)`
  font-size: 14px;
  font-weight: 400;
`
const ButtonBlock = styled.div`
  align-self: center;
  display: flex;
  flex-direction: row;
  gap: 16px;

  @media screen and (max-width: ${MAX_MOBILE_WIDTH}) {
    flex-direction: column;
  }
`

const StyledPrimaryButton = styled(PrimaryButton)`
  padding-left: 40px;
  padding-right: 40px;
`

export const SchedulePaymentProcessingModal: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { setIsOpen } = useHint()
  return (
    <FullScreenModal open>
      <Wrapper>
        <SuccessBlock>
          <ExclamationIcon width={40} height={40} />
          <Title>{t('scheduleCampaignPage.processing')}</Title>

          <Text>{t('scheduleCampaignPage.PaymentProcessingModalText')}</Text>

          <ButtonBlock>
            <PrimaryDottedButton
              onClick={() => {
                navigate(`${balance}`)
                setIsOpen()
              }}
            >
              {t('scheduleCampaignPage.goToBalance')}
            </PrimaryDottedButton>

            <StyledPrimaryButton
              onClick={() => {
                navigate(`${campaignsList}`)
                setIsOpen()
              }}
            >
              {t('scheduleCampaignPage.goToCampaigns')}
            </StyledPrimaryButton>
          </ButtonBlock>
        </SuccessBlock>
      </Wrapper>
    </FullScreenModal>
  )
}
