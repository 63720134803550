import styled from 'styled-components'

import { variables, colors } from 'src/Styled/variables'

interface StyledComponentsProps {
  collapse: boolean
  isShownOnMobile?: boolean
  isMobile?: boolean
}

export const SoundLinkItem = styled.a<StyledComponentsProps>`
  height: 40px;
  cursor: pointer;
  align-items: center;
  border: none;
  display: ${({ isShownOnMobile, isMobile }) =>
    isMobile && !isShownOnMobile ? 'none' : 'flex'};
  padding: ${({ collapse }) => (!collapse ? '12px 16px' : '12px 20px')};
  background-color: ${colors.black};
  width: 100%;
  transition: all 1s ease-out;
  position: relative;
  @media (min-width: 576px) {
    margin-bottom: 16px;
  }

  &::after {
    content: ' ';
    position: absolute;
    z-index: 1001;
    top: 0;
    right: -7px;
    width: 10px;
    height: 100%;
    clip-path: polygon(2.5px 0.2px, 10px 19.38px, 10px 19.38px, 2.5px 40px);
    background-color: ${colors.black};
  }
`

export const SidebarLinkItem = styled.li<StyledComponentsProps>`
  color: ${colors.greyStroke};
  height: 40px;
  transition: all 0.2s;
  cursor: pointer;
  align-items: center;
  background: transparent;
  border: none;
  display: flex;
  padding: ${({ collapse }) => (!collapse ? '12px 16px' : '12px 20px')};
  &:hover {
    background-color: ${colors.greyBg};
  }
`

export const SidebarLinkText = styled.div<StyledComponentsProps>`
  position: relative;
  color: ${colors.greyTextWhiteBg};
  margin: 0;
  font-size: ${variables.fontSize14};
  line-height: 21px;
  display: flex;
  left: 10px;
  width: 160px;
  &:hover {
    color: ${colors.mainText};
  }
  opacity: ${({ collapse }) => (collapse ? '1' : '0')};
  transition: all 0.2s ease-out;
  white-space: nowrap;
  overflow: hidden;
`
export const SidebarSoundLinkText = styled.div<StyledComponentsProps>`
  position: relative;
  margin: 0;
  font-size: ${variables.fontSize12};
  line-height: 21px;
  display: flex;
  left: 10px;
  width: 160px;
  &:hover {
    color: ${colors.mainText};
  }
  opacity: ${({ collapse }) => (collapse ? '1' : '0')};
  overflow: hidden;
  transition: all 0.2s ease-out;
  white-space: nowrap;
  text-transform: uppercase;
  letter-spacing: 7.5px;
`

export const Green = styled.span`
  color: ${colors.green};
`
export const White = styled.span`
  color: ${colors.white};
`

export const SoundIcon = styled.div`
  display: grid;
  margin-right: 2px;
  & img {
    width: 16px;
    height: 16px;
  }
`

export const SidebarIconBlock = styled.div`
  margin-right: 6px;
  color: ${colors.greyTextWhiteBg};
  & img {
    width: 16px;
    height: 16px;
  }

  & a:visited {
    color: ${colors.greyTextWhiteBg};
  }
`
export const Bottom = styled.div<StyledComponentsProps>`
  margin-top: auto;
  @media (max-height: 415px) {
    transition: all 1s ease-out;
    transform: ${({ collapse }) =>
      collapse ? 'translateY(-220px)' : 'translateY(0)'};
  }
`
export const BottomInner = styled.div`
  border-top: 1px solid ${colors.greyStroke};
`
