import { FC } from 'react'

import { useSpotifyCampaignDetails } from 'src/Redux/campaignDetailsApi/useSpotifyCampaignDetails'

import { CampaignDesktopHeader } from './CampaignDesktopHeader'
import { CampaignMobileHeader } from './CampaignMobileHeader'
import { RegularCampaignHeader } from './RegularCampaignHeader'

export const CampaignHeader: FC = () => {
  const { campaignDetails } = useSpotifyCampaignDetails()
  if (!campaignDetails?.is_feed) {
    return <RegularCampaignHeader />
  }

  return (
    <>
      <CampaignDesktopHeader />
      <CampaignMobileHeader />
    </>
  )
}
