import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { useAppSelector } from 'src/Hooks/redux'
import TikTokIcon from 'src/Assets/Svg/tiktok.svg?react'
import { APP } from 'src/Configs/App'
import { InfluencerRegisterEnum } from 'src/Types'
import { colors, variables } from 'src/Styled/variables'
import { getApplicantUpdateEmailError } from 'src/Redux/creatorRegister-process/selectors'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'

import { translateInfluencer } from '../..'
import { TikTokBtnLink } from '../../styles'

import { ErrorReflection } from './ErrorReflection'

const Text2 = styled.div`
  font-size: ${variables.fontSize14};
  color: ${colors.mainText};
  line-height: 21px;
  display: grid;
  gap: 16px;
  margin-bottom: 24px;
  @media (min-width: 576px) {
    margin-bottom: 16px;
  }
`

const TextError = styled.div`
  text-align: left;
  line-height: 21px;
`

const ErrorContainer = styled.div`
  display: flex;
  font-display: row;
  color: ${colors.messegesRed};
  align-items: flex-start;
  margin-bottom: 16px;
  text-align: left;
`

interface LoginCreatorErrorProps {
  status: InfluencerRegisterEnum
}

export const LoginCreatorError: FC<LoginCreatorErrorProps> = ({ status }) => {
  const { t } = useTranslation()
  const authError = useAppSelector(getApplicantUpdateEmailError)
  const { search } = useCustomSearchParams()
  const reason = search.get('reason') || ''

  if (status !== InfluencerRegisterEnum.ERROR) {
    return null
  }

  return (
    <>
      {!authError && <Text2>{t(translateInfluencer)}</Text2>}

      {reason && (
        <ErrorContainer>
          <TextError>
            <ErrorReflection text={reason} />
          </TextError>
        </ErrorContainer>
      )}

      <TikTokBtnLink href={APP.TIKTOK_AUTH_API}>
        <TikTokIcon width={'12.9px'} height={'14.55px'} />
        {t('influencer.connectWithTikTok')}
      </TikTokBtnLink>
    </>
  )
}
