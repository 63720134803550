import { FC, useEffect } from 'react'

import { CheckboxChangeEvent } from 'antd/es/checkbox'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Checkbox } from 'src/Containers/ui'
import { PaymentMethodEnum } from 'src/Constants/enums'
import { useAppDispatch } from 'src/Hooks/redux'
import { changeRememberPayPalForFutureFlag } from 'src/Redux/paypalTransactions-process'
import { getRememberPayPalForFutureFlag } from 'src/Redux/paypalTransactions-process/selectors'

import { Content, ContentWrapper } from './styles'

interface PayPalCheckboxProps {
  paymentMethod: PaymentMethodEnum
}

export const PayPalCheckbox: FC<PayPalCheckboxProps> = ({ paymentMethod }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const isRememberPayPalForFuture = useSelector(getRememberPayPalForFutureFlag)

  useEffect(() => {
    paymentMethod !== PaymentMethodEnum.STRIPE && isRememberPayPalForFuture
      ? localStorage.setItem(
          'currentPaymentMethod',
          PaymentMethodEnum.PAYPAL_BILLING_AGREEMENT,
        )
      : localStorage.setItem('currentPaymentMethod', PaymentMethodEnum.PAYPAL)

    paymentMethod === PaymentMethodEnum.STRIPE &&
      localStorage.removeItem('currentPaymentMethod')
  }, [dispatch, isRememberPayPalForFuture, paymentMethod])

  const onRememberPayPalCheckboxChange = (e: CheckboxChangeEvent): void => {
    dispatch(changeRememberPayPalForFutureFlag(e.target.checked))
  }
  if (paymentMethod === PaymentMethodEnum.PAYPAL) {
    return (
      <Content active={true}>
        <ContentWrapper>
          <Checkbox
            value='rememberPayPal'
            onChange={onRememberPayPalCheckboxChange}
            checked={isRememberPayPalForFuture}
            title={String(t('scheduleCampaignPage.rememberForFuture'))}
          />
        </ContentWrapper>
      </Content>
    )
  }
  return null
}
