import { createSlice } from '@reduxjs/toolkit'

import { ApiError, NameSpace, SliceStatus } from 'src/Redux/types'
import { setApiError } from 'src/Hooks/redux'

import { checkUserEmailToSignIn } from './api-actions'

export interface AuthTypeState {
  authType: string
  email: string
  status: SliceStatus
  error: ApiError | null
}
const initialState: AuthTypeState = {
  authType: 'login',
  email: '',
  status: SliceStatus.Idle,
  error: null,
}
export const authTypeSlice = createSlice({
  initialState,
  name: NameSpace.AuthType,
  reducers: {
    setLoginTypeToInitialState: (state) => {
      state.authType = 'login'
      state.email = ''
    },
  },
  extraReducers(builder) {
    builder.addCase(checkUserEmailToSignIn.pending, (state) => {
      state.status = SliceStatus.Loading
    })
    builder.addCase(checkUserEmailToSignIn.fulfilled, (state, action) => {
      state.status = SliceStatus.Succeeded
      state.authType = action.payload.type
      state.email = action.meta.arg
    })
    builder.addCase(checkUserEmailToSignIn.rejected, (state, action) => {
      state.status = SliceStatus.Failed
      state.error = setApiError(action)
    })
  },
})

export const { setLoginTypeToInitialState } = authTypeSlice.actions
