import React, { FC } from 'react'

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { ScheduleSteps } from 'src/Components/ScheduleTabs'
import { ModalType } from 'src/Containers/ScheduleSpotifyWrapper/components/ModalsContainer'
import { useAppSelector } from 'src/Hooks/redux'
import { getScheduledTikTokCampaign } from 'src/Redux/schedule-process/tiktokCampaign/selectors'
import PlusIcon from 'src/Assets/Svg/plus.svg?react'
import { colors, device } from 'src/Styled/variables'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import {
  ParametersButton,
  SubTitle,
} from 'src/Containers/ScheduleSpotifyWrapper/components/SpotifySettings/TrackParametrs/styles'

export const Comment = styled.div`
  color: ${colors.mainText};
  word-break: break-all;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`

export const CommentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
  padding-left: 16px;
  @media ${device.tabletMin} {
    display: grid;
    grid-template-columns: 1fr 72px;
    align-items: center;
    gap: 16px;
    justify-content: center;
  }
`

export const CommentParameters: FC = () => {
  const { t } = useTranslation()
  const scheduledCampaign = useAppSelector(getScheduledTikTokCampaign)
  const { setSearch } = useCustomSearchParams()

  const handleClick = (): void => {
    const query = {
      step: ScheduleSteps.SETTINGS,
      modal: ModalType.Comment,
    }
    setSearch(query)
  }
  if (!scheduledCampaign.comment) {
    return (
      <ParametersButton onClick={handleClick}>
        <PlusIcon />
        {t('scheduleCampaignPage.addTaskForCreators')}
      </ParametersButton>
    )
  }
  return (
    <CommentContainer>
      <div>
        <SubTitle>{t('scheduleCampaignPage.commentToCreators')}</SubTitle>
        <Comment>{scheduledCampaign.comment}</Comment>
      </div>

      <ParametersButton onClick={handleClick}>
        {t('scheduleCampaignPage.edit')}
      </ParametersButton>
    </CommentContainer>
  )
}
