import styled from 'styled-components'

import { colors, device, variables } from 'src/Styled/variables'

export const CoverImageWrapper = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
`
export const CounterWrapper = styled.div`
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: ${variables.fontSize10};
  font-weight: ${variables.fontWeight600};
  color: ${colors.white};
  backdrop-filter: blur(1.5px);
  background: rgba(0, 0, 0, 0.5);
  transition: all 0.3s linear;

  &:hover {
    background: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(1px);
    color: ${colors.green};
    transition: all 0.3s linear;
    transition-property: backdrop-filter, color, background;
  }
`
export const Container = styled.div<{ isDetailsMode: boolean }>`
  display: flex;
  flex-direction: row;
  gap: 4px;

  @media ${device.mobileMax} {
    padding: ${({ isDetailsMode }) => (isDetailsMode ? '5px' : '0')};
    align-items: center;
    gap: 9px;
  }
`
export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: ${variables.fontSize14};
`
export const ScoreValue = styled.div`
  font-size: ${variables.fontSize10};
`
