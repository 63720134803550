import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { renderRole } from 'src/Constants/functions'
import avatar from 'src/Assets/Png/ava.png'
import {
  getIsArtist,
  getUserRole,
} from 'src/Redux/auth-process/credentialsSlice/selectors'
import {
  getIsDeactivatedCurator,
  getUserRoleToReflect,
  getUserSelector,
} from 'src/Redux/auth-process/userSlice/selectors'

import { SubscriptionInfo } from './SubscriptionInfo'
import { CuratorValidation } from './CuratorValidation/CuratorValidation'

import {
  DeactivatedLabel,
  SidebarAvatar,
  SidebarImg,
  SidebarTitleName,
  SidebarTitleRole,
  UserInfoWrapper,
} from './styles'

interface SideBarTopProps {
  collapse: boolean
}

const SideBarTop: FC<SideBarTopProps> = ({ collapse }) => {
  const { t } = useTranslation()
  const user = useSelector(getUserSelector)
  const artistRole = useSelector(getUserRoleToReflect)
  const userRole = useSelector(getUserRole)
  const isArtist = useSelector(getIsArtist)
  const isDeactivated = useSelector(getIsDeactivatedCurator)
  return (
    <UserInfoWrapper isMenuOpened={!collapse}>
      <SidebarAvatar>
        <SidebarImg
          alt='avatar'
          src={user.user_photo_url}
          fallbackSrc={avatar}
        />
        <SidebarTitleName>
          {user.first_name} {user.last_name}
        </SidebarTitleName>
        {userRole && (
          <SidebarTitleRole>
            {renderRole(userRole, t, artistRole)}
          </SidebarTitleRole>
        )}
        {isDeactivated && (
          <DeactivatedLabel>({t('sidebar.deactivated')})</DeactivatedLabel>
        )}
        <CuratorValidation />
        {isArtist && <SubscriptionInfo />}
      </SidebarAvatar>
    </UserInfoWrapper>
  )
}

export default SideBarTop
