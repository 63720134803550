import { forwardRef } from 'react'

import SelectType from 'react-select/dist/declarations/src/Select'
import { CSSObject } from 'styled-components'
import { useMediaQuery } from 'react-responsive'

import StarYellowIcon from 'src/Assets/Svg/star-yellow.svg?react'
import StarGreyIcon from 'src/Assets/Svg/star-grey.svg?react'

import { SelectResponseRatingProps, RatingOption } from './props'

import {
  Select,
  SelectRatingOption,
  StarWrapper,
  StyledPlaceholder,
} from './styles'

const reviewResponseRatingOptions: Array<RatingOption> = [
  {
    value: 5,
    label: (
      <SelectRatingOption data-testid='rating-5'>
        <StarYellowIcon width={16} height={16} />
        <StarYellowIcon width={16} height={16} />
        <StarYellowIcon width={16} height={16} />
        <StarYellowIcon width={16} height={16} />
        <StarYellowIcon width={16} height={16} />
      </SelectRatingOption>
    ),
  },
  {
    value: 4,
    label: (
      <SelectRatingOption data-testid='rating-4'>
        <StarYellowIcon width={16} height={16} />
        <StarYellowIcon width={16} height={16} />
        <StarYellowIcon width={16} height={16} />
        <StarYellowIcon width={16} height={16} />
        <StarGreyIcon width={16} height={16} />
      </SelectRatingOption>
    ),
  },
  {
    value: 3,
    label: (
      <SelectRatingOption data-testid='rating-3'>
        <StarYellowIcon width={16} height={16} />
        <StarYellowIcon width={16} height={16} />
        <StarYellowIcon width={16} height={16} />
        <StarGreyIcon width={16} height={16} />
        <StarGreyIcon width={16} height={16} />
      </SelectRatingOption>
    ),
  },
  {
    value: 2,
    label: (
      <SelectRatingOption data-testid='rating-2'>
        <StarYellowIcon width={16} height={16} />
        <StarYellowIcon width={16} height={16} />
        <StarGreyIcon width={16} height={16} />
        <StarGreyIcon width={16} height={16} />
        <StarGreyIcon width={16} height={16} />
      </SelectRatingOption>
    ),
  },
  {
    value: 1,
    label: (
      <SelectRatingOption data-testid='rating-1'>
        <StarYellowIcon width={16} height={16} />
        <StarGreyIcon width={16} height={16} />
        <StarGreyIcon width={16} height={16} />
        <StarGreyIcon width={16} height={16} />
        <StarGreyIcon width={16} height={16} />
      </SelectRatingOption>
    ),
  },
]

const SelectResponseRatingComponent = forwardRef<
  SelectType<RatingOption>,
  SelectResponseRatingProps
>(({ onChange, value, text }, ref) => {
  const isMobile = useMediaQuery({ maxWidth: 576 })

  const customStyles: Record<string, (provided: CSSObject) => CSSObject> = {
    menu: (provided) => ({
      ...provided,
      width: '100vw',
    }),
  }
  return (
    <Select
      ref={ref}
      styles={isMobile ? customStyles : {}}
      isSearchable={false}
      options={reviewResponseRatingOptions || []}
      placeholder={
        <StyledPlaceholder>
          <StarWrapper>
            <StarYellowIcon width={16} height={16} />
          </StarWrapper>
          <span>{text}</span>
        </StyledPlaceholder>
      }
      classNamePrefix='review-rating'
      value={reviewResponseRatingOptions.find((c) => c.value === value)}
      onChange={(newValue) => {
        onChange && newValue && onChange(newValue.value)
      }}
    />
  )
})

SelectResponseRatingComponent.displayName = 'SelectResponseRatingComponent'

export default SelectResponseRatingComponent
