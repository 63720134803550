import styled from 'styled-components'

import { colors, device } from 'src/Styled/variables'

export const Link = styled.a`
  color: ${colors.deepBlue};
  font-weight: 600;
  text-decoration: underline;
`
export const Title = styled.h2`
  margin-bottom: 29px;
  color: black !important;
`
export const ButtonWrapper = styled.div`
  margin: 40px auto 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  div {
    width: 160px;
  }
`
export const Body = styled.div`
  background-color: ${colors.white};
  text-align: center;
  padding: 20px;
  padding-bottom: 50px;
  border-radius: 5px;

  svg {
    height: 170px;
    width: 280px;
    transition: 0.3s;
  }
`

export const Container = styled.div`
  margin-top: 3%;
  user-select: none;
  max-width: 1120px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  @media ${device.mobileMin} {
    padding: 0 40px;
  }
`
