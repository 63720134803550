import styled from 'styled-components'
import { Tabs } from 'antd'

import { variables, colors, device } from 'src/Styled/variables'

interface Props {
  approved?: boolean
}

export const Card = styled.div`
  width: 100%;
  padding-bottom: 30px;
  @media ${device.smallScreen} {
    background-color: ${colors.white};
  }
`

export const StyledList = styled.ul`
  width: 100%;
  padding-left: 0px;
`
export const StyledNoData = styled.div`
  text-align: center;
`

export const StyledTabsWrapper = styled.div`
  .ant-tabs-nav-more > span {
    display: none;
  }
  .ant-tabs-tab {
    padding: 10px 0px;
    @media ${device.smallScreen} {
      padding: 12px 0px;
    }
  }

  .ant-tabs-nav-list {
    border-bottom: 1px solid ${colors.greyStroke};
  }
  .ant-tabs-card > .ant-tabs-nav::before {
    display: none;
  }
  .ant-tabs-card .ant-tabs-tab,
  [data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-tab {
    background: transparent;
    border-color: transparent;
  }
  .ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: none;
  }
  .ant-tabs-nav-list {
    box-shadow: none !important;
  }
  .ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::before {
    box-shadow: none !important;
  }
  .ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::after {
    box-shadow: none !important;
  }
  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0;
  }
`

export const StyledTabs = styled(Tabs)`
  .ant-tabs-nav-list {
    box-shadow: none !important;
  }
  .ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::before {
    box-shadow: none !important;
  }
  .ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::after {
    box-shadow: none !important;
  }
  padding-left: 0;
  @media ${device.smallScreen} {
    padding-left: 24px;
    padding-right: 24px;
  }
  .ant-tabs-tab-btn {
    padding: 0px 8px 0 16px;
    @media ${device.smallScreen} {
      padding: 0px 16px 0 24px;
    }
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-size: ${variables.fontSize14};
    line-height: 21px;
  }
  .ant-tabs-ink-bar {
    background: ${colors.green};
  }
  .ant-tabs-nav {
    margin-bottom: 31px;
    @media ${device.smallScreen} {
      margin-bottom: 24px;
    }
  }
`

export const TabName = styled.span`
  color: ${colors.mainText};
  margin-right: 8px;
`

export const StyledNumber = styled.span`
  color: ${(props: Props) =>
    props.approved ? colors.green : colors.greyTextBlackBg};
`
