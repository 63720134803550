import { useEffect } from 'react'

import { useSelector } from 'react-redux'

import { useAppDispatch } from 'src/Hooks/redux'
import { getSchedulePlatform } from 'src/Redux/campaign-process/spotifyCampaignSchedule-process/selectors'

import { useTrackSearch } from './useTrackSearch'

export const useTrackSearchListeners = (): void => {
  const dispatch = useAppDispatch()

  const platform = useSelector(getSchedulePlatform)
  const { setTracksToInitialStateHandler } = useTrackSearch()

  useEffect(() => {
    const handleOutsideClick = (): void => {
      setTracksToInitialStateHandler()
    }
    document.addEventListener('click', handleOutsideClick)
    return () => {
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [setTracksToInitialStateHandler])

  useEffect(() => {
    const handleEsc = (event: KeyboardEvent): void => {
      if (event.key === 'Escape') {
        setTracksToInitialStateHandler()
      }
    }
    window.addEventListener('keydown', handleEsc)

    return () => {
      window.removeEventListener('keydown', handleEsc)
    }
  }, [platform, dispatch, setTracksToInitialStateHandler])
}
