import styled from 'styled-components'

import { CustomScrollbarWrapper } from 'src/Components/CustomScrollbarWrapper'
import { device } from 'src/Styled/variables'

export const ImageCurator = styled.div`
  img {
    border-radius: 50%;
    height: 40px;
    width: 40px;
    object-fit: cover;
  }
`
export const NotificationsItemCurator = styled.div`
  display: grid;
  position: relative;
  grid-template-columns: 40px 1fr;
  gap: 8px;
  margin-bottom: 16px;
  color: #646877;
`

export const NotificationsWrapper = styled(CustomScrollbarWrapper)<{
  isSmallNotificationsContainer: boolean
}>`
  height: ${({ isSmallNotificationsContainer }) =>
    isSmallNotificationsContainer ? 'fit-content' : '280px'}
  }
  @media ${device.mobileMax} {
    height: 50vh;
  }
`
