import React, { FC, PropsWithChildren } from 'react'

import styled, { css } from 'styled-components'
import ReactLoading from 'react-loading'

import PauseIcon from 'src/Assets/Svg/pages-icons/Pause.svg?react'
import PlayIcon from 'src/Assets/Svg/pages-icons/Play.svg?react'

const Container = styled.div`
  position: relative;
  cursor: pointer;
`
const iconStyles = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const StyledIconPause = styled(PauseIcon)`
  ${iconStyles}
`
const StyledIcon = styled(PlayIcon)`
  ${iconStyles}
`
const StyledLoader = styled(ReactLoading)`
  ${iconStyles}
`

type PrimaryTrackCoverWrapperProps = PropsWithChildren & {
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void
  loading: boolean
  isPlaying: boolean
  loaderSize?: string
  iconSize?: string
  className?: string
  show?: boolean
}

export const PrimaryTrackCoverWrapper: FC<PrimaryTrackCoverWrapperProps> = ({
  children,
  loading,
  isPlaying,
  onClick,
  loaderSize = '25px',
  iconSize = '25px',
  className,
  show = true,
}) => {
  if (!show) {
    return (
      <Container onClick={onClick} className={className}>
        {children}
      </Container>
    )
  }
  return (
    <Container className={className} onClick={onClick}>
      {children}

      {loading && (
        <StyledLoader type='spin' width={loaderSize} height={loaderSize} />
      )}

      {isPlaying ? (
        <StyledIconPause width={iconSize} height={iconSize} />
      ) : (
        <StyledIcon width={iconSize} height={iconSize} />
      )}
    </Container>
  )
}
