import React, { FC } from 'react'

import { Skeleton } from 'antd'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { SkeletonNodeProps } from 'antd/es/skeleton/Node'

import { MAX_MOBILE_WIDTH, variables } from 'src/Styled/variables'
import avatar from 'src/Assets/Png/ava.png'
import { SmartImage } from 'src/Components/SmartImage'
import TikTokIcon from 'src/Assets/Svg/platform-icons/TikTok.svg?react'
import { SquareButton } from 'src/Components/Buttons/SquareButton'
import { VideoStatusBadge } from 'src/Components/VideoStatus'
import { PremoderationStatus, VideoStatus } from 'src/Constants/enums'
import { addSpaceToNumber } from 'src/Helpers/layoutHelpers'
import { EventDismissReasonsEnum } from 'src/Redux/creatorSubmittedSoundsApi'
import { TikTokCampaignPricingModelEnum } from 'src/Types'

import { ViewsProgressBar } from '../ViewsProgressBar'

export const AvatarImage = styled(SmartImage)`
  border-radius: 50%;
  height: 40px;
  width: 40px;
  object-fit: cover;
`

const Container = styled.div`
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  align-self: stretch;

  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.common.primaryBorder};
  background-color: ${({ theme }) => theme.common.primaryBg};
  box-shadow: 0px 0px 32px 0px rgba(51, 51, 51, 0.08);

  @media (max-width: ${MAX_MOBILE_WIDTH}) {
    box-shadow: none;
    border: none;
    padding: 0;
  }
`

const HeaderWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`

const VideoDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: stretch;
`

const CreatorInfoWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
`

const CreatorNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
`

const Name = styled.span`
  color: ${({ theme }) => theme.common.primaryText};
  text-align: center;
  font-size: ${variables.fontSize14};
  font-style: normal;
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
  max-width: 400px;
`

const Text = styled.span`
  color: ${({ theme }) => theme.common.primaryText};
  text-align: center;
  font-size: ${variables.fontSize14};
  font-style: normal;
  font-weight: ${variables.fontWeight400};
  line-height: 150%;
  max-width: 400px;
`

const Title = styled.span`
  color: ${({ theme }) => theme.common.primaryText};
  font-size: ${variables.fontSize18};
  font-style: normal;
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
  flex-grow: 1;
`

const StyledButton = styled(SquareButton)`
  padding: 7px;

  @media (max-width: ${MAX_MOBILE_WIDTH}) {
    flex-grow: 0;
  }
`

const StyledSkeleton = styled(({ className, ...rest }: SkeletonNodeProps) => (
  <Skeleton.Input {...rest} rootClassName={className} active />
))`
  &.ant-skeleton-block {
    border-radius: 16px;
  }
  &.ant-skeleton {
    flex-grow: 1 !important;
    @media (max-width: ${MAX_MOBILE_WIDTH}) {
      width: 100% !important;
    }
  }
  & > .ant-skeleton-input {
    width: 100% !important;
    height: 240px !important;
    border-radius: 16px !important;
  }
`

interface VideoDetailsProps {
  userPhotoUrl?: string
  firstName?: string
  followers?: number
  currentViews?: number
  minViews?: number
  videoStatus?: VideoStatus
  videoPublishedDate?: string
  milestoneDate?: string
  dismissReason?: EventDismissReasonsEnum
  loading?: boolean
  pricingModel?: TikTokCampaignPricingModelEnum
  videoPreModerationStatus?: PremoderationStatus
  tiktokProfileUrl?: string
}

export const VideoDetails: FC<VideoDetailsProps> = ({
  userPhotoUrl,
  firstName,
  followers,
  videoStatus,
  currentViews = 0,
  minViews = 0,
  videoPublishedDate = '',
  milestoneDate = '',
  dismissReason,
  loading,
  pricingModel,
  tiktokProfileUrl,
  videoPreModerationStatus,
}) => {
  const { t } = useTranslation()

  if (loading) {
    return <StyledSkeleton />
  }

  if (!videoStatus) {
    return null
  }

  const formattedFollowers = followers ? addSpaceToNumber(followers, ',') : '-'

  const isViewsBasedModel =
    pricingModel === TikTokCampaignPricingModelEnum.ViewsBased

  return (
    <Container>
      <HeaderWrapper>
        <CreatorInfoWrapper>
          <AvatarImage alt='avatar' src={userPhotoUrl} fallbackSrc={avatar} />
          <CreatorNameWrapper>
            <Name>{`${firstName}`}</Name>
            <Text>
              {`${formattedFollowers} ${t('campaignDetailsPage.followers')}`}
            </Text>
          </CreatorNameWrapper>
        </CreatorInfoWrapper>

        <a href={tiktokProfileUrl} target='_blank' rel='noopener noreferrer'>
          <StyledButton>
            <TikTokIcon />
          </StyledButton>
        </a>
      </HeaderWrapper>
      {isViewsBasedModel && (
        <VideoDetailsContainer>
          <TitleWrapper>
            <Title>{t('campaignDetailsPage.videoDetails')}</Title>
            <VideoStatusBadge
              videoPreModerationStatus={videoPreModerationStatus}
            />
          </TitleWrapper>
          <ViewsProgressBar
            currentViews={currentViews}
            minViews={minViews}
            videoPublishedDate={videoPublishedDate}
            milestoneDate={milestoneDate}
            status={videoStatus}
            dismissReason={dismissReason}
          />
        </VideoDetailsContainer>
      )}
    </Container>
  )
}
