import React, { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'

import {
  AccountValidationTitle,
  AccountValidationTextBlock,
  AccountValidationText,
} from './styles'

const AccountValidation: FunctionComponent = () => {
  const { t } = useTranslation()

  return (
    <>
      <AccountValidationTitle>
        {t('sidebar.accountValidationPeriod')}
      </AccountValidationTitle>

      <AccountValidationTextBlock>
        <AccountValidationText>
          &#8226;&nbsp;{t('sidebar.whenYouJustRegistered')}
        </AccountValidationText>

        <AccountValidationText>
          &#8226;&nbsp;{t('sidebar.yourAccountNeedToBeValidated')}
        </AccountValidationText>

        <AccountValidationText>
          &#8226;&nbsp;{t('sidebar.youCanStillEarnExtraScores')}
        </AccountValidationText>
      </AccountValidationTextBlock>
    </>
  )
}

export default AccountValidation
