import { FC, useState } from 'react'

import { useTranslation } from 'react-i18next'

import IconSpotify from 'src/Assets/Svg/spotify.svg?react'
import { Desktop, Mobile } from 'src/Components/Responsive'
import { useSpotifyCampaignDetails } from 'src/Redux/campaignDetailsApi/useSpotifyCampaignDetails'

import CampaignGenres from '../../CampaignGenres'
import CampaignLanguagesComponent from '../../CampaignLanguages'
import { ProgressBarByTime } from '../../ProgressBarByTime'

import { CuratorsCalculation } from './CuratorsCalculation/CuratorsCalculation'
import { DetailsMobile } from './DetailsMobile/DetailsMobile'
import { Credits } from './Credits/Credits'

import {
  CampaignCalculation,
  CampaignCalculationLabel,
  CampaignCalculationsContainer,
  CampaignCalculationValue,
  GenresAndLanguagesContainer,
  SpotifyIconContainer,
  ToggleDetails,
  TrackAuthor,
  TrackAvatar,
  TrackAvatarMobile,
  TrackInfoColumnMobile,
  TrackInfoContainer,
  TrackInfoContainerMobile,
  TrackInfoLabelMobile,
  TrackInfoRowMobile,
  TrackInfoTextMobile,
  TrackTitle,
  TrackTitleContainer,
} from './styles'

export const RegularCampaignHeader: FC = () => {
  const { t } = useTranslation()
  const { campaignDetails } = useSpotifyCampaignDetails()
  const [showDetailsMobile, setShowDetailsMobile] = useState<boolean>(false)

  const toggleDetailsMobile = (): void =>
    setShowDetailsMobile(!showDetailsMobile)

  return (
    <>
      <Desktop>
        <TrackInfoContainer>
          <SpotifyIconContainer>
            <IconSpotify />
          </SpotifyIconContainer>
          <TrackAvatar
            src={campaignDetails?.Track.track_image}
            alt={campaignDetails?.Track.track_name}
          />
          <TrackTitleContainer>
            <TrackTitle>{campaignDetails?.Track.track_name}</TrackTitle>
            <TrackAuthor>{campaignDetails?.Track.artist_name}</TrackAuthor>
          </TrackTitleContainer>
          <CampaignCalculationsContainer>
            <CampaignCalculation>
              <CampaignCalculationLabel>
                {t('campaignResultsPage.budgetLabel')}:{' '}
              </CampaignCalculationLabel>
              <CampaignCalculationValue>{`$${String(
                campaignDetails?.budget,
              )}`}</CampaignCalculationValue>
            </CampaignCalculation>
            <CuratorsCalculation
              curatorsCount={campaignDetails?.curators_count}
              curatorsCountMax={campaignDetails?.curators_count_max}
            />
            <CampaignCalculation>
              <CampaignCalculationLabel>
                {t('campaignResultsPage.playlistsLabel')}:{' '}
              </CampaignCalculationLabel>
              <CampaignCalculationValue>
                {campaignDetails?.playlists_count}
                {campaignDetails?.playlists_count_max
                  ? `-${campaignDetails?.playlists_count_max}`
                  : ''}
              </CampaignCalculationValue>
            </CampaignCalculation>
            <Credits
              status={campaignDetails?.status}
              refund={campaignDetails?.refund}
            />
          </CampaignCalculationsContainer>

          {campaignDetails && !campaignDetails.is_feed && (
            <ProgressBarByTime campaignDetails={campaignDetails} />
          )}

          <GenresAndLanguagesContainer>
            <CampaignGenres genres={campaignDetails?.Track?.TrackGenres} />
            <CampaignLanguagesComponent
              languages={campaignDetails?.Track.TrackLanguages}
            />
          </GenresAndLanguagesContainer>
        </TrackInfoContainer>
      </Desktop>
      <Mobile>
        <TrackInfoContainerMobile>
          <TrackAvatarMobile>
            <TrackAvatar
              src={campaignDetails?.Track.track_image}
              alt={campaignDetails?.Track.track_name}
            />
            <SpotifyIconContainer>
              <IconSpotify />
            </SpotifyIconContainer>
          </TrackAvatarMobile>
          <TrackInfoColumnMobile>
            <TrackInfoRowMobile>
              <TrackInfoLabelMobile>
                {t('campaignResultsPage.trackName')}
              </TrackInfoLabelMobile>
              <TrackInfoTextMobile>
                {campaignDetails?.Track.track_name}
              </TrackInfoTextMobile>
            </TrackInfoRowMobile>
            <TrackInfoRowMobile>
              <TrackInfoLabelMobile>
                {t('campaignResultsPage.artist')}
              </TrackInfoLabelMobile>
              <TrackInfoTextMobile>
                {campaignDetails?.Track.artist_name}
              </TrackInfoTextMobile>
            </TrackInfoRowMobile>
            {showDetailsMobile && (
              <DetailsMobile campaignDetails={campaignDetails} />
            )}
            <ToggleDetails onClick={toggleDetailsMobile}>
              {showDetailsMobile
                ? t('campaignResultsPage.hideDetails')
                : t('campaignResultsPage.showDetails')}
            </ToggleDetails>
          </TrackInfoColumnMobile>
        </TrackInfoContainerMobile>
      </Mobile>
    </>
  )
}
