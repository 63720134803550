import { FunctionComponent } from 'react'

import { UserProtectedProps } from './props'

const ArtistProtected: FunctionComponent<UserProtectedProps> = ({
  component: Component,
  email,
  ...rest
}) => <Component email={email} {...rest} />

export default ArtistProtected
