import { InfluencerRegisterEnum } from 'src/Types'

export const validateRegisterStatus = (
  userStatus: string,
): InfluencerRegisterEnum => {
  if (
    Object.values(InfluencerRegisterEnum).includes(
      userStatus as InfluencerRegisterEnum,
    )
  ) {
    return userStatus as InfluencerRegisterEnum
  }

  return InfluencerRegisterEnum.JOIN
}
