import styled from 'styled-components'

import { colors, variables } from 'src/Styled/variables'

export const ButtonsWrapper = styled.div`
  width: fit-content;
  margin: 20px auto;
  .ant-btn {
    padding: 0 40px;
  }
`
export const ReviewWrapper = styled.div`
  margin-top: 16px;
`

export const RatingWrapper = styled.div`
  display: flex;
  width: fit-content;
  margin-bottom: 16px;
  text-align: left;
`

export const Title = styled.div`
  font-size: ${variables.fontSize10};
  color: ${colors.mainText};
  line-height: 15px;
  text-align: left;
`

export const Text = styled.div`
  font-weight: ${variables.fontWeight400};
  font-size: ${variables.fontSize14};
  line-height: 21px;
  color: ${colors.mainText};
  text-align: left;
`

export const Claimed = styled.div`
  color: ${colors.messegesRed};
  text-align: center;
  font-size: ${variables.fontSize14};
  font-style: normal;
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
  margin-top: 16px;
`
