import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'

import { api } from 'src/Services/api'
import { APP } from 'src/Configs/App'
import { CreatorTransaction, Pagination } from 'src/Types'

import { NameSpace } from '../types'

interface CreatorTransactionsData {
  data: Array<CreatorTransaction>
  meta: Pagination
}

export const getCreatorTransactions = createAsyncThunk<
  CreatorTransactionsData,
  number
>(
  `${NameSpace.Transactions}/getCreatorTransactions`,
  async (pageNumber, { rejectWithValue }) => {
    try {
      const { data } = await api.get<CreatorTransactionsData>(
        `${APP.TIKTOK_SERVER}/payout/balance-history-page?pageNumber=${pageNumber}&pageSize=9999`,
      )
      return data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      return rejectWithValue({
        message: response?.data?.message,
        code: response?.status,
      })
    }
  },
)
interface TransactionsDetailsData {
  hasAwaitingPayout: boolean
  earnedTotalAmount: number
  payoutTotalAmount: number
  isRecipient: boolean
}
export const getTransactionsDetails = createAsyncThunk<TransactionsDetailsData>(
  `${NameSpace.Transactions}/getTransactionsDetails`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await api.get<TransactionsDetailsData>(
        `${APP.TIKTOK_SERVER}/payout/balance-details`,
      )
      return data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      return rejectWithValue({
        message: response?.data?.message,
        code: response?.status,
      })
    }
  },
)
