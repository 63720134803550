import React from 'react'

import ChevronIcon from 'src/Assets/Svg/chevron-right.svg?react'

import { DetailsButton, ChevronIconWrapper } from './ShowMoreButton.styled'

export interface ShowMoreButtonProps {
  isOpened: boolean
  toggle?: (e?: React.MouseEvent) => void
  openedTitle: string
  closedTitle: string
}

export const ShowMoreButton: React.FC<ShowMoreButtonProps> = ({
  openedTitle,
  closedTitle,
  toggle,
  isOpened,
}) => (
  <DetailsButton onClick={toggle}>
    {isOpened ? openedTitle : closedTitle}
    <ChevronIconWrapper isDetailsOpened={isOpened}>
      <ChevronIcon />
    </ChevronIconWrapper>
  </DetailsButton>
)
