import { FC } from 'react'

import WrenchIcon from 'mdi-react/WrenchIcon'
import { Col, Row } from 'antd'

import { UserManagementPanel } from './components/UserManagementPanel'

import { Body, Title } from './styles'
const AdminPage: FC = () => (
  <div>
    <Row>
      <Col md={12}>
        <Title>
          <WrenchIcon /> Admin Page
        </Title>
      </Col>
    </Row>
    <Body>
      <UserManagementPanel />
    </Body>
  </div>
)

export default AdminPage
