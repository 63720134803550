import React, { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'

import Alert from 'src/Components/Alert'

import { PaymentErrorsAlertProps } from './PaymentErrorsAlertProps'

import { AlertText } from './styles'

export enum PaymentErrors {
  ACCOUNT_BALANCE = 'balance',
  PAYPAL_FUNDS = 'ppbalance',
  PROMO_CODE = 'coupon',
  REFERENCE_TRANSACTION = 'referenceTransaction',
  CHANGE_METHOD = 'paypal-error-change-payment-method',
  DEFAULT = 'default',
}

const PaymentErrorsAlert: FunctionComponent<PaymentErrorsAlertProps> = ({
  name,
}) => {
  const { t } = useTranslation()

  const renderRespondError = (errorName: PaymentErrors): string => {
    switch (errorName) {
      case PaymentErrors.ACCOUNT_BALANCE:
        return t('scheduleCampaignPage.paymentBalanceError')
      case PaymentErrors.PAYPAL_FUNDS:
        return t('scheduleCampaignPage.paymentPayPalInsufficientFundsError')
      case PaymentErrors.PROMO_CODE:
        return t('scheduleCampaignPage.paymentPromoCodeError')
      case PaymentErrors.REFERENCE_TRANSACTION:
        return t('scheduleCampaignPage.billingTransactionError')
      case PaymentErrors.CHANGE_METHOD:
        return t('scheduleCampaignPage.paymentProcessingError')
      default:
        return t('scheduleCampaignPage.paymentDefaultError')
    }
  }

  return (
    <Alert color='danger' icon unclosable middleGap>
      <AlertText>{renderRespondError(name)}</AlertText>
    </Alert>
  )
}

export default PaymentErrorsAlert
