import styled from 'styled-components'

import { device } from 'src/Styled/variables'

export const LogoHeader = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 150.07px 1fr;
  justify-items: self-end;
  height: 60px;

  @media ${device.mobileMin} {
    display: grid;
  }
`
export const TotalCloseButton = styled.button`
  width: 40px;
  height: 40px;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  svg {
    height: 15px;
    width: 15px;
  }
`
