import styled from 'styled-components'

import { colors, variables } from 'src/Styled/variables'

export const TrackNameRow = styled.div`
  display: flex;
  align-items: center;
  max-width: 150px;
`

export const IconWrapper = styled.div`
  margin-right: 16px;
  width: 24px;
  height: 24px;
`

export const RowText = styled.span<{ bold?: boolean }>`
  font-size: ${variables.fontSize14};
  line-height: 21px;
  font-weight: ${({ bold }) =>
    bold ? variables.fontWeight600 : variables.fontWeight400};
  color: ${colors.mainText};
`
