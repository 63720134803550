import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { CampaignPlatformTypeEnum } from 'src/Types'
import party from 'src/Assets/Png/party.png'
import IconSuccessfully from 'src/Assets/Svg/successfully.svg?react'
import Button from 'src/Components/Buttons/Button'
import { campaignsList } from 'src/Router/routes'

import {
  ButtonWrapper,
  SuccessBlock,
  SuccessButtonBlock,
  SuccessCircle,
  SuccessText,
  SuccessTitle,
  TrackSubmittedAlertModal,
} from './styles'
interface SuccessCreateCampaignProps {
  platform?: CampaignPlatformTypeEnum
}
export const SuccessCreateCampaign: FC<SuccessCreateCampaignProps> = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const secondParagraph = t('createCampaignPage.weWillNotify')

  const handleCloseModal = (): void => {
    navigate(campaignsList)
  }
  return (
    <TrackSubmittedAlertModal>
      <SuccessBlock>
        <SuccessCircle>
          <IconSuccessfully />
        </SuccessCircle>
        <SuccessTitle>
          {t('createCampaignPage.youCreatedYourCampaign!')}
          <img src={party} alt='party' />
        </SuccessTitle>

        <SuccessText>
          {t('createCampaignPage.ourA&RTeamWillReviewYourCampaignStayTuned')}
        </SuccessText>
        <SuccessText>{secondParagraph}</SuccessText>

        <SuccessButtonBlock>
          <ButtonWrapper>
            <Button type='white' onClick={handleCloseModal}>
              {t('scheduleCampaignPage.goToCampaigns')}
            </Button>
          </ButtonWrapper>
        </SuccessButtonBlock>
      </SuccessBlock>
    </TrackSubmittedAlertModal>
  )
}
