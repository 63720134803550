import { Drawer } from 'antd'
import styled from 'styled-components'
import { DrawerProps } from 'antd/lib'

import { colors } from 'src/Styled/variables'

interface StyledComponentsProps {
  isMenuOpened?: boolean
  isAlertMode?: boolean
}

interface ExtendedDrawerProps extends DrawerProps, StyledComponentsProps {}

export const StyledScrollbar = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  @media (max-height: 550px) {
    overflow: auto;
  }
`
export const StyledDrawer = styled((props: ExtendedDrawerProps) => (
  <Drawer rootClassName={props.className} {...props} />
))`
  z-index: 200 !important;
  .ant-drawer-content-wrapper {
    box-shadow: none;
    width: 240px !important;

    @media (min-width: 768px) and (max-width: 1023px) {
      width: ${({ isMenuOpened }) =>
        isMenuOpened ? '240px' : '60px'} !important;
      transition: width 1s ease-in-out !important;
    }
  }
`

export const SidebarWrapper = styled.div<StyledComponentsProps>`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 1;
  width: ${({ isMenuOpened }) => (isMenuOpened ? '240px' : '60px')};
  padding-top: ${({ isAlertMode }) => (isAlertMode ? '93px' : '60px')};
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  transition: width 1s ease-in-out !important;
`

export const Overlay = styled.div`
  height: 100%;
  width: 100%;
  overflow: auto;
  overscroll-behavior-y: contain;
`
