import React, { ReactElement } from 'react'

import CustomModal from '../CustomModal'

import { ModalLayoutProps } from './ModalLayoutProps'

import { AntdModal } from './styles'

const ModalLayout: React.FC<ModalLayoutProps> = ({
  header,
  hint,
  content,
  visible,
  children,
  width,
  promocode,
  handleClose,
}) => {
  const DEFAULT_MODAL_WIDTH = 600

  const renderModal = (): ReactElement => (
    <CustomModal
      header={header}
      hint={hint}
      content={content}
      promocode={promocode}
      handleClose={handleClose}
    >
      {children}
    </CustomModal>
  )

  return (
    <AntdModal
      open={visible}
      width={width || DEFAULT_MODAL_WIDTH}
      modalRender={renderModal}
      onCancel={handleClose}
    />
  )
}
export default ModalLayout
