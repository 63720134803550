import { FunctionComponent } from 'react'

import { useMediaQuery } from 'react-responsive'

import IconShareFeedback from 'src/Assets/Svg/shareFeedback.svg?react'
import { useAppDispatch } from 'src/Hooks/redux'
import { changeMobileSidebarVisibility } from 'src/Redux/sidebar-process'

import { SidebarArtistSurveyProps } from './SidebarArtistSurveyProps'

import {
  SidebarLinkItem,
  SidebarIconBlock,
  SidebarLinkText,
  StyledPopupButton,
} from './styles'

const SidebarArtistSurvey: FunctionComponent<SidebarArtistSurveyProps> = ({
  collapse,
  userEmail,
  id,
  text,
}) => {
  const dispatch = useAppDispatch()
  const isTablet = useMediaQuery({ maxWidth: 1023 })

  const onChangeMobileSidebarVisibility = (): void => {
    isTablet && dispatch(changeMobileSidebarVisibility())
  }
  return (
    <SidebarLinkItem
      collapse={collapse}
      onClick={onChangeMobileSidebarVisibility}
    >
      <StyledPopupButton
        id={id}
        hidden={{
          email: userEmail,
          is_feed: 'yes',
        }}
      >
        <SidebarIconBlock>
          <IconShareFeedback width={16} height={16} />
        </SidebarIconBlock>
        <SidebarLinkText collapse={!collapse}>{text}</SidebarLinkText>
      </StyledPopupButton>
    </SidebarLinkItem>
  )
}
export default SidebarArtistSurvey
