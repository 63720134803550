import { FunctionComponent, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { Radio, RadioChangeEvent } from 'antd'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { useExistingBillingAgreement } from 'src/Hooks/useExistingBillingAgreement'
import { useAppDispatch } from 'src/Hooks/redux'
import { ShowMoreContainer } from 'src/Components/ShowMoreContainer/ShowMoreContainer'
import { CampaignPlatformTypeEnum } from 'src/Types'
import { PaymentMethodEnum } from 'src/Constants/enums'
import { setPaymentMethod } from 'src/Redux/payment-process'
import { PayPalLogoSmall } from 'src/Assets/Svg'
import { obfuscateEmail } from 'src/Helpers/layoutHelpers'
import { getPaymentMethod } from 'src/Redux/payment-process/selectors'
import { APP } from 'src/Configs/App'
import { useGetBillingAgreementQuery } from 'src/Redux/billingAgreementApi'

import { usePaymentMethodChanged } from './usePaymentMethodChanged'
import { StripeImages } from './StripeImages'
import { PayPalCheckbox } from './PayPalCheckbox'
import { MorePaymentButton } from './MorePaymentButton'
import { StyledRadioExp } from './PaymentMethodsByStripeOnly'

import {
  ContainerPayment,
  MorePaymentContainer,
  PaymentMethodsListWrapper,
  PayPalLogoContainer,
  RadioGroupContainer,
  RadioLabelContainer,
  StepTitle,
  StyledImgWrapper,
  StyledRadio,
  StyledSpan,
} from './styles'

interface PaymentMethodsProps {
  currentPlatform: CampaignPlatformTypeEnum
  isEnoughBalance: boolean
}

const StyledRadioLabelContainer = styled(RadioLabelContainer)`
  padding: 10px 0;
`

const getFormattedEmail = (email?: string | null): string | null => {
  if (!email) {
    return null
  }
  return obfuscateEmail(email)
}
export const PaymentMethods: FunctionComponent<PaymentMethodsProps> = ({
  currentPlatform,
  isEnoughBalance,
}) => {
  const dispatch = useAppDispatch()
  useExistingBillingAgreement()

  const { t } = useTranslation()

  const [isMorePaymentMethodsShowed, setIsMorePaymentMethodsShowed] =
    useState<boolean>(false)

  const { data: billingData } = useGetBillingAgreementQuery()
  const paymentMethod = useSelector(getPaymentMethod)

  const { setIsPaymentMethodChangedSent } = usePaymentMethodChanged()

  const onChangePaymentOption = (e: RadioChangeEvent): void => {
    const currentPaymentMethod = e?.target?.value as PaymentMethodEnum
    dispatch(setPaymentMethod(currentPaymentMethod))

    localStorage.setItem('currentPaymentMethod', currentPaymentMethod)

    setIsPaymentMethodChangedSent(currentPaymentMethod, currentPlatform)
  }

  const onMorePaymentClick = (): void => {
    setIsMorePaymentMethodsShowed((prevState) => !prevState)
  }

  const formattedEmail = getFormattedEmail(billingData?.paypalEmail)

  const isPayPalTransactions =
    billingData?.paypalEmail &&
    !isEnoughBalance &&
    APP.IS_PAYPAL_TRANSACTIONS_ENABLED

  return (
    <RadioGroupContainer>
      <Radio.Group value={paymentMethod} onChange={onChangePaymentOption}>
        {isPayPalTransactions && (
          <ContainerPayment>
            <StepTitle>
              {t('scheduleCampaignPage.savedPaymentMethod')}
            </StepTitle>
            <StyledRadio>
              <Radio
                disabled={isEnoughBalance}
                value={PaymentMethodEnum.PAYPAL_BILLING_AGREEMENT}
              >
                <PayPalLogoContainer>
                  <StyledImgWrapper>
                    <PayPalLogoSmall />
                  </StyledImgWrapper>
                  <span>{formattedEmail}</span>
                </PayPalLogoContainer>
              </Radio>
            </StyledRadio>
          </ContainerPayment>
        )}

        <MorePaymentContainer
          withGap={isMorePaymentMethodsShowed || isEnoughBalance}
        >
          <MorePaymentButton
            showpayPalTransactionEmail={
              Boolean(billingData?.paypalEmail) && !isEnoughBalance
            }
            isMorePaymentMethodsShowed={isMorePaymentMethodsShowed}
            onMorePaymentClick={onMorePaymentClick}
          />

          <ShowMoreContainer
            isContentOpened={
              isMorePaymentMethodsShowed ||
              isEnoughBalance ||
              !billingData?.paypalEmail ||
              !APP.IS_PAYPAL_TRANSACTIONS_ENABLED
            }
          >
            <PaymentMethodsListWrapper>
              {isEnoughBalance && (
                <StyledRadioExp value={PaymentMethodEnum.Credits}>
                  <StyledRadioLabelContainer>
                    <StyledSpan>
                      {t('scheduleCampaignPage.creditsFromBalance')}
                    </StyledSpan>
                  </StyledRadioLabelContainer>
                </StyledRadioExp>
              )}
              <div>
                <StyledRadioExp
                  disabled={isEnoughBalance}
                  value={PaymentMethodEnum.STRIPE}
                >
                  <RadioLabelContainer>
                    <StyledSpan>
                      {t('scheduleCampaignPage.creditAndDebitCards')}
                    </StyledSpan>
                    <StripeImages />
                  </RadioLabelContainer>
                </StyledRadioExp>
              </div>

              <div>
                <StyledRadio>
                  <Radio
                    disabled={isEnoughBalance}
                    value={PaymentMethodEnum.PAYPAL}
                    data-testid='paypal-without-saving'
                  >
                    <PayPalLogoContainer>
                      <StyledImgWrapper>
                        <PayPalLogoSmall />
                      </StyledImgWrapper>
                    </PayPalLogoContainer>
                  </Radio>
                </StyledRadio>
                <PayPalCheckbox paymentMethod={paymentMethod} />
              </div>
            </PaymentMethodsListWrapper>
          </ShowMoreContainer>
        </MorePaymentContainer>
      </Radio.Group>
    </RadioGroupContainer>
  )
}
