import styled from 'styled-components'

import { variables, colors, device } from 'src/Styled/variables'
import Button from 'src/Components/Buttons/Button'

export const PageTitleContainer = styled.div`
  display: flex;
  align-items: center;
`

export const PageTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  align-items: center;
`

export const PageTitle = styled.h3`
  font-size: ${variables.fontSize20};
  font-weight: ${variables.fontWeight600};
  color: ${colors.mainText};
  line-height: 150%;
`

export const BackButton = styled.div`
  display: flex;
  align-items: center;
  padding-right: 22px;
  padding-left: 14px;
  width: 48px;
  height: 12px;
  cursor: pointer;
`

export const CreateCampaignButton = styled(Button)`
  display: flex;
  align-items: center;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 12px 35px;
  background: ${colors.white};
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  height: 64px;
  z-index: 100;
  @media ${device.mobileMin} {
    width: fit-content;
    position: relative;
    padding: 0px;
    background: transparent;
    box-shadow: none;
    height: 0px;
  }
  button {
    display: flex;
    gap: 8px;
    padding: 9.5px 40px;
    align-items: center;
    justify-content: center;
  }
`
