import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import IconSuccessfully from 'src/Assets/Svg/successfully.svg?react'
import { InfluencerRegisterEnum } from 'src/Types'
import Button from 'src/Components/Buttons/Button'
import { useAppDispatch } from 'src/Hooks/redux'
import { setAccessToken } from 'src/Redux/auth-process/credentialsSlice'
import { influencerRegistered } from 'src/Helpers/TagManager'
import { influencerBoard } from 'src/Router/routes'
import { variables } from 'src/Styled/variables'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'

const SuccessCircle = styled.div`
  padding-top: 8px;
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-bottom: 24px;
`
const SuccessText = styled.div`
  font-size: ${variables.fontSize18};
  line-height: 27px;
  display: grid;
  margin-bottom: 32px;

  @media (min-width: 576px) {
    margin-bottom: 24px;
  }
`
const ButtonContainer = styled.div`
  width: 220px;
`

interface LoginCreatorCompletedProps {
  status: InfluencerRegisterEnum
}

export const LoginCreatorCompleted: FC<LoginCreatorCompletedProps> = ({
  status,
}) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { search } = useCustomSearchParams()
  const token = search.get('authToken') || ''
  const userID = search.get('userId') || ''

  if (status !== InfluencerRegisterEnum.COMPLETED) {
    return null
  }

  const onContinueClick = (): void => {
    token &&
      dispatch(
        setAccessToken({
          token,
        }),
      )
    const queryParams = new URLSearchParams(location.search)
    if (queryParams.has('authToken')) {
      queryParams.delete('authToken')
    }
    navigate(`?${queryParams.toString()}`, { replace: true })

    userID && influencerRegistered({ userID })

    navigate(influencerBoard)
  }
  return (
    <>
      <SuccessCircle>
        <IconSuccessfully width={48} height={48} />
      </SuccessCircle>
      <SuccessText>{t('influencer.yourAccountSuccessfullyAdded')}</SuccessText>
      <ButtonContainer>
        <Button type='white' onClick={onContinueClick}>
          {t('influencer.continue')}
        </Button>
      </ButtonContainer>
    </>
  )
}
