import { useSelector } from 'react-redux'

import { useAppDispatch } from 'src/Hooks/redux'
import { getCookieByName, getUtmParameters } from 'src/Constants/functions'
import { sendSourceBusterEvent } from 'src/Redux/sourcebuster-process/api-actions'
import { getSourceBusterData } from 'src/Redux/sourcebuster-process'
import { getAccessToken } from 'src/Redux/auth-process/credentialsSlice/selectors'

interface SourceBusterEvents {
  sourceBusterLoginEvent: (token?: string) => void
}

export const useSourceBusterEvents = (): SourceBusterEvents => {
  const dispatch = useAppDispatch()

  const sourceBusterData = useSelector(getSourceBusterData)
  const savedToken = useSelector(getAccessToken)

  const utmObj = getUtmParameters(getCookieByName('soundCampaignUtm'))

  const sourceBusterLoginEvent = (token?: string): void => {
    if (token || savedToken) {
      void dispatch(
        sendSourceBusterEvent({
          token: token || savedToken,
          gclid: utmObj.gclid || '',
          savedData: sourceBusterData,
          eventName: 'login',
        }),
      )
    }
  }

  return {
    sourceBusterLoginEvent,
  }
}
