import { FC } from 'react'

import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { useDebouncedCallback } from 'use-debounce'
import styled from 'styled-components'

import Button from 'src/Components/Buttons/Button'
import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { DEBOUNCE_DELAY } from 'src/Constants/numeric'
import { InfluencerRegisterEnum } from 'src/Types'
import { colors, variables } from 'src/Styled/variables'
import {
  getApplicantUpdateEmailError,
  getIsConfirmationLinkSent,
} from 'src/Redux/creatorRegister-process/selectors'
import { resetCreatorError } from 'src/Redux/creatorRegister-process'
import { applicantUpdateEmail } from 'src/Redux/creatorRegister-process/api-actions'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'

import { LoginCreatorConfirmation } from '../LoginCreatorConfirmation/LoginCreatorConfirmation'

import { schema } from './schema'

const Form = styled.form`
  width: 100%;
`
const ButtonContainer = styled.div`
  width: 220px;
`
const FormText = styled.div`
  color: ${colors.mainText};
  margin-bottom: 8px;
`
const BtnWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 0px;
`

const ErrorText = styled.div`
  text-align: left;
  color: ${colors.messegesRed};
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight400};
  margin-top: 0px;
  margin-bottom: 8px;
  margin-bottom: 24px;
`

const StyledInput = styled.input<{ searchError?: boolean }>`
  height: 47px !important;
  font-size: ${variables.fontSize14};
  border: ${({ searchError }) =>
    searchError
      ? `1px solid ${colors.messegesRed}`
      : `1px solid ${colors.greyStroke}`};
  border-radius: 8px;
  outline: none;
  padding: 16px;
  width: 100%;
  margin-bottom: ${({ searchError }) => (searchError ? '8px' : '24px')};
  background-color: ${colors.white};
  line-height: 21px;
  color: ${colors.mainText};

  &::-webkit-input-placeholder {
    color: ${colors.greyTextWhiteBg};
  }

  &:focus {
    background-color: ${colors.white};
    outline: none;
    color: ${colors.mainText};
  }
  &:active {
    background-color: ${colors.white};
    outline: none;
    color: ${colors.mainText};
  }
`

interface FormDataProps {
  email: string
}

interface LoginCreatorFromProps {
  status: InfluencerRegisterEnum
  setRegisterState: (value: InfluencerRegisterEnum) => void
}

const FORM_STATUSES = [
  InfluencerRegisterEnum.FORM,
  InfluencerRegisterEnum.CONFIRMATION,
]
export const LoginCreatorFrom: FC<LoginCreatorFromProps> = ({
  setRegisterState,
  status,
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { search } = useCustomSearchParams()
  const authToken = search.get('authToken') || ''
  const authError = useAppSelector(getApplicantUpdateEmailError)
  const isConfirmationLinkSent = useAppSelector(getIsConfirmationLinkSent)

  const {
    register,
    control,
    handleSubmit,
    watch,
    clearErrors,
    formState: { errors },
  } = useForm<FormDataProps>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    resolver: yupResolver(schema),
  })

  const watchEmail = watch('email', '')

  const handleClearErrors = (): void => {
    clearErrors(['email'])
    dispatch(resetCreatorError())
  }

  const debouncedApplicantUpdateEmailHandler = useDebouncedCallback(() => {
    const body = {
      email: watchEmail,
      authToken,
    }
    void dispatch(applicantUpdateEmail(body))
      .unwrap()
      .then((res) => {
        setRegisterState(InfluencerRegisterEnum.CONFIRMATION)
        return res
      })
      .catch(() => {
        // this catch  need for test
        // it is magic
      })
  }, DEBOUNCE_DELAY)

  if (!FORM_STATUSES.includes(status)) {
    return null
  }

  if (status === InfluencerRegisterEnum.CONFIRMATION) {
    return (
      <LoginCreatorConfirmation
        email={watchEmail}
        onClickSendAgain={debouncedApplicantUpdateEmailHandler}
        onClickChangeEmail={() => setRegisterState(InfluencerRegisterEnum.FORM)}
      />
    )
  }

  return (
    <Form onSubmit={handleSubmit(debouncedApplicantUpdateEmailHandler)}>
      <FormText>{t('influencer.enterYourValidEmail')}</FormText>
      <Controller
        name='email'
        control={control}
        defaultValue=''
        render={({ field: { onChange } }) => (
          <StyledInput
            {...register('email')}
            name='email'
            type='email'
            autoComplete='off'
            searchError={Boolean(errors?.email?.message) || authError}
            placeholder={String(t('influencer.email'))}
            onChange={onChange}
            onFocus={handleClearErrors}
            onTouchStart={handleClearErrors}
          />
        )}
      />
      {errors.email?.message ? (
        <ErrorText>{errors.email?.message}</ErrorText>
      ) : null}

      {authError ? (
        <ErrorText>{t('influencer.emailIsAlreadyExist')}</ErrorText>
      ) : null}
      <BtnWrapper>
        <ButtonContainer>
          <Button
            type='green'
            htmlType='submit'
            disabled={
              !watchEmail?.length ||
              Object.keys(errors).length !== 0 ||
              authError ||
              isConfirmationLinkSent
            }
          >
            {t('influencer.sendConfirmationLink')}
          </Button>
        </ButtonContainer>
      </BtnWrapper>
    </Form>
  )
}
