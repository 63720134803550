import styled from 'styled-components'

import { colors, device } from 'src/Styled/variables'

export const SelectCurrency = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 24px;
  padding: 16px;
  border-radius: 5px;
  background-color: #f3f3f3;

  @media ${device.smallScreenMax} {
    flex-direction: column;
  }
`
export const FormGroupLabel = styled.span`
  margin-bottom: 8px;
  display: inline-block;
  color: #646877;
`
export const FormGroupField = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
  .ant-select {
    .ant-select-focused {
      .ant-select-selector {
        color: ${colors.mainText};
        border-color: #eee !important;
        box-shadow: 0 0 0 2px #eee !important;
      }
    }
    .ant-select-focused {
      .ant-select-selector {
        color: ${colors.mainText};
        border-color: #eee !important;
      }
      .ant-select-open {
        color: ${colors.mainText};
        border-color: #eee !important;
        box-shadow: 0 0 0 2px #eee !important;
      }
    }
    .ant-select-selector {
      font-size: 14px;
      color: ${colors.mainText};
      border-radius: 8px;
      box-shadow: 0 0 0 2px #eee !important;
      border-color: #eee !important;
      input {
        height: 40px;
      }
      &:hover,
      &:focus,
      &:active {
        border-color: #eee !important;
        box-shadow: 0 0 0 2px #eee !important;
      }
    }
  }
`
export const Form = styled.form`
  max-width: 2000px;
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
`
export const FormGroup = styled.div`
  margin-bottom: 15px !important;
  max-width: 400px;
  width: 100%;
  position: relative;
  @media ${device.mobileMin} {
    margin-bottom: 0 !important;
  }
`
