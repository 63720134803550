import styled from 'styled-components'

import { colors, device } from 'src/Styled/variables'
import { SharingImageDisplayModeEnum } from 'src/Types/index'

const PERCENT_4_8 = 4.8
const PERCENT_2_8 = 2.8
const PERCENT_10 = 10
const PERCENT_8 = 8
const PERCENT_32 = 32
const PERCENT_1_6 = 1.6
const PERCENT_27 = 27
const PERCENT_4 = 4
const PERCENT_13_2 = 13.2
const PERCENT_7_2 = 7.2
const PERCENT_3_2 = 3.2
const PERCENT_0_8 = 0.8

const calculateSize = (
  containerWidth: number,
  percentSizeFromContainer: number,
): number => (containerWidth / 100) * percentSizeFromContainer

export const ReviewText = styled.span`
  overflow-wrap: anywhere;
  white-space: pre-wrap;
`

export const TrackContainer = styled.div``

export const TrackAvatar = styled.img``

export const TrackInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
`

export const TrackName = styled(ReviewText)``

export const TrackAuthor = styled(ReviewText)``

export const CuratorContainer = styled.div``

export const CuratorAvatar = styled.img``

export const CuratorInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
`

export const CuratorName = styled(ReviewText)``

export const CuratorRole = styled(ReviewText)``

export const Logo = styled.img``

export const Quotes = styled.img``

export const CustomImageContainer = styled.div<{
  containerWidth: number
  displayMode: SharingImageDisplayModeEnum
}>`
  position: relative;
  margin: 40px 0;
  padding: ${({ containerWidth }) =>
    calculateSize(containerWidth, PERCENT_4_8)}px;
  padding-bottom: ${({ containerWidth, displayMode }) =>
    displayMode === SharingImageDisplayModeEnum.square
      ? `${calculateSize(containerWidth, PERCENT_4_8)}px`
      : `${calculateSize(containerWidth, PERCENT_32)}px`};
  width: ${({ containerWidth }) => containerWidth}px;
  height: ${({ containerWidth, displayMode }) =>
    displayMode === SharingImageDisplayModeEnum.square
      ? `${containerWidth}px`
      : 'auto'};
  background: ${colors.black};
  border-radius: ${({ containerWidth }) =>
    calculateSize(containerWidth, PERCENT_1_6)}px;

  ${Logo} {
    position: absolute;
    top: ${({ containerWidth }) =>
      calculateSize(containerWidth, PERCENT_4_8)}px;
    right: ${({ containerWidth }) =>
      calculateSize(containerWidth, PERCENT_4_8)}px;
    width: ${({ containerWidth }) =>
      calculateSize(containerWidth, PERCENT_27)}px;
  }
  ${ReviewText} {
    display: flex;
    color: ${colors.white};
    font-size: ${({ containerWidth }) =>
      calculateSize(containerWidth, PERCENT_4)}px;
    line-height: 1.5;
    font-weight: 600;
  }
  ${TrackContainer} {
    display: flex;
    margin-top: ${({ containerWidth }) =>
      calculateSize(containerWidth, PERCENT_13_2)}px;
    margin-bottom: ${({ containerWidth }) =>
      calculateSize(containerWidth, PERCENT_7_2)}px;
    width: 100%;
    height: ${({ containerWidth }) =>
      calculateSize(containerWidth, PERCENT_10)}px;
    gap: ${({ containerWidth }) =>
      calculateSize(containerWidth, PERCENT_3_2)}px;
  }
  ${TrackAvatar} {
    width: ${({ containerWidth }) =>
      calculateSize(containerWidth, PERCENT_10)}px;
    height: ${({ containerWidth }) =>
      calculateSize(containerWidth, PERCENT_10)}px;
    border-radius: ${({ containerWidth }) =>
      calculateSize(containerWidth, PERCENT_0_8)}px;
  }
  ${TrackName} {
    font-size: ${({ containerWidth }) =>
      calculateSize(containerWidth, PERCENT_2_8)}px;
    font-weight: 400;
  }
  ${TrackAuthor} {
    font-size: ${({ containerWidth }) => calculateSize(containerWidth, 2.0)}px;
    font-weight: 400;
  }
  ${Quotes} {
    position: absolute;
    margin-top: ${({ containerWidth }) => calculateSize(containerWidth, 1)}px;
    right: ${({ containerWidth }) =>
      calculateSize(containerWidth, PERCENT_4_8)}px;
    width: ${({ containerWidth }) =>
      calculateSize(containerWidth, PERCENT_8)}px;
  }
  ${CuratorContainer} {
    position: absolute;
    bottom: 0;
    display: flex;
    margin-bottom: ${({ containerWidth }) =>
      calculateSize(containerWidth, PERCENT_4_8)}px;
    width: 100%;
    height: ${({ containerWidth }) =>
      calculateSize(containerWidth, PERCENT_8)}px;
    gap: ${({ containerWidth }) =>
      calculateSize(containerWidth, PERCENT_3_2)}px;
  }
  ${CuratorAvatar} {
    width: ${({ containerWidth }) =>
      calculateSize(containerWidth, PERCENT_8)}px;
    height: ${({ containerWidth }) =>
      calculateSize(containerWidth, PERCENT_8)}px;
    border: 1px solid ${colors.white};
    border-radius: 50%;
  }
  ${CuratorName} {
    font-size: ${({ containerWidth }) =>
      calculateSize(containerWidth, PERCENT_2_8)}px;
  }
  ${CuratorRole} {
    font-size: ${({ containerWidth }) =>
      calculateSize(containerWidth, PERCENT_2_8)}px;
    font-weight: 400;
  }

  @media ${device.mobileMax} {
    margin: 24px 0;
  }
`
