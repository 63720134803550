import styled from 'styled-components'

import { colors, variables, device } from 'src/Styled/variables'

export const LogoHeader = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 150px 1fr;
  justify-items: self-end;
  height: 60px;
  background-color: ${colors.black};
  padding: 0px 16px;
  @media ${device.mobileMin} {
    display: grid;
  }
`

export const ReferralLinkNotExistBlock = styled.div`
  z-index: 300;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin-top: 60px;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const ReferralLinkNotExistHead = styled.div`
  color: ${colors.mainText};
  font-size: ${variables.fontSize18};
  font-style: normal;
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
  margin-bottom: 16px;
`

export const ReferralLinkNotExistText = styled.div`
  color: ${colors.mainText};
  font-size: ${variables.fontSize14};
  font-style: normal;
  font-weight: ${variables.fontWeight400};
  line-height: 150%;
  margin-bottom: 40px;
`

export const ReferralLinkNotExistButton = styled.div`
  width: 200px;
  align-self: center;
  display: flex;
  justify-content: center;
`
