import styled from 'styled-components'
import { Modal } from 'antd'

import { colors } from 'src/Styled/variables'

export const IconContainer = styled.div`
  display: flex;
  svg {
    fill: ${colors.green};
  }
`
export const ButtonWrapper = styled.div`
  width: fit-content;
  .ant-btn.ant-btn-default {
    padding: 0 40px;
  }
`

export const ButtonBody = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`
export const Container = styled.div`
  &:first-child {
    margin-right: 20px;
  }
`
export const Wrapper = styled.div`
  position: relative;
`
export const StyledModal = styled(Modal)`
  top: 10% !important;
  .ant-modal-body {
    padding: 24px;
  }
  .ant-modal-content {
    padding: 0;
    max-width: 600px;
    border-radius: 8px;
  }
  .ant-modal-header {
    padding: 16.5px 24px;
    background-color: ${colors.green};
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  .ant-modal-title {
    color: ${colors.white};
    font-size: 18px;
    line-height: 150%;
  }
  .ant-modal-close-x {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
