import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'

import { APP } from 'src/Configs/App'
import { ErrorCode, NameSpace } from 'src/Redux/types'
import { api } from 'src/Services/api'
interface CheckReferralCodeResponse {
  firstName: string
}

export const checkReferralCode = createAsyncThunk<
  CheckReferralCodeResponse,
  string
>(
  `${NameSpace.REFERRAL_HASH}/checkReferralCode`,
  async (referralCode, { rejectWithValue }) => {
    try {
      const { data } = await api.get<CheckReferralCodeResponse>(
        `${APP.TIKTOK_SERVER}/users/noauth/${referralCode}`,
      )
      return data
    } catch (error) {
      const { message, response } = error as AxiosError
      return rejectWithValue({
        message,
        code: response?.status || ErrorCode.InternalServerError,
      })
    }
  },
)
