import { createDraftSafeSelector } from '@reduxjs/toolkit'

import { RootState } from '..'
import { SliceStatus } from '../types'

import { CreatorRegisterState } from '.'

const getState = (state: RootState): CreatorRegisterState =>
  state.creatorRegister

export const getIsConfirmationLinkSent = createDraftSafeSelector(
  getState,
  ({ status }) => status === SliceStatus.Loading,
)

export const getApplicantUpdateEmailError = createDraftSafeSelector(
  getState,
  ({ status }) => status === SliceStatus.Failed,
)
