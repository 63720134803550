import React from 'react'

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { MAX_MOBILE_WIDTH } from 'src/Styled/variables'
import ReloadIcon from 'src/Assets/Svg/campaign-statuses-icons/Running.svg?react'
import {
  SquareButton,
  SquareButtonProps,
} from 'src/Components/Buttons/SquareButton'

const StyledButton = styled(SquareButton)`
  padding: 0 12px;
  @media (max-width: ${MAX_MOBILE_WIDTH}) {
    background-color: transparent !important;
  }
`

const ChildrenWrapper = styled.div`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  display: flex;
  gap: 8px;
  > span {
    display: flex;
    align-self: center;
  }
`

export type RelaunchButtonProps = Pick<
  SquareButtonProps,
  'loading' | 'onClick' | 'disabled'
>

export const RelaunchButton: React.FC<RelaunchButtonProps> = ({
  loading,
  onClick,
  disabled,
}) => {
  const { t } = useTranslation()

  return (
    <StyledButton loading={loading} disabled={disabled} onClick={onClick}>
      <ChildrenWrapper>
        <span>
          <ReloadIcon />
        </span>

        <span>{t('components.relaunchCampaign')}</span>
      </ChildrenWrapper>
    </StyledButton>
  )
}
