import { FunctionComponent, useState, useMemo } from 'react'

import { useSelector } from 'react-redux'

import { artistClickShareEvent } from 'src/Helpers/TagManager'
import { ReviewStatus } from 'src/Types/index'
import { getUserSelector } from 'src/Redux/auth-process/userSlice/selectors'
import { ShareModal } from 'src/Components/ShareModal'
import { useSpotifyCampaignDetails } from 'src/Redux/campaignDetailsApi/useSpotifyCampaignDetails'

import { CampaignRatingProps } from './props'
import { MissedReview } from './MisedReview/MisedReview'
import { Author } from './Author/Author'
import { CuratorReview } from './CuratorReview'

import { ReviewContainer, ReviewContentContainer } from './styles'

const MISSED_STATUSES = [
  ReviewStatus.missed,
  ReviewStatus.canceled,
  ReviewStatus.skipped,
]

const CampaignRating: FunctionComponent<CampaignRatingProps> = ({
  review,
  campaignID,
  isSFAConnected,
}) => {
  const {
    Curator,
    id: reviewId,
    Playlist,
    matched,
    curator_rating,
    review_feedback,
    review_refund,
    comment,
    review_status,
    recommendations,
  } = review

  const { campaignDetails } = useSpotifyCampaignDetails()

  const user = useSelector(getUserSelector)

  const isMissed = useMemo(
    () => MISSED_STATUSES.includes(review_status),
    [review_status],
  )

  const [isMatchedOpened, setIsMatchedOpened] = useState<boolean>(false)
  const [isShareModal, setIsShareModal] = useState(false)

  const onToggleShareModal = (): void => {
    setIsShareModal(!isShareModal)
    campaignDetails &&
      artistClickShareEvent({
        userId: user.id,
        campaignId: campaignDetails.id,
      })
  }

  const onToggleMatches = (): void => {
    setIsMatchedOpened(!isMatchedOpened)
  }

  return (
    <ReviewContainer>
      {isShareModal && (
        <ShareModal
          isModalVisible={isShareModal}
          review={review}
          onClose={onToggleShareModal}
        />
      )}
      <ReviewContentContainer>
        <Author curator={Curator} isMissed={isMissed} />
        {!isMissed && (
          <CuratorReview
            reviewId={reviewId}
            campaignID={campaignID}
            recommendations={recommendations}
            reviewFeedback={review_feedback}
            comment={comment}
            matched={matched}
            playlist={Playlist}
            isMatchedOpened={isMatchedOpened}
            isSFAConnected={isSFAConnected}
            onToggleMatches={onToggleMatches}
            onToggleShareModal={onToggleShareModal}
            curatorRating={curator_rating}
          />
        )}
        {isMissed && <MissedReview reviewRefund={review_refund} />}
      </ReviewContentContainer>
    </ReviewContainer>
  )
}

export default CampaignRating
