import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'

import { APP } from 'src/Configs/App'
import { ErrorCode, NameSpace } from 'src/Redux/types'
import { api } from 'src/Services/api'

interface GetAudienceTiktokResponse {
  maxVideos: number
  maxFollowers: number
  minVideos: number
  minFollowers: number
  price: number
  maxViewsMedian: string
  minViewsMedian: string
}

export const getAudienceTiktok = createAsyncThunk<
  GetAudienceTiktokResponse,
  number
>(
  `${NameSpace.AudienceTiktok}/getAudienceTiktok`,
  async (amount, { rejectWithValue }) => {
    try {
      const { data } = await api.get<GetAudienceTiktokResponse>(
        `${APP.TIKTOK_SERVER}/artist/tiktok/expected-reach/?amount=${amount}`,
      )
      return data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      return rejectWithValue({
        message: response?.data.message,
        code: response?.status || ErrorCode.InternalServerError,
      })
    }
  },
)
