import React, { FC, PropsWithChildren } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import ArrowLeftIcon from 'src/Assets/Svg/arrow-left.svg?react'
import { colors, variables } from 'src/Styled/variables'

const PageTitleRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  gap: 12px;
`
const BackButton = styled.div`
  padding: 5px;
  width: 22px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`
const Container = styled.div`
  position: fixed;
  top: 60px;
  left: 0;
  right: 0;
  height: calc(100vh - 60px);
  overflow-y: scroll;
  background-color: #f3f4f7;
  padding: 20px 16px;
`
const PageTitle = styled.h4`
  margin: 0;
  font-size: ${variables.fontSize20};
  font-weight: ${variables.fontWeight600};
  color: ${colors.mainText};
`
type Props = PropsWithChildren & {
  onModalClose: () => void
  isNew: boolean
}

export const MobilePlaylistEditModalWithTitle: FC<Props> = ({
  isNew,
  onModalClose,
  children,
}) => {
  const { t } = useTranslation()
  return (
    <Container>
      <PageTitleRow>
        <BackButton onClick={onModalClose}>
          <ArrowLeftIcon />
        </BackButton>
        <PageTitle>
          {isNew
            ? t('scheduleCampaignPage.addPlaylist')
            : t('scheduleCampaignPage.editPlaylist')}
        </PageTitle>
      </PageTitleRow>
      {children}
    </Container>
  )
}
