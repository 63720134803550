import { FC } from 'react'

import styled from 'styled-components'

import { useFirstChosenTrack } from 'src/Hooks/useFirstChosenTrack'

import { NoAuthPageLayout } from '../../Components/NoAuthPageLayout'
import { CreateCampaignBreadcrumbsNoAuth } from '../../Components/CreateCampaignBreadcrumbsNoAuth'
import { HelmetTitle } from '../../Components/HelmetTitle'
import { MAX_MOBILE_WIDTH } from '../../../../../Styled/variables'

import { useArtistRegisterPage } from './useArtistRegisterPage'
import { ArtistRegisterContent } from './Components/ArtistRegisterContent'

const Container = styled.div`
  display: flex;
  max-width: 960px;
  margin: 0 auto;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;

  @media (max-width: ${MAX_MOBILE_WIDTH}) {
    gap: 40px;
  }
`

export const ArtistRegisterPage: FC = () => {
  const {
    handleSubmit,
    isEmailExist,
    loading: isRegisterLoading,
    onStartFillingForm,
  } = useArtistRegisterPage()

  const { track, platform, loading } = useFirstChosenTrack()

  return (
    <NoAuthPageLayout>
      <HelmetTitle />

      <Container>
        <CreateCampaignBreadcrumbsNoAuth platform={platform} />
        <ArtistRegisterContent
          isEmailExist={isEmailExist}
          isRegisterLoading={isRegisterLoading}
          onSubmitRegisterForm={handleSubmit}
          onStartFillingForm={onStartFillingForm}
          isTrackDataLoading={loading}
          trackCoverUrl={track?.trackCoverUrl}
          trackName={track?.trackName}
          artistName={track?.artistName}
          trackDuration={track?.trackDuration}
          trackPreviewUrl={track?.trackPreviewUrl}
          platform={platform}
        />
      </Container>
    </NoAuthPageLayout>
  )
}
