import React from 'react'

import { useTranslation } from 'react-i18next'

import PlusIcon from 'src/Assets/Svg/roundedPlus.svg?react'

import { PrimaryButton, PrimaryButtonProps } from '../PrimaryButton'

type CreateCampaignButtonProps = Pick<
  PrimaryButtonProps,
  'className' | 'loading' | 'disabled' | 'onClick'
>

export const CreateCampaignButton: React.FC<CreateCampaignButtonProps> = ({
  className,
  loading,
  disabled,
  onClick,
}) => {
  const { t } = useTranslation()

  return (
    <PrimaryButton
      className={className}
      loading={loading}
      disabled={disabled}
      onClick={onClick}
    >
      <PlusIcon width={10} height={10} />
      <span>{t('components.createCampaignButtonTitle')}</span>
    </PrimaryButton>
  )
}
