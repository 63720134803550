import { Modal } from 'antd'
import styled from 'styled-components'

import { device, colors } from 'src/Styled/variables'

export const StyledModal = styled(Modal)`
  top: 5%;
  .ant-modal-content {
    border-radius: 4px;
    padding: 50px 40px 60px;
  }
  .ant-modal-close-x {
    display: none;
  }
`

export const Title = styled.h4`
  color: ${colors.badgeGreen};
  margin-bottom: 20px;
  font-weight: 600;
  text-align: center;
`
export const StyledRoles = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  div {
    width: 100%;
    .ant-btn {
      padding: 0 40px;
      font-weight: 600;
      font-size: 18px;
    }
  }

  @media ${device.mobileMin} {
    flex-direction: row;
    div {
      width: fit-content;
    }
  }
`
