import styled from 'styled-components'

import { colors } from 'src/Styled/variables'

export const ButtonsContainer = styled.div`
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > div:last-child {
    width: 20%;
  }
`

export const Card = styled.div`
  width: 100%;
`

export const CardBody = styled.div`
  padding: 24px;
  background-color: ${colors.white};
  border-radius: 4px;
`

export const TabTriggersOverflow = styled.div`
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`

export const Table = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`
