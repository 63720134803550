import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import PlusIcon from 'src/Assets/Svg/roundedPlus.svg?react'
import { PrimaryButton } from 'src/Components/Buttons/PrimaryButton'
import { ScheduleSteps } from 'src/Components/PrimaryScheduleTabs'
import { ModalType } from 'src/Containers/ScheduleSpotifyWrapper/components/ModalsContainer'
import { useAppSelector } from 'src/Hooks/redux'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import { getScheduledTikTokCampaign } from 'src/Redux/schedule-process/tiktokCampaign/selectors'
import { PrimaryDottedButton } from 'src/Components/Buttons/PrimaryDottedButton'

const ParametersButton = styled(PrimaryButton)`
  display: flex;
  height: 48px;
  padding: 0px 24px;
  justify-content: center;
  align-items: center;
  gap: 4px;
`

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: stretch;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1 0 0;
`

const SubTitle = styled.div`
  color: ${({ theme }) => theme.common.primaryText};
  font-family: 'Segoe UI';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
`
const Comment = styled(SubTitle)`
  font-weight: 400;
`
export const Expectations: FC = () => {
  const { t } = useTranslation()
  const scheduledTiktok = useAppSelector(getScheduledTikTokCampaign)
  const { comment } = scheduledTiktok
  const { setSearch } = useCustomSearchParams()

  const handleClick = (): void => {
    const query = {
      step: ScheduleSteps.SETTINGS,
      modal: ModalType.Comment,
    }
    setSearch(query)
  }
  if (!comment) {
    return (
      <ParametersButton onClick={handleClick}>
        <PlusIcon />
        <span>{t('scheduleCampaignPage.addTaskForCreators')}</span>
      </ParametersButton>
    )
  }
  return (
    <Container>
      <Wrapper>
        <SubTitle>{t('tiktokSchedulePage.task')}</SubTitle>
        <Comment>{comment}</Comment>
      </Wrapper>
      <PrimaryDottedButton onClick={handleClick}>
        {t('scheduleCampaignPage.edit')}
      </PrimaryDottedButton>
    </Container>
  )
}
