import styled from 'styled-components'

import { colors, device, variables } from 'src/Styled/variables'
export const SubscriptionPageWrapper = styled.div`
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: ${colors.white};
  padding-top: 60px;
  display: flex;
  justify-content: center;
  overflow-y: scroll;
  scrollbar-width: none;
`

export const PageContainer = styled.div`
  padding: 16px;
  padding-top: 64px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
  @media ${device.mobileMax} {
    padding: 16px;
  }
`
export const Title = styled.h2`
  margin: 0;
  padding: 0;
  font-size: ${variables.fontSize20};
  line-height: 150%;
  font-weight: ${variables.fontWeight600};
`
export const Container = styled.div`
  padding-bottom: 24px;
  background-color: ${colors.white};
  border-radius: 4px;
`
export const Text = styled.span`
  font-size: ${variables.fontSize14};
  line-height: 150%;
  font-weight: ${variables.fontWeight400};
`
export const SubTitle = styled.div`
  font-size: ${variables.fontSize14};
  line-height: 150%;
  font-weight: ${variables.fontWeight600};
`
