import { Genre, Language } from 'src/Types/index'

export type Option = Array<{
  label: string
  value: number
}>

export const optionsSelectLanguages = (
  options: Language[] | undefined,
): Option => {
  if (!options) {
    return []
  }

  const languagesOptions = options
    .filter((item) => item.language_name !== 'No preference')
    .map((item) => ({
      value: Number(item.id),
      label: item.language_name
        ? item.language_name.slice(0, 1).toUpperCase() +
          item.language_name.slice(1)
        : '',
    }))
  return [...languagesOptions]
}

export const getSelectedGenres = (
  selectedGenres: Genre[] | undefined,
): Option => {
  if (!selectedGenres) {
    return []
  }
  return selectedGenres?.map((item) => ({
    value: Number(item.id),
    label: item.genre_name
      ? item.genre_name.slice(0, 1).toUpperCase() + item.genre_name.slice(1)
      : '',
  }))
}
