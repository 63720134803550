import { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'

import WarningNoDataIcon from 'src/Assets/Svg/warningNoData.svg?react'

import {
  DataNotReceivedContainer,
  DataNotReceivedLabel,
  DataNotReceivedIcon,
} from './styles'

export const DataNotReceivedBlock: FunctionComponent = () => {
  const { t } = useTranslation()
  return (
    <DataNotReceivedContainer>
      <DataNotReceivedIcon>
        <WarningNoDataIcon width={16} height={14.14} />
      </DataNotReceivedIcon>
      <DataNotReceivedLabel>
        {t('campaignResultsPage.dataNotReceived')}
      </DataNotReceivedLabel>
    </DataNotReceivedContainer>
  )
}
