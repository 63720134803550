import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import Button from 'src/Components/Buttons/Button'
import { CloseRoundedButton } from 'src/Components/CloseRoundedButton'
import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { setIsAlertClosed } from 'src/Redux/getPremiumProcess'
import { subscription } from 'src/Router/routes'
import { getIsAlertClosed } from 'src/Redux/getPremiumProcess/selectors'
import { getHasSubscriptionSelector } from 'src/Redux/subscription-process/subscription/selectors'
import { getPremiumFeaturesEvent } from 'src/Helpers/TagManager'
import { getUserId } from 'src/Redux/auth-process/userSlice/selectors'
import {
  getHasPaidCampaignMetadata,
  useMetadataQuery,
} from 'src/Redux/metadataApi'
import { PremiumFeaturesButtonTypeEnum } from 'src/Helpers/TagManager/tagManagerEnums'

import { ButtonsWrapper, Container, Description, Title } from './styles'

export const CampaignsListPromoAlert: FC = () => {
  const navigate = useNavigate()
  const userId = useSelector(getUserId)
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { data } = useMetadataQuery()
  const hasPaidCampaigns = getHasPaidCampaignMetadata(data)

  const isAlertClosed = useSelector(getIsAlertClosed)
  const hasPlan = useAppSelector(getHasSubscriptionSelector)

  const onClickCloseButton = (): void => {
    dispatch(setIsAlertClosed())
  }

  const onClickLearnMoreButton = (): void => {
    getPremiumFeaturesEvent({
      userId,
      buttonType: PremiumFeaturesButtonTypeEnum.CampaignsList,
    })
    dispatch(setIsAlertClosed())
    navigate(subscription)
  }

  if (isAlertClosed || hasPlan || !hasPaidCampaigns) {
    return null
  }
  return (
    <Container>
      <div>
        <Title>{t('sidebar.premium')}</Title>
        <Description>{t('getPremiumPage.upgrade')}</Description>
      </div>
      <ButtonsWrapper>
        <Button onClick={onClickLearnMoreButton} type={'green'}>
          {t('getPremiumPage.learnMore')}
        </Button>
        <CloseRoundedButton onClick={onClickCloseButton} />
      </ButtonsWrapper>
    </Container>
  )
}
