import { FC } from 'react'

import { ErrorText } from './styles'

interface ErrorMessageProps {
  message?: string
}

export const ErrorMessage: FC<ErrorMessageProps> = ({ message }) => {
  if (!message) {
    return null
  }
  return <ErrorText>{message}</ErrorText>
}
