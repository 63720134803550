import styled from 'styled-components'

import { colors, device } from 'src/Styled/variables'

export const Wrapper = styled.div`
  height: 100vh;
  width: 100%;
  min-height: 100vh;
  display: flex;
  overflow-y: auto;
  background-color: black;
  position: absolute;
`
export const Container = styled.div`
  border-radius: 5px;
  margin: auto;
  padding: 10px;
  width: 90%;
`
export const Head = styled.div`
  margin-bottom: 20px;
  text-align: center;

  h3 {
    color: black !important;
    font-weight: 600;
    font-size: 24px;
  }
`
export const Logo = styled.div`
  width: 250px;
  margin: 0 auto;
  padding: 20px;
`
export const Card = styled.div`
  background-color: ${colors.white};
  margin: auto;
  padding: 54px 72px;
  max-width: 520px;
  width: 100%;
  border-radius: 5px;
  @media ${device.mobileMax} {
    padding: 24px 16px;
  }
`
export const TextCenter = styled.div`
  display: flex;
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
  justify-content: space-around;

  &:last-child {
    margin-bottom: 0;
  }

  a {
    color: black !important;

    .spotify-color {
      color: #1db954;
    }
  }
`
