import styled from 'styled-components'
import { Alert } from 'antd'

import ButtonClose from 'src/Assets/Svg/cross-icon.svg?react'
import { variables, colors } from 'src/Styled/variables'

import { IconTypeForAlerts } from './Icon'

const getBorderColor = (color: IconTypeForAlerts): string => {
  switch (color) {
    case 'danger':
      return colors.red
    case 'success':
      return colors.green
    case 'warning':
      return colors.yellow1
    default:
      return colors.green
  }
}

export const AlertStyled = styled(Alert)<{
  noGap?: boolean
  smallGap?: boolean
  middleGap?: boolean
  color: IconTypeForAlerts
  noBorder?: boolean
}>`
  &.ant-alert-with-description.ant-alert-no-icon {
    border-radius: 4px;
    border: 1px solid
      ${({ color, noBorder }) =>
        noBorder ? 'transparent' : getBorderColor(color)};
    transition: 0.2s all linear;
    padding: ${({ noGap }) => (noGap ? '0' : '16px 16px 16px 16px')};
  }

  && {
    margin-bottom: ${({ noGap, smallGap, middleGap }) => {
      if (smallGap) {
        return '8px'
      }
      if (middleGap) {
        return '16px'
      }
      if (noGap) {
        return '0px'
      }
      return '24px'
    }};

    background-color: ${colors.white};
    border: 0;
  }
  .ant-alert-message {
    display: none;
  }
  .ant-alert {
    background-color: red;
  }
`

export const AlertContent = styled.div<{
  rowDirection?: boolean
}>`
  width: 100%;
  display: flex;
  flex-wrap: ${({ rowDirection }) => (rowDirection ? 'nowrap' : 'wrap')};
  flex-direction: ${({ rowDirection }) => (rowDirection ? 'row' : 'column')};
  justify-content: ${({ rowDirection }) =>
    rowDirection ? 'flex-start' : 'space-between !important'};
  @media screen and (min-width: 1330px) {
    flex-direction: row;
  }
`
export const AlertWrapper = styled.div`
  display: flex;
`

export const AlertIcon = styled.div`
  padding-top: 2.4px;
  display: flex;
  align-items: flex-start;
  img {
    width: 16px;
  }
`

export const AlertContentChildren = styled.div<{ textColor?: string }>`
  font-size: ${variables.fontSize14};
  line-height: ${variables.lineHeight150per};
  color: ${({ textColor }) => textColor || colors.mainText};
  margin-left: 8px;
  width: 100%;
`
export const StyledCloseIcon = styled(ButtonClose)`
  transition: 0.2s all linear;
  &:hover {
    path {
      fill: #646877;
    }
  }
`
