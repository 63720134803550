import React, { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'

import { Container, SubTitle, Text, Title } from './styles'

const PlaylistVocalSettings: FunctionComponent = () => {
  const { t } = useTranslation()

  return (
    <Container>
      <Title>{t('scheduleCampaignPage.playlistVocalSettings')}</Title>
      <Text>
        {t(
          'scheduleCampaignPage.youWillReceiveTracksForReviewOnlyMatchingYourPreferences',
        )}
      </Text>
      <SubTitle>{t('scheduleCampaignPage.noVocal')}</SubTitle>
      <Text>
        {t('scheduleCampaignPage.selectIfYouPreferAddInstrumentalMusicOnly')}
      </Text>
      <SubTitle>{t('scheduleCampaignPage.noPreferences')}</SubTitle>
      <Text>{t('scheduleCampaignPage.selectIfYouAreOpenToAddTracks')}</Text>
      <SubTitle>{t('scheduleCampaignPage.language')}</SubTitle>
      <Text>{t('scheduleCampaignPage.selectLanguageOfTracks')}</Text>
    </Container>
  )
}

export default PlaylistVocalSettings
