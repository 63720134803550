import { Modal } from 'antd'
import styled from 'styled-components'

import { SmartImage } from 'src/Components/SmartImage'
import { colors, variables } from 'src/Styled/variables'

export const StyledModal = styled(Modal)`
  top: 10%;
  .ant-modal-content {
    padding: 0;
    width: 600px;
    border-radius: 8px;
  }
  .ant-modal-header {
    padding: 16.5px 24px;
    background-color: ${colors.green};
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  .ant-modal-title {
    color: ${colors.white};
  }
  .ant-modal-close-x {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export const MobileContainer = styled.div`
  padding: 16px;
  background-color: ${colors.white};
  border-radius: 4px;
`
export const MobileWrapper = styled.div`
  position: fixed;
  top: 60px;
  left: 0;
  padding: 16px;
  height: calc(100vh - 60px);
  background-color: #f3f4f7;
  overflow: scroll;
`

export const ModalContent = styled.div`
  padding: 24px;
`

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 72px;

  & > div {
    width: 50%;
  }

  @media (max-width: 576px) {
    height: 85px;
    flex-direction: column-reverse;
    justify-content: space-between;
    padding-bottom: 40px;
  }
`

export const PlaylistContainer = styled.div`
  margin-top: 24px;
  padding: 8px 0 4px;
  width: 100%;
  border-bottom: 1px solid ${colors.greyStroke};

  @media (max-width: 576px) {
    margin-top: 0;
    margin-bottom: 24px;
    border-bottom: none;
    border-top: 1px solid ${colors.greyStroke};
    &::last-child {
      margin-bottom: 0px !important;
    }
  }
`

export const PlaylistTopContainer = styled.div`
  position: relative;
  display: flex;
  padding: 8px 0 6px;
`

export const PlaylistCover = styled(SmartImage)`
  margin-right: 16px;
  width: 40px;
  height: 40px;
  border-radius: 4px;
`

export const PlaylistInfo = styled.div`
  display: flex;
  flex-direction: column;
`

export const PlaylistName = styled.span`
  font-size: ${variables.fontSize14};
  color: ${colors.mainText};

  @media (max-width: 576px) {
    max-width: 70%;
  }
`

export const PlaylistScore = styled.span`
  font-size: ${variables.fontSize14};
  color: ${colors.greyTextWhiteBg};
`

export const PlaylistScoreNumber = styled(PlaylistScore)`
  color: ${colors.mainText};
`

export const LanguagesSelectItem = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight400};
  line-height: 21px;
  color: ${colors.mainText};
  padding: 10px 0px;
`

export const CheckMark = styled.div`
  position: absolute;
  right: 0;
  top: 22px;
  width: 16px;
  height: 16px;
`

export const SelectWrapper = styled.div`
  padding: 24px 0 0;
`

export const MobilePageTitleRow = styled.div`
  display: flex;
  align-items: center;
  height: 43px;
  margin-bottom: 22px;
`

export const PageTitle = styled.h3`
  margin: 0;
  padding: 0;
  font-size: ${variables.fontSize20};
  font-weight: ${variables.fontWeight600};
  color: ${colors.mainText};
`

export const BackButton = styled.div`
  padding: 10px;
  padding-left: 0px;
  width: 20px;
  height: 20px;
`
export const TargetLanguageTitle = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: ${variables.fontSize14};
  color: ${colors.mainText};
  font-weight: ${variables.fontWeight400};
  margin-bottom: 16px;
  @media (min-width: 576px) {
    margin-bottom: 16px;
  }
`
