import React, { FC } from 'react'

import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { SkeletonLoader } from 'src/Components/Skeletons/SkeletonLoader'
import { CampaignListItem } from 'src/Components/CampaignListItem'
import { CampaignPlatformTypeEnum } from 'src/Types'
import { ArtistRoutesEnum } from 'src/Router/ArtistComponentsNewUI'

import { CampaignUnionType } from '../useCampaigns'

import { useRelaunchSpotifyCampaign } from './useRelaunchSpotifyCampaign'
import { CampaignNewReleaseItem } from './CampaignNewReliseItem'

interface CampaignListProps {
  loading: boolean
  campaigns: CampaignUnionType[]
}

const NoDataText = styled.div`
  color: ${(props) => props.theme.common.primaryText};
  width: 100%;
  text-align: center;
  font-family: 'Segoe UI';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`
const List = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  align-self: stretch;
`

export const CampaignList: FC<CampaignListProps> = (props) => {
  const { loading, campaigns } = props
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [relaunch, { isLoading, campaignId }] = useRelaunchSpotifyCampaign()
  if (loading) {
    return <SkeletonLoader count={5} />
  }
  if (!campaigns?.length) {
    return <NoDataText>{t('campaignsListPage.noDataAvailable')}</NoDataText>
  }

  const handleDetailsClick = (
    id: number,
    platform: CampaignPlatformTypeEnum,
  ): void => {
    if (platform === CampaignPlatformTypeEnum.SPOTIFY) {
      navigate(`${ArtistRoutesEnum.SpotifyDetails}/${id}`)
    }
    if (platform === CampaignPlatformTypeEnum.TIKTOK) {
      navigate(`${ArtistRoutesEnum.TiktokDetails}/${id}`)
    }
  }

  const handleStartCampaignClick = (
    id: number,
    platform: CampaignPlatformTypeEnum,
  ): void => {
    if (platform === CampaignPlatformTypeEnum.SPOTIFY) {
      navigate(`${ArtistRoutesEnum.SpotifySchedule}/${id}`)
    }
    if (platform === CampaignPlatformTypeEnum.TIKTOK) {
      navigate(`${ArtistRoutesEnum.TiktokSchedule}/${id}`)
    }
  }
  return (
    <List>
      <CampaignNewReleaseItem />
      {campaigns.map((item) => (
        <CampaignListItem
          key={item.id}
          relaunched={item.relaunched}
          relaunchLoading={isLoading && campaignId === item.id}
          trackImage={item.trackImage}
          trackName={item.trackName}
          artistName={item.artistName}
          platform={item.platform}
          status={item.status}
          statusText={item.statusText}
          onDetailsClick={() => handleDetailsClick(item.id, item.platform)}
          onStartCampaignClick={() =>
            handleStartCampaignClick(item.id, item.platform)
          }
          onRelaunchCampaignClick={() => relaunch(item.id)}
        />
      ))}
    </List>
  )
}
