import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'

import { APP } from 'src/Configs/App'
import { NameSpace } from 'src/Redux/types'
import { api } from 'src/Services/api'

export const changeArtistPassword = createAsyncThunk<unknown, string>(
  `${NameSpace.ArtistPassword}/changeArtistPassword`,
  async (newPassword, { rejectWithValue }) => {
    try {
      const { data } = await api.put<unknown>(
        `${APP.SERVER}/artist/change-password`,
        {
          newPassword,
        },
      )
      return data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      return rejectWithValue({
        message: response?.data.message,
        status: response?.status,
      })
    }
  },
)
