import { FC } from 'react'

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { Expectations } from './Expectations'
import { Parameters } from './Parameters'

const Container = styled.div`
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.common.primaryBorder};
  background: ${({ theme }) => theme.common.primaryBg};
  box-shadow: 0px 0px 32px 0px rgba(51, 51, 51, 0.08);
`

const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`

const Title = styled.div`
  color: ${({ theme }) => theme.common.primaryText};
  font-family: 'Segoe UI';
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
`

export const TiktokScheduleTrackParams: FC = () => {
  const { t } = useTranslation()
  return (
    <Container>
      <SubContainer>
        <Title>{t('tiktokSchedulePage.choose')}</Title>
        <Parameters />
      </SubContainer>
      <SubContainer>
        <Title>{t('tiktokSchedulePage.share')}</Title>
        <Expectations />
      </SubContainer>
    </Container>
  )
}
