import React from 'react'

import styled from 'styled-components'
import {
  Collapse as AntdCollapse,
  CollapseProps as AntdCollapseProps,
} from 'antd'

import { ArrowButton } from 'src/Components/Buttons/ArrowButton'

export const StyledCollapse = styled(AntdCollapse)`
  .ant-collapse-header {
    padding: 0 !important;
    border: none;
    margin-bottom: 8px;
  }
  .ant-collapse-header-text {
    width: fit-content;
    flex-grow: 0 !important;
    margin-inline-end: unset !important;
  }

  .ant-collapse-content-box {
    padding: 0 !important;
  }

  .ant-collapse-expand-icon {
    padding-inline-start: 5px !important;
  }
`

export type CollapseProps = AntdCollapseProps

export const Collapse: React.FC<CollapseProps> = (props) => (
  <StyledCollapse
    ghost
    expandIconPosition='end'
    expandIcon={({ isActive }) => <ArrowButton isDown={isActive} />}
    {...props}
  />
)
