import { FC, useState } from 'react'

import styled from 'styled-components'

import PlusIcon from 'src/Assets/Svg/plus.svg?react'
import { colors, variables } from 'src/Styled/variables'
const Wrapper = styled.div<{ $collapse: boolean }>`
  display: flex;
  width: 240px;
  height: 40px;
  align-items: center;
  gap: 16px;
  cursor: pointer;
  padding: ${({ $collapse }) => ($collapse ? '12px 16px' : '12px 20px')};
  transition: all 0.2s ease-out;
`
const Content = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  flex: 1 0 0;
`
const Text = styled.div<{ $collapse: boolean }>`
  color: ${colors.greyTextWhiteBg};
  font-size: ${variables.fontSize14};
  font-style: normal;
  font-weight: ${variables.fontWeight400};
  line-height: 150%;
  opacity: ${({ $collapse }) => ($collapse ? '1' : '0')};
  transition: all 0.2s ease-out;
`

const IconWrapper = styled.div`
  width: 16px;
  height: 16px;
  background-color: #67dd69;
  border-radius: 50%;
  position: relative;
`
const Label = styled.div<{ $collapse: boolean }>`
  display: flex;
  padding: 0px 4px 2px 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background-color: ${colors.green1Light};
  opacity: ${({ $collapse }) => ($collapse ? '1' : '0')};
  transition: all 0.2s ease-out;
`
const LabelText = styled.div`
  color: ${colors.green};
  font-size: ${variables.fontSize10};
  font-style: normal;
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
  margin-left: auto;
`
const StyledIcon = styled(PlusIcon)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`
interface Props {
  attributeValue: string
  collapse: boolean
  onClick?: () => void
}

export const ProductLiftRequestFeatureButton: FC<Props> = (props) => {
  const { attributeValue, onClick, collapse } = props
  const [visited, setVisited] = useState(
    localStorage.getItem('productLift-sidebar') === attributeValue,
  )

  const handleClick = (): void => {
    onClick && onClick()
    if (visited) {
      return
    }
    setVisited(true)
    localStorage.setItem('productLift-sidebar', attributeValue)
  }
  return (
    <Wrapper
      $collapse={collapse}
      onMouseDown={handleClick}
      onTouchStart={handleClick}
      id={attributeValue}
      data-productlift-sidebar={attributeValue}
    >
      <Content>
        <IconWrapper>
          <StyledIcon fill={colors.white} />
        </IconWrapper>
        <Text $collapse={collapse}>Request feature</Text>
      </Content>
      {!visited && (
        <Label $collapse={collapse}>
          <LabelText>New</LabelText>
        </Label>
      )}
    </Wrapper>
  )
}
