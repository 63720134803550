import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import TimeAgo from 'react-timeago'

import LogoSmall from 'src/Assets/Svg/logoSmall.svg?react'
import { NewReleaseResponse } from 'src/Redux/newReleaseApi'

import { CongratulatoryMessage } from '../CongratulatoryMessage'
import { ReleaseTrackInfo } from '../ReleaseTrackInfo'
import {
  StyledTopBarNewRelease,
  ImgWrapper,
  ReleaseInfo,
  DateWrapper,
  ReleaseInfoText,
  InnerTopBarNewRelease,
  InnerTop,
} from '../../styles'

interface TopBarNewReleaseProps {
  handleCreateAndStartSchedule?: () => void
  releaseData: NewReleaseResponse | undefined
}

export const TopBarNewRelease: FC<TopBarNewReleaseProps> = ({
  handleCreateAndStartSchedule,
  releaseData,
}) => {
  const { t } = useTranslation()

  if (!releaseData) {
    return null
  }

  return (
    <StyledTopBarNewRelease>
      <ImgWrapper>
        <LogoSmall width='11.6px' />
      </ImgWrapper>
      <InnerTopBarNewRelease>
        <InnerTop>
          <ReleaseInfo>
            <CongratulatoryMessage />
            <ReleaseInfoText>
              {t('campaignsPage.letsPushYourTrack')}
            </ReleaseInfoText>
          </ReleaseInfo>

          <DateWrapper>
            <TimeAgo date={releaseData.releaseDate} />
          </DateWrapper>
        </InnerTop>
        <ReleaseTrackInfo
          trackName={releaseData.trackName}
          artistName={releaseData.artistName}
          trackImageUrl={releaseData.trackImageUrl}
          handleCreateAndStartSchedule={handleCreateAndStartSchedule}
        />
      </InnerTopBarNewRelease>
    </StyledTopBarNewRelease>
  )
}
