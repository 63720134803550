import { object, string } from 'yup'
import i18n from 'i18next'

export const schema = object().shape({
  email: string()
    .email(String(i18n.t('influencer.invalidEmailAddress')))
    .required(String(i18n.t('influencer.pleaseEnterTheRequiredField'))),
})

export type FormData = {
  email: string
}
