import { FC } from 'react'

import styled from 'styled-components'

import Star from 'src/Assets/Svg/sidebar-icons/Star.svg?react'
import { variables } from 'src/Styled/variables'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  background: ${({ theme }) => theme.common.secondaryBg};

  svg {
    width: 16px;
    height: 16px;
    fill: ${({ theme }) => theme.common.primaryDetails};
  }
`

const Text = styled.div`
  color: ${({ theme }) => theme.common.fourthText};
  text-align: center;
  font-family: 'Segoe UI';
  font-size: ${variables.fontSize14};
  font-style: normal;
  font-size: ${variables.fontWeight600};
  line-height: 150%;
`

export interface SidebarSubscriptionPlanProps {
  planName: string
}

export const SidebarSubscriptionPlan: FC<SidebarSubscriptionPlanProps> = ({
  planName,
}) => (
  <Container>
    <Star />
    <Text>{`${planName}`}</Text>
  </Container>
)
