import { FC, useEffect, useState } from 'react'

import Pagination from 'react-js-pagination'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'

import { removeCookiesHandler } from 'src/Constants/functions'
import { Artist, Curator, User } from 'src/Types/index'
import dayjs from 'src/Helpers/dayjs'
import Button from 'src/Components/Buttons/Button'
import { getFoundUsers } from 'src/Redux/adminPanel-process/searchUsersSlice/selectors'
import { signInAs } from 'src/Redux/auth-process/credentialsSlice/api-actions'
import { useAppDispatch } from 'src/Hooks/redux'

import { CampaignNotFound, PaginationBottom, StyledTable } from './styles'

const USER_COUNT = 50

interface UsersTableProps {
  users: User[] | Artist[] | Curator[]
  totalUsers: number
  isSearch: boolean
  loading: boolean
  getUsers: (page: number) => void
  setFoundUsers: (users: User[]) => void
}

export const UserTable: FC<UsersTableProps> = ({
  users,
  totalUsers,
  loading,
  isSearch,
  setFoundUsers,
  getUsers,
}) => {
  const navigate = useNavigate()
  const allFoundUsers = useSelector(getFoundUsers)
  const dispatch = useAppDispatch()
  const [activePage, setActivePage] = useState(1)

  const handleLoginAs = (id: number, isArtist: boolean): void => {
    removeCookiesHandler()
    void dispatch(signInAs({ id, isArtist }))
      .unwrap()
      .finally(() => {
        navigate('/')
        if (isArtist) {
          window.location.reload()
        }
      })
  }

  useEffect(() => {
    getUsers(activePage)
  }, [])

  function handlePageChange(pageNumber: number): void {
    const offset = (pageNumber - 1) * USER_COUNT
    setActivePage(pageNumber)

    if (isSearch) {
      setFoundUsers(allFoundUsers.slice(offset, offset + USER_COUNT))
    } else {
      getUsers(pageNumber)
    }
  }
  const columns: ColumnsType<User> = [
    {
      dataIndex: 'first_name',
      key: 'first_name',
      width: '14%',
      title: <div>Full name</div>,
      render: (_, value) => `${value.first_name} ${value.last_name}`,
    },
    {
      dataIndex: 'email',
      key: 'email',
      width: '14%',
      title: <div>Email</div>,
    },
    {
      dataIndex: 'isArtist',
      key: 'isArtist',
      width: '14%',
      title: <div>Role</div>,
      render: (_, value) => <div>{value.isArtist ? 'Artist' : 'Curator'}</div>,
    },
    {
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '14%',
      title: <div>Joined time</div>,
      render: (_, value) => <div>{dayjs(value.createdAt).fromNow()}</div>,
    },
    {
      dataIndex: 'last_activity',
      key: 'last_activity',
      width: '14%',
      title: <div>Last activity</div>,
      render: (_, value) => <div>{dayjs(value.last_activity).fromNow()}</div>,
    },
    {
      dataIndex: '',
      key: '',
      width: '18%',
      title: <div>Action</div>,
      render: (_, value) => (
        <div>
          <Button
            type='green'
            onClick={() => handleLoginAs(value.id as number, value.isArtist)}
          >
            Login As
          </Button>
        </div>
      ),
    },
  ]
  return (
    <>
      <StyledTable>
        <Table
          loading={loading}
          rowKey='id'
          pagination={false}
          columns={columns}
          dataSource={users as User[]}
          locale={{
            emptyText: <CampaignNotFound>Users not found</CampaignNotFound>,
          }}
        />
      </StyledTable>

      <PaginationBottom>
        <Pagination
          activePage={activePage}
          itemsCountPerPage={50}
          pageRangeDisplayed={5}
          totalItemsCount={totalUsers || 0}
          onChange={handlePageChange}
        />
      </PaginationBottom>
    </>
  )
}
