import styled from 'styled-components'

import { colors, variables } from 'src/Styled/variables'

export const ContainerDesc = styled.div`
  padding: 17.5px 24px;
  display: grid;
  grid-column-gap: 16px;
  grid-template-columns: 1fr 1fr 1fr 1.27fr;
  background: ${colors.greyBg};
`

export const ContainerMob = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  background: ${colors.white};
  gap: 16px;
`

export const ContainerMobHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
export const ValueItemMob = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: ${colors.greyBg};
  padding: 9.5px 16px;
  border-radius: 4px;
`
export const ValuesListMob = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2px;
`
export const Value = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  line-height: 150%;
`
export const LevelMob = styled.div`
  font-size: ${variables.fontSize18};
  line-height: 150%;
  font-weight: ${variables.fontWeight600};
`
export const ValueMob = styled.div`
  font-size: ${variables.fontSize14};
  line-height: 150%;
  font-weight: ${variables.fontWeight400};
`
