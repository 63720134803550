import React, { FC } from 'react'

import { Radio, RadioChangeEvent } from 'antd'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { PrimaryGenreOrLanguageItem } from 'src/Components/GenresList/PrimaryGenreOrLanguageItem'
import { SelectedLanguage } from 'src/Containers/ScheduleSpotifyWrapper/components/ModalsContainer/LanguagesModal'
import { capitalizeFirstLetter } from 'src/Helpers/layoutHelpers'
import { useHideLanguageMatchingExperiment } from 'src/Hooks/Experiments/useHideLanguageMatchingExperiment'
import { PrimaryRadio } from 'src/Components/SchedulePaymentMethod/PrimaryRadio'
type Props = {
  selectedLanguage: SelectedLanguage | null
  targetLanguage?: number | null
  onClick: () => void
  onChange: (id: number) => void
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`

const Title = styled.div`
  color: ${({ theme }) => theme.common.primaryText};
  font-family: 'Segoe UI';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
`

const StyledRadioGroup = styled(Radio.Group)`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 16px;
`
const StyledPrimaryRadio = styled(PrimaryRadio)`
  &.ant-radio-wrapper {
    margin-inline-end: 0px;
  }
  &.ant-radio-wrapper span.ant-radio + * {
    padding-inline-end: 0px;
  }
`
const Text = styled.span`
  color: ${({ theme }) => theme.common.primaryText};
  font-family: 'Segoe UI';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`

const SubText = styled.span`
  color: ${({ theme }) => theme.common.primaryPlaceholder};
`
export const LanguagesModalSpotifySelectedLanguage: FC<Props> = (props) => {
  const { t } = useTranslation()
  const { selectedLanguage, onClick, onChange, targetLanguage } = props
  const { isHideLanguageExperiment } = useHideLanguageMatchingExperiment()

  const handleChange = (e: RadioChangeEvent): void => {
    onChange(Number(e.target.value))
  }
  return (
    <Container>
      <PrimaryGenreOrLanguageItem
        name={capitalizeFirstLetter(selectedLanguage?.label || '')}
        selected
        onClick={onClick}
      />
      {!isHideLanguageExperiment && (
        <SubContainer>
          <Title>{t('scheduleCampaignPage.selectTheTypeOfMatching')}</Title>
          <StyledRadioGroup
            value={String(targetLanguage)}
            onChange={handleChange}
          >
            <StyledPrimaryRadio value='3'>
              <Text>{t('scheduleCampaignPage.matchSelectedAndOther')}</Text>
              <SubText>{t('scheduleCampaignPage.recommended')}</SubText>
            </StyledPrimaryRadio>
            <StyledPrimaryRadio value='0'>
              <Text>{t('scheduleCampaignPage.matchSelectedOnly')}</Text>
            </StyledPrimaryRadio>
          </StyledRadioGroup>
        </SubContainer>
      )}
    </Container>
  )
}
