import React, { FC, ReactNode, useEffect, useMemo } from 'react'

import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import ArrowIcon from 'src/Assets/Svg/arrow.svg?react'
import { campaignsList } from 'src/Router/routes'
import { StepItem } from 'src/Containers/ui/Steps/components'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'

import {
  ScheduleTabsBackButton,
  ScheduleTabsContainer,
  PageTitle,
  PageTitleContainer,
  StepsWrapper,
} from './styles'
interface ScheduleTabsProps {
  children: [ReactNode, ReactNode]
}

export enum ScheduleSteps {
  SETTINGS = 'settings',
  CHECKOUT = 'checkout',
}
interface Step {
  id: number
  title: string
  query: { step?: ScheduleSteps }
}

const STEPS: Step[] = [
  {
    id: 1,
    title: 'Settings',
    query: { step: ScheduleSteps.SETTINGS },
  },
  {
    id: 2,
    title: 'Checkout',
    query: { step: ScheduleSteps.CHECKOUT },
  },
]

export const ScheduleTabs: FC<ScheduleTabsProps> = ({ children }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { search, setSearch } = useCustomSearchParams()

  const handleGoBack = (): void => {
    if (search.get('step') === ScheduleSteps.CHECKOUT) {
      setSearch({ step: ScheduleSteps.SETTINGS })
      return
    }
    navigate(campaignsList)
  }

  const handleClick = (item: Step): void => {
    if (item.id === STEPS[0].id) {
      setSearch(item.query)
    }
  }

  const MAP_STEPS = useMemo(
    () => ({
      [ScheduleSteps.SETTINGS]: children[0],
      [ScheduleSteps.CHECKOUT]: children[1],
    }),
    [children],
  )
  useEffect(() => {
    const step = search.get('step')

    const paypalEmail = search.get('paypal_email')
    const paymentStatus = search.get('paymentStatus')

    const isNotFromPaymentPage = !paypalEmail && !paymentStatus

    const isStepValid = Object.keys(MAP_STEPS).includes(step as ScheduleSteps)

    if ((!step || !isStepValid) && isNotFromPaymentPage) {
      setSearch(STEPS[0].query)
    }
  }, [search, setSearch, MAP_STEPS])

  return (
    <ScheduleTabsContainer>
      <PageTitleContainer>
        <ScheduleTabsBackButton onClick={handleGoBack}>
          <ArrowIcon />
        </ScheduleTabsBackButton>
        <PageTitle>{t('scheduleCampaignPage.startCampaign')}</PageTitle>
      </PageTitleContainer>
      <StepsWrapper>
        {STEPS.map((item) => (
          <StepItem
            isCurrent={search.get('step') === item.query.step}
            isPassed={
              search.get('step') === ScheduleSteps.CHECKOUT &&
              item.id === STEPS[0].id
            }
            id={item.id}
            title={item.title}
            key={item.id}
            onClick={() => handleClick(item)}
          />
        ))}
      </StepsWrapper>

      {MAP_STEPS[search.get('step') as ScheduleSteps] || children[0]}
    </ScheduleTabsContainer>
  )
}
