import { FunctionComponent, ReactElement, useMemo } from 'react'

import { Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { Desktop, Mobile } from 'src/Components/Responsive'
import dayjs from 'src/Helpers/dayjs'
import { MobileBalanceItems, TransactionTypeEnum } from 'src/Types/index'
import { LoadingSpinner } from 'src/Containers/ui'
import {
  getCreatorTransactionsSelector,
  getTransactionsLoading,
} from 'src/Redux/transactions-process/selectors'
import TikTokIcon from 'src/Assets/Svg/tiktok.svg?react'
import { useAppSelector } from 'src/Hooks/redux'
import { formatDate } from 'src/Constants/formatDate'
import { colors } from 'src/Styled/variables'
import { HUNDREDTHS } from 'src/Constants/numeric'

import { MobileBalanceList } from '../MobileBalanceList'

import {
  MobileInfoText,
  PageContentContainer,
  HeaderTitle,
  InfoText,
  DescriptionCard,
  DescriptionCardText,
  Amount,
} from './styles'

export const InfluencerBalanceTable: FunctionComponent = (): ReactElement => {
  const { t } = useTranslation()
  const isBalanceHistoryLoading = useAppSelector(getTransactionsLoading)
  const transactions = useSelector(getCreatorTransactionsSelector)
  const getAmountValue = (transactionType: string, amount: number): string => {
    if (transactionType === 'payout' || transactionType === 'requested') {
      return `-$${Number(amount).toFixed(HUNDREDTHS)}`
    }
    return `$${Number(amount).toFixed(HUNDREDTHS)}`
  }

  const columns: ColumnsType<MobileBalanceItems> = [
    {
      dataIndex: 'date',
      key: 'date',
      width: '14%',
      title: (
        <div>
          <HeaderTitle>Date</HeaderTitle>
        </div>
      ),
    },
    {
      title: <HeaderTitle>Status</HeaderTitle>,
      dataIndex: 'type',
      key: 'type',
      width: '20%',

      render: (transactionType) => <InfoText>{transactionType}</InfoText>,
    },
    {
      title: <HeaderTitle>Description</HeaderTitle>,
      dataIndex: ['description'],
      key: 'description',
      render: ({ title, description }) => (
        <DescriptionCard>
          <TikTokIcon />
          <DescriptionCardText>
            <span>{title}</span>
            <InfoText>{description}</InfoText>
          </DescriptionCardText>
        </DescriptionCard>
      ),
      width: '52%',
    },
    {
      title: <HeaderTitle>Amount</HeaderTitle>,
      dataIndex: 'amount',
      key: 'amount',
      render: ({ amount, type }) => (
        <Amount green={type === 'payment'}>
          {getAmountValue(type as TransactionTypeEnum, amount as number)}
        </Amount>
      ),
      width: '14%',
    },
  ]

  const creatorTransactions = useMemo(() => {
    const transactionsTypes: {
      [key: string]: {
        status: string
        description?: string
      }
    } = {
      payment: {
        status: 'Reward received',
      },
      payout: {
        status: 'Payout successful',
        description: 'Payout sent to your bank account',
      },
      canceled: {
        status: 'Payout canceled',
        description: 'Payout has been returned and canceled',
      },
      requested: {
        status: 'Payout requested',
      },
    }

    return (
      transactions?.map((transaction) => {
        let type: {
          status: string
          description?: string
        }

        if (
          transaction.type === 'payout' &&
          transaction.payoutStatus === 'paid'
        ) {
          type = transactionsTypes.payout
        } else if (
          transaction.type === 'payout' &&
          transaction.payoutStatus === 'awaiting'
        ) {
          type = transactionsTypes.requested
        } else {
          type = transactionsTypes[transaction.type]
        }

        return {
          id: transaction.id,
          date: dayjs(transaction.createdAt).format(formatDate.formatDate1),
          type: type?.status,
          description: {
            title:
              transaction.type !== 'payment'
                ? 'For created videos'
                : transaction.description,
            description: type?.description,
          },

          amount: { amount: transaction.amount, type: transaction.type },
        }
      }) || []
    )
  }, [transactions])

  return (
    <>
      <Desktop>
        <PageContentContainer>
          <Table
            rowKey='id'
            loading={{
              indicator: <LoadingSpinner color={colors.green} size='large' />,
              spinning: isBalanceHistoryLoading,
            }}
            pagination={false}
            columns={columns}
            dataSource={creatorTransactions}
            locale={{
              emptyText: (
                <div>
                  {!isBalanceHistoryLoading && (
                    <MobileInfoText>
                      {t('balancePage.youHaveNoRewardsYet')}
                    </MobileInfoText>
                  )}
                </div>
              ),
            }}
          />
        </PageContentContainer>
      </Desktop>
      <Mobile>
        {creatorTransactions && (
          <MobileBalanceList items={creatorTransactions} />
        )}
      </Mobile>
    </>
  )
}
