import React, { useEffect, useRef } from 'react'

import {
  init,
  replayIntegration,
  reactRouterV6BrowserTracingIntegration,
  setUser,
} from '@sentry/react'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'
import { useSelector } from 'react-redux'

import { APP } from 'src/Configs/App'

import { getUserId } from '../Redux/auth-process/userSlice/selectors'

const { MODE } = import.meta.env as Record<string, string>

export const useSentry = (): null => {
  const isSentryInitialized = useRef(false)
  const userId = useSelector(getUserId)

  useEffect(() => {
    const isProductionMode = MODE === 'production'
    const isSentry =
      APP.SENTRY_DSN && !isSentryInitialized.current && isProductionMode

    if (isSentry) {
      init({
        dsn: APP.SENTRY_DSN,
        release: 'SoundCampaignMainApp@10-1',
        environment: 'production',
        integrations: [
          reactRouterV6BrowserTracingIntegration({
            useEffect: React.useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
          }),
          replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
          }),
        ],
        tracesSampleRate: 1.0,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        ignoreErrors: [/\b_tfa\b/, /\bobApi\b/, /\bqp\b/],
      })
    }

    isSentryInitialized.current = true
  }, [])

  useEffect(() => {
    if (isSentryInitialized.current && userId) {
      setUser({ id: String(userId) })
    }
  }, [userId])

  return null
}
