import React, { FC } from 'react'

import styled from 'styled-components'
import ReactLoading from 'react-loading'

import { TrackAudioPlayer } from 'src/Components/TrackAudioPlayer'
import PauseIcon from 'src/Assets/Svg/player/pause.svg?react'
import PlayIcon from 'src/Assets/Svg/player/play.svg?react'
import TrackCoverDefault from 'src/Assets/Svg/common/track-cover.svg'
import { SmartImage } from 'src/Components/SmartImage'
import { variables } from 'src/Styled/variables'

import { TrackDetailsProps } from '../../index'

export const TrackCoverImage = styled(SmartImage)`
  display: flex;
  width: 68px;
  height: 68px;
  align-items: center;
`

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: stretch;
`

const IconWrapper = styled.div`
  position: absolute;
  top: 26px;
  left: 26px;
`

const TrackImageWrapper = styled.div`
  cursor: pointer;
  position: relative;
  border-radius: 12px;
  overflow: hidden;
`

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
`

const Loader = styled(ReactLoading)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const HighlightedText = styled.span`
  color: ${({ theme }) => theme.common.primaryText};
  font-family: 'Segoe UI';
  font-style: normal;
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
`

const Text = styled.div`
  color: ${({ theme }) => theme.common.primaryText};
  font-family: 'Segoe UI';
  font-style: normal;
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight400};
  line-height: 150%;
`

type Props = Pick<
  TrackDetailsProps,
  'coverUrl' | 'trackName' | 'trackAuthorName' | 'duration' | 'trackPreviewUrl'
>

export const Preview: FC<Props> = ({
  coverUrl,
  trackName,
  trackAuthorName,
  trackPreviewUrl,
  duration,
}) => (
  <Container>
    <TrackAudioPlayer
      render={({ loading, currentTrackUrl, isPlaying, onClick }) => (
        <TrackImageWrapper
          onClick={(e) => {
            onClick(e, trackPreviewUrl)
          }}
        >
          <IconWrapper>
            {loading && currentTrackUrl === trackPreviewUrl && (
              <Loader type='spin' width={'30px'} height={'30px'} />
            )}
            {isPlaying ? <PauseIcon /> : <PlayIcon />}
          </IconWrapper>

          <TrackCoverImage src={coverUrl} fallbackSrc={TrackCoverDefault} />
        </TrackImageWrapper>
      )}
    />
    <InfoContainer>
      <div>
        <HighlightedText>{trackName}</HighlightedText>
        <Text>{trackAuthorName}</Text>
      </div>
      <Text>{duration}</Text>
    </InfoContainer>
  </Container>
)
