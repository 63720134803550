import React, { FC } from 'react'

import styled from 'styled-components'
import { Modal } from 'antd'

import { PrimaryDottedButton } from 'src/Components/Buttons/PrimaryDottedButton'
import { CampaignPlatformTypeEnum } from 'src/Types'
import { MAX_MOBILE_WIDTH, variables } from 'src/Styled/variables'
import guideImage1 from 'src/Assets/Webp/how-to-get-url-1.webp'
import guideImage2 from 'src/Assets/Webp/how-to-get-url-2.webp'
import guideImage3 from 'src/Assets/Webp/how-to-get-url-3.webp'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'

const StyledModal = styled(Modal)`
  max-width: 600px;
  width: 100% !important;

  .ant-modal-body {
    width: 100% !important;
    display: flex;
    flex-direction: column;
    gap: 24px;
    position: relative !important;
    padding-top: 60px;
  }

  .ant-modal-content {
    width: 100% !important;
    padding: 0;
    border-radius: 16px;
    border: 1px solid #e7e7e7;
    background: #fff;
    box-shadow: 0px 0px 12px 0px rgba(51, 51, 51, 0.12);
  }

  .ant-modal-close-icon {
    svg {
      path {
        fill: ${({ theme }) => theme.common.thirdBg};
      }
    }
  }

  @media (max-width: ${MAX_MOBILE_WIDTH}) {
    top: 9vh;
    max-width: 288px !important;
    width: 100% !important;
    height: calc(100vh - 18vh) !important;
    border-radius: 16px;
  }
`

const StyledPrimaryDottedButton = styled(PrimaryDottedButton)`
  align-self: center;
  height: 48px;
`

const Title = styled.span`
  font-family: 'Segoe UI';
  font-weight: ${variables.fontWeight600};
  font-size: ${variables.fontSize14};
  font-style: normal;
  line-height: 150%;
  color: ${({ theme }) => theme.common.primaryText};
`

const SubTitle = styled.span`
  font-family: 'Segoe UI';
  font-weight: ${variables.fontWeight600};
  font-size: ${variables.fontSize12};
  font-style: normal;
  line-height: 150%;
  color: ${({ theme }) => theme.common.primaryText};
`

const Text = styled.li`
  margin: 0;
  padding: 0;
  font-family: 'Segoe UI';
  font-weight: ${variables.fontWeight400};
  font-size: ${variables.fontSize12};
  font-style: normal;
  line-height: 150%;
  color: ${({ theme }) => theme.common.primaryText};
`

const ImageWrapper = styled.div`
  width: 172px;
  height: 356px;
  border-radius: 16px;
  overflow: hidden;
`

const TitleWrapper = styled.div`
  width: 100%;
  height: 56px;
  position: absolute;
  top: 0px;
  padding-left: 24px;
  padding-top: 16px;
`

const ItemInfoWrapper = styled.div`
  display: flex;
  max-width: 172px;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
`

const HeaderBorder = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.common.primaryBorder};
  position: absolute;
  top: 54px;
  left: 0;
`

const GuideItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`

const GuideItemsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-self: center;
  justify-content: space-between;
  padding: 24px;

  @media (max-width: ${MAX_MOBILE_WIDTH}) {
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }
`

interface HowToGetTrackUrlProps {
  platform: CampaignPlatformTypeEnum
}

const guideItems = [
  {
    title: 'Go to the Discovery Tab',
    steps: [
      'Open TikTok on your phone (this cannot be done on a desktop)',
      'Tap the Discovery tab (compass icon)',
    ],
    image: guideImage1,
  },
  {
    title: 'Search for Your Track',
    steps: [
      'Type the track and artist name in the search bar',
      'Go to the Sounds tab',
      'Tap on name of your track to open its page',
    ],
    image: guideImage2,
  },
  {
    title: 'Copy the Link',
    steps: [
      'Tap the Share button (arrow icon)',
      'Select Copy link',
      'Paste this link into Soundcamp’s search field',
    ],
    image: guideImage3,
  },
]

export const HowToGetTrackUrl: FC<HowToGetTrackUrlProps> = ({ platform }) => {
  const { setSearch, search } = useCustomSearchParams()

  const isHowGetTrackModalVisible =
    search.get('isHowGetTrackModalVisible') === 'true'

  const isTikTok = platform === CampaignPlatformTypeEnum.TIKTOK

  if (!isTikTok) {
    return null
  }

  const onOpen = (): void => {
    setSearch(
      {
        isHowGetTrackModalVisible: 'true',
      },
      { replace: true },
    )
  }

  const onClose = (): void => {
    setSearch(
      {
        isHowGetTrackModalVisible: null,
      },
      { replace: true },
    )
  }

  return (
    <>
      <StyledModal
        open={isHowGetTrackModalVisible}
        footer={null}
        onCancel={onClose}
      >
        <HeaderBorder />
        <TitleWrapper>
          <Title>How to get your track URL?</Title>
        </TitleWrapper>
        <GuideItemsContainer>
          {guideItems.map((item, index) => (
            <GuideItemContainer key={item.title}>
              <ItemInfoWrapper>
                <SubTitle>{item.title}</SubTitle>
                <ul>
                  {item.steps.map((step) => (
                    <Text key={step}>{step}</Text>
                  ))}
                </ul>
              </ItemInfoWrapper>
              <ImageWrapper>
                <img src={item.image} alt={`Guide image ${index}`} />
              </ImageWrapper>
            </GuideItemContainer>
          ))}
        </GuideItemsContainer>
      </StyledModal>

      <StyledPrimaryDottedButton onClick={onOpen}>
        How to get your track URL?
      </StyledPrimaryDottedButton>
    </>
  )
}
