import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { DotedButton } from 'src/Components/Buttons/DotedButton'
import { hidePartOfEmailWithStars } from 'src/Constants/functions'
import { colors, variables } from 'src/Styled/variables'

const ConfirmationText = styled.div`
  font-weight: ${variables.fontWeight600};
  font-size: ${variables.fontSize18};
  line-height: 27px;
  margin-bottom: 16px;
  text-align: center;
`

const Center = styled.div`
  font-size: ${variables.fontSize14};
  line-height: 21px;
  text-align: center;
  margin-bottom: 52px;
  @media (min-width: 576px) {
    margin-bottom: 29px;
  }
`

const ButtonsWrapper = styled.div`
  font-size: ${variables.fontSize14};
  line-height: 21px;
  color: ${colors.mainText};
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  align-items: center;
  margin-bottom: 8px;
`

interface LoginCreatorConfirmationProps {
  email: string
  onClickSendAgain: () => void
  onClickChangeEmail: () => void
}

export const LoginCreatorConfirmation: FC<LoginCreatorConfirmationProps> = ({
  onClickSendAgain,
  email,
  onClickChangeEmail,
}) => {
  const { t } = useTranslation()
  return (
    <>
      <ConfirmationText>
        {t('influencer.confirmationLinkWaSentTo')}&nbsp;
        {hidePartOfEmailWithStars(email)}
      </ConfirmationText>
      <Center>{t('influencer.pleaseCheckYourEmail')}</Center>
      <ButtonsWrapper>
        <DotedButton color={colors.mainText} onClick={onClickSendAgain}>
          {t('influencer.sendAgain')}
        </DotedButton>
        <DotedButton color={colors.mainText} onClick={onClickChangeEmail}>
          {t('influencer.changeEmail')}
        </DotedButton>
      </ButtonsWrapper>
    </>
  )
}
