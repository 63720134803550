import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'

import { api } from 'src/Services/api'
import { APP } from 'src/Configs/App'

import { NameSpace, ErrorCode } from '../types'

interface SpotifyToken {
  access_token: string
}

export const getSpotifyAccessToken = createAsyncThunk<SpotifyToken, boolean>(
  `${NameSpace.SpotifyToken}/getSpotifyAccessToken`,
  async (isAdmin: boolean, { rejectWithValue }) => {
    try {
      const { data } = await api.post<SpotifyToken>(
        `${APP.SERVER}/auth/access-spotify-token`,
        {
          isAdmin,
        },
      )
      return data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      return rejectWithValue({
        message: response?.data.message,
        code: response?.status || ErrorCode.InternalServerError,
      })
    }
  },
)

interface FreeSpotifyToken {
  access_token: string

  country: string
}

export const getFreeSpotifyAccessToken = createAsyncThunk(
  `${NameSpace.SpotifyToken}/getFreeSpotifyAccessToken`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await api.get<FreeSpotifyToken>(
        `${APP.SERVER}/auth/free-access-spotify-token`,
      )
      return data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      return rejectWithValue({
        message: response?.data.message,
        code: response?.status || ErrorCode.InternalServerError,
      })
    }
  },
)
