import { useCallback, useRef } from 'react'

import { useSelector } from 'react-redux'

import {
  enteredTrackUrlEvent,
  startedEnterTrackEvent,
  trackAddedEvent,
} from 'src/Helpers/TagManager'
import { CampaignPlatformTypeEnum } from 'src/Types'
import { getSchedulePlatform } from 'src/Redux/campaign-process/spotifyCampaignSchedule-process/selectors'
import { trackAddedMpEvent } from 'src/Redux/ga4-process/api-actions'
import {
  getIsFirstCampaign,
  getUserId,
} from 'src/Redux/auth-process/userSlice/selectors'
import { getAmountOfCampaigns } from 'src/Redux/campaignList-process'

import { useAppDispatch } from '../redux'

interface TrackAddedEventParams {
  trackName: string
  trackDuration: number
  trackId: string | number
  artistName: string
  platformType: CampaignPlatformTypeEnum
}

export const usePageViewEvent = (): {
  trackAddedEventHandler: (params: TrackAddedEventParams) => void
  enteredTrackUrlEventHandler: () => void
  startedEnterTrackEventHandler: () => void
} => {
  const dispatch = useAppDispatch()

  const isEventTrackEnteredSent = useRef(false)

  const amountOfCampaigns = useSelector(getAmountOfCampaigns)
  const isFirstCampaign = useSelector(getIsFirstCampaign)
  const userId = useSelector(getUserId)
  const platform = useSelector(getSchedulePlatform)

  const campaignNumber = amountOfCampaigns + 1

  const trackAddedEventHandler = useCallback(
    ({
      trackName,
      trackDuration,
      trackId,
      artistName,
      platformType,
    }: TrackAddedEventParams): void => {
      trackAddedEvent({
        campaignNumber,
        trackName,
        trackDuration,
        trackId,
        artistName,
        isFirstCampaign: Boolean(isFirstCampaign),
        platformName: platformType,
        userId: userId || null,
      })

      void dispatch(
        trackAddedMpEvent({
          campaignPlatform: platformType,
          trackId: String(trackId),
          userId: Number(userId || null),
        }),
      )
    },
    [campaignNumber, dispatch, isFirstCampaign, userId],
  )

  const enteredTrackUrlEventHandler = (): void => {
    platform === CampaignPlatformTypeEnum.TIKTOK &&
      enteredTrackUrlEvent({
        campaignPlatform: platform,
        userId,
        isFirstCampaign,
        campaignNumber,
      })
  }

  const startedEnterTrackEventHandler = (): void => {
    if (
      platform === CampaignPlatformTypeEnum.TIKTOK &&
      !isEventTrackEnteredSent.current
    ) {
      startedEnterTrackEvent({
        campaignPlatform: platform,
        userId,
        isFirstCampaign,
        campaignNumber,
      })
      isEventTrackEnteredSent.current = true
    }
  }

  return {
    trackAddedEventHandler,
    enteredTrackUrlEventHandler,
    startedEnterTrackEventHandler,
  }
}
