import styled from 'styled-components'

import { colors, device, variables } from 'src/Styled/variables'

export const ContainerPayment = styled.div`
  display: grid;
  padding: 24px 24px 20px 24px;
  background-color: ${colors.white};
  border-radius: 4px;
  margin-bottom: 16px;
  gap: 16px;
`

interface Props {
  withGap?: boolean
}

export const MorePaymentContainer = styled(ContainerPayment)<Props>`
  padding: 24px;
  gap: ${(props: Props) => (props.withGap ? '8px' : '0px')};
`

export const MorePaymentTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const StepTitle = styled.div`
  font-size: ${variables.fontSize18};
  font-weight: ${variables.fontWeight600};
  color: ${colors.mainText};
  line-height: 150%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 16px;
`

export const CardsImgWrapper = styled.div`
  display: flex;
  padding: 12px 0 4px 8px;
  flex-wrap: wrap;
  gap: 4px;
  @media ${device.smallScreen} {
    padding: 12px 0 12px 4px;
  }
`
export const Card = styled.div`
  display: flex;
  width: 48px;
  height: 24px;
  justify-content: center;
  align-items: center;
`

export const ContentWrapper = styled.div`
  margin-top: 12px;
  padding-left: 24px;
`

export const PayPalLogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`

export const RadioLabelContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media ${device.mobileMax} {
    flex-direction: column;

    ${CardsImgWrapper} {
      margin-top: 5px;
    }
  }
`

export const StyledImgWrapper = styled.div`
  margin-top: 6px;
`

export const StyledSpan = styled.span`
  font-size: ${variables.fontSize18};
  display: flex;
  align-items: center;
  color: ${colors.mainText};
  font-style: normal;
  font-weight: ${variables.fontWeight400};
  line-height: 150%;
  white-space: break-spaces;
  @media ${device.mobileMax} {
    padding-left: 8px;
  }
`

export const StyledRadio = styled.div<{ isInner?: boolean }>`
  display: grid;
  width: 100%;
  grid-template-columns: 16px 1fr;
  gap: 8px;

  .ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
    border-color: ${colors.greyTextBlackBg};
    background: ${colors.errorBg};
  }
  .ant-radio-wrapper-disabled {
    opacity: 0.5;
  }
  .ant-radio-wrapper:hover {
    .ant-radio-inner {
      border-color: ${colors.greyTextBlackBg};
    }
  }

  .ant-radio-input {
    animation-duration: 0s !important;
  }
  .ant-radio-inner {
    border-color: ${colors.greyTextBlackBg};
    background: ${colors.errorBg};
    animation-duration: 0s !important;

    &:after {
      background-color: ${colors.green};
    }
  }

  .ant-radio-input:focus + .ant-radio-inner,
  .ant-radio-input:hover + .ant-radio-inner {
    border-color: ${colors.greyTextBlackBg};
    background: ${colors.errorBg};
    box-shadow: none;

    &:after {
      background-color: ${colors.green};
      box-shadow: none;
    }
  }

  &:hover {
    .ant-radio-checked .ant-radio-inner {
      border-color: ${colors.greyTextBlackBg};
      background: ${colors.errorBg};
      box-shadow: none;

      &:after {
        background-color: ${colors.green};
        box-shadow: none;
      }
    }
  }

  @media ${device.mobileMax} {
    height: unset;
  }
`

export const Content = styled.div<{ active?: boolean }>`
  display: ${({ active }) => (active ? 'flex' : 'none')};
  font-size: 16px !important;
`
export const PaymentMethodsListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 8px;
  gap: 4px;
  @media ${device.smallScreen} {
    row-gap: 0px;
  }
`
export const RadioGroupContainer = styled.div`
  .ant-radio-group {
    width: 100% !important;
  }
`
