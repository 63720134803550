import React, { FC } from 'react'

export interface SmartImageProps
  extends React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > {
  fallbackSrc: string
}

export const SmartImage: FC<SmartImageProps> = (props) => {
  const { fallbackSrc, src, ...rest } = props

  const handleError = (event: React.SyntheticEvent<HTMLImageElement>): void => {
    event.currentTarget.onerror = null
    event.currentTarget.src = fallbackSrc
  }
  return <img {...rest} src={src || fallbackSrc} onError={handleError} />
}
