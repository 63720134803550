import { FC, HTMLProps, MouseEvent } from 'react'

import { Button as AntButton } from 'antd'
import { useDebouncedCallback } from 'use-debounce'

import { StyledDotedButton } from './styles'

interface DotedButtonProps extends HTMLProps<HTMLButtonElement> {
  color: string
  loading?: boolean
}

export const DotedButton: FC<DotedButtonProps> = (props) => {
  const { className, color, children, disabled, loading, onClick } = props

  const debouncedClick = useDebouncedCallback(
    (event) => {
      onClick?.(event as MouseEvent<HTMLButtonElement>)
    },
    0,
    { trailing: true },
  )

  return (
    <StyledDotedButton className={className} color={color}>
      <AntButton
        disabled={disabled || false}
        loading={loading}
        onClick={debouncedClick}
      >
        {children}
      </AntButton>
    </StyledDotedButton>
  )
}
