import styled from 'styled-components'

import { colors, variables } from 'src/Styled/variables'
import { CopyButton } from 'src/Containers/ui/CopyButton/CopyButton'

export const StyledCopyButton = styled(CopyButton)`
  height: 40px;
  @media (min-width: 1160px) {
    width: fit-content;
    width: calc(100% / 3 - 32px);
  }
`

export const ReferralLink = styled.a`
  display: flex;
  height: 40px;
  width: 100%;
  padding: 9.5px 40px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 27px;
  border: 1px solid ${colors.greyStroke};
  background: ${colors.white};
  color: ${colors.mainText};
  font-size: ${variables.fontSize14};
  line-height: 150%;
  white-space: nowrap;
  &:hover {
    color: ${colors.mainText};
  }
  &:focus {
    color: ${colors.mainText};
  }
  @media (min-width: 1160px) {
    width: calc(100% / 3 - 32px);
  }
`

export const InviteBlockContainer = styled.div`
  display: flex;
  padding: 16px 24px 24px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 4px;
  background: ${colors.white};
  margin-bottom: 16px;
  width: 100%;
`
export const Description = styled.div``
export const InviteBlockTitle = styled.div`
  color: ${colors.mainText};
  font-size: ${variables.fontSize18};
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
`
export const ButtonsBlock = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;

  @media (min-width: 1160px) {
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }
`
