import React from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { variables } from 'src/Styled/variables'
import { getInfluencerId } from 'src/Redux/auth-process/userSlice/selectors'
import {
  selectNewSoundsCount,
  useNewSoundsQuery,
} from 'src/Redux/creatorNewSoundsApi'
import { useAppSelector } from 'src/Hooks/redux'
import {
  selectInProgressSoundsCount,
  useInProgressSoundsQuery,
} from 'src/Redux/creatorInProgressSoundsApi'
import {
  selectSubmittedSoundsCount,
  useSubmittedSoundsQuery,
} from 'src/Redux/creatorSubmittedSoundsApi'

import { NewSoundsList } from '../SoundsList/NewSoundsList'
import { InProgressSoundsList } from '../SoundsList/InProgressSoundsList'
import { SubmittedSoundsList } from '../SoundsList/SubmittedSoundsList'
import { TabKeysEnum } from '../../types'

export interface Tab {
  key: string
  label: React.ReactNode
  children: React.ReactNode
}

interface UseTracksTableI {
  tabs: Tab[]
}

export const TabPane = styled.span`
  display: flex;
  padding: 9px 24px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  align-self: stretch;
`

export const TabPaneText = styled.span`
  font-size: ${variables.fontSize14};
  font-style: normal;
  font-weight: ${variables.fontWeight400};
  line-height: 150%;
`

export const useTracksTable = (): UseTracksTableI => {
  const { t } = useTranslation()
  const influencerId = useAppSelector(getInfluencerId)

  const { newSoundsCount, isError } = useNewSoundsQuery(
    {
      creatorId: influencerId,
      pageNumber: 1,
    },
    { selectFromResult: selectNewSoundsCount },
  )

  const { inProgressSoundsCount } = useInProgressSoundsQuery(1, {
    selectFromResult: selectInProgressSoundsCount,
  })

  const { submittedSoundsCount } = useSubmittedSoundsQuery(1, {
    selectFromResult: selectSubmittedSoundsCount,
  })

  const getTabs = (): Tab[] => [
    {
      key: TabKeysEnum.NEW,
      label: (
        <TabPane>
          <TabPaneText>{t('soundsPage.newSounds')}</TabPaneText>
          <TabPaneText>{isError ? 0 : newSoundsCount}</TabPaneText>
        </TabPane>
      ),
      children: <NewSoundsList />,
    },
    {
      key: TabKeysEnum.IN_PROGRESS,
      label: (
        <TabPane>
          <TabPaneText>{t('soundsPage.inProgress')}</TabPaneText>
          <TabPaneText>{inProgressSoundsCount}</TabPaneText>
        </TabPane>
      ),
      children: <InProgressSoundsList />,
    },
    {
      key: TabKeysEnum.SUBMITTED,
      label: (
        <TabPane>
          <TabPaneText>{t('soundsPage.submitted')}</TabPaneText>
          <TabPaneText>{submittedSoundsCount}</TabPaneText>
        </TabPane>
      ),
      children: <SubmittedSoundsList />,
    },
  ]

  return {
    tabs: getTabs(),
  }
}
