import styled from 'styled-components'

import { colors, variables } from 'src/Styled/variables'

export const PlatformLinkWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 16px;
  align-self: stretch;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  .mdi-icon {
    height: 16px;
    width: 16px;
    fill: ${colors.badgeGreen};
    margin: 0;
  }
`

export const PlatformLink = styled.a`
  display: flex;
  flex: 1 0 0;
  justify-content: center;
  align-items: center;
  height: 40px;
  padding: 9.5px 0;
  border-radius: 27px;
  border: 1px solid ${colors.mainText};
  background: ${colors.white};
  white-space: nowrap;
  color: ${colors.mainText};
  font-size: ${variables.fontSize14};
  font-style: normal;
  font-weight: ${variables.fontWeight400};
  line-height: 150%;
  .ant-spin {
    display: none;
  }
  &:hover {
    color: ${colors.mainText};
  }
  &:focus {
    color: ${colors.mainText};
    border: 1px solid ${colors.greyTextBlackBg};
    #content {
      display: none;
    }
    .ant-spin {
      display: block;
    }
  }
`
