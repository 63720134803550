import { FC } from 'react'

import CachedIcon from 'mdi-react/CachedIcon'
import { useTranslation } from 'react-i18next'

import { Desktop, Mobile } from 'src/Components/Responsive'
import Button from 'src/Components/Buttons/Button'
import { colors } from 'src/Styled/variables'
import ConnectToSpotifyModal from 'src/Containers/ui/ConnectToSpotifyModal'

import { usePlaylistsSocket } from '../usePlaylistsSocket'

import { ButtonBody, IconContainer, Loading, RefreshBtnMobile } from './styles'

export const RefreshButton: FC = () => {
  const { t } = useTranslation()
  const { loading, toggle, isModalSpotify, checkAccessToken } =
    usePlaylistsSocket()
  return (
    <div>
      <Desktop>
        <Button type='white' disabled={loading} onClick={checkAccessToken}>
          {loading ? (
            <Loading
              data-testid='loading-spinner'
              color={colors.greyTextWhiteBg}
              height={24}
              type='spinningBubbles'
            />
          ) : (
            <ButtonBody>
              <IconContainer>
                <CachedIcon size='16' />
              </IconContainer>
              {t('playlistsPage.refreshData')}
            </ButtonBody>
          )}
        </Button>
      </Desktop>
      <Mobile>
        <RefreshBtnMobile disabled={loading} onClick={checkAccessToken}>
          {loading ? (
            <Loading
              data-testid='loading-spinner'
              height={24}
              color={colors.greyTextWhiteBg}
              type='spinningBubbles'
            />
          ) : (
            <CachedIcon size='16' />
          )}
        </RefreshBtnMobile>
      </Mobile>
      <ConnectToSpotifyModal modal={isModalSpotify} toggle={toggle} />
    </div>
  )
}
