import React, { FC } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import Button from 'src/Components/Buttons/Button'
import { CLEAR_HINT } from 'src/Constants/hints'
import { colors, variables } from 'src/Styled/variables'

const HintPortal = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  border-radius: 4px;
  transform: translate(102%, -50%);
  padding: 26px 24px;
  color: ${colors.mainText};
  background: ${colors.white};
  font-size: ${variables.fontSize14};
  font-family: sans-serif;
  line-height: 1;
  z-index: 100;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  width: 320px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`

export const GotItContainer = styled.div`
  margin: 0 auto;
  & > div {
    width: 160px;
  }
`

interface Props {
  component: React.ReactElement | null
  onSetHint: (value: string) => void
}

export const DesktopHintPortal: FC<Props> = ({ component, onSetHint }) => {
  const { t } = useTranslation()
  if (!component) {
    return null
  }
  return (
    <HintPortal>
      {component}
      <GotItContainer>
        <Button
          type='whiteWithGreenBorder'
          onClick={() => onSetHint(CLEAR_HINT)}
        >
          {t('scheduleCampaignPage.gotIt')}
        </Button>
      </GotItContainer>
    </HintPortal>
  )
}
