import React, { FC } from 'react'

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useIntercom } from 'react-use-intercom'

import Alert from 'src/Components/Alert'
import { DotedButton } from 'src/Components/Buttons/DotedButton'
import { colors, variables } from 'src/Styled/variables'

export const AlertSubTitle = styled.div``
export const AlertTitle = styled.div`
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
`

export const StyledAlert = styled(Alert)`
  margin-bottom: 0 !important;
  width: 100%;
`
export const StyledDotedButton = styled(DotedButton)`
  margin-top: 15px;
`
export const MonitoringFailedAlert: FC = () => {
  const { t } = useTranslation()
  const { show } = useIntercom()
  const handleClickSupport = (): void => {
    show()
  }
  return (
    <StyledAlert color={'danger'} icon unclosable={true}>
      <AlertTitle>{t('soundsPage.monitoringFailedAlertTitle')}</AlertTitle>

      <AlertSubTitle>
        {t('soundsPage.monitoringFailedAlertDescription')}
      </AlertSubTitle>

      <StyledDotedButton onClick={handleClickSupport} color={colors.mainText}>
        Contact support
      </StyledDotedButton>
    </StyledAlert>
  )
}
