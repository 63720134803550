import { FC } from 'react'

import { FIXED_BY, payout, withdraw } from 'src/Constants/constants'

import { Amount } from '../styles'

interface AmountCellProps {
  type: string
  amount: number
}

export const AmountCell: FC<AmountCellProps> = ({ type, amount }) => {
  if (type === payout || type === withdraw) {
    return <Amount>{`- $${Math.abs(Number(amount)).toFixed(FIXED_BY)}`}</Amount>
  }
  return <Amount isGreen>{`$${Number(amount).toFixed(FIXED_BY)}`}</Amount>
}
