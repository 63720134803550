import { FC } from 'react'

import { Message, Wrapper } from './styles'

interface FullWideNotificationProps {
  message: string
}

export const FullWideNotification: FC<FullWideNotificationProps> = ({
  message,
}) => (
  <Wrapper>
    <Message>{message}</Message>
  </Wrapper>
)
