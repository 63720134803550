import styled from 'styled-components'

import { colors, device, variables } from 'src/Styled/variables'

export const Container = styled.div`
  padding: 14px 24px;
  background-color: ${colors.greyBg};
  margin-bottom: 2px;
  border: 1px solid ${colors.green};
  flex-direction: row;
  display: flex;
  justify-content: space-between;

  @media ${device.mobileMax} {
    flex-direction: column;
    gap: 24px;
    padding: 16px;
    margin-bottom: 8px;
  }
`
export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  width: 192px;

  @media ${device.mobileMax} {
    min-width: 190px;
    margin-left: auto;
  }
`
export const Title = styled.div`
  font-size: ${variables.fontSize18};
  font-weight: ${variables.fontWeight600};
  color: ${colors.green};
`
export const Description = styled.div`
  color: ${colors.mainText};
`
