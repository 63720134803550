import { FC } from 'react'

import { Desktop, Mobile } from 'src/Components/Responsive'

import { TiktokReviewDesktop } from './components/TiktokReviewDesktop'
import { TiktokReviewMobile } from './components/TiktokReviewMobile'

export interface TiktokReviewParams {
  campaignID?: string
  videoID?: string
}

export const TiktokReview: FC = () => (
  // TODO need to merge desktop and mobile components

  <>
    <Desktop>
      <TiktokReviewDesktop />
    </Desktop>
    <Mobile>
      <TiktokReviewMobile />
    </Mobile>
  </>
)
