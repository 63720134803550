import React, { FunctionComponent, useMemo } from 'react'

import { useTranslation } from 'react-i18next'

import dayjs from 'src/Helpers/dayjs'
import { formatDate } from 'src/Constants/formatDate'
import { CampaignStatusEnum } from 'src/Types/index'

import { RelaunchedCampaignInfoHint } from '../RelaunchedCampaignInfoHint/index'

import { ProgressBarByTimeProps } from './props'

import {
  EndedStatusLabel,
  EndedStatusReviews,
  EndedStatusReviewsDiff,
  ProgressBar,
  ProgressContainer,
  ProgressInfo,
  ProgressInfoItem,
  ProgressSection,
} from './styles'

const MIN_DAYS_LEFT = 4

export const ProgressBarByTime: FunctionComponent<ProgressBarByTimeProps> = ({
  campaignDetails,
}) => {
  const { t } = useTranslation()

  const totalDays = Array.from({ length: 14 }).map((_, id) => id)

  const daysLeft = useMemo(() => {
    const nowDate = new Date()
    return dayjs(campaignDetails.end_date).diff(dayjs(nowDate), 'days') + 1 || 0
  }, [campaignDetails.end_date])

  const startDate = useMemo(
    () =>
      dayjs(campaignDetails.start_date).format(formatDate.formatDateMonthFull),
    [campaignDetails],
  )

  const daysLeftLabel =
    daysLeft > 1 ? t('reviewsPage.daysLeft') : t('reviewsPage.dayLeft')

  return (
    <ProgressContainer>
      {campaignDetails.status !== CampaignStatusEnum.ENDED &&
      campaignDetails.status !== CampaignStatusEnum.STOPPED ? (
        <>
          <ProgressInfo>
            <RelaunchedCampaignInfoHint type={campaignDetails.type} />

            <ProgressInfoItem>{startDate}</ProgressInfoItem>
            <ProgressInfoItem>{`, ${daysLeft} ${daysLeftLabel}`}</ProgressInfoItem>
          </ProgressInfo>
          <ProgressBar>
            {totalDays.map((el, id) => (
              <ProgressSection
                key={`${el}`}
                first={!id}
                last={id === totalDays.length - 1}
                passed={id >= daysLeft}
                yellow={daysLeft <= MIN_DAYS_LEFT}
              />
            ))}
          </ProgressBar>
        </>
      ) : (
        <>
          <RelaunchedCampaignInfoHint type={campaignDetails.type} />
          <EndedStatusLabel>{campaignDetails.status}</EndedStatusLabel>
          <EndedStatusReviews>
            {`${campaignDetails.reviewed_count}/${campaignDetails.reviews_count}`}
          </EndedStatusReviews>
          <EndedStatusReviewsDiff
            visible={Boolean(campaignDetails.missed_count)}
          >
            {`(${campaignDetails.missed_count}) `}
          </EndedStatusReviewsDiff>
          <EndedStatusReviews> reviews</EndedStatusReviews>
        </>
      )}
    </ProgressContainer>
  )
}
