import React from 'react'

import styled from 'styled-components'

import Logo from 'src/Assets/Svg/Logo/Logo.svg?react'
import SoundCampaignText from 'src/Assets/Svg/sidebar-icons/SoundCampaignText.svg?react'

const StyledSidebarToggle = styled.div`
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  background: ${({ theme }) => theme.common.secondaryBg};
  cursor: pointer;
`

export interface SidebarToggleProps {
  collapsed: boolean
  onClick?: () => void
}

export const SidebarToggle: React.FC<SidebarToggleProps> = ({
  collapsed,
  onClick,
}) => (
  <StyledSidebarToggle onClick={onClick}>
    <Logo />
    {!collapsed && <SoundCampaignText />}
  </StyledSidebarToggle>
)
