import {
  createDraftSafeSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit'

import { RootState } from '..'
import { NameSpace } from '../types'

interface TypeformState {
  closedIds: Array<string>
}

const initialState: TypeformState = {
  closedIds: [],
}

export const typeformProcess = createSlice({
  initialState,
  name: NameSpace.TYPEFORM,
  reducers: {
    setClosedTypeformList: (state, { payload }: PayloadAction<string[]>) => {
      state.closedIds = payload
    },
  },
})

export const { setClosedTypeformList } = typeformProcess.actions

const getState = (state: RootState): TypeformState => state.typeform

export const getClosedTypeformIds = createDraftSafeSelector(
  getState,
  ({ closedIds }) => closedIds,
)
