import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import WarningIcon from '/src/Assets/Svg/pages-icons/Exclamation.svg?react'

const Text = styled.div`
  color: ${({ theme }) => theme.common.fifthText};
  font-family: 'Segoe UI';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`
const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`
const IconContainer = styled.div`
  width: 16px;
  height: 16px;
`

export const MatchingError: FC = () => {
  const { t } = useTranslation()
  return (
    <Container>
      <IconContainer>
        <WarningIcon />
      </IconContainer>
      <Text>{t('scheduleCampaignPage.trackGenresError')}</Text>
    </Container>
  )
}
