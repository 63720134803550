import { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'

import Button from 'src/Components/Buttons/Button'

import AccountValidation from '../AccountValidation'

import { GotItContainer, StyledModal } from './styles'

interface AccountValidationModalProps {
  toggle: (arg0: boolean) => void
  isOpen: boolean
}

const AccountValidationModal: FunctionComponent<
  AccountValidationModalProps
> = ({ toggle, isOpen }) => {
  const { t } = useTranslation()

  const onModalClose = (): void => {
    toggle(false)
  }

  return (
    <StyledModal
      open={isOpen}
      closable={false}
      footer={null}
      onCancel={onModalClose}
    >
      <AccountValidation />
      <GotItContainer>
        <Button type='whiteWithGreenBorder' onClick={onModalClose}>
          {t('scheduleCampaignPage.gotIt')}
        </Button>
      </GotItContainer>
    </StyledModal>
  )
}
export default AccountValidationModal
