import useSoundImage from 'src/Assets/Png/useSoundImage.png'

export const acceptRulesListOfItems = [
  {
    id: 1,
    title: 'influencer.ruleTitle1',
    text: 'influencer.ruleDescription1',
    img: useSoundImage,
    imgHeight: '72',
    imgWidth: '252',
    text2: 'influencer.ruleDescription1_1',
    altName: 'Use this sound',
  },
  {
    id: 2,
    title: 'influencer.ruleTitle2',
    text: 'influencer.ruleDescription2',
  },
  {
    id: 3,
    title: 'influencer.ruleTitle3',
    text: 'influencer.ruleDescription3',
  },
  {
    id: 4,
    title: 'influencer.ruleTitle4',
    text: 'influencer.ruleDescription4',
  },
  {
    id: 5,
    title: 'influencer.ruleTitle5',
    text: 'influencer.ruleDescription5',
  },
]
