import { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { yupResolver } from '@hookform/resolvers/yup'

import { changeArtistPassword } from 'src/Redux/settings-process/artistPassword/api-actions'
import { ResetPasswordSchema } from 'src/Containers/ResetPassword/components/helpers'
import { SmartInput } from 'src/Components/SmartInput'
import Button from 'src/Components/Buttons/Button'
import Alert from 'src/Components/Alert'
import { useAppDispatch } from 'src/Hooks/redux'
import { getIsSuccessChangedPassword } from 'src/Redux/settings-process/artistPassword/selectors'
import { getIsArtist } from 'src/Redux/auth-process/credentialsSlice/selectors'

import {
  AlertText,
  AlertWrapper,
  Form,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
  FormWrapper,
} from './styles'

interface FormState {
  password: string
  confirm: string
}

const ChangePassword: FunctionComponent = () => {
  const { t } = useTranslation()
  const isArtist = useSelector(getIsArtist)
  const isSuccess = useSelector(getIsSuccessChangedPassword)
  const dispatch = useAppDispatch()

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting, isValid },
  } = useForm<FormState>({
    mode: 'onChange',
    resolver: yupResolver(ResetPasswordSchema),
  })

  const onSubmit = (data: FormState): void => {
    if (isArtist) {
      void dispatch(changeArtistPassword(data.password))
    }
    reset()
  }

  return (
    <>
      {isSuccess && (
        <AlertWrapper>
          <Alert color='success' icon>
            <AlertText>{t('settingsPage.passwordChanged')}</AlertText>
          </Alert>
        </AlertWrapper>
      )}
      <FormWrapper>
        <Form data-testid='change-password' onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <FormGroupLabel>{t('settingsPage.choosePassword')}</FormGroupLabel>
            <FormGroupField>
              <SmartInput
                {...register('password')}
                error={errors.password}
                data-testid='password'
                type='password'
              />
            </FormGroupField>
          </FormGroup>
          <FormGroup>
            <FormGroupLabel>{t('settingsPage.confirmPassword')}</FormGroupLabel>
            <FormGroupField>
              <SmartInput
                {...register('confirm')}
                data-testid='confirm'
                error={errors.confirm}
                type='password'
              />
            </FormGroupField>
          </FormGroup>
          <Button
            disabled={!isValid || isSubmitting}
            htmlType='submit'
            type='green'
          >
            {t('settingsPage.updatePassword')}
          </Button>
        </Form>
      </FormWrapper>
    </>
  )
}

export default ChangePassword
