import { useEffect } from 'react'

import { useNavigate } from 'react-router-dom'

import { setAccessToken } from 'src/Redux/auth-process/credentialsSlice'
import { useAppDispatch } from 'src/Hooks/redux'
import {
  campaignsList,
  scheduleCampaignTikTok,
  scheduleCampaign,
} from 'src/Router/routes'
import { CampaignPlatformTypeEnum } from 'src/Types'
import { loginEvent } from 'src/Helpers/TagManager'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import { useSourceBusterEvents } from 'src/Hooks/useSourceBusterEvents'

import {
  getScheduleRedirectUrl,
  getTokenFromUrl,
  getUserFromUrl,
} from './helpers'

export const useRedirect = (): null => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { search } = useCustomSearchParams()

  const token = getTokenFromUrl(search)
  const user = getUserFromUrl(search.get('user'))
  const { sourceBusterLoginEvent } = useSourceBusterEvents()

  useEffect(() => {
    // for save token in redux
    if (token && typeof token === 'string') {
      dispatch(
        setAccessToken({
          token,
        }),
      )
      if (user) {
        loginEvent(user)
        sourceBusterLoginEvent()
      }
    }
    // for redirect to schedule page
    if (user?.schedule) {
      const query = getScheduleRedirectUrl(search)
      if (user?.type === CampaignPlatformTypeEnum.TIKTOK) {
        navigate(
          `${campaignsList}${scheduleCampaignTikTok}/${user.campaign_id}?${query}`,
        )
        return
      }
      navigate(
        `${campaignsList}${scheduleCampaign}/${user.campaign_id}?${query}`,
      )
    }
  }, [dispatch, navigate, search, token, user, sourceBusterLoginEvent])

  return null
}
