import styled from 'styled-components'

import { colors, device, variables } from 'src/Styled/variables'

export const CommentTitle = styled.div`
  color: ${colors.mainText};
  font-size: ${variables.fontSize10};
  font-weight: ${variables.fontWeight400};
  line-height: 150%;
`

export const Comment = styled.div`
  color: ${colors.mainText};
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight400};
  line-height: 150%;
`

export const RateWrapper = styled.div`
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`
export const RateDateContainer = styled.div`
  color: ${colors.mainText};
  display: flex;
  align-items: center;
  gap: 8px;
  & > div {
    line-height: 21px;
  }
  @media (${device.mobileMax}) {
    flex-direction: column;
    gap: 0;
    align-items: flex-start;
  }
`
export const RateDateWrapper = styled.div`
  display: flex;
  gap: 8px;
  line-height: 150%;
  @media (${device.mobileMax}) {
    width: 100%;

    font-size: ${variables.fontSize18};
    position: relative;
  }
`
export const RateDate = styled.div``

export const RateContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  min-height: 40px;
  @media (${device.mobileMax}) {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
`

export const StyledReactStars = styled.div`
  & > svg {
    width: 18px;
    height: 18px;
    line-height: 100%;
    letter-spacing: 1px;
  }
`
