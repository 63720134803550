import styled from 'styled-components'

import { colors, variables } from 'src/Styled/variables'

export const StepsWrapper = styled.div`
  display: flex;
  padding-bottom: 16px;
  gap: 2px;
  align-items: center;
`
export const PageTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
`
export const PageTitle = styled.h3`
  margin: 0;
  font-size: ${variables.fontSize20};
  font-weight: ${variables.fontWeight600};
  color: ${colors.mainText};
`
export const ScheduleTabsBackButton = styled.div`
  width: 24px;
  height: 30px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`
export const ScheduleTabsContainer = styled.div`
  max-width: 1120px;
  margin: 0 auto;
`
