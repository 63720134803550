import { useTranslation } from 'react-i18next'

import { CampaignPlatformTypeEnum } from 'src/Types'

interface UsePlaceholderText {
  placeholderText: string
}

export const usePlaceholderText = (
  type?: CampaignPlatformTypeEnum | null,
): UsePlaceholderText => {
  const { t } = useTranslation()

  const getPlaceholderText = (): string => {
    if (type === CampaignPlatformTypeEnum.SPOTIFY) {
      return t('createCampaignPage.typeYourTrackNameOrInsertALinkSpotify')
    }
    if (type === CampaignPlatformTypeEnum.TIKTOK) {
      return t('createCampaignPage.typeYourTrackNameOrInsertALinkTiktok')
    }
    return ''
  }

  const placeholderText = getPlaceholderText()

  return {
    placeholderText,
  }
}
