import { FunctionComponent } from 'react'

import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import IconArrow from 'src/Assets/Svg/arrow.svg?react'
import { campaignsList } from 'src/Router/routes'

import { PageTitleContainer, PageTitle, BackButton } from './styles'

const DetailsHeader: FunctionComponent = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const handleGoBackToCampaignsList = (): void => {
    navigate(`${campaignsList}`)
  }

  return (
    <PageTitleContainer>
      <BackButton
        data-testid={'back-button'}
        onClick={handleGoBackToCampaignsList}
      >
        <IconArrow />
      </BackButton>
      <PageTitle>{t('campaignResultsPage.pageTitle')}</PageTitle>
    </PageTitleContainer>
  )
}

export default DetailsHeader
