import { Radio } from 'antd'
import styled from 'styled-components'

import { colors, variables } from 'src/Styled/variables'

export const CustomRadio = styled(Radio)`
  font-size: ${variables.fontSize18};
  line-height: 150%;
  span {
    display: flex;
    gap: 8px;
    align-items: center;
  }
  &.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
    border-color: ${colors.greyStroke};
    background-color: transparent;
    &::after {
      background-color: ${colors.green};
    }
  }
  .ant-radio-input:focus + .ant-radio-inner,
  .ant-radio-input:hover + .ant-radio-inner {
    border-color: ${colors.greyTextBlackBg};
    background: ${colors.errorBg};
    box-shadow: none;

    &:after {
      background-color: ${colors.green};
      box-shadow: none;
    }
  }

  &:hover {
    .ant-radio-checked .ant-radio-inner {
      border-color: ${colors.greyTextBlackBg};
      background: ${colors.errorBg};
      box-shadow: none;

      &:after {
        background-color: ${colors.green};
        box-shadow: none;
      }
    }
  }
`
