import styled from 'styled-components'

import { colors } from 'src/Styled/variables'

export const MatchingInfoContainerDesktop = styled.div`
  display: flex;
  color: ${colors.messegesYellow1};
  align-items: flex-start;
`

export const MatchingInfoText = styled.div`
  text-align: left;
  line-height: 21px;
`

export const MatchingInfoContainerMobile = styled.div`
  display: flex;
  color: ${colors.messegesYellow1};
  align-items: flex-start;
  margin-top: 16px;
  margin-bottom: 24px;
  padding-left: 16px;
  padding-right: 16px;
`

export const MatchingInfoIcon = styled.div`
  margin-right: 8px;
  width: 16px;
  height: 14px;

  & img {
    width: 16px;
    height: 14px;
  }
`

export const MatchingInfoWrapper = styled.div`
  padding-top: 16px;
`
