import { APP } from 'src/Configs/App'
import { rtkApi } from 'src/Services/rtkApi'
import { CampaignPlatformTypeEnum } from 'src/Types'

interface CreditsPaymentResponse {
  succeeded: true
}

interface Campaign {
  type: CampaignPlatformTypeEnum
  amount: number
  startDate: string
  campaignId: number
}

interface CreditsPaymentData {
  campaigns: Campaign[]
}

const creditsPaymentApi = rtkApi.injectEndpoints({
  endpoints(build) {
    return {
      payByCredits: build.mutation<CreditsPaymentResponse, CreditsPaymentData>({
        query: ({ campaigns }) => ({
          url: `${APP.TIKTOK_SERVER}/payment/balance-only/charge`,
          method: 'POST',
          body: {
            campaigns,
          },
        }),
      }),
    }
  },
})

export const { usePayByCreditsMutation } = creditsPaymentApi
