import { FC } from 'react'

import { Options } from 'react-select'

import { SelectedLanguage } from 'src/Types'

import { LanguagesSelectItem } from './styles'

interface OptionLabelLanguageProps {
  language: SelectedLanguage
  inputValueLanguages: string[]
  search: {
    inputValue: string
    selectValue: Options<SelectedLanguage>
  }
}

export const OptionLabelLanguage: FC<OptionLabelLanguageProps> = ({
  language,
  inputValueLanguages,
  search,
}) => {
  const { label } = language
  const { inputValue, selectValue } = search
  const inputLength = inputValue.length

  const index = label.toLowerCase().indexOf(inputValue.toLowerCase())
  const selectValueLabel = selectValue.map((item) => item.label)

  if (selectValueLabel.includes(label)) {
    return <div>{label}</div>
  }

  return (
    <LanguagesSelectItem>
      <span>
        {label.slice(0, index)}

        <strong style={index === 0 ? { textTransform: 'capitalize' } : {}}>
          {inputValueLanguages}
        </strong>
        {label.slice(index + inputLength)}
      </span>
      <span />
    </LanguagesSelectItem>
  )
}
