import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { ApiError, NameSpace, SliceStatus } from 'src/Redux/types'
import { setApiError } from 'src/Hooks/redux'

import { getAudienceTiktok } from './api-actions'

export interface AudienceTiktokState {
  potentialMinReach: number
  estimatedMinVideos: number
  potentialMaxReach: number
  estimatedMaxVideos: number
  minViews: string
  maxViews: string
  price: number
  error: ApiError | null
  status: SliceStatus
}

const initialState: AudienceTiktokState = {
  maxViews: '',
  minViews: '',
  potentialMinReach: 0,
  estimatedMinVideos: 0,
  potentialMaxReach: 0,
  estimatedMaxVideos: 0,
  price: 0,
  error: null,
  status: SliceStatus.Idle,
}

export const audienceTiktokSlice = createSlice({
  initialState,
  name: NameSpace.AudienceTiktok,
  reducers: {
    updateCampaignPrice: (state, { payload }: PayloadAction<number>) => {
      state.price = payload
    },
  },
  extraReducers(builder) {
    builder.addCase(getAudienceTiktok.pending, (state) => {
      state.status = SliceStatus.Loading
    })
    builder.addCase(getAudienceTiktok.fulfilled, (state, { payload }) => {
      state.status = SliceStatus.Succeeded
      state.potentialMinReach = payload.minFollowers
      state.potentialMaxReach = payload.maxFollowers
      state.estimatedMinVideos = payload.minVideos
      state.estimatedMaxVideos = payload.maxVideos
      state.minViews = payload.minViewsMedian
      state.maxViews = payload.maxViewsMedian
      state.price = payload.price
    })
    builder.addCase(getAudienceTiktok.rejected, (state, action) => {
      state.status = SliceStatus.Failed
      state.error = setApiError(action)
    })
  },
})

export const { updateCampaignPrice } = audienceTiktokSlice.actions
