import { FC } from 'react'

import { RelaunchButton } from 'src/Components/RelaunchButton'

import { StyledRelaunchButtonBlock, ButtonsContainer } from './styles'

interface Props {
  relaunchBtnFlag?: boolean | null
  handleRelaunchCampaign: (campaignId: number | string) => void
  id: number | string | undefined
}

const RelaunchButtonBlock: FC<Props> = ({
  relaunchBtnFlag,
  handleRelaunchCampaign,
  id,
}) => {
  if (!relaunchBtnFlag || !id) {
    return null
  }
  return (
    <StyledRelaunchButtonBlock>
      <ButtonsContainer>
        <RelaunchButton
          data-testid='relaunchButton'
          onRelaunchCampaign={() => {
            handleRelaunchCampaign(id)
          }}
        />
      </ButtonsContainer>
    </StyledRelaunchButtonBlock>
  )
}

export default RelaunchButtonBlock
