import styled from 'styled-components'

import { device } from 'src/Styled/variables'

export const DetailsContainer = styled.div<{ isDetailsOpened?: boolean }>`
  position: relative;
  max-height: ${({ isDetailsOpened }) => (isDetailsOpened ? '1000px' : 0)};
  opacity: ${({ isDetailsOpened }) => (isDetailsOpened ? 1 : 0)};
  transition: 0.3s all ease-in-out;

  @media ${device.mobileMax} {
    max-height: ${({ isDetailsOpened }) => (isDetailsOpened ? '1000px' : 0)};
  }
`
