import { forwardRef, useState } from 'react'

import { useSelector } from 'react-redux'
import { Country } from 'react-phone-number-input/react-hook-form'
import { InputRef } from 'antd'
import { useTranslation } from 'react-i18next'
import 'react-phone-number-input/style.css'
import { Control } from 'react-hook-form'
import {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from 'react-phone-number-input'
import { useDebouncedCallback } from 'use-debounce'

import { getCountry } from 'src/Redux/auth-process/countrySlice/selectors'
import { FormDataProps } from 'src/Pages/Artist/CreateCampaignPage/NoAuthPages/ArtistRegisterPage/Components/ArtistRegisterForm'
import { DEBOUNCE_TIMER_500 } from 'src/Constants/constants'
import { arrPhoneCodes } from 'src/Helpers/validation'
import {
  phoneEnteredEvent,
  phoneNumberAddedEvent,
} from 'src/Helpers/TagManager'

import { Message, Label, StyledInput, Wrapper } from './styles'

export type PhoneNumberInputProps = {
  name: string
  control: Control<FormDataProps>
}

export const PhoneNumberInput = forwardRef<InputRef, PhoneNumberInputProps>(
  (props) => {
    const { t } = useTranslation()
    const { name, control } = props
    const country = useSelector(getCountry)
    const [defaultCountry, setDefaultCountry] = useState<Country | undefined>()
    const [phoneNumberValidMessage, setPhoneNumberValidMessage] = useState('')
    const phoneValue = control._getWatch(name)
    const invalidNumberMessage = t('createCampaignPage.invalidNumber')
    const validNumberMessage = t('createCampaignPage.greatSMS')

    const showMessageAndSendGTMEvent = (): void => {
      ![invalidNumberMessage].includes(phoneNumberValidMessage) &&
        phoneNumberAddedEvent()
    }

    const changeMessageHandler = (phoneNumber: string): void => {
      const isPossiblePhone = isPossiblePhoneNumber(phoneNumber)
      const isValidPhone = isValidPhoneNumber(phoneNumber)

      if (!isPossiblePhone && !isValidPhone) {
        setPhoneNumberValidMessage(invalidNumberMessage)
        return
      }

      setPhoneNumberValidMessage(validNumberMessage)
      phoneEnteredEvent()
    }
    const debouncedOnChange = useDebouncedCallback((phoneNumber: string) => {
      changeMessageHandler(phoneNumber)
    }, DEBOUNCE_TIMER_500)

    return (
      <Wrapper
        onClick={() => {
          if (!phoneValue) {
            setDefaultCountry(country as Country)
          }
        }}
      >
        <Label>{t('phoneInput.phoneOptional')}</Label>

        <StyledInput
          $isError={Boolean(phoneNumberValidMessage === invalidNumberMessage)}
          international
          addInternationalOption
          autoComplete='none'
          countryCallingCodeEditable
          name={name}
          control={control}
          defaultCountry={defaultCountry}
          rules={{ required: true }}
          onChange={(number: string) => {
            if (number && !arrPhoneCodes.includes(number)) {
              debouncedOnChange(number)
            }
          }}
          onKeyDown={(event: KeyboardEvent) =>
            (event.key === 'e' || event.key === '-') && event.preventDefault()
          }
          onFocus={showMessageAndSendGTMEvent}
        />

        {phoneNumberValidMessage && (
          <Message $isError={phoneNumberValidMessage === invalidNumberMessage}>
            {phoneNumberValidMessage}
          </Message>
        )}
      </Wrapper>
    )
  },
)

PhoneNumberInput.displayName = 'PhoneNumberInput'
