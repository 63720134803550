import { FC } from 'react'

import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { colors, device, variables } from 'src/Styled/variables'
import ArrowIcon from 'src/Assets/Svg/arrow.svg?react'
import { CampaignPlatformTypeEnum } from 'src/Types'

import { SearchTrackByPlatformParams } from '../../Containers/SearchTrackByPlatform/index'
import { IconComponent } from '../IconComponent'

interface SubHeaderProps {
  onBackClick: () => void
}

export const ArrowBackButton = styled.div`
  cursor: pointer;
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
`

export const SubHeadForFirstCampaignSearch = styled.div`
  color: ${colors.mainText};
  font-style: normal;
  line-height: 150%;
  font-weight: ${variables.fontWeight600};
  font-size: ${variables.fontSize24};
  display: grid;
  grid-template-columns: 24px 1fr 16px;
  height: 40px;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;

  @media ${device.mobileMin} {
    margin-bottom: 24px;
  }
`

export const FirstTrackSubHeader: FC<SubHeaderProps> = ({ onBackClick }) => {
  const { platform } = useParams<SearchTrackByPlatformParams>()
  const { t } = useTranslation()
  const title =
    platform === CampaignPlatformTypeEnum.SPOTIFY
      ? t('createCampaignPage.newSpotifyCampaign')
      : t('createCampaignPage.addingTikTokCampaign')
  return (
    <SubHeadForFirstCampaignSearch>
      <ArrowBackButton onClick={onBackClick}>
        <ArrowIcon width={12} height={12} />
      </ArrowBackButton>
      <div>{title}</div>
      <IconComponent platform={platform} />
    </SubHeadForFirstCampaignSearch>
  )
}
