import { useCallback } from 'react'

import { useIntercom } from 'react-use-intercom'
import { useNavigate } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { logOut } from 'src/Redux/auth-process/credentialsSlice'
import { logOutEvent } from 'src/Helpers/TagManager'
import { clearUserState } from 'src/Redux/auth-process/userSlice'
import { rtkApi } from 'src/Services/rtkApi'
import { getUser } from 'src/Redux/auth-process/userSlice/selectors'
import { removeCookiesHandler } from 'src/Constants/functions'

interface LogOutInterface {
  handleLogOut: () => void
}

export const useLogOut = (): LogOutInterface => {
  const { shutdown } = useIntercom()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const user = useAppSelector(getUser)

  const handleLogOut = useCallback(() => {
    shutdown()
    user && logOutEvent(user)
    dispatch(logOut())
    dispatch(clearUserState())
    navigate('/')
    removeCookiesHandler()
    dispatch(rtkApi.util.resetApiState())
    localStorage.removeItem('user')
  }, [dispatch, navigate, user, shutdown])

  return { handleLogOut }
}
