import { FC } from 'react'

import { useSelector } from 'react-redux'

import {
  getActivePlaylists,
  getInactivePlaylists,
  getPendingPlaylists,
  getPlaylist,
} from 'src/Redux/playlists-process/selectors'
import { useAppSelector } from 'src/Hooks/redux'

import { removeDuplicatedPlaylists } from '../halpers'

import { TabTrigger, TabTriggersContainer } from './styles'

interface IProps {
  activeTab: number
  setActiveTab: (tab: number) => void
}

export enum PlaylistsTabsEnum {
  All = 1,
  OnReview = 2,
  Active = 3,
  Inactive = 4,
}

export const PlaylistTabs: FC<IProps> = ({ activeTab, setActiveTab }) => {
  const playlists = useSelector(getPlaylist)
  const activePlaylists = useAppSelector(getActivePlaylists)
  const inactivePlaylists = useAppSelector(getInactivePlaylists)
  const pendingPlaylists = useAppSelector(getPendingPlaylists)
  return (
    <TabTriggersContainer>
      <TabTrigger
        active={activeTab === PlaylistsTabsEnum.All}
        onClick={() => setActiveTab(PlaylistsTabsEnum.All)}
      >
        All
        <span>{playlists.length}</span>
      </TabTrigger>
      <TabTrigger
        active={activeTab === PlaylistsTabsEnum.OnReview}
        onClick={() => setActiveTab(PlaylistsTabsEnum.OnReview)}
      >
        On review
        <span>{pendingPlaylists.length}</span>
      </TabTrigger>
      <TabTrigger
        active={activeTab === PlaylistsTabsEnum.Active}
        onClick={() => setActiveTab(PlaylistsTabsEnum.Active)}
      >
        Active
        <span>{activePlaylists.length}</span>
      </TabTrigger>
      <TabTrigger
        active={activeTab === PlaylistsTabsEnum.Inactive}
        onClick={() => setActiveTab(PlaylistsTabsEnum.Inactive)}
      >
        Inactive
        <span>{removeDuplicatedPlaylists(inactivePlaylists).length}</span>
      </TabTrigger>
    </TabTriggersContainer>
  )
}
