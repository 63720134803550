import React, { FC, Fragment } from 'react'

import styled from 'styled-components'
import { Link } from 'react-router-dom'

import Arrow from 'src/Assets/Svg/common/left-arrow.svg?react'
import { variables } from 'src/Styled/variables'

import { IconButton } from '../Buttons/IconButton'

const Separator = styled.div`
  width: 4px;
  height: 4px;
  background: ${({ theme }) => theme.button.thirdText};
  border-radius: 50%;
  margin: 0 8px;
  flex-shrink: 0;
`

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;

  border-radius: 40px;
  border: ${({ theme }) => `1px solid ${theme.button.primaryBorder}`};
  background: ${({ theme }) => theme.button.primaryBg};
`

const StyledIconButton = styled(IconButton)`
  margin-left: -1px;
`

const LinksList = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  padding-right: 24px;
  align-items: center;
`

const LinkItem = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.button.thirdText};
  font-family: 'Segoe UI';
  font-style: normal;
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight400};
  line-height: 150%;
  flex-shrink: 0;
  flex-grow: 0;
  min-width: 0;
  overflow: hidden;
  cursor: pointer;

  &:first-child {
    flex-shrink: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: 12px;
  }

  &:hover {
    color: ${({ theme }) => theme.button.primaryText} !important;
    background: none !important;
  }

  &:focus-visible {
    color: ${({ theme }) => theme.button.primaryText} !important;
    outline: none !important;
  }

  &:active {
    color: ${({ theme }) => theme.button.fifthText} !important;
    background: none !important;
  }
`

const LastLink = styled.span`
  color: ${({ theme }) => theme.common.primaryText};
  font-family: 'Segoe UI';
  font-style: normal;
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight400};
  line-height: 150%;
  flex-shrink: 0;
`

export type BreadCrumbItem = {
  path: string
  title: string
}

export interface BreadcrumbProps {
  items: Array<BreadCrumbItem>
  onButtonClick: () => void
}

export const Breadcrumb: FC<BreadcrumbProps> = ({ onButtonClick, items }) => (
  <Container>
    <StyledIconButton onClick={onButtonClick}>
      <Arrow />
    </StyledIconButton>

    <LinksList>
      {items.map((route, index) => {
        const isLast = index === items.length - 1
        return (
          <Fragment key={route.title}>
            {!isLast ? (
              <LinkItem to={route?.path ?? '/'}>{route.title}</LinkItem>
            ) : (
              <LastLink>{route.title}</LastLink>
            )}
            {!isLast && <Separator />}
          </Fragment>
        )
      })}
    </LinksList>
  </Container>
)
