import { useEffect } from 'react'

import { useGrowthBook } from '@growthbook/growthbook-react'

import { useAppSelector } from 'src/Hooks/redux'
import { getIsNewUserForMinBudgetDecreaseExp } from 'src/Redux/auth-process/userSlice/selectors'
import {
  useAddFeatureForUserMutation,
  useGetUserFeaturesQuery,
} from 'src/Redux/A-BFeaturesApi'

const MIN_BUDGET_DECREASE_EXP_NAME = 'min_budget_decrease'
export const MIN_BUDGET_DECREASE_FEATURE_NAME =
  'spotify_campaign_min_budget_group'

export const useMinBudgetDecrease = (): void => {
  const growthBook = useGrowthBook()

  const [addFeatureForUser] = useAddFeatureForUserMutation()

  const { data: minBudgetFeatureValue, isSuccess } = useGetUserFeaturesQuery(
    null,
    {
      selectFromResult: (result) => ({
        ...result,
        data: result?.data?.features?.find(
          (item) => item?.name === MIN_BUDGET_DECREASE_FEATURE_NAME,
        )?.variation,
      }),
    },
  )

  const isNewUser = useAppSelector(getIsNewUserForMinBudgetDecreaseExp)

  const isUserWithoutExperiment =
    typeof minBudgetFeatureValue !== 'number' && isSuccess

  useEffect(() => {
    if (isNewUser && isUserWithoutExperiment) {
      const minBudgetExpVariant = growthBook.getFeatureValue(
        MIN_BUDGET_DECREASE_EXP_NAME,
        0,
      )

      addFeatureForUser({
        featureName: MIN_BUDGET_DECREASE_FEATURE_NAME,
        variation: minBudgetExpVariant,
      })
    }
  }, [
    addFeatureForUser,
    growthBook,
    isNewUser,
    isUserWithoutExperiment,
    minBudgetFeatureValue,
  ])
}
