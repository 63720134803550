import { useEffect } from 'react'

import { getUserId } from 'src/Redux/auth-process/userSlice/selectors'
import { useAppSelector } from 'src/Hooks/redux'
import { CampaignPlatformTypeEnum, CampaignTypeEnum } from 'src/Types'
import { toggleActivatedEvent } from 'src/Helpers/TagManager'
import { getAllCampaignsCount, useMetadataQuery } from 'src/Redux/metadataApi'

export const useSearchTrackEvents = (
  campaignType: CampaignTypeEnum,
  platform?: CampaignPlatformTypeEnum | null,
): void => {
  const { data, isError } = useMetadataQuery()

  const amountOfCampaigns = getAllCampaignsCount(data)
  const userId = useAppSelector(getUserId)

  const campaignNumber = amountOfCampaigns + 1

  useEffect(() => {
    if (platform && (data || isError)) {
      toggleActivatedEvent({
        platformName: platform,
        campaignType,
        campaignNumber,
        userId,
      })
    }
  }, [campaignNumber, campaignType, data, isError, platform, userId])
}
