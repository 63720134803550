import { forwardRef } from 'react'

import { Dayjs } from 'dayjs'
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs'
import generatePicker from 'antd/es/date-picker/generatePicker'
import { DatePickerProps } from 'antd'
import styled from 'styled-components'
import { PickerRef } from 'rc-picker'

import RightIcon from 'src/Assets/Svg/pages-icons/RightArrow.svg?react'

const AntdDatePicker = generatePicker<Dayjs>(dayjsGenerateConfig)

const StyledPrevIcon = styled(RightIcon)`
  transform: rotate(180deg);
`

const CustomDatePicker = forwardRef<PickerRef, DatePickerProps>(
  ({ className, ...rest }, ref) => (
    <AntdDatePicker
      nextIcon={<RightIcon />}
      prevIcon={<StyledPrevIcon />}
      popupClassName={className}
      prefixCls='date-picker'
      ref={ref}
      {...rest}
    />
  ),
)

export const PrimaryDatePicker = styled(CustomDatePicker)`
  z-index: 1000 !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;

  &.date-picker-dropdown .date-picker-date-panel .date-picker-body {
    padding: 0 16px;
  }
  &.date-picker-dropdown .date-picker-header-view > button {
    line-height: 150%;
    &:hover {
      color: ${({ theme }) => theme.common.primaryText};
    }
  }

  &.date-picker-dropdown .date-picker-header-view {
    color: ${({ theme }) => theme.common.primaryText};
    font-family: 'Segoe UI';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
  }
  &.date-picker-dropdown .date-picker-panel-container {
    border-radius: 16px;
    border: 1px solid ${({ theme }) => theme.common.primaryBorder};
    background: ${({ theme }) => theme.common.primaryBg};
    box-shadow: 0px 0px 12px 0px rgba(51, 51, 51, 0.12);
  }

  &.date-picker-dropdown .date-picker-header {
    padding: 16px 16px;
    border-bottom: none;
  }

  &.date-picker-dropdown .date-picker-ranges {
    padding: 16px 16px;
  }
  &.date-picker-dropdown .date-picker-header > button {
    min-width: fit-content;
  }
  .date-picker-super-prev-icon,
  .date-picker-super-next-icon {
    display: none;
  }
  .date-picker-content thead tr th {
    font-family: 'Segoe UI';
    color: ${({ theme }) => theme.common.primaryText};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
  &.date-picker-dropdown
    .date-picker-cell-in-view.date-picker-cell-selected:not(
      .date-picker-cell-disabled
    )
    .date-picker-cell-inner {
    border-radius: 8px;
    background: ${({ theme }) => theme.button.thirdBg};
    color: ${({ theme }) => theme.common.fourthText};
    font-family: 'Segoe UI';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
  &.date-picker-dropdown .date-picker-cell {
    font-family: 'Segoe UI';
    color: ${({ theme }) => theme.common.primaryText};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
  &.date-picker-dropdown .date-picker-cell-in-view {
    color: ${({ theme }) => theme.common.primaryText};
    font-family: 'Segoe UI';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
  &.date-picker-dropdown
    .date-picker-cell-in-view.date-picker-cell-today
    .date-picker-cell-inner::before {
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.button.thirdBg};
  }
  &.date-picker-dropdown .date-picker-ranges .date-picker-ok {
    margin: 0 auto;
  }
  &.date-picker-dropdown .date-picker-cell-disabled::before {
    background: ${({ theme }) => theme.common.primaryBorder};
  }
`
