import { FC, useEffect } from 'react'

import { ModalType } from 'src/Containers/ScheduleSpotifyWrapper/components/ModalsContainer'
import { GenresModal } from 'src/Components/GenresModal'
import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { getAllGenres } from 'src/Redux/genres-process/api-actions'
import { getScheduledTikTokCampaign } from 'src/Redux/schedule-process/tiktokCampaign/selectors'
import { CampaignPlatformTypeEnum, Genre } from 'src/Types'
import { updateTikTokCampaign } from 'src/Redux/schedule-process/tiktokCampaign/api-actions'
import { useGenresAndLanguagesAnalytics } from 'src/Hooks/useGenresAndLanguagesAnalytics'
import { mapScheduledSpotifyCampaignForUpdate } from 'src/Containers/ScheduleTiktokWrapper/helpers/mapScheduledSpotifyCampaignForUpdate'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'

import { CommentModal } from '../CommentModal'

export const ModalsContainer: FC = () => {
  const { search, setSearch } = useCustomSearchParams()
  const scheduledCampaign = useAppSelector(getScheduledTikTokCampaign)
  const dispatch = useAppDispatch()

  const { handleGenreAddedEvent } = useGenresAndLanguagesAnalytics()

  useEffect(() => {
    void dispatch(getAllGenres())
  }, [dispatch])

  const handleSave = (selectedGenres: Genre[]): void => {
    const mappedCampaign =
      mapScheduledSpotifyCampaignForUpdate(scheduledCampaign)

    handleGenreAddedEvent({
      platformName: CampaignPlatformTypeEnum.TIKTOK,
      genreId: selectedGenres.map((el) => Number(el.id)).join(),
      genreName: selectedGenres.map((el) => String(el.genre_name)).join(),
    })

    void dispatch(
      updateTikTokCampaign({
        ...mappedCampaign,
        genres: selectedGenres.map((el) => Number(el.id)),
      }),
    )
      .unwrap()
      .finally(() => {
        setSearch({ modal: null })
      })
  }

  if (search.get('modal') === ModalType.Genres) {
    return (
      <GenresModal
        genres={scheduledCampaign.genres.map((genre) => ({
          id: String(genre.value),
          name: String(genre.label),
        }))}
        onSave={handleSave}
      />
    )
  }
  if (search.get('modal') === ModalType.Comment) {
    return <CommentModal />
  }
  return null
}
