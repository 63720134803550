import styled from 'styled-components'
import { Modal } from 'antd'

import { variables, colors } from 'src/Styled/variables'

export const ModalContainer = styled(Modal)<{ modalHeaderGreen: boolean }>`
  position: fixed;
  top: 60px;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0;
  margin: 0;
  width: 100% !important;
  max-width: 100% !important;
  & > div {
    height: 100%;
    position: relative;
  }

  .ant-modal-content {
    width: 100%;
    height: 100%;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }

    .ant-modal-close {
      position: fixed;
      top: 60px;
      display: ${({ modalHeaderGreen }) =>
        modalHeaderGreen ? 'flex' : 'none'};
      align-items: center;
      justify-content: center;

      .ant-modal-close-x {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 62px;
        height: 62px;
        color: ${colors.greyTextWhiteBg};
        opacity: 0.5;

        svg {
          width: 10px;
          height: 10px;
        }
      }
    }
    .ant-modal-header {
      display: none;
    }
    .ant-modal-body {
      padding: 0;
      border-radius: 0 0 8px 8px;
    }
    .ant-modal-footer {
      display: none;
    }
  }
`

export const HeaderContainer = styled.div`
  display: grid;
  grid-template-columns: 12px 1fr;
  gap: 22px;
  align-items: start;
  margin-bottom: 22px;
  padding-top: 60px;
`

export const BackButton = styled.div`
  margin-right: 22px;
  width: 12px;
  height: 12px;
  align-self: center;
`

export const HeaderTitle = styled.h4`
  font-size: ${variables.fontSize20};
  font-weight: ${variables.fontWeight600};
  color: ${colors.mainText};
  line-height: 30px;
  align-self: center;
`

export const MobileModalContainer = styled.div<{
  fullScreenMobileMode?: boolean
}>`
  padding: 16px;
  background: ${colors.white};
  border-radius: 4px;
  min-height: ${({ fullScreenMobileMode }) =>
    fullScreenMobileMode ? '100%' : 'unset'};
`

export const ModalWrapper = styled.div<{ fullScreenMobileMode?: boolean }>`
  z-index: 1;
  box-sizing: border-box;
  position: fixed;
  overflow: auto;
  left: 0;
  top: 0;
  background-color: ${colors.greyBlue};
  width: 100%;
  height: 100%;
  padding: ${({ fullScreenMobileMode }) =>
    fullScreenMobileMode ? '60px 0 0 0' : '21px 16px 0 16px'};
`
