import { object, string as yupString, ref } from 'yup'

export const ResetPasswordSchema = object({
  password: yupString().required('Password field should not be empty'),
  confirm: yupString()
    .required('You should confirm your new password')
    .oneOf(
      [ref('password')],
      'The password and confirmation password do not match',
    ),
}).required()
