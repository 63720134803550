import styled from 'styled-components'

import { variables, colors } from 'src/Styled/variables'

export const DataNotReceivedContainer = styled.div`
  display: flex;
  align-items: center;
`

export const DataNotReceivedIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
`

export const DataNotReceivedLabel = styled.div`
  margin: 0 0 0 8px;
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight400};
  color: ${colors.mainText};
`
