import React, { FC } from 'react'

import { useTranslation } from 'react-i18next'

import { InfoBlock } from './components/InfoBlock'
import { infoBlocks } from './pageData'
import { EarningBlock } from './components/EarningBlock'

import { PageContainer, Title } from './styles'

export const Monetization: FC = () => {
  const { t } = useTranslation()

  return (
    <PageContainer>
      <Title>{t('monetization.monetizationTitle')}</Title>

      <EarningBlock />

      {infoBlocks.map((infoBlock) => (
        <InfoBlock key={infoBlock.title} data={infoBlock} />
      ))}
    </PageContainer>
  )
}
