import styled from 'styled-components'

import { colors, device, variables } from 'src/Styled/variables'

export const List = styled.div`
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 0 0 40px;
  width: 100%;
`
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
`
export const ListItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`
export const Container = styled.div<{ isBig: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 24px 56px;
  justify-content: center;
  height: ${({ isBig }) => (isBig ? '380px' : '332px')};
  align-items: center;
  background-color: ${colors.greyBg};
  border: 1px solid ${colors.greyStroke};
  flex-grow: 1;
  @media ${device.mobileMax} {
    padding: 24px;
    width: 100%;
  }
`
export const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`
export const TitleWrapper = styled.div`
  margin-bottom: 14px;
`

export const ButtonWrapper = styled.div`
  width: 140px;
  display: flex;
  justify-content: center;
`
export const Recommended = styled.div`
  background-color: ${colors.green1Light};
  font-weight: ${variables.fontWeight600};
  border-radius: 4px;
  color: ${colors.green};
  padding: 0px 4px 4px 4px;
  position: absolute;
  top: -10px;

  @media ${device.mobileMax} {
    position: relative;
  }
`
