import styled from 'styled-components'
import { Modal } from 'antd'

import { variables, colors, device } from 'src/Styled/variables'

export const ModalContainer = styled(Modal)`
  position: fixed;
  top: 60px !important;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0;
  margin: 0 !important;
  width: 100% !important;
  max-width: 100% !important;

  .ant-modal-content {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: ${colors.white};
    border-radius: 0;
    overflow-y: auto;
    overflow-x: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }

    .ant-modal-close {
      position: fixed;
      top: 100px;
      right: 38px;
      display: flex;
      align-items: center;
      justify-content: center;

      .ant-modal-close-x {
        display: flex;
        align-items: center;
        justify-content: center;
        color: ${colors.greyTextWhiteBg};
        opacity: 0.5;

        svg {
          width: 14px;
          height: 14px;
        }
      }
    }
    .ant-modal-header {
      display: none;
    }
    .ant-modal-body {
      padding: 0;
      border-radius: 0 0 8px 8px;
    }
    .ant-modal-footer {
      display: none;
    }

    @media ${device.mobileMax} {
      background: ${colors.greyBlue};

      .ant-modal-close {
        display: none;
      }
    }
  }
`

export const BackButton = styled.div`
  position: absolute;
  top: 21px;
  left: 30px;
  display: flex;
  align-items: center;
`

export const BackIcon = styled.div`
  align-self: center;
  margin-right: 22px;
  width: 12px;
`

export const BackLabel = styled.span`
  display: flex;
  font-size: ${variables.fontSize20};
  line-height: 1.5;
  font-weight: ${variables.fontWeight600};
  color: ${colors.mainText};
`

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% - 32px);
  background: ${colors.white};
  margin: 64px 16px 70px;

  @media ${device.mobileMax} {
    padding: 16px;
    margin: 64px 16px 70px;
  }
`

export const Text = styled.div`
  display: inline-block;
  font-size: ${variables.fontSize18};
  line-height: 1.5;
  font-weight: 400;
  color: ${colors.mainText};

  @media ${device.mobileMax} {
    flex-direction: column;
    align-self: flex-start;
  }
`

export const Title = styled(Text)`
  width: 600px;

  @media ${device.mobileMax} {
    width: 100%;
  }
`

export const Hashtag = styled(Text)`
  margin: 0;
  margin-left: 6px;
  color: ${colors.green};

  &:first-child {
    margin-left: 6px;
  }

  @media ${device.mobileMax} {
    margin-left: 0px;

    &:first-child {
      margin-left: 0px;
    }
  }
`

export const ButtonContainer = styled.div`
  margin-top: 64px;
  width: 185px;
`

export const HiddenImageContainer = styled.div`
  opacity: 0;
  position: absolute;
  pointer-events: none;
`

export const RadioContainer = styled.div`
  margin-bottom: 32px;
`
