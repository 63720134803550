import React from 'react'

import { StyledDottedBtn } from './DottedBtn.styled'

export interface DottedBtnProps {
  content: string
  onClick?: (e?: React.MouseEvent) => void
}

export const DottedBtn: React.FC<DottedBtnProps> = ({ content, ...props }) => (
  <StyledDottedBtn type='button' {...props}>
    {content}
  </StyledDottedBtn>
)
