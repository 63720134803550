import React, { FC } from 'react'

import styled from 'styled-components'

import { Review } from 'src/Types'
import { artistClickShareEvent } from 'src/Helpers/TagManager'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import { MAX_MOBILE_WIDTH } from 'src/Styled/variables'

import { ReviewBlock } from '../ReviewBlock'
import { ShareModal } from '../ShareModal'

const MainInfoContainer = styled.div`
  display: flex;
  padding: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.common.primaryBorder};
  background-color: ${({ theme }) => theme.common.primaryBg};
  box-shadow: 0px 0px 32px 0px rgba(51, 51, 51, 0.08);

  @media (max-width: ${MAX_MOBILE_WIDTH}) {
    box-shadow: none;
    border: none;
    padding: 0;
    margin-top: 32px;
  }
`

type ReviewBlockProps = {
  reviews?: Array<Review>
  userId?: number
  campaignId?: number
  trackName?: string
  trackAuthorName?: string
  trackCoverUrl?: string
}

export const ReviewsList: FC<ReviewBlockProps> = ({
  reviews,
  userId,
  campaignId,
  trackName,
  trackAuthorName,
  trackCoverUrl,
}) => {
  const { setSearch } = useCustomSearchParams()

  if (!reviews || reviews.length === 0) {
    return null
  }

  const onShareClick = (): void => {
    setSearch({
      modal: 'share',
    })

    campaignId &&
      artistClickShareEvent({
        userId,
        campaignId,
      })
  }

  return (
    <MainInfoContainer>
      {reviews.map((review) => (
        <>
          <ReviewBlock
            key={review.id}
            comment={review.comment}
            firstName={review.Curator.User.first_name}
            lastName={review.Curator.User.last_name}
            followersCount={Number(review.Curator.followers_count)}
            playlistsCount={review.Curator.playlists_count}
            userCover={review.Curator.user_photo_url}
            matchedTrackPlaylists={
              review.matched?.map((playlist) => ({
                playlistGenres: playlist.genres.map((genre) => genre.name),
                playlistName: playlist.playlist_name,
              })) || []
            }
            addedTrackPlaylist={{
              playlistName: review?.Playlist?.playlist_name || '',
              playlistGenres:
                review?.Playlist?.genres.map((genre) => genre.name) || [],
              playlistUrl: review?.Playlist?.playlist_url,
            }}
            onShareClick={onShareClick}
          />

          <ShareModal
            userId={Number(userId)}
            campaignId={campaignId}
            trackName={trackName}
            trackAuthorName={trackAuthorName}
            trackCoverUrl={trackCoverUrl}
            comment={review.comment}
            firstName={review.Curator.User.first_name}
            lastName={review.Curator.User.last_name}
            userPhotoUrl={review.Curator.user_photo_url}
          />
        </>
      ))}
    </MainInfoContainer>
  )
}
