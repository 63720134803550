import styled from 'styled-components'

import { colors, device } from 'src/Styled/variables'

import { GapSize } from './props'

export const StyledRadioGroup = styled.div<{
  isInner?: boolean
  isVertical: boolean
  gapSize: GapSize
}>`
  margin-left: ${({ isInner }) => (isInner ? '40px' : '0px')};

  .ant-radio-group {
    width: 100%;
    .ant-space {
      width: 100%;
      gap: ${({ gapSize }) => {
        if (gapSize === 'lg') {
          return '50px'
        }
        if (gapSize === 'sm') {
          return '10px'
        }
        return '30px'
      }} !important;
      .ant-space-item {
        margin-top: ${({ isInner }) => (isInner ? '22px' : '13px')};
        ${({ isVertical }) => {
          if (isVertical) {
            return `
              &:first-child {
                margin-top: 21px;
              }
            `
          }
          return ''
        }}
        @media ${device.mobileMax} {
          margin-top: ${({ isInner }) => (isInner ? '2px' : '4px')};
          &:first-child {
            margin-top: 16px;
          }
        }
      }
    }
  }
`

export const StyledRadio = styled.div<{ isInner?: boolean }>`
  .ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
    border-color: ${colors.greyTextBlackBg};
    background: ${colors.errorBg};
  }
  .ant-radio-wrapper:hover {
    .ant-radio-inner {
      border-color: ${colors.greyTextBlackBg};
    }
  }

  .ant-radio-input {
    animation-duration: 0s !important;
  }
  .ant-radio-inner {
    border-color: ${colors.greyTextBlackBg};
    background: ${colors.errorBg};
    animation-duration: 0s !important;

    &:after {
      background-color: ${colors.green};
    }
  }

  .ant-radio-input:focus + .ant-radio-inner,
  .ant-radio-input:hover + .ant-radio-inner {
    border-color: ${colors.greyTextBlackBg};
    background: ${colors.errorBg};
    box-shadow: none;

    &:after {
      background-color: ${colors.green};
      box-shadow: none;
    }
  }

  &:hover {
    .ant-radio-checked .ant-radio-inner {
      border-color: ${colors.greyTextBlackBg};
      background: ${colors.errorBg};
      box-shadow: none;

      &:after {
        background-color: ${colors.green};
        box-shadow: none;
      }
    }
  }
`

export const Content = styled.div<{ active?: boolean }>`
  display: ${({ active }) => (active ? 'flex' : 'none')};
  font-size: 16px !important;
`
