import React, { useState, useEffect, useMemo, useCallback } from 'react'

import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useDebouncedCallback } from 'use-debounce'

import {
  addTransferwiseRecipient,
  getTransferwiseAccountRequirements,
  specifyTransferwiseAccountRequirements,
} from 'src/Redux/wise-process/api-actions'
import { colors } from 'src/Styled/variables'
import {
  getRecipient,
  getRequirements,
  getWiseLoading,
} from 'src/Redux/wise-process/selectors'
import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { ApiError } from 'src/Redux/types'

import BankDetailsForm from './BankDetailsForm'
import { BankDetails, getInitialValues } from './helpers'
import { StyledTabs, TabName, TabsContainer, UserInfo, Loader } from './style'
import { PayoutAlerts } from './PayoutAlerts'
import { CurrencyForm } from './CurrencyForm'

const DELAY = 300
const KEYS_AMOUNT = 2

const WisePayout: React.FunctionComponent = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const requirements = useSelector(getRequirements)
  const recipient = useSelector(getRecipient)
  const [activeTab, setActiveTab] = useState<string>()
  const [currentCurrency, setCurrentCurrency] = useState<string>()
  const isWiseLoading = useAppSelector(getWiseLoading)
  const [isSubmitSuccessAlert, setIsSubmitSuccessAlert] = useState(false)
  const [isSubmitFailedAlert, setIsSubmitFailedAlert] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const handleChangeCurrency = useCallback(
    ({ value }: { value: string }): void => {
      setCurrentCurrency(value)
      void dispatch(getTransferwiseAccountRequirements(value))
    },
    [dispatch],
  )

  useEffect(() => {
    if (recipient && !currentCurrency) {
      handleChangeCurrency({ value: recipient.currency })
    }
    if (recipient && requirements?.length) {
      const active = requirements.find(
        (item) => item.type === recipient.type,
      )?.title
      setActiveTab(active)
    }
    if (requirements?.length && !recipient) {
      setActiveTab(requirements[0].title)
    }
  }, [recipient, currentCurrency, handleChangeCurrency, requirements])

  const initialValues = useMemo(
    () => getInitialValues(recipient, requirements),
    [recipient, requirements],
  )

  const handleSubmit = (
    bankDetailsForm: BankDetails,
    transferType: string,
  ): void => {
    void dispatch(
      addTransferwiseRecipient({
        currency: currentCurrency || '',
        type: transferType || '',
        details: bankDetailsForm,
      }),
    )
      .unwrap()
      .then((res) => {
        setIsSubmitFailedAlert(false)
        setIsSubmitSuccessAlert(true)
        return res
      })
      .catch((error: ApiError) => {
        setErrorMessage(error.message || String(t('settingsPage.contact')))
        setIsSubmitFailedAlert(true)
        setIsSubmitSuccessAlert(false)
      })
      .finally(() => {
        window.scrollTo(0, 0)
      })
  }

  const handleSpecifyRequirements = (
    val: string,
    key: string,
    transferType: string,
  ): void => {
    const details: Record<string, string | Record<string, string>> = {}
    const path = key.split('.')
    if (path.length === KEYS_AMOUNT) {
      const [outerPath, nestedPath] = path
      const nesting = {} as Record<string, string>
      nesting[nestedPath] = val
      details[outerPath] = nesting
    } else {
      details[key] = val
    }
    if (currentCurrency && transferType) {
      void dispatch(
        specifyTransferwiseAccountRequirements({
          currency: currentCurrency,
          type: transferType,
          details,
        }),
      )
        .unwrap()
        .catch(() => {
          setIsSubmitFailedAlert(true)
          setErrorMessage('Something went wrong')
        })
    }
  }
  const debounceSubmit = useDebouncedCallback(handleSubmit, DELAY)

  const items = requirements
    ?.filter(({ type }) => type !== 'email')
    ?.map((tab) => ({
      label: <TabName>{tab.title}</TabName>,
      key: tab.title,
      children: (
        <>
          {tab.usageInfo && <UserInfo>{tab.usageInfo}</UserInfo>}
          <BankDetailsForm
            item={tab}
            initialValues={
              recipient &&
              recipient.currency === currentCurrency &&
              recipient.type === tab.type
                ? initialValues
                : {}
            }
            handleSpecifyRequirements={(val, key) =>
              handleSpecifyRequirements(val, key, tab.type)
            }
            onSubmit={(data) => {
              debounceSubmit(data, tab.type)
            }}
          />
        </>
      ),
    }))

  const showLoader = isWiseLoading && !currentCurrency && !requirements?.length

  return (
    <>
      <PayoutAlerts
        errorMessage={errorMessage}
        isSubmitFailedAlert={isSubmitFailedAlert}
        isSubmitSuccessAlert={isSubmitSuccessAlert}
        setIsSubmitFailedAlert={setIsSubmitFailedAlert}
        setIsSubmitSuccessAlert={setIsSubmitSuccessAlert}
      />
      <CurrencyForm setCurrentCurrency={setCurrentCurrency} />
      {showLoader ? (
        <Loader color={colors.green} height={24} type='spinningBubbles' />
      ) : (
        <TabsContainer>
          <StyledTabs
            activeKey={activeTab}
            items={items}
            onChange={(activeKey) => setActiveTab(activeKey)}
          />
        </TabsContainer>
      )}
    </>
  )
}
export default WisePayout
