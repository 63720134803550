import { createSlice } from '@reduxjs/toolkit'

import { setApiError } from 'src/Hooks/redux'
import { Genre } from 'src/Types/index'

import { ApiError, NameSpace } from '../types'

import {
  AutoselectedGenres,
  getAllCuratorGenres,
  getAllGenres,
  getAutoselectedSpotifyPlaylistGenres,
} from './api-actions'

export interface GenresState {
  genres: Array<Genre>
  autoselectedGenres: AutoselectedGenres['autoselectedGenres']
  max_curators: number
  loading: boolean
  error: ApiError | null
}

const initialState: GenresState = {
  genres: [],
  autoselectedGenres: [],
  max_curators: 0,
  loading: false,
  error: null,
}

export const genresProcess = createSlice({
  initialState,
  name: NameSpace.Genres,
  reducers: {},
  extraReducers(builder) {
    // getAllGenresAction
    builder.addCase(getAllGenres.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(getAllGenres.rejected, (state, action) => {
      state.loading = false
      state.error = setApiError(action) || null
    })
    builder.addCase(getAllGenres.fulfilled, (state, action) => {
      state.loading = false
      state.genres = action.payload
      state.error = null
    })
    // getAllCuratorsGenresAction
    builder.addCase(getAllCuratorGenres.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(getAllCuratorGenres.rejected, (state, action) => {
      state.loading = false
      state.error = setApiError(action)
    })
    builder.addCase(getAllCuratorGenres.fulfilled, (state, action) => {
      state.loading = false
      state.genres = action.payload
      state.error = null
    })
    // getAutoselectedSpotifyPlaylistGenres
    builder.addCase(getAutoselectedSpotifyPlaylistGenres.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(
      getAutoselectedSpotifyPlaylistGenres.rejected,
      (state, action) => {
        state.loading = false
        state.error = setApiError(action) || null
      },
    )
    builder.addCase(
      getAutoselectedSpotifyPlaylistGenres.fulfilled,
      (state, action) => {
        state.loading = false
        state.autoselectedGenres = action.payload.autoselectedGenres || []
        state.error = null
      },
    )
  },
})
