import { createSlice } from '@reduxjs/toolkit'

import { ApiError, NameSpace, SliceStatus } from 'src/Redux/types'
import { Coupon } from 'src/Types'
import { setApiError } from 'src/Hooks/redux'

import { checkCoupon } from './api-actions'

export interface CouponState {
  coupon: Coupon | null
  error: ApiError | null
  status: SliceStatus
}

const initialState: CouponState = {
  coupon: null,
  error: null,
  status: SliceStatus.Idle,
}

export const couponSlice = createSlice({
  initialState,
  name: NameSpace.Cupon,
  reducers: {
    removeCoupon(state) {
      state.coupon = null
      state.error = null
      state.status = SliceStatus.Idle
    },
  },
  extraReducers(builder) {
    builder.addCase(checkCoupon.pending, (state) => {
      state.status = SliceStatus.Loading
    })
    builder.addCase(checkCoupon.fulfilled, (state, { payload }) => {
      state.status = SliceStatus.Succeeded
      state.coupon = payload.coupon
    })
    builder.addCase(checkCoupon.rejected, (state, action) => {
      state.status = SliceStatus.Failed
      state.error = setApiError(action)
    })
  },
})

export const { removeCoupon } = couponSlice.actions
