import React from 'react'

import ModalCloseIcon from 'src/Assets/Svg/modalClose.svg?react'
import ButtonClose from 'src/Assets/Svg/buttonClose.svg?react'

import { CustomModalProps } from './CustomModalProps'

import {
  ContentContainer,
  ModalCloseButton,
  PromocodeButtonClose,
  ModalHeader,
  ModalTitle,
  ModalWrapper,
} from './styles'

const CustomModal: React.FC<CustomModalProps> = ({
  header,
  hint,
  content,
  children,
  promocode,
  handleClose,
}) => (
  <ModalWrapper>
    {!header && (
      <ModalHeader promocode={promocode || false}>
        <ModalTitle>{content}</ModalTitle>
        <ModalCloseButton onClick={handleClose} promocode={promocode || false}>
          {promocode ? (
            <PromocodeButtonClose>
              <ButtonClose width={10} height={10} />
            </PromocodeButtonClose>
          ) : (
            <ModalCloseIcon width={10} height={10} />
          )}
        </ModalCloseButton>
      </ModalHeader>
    )}

    <ContentContainer header hint={hint}>
      {children}
    </ContentContainer>
  </ModalWrapper>
)

export default CustomModal
