import React, { FC } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { useAppSelector } from 'src/Hooks/redux'
import { getCampaignToSchedule } from 'src/Redux/campaign-process/spotifyCampaignSchedule-process/selectors'
import { ANY_LANGUAGE_ID } from 'src/Constants/constants'

import { SpotifyScheduleGenres } from './SpotifyScheduleGenres'
import { SpotifyScheduleLanguage } from './SpotifyScheduleLanguage'

const Container = styled.div`
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.common.primaryBorder};
  background: ${({ theme }) => theme.common.primaryBg};
  box-shadow: 0px 0px 32px 0px rgba(51, 51, 51, 0.08);
`
const Title = styled.div`
  color: ${({ theme }) => theme.common.primaryText};
  font-family: 'Segoe UI';
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
`

export const SpotifyTrackParameters: FC = () => {
  const { t } = useTranslation()
  const campaignDetails = useAppSelector(getCampaignToSchedule)

  const isGenresActive = !campaignDetails.genres.length
  const hasSelectedLanguages = Boolean(
    campaignDetails.languages.filter((lang) => lang.id !== ANY_LANGUAGE_ID)
      .length,
  )
  return (
    <Container>
      <Title>{t('scheduleCampaignPage.chooseTrackParameters')}</Title>
      <SpotifyScheduleGenres
        hasSelectedLanguages={hasSelectedLanguages}
        genres={campaignDetails.genres}
      />
      <SpotifyScheduleLanguage
        isAddButtonDisabled={isGenresActive}
        languages={campaignDetails.languages}
      />
    </Container>
  )
}
