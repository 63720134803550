import styled from 'styled-components'

import { colors } from 'src/Styled/variables'

export const Message = styled.div`
  text-align: center;
  color: ${colors.white};
  width: 100%;
  margin: 0 auto;
`

export const Wrapper = styled.div`
  text-align: center;
  margin: 0;
  padding: 12px 32px 12px 17px;
  background: transparent;
`
