import React, { FunctionComponent, useEffect, useRef } from 'react'

import { useSelector } from 'react-redux'

import { Mobile, Desktop } from 'src/Components/Responsive'
import ArrowLeftIcon from 'src/Assets/Svg/arrow-left.svg?react'
import { TrackModalAlerts } from 'src/Containers/Tracks/components/TrackModalAlerts/TracksAlerts'
import { getUploadVideoError } from 'src/Redux/influencer-process/selectors'
import { ErrorCode } from 'src/Redux/types'

import { ModalProps } from './props'

import {
  ModalContainer,
  MobileModalContainer,
  HeaderContainer,
  HeaderTitle,
  BackButton,
  ModalWrapper,
} from './styles'

const Modal: FunctionComponent<ModalProps> = ({
  title,
  isModalVisible,
  modalHeaderGreen,
  closeModal,
  children,
  fullScreenMobileMode,
}) => {
  const containerRef: React.RefObject<HTMLDivElement> = useRef(null)
  const uploadVideoError = useSelector(getUploadVideoError)

  const scrollToTop = (): void => {
    if (containerRef.current) {
      containerRef.current.scrollTo(0, 0)
    }
  }

  useEffect(() => {
    if (uploadVideoError?.code === ErrorCode.InternalServerError) {
      scrollToTop()
    }
  }, [uploadVideoError])

  return (
    <>
      <Desktop>
        <ModalContainer
          title={title}
          open={isModalVisible}
          modalHeaderGreen={Boolean(modalHeaderGreen)}
          onCancel={closeModal}
        >
          {children}
        </ModalContainer>
      </Desktop>
      <Mobile>
        <ModalWrapper
          ref={containerRef}
          fullScreenMobileMode={fullScreenMobileMode}
        >
          {!fullScreenMobileMode && (
            <HeaderContainer>
              <BackButton onClick={closeModal}>
                <ArrowLeftIcon />
              </BackButton>
              <HeaderTitle>{title}</HeaderTitle>
            </HeaderContainer>
          )}
          <TrackModalAlerts />
          <MobileModalContainer fullScreenMobileMode={fullScreenMobileMode}>
            {children}
          </MobileModalContainer>
        </ModalWrapper>
      </Mobile>
    </>
  )
}

export default Modal
