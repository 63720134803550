import { createSlice } from '@reduxjs/toolkit'

import { setApiError } from 'src/Hooks/redux'

import { ApiError, NameSpace, SliceStatus } from '../../types'

import { getReferralUserList } from './api-actions'

export interface ReferralUser {
  firstName: string
  lastName: string
  email: string
  status: string
  createdAt: string
  amount: string
}

export interface ReferralListState {
  error: ApiError | null
  status: SliceStatus
  referralList: ReferralUser[]
}

const initialState: ReferralListState = {
  error: null,
  status: SliceStatus.Idle,
  referralList: [],
}

export const referralProcess = createSlice({
  initialState,
  name: NameSpace.ReferAndEarn,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getReferralUserList.pending, (state) => {
      state.status = SliceStatus.Loading
    })
    builder.addCase(getReferralUserList.fulfilled, (state, action) => {
      state.status = SliceStatus.Succeeded
      state.referralList = action.payload
    })
    builder.addCase(getReferralUserList.rejected, (state, action) => {
      state.status = SliceStatus.Failed
      state.error = setApiError(action)
    })
  },
})
