import React, { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'

import Button from 'src/Components/Buttons/Button'

import PlaylistVocalSettings from '../PlaylistVocalSettings'

import { ButtonWrapper, Modal } from './styles'

type PlaylistVocalSettingsProps = {
  toggle: (arg0: boolean) => void
  isOpen: boolean
}
const PlaylistVocalSettingsModal: FunctionComponent<
  PlaylistVocalSettingsProps
> = ({ toggle, isOpen }) => {
  const { t } = useTranslation()

  const onModalClose = (): void => {
    toggle(false)
  }

  return (
    <Modal open={isOpen} footer={null} closable={false} onCancel={onModalClose}>
      <PlaylistVocalSettings />
      <ButtonWrapper>
        <Button type='whiteWithGreenBorder' onClick={onModalClose}>
          {t('scheduleCampaignPage.gotIt')}
        </Button>
      </ButtonWrapper>
    </Modal>
  )
}
export default PlaylistVocalSettingsModal
