import { createDraftSafeSelector } from '@reduxjs/toolkit'

import { RootState } from '..'

import { PayPalTransactionsState } from '.'

const getState = (state: RootState): PayPalTransactionsState =>
  state.paypalTransactions

export const getRememberPayPalForFutureFlag = createDraftSafeSelector(
  getState,
  ({ rememberPayPalForFutureFlag }) => rememberPayPalForFutureFlag,
)

export const getIsProcessingPage = createDraftSafeSelector(
  getState,
  ({ isProcessingPage }) => isProcessingPage,
)

export const getIsPayPalTransactionsLoading = createDraftSafeSelector(
  getState,
  ({ loading }) => loading,
)
