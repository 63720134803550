import { createSlice } from '@reduxjs/toolkit'

import {
  CuratorStats,
  RejectReason,
  Review,
  ReviewDetails,
  ReviewStatus,
} from 'src/Types'
import { setApiError } from 'src/Hooks/redux'

import { ApiError, NameSpace } from '../types'

import {
  getAwaitingTracks,
  getCuratorStats,
  getMissedTracks,
  getRejectReasons,
  getReviewDetails,
  getReviewedTracks,
  getWordsBlacklist,
  respondToReview,
  sendReview,
  skipReview,
} from './api-actions'

export interface ReviewsState {
  rejectReasons: Array<RejectReason>
  rewardPerReview: number
  reviewDetails: ReviewDetails
  awaitingTracks: Array<Review>
  reviewedTracks: Array<Review>
  missedTracks: Array<Review>
  cancelledTracks: Array<Review>
  totalAllTracks: number
  totalAwaitingTracks: number
  totalReviewedTracks: number
  totalMissedTracks: number
  totalCancelledTracks: number
  wordsBlacklist: string[]
  curatorStats: CuratorStats
  loading: boolean
  error: ApiError | null
}

const initialState: ReviewsState = {
  rejectReasons: [],
  rewardPerReview: 0,
  reviewDetails: {
    id: 0,
    campaign_id: 0,
    comment: '',
    curator_rating: 0,
    playlists: [],
    potential_reward: '',
    rank: 0,
    reject_reason_id: 0,
    review_feedback: '',
    review_status: ReviewStatus.awaiting_review,
    track: {
      id: 0,
      track_image: '',
      track_name: '',
      track_url: '',
      artist_name: '',
      genres: [],
      languages: [],
    },
    daysLeft: 0,
    isRejectLastThree: false,
  },
  awaitingTracks: [],
  reviewedTracks: [],
  missedTracks: [],
  cancelledTracks: [],
  totalAllTracks: 0,
  totalAwaitingTracks: 0,
  totalReviewedTracks: 0,
  totalMissedTracks: 0,
  totalCancelledTracks: 0,
  wordsBlacklist: [],
  curatorStats: {
    acceptanceRate: 0,
    trackAddedCount: 0,
    missedCanceledCount: 0,
    missingRate: 0,
    receivedCount: 0,
    reviewRate: 0,
    reviewedCount: 0,
  },
  loading: true,
  error: null,
}

export const reviewsProcess = createSlice({
  initialState,
  name: NameSpace.Reviews,
  reducers: {},
  extraReducers: (builder) => {
    //  getRejectReasons
    builder.addCase(getRejectReasons.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getRejectReasons.fulfilled, (state, action) => {
      state.loading = false
      state.error = null
      state.rejectReasons = action.payload
    })
    builder.addCase(getRejectReasons.rejected, (state, action) => {
      state.loading = false
      state.error = setApiError(action)
    })

    // getAwaitingTracks
    builder.addCase(getAwaitingTracks.pending, (state) => {
      state.loading = state.awaitingTracks.length === 0
    })
    builder.addCase(getAwaitingTracks.fulfilled, (state, { payload }) => {
      state.loading = false
      state.error = null
      state.awaitingTracks = payload.reviews
        ? [...state.awaitingTracks, ...payload.reviews]
        : []
      state.totalAwaitingTracks = payload.total ? payload.total : 0
    })
    builder.addCase(getAwaitingTracks.rejected, (state, action) => {
      state.loading = false
      state.error = setApiError(action)
    })
    // getReviewedTracks
    builder.addCase(getReviewedTracks.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getReviewedTracks.fulfilled, (state, { payload }) => {
      state.loading = false
      state.error = null
      state.reviewedTracks = payload
        ? [...state.reviewedTracks, ...payload.reviews]
        : []
      state.totalReviewedTracks = payload.total ? payload.total : 0
    })
    builder.addCase(getReviewedTracks.rejected, (state, action) => {
      state.loading = false
      state.error = setApiError(action)
    })
    // getMissedTracks
    builder.addCase(getMissedTracks.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getMissedTracks.fulfilled, (state, { payload }) => {
      state.loading = false
      state.error = null
      state.missedTracks = payload
        ? [...state.missedTracks, ...payload.reviews]
        : []
      state.totalMissedTracks = payload.total ? payload.total : 0
    })
    builder.addCase(getMissedTracks.rejected, (state, action) => {
      state.loading = false
      state.error = setApiError(action)
    })

    // getReviewDetails

    builder.addCase(getReviewDetails.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getReviewDetails.fulfilled, (state, { payload }) => {
      state.loading = false
      state.error = null
      state.reviewDetails = payload
    })
    builder.addCase(getReviewDetails.rejected, (state, action) => {
      state.loading = false
      state.error = setApiError(action)
    })

    // getWordsBlacklist
    builder.addCase(getWordsBlacklist.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getWordsBlacklist.fulfilled, (state, { payload }) => {
      state.loading = false
      state.error = null
      state.wordsBlacklist = payload
    })
    builder.addCase(getWordsBlacklist.rejected, (state, action) => {
      state.loading = false
      state.error = setApiError(action)
    })
    // getCuratorStats
    builder.addCase(getCuratorStats.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getCuratorStats.fulfilled, (state, { payload }) => {
      state.loading = false
      state.error = null
      state.curatorStats = payload
    })
    builder.addCase(getCuratorStats.rejected, (state, action) => {
      state.loading = false
      state.error = setApiError(action)
    })

    // skipReview
    builder.addCase(skipReview.pending, (state) => {
      state.loading = true
    })
    builder.addCase(skipReview.fulfilled, (state, { payload }) => {
      state.loading = false
      state.error = null
      state.totalAwaitingTracks = state.totalAwaitingTracks - 1
      state.awaitingTracks = state.awaitingTracks.filter(
        ({ id }) => id !== Number(payload.review_id),
      )
    })
    builder.addCase(skipReview.rejected, (state, action) => {
      state.loading = false
      state.error = setApiError(action)
    })

    // sendReview
    builder.addCase(sendReview.pending, (state) => {
      state.loading = true
    })
    builder.addCase(sendReview.fulfilled, (state, { payload }) => {
      state.loading = false
      state.error = null
      state.totalAwaitingTracks = state.totalAwaitingTracks - 1
      state.awaitingTracks = state.awaitingTracks.filter(
        ({ id }) => id !== Number(payload.review.id),
      )
    })
    builder.addCase(sendReview.rejected, (state, action) => {
      state.loading = false
      state.error = setApiError(action)
    })

    // respondToReview
    builder.addCase(respondToReview.pending, (state) => {
      state.loading = true
    })
    builder.addCase(respondToReview.fulfilled, (state) => {
      state.loading = false
      state.error = null
    })
    builder.addCase(respondToReview.rejected, (state, action) => {
      state.loading = false
      state.error = setApiError(action)
    })
  },
})
