import { FC } from 'react'

import { string as yupString, object } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { SmartInput } from 'src/Components/SmartInput'
import Button from 'src/Components/Buttons/Button'
import { setLoginTypeToInitialState } from 'src/Redux/auth-process/authTypeSlice'
import { getAuthEmail } from 'src/Redux/auth-process/authTypeSlice/selectors'
import { useAppSelector } from 'src/Hooks/redux'

import { AccountOr, TextBlack, TextRed } from '../LoginSpotify/styles'
import { SendLoginLink } from '../SendLoginLink'

import { FieldWrapper, Form, Wrapper } from './styles'

interface FormState {
  email: string
}
export const LoginWithoutSignup: FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const defaultEmail = useAppSelector(getAuthEmail)

  const {
    watch,
    formState: { errors },
    register,
  } = useForm<FormState>({
    defaultValues: {
      email: defaultEmail,
    },
    mode: 'all',
    resolver: yupResolver(
      object({
        email: yupString().email().required(),
      }),
    ),
  })

  const handleLink = (): void => {
    dispatch(setLoginTypeToInitialState())
  }
  return (
    <>
      <Form>
        <Wrapper>
          <FieldWrapper>
            <SmartInput
              {...register('email')}
              error={errors.email}
              placeholder={t('login.email') || ''}
              type='email'
              data-cy='input-render-field-email'
              autoFocus
            />
          </FieldWrapper>
        </Wrapper>
      </Form>
      <TextRed data-cy='text_red-login-form-p-without-password'>
        <p data-cy='login-form-p-without-password' style={{ marginBottom: 0 }}>
          {t('login.withoutPassword')}
        </p>
      </TextRed>
      <TextBlack>
        <p data-cy='login-form-p-without-we-can-email'>
          {t('login.weCanEmail')}
        </p>
      </TextBlack>
      <SendLoginLink email={watch().email} />
      <AccountOr data-cy='account__or-login-easily-using'>
        <p data-cy='login-form-p'>{t('login.easilyUsing')}</p>
      </AccountOr>
      <Button
        htmlType='submit'
        type='green'
        data-cy='login-form-sign-another-account'
        onClick={handleLink}
      >
        {t('login.logInAnotherAccount')}
      </Button>
    </>
  )
}
