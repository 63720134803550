import React, { useEffect, useState, useMemo, ReactNode } from 'react'

import sbjs from 'sourcebuster'
import TagManager, { DataLayerArgs } from 'react-gtm-module'

import { APP } from 'src/Configs/App'

interface ITagManager {
  dataLayer: (dataLayerArgs: DataLayerArgs) => void
}
export interface TagManagerContextInterface {
  tagManager?: ITagManager
}

export interface TagManagerProps {
  children: ReactNode
}

const TagManagerContext = React.createContext({} as TagManagerContextInterface)

const TagManagerContextProvider: React.FC<TagManagerProps> = ({ children }) => {
  const [tagManager, setTagManager] = useState<ITagManager | undefined>()

  // Connect to SourceBuster
  useEffect(() => {
    sbjs.init({
      domain: APP.SOURCEBUSTER_DOMAIN,
      timezone_offset: 1,
    })
  }, [])

  useEffect(() => {
    const script = document.createElement('script')
    script.innerHTML = `
    (function(w,d,s,l,i){
      w[l]=w[l]||[];
      w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
      var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
      j.async=true;
      j.src='https://data.soundcamps.com/gtm.js?id='+i+dl;
      f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${APP.GTM_CONTAINER}');
  `
    document.head.appendChild(script)
    setTagManager(TagManager)
  }, [])

  const contextValue = useMemo(() => ({ tagManager }), [tagManager])

  return (
    <TagManagerContext.Provider value={contextValue}>
      {children}
    </TagManagerContext.Provider>
  )
}

export { TagManagerContext }
export default TagManagerContextProvider
