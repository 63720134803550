import { FunctionComponent } from 'react'

import styled from 'styled-components'
import { Typography } from 'antd'

import { capitalize } from 'src/Helpers/layoutHelpers'
import { Language } from 'src/Types/index'
import { variables, colors } from 'src/Styled/variables'

const { Paragraph } = Typography

export const LanguagesRow = styled(Paragraph)`
  font-size: ${variables.fontSize10};
  color: ${colors.mainText};

  &:first-child {
    margin-bottom: 4px;
  }
`

export interface CampaignLanguagesProps {
  languages?: Language[]
}

const CampaignLanguagesComponent: FunctionComponent<CampaignLanguagesProps> = ({
  languages,
}) => {
  const filteredLanguages = languages?.filter(
    (el) => el?.Language?.language_name !== 'Any language',
  )

  const anyLangPresent = languages?.find(
    (el) => el?.Language?.language_name === 'Any language',
  )
  return (
    <LanguagesRow ellipsis={{ rows: 1 }}>
      {filteredLanguages?.map(
        (el, id) =>
          `${capitalize(el?.Language?.language_name || '')}${
            id === filteredLanguages.length - 1 ? '' : ', '
          }`,
      )}
      {!anyLangPresent && filteredLanguages?.length ? ' (only)' : ''}
    </LanguagesRow>
  )
}

export default CampaignLanguagesComponent
