import {
  createDraftSafeSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit'

import { formatTimer } from 'src/Helpers/layoutHelpers'

import { NameSpace } from '../types'
import { RootState } from '..'

interface AnyCampaignPaidProcessState {
  checkoutCountdown: number | null
}
const initialState: AnyCampaignPaidProcessState = {
  checkoutCountdown: null,
}

export const checkoutTimer = createSlice({
  initialState,
  name: NameSpace.CHECKOUT_TIMER,
  reducers: {
    updateCountdown: (state, { payload }: PayloadAction<number | null>) => {
      state.checkoutCountdown = payload
    },

    startCountdown: (state, { payload }: PayloadAction<number | null>) => {
      if (state.checkoutCountdown === null) {
        state.checkoutCountdown = payload
      }
    },
  },
})

const getState = (state: RootState): AnyCampaignPaidProcessState =>
  state.checkoutTimer

export const getCheckoutTimer = createDraftSafeSelector(
  getState,
  ({ checkoutCountdown }) => checkoutCountdown,
)

export const getFormattedCheckoutTimer = createDraftSafeSelector(
  getState,
  ({ checkoutCountdown }) =>
    checkoutCountdown ? formatTimer(checkoutCountdown) : '00:00',
)

export const { updateCountdown, startCountdown } = checkoutTimer.actions
