import React, { FC } from 'react'

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Skeleton } from 'antd'

import TrackCoverIcon from 'src/Assets/Svg/pages-icons/TrackCover.svg'
import { MAX_MOBILE_WIDTH } from 'src/Styled/variables'

import { PrimaryButton } from '../Buttons/PrimaryButton'
import { TrackAudioPlayer } from '../TrackAudioPlayer'
import { SmartImage } from '../SmartImage'
import { PrimaryTrackCoverWrapper } from '../TrackAudioPlayer/PrimaryTrackCoverWrapper'
import { PrimaryDottedButton } from '../Buttons/PrimaryDottedButton'

export type ChosenTrackProps = {
  title: string
  artist?: string
  trackName?: string
  createLoading?: boolean
  duration?: string
  image?: string
  trackUrl?: string
  loading?: boolean
  onCreateClick: () => void
  onChangeClick: () => void
  onPlayEvent?: () => void
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  align-self: stretch;
  @media screen and (min-width: ${MAX_MOBILE_WIDTH}) {
    border-radius: 16px;
    padding: 24px;
    border: 1px solid ${({ theme }) => theme.common.primaryBorder};
    background: ${({ theme }) => theme.common.primaryBg};
    box-shadow: 0px 0px 32px 0px rgba(51, 51, 51, 0.08);
  }
`

const StyledPrimaryButton = styled(PrimaryButton)`
  padding-left: 40px;
  padding-right: 40px;
  border: 1px solid ${({ theme }) => theme.button.primaryText};
`

const TrackDetails = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 16px;
  align-self: stretch;
  flex-wrap: wrap;
`
const TrackInfo = styled.div`
  display: flex;
  min-width: 200px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
`
const TrackInfoName = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`
const Title = styled.div`
  color: ${({ theme }) => theme.common.primaryText};
  width: 100%;
  font-family: 'Segoe UI';
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
`
const Duration = styled(Title)`
  font-size: 14px;
  font-weight: 400;
`

const TrackName = styled(Title)`
  font-size: 14px;
  font-weight: 600;
`

const Artist = styled(Title)`
  font-size: 14px;
  font-weight: 400;
`

const TrackImg = styled(SmartImage)`
  border-radius: 12px;
  width: 68px;
  height: 68px;
`

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 24px;
  @media screen and (min-width: ${MAX_MOBILE_WIDTH}) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`

const Loader = styled(Skeleton.Node)`
  &.ant-skeleton {
    width: 100%;
    &.ant-skeleton-element .ant-skeleton-image {
      width: 100%;
      height: 120px;
      border-radius: 16px;
    }
  }
`

export const ChosenTrack: FC<ChosenTrackProps> = ({
  title,
  trackName,
  trackUrl,
  image,
  createLoading,
  artist,
  onCreateClick,
  onChangeClick,
  onPlayEvent,
  duration,
  loading: containerLoading,
}) => {
  const { t } = useTranslation()

  if (containerLoading) {
    return (
      <Loader active>
        <div />
      </Loader>
    )
  }
  return (
    <Container>
      <Title>{title}</Title>
      <Wrapper>
        <TrackDetails>
          <TrackAudioPlayer
            render={({ loading, currentTrackUrl, isPlaying, onClick }) => (
              <PrimaryTrackCoverWrapper
                show={Boolean(trackUrl)}
                iconSize={'25px'}
                loaderSize='50px'
                isPlaying={isPlaying && currentTrackUrl === trackUrl}
                loading={loading && currentTrackUrl === trackUrl}
                onClick={(e) => {
                  onClick(e, trackUrl)
                  if (!isPlaying) {
                    onPlayEvent?.()
                  }
                }}
              >
                <TrackImg
                  src={image}
                  alt={trackName}
                  fallbackSrc={TrackCoverIcon}
                />
              </PrimaryTrackCoverWrapper>
            )}
          />
          <TrackInfo>
            <TrackInfoName>
              <TrackName>{trackName}</TrackName>
              <Artist>{artist}</Artist>
            </TrackInfoName>
            <Duration>{duration}</Duration>
          </TrackInfo>
        </TrackDetails>
        <PrimaryDottedButton onClick={onChangeClick}>
          {t('chosenTrack.changeTrack')}
        </PrimaryDottedButton>
      </Wrapper>
      <StyledPrimaryButton loading={createLoading} onClick={onCreateClick}>
        {t('chosenTrack.createCampaign')}
      </StyledPrimaryButton>
    </Container>
  )
}
