import {
  NO_TRACKS_WERE_FOUND,
  SEARCH_ERROR_BY_NAME,
  SEARCH_ERROR_NOT_OFFICIAL_TRACK,
  SEARCH_ERROR_SAME_TIKTOK_TRACK,
  SEARCH_ERROR_SERVICE_UNAVAILABLE,
} from 'src/Constants/constants'

import { ErrorCode } from '../types'

export const getTiktokTrackErrorMessage = (
  message?: string,
  code?: number,
): string => {
  if (code === ErrorCode.Conflict) {
    return SEARCH_ERROR_SAME_TIKTOK_TRACK
  }

  if (message === 'noTracksWereFound') {
    return NO_TRACKS_WERE_FOUND
  }

  if (message === SEARCH_ERROR_BY_NAME || message === 'timeoutError') {
    return SEARCH_ERROR_SERVICE_UNAVAILABLE
  }

  if (message === 'notTheOfficial') {
    return SEARCH_ERROR_NOT_OFFICIAL_TRACK
  }

  return 'Oops! Something went wrong. Please try again later.'
}
