import { CampaignVideo } from 'src/Types'
import { PremoderationStatus, VideoStatus } from 'src/Constants/enums'

const monitoringVideoStatuses = [
  VideoStatus.APPROVED_BY_ARTIST,
  VideoStatus.APPROVED_AUTOMATICALLY,
  VideoStatus.APPROVED_BY_ADMIN,
  VideoStatus.AWAITING_MODERATOR_REVIEW,
]

const confirmedVideoStatuses = [
  VideoStatus.APPROVED_BY_ARTIST,
  VideoStatus.APPROVED_AUTOMATICALLY,
  VideoStatus.APPROVED_BY_ADMIN,
]

export interface CampaignPreModerationVideo extends CampaignVideo {
  preModerationStatus?: PremoderationStatus
}

export const mapVideoToPreModerationStatus = (
  video: CampaignVideo,
): CampaignPreModerationVideo => {
  let preModerationStatus

  const isConfirmedConditions = video.isConfirmed
  const isMonitoringConditions = !video.isConfirmed && video.isAvailable

  if (video.status === VideoStatus.AWAITING_REVIEW) {
    preModerationStatus = PremoderationStatus.NEW_VIDEO
  }
  if (
    monitoringVideoStatuses.includes(video.status) &&
    isMonitoringConditions
  ) {
    preModerationStatus = PremoderationStatus.MONITORING
  }

  if (confirmedVideoStatuses.includes(video.status) && isConfirmedConditions) {
    preModerationStatus = PremoderationStatus.CONFIRMED
  }

  if ([VideoStatus.DECLINED, VideoStatus.DISMISSED].includes(video.status)) {
    preModerationStatus = PremoderationStatus.REJECTED
  }

  if (preModerationStatus) {
    return { ...video, preModerationStatus }
  }

  return video
}
