import { createDraftSafeSelector } from '@reduxjs/toolkit'

import { SliceStatus } from 'src/Redux/types'

import { RootState } from '..'
import { getSpotifyTracksError } from '../spotifyTracks-process/selectors'

import { TikTokTrackState } from '.'

const getState = (state: RootState): TikTokTrackState => state.tikTokTrack

export const getTikTokTracksSelector = createDraftSafeSelector(
  getState,
  ({ tikTokTracks }) => tikTokTracks,
)

export const getIsLoadingTikTok = createDraftSafeSelector(
  getState,
  ({ status }) => status === SliceStatus.Loading,
)

export const getTikTokTrackSelector = createDraftSafeSelector(
  getState,
  ({ tikTokTrack }) => tikTokTrack,
)
export const getTiktokTrackErrorMessageSelector = createDraftSafeSelector(
  getState,
  ({ error }) => error?.message || '',
)

export const getAllSearchErrorSelector = createDraftSafeSelector(
  (state: RootState) => ({
    tiktokTrackErrorMessage: getTiktokTrackErrorMessageSelector(state),
    spotifyErrorMessage: getSpotifyTracksError(state)?.message || '',
  }),
  ({ tiktokTrackErrorMessage, spotifyErrorMessage }) =>
    tiktokTrackErrorMessage || spotifyErrorMessage,
)
