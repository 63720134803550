import { FC } from 'react'

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { useGetLanguagesForExistingPlaylistsQuery } from 'src/Redux/languagesApi'

import { getNewFilteredLanguages } from '../helpers'
import { List } from '../styles'

import { GroupedItem } from './GroupedItem'
import { SelectedLanguageComponent } from './SelectedLanguage'

import { SelectedLanguage } from '.'

const Text = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.common.primaryText};
  font-family: 'Segoe UI';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`

interface Props {
  selectedLanguage: SelectedLanguage | null
  searchValue: string

  targetLanguage?: number
  onClick: (item: SelectedLanguage) => void
  onChange: (id: number) => void
}

export const LanguagesList: FC<Props> = ({
  selectedLanguage,
  searchValue,
  targetLanguage,
  onClick,
  onChange,
}) => {
  const { t } = useTranslation()
  const { data: groupedOptions } = useGetLanguagesForExistingPlaylistsQuery()

  if (selectedLanguage) {
    return (
      <SelectedLanguageComponent
        selectedLanguage={selectedLanguage}
        targetLanguage={targetLanguage}
        onClick={() => onClick(selectedLanguage)}
        onChange={onChange}
      />
    )
  }

  const filteredOptions = getNewFilteredLanguages(
    groupedOptions || [],
    searchValue,
  )
  if (
    !filteredOptions[0]?.options?.length &&
    !filteredOptions[1]?.options?.length
  ) {
    return (
      <List>
        <Text>{t('spotifySchedulePage.noData')}</Text>
      </List>
    )
  }

  return (
    <List style={{ padding: 0 }}>
      {filteredOptions.map((item) => (
        <GroupedItem
          key={item.label}
          searchValue={searchValue}
          item={item}
          onClick={onClick}
        />
      ))}
    </List>
  )
}
