import { useCallback } from 'react'

import { NavigateOptions, useSearchParams } from 'react-router-dom'

interface UseCustomSearchParamsI {
  search: URLSearchParams
  setSearch: (
    params: Record<string, string | null>,
    navigateOpts?: NavigateOptions,
  ) => void
}

export const useCustomSearchParams = (): UseCustomSearchParamsI => {
  const [search, setSearchOriginal] = useSearchParams()

  const setSearch = useCallback(
    (
      params: Record<string, string | null>,
      navigateOpts?: NavigateOptions,
    ): void => {
      const newSearchParams = new URLSearchParams(search.toString())
      Object.entries(params).forEach(([key, value]) => {
        if (value === null) {
          newSearchParams.delete(key)
          return
        }
        newSearchParams.set(key, value)
      })
      setSearchOriginal(newSearchParams, navigateOpts)
    },
    [search, setSearchOriginal],
  )

  return {
    search,
    setSearch,
  }
}
