import { APP } from 'src/Configs/App'
import { rtkApi } from 'src/Services/rtkApi'

interface GetTikTokCampaignBudgetRangeResponse {
  campaignMinBudget: number
  campaignMaxBudget: number
}

const artistPaymentSettingsApi = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    getTikTokCampaignBudgetRange: builder.query<
      GetTikTokCampaignBudgetRangeResponse,
      void | null
    >({
      query: () => ({
        url: `${APP.TIKTOK_SERVER}/artist/payment-settings/tiktok`,
        method: 'get',
      }),

      providesTags: ['TikTokMinBudget'],
    }),
  }),
})
export const { useGetTikTokCampaignBudgetRangeQuery } = artistPaymentSettingsApi
