import React from 'react'

import styled from 'styled-components'

import { IconButton, IconButtonProps } from '../IconButton'

const NotificationLabel = styled.div`
  position: absolute;
  right: 8px;
  top: 8px;
  z-index: 1000;

  &:before {
    background-color: ${({ theme }) => theme.common.dangerBorder};
    opacity: 0.3;
    width: 12px;
    height: 12px;
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    animation: beforePulse 1.5s infinite;
  }
  &:after {
    background-color: ${({ theme }) => theme.common.pink};
    width: 6px;
    height: 6px;
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    transform: translate(-50%, -50%);
  }
`

type NotificationButtonProps = IconButtonProps & {
  hasNewNotifications?: boolean
}

export const NotificationButton: React.FC<NotificationButtonProps> = ({
  hasNewNotifications,
  ...rest
}) => (
  <div style={{ position: 'relative' }}>
    {hasNewNotifications && <NotificationLabel />}
    <IconButton {...rest} />
  </div>
)
