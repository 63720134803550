import React, { FC } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { colors, variables } from 'src/Styled/variables'

const ModalTitle = styled.h4`
  margin-bottom: 16px;
  color: ${colors.mainText};
  font-size: ${variables.fontSize18};
  font-weight: ${variables.fontWeight600};
`
const ModalDescription = styled.p`
  margin-bottom: 16px;
`

const ModalDescriptionText = styled.span`
  color: ${colors.mainText};
  font-size: ${variables.fontSize14};
`
const ModalDescriptionTextBold = styled(ModalDescriptionText)`
  font-weight: ${variables.fontWeight600};
`

interface Props {
  count: number
}

export const PlaylistsToSetModalDescription: FC<Props> = ({ count }) => {
  const { t } = useTranslation()
  return (
    <>
      <ModalTitle>{t('playlistsPage.playlistsToSetTitle')}</ModalTitle>
      <ModalDescription>
        <ModalDescriptionText>
          {t('playlistsPage.playlistsToSetRow1')}
        </ModalDescriptionText>
      </ModalDescription>
      <ModalDescription>
        <ModalDescriptionText>
          {t('playlistsPage.playlistsToSetRow2Part1')}
        </ModalDescriptionText>
        <ModalDescriptionTextBold>
          {` ${count} ${t('playlistsPage.playlistsToSetRow2Highlight')} `}
        </ModalDescriptionTextBold>
        <ModalDescriptionText>
          {t('playlistsPage.playlistsToSetRow2Part2')}
        </ModalDescriptionText>
      </ModalDescription>
    </>
  )
}
