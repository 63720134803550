import React, { FC, useMemo } from 'react'

import styled from 'styled-components'

import { ScheduleSettingsHeader } from 'src/Components/ScheduleSettingsHeader'
import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import {
  getIsLoadingTikTokCampaign,
  getScheduledTikTokCampaign,
} from 'src/Redux/schedule-process/tiktokCampaign/selectors'
import { CampaignPlatformTypeEnum } from 'src/Types'
import { getHasActiveSubscriptionSelector } from 'src/Redux/subscription-process/subscription/selectors'
import _dayjs from 'src/Helpers/dayjs'
import { mapScheduledSpotifyCampaignForUpdate } from 'src/Containers/ScheduleTiktokWrapper/helpers/mapScheduledSpotifyCampaignForUpdate'
import { updateTikTokCampaign } from 'src/Redux/schedule-process/tiktokCampaign/api-actions'
import { ScheduleSettingsPageSkeleton } from 'src/Components/Skeletons/ScheduleSettingsPageSkeleton'
import { PayPalProcessingModalTikTok } from 'src/Containers/ScheduleTiktokWrapper/components/TiktokSettings/PayPalProcessingModal'

import { TiktokScheduleTrackParams } from './TiktokScheduleTrackParams'
import { TiktokScheduleBudget } from './TiktokScheduleBudget.tsx'
import { TiktokScheduleSettingsModals } from './TiktokScheduleSettingsModals'

const Container = styled.div`
  display: flex;
  max-width: 1120px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  @media screen and (min-width: 768px) {
    align-items: flex-start;
    flex-direction: row;
    align-self: stretch;
  }
`

export const TiktokScheduleSettingsPage: FC = () => {
  const dispatch = useAppDispatch()
  const scheduledCampaign = useAppSelector(getScheduledTikTokCampaign)
  const hasActivePlan = useAppSelector(getHasActiveSubscriptionSelector)
  const loading = useAppSelector(getIsLoadingTikTokCampaign)

  const trackCover =
    scheduledCampaign.track?.coverUrl ?? scheduledCampaign.trackCoverUrl

  const artistName =
    scheduledCampaign.track?.authorName ??
    (scheduledCampaign.trackAuthorName || 'No data')

  const trackName =
    scheduledCampaign.track?.title ??
    (scheduledCampaign.trackTitle || 'No data')

  const startDate = useMemo(() => {
    if (scheduledCampaign.startDate) {
      return _dayjs(scheduledCampaign.startDate)
    }
    return hasActivePlan ? _dayjs() : _dayjs().add(1, 'day')
  }, [scheduledCampaign.startDate, hasActivePlan])

  if (loading) {
    return <ScheduleSettingsPageSkeleton />
  }
  return (
    <>
      <PayPalProcessingModalTikTok />
      <TiktokScheduleSettingsModals />
      <Container>
        <ScheduleSettingsHeader
          artistName={artistName}
          trackCover={trackCover}
          trackName={trackName}
          platform={CampaignPlatformTypeEnum.TIKTOK}
          startDate={startDate}
          todayEnable={hasActivePlan}
          onChangeDate={(date) => {
            const mappedCampaign =
              mapScheduledSpotifyCampaignForUpdate(scheduledCampaign)
            void dispatch(
              updateTikTokCampaign({
                ...mappedCampaign,
                startDate: date.toISOString(),
              }),
            )
          }}
        />
        <Wrapper>
          <TiktokScheduleTrackParams />
          <TiktokScheduleBudget />
        </Wrapper>
      </Container>
    </>
  )
}
