import React, { FC } from 'react'

import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Breadcrumb, BreadCrumbItem } from 'src/Components/Breadcrumb'
import { ArtistRoutesEnum } from 'src/Router/ArtistComponentsNewUI'

const NAVIGATE_BACK_INDEX = -1

export const CreateCampaignBreadcrumbsAuth: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const breadcrumbItems: BreadCrumbItem[] = [
    {
      title: t('createNewCampaignPage.campaigns'),
      path: ArtistRoutesEnum.CampaignsList,
    },
    {
      title: t('createNewCampaignPage.choosingCampaign'),
      path: ArtistRoutesEnum.ChooseCreateCampaignPlatform,
    },
    {
      title: t('createNewCampaignPage.trackSearch'),
      path: '',
    },
  ]

  const onBreadcrumbButtonClick = (): void => {
    navigate(NAVIGATE_BACK_INDEX)
  }

  const filteredBreadcrumbItems: BreadCrumbItem[] = breadcrumbItems.reduce<{
    items: BreadCrumbItem[]
    found: boolean
  }>(
    (acc, item) => {
      if (acc.found) {
        return acc
      }
      acc.items.push(item)
      if (item.path === pathname) {
        acc.found = true
      }
      return acc
    },
    { items: [], found: false },
  ).items

  return (
    <Breadcrumb
      items={filteredBreadcrumbItems}
      onButtonClick={onBreadcrumbButtonClick}
    />
  )
}
