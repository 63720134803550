import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'

import { APP } from 'src/Configs/App'
import { ErrorCode, NameSpace } from 'src/Redux/types'
import { api } from 'src/Services/api'
import { Coupon } from 'src/Types'

interface CouponResponse {
  coupon: Coupon
}

export const checkCoupon = createAsyncThunk<CouponResponse, string>(
  `${NameSpace.Cupon}/checkCoupon`,
  async (value, { rejectWithValue }) => {
    try {
      const { data } = await api.get<CouponResponse>(
        `${APP.SERVER}/user/coupon/${value}`,
      )
      return data
    } catch (error) {
      const { response } = error as AxiosError<{ error: string }>
      return rejectWithValue({
        message: response?.data.error,
        code: response?.status || ErrorCode.InternalServerError,
      })
    }
  },
)
