import { FC } from 'react'

import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

import { variables } from 'src/Styled/variables'
import { useQuickLoginUTMParameters } from 'src/Hooks/useQuickLoginUTMParameters'
import {
  signupErrorToLogin,
  signupToLogin,
} from 'src/Helpers/TagManager/AuthEvents'
import { DottedBtn } from 'src/Components/DottedBtn/DottedBtn'
import { PublicRoutesEnum } from 'src/Router/PublicComponentsNewUI'

const LoginButtonContainer = styled.div`
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 8px;
`

const Text = styled.span`
  font-family: 'Segoe UI';
  color: ${({ theme }) => theme.common.primaryText};
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight400};
  line-height: 150%;
  margin-top: 5px;
`

interface LoginLinkProps {
  isEmailExist: boolean
}

export const LoginLink: FC<LoginLinkProps> = ({ isEmailExist }) => {
  const navigate = useNavigate()
  const utmParameters = useQuickLoginUTMParameters()
  const params = new URLSearchParams(utmParameters).toString()
  const loginWithParams = `${PublicRoutesEnum.Login}?${params}`

  const handleClicked = (): void => {
    navigate(loginWithParams)
    if (isEmailExist) {
      signupErrorToLogin()
      return
    }
    signupToLogin()
  }

  return (
    <LoginButtonContainer>
      <Text>I already have an account.</Text>
      <DottedBtn onClick={handleClicked} content={'Login'} />
    </LoginButtonContainer>
  )
}
