import React, { FunctionComponent } from 'react'

import styled from 'styled-components'

import { Badge, BadgeType } from 'src/Components/Badge'
import { colors, device, variables } from 'src/Styled/variables'

interface DetailsTitleProps {
  title: string
  badgeTitle: string
  badgeType: BadgeType
}

export const TitleWrapper = styled.div`
  display: flex;
  max-width: 600px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  @media ${device.mobileMax} {
    display: flex;
    max-width: 600px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
  }
`

export const MainTitle = styled.h1`
  margin: 0;
  padding: 0;
  color: ${colors.mainText};
  font-size: ${variables.fontSize20};
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
`

export const DetailsTitle: FunctionComponent<DetailsTitleProps> = ({
  title,
  badgeTitle,
  badgeType,
}) => (
  <TitleWrapper>
    <MainTitle>{title}</MainTitle>
    <Badge title={badgeTitle} type={badgeType} />
  </TitleWrapper>
)
