import React from 'react'

import { useTranslation } from 'react-i18next'
import SpotifyIcon from 'mdi-react/SpotifyIcon'

import StatsImage from 'src/Assets/Svg/stats.svg?react'
import { APP } from 'src/Configs/App'
import Button from 'src/Components/Buttons/Button'

import { Wrapper, StyledModal, ButtonWrapper } from './styles'

const ConnectToSpotifyModal: React.FunctionComponent<{
  toggle: () => void
  modal: boolean
}> = ({ toggle, modal }) => {
  const { t } = useTranslation()

  const handleSubmit = (): void => {
    localStorage.clear()
    window.location.href = APP.SPOTIFY_AUTH_API
  }

  return (
    <div>
      <StyledModal
        open={modal}
        title={t('connect.connect')}
        footer={null}
        onCancel={toggle}
      >
        <Wrapper>
          <StatsImage height={'200px'} width={'200px'} />
          <div>{t('connect.itSeems')}</div>
          <div>{t('connect.reconnect')}</div>
          <br />
          <ButtonWrapper>
            <Button type='green' onClick={handleSubmit}>
              <SpotifyIcon />
              {t('connect.connectAgain')}
            </Button>
          </ButtonWrapper>
        </Wrapper>
      </StyledModal>
    </div>
  )
}

export default ConnectToSpotifyModal
