import { AppStore } from '.'

export type AppDispatch = AppStore['store']['dispatch']

export enum NameSpace {
  Genres = 'GENRES',
  Languages = 'LANGUAGES',
  CampaignList = 'CAMPAIGN_LIST',
  SpotifyToken = 'SPOTIFY_TOKEN',
  Track = 'TRACK',
  Sidebar = 'SIDEBAR',
  VideoRate = 'VIDEO_RATE',
  Video = 'VIDEO',
  Playlists = 'PLAYLISTS',
  Transactions = 'TRANSACTIONS',
  Artists = 'ARTISTS',
  Reviews = 'REVIEWS',
  Feed = 'FEED',
  Influencer = 'INFLUENCER',
  Wise = 'WISE',
  Campaign = 'CAMPAIGN',
  CampaignVideos = 'CAMPAIGN_VIDEOS',
  SpotifyTracks = 'SPOTIFY_TRACKS',
  ArtistPassword = 'ARTIST_PASSWORD',
  TikTokTrack = 'TIKTOK_TRACK',
  Notifications = 'NOTIFICATIONS',
  CuratorDeleteAccount = 'CURATOR_DELETE_ACCOUNT',
  ArtistBalanceStats = 'ARTIST_BALANCE_STATS',
  ArtistBalanceHistory = 'ARTIST_BALANCE_HISTORY',
  ArtistList = 'ARTIST_LIST',
  CuratorList = 'CURATOR_LIST',
  SearchUsers = 'SEARCH_USERS',
  SessionNotifications = 'SESSION_NOTIFICATIONS',
  GoogleAnalytics = 'GOOGLE_ANALYTICS',
  GA4 = 'GA4',
  AuthType = 'AUTH_TYPE',
  Currency = 'CURRENCY',
  Credentials = 'CREDENTIALS',
  User = 'USER',
  EmailPassword = 'EMAIL_PASSWORD',
  AudienceTiktok = 'AUDIENCE_TIKTOK',
  Cupon = 'CUPON',
  TiktokCampaign = 'TIKTOK_CAMPAIGN',
  Alert = 'ALERT',
  CuratorBalanceHistory = 'CURATOR_BALANCE_HISTORY',
  OrderPayout = 'ORDER_PAYOUT',
  Payment = 'PAYMENT',
  PayPalTransactions = 'PAYPAL_TRANSACTIONS',
  SourceBusterEvent = 'SOURCE_BUSTERS_EVENT',
  ReferAndEarn = 'REFER_AND_EARN',
  Analytics = 'ANALYTICS',
  CANCELED_ALERTS = 'CANCELED_ALERTS',
  CURATOR_TRACKS = 'CURATOR_TRACKS',
  CURATOR_LEVELS = 'CURATOR_LEVELS',
  GET_PREMIUM = 'GET_PREMIUM',
  REFERRAL_HASH = 'REFERRAL_HASH',
  REFERRAL_CODE = 'REFERRAL_CODE',
  SEARCH_PLAYLIST = 'SEARCH_PLAYLIST',
  SIMILAR_GENRES = 'SIMILAR_GENRES',
  SUBSCRIPTION = 'SUBSCRIPTION',
  CREDITS_PAYMENT = 'CREDITS_PAYMENT',
  COUNTRY = 'COUNTRY',
  CRETOR_REGISTER = 'CRETOR_REGISTER',
  RATE = 'RATE',
  TYPEFORM = 'TYPEFORM',
  CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION',
  CHECKOUT_TIMER = 'CHECKOUT_TIMER',
  PageLayout = 'PAGE_LAYOUT',
}

export enum ErrorCode {
  Bed_Request = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  InternalServerError = 500,
  ServiceUnavailable = 503,
  GatewayTimeout = 504,
  Conflict = 409,
}

export enum SUCCESS_CODE {
  OK = 200,
  NO_CONTENT = 204,
}

export interface ApiError {
  message: string
  code: number
}

export enum SliceStatus {
  Idle = 'idle',
  Loading = 'loading',
  Succeeded = 'succeeded',
  Failed = 'failed',
}

export interface SliceState {
  status: SliceStatus
  error: ApiError | null
}

export interface Sound {
  trackAuthorName: string
  trackCoverUrl: string
  trackTitle: string
  trackTiktokUrl: string
}
