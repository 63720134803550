import { FunctionComponent, Suspense } from 'react'

import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Route, Routes } from 'react-router-dom'

import { APP } from 'src/Configs/App'
import { Roles } from 'src/Constants/enums'
import { PageContainer } from 'src/Components/index'
import {
  getIsAuth,
  getUserRole,
} from 'src/Redux/auth-process/credentialsSlice/selectors'
import { getUserEmail } from 'src/Redux/auth-process/userSlice/selectors'
import Layout from 'src/Containers/Layout'
import TiktokWidgetSearch from 'src/Containers/Widgets/TiktokWidgetSearch'
import { SpotifyWidgetSearch } from 'src/Containers/Widgets/SpotifyWidgetSearch'
import { colors } from 'src/Styled/variables'
import { useNewUIExperiment } from 'src/Hooks/Experiments/useNewUIExperiment'

import { PageLayoutContainer } from '../Containers/PageLayoutContainer'

import PublicComponents from './PublicComponents'
import ArtistProtected from './ArtistProtected'
import ArtistComponents from './ArtistComponents'
import CuratorProtected from './CuratorProtected'
import CuratorComponents from './CuratorComponents'
import { InfluencerProtected } from './InfluencerProtected'
import { InfluencerComponents } from './InfluencerComponents'
import { widgets, tiktokSearch, spotifySearch } from './routes'
import { ArtistComponentsNewUI } from './ArtistComponentsNewUI'
import { PublicComponentsNewUI } from './PublicComponentsNewUI'

const protectedPages = {
  [Roles.artist]: {
    UserProtected: ArtistProtected,
    UserComponents: ArtistComponents,
  },
  [Roles.curator]: {
    UserProtected: CuratorProtected,
    UserComponents: CuratorComponents,
  },
  [Roles.influencer]: {
    UserProtected: InfluencerProtected,
    UserComponents: InfluencerComponents,
  },
}

const Container = styled.main`
  background-color: ${colors.mainBackground};
`
const PublicContainer = styled.main``

const Router: FunctionComponent = () => {
  const isAuth = useSelector(getIsAuth)
  const role = useSelector(getUserRole)
  const email = useSelector(getUserEmail)

  const { isNewUI, isNewUIArtist } = useNewUIExperiment()

  if (APP?.admin_email?.includes(email)) {
    localStorage.setItem('admin', 'true')
  }

  if (window.location.pathname.includes(`${widgets}`)) {
    return (
      <PublicContainer>
        <Routes>
          <Route
            element={
              <Suspense>
                <TiktokWidgetSearch />
              </Suspense>
            }
            path={`${widgets}${tiktokSearch}`}
          />
          <Route
            element={
              <Suspense>
                <SpotifyWidgetSearch />
              </Suspense>
            }
            path={`${widgets}${spotifySearch}`}
          />
        </Routes>
      </PublicContainer>
    )
  }

  if (isNewUIArtist) {
    return (
      <PageLayoutContainer>
        <ArtistComponentsNewUI />
      </PageLayoutContainer>
    )
  }

  if (isAuth && role && role !== Roles.admin) {
    const { UserProtected, UserComponents } =
      protectedPages[role as keyof typeof protectedPages] ?? {}
    return (
      <Container>
        <Layout />
        <PageContainer>
          <UserProtected component={UserComponents} email={email} />
        </PageContainer>
      </Container>
    )
  }

  if (isNewUI) {
    return <PublicComponentsNewUI />
  }

  return (
    <PublicContainer>
      <PublicComponents />
    </PublicContainer>
  )
}

export default Router
