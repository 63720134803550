import { FC } from 'react'

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { colors, variables } from 'src/Styled/variables'
import { Genre } from 'src/Types'
import { isEmpty } from 'src/Helpers/layoutHelpers'

export const Status = styled.span<{
  color: string
}>`
  display: inline-block;
  width: 100%;
  color: ${({ color }) => color};
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight400};
  text-align: center;

  @media (max-width: 576px) {
    text-align: left;
  }
`

interface Props {
  status: string
  selectedGenres: Genre[]
  meetConditions: boolean
}

export const PlaylistStatus: FC<Props> = ({
  status,
  selectedGenres,
  meetConditions,
}) => {
  const { t } = useTranslation()
  if (isEmpty(selectedGenres) || !meetConditions) {
    return <Status color={colors.white} />
  }
  if (meetConditions && status === 'activated') {
    return <Status color={colors.green}>{t('playlistsPage.active')}</Status>
  }
  if (meetConditions && status === 'on_review') {
    return <Status color={colors.orange}>{t('playlistsPage.onReview')}</Status>
  }
  return (
    <Status color={colors.messegesRed}>{t('playlistsPage.inactive')}</Status>
  )
}
