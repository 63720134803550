import React, { FC } from 'react'

import { useTranslation } from 'react-i18next'

import { CampaignStatusEnum } from 'src/Types'
import { StatsBlock } from 'src/Components/StatsSectionBlock'

interface BudgetSectionProps {
  campaignStatus: CampaignStatusEnum
  budget?: number
  spent?: string
  reserved?: number
}

export const BudgetSection: FC<BudgetSectionProps> = ({
  campaignStatus,
  budget,
  spent,
  reserved,
}) => {
  const { t } = useTranslation()

  const budgetSectionVisible = ![
    CampaignStatusEnum.AWAITING_REVIEW,
    CampaignStatusEnum.PENDING,
  ].includes(campaignStatus)

  if (!budgetSectionVisible) {
    return null
  }

  const sections = [
    {
      title: t('campaignDetailsPage.budget'),
      value: `$${budget?.toFixed(2)}`,
    },
    {
      title: t('campaignDetailsPage.reserved'),
      value: `$${reserved?.toFixed(2)}`,
    },
    {
      title: t('campaignDetailsPage.spent'),
      value: `$${spent}`,
    },
  ]

  return <StatsBlock sections={sections} />
}
