import { useCallback } from 'react'

import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { updateTikTokCampaign } from 'src/Redux/schedule-process/tiktokCampaign/api-actions'
import { getScheduledTikTokCampaign } from 'src/Redux/schedule-process/tiktokCampaign/selectors'

import { mapScheduledSpotifyCampaignForUpdate } from './mapScheduledSpotifyCampaignForUpdate'

export const useChangeStartDate = (): {
  handleChangeDate: (date: string | Date) => void
} => {
  const dispatch = useAppDispatch()

  const scheduledTikTokCampaign = useAppSelector(getScheduledTikTokCampaign)

  const handleChangeDate = useCallback(
    (date: string | Date): void => {
      if (scheduledTikTokCampaign?.id) {
        const mappedCampaign = mapScheduledSpotifyCampaignForUpdate({
          ...scheduledTikTokCampaign,
          startDate: date,
        })

        void dispatch(updateTikTokCampaign(mappedCampaign))
      }
    },
    [dispatch, scheduledTikTokCampaign],
  )

  return {
    handleChangeDate,
  }
}
