import { useEffect, useRef, useState } from 'react'

import io, { Socket } from 'socket.io-client'

import { APP } from 'src/Configs/App'
import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { getCuratorRank } from 'src/Redux/auth-process/userSlice/api-actions'
import { getCuratorSpotifyId } from 'src/Redux/auth-process/userSlice/selectors'
import { updateStatePlaylists } from 'src/Redux/playlists-process'
import { getSpotifyToken } from 'src/Redux/spotifyToken-process/selectors'
import { ErrorCode } from 'src/Redux/types'
import { Playlist } from 'src/Types'

interface ReturnPlaylistsSocket {
  loading: boolean
  toggle: () => void
  isModalSpotify: boolean
  checkAccessToken: () => void
}

interface SocketResponse {
  Playlists?: Array<Playlist>
  statusCode?: number
  error?: {
    error?: {
      error_description?: string
    }
  }
}
export const usePlaylistsSocket = (): ReturnPlaylistsSocket => {
  const [loading, setLoading] = useState(false)
  const [isModalSpotify, setModalSpotify] = useState(false)
  const dispatch = useAppDispatch()
  const curatorSpotifyId = useAppSelector(getCuratorSpotifyId)
  const spotifyAccessToken = useAppSelector(getSpotifyToken)

  const socketRef = useRef<Socket | null>(null)

  useEffect(() => {
    if (!socketRef.current && APP.SOCKET_URL) {
      socketRef.current = io(APP.SOCKET_URL, {
        path: '/my-custom-path/',
      })
    }
    socketRef.current?.on('newPlaylists', (data: SocketResponse) => {
      if (data) {
        const { Playlists } = data
        if (Playlists && Playlists?.length) {
          dispatch(updateStatePlaylists({ Playlists }))
          void dispatch(getCuratorRank())
        }
        if (
          data.statusCode === ErrorCode.Bed_Request &&
          data?.error?.error?.error_description === 'Invalid refresh token'
        ) {
          setModalSpotify(true)
        }

        setLoading(false)
      }
    })

    return () => {
      socketRef.current?.disconnect()
    }
  }, [dispatch])

  const checkAccessToken = (): void => {
    if (spotifyAccessToken && curatorSpotifyId) {
      setLoading(true)
      socketRef.current?.emit('playlists', { spotify_id: curatorSpotifyId })
    }
  }
  const toggle = (): void => setModalSpotify(!isModalSpotify)

  return { loading, checkAccessToken, isModalSpotify, toggle }
}
