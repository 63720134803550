import React, { FC } from 'react'

import { useTranslation } from 'react-i18next'

import { CampaignPlatformTypeEnum } from 'src/Types'
import { Desktop } from 'src/Components/Responsive'
import IconProtection from 'src/Assets/Svg/protection.svg?react'

import { AlertProtectionWrapper, AlertProtectionWrapperText } from './styles'

interface ProtectionAlertProps {
  platformType: CampaignPlatformTypeEnum
}

export const ProtectionAlert: FC<ProtectionAlertProps> = ({ platformType }) => {
  const { t } = useTranslation()

  const isSpotify = platformType === CampaignPlatformTypeEnum.SPOTIFY
  const protectionText = isSpotify
    ? t('scheduleCampaignPage.artistProtectionSpotify')
    : t('scheduleCampaignPage.artistProtectionTikTok')

  return (
    <Desktop>
      <AlertProtectionWrapper>
        <IconProtection width={21} height={24} />
        <AlertProtectionWrapperText>
          <span>{t('scheduleCampaignPage.protectionTitle')}</span>
          <span>{protectionText}</span>
        </AlertProtectionWrapperText>
      </AlertProtectionWrapper>
    </Desktop>
  )
}
