import React, { FunctionComponent } from 'react'

import { useMediaQuery } from 'react-responsive'
import { Typography } from 'antd'
import styled from 'styled-components'

import { capitalizeFirstLetter } from 'src/Helpers/layoutHelpers'
import { Genre } from 'src/Types/index'
import { variables, colors } from 'src/Styled/variables'

export interface CampaignGenresProps {
  genres?: Genre[]
}

const { Paragraph } = Typography

export const GenresRow = styled(Paragraph)`
  font-size: ${variables.fontSize10};
  color: ${colors.mainText};

  &:first-child {
    margin-bottom: 4px;
  }
`

const DESKTOP_MAX_ROWS = 3
const MOBILE_MAX_ROWS = 5

const CampaignGenresComponent: FunctionComponent<CampaignGenresProps> = ({
  genres,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 576 })
  const rows = isMobile ? MOBILE_MAX_ROWS : DESKTOP_MAX_ROWS
  return (
    <GenresRow ellipsis={{ rows }}>
      {genres?.map(
        (el, id) =>
          `${capitalizeFirstLetter(el?.Genre?.genre_name || '')}${
            id === genres.length - 1 ? '' : ', '
          }`,
      )}
    </GenresRow>
  )
}

export default CampaignGenresComponent
