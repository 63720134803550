import { useEffect } from 'react'

import { ScheduleSummaryProps } from 'src/Components/ScheduleSummary'
import { HUNDRED, HUNDREDTHS } from 'src/Constants/numeric'
import { couponAppliedEvent } from 'src/Helpers/TagManager'
import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { useBonusTooltipTimer } from 'src/Hooks/useBonusTooltip'
import { useCheckoutButton } from 'src/Hooks/useCheckoutButton'
import { usePaymentAmountsWithCurrency } from 'src/Hooks/usePaymentAnountsWithCurrency'
import { getFormattedCheckoutTimer } from 'src/Redux/checkoutCountdown-process'
import {
  getIsEnoughBalanceForTiktok,
  getTiktokPrice,
} from 'src/Redux/schedule-process/audienceTiktokSlice/selectors'
import { checkCoupon } from 'src/Redux/schedule-process/couponSlice/api-actions'
import { getCoupon } from 'src/Redux/schedule-process/couponSlice/selectors'
import { getScheduledTikTokCampaign } from 'src/Redux/schedule-process/tiktokCampaign/selectors'
import { CampaignPlatformTypeEnum, CampaignTypeEnum } from 'src/Types'

export const useTikTokScheduleSummary = (): ScheduleSummaryProps => {
  const dispatch = useAppDispatch()
  const coupon = useAppSelector(getCoupon)
  const { startNextTimeBonusCountdown, isNextTimeBonus } =
    useBonusTooltipTimer()

  const {
    currencyDiscount,
    campaignCurrencyBudget,
    currencyVat,
    currencyBalance,
    currencyTotal,
    isVATCountry,
  } = usePaymentAmountsWithCurrency(CampaignPlatformTypeEnum.TIKTOK)
  const showPromocode = useAppSelector(getIsEnoughBalanceForTiktok)
  const countdown = useAppSelector(getFormattedCheckoutTimer)
  const scheduledTikTokCampaign = useAppSelector(getScheduledTikTokCampaign)
  const tikTokCampaignPrice = useAppSelector(getTiktokPrice)
  const { isLoading, onClickContinue } = useCheckoutButton(
    CampaignPlatformTypeEnum.TIKTOK,
  )

  useEffect(() => {
    startNextTimeBonusCountdown()
  }, [startNextTimeBonusCountdown])

  const onApplyPromocode = async (value: string): Promise<void> => {
    const data = await dispatch(checkCoupon(value)).unwrap()

    if (data) {
      const memoDiscount = (
        tikTokCampaignPrice *
        (data.coupon.discount / HUNDRED)
      ).toFixed(HUNDREDTHS)

      couponAppliedEvent(
        scheduledTikTokCampaign.type || CampaignTypeEnum.BASIC,
        data.coupon,
        memoDiscount ? Number(memoDiscount) : 0,
      )
    }
  }

  const artistName =
    scheduledTikTokCampaign.track?.authorName ??
    scheduledTikTokCampaign.trackAuthorName
  const trackName =
    scheduledTikTokCampaign.track?.title ?? scheduledTikTokCampaign.trackTitle
  return {
    onApplyPromocode,
    countdown,
    paymentLoading: isLoading,
    onConfirm: onClickContinue,
    price: campaignCurrencyBudget,
    discount: currencyDiscount,
    discountPercentage: coupon?.discount ? String(coupon.discount) : '',
    vat: isVATCountry ? currencyVat : '',
    balance: currencyBalance,
    total: currencyTotal,
    showPromocode: !showPromocode && !coupon?.discount,
    showTooltip: isNextTimeBonus,
    artistName: artistName ?? '',
    trackName: trackName ?? '',
    platform: CampaignPlatformTypeEnum.TIKTOK,
  }
}
