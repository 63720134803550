import styled from 'styled-components'

import { variables, colors, device } from 'src/Styled/variables'
import Button from 'src/Components/Buttons/Button'
import { SmartImage } from 'src/Components/SmartImage'

export const SoundsListItemContainer = styled.div`
  display: flex;
  padding: 24px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 4px;
  background-color: ${colors.greyBg};

  @media ${device.mobileMax} {
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
  }
`

export const SoundInfoContainer = styled.div`
  display: flex;
  max-width: 240px;
  padding-right: 16px;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
`

export const TrackNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
`

export const ValuesContainer = styled.div`
  display: flex;
  padding-right: 16px;
  flex-direction: column;
  align-items: flex-start;
`

export const TrackCoverContainer = styled(SmartImage)`
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
`

export const StyledButton = styled(Button)`
  width: 108px;

  @media ${device.mobileMax} {
    width: 182px;
  }
`

export const NormalText = styled.span`
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight400};
  line-height: 150%;
  color: ${colors.mainText};
`

export const SmallText = styled.span`
  font-size: ${variables.fontSize10};
  font-weight: ${variables.fontWeight400};
  line-height: 150%;
  color: ${colors.mainText};
`
export const MobileBlockWrapper = styled.span`
  @media ${device.mobileMax} {
    padding-left: 48px;
  }
`
