import { FC } from 'react'

import styled from 'styled-components'

import { renderLanguageOrGenreName } from 'src/Components/GenresList/helpers'
import { GroupedOption } from 'src/Types'
import { SelectedLanguage } from 'src/Containers/ScheduleSpotifyWrapper/components/ModalsContainer/LanguagesModal'
import { PrimaryGenreOrLanguageItem } from 'src/Components/GenresList/PrimaryGenreOrLanguageItem'

const LanguageListContainer = styled.ul`
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`
export const LanguageListLabel = styled.div`
  color: ${({ theme }) => theme.common.primaryText};
  margin-bottom: 16px;
  margin-top: 16px;
`

interface Props {
  item: GroupedOption
  searchValue: string
  onClick: (item: SelectedLanguage) => void
}

export const LanguagesModalSpotifyGroupedItem: FC<Props> = ({
  item,
  onClick,
  searchValue,
}) => {
  const { label, options } = item
  if (!options.length) {
    return null
  }
  return (
    <>
      <LanguageListLabel>{label}</LanguageListLabel>
      <LanguageListContainer>
        {options.map((language) => (
          <PrimaryGenreOrLanguageItem
            key={language.value}
            name={renderLanguageOrGenreName(language.label, searchValue)}
            onClick={() => onClick(language)}
          />
        ))}
      </LanguageListContainer>
    </>
  )
}
