import styled from 'styled-components'

import { SmartImage } from 'src/Components/SmartImage'
import { colors, device, variables } from 'src/Styled/variables'

export const Container = styled.div`
  background-color: ${colors.white};
  padding: 20px 25.5px 24px;
  margin-bottom: 16px;
  border-radius: 4px;
  display: grid;
  grid-row-gap: 20px;
  @media ${device.mobileMin} {
    padding: 20px 24px;
    margin-bottom: 8px;
  }
`
export const Platform = styled.div`
  display: grid;
  grid-template-columns: 24px 1fr;
  grid-gap: 8px;
  align-items: center;
  text-align: left;
  font-weight: ${variables.fontWeight400};
  font-size: ${variables.fontSize18};
  line-height: 150%;
  color: ${colors.mainText};
  &img {
    align-self: center;
  }
`
export const TrackBlock = styled.div`
  min-height: 48px;
  display: flex;
  flex-direction: column;

  @media ${device.mobileMin} {
    justify-content: space-between;
    flex-direction: row;
    gap: 16px;
    align-items: center;
  }
`

export const TrackBlockLeft = styled.div`
  display: grid;
  grid-template-columns: 64px 1fr;
  grid-column-gap: 16px;
  & img {
    align-self: center;
  }
  @media ${device.mobileMin} {
    justify-items: center;
  }
`

export const CenteredWrapper = styled.div`
  min-width: 82px;
  margin: 0 auto;
  margin-top: 25.5px;
  @media ${device.mobileMin} {
    margin: inherit;
    justify-items: center;
  }
`

export const TrackImg = styled(SmartImage)`
  width: 64px;
  height: 64px;
  border-radius: 4px;
`

export const TrackText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  text-align: left;
  line-height: 21px;
  font-size: ${variables.fontSize14};
`

export const TrackDescription = styled.div`
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight400};
  line-height: 21px;
  color: ${colors.mainText};
`
export const TrackTitle = styled(TrackDescription)`
  font-weight: ${variables.fontWeight600};
  @media ${device.mobileMin} {
    margin-bottom: 4px;
  }
`
export const TrackDuration = styled(TrackDescription)`
  color: ${colors.greyTextWhiteBg};
`
