import { useCallback, useEffect, useState } from 'react'

import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import {
  editPlaylistsLanguages,
  PlaylistLanguagesBody,
} from 'src/Redux/playlists-process/api-actions'
import { getPlaylistsWithNoPref } from 'src/Redux/playlists-process/selectors'
import {
  ActionsForSelectGenresAndLanguagesEnum,
  SelectedLanguage,
} from 'src/Types/index'

interface Option {
  action: ActionsForSelectGenresAndLanguagesEnum
  option: SelectedLanguage
}

interface UseSetLanguages {
  selectedInputLanguages: Array<Array<SelectedLanguage>>
  inputValueLanguages: Array<string>
  onInputChangeLanguages: (characterEntered: string, id: number) => void
  onChangeSelectLanguages: (
    selectedOptions: Array<SelectedLanguage>,
    option: Option,
    id: number,
  ) => void
  onSaveLanguages: () => Promise<void>
}

export const useSetLanguages = (): UseSetLanguages => {
  const dispatch = useAppDispatch()
  const playlistsWithNoPref = useAppSelector(getPlaylistsWithNoPref)
  const [selectedInputLanguages, setSelectedLanguages] = useState<
    Array<Array<SelectedLanguage>>
  >([])
  const [inputValueLanguages, setInputValueLanguages] = useState<Array<string>>(
    [],
  )

  useEffect(() => {
    if (playlistsWithNoPref.length) {
      const emptyState: [a: Array<SelectedLanguage[]>, b: Array<string>] = [
        [],
        [],
      ]
      for (const _ of playlistsWithNoPref) {
        emptyState[0].push([])
        emptyState[1].push('')
      }
      setSelectedLanguages(emptyState[0])
      setInputValueLanguages(emptyState[1])
    }
  }, [playlistsWithNoPref])

  const onInputChangeLanguages = (
    characterEntered: string,
    id: number,
  ): void => {
    setInputValueLanguages(
      inputValueLanguages.map((el, index) =>
        index === id ? characterEntered : el,
      ),
    )
  }

  const onChangeSelectLanguages = (
    selectedOptions: Array<SelectedLanguage>,
    { action, option }: Option,
    id: number,
  ): void => {
    if (action === ActionsForSelectGenresAndLanguagesEnum.SELECT_OPTION) {
      if (option.value === 1) {
        return setSelectedLanguages(
          selectedInputLanguages.map((el, index) =>
            index === id ? [option] : el,
          ),
        )
      }

      const selectedOptionsWithoutNoVocal = selectedOptions.filter(
        (lang) => lang.value !== 1,
      )
      setSelectedLanguages(
        selectedInputLanguages.map((el, index) =>
          index === id ? selectedOptionsWithoutNoVocal : el,
        ),
      )
    } else {
      setSelectedLanguages(
        selectedInputLanguages.map((el, index) =>
          index === id ? selectedOptions : el,
        ),
      )
    }
  }

  const onSaveLanguages = useCallback(async () => {
    const result = selectedInputLanguages.map((el, id) => {
      if (el.length) {
        const languageIds = el.map((languageId) => languageId.value)
        return {
          language_ids: languageIds,
          id: playlistsWithNoPref[id].id,
          genre_ids: playlistsWithNoPref[id].PlaylistGenres.map(
            (genre) => genre.genre_id,
          ),
          moods_ids: playlistsWithNoPref[id].PlaylistMoods.map(
            (mood) => mood.mood_id,
          ),
        }
      }
      return null
    })

    const data = result.filter((el) => el !== null)

    await dispatch(editPlaylistsLanguages(data as PlaylistLanguagesBody[]))
  }, [selectedInputLanguages, dispatch, playlistsWithNoPref])

  return {
    selectedInputLanguages,
    inputValueLanguages,
    onInputChangeLanguages,
    onChangeSelectLanguages,
    onSaveLanguages,
  }
}
