import React, { FC } from 'react'

import styled from 'styled-components'

export interface PrimaryDottedButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {}

const Text = styled.div`
  text-align: center;
  font-family: 'Segoe UI';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  border-bottom: 1px dashed ${({ theme }) => theme.common.primaryText};
`

const StyledDottedButton = styled.button`
  display: inline-flex;
  height: 48px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  color: ${({ theme }) => theme.common.primaryText};
  background: transparent;
  border: none;
  cursor: pointer;
  &:hover,
  &:focus-visible {
    outline: none;
    ${Text} {
      border-bottom-color: transparent;
    }
  }
  &:active {
    ${Text} {
      border-bottom-style: solid;
    }
  }
  &:disabled {
    cursor: not-allowed;
    ${Text} {
      border-bottom-style: dashed;
      color: ${({ theme }) => theme.common.thirdText};
      border-bottom-color: ${({ theme }) => theme.common.thirdText};
    }
  }
`

export const PrimaryDottedButton: FC<PrimaryDottedButtonProps> = ({
  children,
  ...restProps
}) => (
  <StyledDottedButton {...restProps}>
    <Text>{children}</Text>
  </StyledDottedButton>
)
