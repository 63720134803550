import common_fr from './fr/common.json'
import common_en from './en/common.json'
import common_de from './de/common.json'

export default {
  en: {
    translation: common_en,
  },
  fr: {
    translation: common_fr,
  },
  de: {
    translation: common_de,
  },
}
