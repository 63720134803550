import React, { FC } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { getTransactionsTotalCount } from 'src/Redux/transactions-process/selectors'
import InfinityScrollWrapper from 'src/Components/InfinityScrollWrapper'
import { useAppSelector } from 'src/Hooks/redux'
import {
  getCuratorTransactions,
  getIsLoadingCuratorTransactions,
} from 'src/Redux/transactions-process/curatorBalanceHistorySlice/selectors'

import { MobileBalanceItem } from '../MobileBalanceItem'
import { EmptyBalance } from '../styles'

interface MobileBalanceListProps {
  onNextPageScroll: () => void
}

const BalanceHistoryList = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  list-style: none;
  padding: 0 0 60px 0;
`

export const MobileBalanceList: FC<MobileBalanceListProps> = ({
  onNextPageScroll,
}) => {
  const isBalanceListLoading = useAppSelector(getIsLoadingCuratorTransactions)
  const { t } = useTranslation()
  const transactions = useAppSelector(getCuratorTransactions)
  const totalTransactions = useAppSelector(getTransactionsTotalCount)

  if (transactions.length === 0) {
    return <EmptyBalance>{t('balancePage.youHaveNoRewardsYet')}</EmptyBalance>
  }
  return (
    <InfinityScrollWrapper
      dataLength={transactions.length}
      isLoading={isBalanceListLoading}
      next={onNextPageScroll}
      hasMore={totalTransactions > transactions.length}
    >
      <BalanceHistoryList>
        {transactions.map((transaction) => (
          <MobileBalanceItem key={transaction.id} transaction={transaction} />
        ))}
      </BalanceHistoryList>
    </InfinityScrollWrapper>
  )
}
