import styled from 'styled-components'

import { colors, device, variables } from 'src/Styled/variables'

export const CustomModal = styled.div`
  z-index: 300;
  padding-top: 60px;
  position: fixed;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const SuccessBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 552px;
  @media ${device.mobileMax} {
    width: 288px;
    text-align: center;
  }
`

export const Text = styled.div`
  font-weight: ${variables.fontWeight400};
  color: ${colors.mainText};
  font-size: ${variables.fontSize14};
  line-height: 150%;
  text-align: center;
`

export const TitleWrapper = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  font-weight: ${variables.fontWeight600};
  font-size: ${variables.fontSize18};
  line-height: 150%;
  margin-bottom: 12px;
`
