import React, { FC, useMemo } from 'react'

import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Breadcrumb, BreadCrumbItem } from 'src/Components/Breadcrumb'
import { PublicRoutesEnum } from 'src/Router/PublicComponentsNewUI'
import { CampaignPlatformTypeEnum } from 'src/Types'

interface CreateCampaignBreadcrumbsNoAuthProps {
  platform?: CampaignPlatformTypeEnum
}

const NAVIGATE_BACK_INDEX = -1

export const CreateCampaignBreadcrumbsNoAuth: FC<
  CreateCampaignBreadcrumbsNoAuthProps
> = ({ platform }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const breadcrumbItems: BreadCrumbItem[] = useMemo(
    () => [
      {
        title: t('createNewCampaignPage.choosingCampaign'),
        path: PublicRoutesEnum.CreateCampaign,
      },
      {
        title: t('createNewCampaignPage.trackSearch'),
        path:
          platform === CampaignPlatformTypeEnum.SPOTIFY
            ? PublicRoutesEnum.CreateSpotifyCampaign
            : PublicRoutesEnum.CreateTikTokCampaign,
      },
      {
        title: t('createNewCampaignPage.contactDetails'),
        path: '',
      },
    ],
    [platform, t],
  )

  const onBreadcrumbButtonClick = (): void => {
    navigate(NAVIGATE_BACK_INDEX)
  }

  const filteredBreadcrumbItems: BreadCrumbItem[] = breadcrumbItems.reduce<{
    items: BreadCrumbItem[]
    found: boolean
  }>(
    (acc, item) => {
      if (acc.found) {
        return acc
      }
      acc.items.push(item)
      if (item.path === pathname) {
        acc.found = true
      }
      return acc
    },
    { items: [], found: false },
  ).items

  return (
    <Breadcrumb
      items={filteredBreadcrumbItems}
      onButtonClick={onBreadcrumbButtonClick}
    />
  )
}
