import {
  artistAcceptedVideo,
  artistRejectedVideo,
  artistReviewedVideo,
} from 'src/Helpers/TagManager'
import { handleTakeVideoId } from 'src/Helpers/handleTakeVideoId'
import { useAppSelector } from 'src/Hooks/redux'
import { getUserId } from 'src/Redux/auth-process/userSlice/selectors'
import { SetCampaignVideoRateResult } from 'src/Redux/campaignVideoApi'

interface IParams {
  data: SetCampaignVideoRateResult
  videoID: number
  feedback: string
  videoLink?: string
}
const MIN_RATE = 3
export const useHandleRateEvents = (): [(params: IParams) => void] => {
  const userID = useAppSelector(getUserId)

  const handleRateVideoEvents = (params: IParams): void => {
    const { data, videoID, feedback, videoLink } = params
    const { rateVideo, trackId, campaignId, paidAmount } = data
    const videoId = handleTakeVideoId(videoLink ?? '')
    if (userID) {
      artistReviewedVideo({
        userID,
        campaignId,
        trackId,
        videoId,
        reviewId: videoID,
        rate: rateVideo,
        amountPaid: paidAmount,
      })
    }
    if (userID && rateVideo >= MIN_RATE) {
      artistAcceptedVideo({
        userID,
        videoId,
        trackId,
        campaignId,
        reviewId: videoID,
        rate: rateVideo,
        amountPaid: paidAmount,
      })
    } else {
      userID &&
        artistRejectedVideo({
          userID,
          campaignId,
          trackId,
          videoId,
          rate: rateVideo,
          rejectionReason: feedback,
          reviewId: videoID,
          amountPaid: paidAmount,
        })
    }
  }
  return [handleRateVideoEvents]
}
