import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { useSelector } from 'react-redux'

import { addSpaceToNumber, formatNumberWithK } from 'src/Helpers/layoutHelpers'
import { getLevels } from 'src/Redux/levels-procces/selectors'

import PlaylistsBlock from '../PlaylistsBlock'
import { PlaylistIconData } from '../../types'

import {
  ContainerDesc,
  ContainerMob,
  ContainerMobHeader,
  LevelMob,
  Value,
  ValueItemMob,
  ValueMob,
  ValuesListMob,
} from './styles'

export interface Level {
  position: number
  score: number
  reward: number
  playlists: Array<PlaylistIconData>
}

const TableListItem: FC<{ level: Level }> = ({ level }) => {
  const isMobile = useMediaQuery({ maxWidth: 576 })
  const levels = useSelector(getLevels)

  const { t } = useTranslation()

  const { position, score, reward, playlists } = level

  const isLastLevel = position === levels.length
  const nextLevelScore = isLastLevel ? score : levels[position].score
  const formattedPosition = `${t('levelsPage.level')} ${position}`
  const formattedScoreDesc = addSpaceToNumber(score, ',')
  const formattedScoreMob = formatNumberWithK(score)
  const formattedNextScoreMob = formatNumberWithK(nextLevelScore)
  const formattedPeriodScoreMob = isLastLevel
    ? `${formattedScoreMob}+`
    : `${formattedScoreMob} - ${formattedNextScoreMob}`
  const formattedReward = `$${reward}`

  if (isMobile) {
    return (
      <ContainerMob>
        <ContainerMobHeader>
          <LevelMob>{formattedPosition}</LevelMob>
          <ValueMob>
            {t('levelsPage.score')} {formattedPeriodScoreMob}
          </ValueMob>
        </ContainerMobHeader>

        <ValuesListMob>
          <ValueItemMob>
            <ValueMob>{t('levelsPage.rewardPerReview')}:</ValueMob>
            <ValueMob>{formattedReward}</ValueMob>
          </ValueItemMob>
        </ValuesListMob>

        <PlaylistsBlock playlists={playlists} />
      </ContainerMob>
    )
  }

  return (
    <ContainerDesc>
      <Value>{formattedPosition}</Value>
      <Value>{formattedScoreDesc}</Value>
      <Value>{formattedReward}</Value>
      <PlaylistsBlock playlists={playlists} />
    </ContainerDesc>
  )
}

export default TableListItem
