import styled from 'styled-components'

import { colors, device, variables } from 'src/Styled/variables'

export const PageTitle = styled.h3`
  margin-bottom: 26px;
  color: ${colors.mainText};
  font-weight: ${variables.fontWeight600};
  font-size: ${variables.fontSize20};
  line-height: 30px;
  @media (min-width: 576px) {
    margin-bottom: 24px;
  }
`
export const PlaylistContainer = styled.div`
  box-sizing: content-box;
  max-width: 1120px;
  padding: 0;
  margin: 0 auto;
  @media ${device.mobileMin} {
    padding: 0 24px 0 30px;
  }
`

export const Container = styled.div`
  box-sizing: border-box;
  width: 100%;

  @media screen and (max-width: 576px) {
    padding-left: 0;
    padding-right: 0;
  }
`
