import { createDraftSafeSelector } from '@reduxjs/toolkit'

import { RootState } from 'src/Redux'
import { SliceStatus } from 'src/Redux/types'

import { CouponState } from '.'

const getState = (state: RootState): CouponState => state.scheduling.coupon

export const getCoupon = createDraftSafeSelector(
  getState,
  ({ coupon }) => coupon,
)

export const getIsCouponLoading = createDraftSafeSelector(
  getState,
  ({ status }) => status === SliceStatus.Loading,
)
export const couponCheckStatusIsSucceededSelector = createDraftSafeSelector(
  getState,
  ({ status }) => status === SliceStatus.Succeeded,
)
