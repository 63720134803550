import { FC, ReactNode } from 'react'

import { useTranslation } from 'react-i18next'

import { Desktop, Mobile } from 'src/Components/Responsive'
import { ArtistFeedback, ID, MatchedPlaylist, Playlist } from 'src/Types'
import ShareIcon from 'src/Assets/Svg/share.svg?react'

import { renderGenresLanguages } from '../renderGenresLanguages'
import { MatchedPlaylists } from '../MatchedPlaylists/MatchedPlaylists'
import { ReviewFeedback } from '../ReviewFeedback/ReviewFeedback'
import {
  ReviewMainContainer,
  ReviewMainSectionLabel,
  ReviewMainSectionText,
  ShareButton,
  ShareIconWrapper,
  MatchesAndResultContainer,
  MatchesAndResultCol,
  MatchesAndResultRow,
  ReviewSubSectionLabel,
  ReviewSubSectionText,
  HiddenPlaylistText,
  OpenOnSpotify,
  OpenOnSpotifyLink,
  ToggleMatches,
  ResponseContainer,
} from '../styles'

export interface CuratorReviewProps {
  playlist?: Playlist
  campaignID: number
  isSFAConnected: boolean
  onToggleShareModal: () => void
  onToggleMatches: () => void
  isMatchedOpened: boolean
  matched: MatchedPlaylist[] | undefined
  comment: string
  recommendations?: string
  reviewId: ID
  reviewFeedback?: ArtistFeedback
  curatorRating: number
}

export const CuratorReview: FC<CuratorReviewProps> = (props) => {
  const {
    onToggleMatches,
    onToggleShareModal,
    isMatchedOpened,
    playlist,
    matched,
    comment,
    recommendations,
    campaignID,
    reviewId,
    reviewFeedback,
    curatorRating,
  } = props
  const { t } = useTranslation()

  const renderShareButton = (): ReactNode => (
    <ShareButton onClick={onToggleShareModal}>
      <ShareIconWrapper>
        <ShareIcon />
      </ShareIconWrapper>
      Share
    </ShareButton>
  )
  return (
    <ReviewMainContainer>
      <ReviewMainSectionLabel>
        {t('campaignResultsPage.review')}
      </ReviewMainSectionLabel>
      <ReviewMainSectionText>
        {comment}
        <Desktop>{renderShareButton()}</Desktop>
      </ReviewMainSectionText>
      {recommendations && (
        <>
          <ReviewMainSectionLabel>
            {t('campaignResultsPage.recommendation')}
          </ReviewMainSectionLabel>
          <ReviewMainSectionText>{recommendations}</ReviewMainSectionText>
        </>
      )}
      <Mobile>{renderShareButton()}</Mobile>

      {playlist?.playlist_url && (
        <MatchesAndResultContainer>
          <MatchesAndResultCol>
            <ReviewMainSectionLabel>
              {t('campaignResultsPage.trackMatches')}
            </ReviewMainSectionLabel>
            <MatchesAndResultRow>
              <ReviewSubSectionLabel short>
                {`${t('campaignResultsPage.playlist')}:`}
              </ReviewSubSectionLabel>
              <ReviewSubSectionText>
                {playlist?.is_hidden ? (
                  <HiddenPlaylistText>
                    {`${t('campaignResultsPage.hiddenByCurator')}`}
                  </HiddenPlaylistText>
                ) : (
                  playlist?.playlist_name
                )}
              </ReviewSubSectionText>
            </MatchesAndResultRow>
            <MatchesAndResultRow>
              <ReviewSubSectionLabel short>
                {`${t('campaignResultsPage.genres')}:`}
              </ReviewSubSectionLabel>
              <ReviewSubSectionText>
                {renderGenresLanguages(playlist.genres)}
              </ReviewSubSectionText>
            </MatchesAndResultRow>
            <MatchesAndResultRow>
              <ReviewSubSectionLabel short>
                {`${t('campaignResultsPage.vocal')}:`}
              </ReviewSubSectionLabel>
              <ReviewSubSectionText>
                {renderGenresLanguages(playlist.languages)}
              </ReviewSubSectionText>
            </MatchesAndResultRow>
            <Desktop>
              <OpenOnSpotify>
                {t('campaignResultsPage.openPlaylistOn')}
                <OpenOnSpotifyLink target='_blank' href={playlist.playlist_url}>
                  {t('campaignResultsPage.spotify')}
                </OpenOnSpotifyLink>
              </OpenOnSpotify>
            </Desktop>
          </MatchesAndResultCol>

          {/* Temporary committed due to SFA not working */}

          {/* <MatchesAndResult
            isSFAConnected={isSFAConnected}
            playlist={playlist}
          /> */}
        </MatchesAndResultContainer>
      )}
      {matched && isMatchedOpened && <MatchedPlaylists matched={matched} />}
      {matched && (
        <ToggleMatches onClick={onToggleMatches}>
          {isMatchedOpened
            ? t('campaignResultsPage.hideMatches')
            : t('campaignResultsPage.showMatches')}
        </ToggleMatches>
      )}
      <ResponseContainer>
        <ReviewFeedback
          reviewFeedback={reviewFeedback}
          campaignID={campaignID}
          reviewId={reviewId}
          curatorRating={curatorRating}
        />
      </ResponseContainer>
    </ReviewMainContainer>
  )
}
