import { FunctionComponent, ReactElement } from 'react'

import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { APP } from 'src/Configs/App'
import Button from 'src/Components/Buttons/Button'
import { campaignsList, createCampaign } from 'src/Router/routes'
import { getIsAuth } from 'src/Redux/auth-process/credentialsSlice/selectors'
import { getSpotifyToken } from 'src/Redux/spotifyToken-process/selectors'

import { ButtonWrapper, Card, Text } from './styles'

export const CampaignEmptyPage: FunctionComponent = (): ReactElement => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const spotifyAccessToken = useSelector(getSpotifyToken)
  const auth = useSelector(getIsAuth)

  const checkAccessToken = (): void => {
    if (auth || spotifyAccessToken) {
      navigate(`${campaignsList}${createCampaign}`)
    } else {
      window.location.href = APP.SPOTIFY_AUTH_API
    }
  }

  return (
    <Card>
      <ButtonWrapper>
        <Text>{t('campaignsPage.getStarted')}</Text>
        <Button type='white' onClick={checkAccessToken}>
          +&nbsp;{t('campaignsPage.createNewCampaign')}
        </Button>
      </ButtonWrapper>
    </Card>
  )
}
