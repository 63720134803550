import { createDraftSafeSelector } from '@reduxjs/toolkit'

import { RootState } from 'src/Redux'
import { SliceStatus } from 'src/Redux/types'

import { SearchUsersState } from '.'

const getState = (state: RootState): SearchUsersState =>
  state.adminPanel.searchUsers

export const getFoundUsers = createDraftSafeSelector(
  getState,
  ({ foundUsers }) => foundUsers,
)

export const getIsSearchUsersLoading = createDraftSafeSelector(
  getState,
  ({ status }) => status === SliceStatus.Loading,
)
