import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'

import { APP } from 'src/Configs/App'
import {
  getDefaultStartDate,
  getIsMoreThenDaysLeft,
} from 'src/Constants/functions'
import { RootState } from 'src/Redux'
import { getHasActiveOrCanceledSubscriptionSelector } from 'src/Redux/subscription-process/subscription/selectors'
import { ErrorCode, NameSpace } from 'src/Redux/types'
import { api } from 'src/Services/api'
import { TikTokUpdateCampaign, TiktokCampaign } from 'src/Types'

export const updateTikTokCampaign = createAsyncThunk<
  TiktokCampaign,
  TikTokUpdateCampaign
>(
  `${NameSpace.TiktokCampaign}/updateTikTokCampaign`,
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await api.put<TiktokCampaign>(
        `${APP.TIKTOK_SERVER}/artist/tiktok/campaign/${payload.id}`,
        payload,
      )
      return data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      return rejectWithValue({
        message: response?.data.message,
        code: response?.status || ErrorCode.InternalServerError,
      })
    }
  },
)
export const getCampaignTikTokCampaignToScheduleDetails = createAsyncThunk<
  TiktokCampaign,
  number
>(
  `${NameSpace.TiktokCampaign}/getCampaignTikTokCampaignToScheduleDetails`,
  async (tiktokCampaignId, { rejectWithValue, getState }) => {
    try {
      const { data } = await api.get<TiktokCampaign>(
        `${APP.TIKTOK_SERVER}/artist/tiktok/campaigns/${tiktokCampaignId}`,
      )
      const state = getState() as RootState
      const hasPlan = getHasActiveOrCanceledSubscriptionSelector(state)
      const isMoreThenMinDaysLeft = getIsMoreThenDaysLeft(data.startDate)

      return {
        ...data,
        startDate: !isMoreThenMinDaysLeft
          ? getDefaultStartDate(hasPlan)
          : data.startDate,
      }
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      return rejectWithValue({
        message: response?.data.message,
        code: response?.status || ErrorCode.InternalServerError,
      })
    }
  },
)
