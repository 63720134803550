import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { useSelector } from 'react-redux'

import { getLevels, getLevelsLoading } from 'src/Redux/levels-procces/selectors'
import { LoadingSpinner } from 'src/Containers/ui'
import { colors } from 'src/Styled/variables'

import TableListItem from '../TableListItem'

import {
  LoaderContainer,
  TableHeaderContainer,
  TableList,
  Value,
} from './styles'

const LevelsTable: FC = () => {
  const { t } = useTranslation()
  const isMobile = useMediaQuery({ maxWidth: 576 })

  const levels = useSelector(getLevels)
  const isLoading = useSelector(getLevelsLoading)

  return (
    <div>
      {!isMobile && (
        <TableHeaderContainer>
          <Value>{t('levelsPage.description')}</Value>
          <Value>{t('levelsPage.scoreRequired')}</Value>
          <Value>{t('levelsPage.rewardPerReview')}</Value>
          <Value>{t('levelsPage.playlists')}</Value>
        </TableHeaderContainer>
      )}
      {isLoading && (
        <LoaderContainer>
          <LoadingSpinner color={colors.green} size='large' />
        </LoaderContainer>
      )}

      <TableList>
        {levels.map((item) => (
          <TableListItem key={item.position} level={item} />
        ))}
      </TableList>
    </div>
  )
}
export default LevelsTable
