import { Modal } from 'antd'
import styled from 'styled-components'

import { colors } from 'src/Styled/variables'

export const StyledModal = styled(Modal)`
  top: 10%;
  .ant-modal-content {
    padding: 0;
    border-radius: 8px;
  }
  .ant-modal-header {
    padding: 16.5px 24px;
    background-color: ${colors.badgeRed};
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  .ant-modal-title {
    color: ${colors.white};
  }
  .ant-modal-close-x {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
export const Wrapper = styled.div`
  margin: 30px;
  display: flex;
  align-items: center;
  gap: 8px;
  flex-direction: column;
  padding-bottom: 30px;
  div {
    width: 100%;
  }
`
export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  & > div {
    width: fit-content;
    .ant-btn.ant-btn-default {
      padding: 0 40px;
      display: flex;
      align-items: center;
      gap: 5px;
    }
  }
`
