import {
  PayloadAction,
  createDraftSafeSelector,
  createSlice,
} from '@reduxjs/toolkit'

import { CampaignPlatformTypeEnum } from 'src/Types'

import { RootState } from '..'

export interface CampaignsActionsProcessState {
  filters: {
    [CampaignPlatformTypeEnum.SPOTIFY]: boolean
    [CampaignPlatformTypeEnum.TIKTOK]: boolean
  }
  search: string
}

const initialState: CampaignsActionsProcessState = {
  filters: {
    [CampaignPlatformTypeEnum.SPOTIFY]: false,
    [CampaignPlatformTypeEnum.TIKTOK]: false,
  },
  search: '',
}
export const campaignsActionsProcess = createSlice({
  initialState,
  name: 'campaignsActionsProcess',
  reducers: {
    setCampaignsFilters(
      state,
      { payload }: PayloadAction<CampaignsActionsProcessState['filters']>,
    ) {
      state.filters = payload
    },
    setCampaignsSearch(state, { payload }: PayloadAction<string>) {
      state.search = payload
    },
  },
})
export const { setCampaignsFilters, setCampaignsSearch } =
  campaignsActionsProcess.actions

const getState = (state: RootState): CampaignsActionsProcessState =>
  state.campaignsActions

export const getCampaignsFilters = createDraftSafeSelector(
  getState,
  (state) => state.filters,
)
export const getCampaignsSearch = createDraftSafeSelector(
  getState,
  (state) => state.search,
)
