import { combineReducers } from '@reduxjs/toolkit'

import { artistPasswordSlice } from './artistPassword'
import { curatorDeleteAccountSlice } from './curatorDeleteAccount'
import { artistDeleteAccountSlice } from './artistDeleteAccount'

export const settingsProcess = combineReducers({
  artistPassword: artistPasswordSlice.reducer,
  curatorDeleteAccount: curatorDeleteAccountSlice.reducer,
  artistDeleteAccount: artistDeleteAccountSlice.reducer,
})
