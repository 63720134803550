import { useEffect } from 'react'

import { useNavigate, useParams } from 'react-router-dom'

import { TiktokCampaignStatus } from 'src/Types'
import { campaignsList } from 'src/Router/routes'
import { useAppDispatch } from 'src/Hooks/redux'
import { getCampaignTikTokCampaignToScheduleDetails } from 'src/Redux/schedule-process/tiktokCampaign/api-actions'
import { PaymentStatusEnum } from 'src/Containers/ScheduleSpotifyWrapper/helpers/useCheckSpotifyCampaignStatus'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'

export const useCheckTikTokCampaignStatus = (): void => {
  const dispatch = useAppDispatch()
  const { search } = useCustomSearchParams()
  const params = useParams()
  const navigate = useNavigate()

  const paymentStatus = search.get('paymentStatus')
  const campaignId = Number(params.number)

  const campaignDetailsUrl = `campaigns/details/${campaignId}`
  const isSuccessPayedCampaign = paymentStatus === PaymentStatusEnum.SUCCESS

  useEffect(() => {
    void dispatch(getCampaignTikTokCampaignToScheduleDetails(campaignId))
      .unwrap()
      .then((res) => {
        if (!isSuccessPayedCampaign) {
          res.status === TiktokCampaignStatus.SCHEDULED &&
            navigate(campaignsList)
          res.status !== TiktokCampaignStatus.PENDING &&
            navigate(campaignDetailsUrl)
        }
        return res
      })
  }, [
    campaignDetailsUrl,
    campaignId,
    dispatch,
    isSuccessPayedCampaign,
    navigate,
  ])
}
