import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'

import Button from 'src/Components/Buttons/Button'
import CloseIcon from 'src/Assets/Svg/close-cross-white.svg?react'
import PlusIcon from 'src/Assets/Svg/plus.svg?react'

import AddPlaylistForm from '../AddPlaylistForm'

import {
  ButtonBody,
  ButtonWrapper,
  Container,
  IconContainer,
  StyledModal,
  Wrapper,
} from './styles'

const AddPlaylistModal: React.FC = () => {
  const { t } = useTranslation()
  const [modal, setModalValue] = useState(false)

  const toggle = (): void => setModalValue(!modal)
  return (
    <Container>
      <ButtonWrapper>
        <Button type='white' onClick={toggle}>
          <ButtonBody>
            <IconContainer>
              <PlusIcon />
            </IconContainer>
            {t('playlistsPage.addManually')}
          </ButtonBody>
        </Button>
      </ButtonWrapper>

      <StyledModal
        open={modal}
        title={t('playlistsPage.addSpotifyPlaylist')}
        closeIcon={
          <CloseIcon width={10} height={10} data-testid='closeCross' />
        }
        footer={null}
        onCancel={toggle}
      >
        <Wrapper>
          <AddPlaylistForm toggle={toggle} />
        </Wrapper>
      </StyledModal>
    </Container>
  )
}

export default AddPlaylistModal
