import { useTranslation } from 'react-i18next'

import { Genre, ProperGenreOrLanguage, GroupedOption } from 'src/Types'
import { RadioOption } from 'src/Components/TargetLanguageRadio/props'
import {
  RadioLabel,
  RadioSubLabel,
} from 'src/Components/TargetLanguageRadio/styles'
import { ANY_LANGUAGE_ID } from 'src/Constants/constants'

import { SelectedLanguage } from './LanguagesModal'

export const convertGenres = (genres: ProperGenreOrLanguage[]): Genre[] =>
  genres.map((genre) => ({
    id: Number(genre.id),
    genre_name: String(genre.name),
  }))

export const convertLanguages = (
  languages: ProperGenreOrLanguage[],
): SelectedLanguage | null => {
  const selected = languages.find((language) => language.id !== ANY_LANGUAGE_ID)

  if (!selected) {
    return null
  }
  return {
    value: Number(selected.id),
    label: selected.name,
  }
}

export const getNewFilteredLanguages = (
  groupedOptions: GroupedOption[],
  searchValue: string,
): GroupedOption[] =>
  groupedOptions.map((item) => ({
    ...item,
    options: item.options.filter(({ label }) =>
      label?.toLowerCase().includes(searchValue.toLowerCase()),
    ),
  }))

export const useLanguageRadioOptions = (): Array<RadioOption> => {
  const { t } = useTranslation()

  return [
    {
      value: '3',
      label: (
        <RadioLabel>
          {t('scheduleCampaignPage.matchSelectedAndOther')}
          <RadioSubLabel>{t('scheduleCampaignPage.recommended')}</RadioSubLabel>
        </RadioLabel>
      ),
    },
    {
      value: '0',
      label: (
        <RadioLabel>{t('scheduleCampaignPage.matchSelectedOnly')}</RadioLabel>
      ),
    },
  ]
}
