import { FC } from 'react'

import { useTranslation } from 'react-i18next'

import RelaunchIcon from 'src/Assets/Svg/relaunch.svg?react'
import Button from 'src/Components/Buttons/Button'

import { RelaunchButtonProps } from './RelaunchButtonProps'

import { StyledRelaunchButton } from './styles'

export const RelaunchButton: FC<RelaunchButtonProps> = ({
  onRelaunchCampaign,
}) => {
  const { t } = useTranslation()
  return (
    <Button type='green' onClick={onRelaunchCampaign}>
      <StyledRelaunchButton>
        <RelaunchIcon />
        {t('campaignResultsPage.relaunch')}
      </StyledRelaunchButton>
    </Button>
  )
}
