import React from 'react'

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import TikTok from 'src/Assets/Svg/platform-icons/TikTok.svg?react'
import Spotify from 'src/Assets/Svg/platform-icons/Spotify.svg?react'
import {
  SquareButton,
  SquareButtonProps,
} from 'src/Components/Buttons/SquareButton'
import { MAX_MOBILE_WIDTH } from 'src/Styled/variables'
import { CampaignPlatformTypeEnum } from 'src/Types'

const StyledButton = styled(SquareButton)`
  padding: 0 12px;

  @media (max-width: ${MAX_MOBILE_WIDTH}) {
    padding: 7px;
    span {
      display: none;
    }
  }
`

const ChildrenWrapper = styled.div`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  display: flex;
  gap: 8px;

  @media (max-width: ${MAX_MOBILE_WIDTH}) {
    span {
      display: none;
    }
  }
`

export type UpsellButtonProps = Pick<
  SquareButtonProps,
  'loading' | 'onClick' | 'disabled'
> & {
  platform: CampaignPlatformTypeEnum
}

export const UpsellButton: React.FC<UpsellButtonProps> = ({
  loading,
  onClick,
  disabled,
  platform,
}) => {
  const { t } = useTranslation()

  const PlatformIcon =
    platform === CampaignPlatformTypeEnum.TIKTOK ? TikTok : Spotify

  const buttonText =
    platform === CampaignPlatformTypeEnum.TIKTOK
      ? t('components.createTikTokCampaignButton')
      : t('components.createSpotifyCampaignButton')

  return (
    <StyledButton loading={loading} disabled={disabled} onClick={onClick}>
      <ChildrenWrapper>
        {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
        <>
          <PlatformIcon />
        </>

        <span>{buttonText}</span>
      </ChildrenWrapper>
    </StyledButton>
  )
}
