import styled from 'styled-components'

import { colors, device, variables } from 'src/Styled/variables'
export const CreateCampaignHead = styled.div`
  padding-left: 0;
  padding-top: 0px;
  margin-bottom: 21px;
  font-size: ${variables.fontSize20};
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
  color: ${colors.mainText};
  margin-top: 21px;

  @media ${device.mobileMin} {
    margin-top: 24px;
    font-size: ${variables.fontSize24};
    margin-bottom: 18px;
  }
`

export const CreateCampaignSubHead = styled.div`
  padding-left: 0;
  padding-top: 0px;
  margin-bottom: 24px;
  font-size: ${variables.fontSize18};
  font-weight: ${variables.fontWeight400};
  line-height: 27px;
  color: ${colors.mainText};
  cursor: pointer;
`
