import { useEffect } from 'react'

import { useNavigate, useParams } from 'react-router-dom'

import { getCampaignStatus } from 'src/Redux/campaign-process/spotifyCampaignSchedule-process/api-actions'
import { CampaignStatusEnum } from 'src/Types'
import { campaignsList } from 'src/Router/routes'
import { useAppDispatch } from 'src/Hooks/redux'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'

export enum PaymentStatusEnum {
  SUCCESS = 'success',
  FAILURE = 'failure',
  ERROR = 'error',
}

export const useCheckSpotifyCampaignStatus = (): void => {
  const dispatch = useAppDispatch()
  const { search } = useCustomSearchParams()
  const params = useParams()
  const navigate = useNavigate()

  const paymentStatus = search.get('paymentStatus')
  const campaignId = Number(params.number)

  useEffect(() => {
    void dispatch(getCampaignStatus({ campaignId }))
      .unwrap()
      .then((response) => {
        const isFinishedCampaign =
          response.campaign.status === CampaignStatusEnum.ENDED ||
          response.campaign.status === CampaignStatusEnum.RUNNING

        const isSuccessPayedCampaign =
          paymentStatus === PaymentStatusEnum.SUCCESS

        if (isFinishedCampaign && !isSuccessPayedCampaign) {
          const campaignDetailsUrl = `/campaigns/view/${campaignId}`
          navigate(campaignDetailsUrl)
        }

        return response
      })
      .catch(() => {
        navigate(campaignsList)
      })
  }, [campaignId, dispatch, paymentStatus, navigate])
}
