import React, { FunctionComponent } from 'react'

import { Checkbox } from 'antd'

import { CheckboxProps } from './CheckboxProps'

import { StyledCheckbox, Label } from './styles'

const CheckboxAntd: FunctionComponent<CheckboxProps> = ({
  checked,
  onChange,
  title,
  className,
  ...rest
}) => (
  <StyledCheckbox>
    <Checkbox
      className={className}
      checked={checked}
      onChange={onChange}
      {...rest}
    >
      <Label>{title}</Label>
    </Checkbox>
  </StyledCheckbox>
)

export default CheckboxAntd
