import { createSlice } from '@reduxjs/toolkit'

import { ApiError, NameSpace, SliceStatus } from 'src/Redux/types'
import { Artist } from 'src/Types'
import { setApiError } from 'src/Hooks/redux'

import { getArtists } from './api-actions'

export interface ArtistsListState {
  artists: Array<Artist>
  totalArtists: number
  status: SliceStatus
  error: ApiError | null
}
const initialState: ArtistsListState = {
  artists: [],
  totalArtists: 0,
  status: SliceStatus.Idle,
  error: null,
}

export const artistsListSlice = createSlice({
  initialState,
  name: NameSpace.ArtistList,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getArtists.pending, (state) => {
      state.status = SliceStatus.Loading
    })
    builder.addCase(getArtists.fulfilled, (state, action) => {
      state.status = SliceStatus.Succeeded
      state.artists = action.payload.artists
      state.totalArtists = action.payload.usersCount.totalArtists
    })
    builder.addCase(getArtists.rejected, (state, action) => {
      state.status = SliceStatus.Failed
      state.error = setApiError(action)
    })
  },
})
