import React from 'react'

import styled from 'styled-components'

import { variables } from 'src/Styled/variables'

const Container = styled.button`
  box-sizing: border-box;
  display: flex;
  height: 40px;
  padding: 0px 16px;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;
  cursor: pointer;
  border-radius: 8px;
  text-decoration: none;
  border: none;
  background: none;
  width: 100%;

  &:focus-visible {
    outline: ${({ theme }) => `1px solid ${theme.common.secondaryBorder}`};
    outline-offset: -1px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.common.primaryBorder};
  }

  &:active {
    background-color: ${({ theme }) => theme.common.primaryBorder};
    color: ${({ theme }) => theme.common.secondaryText};

    svg {
      path {
        fill: ${({ theme }) => theme.common.secondaryText};
      }
    }

    &:focus {
      outline: none;
      border: none;
    }
  }

  &:disabled {
    pointer-events: none;
    cursor: not-allowed;
    color: ${({ theme }) => theme.common.thirdText};

    svg {
      path {
        fill: ${({ theme }) => theme.common.thirdText};
      }
    }
  }
`

const Title = styled.span`
  font-family: 'Segoe UI';
  font-weight: ${variables.fontWeight400};
  font-size: ${variables.fontSize14};
  font-style: normal;
  line-height: 150%;
  color: ${({ theme }) => theme.common.primaryText};
`

export interface HeaderMenuItemProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  Icon: React.FC<React.SVGProps<SVGSVGElement>>
  title: string
}

export const HeaderMenuItem: React.FC<HeaderMenuItemProps> = ({
  Icon,
  title,
  disabled,
  onClick,
}) => (
  <Container disabled={disabled} onClick={onClick}>
    <Icon fill='#333' />
    <Title>{title}</Title>
  </Container>
)
