import styled from 'styled-components'

import { colors } from 'src/Styled/variables'

const MAX_PERCENT = 100
export const ProgressBar = styled.div`
  padding-top: 6px;
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-column-gap: 1px;

  & > div:first-child {
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
  }
  & > div:last-child {
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
  }
`

export const ProgressSection = styled.div<{
  to: number
  from: number
  percent: number
}>`
  /* width: calc((100% / 10)); */
  height: 4px;

  background: ${({ percent, from, to }) => {
    if (from < percent && percent < to && to === MAX_PERCENT) {
      return `linear-gradient(270deg, ${colors.greyTextBlackBg} 50%, ${colors.green} 50%);`
    }
    if (from < percent || percent > to) {
      return colors.green
    }

    return colors.greyTextBlackBg
  }};
`
