import React, { FC } from 'react'

import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Breadcrumb } from 'src/Components/Breadcrumb'
import { ArtistRoutesEnum } from 'src/Router/ArtistComponentsNewUI'

export const TikTokVideoDetailsBreadcrumbs: FC = () => {
  const { campaignID } = useParams<{ campaignID: string }>()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const breadcrumbItems = [
    {
      title: t('campaignDetailsPage.campaigns'),
      path: ArtistRoutesEnum.CampaignsList,
    },
    {
      title: t('campaignDetailsPage.campaignsDetails'),
      path: `${ArtistRoutesEnum.TiktokDetails}/${campaignID}`,
    },
    {
      title: t('campaignDetailsPage.videoDetails'),
      path: '',
    },
  ]

  const onBreadcrumbButtonClick = (): void => {
    navigate(`${ArtistRoutesEnum.TiktokDetails}/${campaignID}`)
  }

  return (
    <Breadcrumb
      items={breadcrumbItems}
      onButtonClick={onBreadcrumbButtonClick}
    />
  )
}
