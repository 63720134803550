import { FunctionComponent } from 'react'

import InfiniteScroll from 'react-infinite-scroll-component'
import { useTranslation } from 'react-i18next'

import { getEventById } from 'src/Redux/influencer-process/api-actions'
import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { openCampaignDetails } from 'src/Redux/influencer-process'
import SoonInfluencerScreenIcon from 'src/Assets/Svg/soonInfluencerScreen.svg?react'
import { InfluencerEvent, InfluencerEventStatus } from 'src/Types'
import InfinityLoader from 'src/Components/InfinityLoader'
import { TabKeys } from 'src/Containers/Tracks/helpers'
import { getUser } from 'src/Redux/auth-process/userSlice/selectors'
import { getCreatorEventsPageActiveTab } from 'src/Redux/influencer-process/selectors'

import { ListItem } from '../ListItem'

import { TracksListProps } from './TracksListProps'

import { LoaderWrapper, SoonContainer, TracksListContainer } from './styles'

export const TracksList: FunctionComponent<TracksListProps> = ({
  events: tracksEvents,
  loadMore,
  isNoItemsMessageVisible,
  isLoading,
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { events, meta } = tracksEvents
  const creator = useAppSelector(getUser)
  const activeTab = useAppSelector(getCreatorEventsPageActiveTab)

  const openDetails = (event: InfluencerEvent): void => {
    if (event.status === InfluencerEventStatus.NEW) {
      dispatch(openCampaignDetails(event.id))
    } else {
      void dispatch(getEventById(event.id))
    }
  }

  if (events.length === 0 && isLoading) {
    return (
      <TracksListContainer>
        <LoaderWrapper>
          <InfinityLoader isLoading={true} />
        </LoaderWrapper>
      </TracksListContainer>
    )
  }

  const isNoItemsMessageVisibleInner =
    (events.length === 0 && isNoItemsMessageVisible) ||
    (creator.isBlocked && activeTab === TabKeys.NEW)

  return (
    <TracksListContainer>
      {isNoItemsMessageVisibleInner ? (
        <SoonContainer>
          <SoonInfluencerScreenIcon width={31.36} height={51.29} />
          <div>{t('influencer.youllGetNewTracksForVideosCreationSoon')}</div>
        </SoonContainer>
      ) : null}

      {events.length > 0 && (
        <InfiniteScroll
          dataLength={events.length}
          next={loadMore}
          hasMore={meta?.hasNextPage || false}
          loader={
            <LoaderWrapper>
              <InfinityLoader isLoading={true} />
            </LoaderWrapper>
          }
        >
          {events.map((item) => (
            <ListItem
              key={item.id}
              event={item}
              id={item.id}
              onDetailsClick={openDetails}
            />
          ))}
        </InfiniteScroll>
      )}
    </TracksListContainer>
  )
}
