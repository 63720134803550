import { code } from 'currency-codes'

import {
  Recipient,
  TransferwiseRequirement,
  RecipientAddress,
} from 'src/Types/index'

import { Currencies } from './props'

interface Option {
  value: string
  label: string
}

export const setCurrenciesOptions = (
  currencies?: Currencies[],
): Option[] | undefined =>
  currencies?.map((currency) => {
    const currencyCode = code(currency.code)

    return {
      value: currency.code,
      label: `${currency.code} - ${
        (currencyCode && currencyCode?.currency) || ''
      }`,
    }
  })

export type InitialBankDetails = Record<string, string | RecipientAddress>

export const getInitialValues = (
  recipient: Recipient | null,
  requirements: TransferwiseRequirement[],
): InitialBankDetails => {
  // adding only necessary property's to form initial values
  const initialObj: InitialBankDetails = {}

  if (recipient?.accountHolderName) {
    initialObj.accountHolderName = recipient.accountHolderName
  }

  if (recipient?.details?.address) {
    initialObj.address = recipient.details.address
  }

  if (recipient?.type && requirements && requirements.length > 0) {
    const currentRequirements = requirements.find(
      (requirement) => requirement.type === recipient.type,
    )

    currentRequirements &&
      currentRequirements.fields.forEach((field) => {
        field.group.forEach((groupItem) => {
          if (groupItem.key in recipient.details) {
            initialObj[groupItem.key] =
              recipient.details[groupItem.key as keyof typeof recipient.details]
          }
        })
      })
  }

  return initialObj
}

export interface BankDetails {
  address: { state?: string }
}
