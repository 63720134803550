import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'

import { APP } from 'src/Configs/App'
import { ArtistRolesEnum, Roles } from 'src/Constants/enums'
import { NameSpace } from 'src/Redux/types'
import { api } from 'src/Services/api'
import { Curator, UsersMeResponse } from 'src/Types'

interface GetUserInfoResponse {
  artist: {
    is_tiktok_allowed: boolean
    show_tiktok_alert: boolean
    artist_role: ArtistRolesEnum
  }
  balance: number
  referral_code: null | string
  is_new_term: boolean
  complaints: number
  country: string | null
  curator?: Curator | null
  role: Roles
  first_name: string
  last_name: string
  user_id: number
  email: string
  created_at: string
  isPassword: boolean
  isEmailBounced: boolean
  user_photo_url?: string
  isDeactivated: boolean
  is_email_confirmed: boolean
  phone_number?: string
}

export const getUserInfo = createAsyncThunk<GetUserInfoResponse>(
  `${NameSpace.User}/getUserInfo`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await api.get<GetUserInfoResponse>(
        `${APP.SERVER}/user/info`,
      )
      return data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      return rejectWithValue({
        message: response?.data.message,
        code: response?.status,
      })
    }
  },
)

interface GetCuratorRankResponse {
  rank: number
  score: number
}

export const getCuratorRank = createAsyncThunk<GetCuratorRankResponse>(
  `${NameSpace.User}/getCuratorRank`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await api.get<GetCuratorRankResponse>(
        `${APP.SERVER}/curator/rank`,
      )
      return data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      return rejectWithValue({
        message: response?.data.message,
        code: response?.status,
      })
    }
  },
)

export const setIsNewFeed = createAsyncThunk<unknown>(
  `${NameSpace.User}/setIsNewFeed`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await api.put<unknown>(
        `${APP.SERVER}/curator/feed-alert`,
        {
          is_new_feed: false,
        },
      )
      return data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      return rejectWithValue({
        message: response?.data.message,
        code: response?.status,
      })
    }
  },
)

interface AcceptTermsNewBody {
  termsPosition: number
  termsCount: number
}

interface AcceptTermsNewResponse {
  are_terms_accepted: boolean
  terms_position: number
}
export const acceptTermsNew = createAsyncThunk<
  AcceptTermsNewResponse,
  AcceptTermsNewBody
>(
  `${NameSpace.User}/acceptTermsNew`,
  async ({ termsPosition, termsCount }, { rejectWithValue }) => {
    try {
      const { data } = await api.put<AcceptTermsNewResponse>(
        `${APP.SERVER}/curator/terms`,
        {
          termsPosition,
          termsCount,
        },
      )
      return data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      return rejectWithValue({
        message: response?.data.message,
        code: response?.status,
      })
    }
  },
)

export const activateCurator = createAsyncThunk<unknown>(
  `${NameSpace.User}/activateCurator`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await api.patch<unknown>(
        `${APP.SERVER}/curator/activate`,
      )
      return data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      return rejectWithValue({
        message: response?.data.message,
        code: response?.status,
      })
    }
  },
)

interface SetGcidToUserBody {
  gcid: string
  isArtist: boolean
}

interface SetGcidToUserResponse {
  gcid: string
}

export const setGcidToUser = createAsyncThunk<
  SetGcidToUserResponse,
  SetGcidToUserBody
>(
  `${NameSpace.User}/setGcidToUser`,
  async ({ gcid, isArtist }, { rejectWithValue }) => {
    try {
      const { data } = await api.put<SetGcidToUserResponse>(
        `${APP.SERVER}/auth/gcid`,
        {
          gcid,
          isArtist,
        },
      )
      return data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      return rejectWithValue({
        message: response?.data.message,
        code: response?.status,
      })
    }
  },
)

export const usersMe = createAsyncThunk<UsersMeResponse>(
  `${NameSpace.User}/usersMe`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await api.get<UsersMeResponse>(
        `${APP.TIKTOK_SERVER}/users/me`,
      )
      return data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      return rejectWithValue({
        message: response?.data.message,
        code: response?.status,
      })
    }
  },
)

// Temporarily used for the artist, in order not to overwrite the data received by getUserInfo and get HMAC hash for Intercom
export const getIntercomHash = createAsyncThunk<UsersMeResponse>(
  `${NameSpace.User}/getIntercomHash`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await api.get<UsersMeResponse>(
        `${APP.TIKTOK_SERVER}/users/me`,
      )
      return data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      return rejectWithValue({
        message: response?.data.message,
        code: response?.status,
      })
    }
  },
)

interface GetSpotifyAvatarResponse {
  new_avatar_image: string
}

export const getSpotifyAvatar = createAsyncThunk<GetSpotifyAvatarResponse>(
  `${NameSpace.User}/getSpotifyAvatar`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await api.get<GetSpotifyAvatarResponse>(
        `${APP.SERVER}/user/profile-avatar`,
      )
      return data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      return rejectWithValue({
        message: response?.data.message,
        code: response?.status,
      })
    }
  },
)
