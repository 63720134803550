import styled from 'styled-components'

import { colors, variables } from 'src/Styled/variables'

export const TiktokBudgetContainer = styled.div`
  display: flex;
  min-width: 288px;
  max-width: 720px;
  padding: 24px;
  flex-direction: column;
  gap: 24px;
  align-self: stretch;
  border-radius: 4px;
  background: ${colors.white};
  @media (min-width: 1279px) {
    min-width: 288px;
    max-width: 340px;
  }
`

export const Title = styled.div`
  color: ${colors.mainText};
  font-size: ${variables.fontSize18};
  font-style: normal;
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
  width: 100%;
`

export const BudgetContainer = styled.div`
  display: grid;
  gap: 8px;
`
interface Props {
  hasActivePlan?: boolean
}
export const SliderContainer = styled.div<Props>`
  height: ${(props: Props) => (props.hasActivePlan ? '240px' : '198px')};
  padding: 29px 0 29px 7px;
`
