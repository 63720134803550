import TagManager from 'react-gtm-module'

import { PaymentMethodEnum, Roles, TagManagerEnum } from 'src/Constants/enums'
import { CampaignPlatformTypeEnum, CampaignTypeEnum, User } from 'src/Types'
import { PAYPAL_SAVED } from 'src/Constants/constants'

import {
  EventNameEnum,
  EventTypeEnum,
  PurchaseTypeEnum,
} from '../tagManagerEnums'

export const paymentMethodChanged = ({
  campaignType,
  campaignPlatform,
  campaignNumber,
  campaignId,
  userId,
  resultPrice,
  genreList,
  languageList,
  paymentMethod,
  prevPaymentMethod,
  is_feed,
  isDanceRequired = false,
  subscriptionPlan,
}: {
  campaignType: string
  campaignPlatform: CampaignPlatformTypeEnum
  campaignNumber: number
  campaignId: number
  userId: number | string
  resultPrice: number
  genreList: string
  languageList: string
  paymentMethod: PaymentMethodEnum | string
  prevPaymentMethod: PaymentMethodEnum
  is_feed?: boolean
  isDanceRequired?: boolean
  subscriptionPlan?: string
}): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventTypeEnum.PaymentMethodChanged,
      event_name: EventNameEnum.PaymentMethodChanged,
      flow: 'checkout',
      'campaign-type': campaignType,
      'campaign-platform': campaignPlatform,
      'campaign-number':
        campaignType === CampaignTypeEnum.FIRST ? 1 : campaignNumber,
      'campaign-id': campaignId,
      ...(userId ? { userID: userId } : {}),
      'result-price': resultPrice,
      'genre-list': genreList,
      language_list: languageList,
      payment_method:
        paymentMethod === PaymentMethodEnum.PAYPAL_BILLING_AGREEMENT
          ? PAYPAL_SAVED
          : paymentMethod,
      prev_payment_method:
        prevPaymentMethod && typeof prevPaymentMethod === 'string'
          ? (prevPaymentMethod.toLocaleLowerCase() as PaymentMethodEnum)
          : '',
      is_feed,
      dance_required: isDanceRequired,
      ...(subscriptionPlan ? { subscription_plan: subscriptionPlan } : {}),
    },
  })
}

export const purchaseEvent = ({
  user,
  campaignId,
  transactionTotal,
  price,
  isFirstCampaign,
  campaignPlatform,
}: {
  user: User
  campaignId: string | number
  transactionTotal: number
  price: number
  isFirstCampaign: boolean
  campaignPlatform: string
}): void => {
  TagManager.dataLayer({
    dataLayer: {
      transactionTotal,
      event: EventTypeEnum.Purchase,
      userID: user.id,
      campaignID: campaignId,
      transactionId: campaignId,
      transactionProducts: [
        {
          price,
          sku: isFirstCampaign ? 'S1' : 'S2',
          name: isFirstCampaign
            ? `${
                campaignPlatform.charAt(0).toUpperCase() +
                campaignPlatform.slice(1)
              } Campaign - First time`
            : `${
                campaignPlatform.charAt(0).toUpperCase() +
                campaignPlatform.slice(1)
              } Campaign`,
          category:
            campaignPlatform === CampaignPlatformTypeEnum.SPOTIFY
              ? 'Spotify'
              : 'TikTok',
          quantity: 1,
        },
      ],
    },
  })
}

export const paymentCompletedEvent = ({
  campaignType,
  platformName,
  campaignNumber,
  campaignId,
  userId,
  userRole,
  campaignValue,
  genresList,
  languagesList,
  paymentAmount,
  transactionId,
  paymentMethod,
  isAnyLanguage,
  subscriptionPlan,
  email,
  phone,
}: {
  campaignType: string
  platformName: string
  campaignNumber: number
  campaignId: number | string
  userId: number | string
  userRole: Roles
  campaignValue: number
  genresList: string
  languagesList: string
  paymentAmount: number
  transactionId: string
  paymentMethod: PaymentMethodEnum | string
  isAnyLanguage?: boolean
  subscriptionPlan?: string
  email: string
  phone: string
}): void => {
  TagManager.dataLayer({
    dataLayer: {
      email,
      phone,
      event: EventTypeEnum.PaymentCompleted,
      event_name: EventNameEnum.PaymentCompleted,
      flow: 'checkout',
      'campaign-type': campaignType,
      'campaign-platform': platformName,
      'campaign-number': campaignNumber,
      'campaign-id': campaignId,
      userID: userId,
      'account-type': userRole,
      'result-price': campaignValue,
      'genre-list': genresList,
      language_list: languagesList,
      affiliation: 'web,',
      value: paymentAmount,
      currency: 'USD',
      transaction_id: transactionId,
      purchase_type:
        campaignType === 'first'
          ? PurchaseTypeEnum.FirstPurchase
          : PurchaseTypeEnum.Purchase,
      payment_method:
        paymentMethod === PaymentMethodEnum.PAYPAL_BILLING_AGREEMENT
          ? PAYPAL_SAVED
          : paymentMethod,
      is_feed: platformName === CampaignPlatformTypeEnum.SPOTIFY,
      dance_required: false,
      ...(platformName === CampaignPlatformTypeEnum.SPOTIFY
        ? {
            language_options: isAnyLanguage
              ? TagManagerEnum.WIDER_LANGUAGES_MATCHING
              : TagManagerEnum.SELECTED_LANGUAGES_ONLY,
          }
        : {}),
      ...(subscriptionPlan ? { subscription_plan: subscriptionPlan } : {}),
    },
  })
}

export const paymentCancelledEvent = ({
  campaignType,
  platformName,
  campaignNumber,
  campaignId,
  userId,
  userRole,
  campaignValue,
  genresList,
  languagesList,
  paymentAmount,
  paymentMethod,
}: {
  campaignType: string
  platformName: string
  campaignNumber: number
  campaignId: number | string
  userId: number | string
  userRole: string
  campaignValue: number
  genresList: string
  languagesList: string
  paymentAmount: number | string | null
  paymentMethod: string
}): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventTypeEnum.PaymentCancelled,
      event_name: EventNameEnum.PaymentCancelled,
      flow: 'checkout',
      'campaign-type': campaignType,
      'campaign-platform': platformName,
      ...(campaignNumber ? { 'campaign-number': campaignNumber } : {}),
      'campaign-id': campaignId,
      userID: userId,
      'account-type': userRole,
      'result-price': campaignValue,
      ...(genresList ? { 'genre-list': genresList } : {}),
      ...(languagesList ? { language_list: languagesList } : {}),
      is_feed: platformName === CampaignPlatformTypeEnum.SPOTIFY,
      affiliation: 'web,',
      value: paymentAmount,
      currency: 'USD',
      purchase_type:
        campaignType === 'first'
          ? PurchaseTypeEnum.FirstPurchase
          : PurchaseTypeEnum.Purchase,
      payment_method:
        paymentMethod === PaymentMethodEnum.PAYPAL_BILLING_AGREEMENT
          ? PAYPAL_SAVED
          : paymentMethod,
    },
  })
}

export const checkIsGTMPaymentEventNeeded = (
  campaignId: number | string,
  paymentResult: 'success' | 'failure',
): boolean => {
  const stringCampaignId = String(campaignId)
  const storageKey =
    paymentResult === 'success' ? 'stripeGtmSuccess' : 'stripeGtmFailure'

  const successSentCampaigns = localStorage.getItem(storageKey)

  if (successSentCampaigns) {
    const successSentGTMCampaigns = successSentCampaigns.split(',')

    if (!successSentGTMCampaigns.includes(stringCampaignId)) {
      successSentGTMCampaigns.push(stringCampaignId)
      const newSuccessGTMCampaigns = successSentGTMCampaigns.join(',')
      localStorage.setItem(storageKey, newSuccessGTMCampaigns)
      return true
    }

    return false
  }
  localStorage.setItem(storageKey, stringCampaignId)
  return true
}
