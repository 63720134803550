import { FC } from 'react'

import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Table } from 'antd'
import { ColumnsType } from 'antd/es/table'

import { getRatesSelector } from 'src/Redux/rate-process/selectors'

import { RateTableContainer } from './styles'

interface RateTableProps {
  rank: number
  rewardPerVideo: number
  followersRequired: string
  isUserPosition: boolean
}

const RateTable: FC = () => {
  const { t } = useTranslation()
  const rates = useSelector(getRatesSelector)

  const columns: ColumnsType<RateTableProps> = [
    {
      title: t('rate.level'),
      dataIndex: 'rank',
      key: 'rank',
      render: (text) => `Level ${text}`,
    },
    {
      title: t('rate.followersRequired'),
      dataIndex: 'followersRequired',
      key: 'followersRequired',
      render: (text: string) => text,
    },
    {
      title: t('rate.rewardPerVideo'),
      dataIndex: 'rewardPerVideo',
      key: 'rewardPerVideo',
      render: (text) => `$${text}`,
    },
  ]

  return (
    <RateTableContainer>
      <Table
        rowKey='rank'
        pagination={false}
        columns={columns}
        dataSource={rates}
        scroll={{ x: true }}
      />
    </RateTableContainer>
  )
}

export default RateTable
