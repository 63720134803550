import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import Button from 'src/Components/Buttons/Button'
import { subscription } from 'src/Router/routes'

const StyledButton = styled(Button)`
  .ant-btn.ant-btn-default {
    width: 200px;
  }
`

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  @media (min-width: 567px) {
    flex-direction: row;
  }
`

interface CancelButtonsProps {
  onCancel: () => void
  disabled?: boolean
  loading?: boolean
}

export const CancelButtons: FC<CancelButtonsProps> = ({
  onCancel,
  disabled,
  loading,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleClose = (): void => {
    navigate(subscription)
  }
  return (
    <ButtonsContainer>
      <StyledButton onClick={handleClose} type='whiteWithGreenBorder'>
        {t('getPremiumPage.cancelKeep')}
      </StyledButton>
      <StyledButton
        loading={loading}
        disabled={disabled}
        onClick={onCancel}
        type='red'
      >
        {t('getPremiumPage.cancelCancel')}
      </StyledButton>
    </ButtonsContainer>
  )
}
