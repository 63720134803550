import { APP } from 'src/Configs/App'
import { rtkApi } from 'src/Services/rtkApi'
interface Package {
  id: number
  name: string
  cashbackPercentage: number
  paymentAmount: number
}

const subscriptionPackagesApi = rtkApi.injectEndpoints({
  endpoints(build) {
    return {
      subscriptionPackages: build.query<Package[], void>({
        query: () => `${APP.TIKTOK_SERVER}/subscription-packages`,
      }),
    }
  },
})
export const { useSubscriptionPackagesQuery } = subscriptionPackagesApi
