import React, { useCallback, useMemo } from 'react'

import { useTranslation } from 'react-i18next'

import { useAppSelector } from 'src/Hooks/redux'
import { getPlaylistsWithNoPref } from 'src/Redux/playlists-process/selectors'
import { Desktop, Mobile } from 'src/Components/Responsive'
import ArrowLeftIcon from 'src/Assets/Svg/arrow-left.svg?react'
import CloseIcon from 'src/Assets/Svg/close-cross-white.svg?react'
import Button from 'src/Components/Buttons/Button'

import { PlaylistElement } from './PlaylistElement'
import { PlaylistsToSetModalDescription } from './PlaylistsToSetModalDescription'
import { useSetLanguages } from './useSetLanguages'

import {
  BackButton,
  ButtonsContainer,
  MobileContainer,
  MobilePageTitleRow,
  MobileWrapper,
  ModalContent,
  PageTitle,
  StyledModal,
} from './styles'

interface Props {
  toggle: () => void
  modal: boolean
}

const PlaylistsToSetModal: React.FunctionComponent<Props> = ({
  toggle,
  modal,
}) => {
  const { t } = useTranslation()

  const playlistsWithNoPref = useAppSelector(getPlaylistsWithNoPref)
  const {
    selectedInputLanguages,
    inputValueLanguages,
    onInputChangeLanguages,
    onChangeSelectLanguages,
    onSaveLanguages,
  } = useSetLanguages()

  const onSubmit = useCallback(async () => {
    await onSaveLanguages().finally(() => {
      toggle()
    })
  }, [onSaveLanguages, toggle])

  const submitEnabled = useMemo(
    () => Boolean(selectedInputLanguages.find((el) => el.length)),
    [selectedInputLanguages],
  )

  return (
    <>
      <Desktop>
        <StyledModal
          open={modal}
          title={t('playlistsPage.playlistsToSetModal')}
          closeIcon={<CloseIcon width={10} height={10} />}
          footer={null}
          onCancel={toggle}
        >
          <ModalContent>
            <PlaylistsToSetModalDescription
              count={playlistsWithNoPref.length}
            />
            {playlistsWithNoPref.map((el, id) => (
              <PlaylistElement
                key={el.id}
                index={id}
                item={el}
                selectedInputLanguages={selectedInputLanguages}
                inputValueLanguages={inputValueLanguages}
                onInputChangeLanguages={onInputChangeLanguages}
                onChangeSelectLanguages={onChangeSelectLanguages}
              />
            ))}
            <ButtonsContainer>
              <Button type='green' disabled={!submitEnabled} onClick={onSubmit}>
                {t('playlistsPage.saveSettings')}
              </Button>
            </ButtonsContainer>
          </ModalContent>
        </StyledModal>
      </Desktop>
      <Mobile>
        {modal && (
          <MobileWrapper>
            <MobilePageTitleRow>
              <BackButton onClick={toggle}>
                <ArrowLeftIcon />
              </BackButton>
              <PageTitle>{t('playlistsPage.pageTitle')}</PageTitle>
            </MobilePageTitleRow>
            <MobileContainer>
              <PlaylistsToSetModalDescription
                count={playlistsWithNoPref.length}
              />
              {playlistsWithNoPref.map((el, id) => (
                <PlaylistElement
                  key={el.id}
                  index={id}
                  item={el}
                  selectedInputLanguages={selectedInputLanguages}
                  inputValueLanguages={inputValueLanguages}
                  onInputChangeLanguages={onInputChangeLanguages}
                  onChangeSelectLanguages={onChangeSelectLanguages}
                />
              ))}
              <ButtonsContainer>
                <Button
                  type='green'
                  disabled={!submitEnabled}
                  onClick={onSubmit}
                >
                  {t('playlistsPage.saveSettings')}
                </Button>
              </ButtonsContainer>
            </MobileContainer>
          </MobileWrapper>
        )}
      </Mobile>
    </>
  )
}

export default PlaylistsToSetModal
