import React, { PropsWithChildren } from 'react'

import styled from 'styled-components'

import { variables } from 'src/Styled/variables'

const StyledLink = styled.a`
  color: ${({ theme }) => theme.button.thirdText};
  font-family: 'Segoe UI';
  font-size: ${variables.fontSize14};
  font-style: normal;
  font-weight: ${variables.fontWeight400};
  line-height: 150%;
  cursor: pointer;
  text-decoration: none;
  border-bottom: 1px dashed ${({ theme }) => theme.button.thirdText};

  &:hover {
    color: ${({ theme }) => theme.button.thirdText};
    border-bottom: none;
    &:active {
      color: ${({ theme }) => theme.button.thirdText};
    }
  }

  &:focus {
    border-bottom: none;
    outline: none;
    color: ${({ theme }) => theme.button.thirdText};
  }

  &:active {
    color: ${({ theme }) => theme.button.thirdText};
    border-bottom: 1px solid ${({ theme }) => theme.button.thirdText};
  }

  &:visited {
    color: ${({ theme }) => theme.button.thirdText};
    text-decoration: none;
  }
`

export type DottedLinkProps = PropsWithChildren &
  React.AnchorHTMLAttributes<HTMLAnchorElement>

export const DottedLink: React.FC<DottedLinkProps> = ({
  children,
  ...props
}) => <StyledLink {...props}>{children}</StyledLink>
