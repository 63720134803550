import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'

import { SmartImage } from 'src/Components/SmartImage'
import track from 'src/Assets/Png/track.png'
import { Tooltip } from 'src/Components/Tooltip'
import { addSpaceToNumber } from 'src/Helpers/layoutHelpers'

import { PlaylistIconData } from '../../types'
import { PlaylistCoverTooltipContent } from '../PlaylistCoverTooltipContent'

import {
  Container,
  CounterWrapper,
  CoverImageWrapper,
  DetailsContainer,
  ScoreValue,
} from './styles'

interface PlaylistCardProps {
  playlist: PlaylistIconData
  isOpener: boolean
  onOpenerClick: () => void
  showMorePlaylistsCount: number
  isShortList: boolean
  isDetailsMode: boolean
}

const PlaylistCard: FC<PlaylistCardProps> = ({
  playlist,
  isOpener,
  onOpenerClick,
  showMorePlaylistsCount,
  isShortList,
  isDetailsMode,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 576 })

  const { imageUrl, playlistName, score } = playlist
  const { t } = useTranslation()

  const showAllMessage = isShortList && <span>{t('levelsPage.showAll')}</span>

  const tooltipContent = isOpener ? (
    showAllMessage
  ) : (
    <PlaylistCoverTooltipContent playlist={playlist} />
  )

  const openerContent =
    isOpener && !isMobile ? (
      <CounterWrapper onClick={onOpenerClick}>
        +{showMorePlaylistsCount}
      </CounterWrapper>
    ) : null

  const formattedScore = `Score: ${addSpaceToNumber(score, ',')}`

  const detailContent = isDetailsMode ? (
    <DetailsContainer>
      <span>{playlistName}</span>
      <ScoreValue>{formattedScore}</ScoreValue>
    </DetailsContainer>
  ) : null
  // Don`t remove key prop from Tooltip
  // Because  Tooltip component  is not re-rendered when the content changes
  return (
    <Tooltip key={imageUrl + String(isOpener)} content={tooltipContent}>
      <Container isDetailsMode={isDetailsMode}>
        <CoverImageWrapper>
          <SmartImage src={imageUrl} alt='playlistCover' fallbackSrc={track} />
          {openerContent}
        </CoverImageWrapper>
        {detailContent}
      </Container>
    </Tooltip>
  )
}

export default PlaylistCard
