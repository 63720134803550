import { FC } from 'react'

import { useTranslation } from 'react-i18next'

import {
  TiktokCampaignStatus,
  CampaignStatusEnum,
  CampaignTypeEnum,
} from 'src/Types/index'

import { CampaignStatusProps } from './CampaignStatusProps'

import { StatusWithType } from './styles'

export const CampaignStatus: FC<CampaignStatusProps> = ({ campaign }) => {
  const { t } = useTranslation()
  const { status, type } = campaign

  const relaunched = type === CampaignTypeEnum.RELAUNCHED

  const pendingApprovalStatuses = [
    CampaignStatusEnum.PENDING_APPROVAL,
    TiktokCampaignStatus.AWAITING_REVIEW,
  ]

  const approvedStatuses = [
    CampaignStatusEnum.APPROVED,
    TiktokCampaignStatus.PENDING,
  ]

  const stoppedStatuses = [
    TiktokCampaignStatus.STOPPED,
    CampaignStatusEnum.STOPPED,
    TiktokCampaignStatus.STOPPED_BY_ADMIN,
    TiktokCampaignStatus.CANCELED_BY_ADMIN,
  ]

  const runningStatuses = [
    CampaignStatusEnum.RUNNING,
    TiktokCampaignStatus.IN_PROCESS,
  ]

  const endedStatuses = [
    CampaignStatusEnum.ENDED,
    TiktokCampaignStatus.FINISHED,
  ]

  if (pendingApprovalStatuses.includes(status)) {
    return t('campaignsPage.pendingApproval')
  }

  if (approvedStatuses.includes(status)) {
    return t('campaignsPage.approved')
  }

  if (status === CampaignStatusEnum.SCHEDULED) {
    return t('campaignsPage.scheduled')
  }

  if (status === CampaignStatusEnum.PAYMENT_PENDING) {
    return t('campaignsPage.pendingPayment')
  }

  if (stoppedStatuses.includes(status)) {
    return <>{t('campaignsPage.stopped')}</>
  }

  if (runningStatuses.includes(status)) {
    return (
      <StatusWithType>
        {t('campaignsPage.running')}&nbsp;{relaunched && '-'}&nbsp;
        {relaunched && t('campaignsPage.relaunched')}
      </StatusWithType>
    )
  }
  if (endedStatuses.includes(status)) {
    return <>{t('campaignsPage.ended')}</>
  }
  return null
}
