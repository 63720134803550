import { FC } from 'react'

import { RelaunchButton } from 'src/Components/RelaunchButton'
import DotsIcon from 'src/Assets/Svg/details.svg?react'
import { CampaignPlatformTypeEnum, CampaignTypeEnum } from 'src/Types'

import { DetailsButton } from '../DetailsButton'

import { CardButtonWrapper, StyledBtn, StyledDetailsButton } from './styles'

interface CampaignCardEndedButtonProps {
  platformType: CampaignPlatformTypeEnum
  onRelaunchCampaign: () => void
  onViewResults: () => void
  type: CampaignTypeEnum
  relaunched?: boolean
}

export const CampaignCardEndedButton: FC<CampaignCardEndedButtonProps> = (
  props,
) => {
  const { type, relaunched, platformType, onRelaunchCampaign, onViewResults } =
    props
  const isRelaunchAvailable =
    type !== CampaignTypeEnum.RELAUNCHED && !relaunched
  if (platformType === CampaignPlatformTypeEnum.SPOTIFY) {
    return (
      <StyledBtn>
        <CardButtonWrapper>
          {isRelaunchAvailable ? (
            <>
              <RelaunchButton onRelaunchCampaign={onRelaunchCampaign} />
              <StyledDetailsButton onClick={onViewResults}>
                <DotsIcon />
              </StyledDetailsButton>
            </>
          ) : (
            <DetailsButton onViewResults={onViewResults} />
          )}
        </CardButtonWrapper>
      </StyledBtn>
    )
  }
  return (
    <StyledBtn>
      <CardButtonWrapper>
        <DetailsButton onViewResults={onViewResults} />
      </CardButtonWrapper>
    </StyledBtn>
  )
}
