import styled from 'styled-components'

import { variables, colors } from 'src/Styled/variables'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  padding: 40px 15px;
`
export const TextWrapper = styled.div`
  text-align: center;
  font-size: ${variables.fontSize18};
  color: ${colors.mainText};
  line-height: 150%;
`
