import { FC } from 'react'

import styled from 'styled-components'

import { MAX_MOBILE_WIDTH } from 'src/Styled/variables'

import { AlertsBlock } from './CampaignList/AlertsBlock'
import { CampaignListHeader } from './CampaignListHeader'
import { useCampaigns } from './useCampaigns'
import { CampaignList } from './CampaignList'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;

  @media (min-width: ${MAX_MOBILE_WIDTH}) {
    max-width: 1120px;
    padding: 40px;

    align-items: flex-start;
    align-self: stretch;
    border-radius: 16px;
    border: 1px solid ${(props) => props.theme.common.primaryBorder};
    background-color: ${(props) => props.theme.common.primaryBg};
    box-shadow: 0px 0px 32px 0px rgba(51, 51, 51, 0.08);
  }
`

export const CampaignsListPage: FC = () => {
  const { loading, campaigns } = useCampaigns()
  return (
    <Container>
      <CampaignListHeader loading={loading} />
      <AlertsBlock loading={loading} />
      <CampaignList campaigns={campaigns} loading={loading} />
    </Container>
  )
}
