import { FC, SVGProps } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import track from 'src/Assets/Png/track.png'
import { DottedBtn } from 'src/Components/DottedBtn/DottedBtn'
import { convertTimeHandler } from 'src/Constants/functions'
import { CampaignPlatformTypeEnum, CampaignTypeEnum } from 'src/Types'
import { TrackAudioPlayer } from 'src/Components/TrackAudioPlayer'
import { TrackCoverWrapper } from 'src/Components/TrackAudioPlayer/TrackCoverWrapper'
import { useAppSelector } from 'src/Hooks/redux'
import { getUserId } from 'src/Redux/auth-process/userSlice/selectors'
import { getUserRole } from 'src/Redux/auth-process/credentialsSlice/selectors'
import { onPlayAddedTrack } from 'src/Helpers/TagManager/TrackEvent'

import {
  CenteredWrapper,
  Container,
  Platform,
  TrackBlock,
  TrackBlockLeft,
  TrackDescription,
  TrackDuration,
  TrackImg,
  TrackText,
  TrackTitle,
} from './styles'

interface TrackCardProps {
  title: string
  trackCoverUrl?: string
  trackTitle?: string
  trackAuthorName?: string
  trackPlayUrl: string
  trackDuration?: number
  Icon: FC<SVGProps<SVGSVGElement>>
  onChangeTrack: () => void
  platform: CampaignPlatformTypeEnum
}
const StyledTrackCoverWrapper = styled(TrackCoverWrapper)`
  margin-right: 0;
  height: fit-content;
`
export const TrackCard: FC<TrackCardProps> = (props) => {
  const { t } = useTranslation()
  const userId = useAppSelector(getUserId)
  const role = useAppSelector(getUserRole)
  const {
    trackTitle,
    trackCoverUrl,
    trackAuthorName,
    title,
    trackDuration,
    trackPlayUrl,
    Icon,
    onChangeTrack,
    platform,
  } = props
  const handleClick = (): void => {
    onPlayAddedTrack(CampaignTypeEnum.BASIC, role, platform, userId)
  }
  return (
    <Container>
      <Platform>
        <Icon width={24} height={24} />
        {title}
      </Platform>
      <TrackBlock>
        <TrackBlockLeft>
          {trackPlayUrl && (
            <TrackAudioPlayer
              render={({ loading, currentTrackUrl, isPlaying, onClick }) => (
                <StyledTrackCoverWrapper
                  iconSize={'25px'}
                  loaderSize='50px'
                  isPlaying={isPlaying && currentTrackUrl === trackPlayUrl}
                  loading={loading && currentTrackUrl === trackPlayUrl}
                  onClick={(e) => {
                    onClick(e, trackPlayUrl)
                    if (!isPlaying) {
                      handleClick()
                    }
                  }}
                >
                  <TrackImg
                    src={trackCoverUrl}
                    alt={trackTitle}
                    fallbackSrc={track}
                    width='64'
                  />
                </StyledTrackCoverWrapper>
              )}
            />
          )}
          {!trackPlayUrl && (
            <TrackImg
              src={trackCoverUrl}
              alt={trackTitle}
              fallbackSrc={track}
              width='64'
            />
          )}

          <TrackText>
            <TrackTitle>{trackTitle}</TrackTitle>
            <TrackDescription>{trackAuthorName}</TrackDescription>
            <TrackDuration>
              {convertTimeHandler({
                value: trackDuration || 0,
                type:
                  platform === CampaignPlatformTypeEnum.SPOTIFY ? 'ms' : 's',
              })}
            </TrackDuration>
          </TrackText>
        </TrackBlockLeft>
        <CenteredWrapper>
          <DottedBtn
            content={t('createCampaignPage.changeTrack')}
            onClick={onChangeTrack}
          />
        </CenteredWrapper>
      </TrackBlock>
    </Container>
  )
}
