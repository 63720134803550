import { createSlice } from '@reduxjs/toolkit'

import { ApiError, NameSpace, SliceStatus } from 'src/Redux/types'
import { Curator } from 'src/Types'
import { setApiError } from 'src/Hooks/redux'

import { getCurators } from './api-actions'

export interface CuratorListState {
  curators: Array<Curator>
  totalCurators: number
  status: SliceStatus
  error: ApiError | null
}

const initialState: CuratorListState = {
  curators: [],
  status: SliceStatus.Idle,
  totalCurators: 0,
  error: null,
}

export const curatorListSlice = createSlice({
  initialState,
  name: NameSpace.CuratorList,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getCurators.pending, (state) => {
      state.status = SliceStatus.Loading
    })
    builder.addCase(getCurators.fulfilled, (state, { payload }) => {
      state.status = SliceStatus.Succeeded
      state.curators = payload.curators
      state.totalCurators = payload.usersCount.totalCurators
      state.error = null
    })
    builder.addCase(getCurators.rejected, (state, action) => {
      state.status = SliceStatus.Failed
      state.error = setApiError(action)
    })
  },
})
