import styled from 'styled-components'

import { variables, colors, device } from 'src/Styled/variables'

export const CreatorDetailsModalContent = styled.div`
  @media ${device.mobileMin} {
    padding-top: 60px;
    max-width: 600px;
    margin: 0 auto;
    border-radius: 4px;
  }
`

export const RulesWrapper = styled.div`
  margin-bottom: 38px;
`

export const TitleWrapper = styled.div`
  display: flex;
  padding-left: 8px;
  margin-bottom: 38px;
  & button {
    background-color: transparent;
    margin-right: 14px;
  }
  @media ${device.mobileMin} {
    margin-bottom: 36px;
  }
`

export const TitleDetailsModal = styled.div`
  color: ${colors.mainText};
  font-weight: ${variables.fontWeight600};
  font-size: ${variables.fontSize18};
  line-height: 150%;
  text-align: left;

  @media ${device.mobileMin} {
    font-weight: ${variables.fontWeight600};
    font-size: ${variables.fontSize18};
    line-height: 27px;
    margin-bottom: 10.5px;
  }
`
export const AttentionText = styled.span`
  color: ${colors.messegesRed};
  font-weight: ${variables.fontWeight600};
  font-size: ${variables.fontSize14};
`
export const MainText = styled.span`
  color: ${colors.mainText};
  font-weight: ${variables.fontWeight400};
  font-size: ${variables.fontSize14};
`
export const AttentionWrapper = styled.div`
  margin-top: 16px;
  line-height: 21px;
`
export const SuccessfullyMessageWrapper = styled.div`
  padding-top: 35%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: ${colors.mainText};
  font-weight: ${variables.fontWeight400};
  font-size: ${variables.fontSize20};
  line-height: 150%;

  @media ${device.mobileMax} {
    flex-direction: column;
    padding-top: 55%;
  }
  & > span {
    text-align: center;
    font-size: ${variables.fontSize18};
  }
`
export const SuccessIcon = styled.img<{ mobile?: boolean }>`
  margin-right: 16px;
  margin-bottom: ${({ mobile }) => (mobile ? '16px' : '0px')};
  width: 40px;
  height: 40px;
  @media ${device.mobileMax} {
    margin-bottom: 18px;
  }
`
export const UnderlinedText = styled.span`
  text-decoration: underline;
`
export const AlertContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 25px;
  color: ${colors.mainText};
  @media ${device.mobileMin} {
    flex-direction: row;
    row-gap: unset;
    justify-content: space-between;
  }
`
