import { FC } from 'react'

import { RadioChangeEvent } from 'antd'
import { useTranslation } from 'react-i18next'

import { GenreOrLanguageItem } from 'src/Components/GenresList/GenreItem'
import { capitalizeFirstLetter } from 'src/Helpers/layoutHelpers'
import { Separator } from 'src/Components/GenresList/styles'
import { RadioAntd } from 'src/Containers/ui'
import { useHideLanguageMatchingExperiment } from 'src/Hooks/Experiments/useHideLanguageMatchingExperiment'

import { useLanguageRadioOptions } from '../helpers'
import { RadioTitle } from '../styles'

import { SelectedLanguage } from '.'

interface Props {
  selectedLanguage: SelectedLanguage | null
  targetLanguage?: number | null
  onClick: () => void
  onChange: (id: number) => void
}

export const SelectedLanguageComponent: FC<Props> = (props) => {
  const { t } = useTranslation()
  const { selectedLanguage, onClick, onChange, targetLanguage } = props
  const radioOptions = useLanguageRadioOptions()
  const { isHideLanguageExperiment } = useHideLanguageMatchingExperiment()

  const handleChange = (e: RadioChangeEvent): void => {
    onChange(Number(e.target.value))
  }
  return (
    <div>
      <GenreOrLanguageItem
        name={capitalizeFirstLetter(selectedLanguage?.label || '')}
        hasCross
        onClick={onClick}
      />
      {!isHideLanguageExperiment && (
        <>
          <Separator />
          <RadioTitle>
            {t('scheduleCampaignPage.selectTheTypeOfMatching')}
          </RadioTitle>
          <RadioAntd
            options={radioOptions}
            direction='vertical'
            gapSize='sm'
            defaultValue={String(targetLanguage)}
            onChange={handleChange}
          />
        </>
      )}
    </div>
  )
}
