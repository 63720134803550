import { FC } from 'react'

import {
  Control,
  Controller,
  FieldError,
  FieldErrors,
  get,
} from 'react-hook-form'
import { Select } from 'antd'

import { TransferwiseRequirementValuesAllowed } from 'src/Types/index'
import { SmartInput } from 'src/Components/SmartInput'

import { setSelectOptions } from '../helpers'
import {
  FormGroup,
  FormGroupField,
  FormGroupLabel,
  FormGroupSelect,
  RedText,
  Wrapper,
} from '../styles'

type InputType = 'radio' | 'select' | 'date' | 'text'

export type RenderInputProps = {
  handleSpecifyRequirements: (val: string, key: string) => void
  example: string
  propKey: string
  name: string
  valuesAllowed: TransferwiseRequirementValuesAllowed[] | null
  refreshRequirementsOnChange: boolean
  type: InputType
  error: FieldErrors<Record<string, string>>
  control: Control<Record<string, string>, Record<string, unknown>>
}

export const RenderInput: FC<RenderInputProps> = ({
  handleSpecifyRequirements,
  type,
  name,
  propKey,
  valuesAllowed,
  refreshRequirementsOnChange,
  example,
  error,
  control,
}) => {
  if (type === 'date' || type === 'text') {
    return (
      <Wrapper>
        <FormGroup>
          <FormGroupLabel>{name}</FormGroupLabel>
          <FormGroupField>
            <SmartInput
              {...control.register(propKey)}
              data-testid={propKey}
              error={get(error, propKey) as FieldError}
              placeholder={example}
              type='text'
            />
          </FormGroupField>
        </FormGroup>
      </Wrapper>
    )
  }

  if (type === 'radio' || type === 'select') {
    return (
      <Wrapper>
        <FormGroup>
          <FormGroupLabel>{name}</FormGroupLabel>
          <FormGroupSelect>
            <Controller
              name={propKey}
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  data-testid={propKey}
                  showSearch
                  placeholder={name}
                  status={get(error, propKey) ? 'error' : ''}
                  options={setSelectOptions(valuesAllowed || [])}
                  size='large'
                  onChange={(value: string) => {
                    field.onChange(value)
                    if (refreshRequirementsOnChange) {
                      handleSpecifyRequirements(value, propKey)
                    }
                  }}
                />
              )}
            />
          </FormGroupSelect>
        </FormGroup>
      </Wrapper>
    )
  }

  return <RedText>Something went wrong.</RedText>
}
