import { createDraftSafeSelector } from '@reduxjs/toolkit'

import { RootState } from 'src/Redux'

import { ArtistDeleteAccountState } from '.'

const getState = (state: RootState): ArtistDeleteAccountState =>
  state.settings.artistDeleteAccount

export const getErrorArtistDeleteAccount = createDraftSafeSelector(
  getState,
  (state) => state.error,
)
