import styled, { createGlobalStyle } from 'styled-components'

import { colors, device, variables } from 'src/Styled/variables'

export const GlobalStyled = createGlobalStyle`
 .ant-modal{
  transform-origin: unset;
}
.overlayClassName .ant-popover-arrow{
  display: none !important;
 }
 .overlayClassName .ant-popover-inner-content{
    padding: 24px;
  }
  .overlayClassName .ant-popover-inner {
    width: 240px;
  }
 .ant-modal-mask {
    background-color: rgba(0, 0, 0, 0.5) ;
  }
 .ant-modal {
    top: 25%;
  }
  
`

export const HintWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 9px;
  justify-content: space-between;
`
export const HintTitle = styled.span`
  color: #646877;
  @media ${device.mobileMax} {
    font-size: ${variables.fontSize14};
  }
`
export const ProgressContainer = styled.div<{ isFocused?: boolean }>`
  width: 100%;
  cursor: pointer;
  & > ${HintWrapper} > svg > path {
    fill: ${(props) =>
      props.isFocused ? colors.progressActive : colors.greyTextBlackBg};
  }
  &:hover {
    & > ${HintWrapper} > svg > path {
      fill: ${colors.progressActive};
    }
  }
  @media (max-width: 576px) {
    margin-bottom: 16px;
  }
`
export const ProgressInfoItem = styled.span`
  font-size: ${variables.fontSize10};
  color: ${colors.greyTextWhiteBg};
`
export const EndedStatusLabel = styled.span`
  display: block;
  color: ${colors.messegesRed};
  text-transform: capitalize;
`

export const EndedStatusReviews = styled.span`
  color: ${colors.greyTextWhiteBg};
`
