import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import { colors, variables } from 'src/Styled/variables'
import { ProblemsWithTikTokErrorAlert } from 'src/Components/ProblemsWithTikTokErrorAlert'
import {
  selectStoppedSoundsCount,
  useStoppedSoundsQuery,
} from 'src/Redux/creatorStoppedSoundsApi'
import { getInfluencerId } from 'src/Redux/auth-process/userSlice/selectors'
import { useAppSelector } from 'src/Hooks/redux'
import { useNewSoundsQuery } from 'src/Redux/creatorNewSoundsApi'
import { RoundedButton } from 'src/Components/Buttons/RoundButton'

import { TracksTable } from './components/TracksTable'
import { useSoundsPageAnalytics } from './useSoundsPageAnalytics'
import { WarningModal } from './components/WarningModal'
import { CampaignCanceledAlert } from './components/CampaignCanceledAlert'
import { ErrorModal } from './components/ErrorModal'
import { useRefreshData } from './hooks/useRefreshData'

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`
export const Title = styled.h2`
  margin: 0;
  padding: 0;
  color: ${colors.mainText};
  font-size: ${variables.fontSize20};
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
`

export const TitleWrapper = styled.div`
  height: 40px;
  display: flex;
  justify-content: space-between;
`

export const RefreshButtonWrapper = styled.div`
  @media screen and (min-width: 768px) {
    display: none;
  }
`

export const SoundsPage: FC = () => {
  const { t } = useTranslation()
  const { search } = useCustomSearchParams()
  const influencerId = useAppSelector(getInfluencerId)
  const isErrorModalVisible = search.get('errorModalVisible') === 'true'

  // eslint-disable-next-line no-undefined
  const { stoppedSoundsSoundsCount } = useStoppedSoundsQuery(undefined, {
    selectFromResult: selectStoppedSoundsCount,
  })

  const { isError } = useNewSoundsQuery({
    creatorId: influencerId,
    pageNumber: 1,
  })

  const isCampaignCanceledAlertVisible = stoppedSoundsSoundsCount > 0

  useSoundsPageAnalytics()

  const [onRefreshClick] = useRefreshData()

  return (
    <PageContainer>
      <TitleWrapper>
        <Title>{t('soundsPage.pageTitle')}</Title>
        <RefreshButtonWrapper>
          <RoundedButton onClick={onRefreshClick} />
        </RefreshButtonWrapper>
      </TitleWrapper>

      {isError && <ProblemsWithTikTokErrorAlert />}
      {isCampaignCanceledAlertVisible && <CampaignCanceledAlert />}
      {isErrorModalVisible && <ErrorModal />}

      <TracksTable />

      <WarningModal />
    </PageContainer>
  )
}
