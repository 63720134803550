import { PayloadAction, Middleware } from '@reduxjs/toolkit'

import browserHistory from 'src/Router/browser-history'

import rootReducer from '../root-reducer'

type Reducer = ReturnType<typeof rootReducer>

export const redirect: Middleware<unknown, Reducer> =
  () => (next) => (action: PayloadAction<string>) => {
    if (action.type.includes('redirectToRoute')) {
      browserHistory.push(action.payload)
    }

    return next(action)
  }
