import { FC, PropsWithChildren } from 'react'

import styled from 'styled-components'

import ErrorIcon from 'src/Assets/Svg/common/error.svg?react'
import { variables } from 'src/Styled/variables'

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
`

const Text = styled.span`
  font-family: 'Segoe UI';
  font-size: ${variables.fontSize14};
  font-style: normal;
  font-weight: ${variables.fontWeight400};
  line-height: 150%;
  color: ${({ theme }) => theme.common.dangerText};
`

type TrackSearchErrorProps = PropsWithChildren

export const TrackSearchError: FC<TrackSearchErrorProps> = ({ children }) => (
  <Container>
    <ErrorIcon />
    <Text>{children}</Text>
  </Container>
)
