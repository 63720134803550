import { FC } from 'react'

import { useLocation } from 'react-router-dom'

import { ALBUM_IMAGES } from 'src/Constants/constants'
import {
  CampaignPlatformTypeEnum,
  CampaignTypeEnum,
  SpotifyTrack,
} from 'src/Types'
import spotifyIcon from 'src/Assets/Png/spotify-icon.png'
import { onPlaySearchedTrack } from 'src/Helpers/TagManager/TrackEvent'
import { useAppSelector } from 'src/Hooks/redux'
import { getUserId } from 'src/Redux/auth-process/userSlice/selectors'
import { getUserRole } from 'src/Redux/auth-process/credentialsSlice/selectors'

import { TrackAudioPlayer } from '../TrackAudioPlayer'
import { TrackCoverWrapper } from '../TrackAudioPlayer/TrackCoverWrapper'

import { CheckTrackFromSearchProps } from './CheckTrackFromSearchProps'

import {
  SearchResultsItem,
  SearchResultsImg,
  SearchResultsText,
  SearchResultsTitle,
  SearchResultsDescription,
} from './styles'

type SearchResultSpotifyItemProps = Pick<
  CheckTrackFromSearchProps,
  'onCheckTrack' | 'tracks'
>

export const SearchResultSpotifyItem: FC<SearchResultSpotifyItemProps> = (
  props,
) => {
  const { tracks, onCheckTrack } = props
  const userId = useAppSelector(getUserId)
  const role = useAppSelector(getUserRole)
  const { pathname } = useLocation()
  const handleEvent = (): void => {
    const campaignType = pathname.includes('campaigns')
      ? CampaignTypeEnum.BASIC
      : CampaignTypeEnum.FIRST
    onPlaySearchedTrack(
      campaignType,
      role,
      CampaignPlatformTypeEnum.SPOTIFY,
      userId,
    )
  }
  return (
    <TrackAudioPlayer
      render={({ isPlaying, loading, onClick, currentTrackUrl }) => (
        <>
          {((tracks as SpotifyTrack[]) || []).map((item) => (
            <SearchResultsItem
              key={item.id}
              onClick={() => onCheckTrack(item.id)}
            >
              <TrackCoverWrapper
                show={Boolean(item.preview_url)}
                isPlaying={isPlaying && currentTrackUrl === item.preview_url}
                loading={loading && currentTrackUrl === item.preview_url}
                onClick={(e) => {
                  onClick(e, item.preview_url as string)
                  if (!isPlaying) {
                    handleEvent()
                  }
                }}
              >
                <SearchResultsImg
                  src={item.album.images[ALBUM_IMAGES]?.url}
                  fallbackSrc={spotifyIcon}
                  alt={item.name}
                  width='40'
                />
              </TrackCoverWrapper>

              <SearchResultsText>
                <SearchResultsTitle>{item?.name}</SearchResultsTitle>
                <SearchResultsDescription>
                  {item?.artists[0]?.name}
                </SearchResultsDescription>
              </SearchResultsText>
            </SearchResultsItem>
          ))}
        </>
      )}
    />
  )
}
