import { FunctionComponent } from 'react'

import { Col, Row } from 'antd'
import { useTranslation } from 'react-i18next'

import { ReferAndEarnTitle } from './styles'

export const PageTitle: FunctionComponent = () => {
  const { t } = useTranslation()

  return (
    <Col lg={12} md={12} xl={12}>
      <Row>
        <Col md={12}>
          <ReferAndEarnTitle>
            {t('referAndEarn.referAndEarn')}
          </ReferAndEarnTitle>
        </Col>
      </Row>
    </Col>
  )
}
