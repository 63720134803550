import React, { useEffect } from 'react'

import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { message } from 'antd'

import { login } from 'src/Router/routes'
import { FullWideNotification } from 'src/Components/FullWideNotification'
import { colors } from 'src/Styled/variables'
import { setLoginTypeToInitialState } from 'src/Redux/auth-process/authTypeSlice'
import { useAppDispatch } from 'src/Hooks/redux'
import { getAuthType } from 'src/Redux/auth-process/authTypeSlice/selectors'

const DELAY = 3000

export const useLogin = (): {
  contextHolder: JSX.Element
} => {
  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const authType = useSelector(getAuthType)
  const [messageApi, contextHolder] = message.useMessage()

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    if (location.pathname !== login) {
      navigate({
        pathname: 'login',
        search: searchParams.toString(),
      })
    }
  }, [navigate, location])

  useEffect(() => {
    if (location.search.includes('blacklist')) {
      navigate(login)
      void messageApi.open({
        content: (
          <FullWideNotification
            message={t('login.authErrorBlacklistRegistered')}
          />
        ),
        duration: 3,
        style: { backgroundColor: colors.badgeRed },
      })
    }
    return () => {
      dispatch(setLoginTypeToInitialState())
    }
  }, [location, messageApi, navigate, t, dispatch])

  useEffect(() => {
    let id: ReturnType<typeof setTimeout>
    if (authType === 'userIsNotExist') {
      id = setTimeout(() => {
        dispatch(setLoginTypeToInitialState())
      }, DELAY)
    }
    return () => {
      clearTimeout(id)
    }
  }, [authType, dispatch])

  return { contextHolder }
}
