import { useMemo } from 'react'

import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'

import { addSpaceToNumber } from 'src/Helpers/layoutHelpers'
import { LoaderWrapper } from 'src/Components/InfinityScrollWrapper/styled'
import InfinityLoader from 'src/Components/InfinityLoader'
import { PremoderationStatus } from 'src/Constants/enums'
import { useGetCampaignTiktokDetailsQuery } from 'src/Redux/campaignTiktokDetailsApi'
import {
  useGetCampaignAwaitingVideosQuery,
  useGetCampaignVideosQuery,
  useGetCampaignVideosStatsQuery,
  useLazyGetCampaignAwaitingVideosQuery,
  useLazyGetCampaignVideosQuery,
} from 'src/Redux/campaignVideosApi'

import { EmptyListItem } from './components/EmptyListItem'
import { VideoItem } from './components/VideoItem'

import { StyledList, StyledNumber, TabName } from './styles'

export const useTikTokCampaignDetails = (): {
  valuesBlocks: { title: string; value: string | number; hintText?: string }[]
  videoTableItems: { label: JSX.Element; key: string; children: JSX.Element }[]
  isMonitoringVideosAvailable: boolean
} => {
  const { t } = useTranslation()
  const { campaignID } = useParams()
  const [getNextCampaignVideos] = useLazyGetCampaignVideosQuery()
  const [getNextCampaignAwaitingVideos] =
    useLazyGetCampaignAwaitingVideosQuery()
  const { data: allVideos, isFetching: isAllVideosFetching } =
    useGetCampaignVideosQuery(
      {
        campaignId: campaignID,
        pageNumber: 1,
      },
      {
        skip: !campaignID,
        refetchOnMountOrArgChange: true,
      },
    )
  const { data: awaitingVideos, isFetching: isAwaitingVideosFetching } =
    useGetCampaignAwaitingVideosQuery(
      {
        campaignId: campaignID,
        pageNumber: 1,
      },
      {
        skip: !campaignID,
        refetchOnMountOrArgChange: true,
      },
    )
  const { data: campaignDetails } = useGetCampaignTiktokDetailsQuery(campaignID)
  const { data: videosCounts } = useGetCampaignVideosStatsQuery(campaignID, {
    skip: !campaignID,
  })

  const loadMoreAllVideos = (): void => {
    if (campaignID && allVideos?.meta.pageNumber) {
      getNextCampaignVideos({
        campaignId: campaignID,
        pageNumber: allVideos?.meta.pageNumber + 1,
      })
    }
  }

  const loadMoreAwaitingVideos = (): void => {
    if (campaignID && awaitingVideos?.meta.pageNumber) {
      getNextCampaignAwaitingVideos({
        campaignId: campaignID,
        pageNumber: awaitingVideos?.meta.pageNumber + 1,
      })
    }
  }

  const isMonitoringVideosAvailable = Boolean(
    allVideos?.videos?.find(
      (video) => video.preModerationStatus === PremoderationStatus.MONITORING,
    ),
  )

  const getStatsValue = (value: number | undefined): string | number =>
    value ? addSpaceToNumber(value, ',') : 0

  const valuesBlocks = useMemo(
    () => [
      {
        title: t('campaignResultsPage.totalViews'),
        value: getStatsValue(campaignDetails?.stats?.totalViews),
      },
      {
        title: t('campaignResultsPage.totalLikes'),
        value: getStatsValue(campaignDetails?.stats?.totalLikes),
      },
      {
        title: t('campaignResultsPage.comments'),
        value: getStatsValue(campaignDetails?.stats?.totalComments),
      },
      {
        title: t('campaignResultsPage.videoPublished'),
        value: getStatsValue(campaignDetails?.stats?.totalVideos),
        hintText: t('campaignResultsPage.videosValueHint'),
      },
    ],
    [campaignDetails, t],
  )

  const videosTabs = useMemo(
    () => [
      {
        group: 'all',
        title: t('campaignResultsPage.all'),
        count: videosCounts?.all ?? 0,
      },
      {
        group: 'awaiting',
        title: t('campaignResultsPage.awaitingYourRating'),
        count: videosCounts?.awaiting ?? 0,
      },
    ],
    [t, videosCounts?.all, videosCounts?.awaiting],
  )

  const videoTableItems = videosTabs?.map((tab) => ({
    label: (
      <>
        <TabName>{tab.title}</TabName>
        <StyledNumber
          isGreen={Boolean(tab.group === 'awaiting' && tab.count >= 1)}
        >
          {tab.count}
        </StyledNumber>
      </>
    ),
    key: tab.group,
    children: (
      <StyledList>
        {tab.group === 'all' &&
          (allVideos?.videos?.length === 0 ? (
            <EmptyListItem />
          ) : (
            <InfiniteScroll
              dataLength={allVideos?.videos?.length || 0}
              next={loadMoreAllVideos}
              hasMore={allVideos?.meta?.hasNextPage || false}
              loader={
                <LoaderWrapper>
                  <InfinityLoader isLoading={isAllVideosFetching} />
                </LoaderWrapper>
              }
            >
              {allVideos?.videos.map((item) => (
                <VideoItem key={item.id} video={item} />
              ))}
            </InfiniteScroll>
          ))}

        {tab.group === 'awaiting' &&
          (awaitingVideos?.videos.length === 0 ? (
            <EmptyListItem />
          ) : (
            <InfiniteScroll
              dataLength={awaitingVideos?.videos?.length || 0}
              next={loadMoreAwaitingVideos}
              hasMore={awaitingVideos?.meta?.hasNextPage || false}
              loader={
                <LoaderWrapper>
                  <InfinityLoader isLoading={isAwaitingVideosFetching} />
                </LoaderWrapper>
              }
            >
              {awaitingVideos?.videos.map((item) => (
                <VideoItem key={item.id} video={item} />
              ))}
            </InfiniteScroll>
          ))}
      </StyledList>
    ),
  }))

  return {
    valuesBlocks,
    videoTableItems,
    isMonitoringVideosAvailable,
  }
}
