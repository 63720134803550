import { FC, useMemo } from 'react'

import { useTranslation } from 'react-i18next'

import { numberFormatSpaces } from 'src/Constants/functions'
import { Curator } from 'src/Types'
import avatar from 'src/Assets/Png/ava.png'
import {
  capitalizeFirstLetter,
  getValidNumber,
} from 'src/Helpers/layoutHelpers'
import { Rating } from 'src/Components/Rating/Rating'

import {
  AuthorContainer,
  AvatarContainer,
  AuthorInfoContainer,
  AuthorName,
  RatingContainer,
  AuthorStatsContainer,
  Avatar,
} from '../styles'

interface AuthorProps {
  isMissed?: boolean
  curator?: Curator
}

export const Author: FC<AuthorProps> = ({ isMissed, curator }) => {
  const { t } = useTranslation()

  const playlistsLabel =
    Number(curator?.playlists_count) !== 1
      ? t('campaignResultsPage.playlistsLabel').toLowerCase()
      : t('campaignResultsPage.playlistLabel').toLowerCase()

  const followersLabel =
    Number(curator?.followers_count) !== 1
      ? t('campaignResultsPage.followers').toLowerCase()
      : t('campaignResultsPage.follower').toLowerCase()

  const curatorName = useMemo(() => {
    if (isMissed || !curator) {
      return t('campaignResultsPage.unknownCurator')
    }
    return `${capitalizeFirstLetter(curator?.User?.first_name)} ${
      curator?.User?.last_name
        ? capitalizeFirstLetter(curator.User.last_name)
        : ''
    }`
  }, [isMissed, curator, t])

  return (
    <AuthorContainer>
      <AvatarContainer>
        <Avatar
          alt='avatar'
          src={curator?.User?.user_photo_url}
          fallbackSrc={avatar}
        />
      </AvatarContainer>
      <AuthorInfoContainer>
        <AuthorName>{curatorName}</AuthorName>
        <RatingContainer>
          <Rating rating={curator ? curator?.rating : 0} />
        </RatingContainer>
        {!isMissed && (
          <AuthorStatsContainer>
            {`${getValidNumber(
              curator?.playlists_count,
            )} ${playlistsLabel}, ${numberFormatSpaces(
              Number(curator?.followers_count),
            )} ${followersLabel}`}
          </AuthorStatsContainer>
        )}
      </AuthorInfoContainer>
    </AuthorContainer>
  )
}
