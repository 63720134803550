import React, { FC, useState } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'

import { HintGuide } from 'src/Components/HintGuide/HintGuide'
import { colors, device, variables } from 'src/Styled/variables'
import { styledSelectCustomStyles } from 'src/Styled/StyledSelectCustomStyles'
import Warning from 'src/Assets/Svg/warning.svg?react'

import GenresSelectionGuideModal from '../GenresSelectionGuideModal'

import { SelectOption } from './props'

import { StyledSelect } from './styles'

const GenresTitle = styled.h5`
  font-size: ${variables.fontSize14};
  color: ${colors.mainText};
  font-weight: ${variables.fontWeight400};
  margin-bottom: 13px;
  display: flex;
  justify-content: space-between;
`

const DesktopBeCarefulWrapper = styled.div`
  margin-bottom: 26px;
  display: flex;
  flex-direction: row;
`
export const BeCarefulMobileImg = styled.div`
  margin-right: 8px;
`
export const BeCarefulMobileText = styled.div`
  color: ${colors.messegesYellow1};
`

interface PlaylistGenresSelectProps {
  selectedInputGenres: SelectOption[]
  onChangeSelectGenres: (value: unknown) => void
  hintName: string | null
  onSetHint: (hintName: string) => void
}

export const PlaylistGenresSelect: FC<PlaylistGenresSelectProps> = ({
  onChangeSelectGenres,
  selectedInputGenres,
  hintName,
  onSetHint,
}) => {
  const isMobile = useMediaQuery({ query: device.mobileMax })
  const { t } = useTranslation()
  const [modal, setModalValue] = useState<boolean | string>('')

  const handleHint = (name: string): void => {
    if (isMobile) {
      setModalValue(name)
    } else {
      onSetHint(name)
    }
  }

  const notEnoughGenres = Number(selectedInputGenres.length) < 2
  return (
    <>
      <GenresTitle>
        <div>{t('scheduleCampaignPage.genresSelectedLabel')}</div>
        <HintGuide
          hintName='hintGenres'
          setHintName={handleHint}
          currentHint={hintName}
        />
        <GenresSelectionGuideModal
          isOpen={Boolean(modal)}
          toggle={setModalValue}
        />
      </GenresTitle>
      <StyledSelect
        isMulti
        styles={styledSelectCustomStyles}
        classNamePrefix='genres'
        closeMenuOnSelect={false}
        isSearchable={false}
        menuIsOpen={false}
        placeholder={false}
        value={selectedInputGenres}
        onChange={onChangeSelectGenres}
      />
      {notEnoughGenres && (
        <DesktopBeCarefulWrapper>
          <BeCarefulMobileImg>
            <Warning width={16} height={16} />
          </BeCarefulMobileImg>

          <BeCarefulMobileText>
            {t('scheduleCampaignPage.minimum2GenresAreRequired')}
          </BeCarefulMobileText>
        </DesktopBeCarefulWrapper>
      )}
    </>
  )
}
