import TagManager from 'react-gtm-module'

import { User } from 'src/Types'

import { EventNameEnum, EventTypeEnum } from '../tagManagerEnums'

export const payoutRequestEvent = (user: User, amount: number): void => {
  TagManager.dataLayer({
    dataLayer: {
      'user-id': user.curatorId,
      'User-first-name': user.first_name,
      'registration-date': user.createdAt,
      'account-type': 'curator',
      'payout-value': amount,
      event: EventTypeEnum.PayoutRequest,
    },
  })
}

export const payoutRequestedEvent = (
  payoutAmount: number,
  payoutRequestId?: number,
  userId?: string,
): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventTypeEnum.PayoutRequested,
      event_name: EventNameEnum.PayoutRequested,
      'account-type': 'curator',
      ...(userId ? { userID: userId } : {}),
      payout_amount: payoutAmount,
      ...(payoutRequestId ? { payout_request_id: payoutRequestId } : {}),
    },
  })
}
