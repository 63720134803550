import { useEffect } from 'react'

import { useAppDispatch } from 'src/Hooks/redux'
import { getLevelsWithPlaylists } from 'src/Redux/levels-procces/api-actions'

export const useLevels = (): void => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    void dispatch(getLevelsWithPlaylists())
  }, [dispatch])
}
