import { createSlice } from '@reduxjs/toolkit'

import { setApiError } from 'src/Hooks/redux'
import { ApiError, NameSpace, SliceStatus } from 'src/Redux/types'

import { changeArtistPassword } from './api-actions'

export interface ArtistPasswordState {
  status: SliceStatus
  error: ApiError | null
}

const initialState: ArtistPasswordState = {
  status: SliceStatus.Idle,
  error: null,
}

export const artistPasswordSlice = createSlice({
  initialState,
  name: NameSpace.ArtistPassword,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(changeArtistPassword.pending, (state) => {
      state.status = SliceStatus.Loading
      state.error = null
    })
    builder.addCase(changeArtistPassword.fulfilled, (state) => {
      state.status = SliceStatus.Succeeded
    })
    builder.addCase(changeArtistPassword.rejected, (state, action) => {
      state.status = SliceStatus.Failed
      state.error = setApiError(action)
    })
  },
})
