import { FunctionComponent, useState } from 'react'

import { useNewSoundsQuery } from 'src/Redux/creatorNewSoundsApi'
import { useAppSelector } from 'src/Hooks/redux'
import { getUserSelector } from 'src/Redux/auth-process/userSlice/selectors'
import { useGetMonetizationDataQuery } from 'src/Redux/monetizationApi'

import { SoundsList } from '../index'
import { NewSoundsListItem } from '../../SoundsListItem/components/NewSoundsListItem'

export const NewSoundsList: FunctionComponent = () => {
  const user = useAppSelector(getUserSelector)
  const [page, setPage] = useState(1)

  const {
    data: newSoundsData,
    isLoading,
    isFetching,
    isError,
  } = useNewSoundsQuery({
    creatorId: user.influencerId,
    pageNumber: page,
  })

  const { data: monetizationData } = useGetMonetizationDataQuery()

  const newSounds = isError ? [] : newSoundsData?.data || []
  const hasNextPage = newSoundsData?.meta.hasNextPage || false

  const loadMore = (): void => {
    setPage(page + 1)
  }

  return (
    <SoundsList
      hasNextPage={hasNextPage}
      isLoading={isLoading || isFetching}
      onLoadMore={loadMore}
      soundsAmount={newSounds.length}
    >
      {newSounds.map((item) => (
        <NewSoundsListItem
          key={item.id}
          campaignId={item.id}
          trackAuthorName={item.trackAuthorName}
          trackCoverUrl={item.trackCoverUrl}
          trackTiktokId={item.trackTiktokId}
          trackTitle={item.trackTitle}
          maxCreatorReward={monetizationData?.maxCreatorReward}
        />
      ))}
    </SoundsList>
  )
}
