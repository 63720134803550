import React, { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import dayjs from 'src/Helpers/dayjs'
import { formatDate } from 'src/Constants/formatDate'

import {
  MobileBlockWrapper,
  NormalText,
  SmallText,
  ValuesContainer,
} from '../../styles'

type DeadlineInfoProps = {
  deadline: string
}

export const DeadlineValuesContainer = styled(ValuesContainer)`
  max-width: 125px;
`

export const DeadlineInfo: FunctionComponent<DeadlineInfoProps> = ({
  deadline,
}) => {
  const { t } = useTranslation()

  const formattedDeadline = dayjs(deadline).format(formatDate.formatDateFirst2)

  return (
    <MobileBlockWrapper>
      <DeadlineValuesContainer>
        <SmallText>{t('soundsPage.deadline')}</SmallText>
        <NormalText>{formattedDeadline}</NormalText>
      </DeadlineValuesContainer>
    </MobileBlockWrapper>
  )
}
