import { FC, useMemo } from 'react'

import { useSelector } from 'react-redux'

import avatar from 'src/Assets/Png/ava.png'
import { SmartImage } from 'src/Components/SmartImage'
import { getNotifications } from 'src/Redux/notification-process/selectors'
import { getIsArtist } from 'src/Redux/auth-process/credentialsSlice/selectors'
import { useGetNewReleaseQuery } from 'src/Redux/newReleaseApi'

import { ArtistNotification } from '../ArtistNotification'
import { HaveNotNotifications } from '../HaveNotNotifications'
import { OtherNotification } from '../OtherNotification'

import { TopBarNewRelease } from './TopBarNewRelease'

import {
  NotificationsItemCurator,
  ImageCurator,
  NotificationsWrapper,
} from './styles'

const MAX_DISPLAYED_NOTIFICATIONS = 4

interface TopBarNewReleaseProps {
  handleCreateAndStartSchedule: () => void
}
export const TopBarNotificationContent: FC<TopBarNewReleaseProps> = ({
  handleCreateAndStartSchedule,
}) => {
  const isArtist = useSelector(getIsArtist)
  const notifications = useSelector(getNotifications)
  const { data: releaseData } = useGetNewReleaseQuery(null, {
    refetchOnMountOrArgChange: true,
    skip: !isArtist,
  })

  const sortedNotifications = useMemo(() => {
    const releaseNotification = releaseData
      ? [
          {
            id: 'new-release',
            date: releaseData.releaseDate,
            isRelease: true,
            trackName: releaseData.trackName,
            artistName: releaseData.artistName,
          },
        ]
      : []

    return [...notifications, ...releaseNotification].sort(
      (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime(),
    )
  }, [notifications, releaseData])

  if (!sortedNotifications.length) {
    return (
      <>
        <TopBarNewRelease
          handleCreateAndStartSchedule={handleCreateAndStartSchedule}
        />
        <HaveNotNotifications />
      </>
    )
  }

  const isSmallNotificationsContainer = sortedNotifications.length
    ? sortedNotifications.length < MAX_DISPLAYED_NOTIFICATIONS
    : false

  if (isArtist) {
    return (
      <NotificationsWrapper
        isSmallNotificationsContainer={isSmallNotificationsContainer}
      >
        {sortedNotifications.map((notification) =>
          notification.isRelease ? (
            <TopBarNewRelease
              key={notification.id}
              handleCreateAndStartSchedule={handleCreateAndStartSchedule}
            />
          ) : (
            <ArtistNotification
              key={notification.id}
              firstName={notification.Playlist?.Curator?.first_name}
              lastName={notification.Playlist?.Curator?.last_name}
              photo={notification.Playlist?.Curator?.user_photo_url}
              date={notification.date}
              playlistName={notification.Playlist?.playlist_name}
              playlistUrl={notification.Playlist?.playlist_url}
            />
          ),
        )}
      </NotificationsWrapper>
    )
  }

  return (
    <NotificationsWrapper
      isSmallNotificationsContainer={isSmallNotificationsContainer}
    >
      {sortedNotifications.map((notification) =>
        notification.isRelease ? (
          <TopBarNewRelease
            key={notification.id}
            handleCreateAndStartSchedule={handleCreateAndStartSchedule}
          />
        ) : (
          <NotificationsItemCurator key={notification.id}>
            <ImageCurator>
              <SmartImage src={avatar} alt='avatar' fallbackSrc={avatar} />
            </ImageCurator>
            <OtherNotification {...notification} />
          </NotificationsItemCurator>
        ),
      )}
    </NotificationsWrapper>
  )
}
