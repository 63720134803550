import styled from 'styled-components'
import { Link } from 'react-router-dom'

import ArrowTotalBtnDown from 'src/Assets/Svg/arrowTotalDown.svg?react'
import { variables, device, colors } from 'src/Styled/variables'

export const FaqsHeader = styled.h3`
  font-style: normal;
  color: ${colors.mainText};
  font-weight: ${variables.fontWeight600};
  font-size: ${variables.fontSize18};
  line-height: 150%;
  margin-bottom: 24px;
  margin-top: 79px;
  padding-left: 24px;

  @media ${device.mobileMin} {
    margin-top: 118px;
  }
`

export const FaqsList = styled.ul`
  padding: 0px;
  list-style-type: none;
  list-style: none;
  margin-bottom: 40px;
  display: grid;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
  white-space: break-spaces;
`

interface Props {
  isActive?: boolean
  active?: string
}

export const FaqsItem = styled.li<Props>`
  height: fit-content;
  display: inline-block;
  align-items: center;
  transition: transform 0.5s ease-in-out;
  opacity: 1;
  cursor: pointer;

  @media ${device.tabletMin} {
    &:hover {
      background-color: ${(props) =>
        props.isActive ? 'transparent' : 'rgba(100, 104, 119, 0.05)'};
      transition: transform 0.5s ease-in-out;
    }
  }

  &.nonactive {
    opacity: 0;
    display: none;
  }
`

export const FaqsTitle = styled.div`
  color: ${colors.mainText};
  font-weight: ${variables.fontWeight600};
  font-size: ${variables.fontSize14};
  line-height: 150%;
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 16px 24px 16px 24px;
`

export const FaqsAnswersList = styled.div`
  list-style-type: none;
  padding: 0 0 0 24px;
`
export const FaqsAnswersItem = styled.div`
  > span:nth-child(2) {
    text-decoration: underline;
    padding-left: 4px;
    padding-right: 4px;
  }
`

export const FaqsText = styled.div`
  color: ${colors.mainText};
  font-weight: ${variables.fontWeight400};
  font-size: ${variables.fontSize12};
  line-height: 150%;
  margin-bottom: 16px;
  transition: transform 0.5s ease-in-out;
`

export const StyledArrowTotalBtnDown = styled(ArrowTotalBtnDown)<Props>`
  transform: rotate(
    ${(props) => (props.active === 'true' ? '180deg' : '0deg')}
  );
  transition: transform 0.5s ease-in-out;
`

export const StyledDottedBtn = styled.button`
  font-style: normal;
  background-color: transparent;
  font-weight: ${variables.fontWeight400};
  font-size: ${variables.fontSize14};
  line-height: 14px;
  padding-left: 0;
  padding-right: 0;
  color: ${colors.mainText};
  cursor: pointer;
  &:hover,
  &:visited {
    color: ${colors.mainText};
  }
  padding-bottom: 2px;
  border-bottom: 1px dashed ${colors.mainText};
  margin: 0 auto;
  margin-bottom: 80px;
`

export const StyledDottedLink = styled(Link)`
  font-style: normal;
  font-weight: ${variables.fontWeight400};
  font-size: ${variables.fontSize14};
  line-height: 14px;
  color: ${colors.mainText};
  &:hover,
  &:visited {
    color: ${colors.mainText};
  }
  padding-bottom: 2px;
  border-bottom: 1px dashed ${colors.mainText};
  margin: 0 auto;
  margin-bottom: 80px;
`
export const FaqsButton = styled.div`
  display: grid;
  line-height: 150%;
`
