import React, { FC, useState } from 'react'

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { PrimaryDottedButton } from 'src/Components/Buttons/PrimaryDottedButton'

import { ScheduleSummaryProps } from '..'

import { ScheduleSummaryPromocodePopup } from './ScheduleSummaryPromocodePopup'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
`
const StyledPrimaryDottedButton = styled(PrimaryDottedButton)`
  padding-left: 0;
`

type Props = Pick<ScheduleSummaryProps, 'onApplyPromocode'>

export const ScheduleSummaryPromocode: FC<Props> = ({ onApplyPromocode }) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  return (
    <Container>
      <ScheduleSummaryPromocodePopup
        open={open}
        onCancel={() => setOpen(false)}
        onClose={() => setOpen(false)}
        onApplyPromocode={(values) =>
          onApplyPromocode(values).then((data) => {
            setOpen(false)
            return data
          })
        }
      />
      <StyledPrimaryDottedButton onClick={() => setOpen(true)}>
        {t('scheduleSummary.promo')}
      </StyledPrimaryDottedButton>
    </Container>
  )
}
