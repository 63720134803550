import React, { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'

import {
  Container,
  StartText,
  SubTitle,
  Text,
  TextBlock,
  Title,
} from './styles'

const GenresSelectionGuide: FunctionComponent = () => {
  const { t } = useTranslation()

  return (
    <Container>
      <Title>{t('scheduleCampaignPage.genresSelectionGuide')}</Title>
      <TextBlock>
        <StartText>{t('scheduleCampaignPage.youCanSelectGenres')}</StartText>
        <SubTitle>
          <span role='img' aria-label='thumbs_up'>
            &#128077;
          </span>
          &nbsp;{t('scheduleCampaignPage.do')}
        </SubTitle>
        <Text>{t('scheduleCampaignPage.selectMostRelevantGenres')}</Text>
        <SubTitle>
          <span role='img' aria-label='thumbs_down'>
            &#128078;
          </span>
          &nbsp;{t('scheduleCampaignPage.dont')}
        </SubTitle>
        <Text>{t('scheduleCampaignPage.doNotSelect')}</Text>
        <SubTitle>
          <span role='img' aria-label='point_up'>
            &#9757;
          </span>
          &nbsp;{t('scheduleCampaignPage.rememberSubtitle')}
        </SubTitle>
        <Text>{t('scheduleCampaignPage.rememberThatArtists')}</Text>
      </TextBlock>
    </Container>
  )
}

export default GenresSelectionGuide
