import { FC } from 'react'

import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

import { CloseIconGrey, SoundCampaignLogo } from 'src/Assets/Svg'
import { device } from 'src/Styled/variables'
import { campaignsList } from 'src/Router/routes'

export const LogoHeader = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 150.07px 1fr;
  justify-items: self-end;
  height: 60px;

  @media ${device.mobileMin} {
    display: grid;
  }
`
export const TotalCloseButton = styled.button`
  width: 40px;
  height: 40px;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  svg {
    height: 15px;
    width: 15px;
  }
`

interface CreateNewCampaignHeaderProps {
  onClick?: () => void
}

export const CreateNewCampaignHeader: FC<CreateNewCampaignHeaderProps> = ({
  onClick,
}) => {
  const navigate = useNavigate()
  const handleClick = (): void => {
    navigate(`${campaignsList}`)
    if (typeof onClick === 'function') {
      onClick()
    }
  }

  return (
    <LogoHeader id='scroll'>
      <SoundCampaignLogo />
      <TotalCloseButton onClick={handleClick}>
        <CloseIconGrey />
      </TotalCloseButton>
    </LogoHeader>
  )
}
