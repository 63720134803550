import styled from 'styled-components'

import { colors, device } from 'src/Styled/variables'

export const StyledRelaunchButtonBlock = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 16px;
  background: ${colors.white};
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  height: 64px;
  z-index: 100;
  @media ${device.mobileMin} {
    position: relative;
    padding: 0px;
    display: flex;
    align-items: center;
    background: transparent;
    box-shadow: none;
    height: 0px;
  }
`

export const ButtonsContainer = styled.div`
  max-width: 137px;
  margin: 0 auto;
  @media ${device.mobileMin} {
    width: 100%;
  }
`
