export enum PurchaseTypeEnum {
  FirstPurchase = 'FirstPurchase',
  Purchase = 'purchase',
}

export enum PremiumFeaturesButtonTypeEnum {
  LeftSide = 'left-side',
  CampaignsList = 'campaigns_list',
}

export enum ReferralShareButtonTypeEnum {
  Email = 'email',
  Sms = 'sms',
  Facebook = 'facebook',
  WhatsApp = 'whatsapp',
  Twitter = 'twitter',
}
export type PaymentMethod =
  | 'PayPal_saved'
  | 'Stripe_saved'
  | 'PayPal'
  | 'Stripe'

export enum EventTypeEnum {
  PageView = 'Pageview',
  PaymentDetails = 'payment.details',
  CouponApplied = 'coupon.applied',
  Purchase = 'purchase',
  CRegStart = 'c.reg.start',
  PayoutRequest = 'c.payout.request',
  GenreAdded = 'genre_added',
  GenreRemoved = 'genre_removed',
  LanguagesAdded = 'languages.added',
  LanguageRemoved = 'language_removed',
  LogOut = 'logout',
  NcStart = 'nc.start',
  CreateCampaign = 'CreatedCampaign',
  CampaignSendToReview = 'campaign_send_to_review',
  TrackAdded = 'track.added',
  PhoneAdded = 'phone.number.added',
  RegisterFormFilling = 'filling.form',
  FirstCampaignDetails = 'fc.details',
  CampaignDetails = 'campaign.details',
  ArtistDetails = 'artist.details',
  CreatedFirstCampaign = 'CreatedFirstCampaign',
  CreatedCampaign = 'created_campaign',
  CreateFirstCampaignStart = 'fc.start',
  Login = 'login',
  CampaignBudgetUpdateEvent = 'campaign.budget.update',
  FirstPurchase = 'FirstPurchase',
  StartDateUpdate = 'start.date.update',
  ViewSignUpPopUp = 'view_sign_up_pop-up',
  RegistrationComplete = 'registration_complete',
  CampaignScheduleStart = 'campaign_schedule_start',
  CheckoutView = 'view_checkout_pop_up',
  ReviewRequestSeen = 'review_request_seen',
  ReviewMade = 'review_made',
  TrackAddedToPlaylist = 'track_added_to_playlist',
  ReviewSkipped = 'review_skipped',
  CuratorRegistrationStart = 'curator_registration_start',
  CreatorRegistrationStart = 'creator_registration_start',
  PlaylistAdded = 'playlist_added',
  PayoutRequested = 'payout_requested',
  PaymentMethodChanged = 'payment_method_changed',
  CampaignBudgetReceived = 'campaign_budget_received',
  CheckoutConfirmedPayment = 'checkout_confirmed_payment',
  ReviewRequestSeenNotAvailable = 'c_review_request_seen_not_available',
  PaymentCompleted = 'payment_completed',
  ArtistReviewSent = 'artist_review_sent',
  PaymentCancelled = 'payment_cancelled',
  CreatorReviewAvailableSongs = 'creator_review_available_songs',
  CreatorAcceptedTrack = 'creator_accepted_track',
  CreatorCancelledTask = 'creator_cancelled_task',
  CreatorUploadedVideo = 'creator_uploaded_video',
  ArtistReviewedVideo = 'artist_reviewed_video',
  ArtistAcceptedVideo = 'artist_accepted_video',
  ArtistRejectedVideo = 'artist_rejected_video',
  GA_EVENT = 'ga_event',
  MsgLowReachReceived = 'msg_low_reach_received',
}

export enum EventNameEnum {
  AddedTrackPlay = 'added_track_play',
  ReviewTrackSpotifyClicked = 'review_track_spotify_clicked',
  ReviewTrackListened = 'review_track_listened',
  FeedTrackListened = 'feed_track_listened',
  SearchedTrackPlay = 'searched_track_play  ',
  SignupLoginToError = 'signup_error_to_login',
  SignupLoginError = 'signup_login_error',
  LoginForm = 'login_form',
  QuickLogin = 'quick_login',
  EmailUnchecked = 'email_unchecked',
  PhoneEntered = 'phone_entered',
  TrackAdded = 'track_added',
  GenreAdded = 'genre_added',
  GenreRemoved = 'genre_removed',
  LanguagesAdded = 'languages.added',
  LanguageRemoved = 'language_removed',
  CampaignSendToReview = 'campaign_send_to_review',
  ViewSignUpPopUp = 'view_sign_up_pop-up',
  RegistrationComplete = 'registration_complete',
  CreatedFirstCampaign = 'CreatedFirstCampaign',
  CreatedCampaign = 'created_campaign',
  CampaignScheduleStart = 'campaign_schedule_start',
  CheckoutView = 'view_checkout_pop_up',
  ReviewRequestSeen = 'review_request_seen',
  ReviewMade = 'review_made',
  TrackAddedToPlaylist = 'track_added_to_playlist',
  ReviewSkipped = 'review_skipped',
  CuratorRegistrationStart = 'curator_registration_start',
  CreatorRegistrationStart = 'creator_registration_start',
  PlaylistAdded = 'playlist_added',
  PayoutRequested = 'payout_requested',
  PaymentMethodChanged = 'payment_method_changed',
  CampaignBudgetReceived = 'campaign_budget_received',
  CampaignBudgetUpdateEvent = 'campaign_budget_update',
  CheckoutConfirmedPayment = 'checkout_confirmed_payment',
  ReviewRequestSeenNotAvailable = 'c_review_request_seen_not_available',
  PaymentCompleted = 'payment_completed',
  ArtistReviewSent = 'artist_review_sent',
  PaymentCancelled = 'payment_cancelled',
  CreatorReviewAvailableSongs = 'creator_review_available_songs',
  CreatorAcceptedTrack = 'creator_accepted_track',
  CreatorCancelledTask = 'creator_cancelled_task',
  CreatorUploadedVideo = 'creator_uploaded_video',
  ArtistReviewedVideo = 'artist_reviewed_video',
  ArtistAcceptedVideo = 'artist_accepted_video',
  ArtistRejectedVideo = 'artist_rejected_video',
  ToggleActivated = 'toggle_activated',
  CuratorSelectedPositionAddTrack = 'curator_selected_position_add_track',
  ChangedLanguageOptions = 'changed_language_options',
  ClickShareReview = 'click_share_review',
  ViewShareReviewPopUp = 'view_share_review_pop_up',
  ClickDownloadImage = 'click_download_image',
  SearchUnavailable = 'search_unavaliable',
  StartedEnterTrack = 'started_enter_track',
  EnteredTrackUrl = 'entered_track_url',
  ErrorAddTrack = 'error_add_track',
  SoundLinkClick = 'soundlink_click',
  RelaunchDetailsClick = 'relaunch_details_click',
  RelaunchCampaignListClick = 'relaunch_campaign_list_click',
  SubscribeStarter = 'subscribe_starter',
  SubscribeBusiness = 'subscribe_business',
  SubscribeProfessional = 'subscribe_professional',
  CopyInviteLink = 'copy_invite_link',
  CopyInvitationMessage = 'copy_invitation_message',
  GetPremiumFeatures = 'get_premium_features',
  ErrorAccessToken = 'error_access_token',
  ErrorSameTrack = 'error_same_track',
  ErrorOfficialTrack = 'error_official_link',
  SubscriptionSelected = 'subscription_selected',
  ChangedPlan = 'changed_plan',
  SubscriptionConfirmedPayment = 'subscription_confirmed_payment',
  SubscriptionPaymentCompleted = 'subscription_payment_completed',
  ReferralShare = 'referral_share',
  ThankPageCreateTiktokCampaign = 'thank_page_create_tiktok_campaign',
  DiscoverTiktokPromotion = 'discover_tiktok_promotion',
  EnterTrack = 'enter_track',
  UpsellClick = 'upsell_click',
  NotificationClick = 'notification_click',
  TiktokAddTrackHint = 'tiktok_add_track_hint',
  MsgLowReachReceived = 'msg_low_reach_received',
  FAQTiktokCreating = 'faq_tiktok_creating',
  FAQTiktokCreatingAnotherQuestion = 'tiktok_creating_another_question',
  LaunchCampaignNotification = 'launch_campaign_notification',
  NewReleasedPromote = 'new_released_promote',
  NewReleasedCampaignsList = 'new_released_campaigns_list',
}
