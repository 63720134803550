import { createSlice } from '@reduxjs/toolkit'

import { setApiError } from 'src/Hooks/redux'
import { NameSpace, SliceState, SliceStatus } from 'src/Redux/types'

import {
  cancelSubscription,
  changeSubscription,
  createSubscription,
  getSubscription,
} from './api-action'

export type SubscriptionStatus = 'active' | 'canceled' | 'ended'

interface Package {
  cashbackPercentage: number
  id: number
  name: string
  paymentAmount: number
}

export interface Subscription {
  lastPaymentDate: string
  id: number
  nextPaymentDate: string
  canceledAt: string
  userId: number
  packageId: number
  paymentType: string
  status: SubscriptionStatus
  paymentAmount: number
  activeUserId: number
  package: Package | null
}

export interface SubscriptionState extends SliceState {
  data: Subscription | null
}
const initialState: SubscriptionState = {
  status: SliceStatus.Idle,
  data: null,
  error: null,
}

export const subscription = createSlice({
  initialState,
  name: NameSpace.SUBSCRIPTION,
  reducers: {},
  extraReducers(builder) {
    // getSubscription
    builder.addCase(getSubscription.pending, (state) => {
      state.status = SliceStatus.Loading
    })
    builder.addCase(getSubscription.fulfilled, (state, action) => {
      state.status = SliceStatus.Succeeded
      state.data = action.payload
      state.error = null
    })
    builder.addCase(getSubscription.rejected, (state, action) => {
      state.status = SliceStatus.Failed
      state.error = setApiError(action)
    })

    // createSubscription
    builder.addCase(createSubscription.pending, (state) => {
      state.status = SliceStatus.Loading
    })
    builder.addCase(createSubscription.fulfilled, (state, { payload }) => {
      state.status = SliceStatus.Succeeded
      state.data = payload
      state.error = null
    })
    builder.addCase(createSubscription.rejected, (state, action) => {
      state.status = SliceStatus.Failed
      state.error = setApiError(action)
    })
    // changeSubscription
    builder.addCase(changeSubscription.pending, (state) => {
      state.status = SliceStatus.Loading
    })
    builder.addCase(changeSubscription.fulfilled, (state, { payload }) => {
      state.status = SliceStatus.Succeeded
      state.data = payload
      state.error = null
    })
    builder.addCase(changeSubscription.rejected, (state, action) => {
      state.status = SliceStatus.Failed
      state.error = setApiError(action)
    })

    // cancelSubscription
    builder.addCase(cancelSubscription.pending, (state) => {
      state.status = SliceStatus.Loading
    })
    builder.addCase(cancelSubscription.fulfilled, (state) => {
      state.status = SliceStatus.Succeeded
      state.error = null
      if (state.data?.status) {
        state.data.status = 'canceled'
      }
    })
    builder.addCase(cancelSubscription.rejected, (state, action) => {
      state.status = SliceStatus.Failed
      state.error = setApiError(action)
    })
  },
})
