import { FC, ReactElement } from 'react'

import { useTranslation } from 'react-i18next'
import Select, { GroupBase, Options } from 'react-select'

import GreenCheckMark from 'src/Assets/Svg/checkmark-green-round.svg?react'
import GreyCheckMark from 'src/Assets/Svg/checkmark-grey-round.svg?react'
import track from 'src/Assets/Png/track.png'
import { styledSelectCustomStyles } from 'src/Styled/StyledSelectCustomStyles'
import { MultiValueRemove } from 'src/Components/MultiValueRemove'
import {
  SelectedLanguage,
  ActionsForSelectGenresAndLanguagesEnum,
  Playlist,
} from 'src/Types'
import { PLAYLIST_MAX_LENGTH_NAME } from 'src/Constants/numeric'
import { useGetAllLanguagesQuery } from 'src/Redux/languagesApi'

import { optionsSelectLanguages } from './helpers'
import { OptionLabelLanguage } from './OptionLabelLanguge'

import {
  PlaylistContainer,
  SelectWrapper,
  PlaylistScoreNumber,
  CheckMark,
  PlaylistTopContainer,
  PlaylistCover,
  PlaylistInfo,
  PlaylistName,
  PlaylistScore,
  TargetLanguageTitle,
} from './styles'

interface ActionMeta {
  action: ActionsForSelectGenresAndLanguagesEnum
  option: SelectedLanguage
}

interface PlaylistElementProps {
  item: Playlist
  index: number
  selectedInputLanguages: Array<Array<SelectedLanguage>>
  inputValueLanguages: string[]
  onInputChangeLanguages: (value: string, index: number) => void
  onChangeSelectLanguages: (
    option: SelectedLanguage[],
    value: ActionMeta,
    index: number,
  ) => void
}

export const PlaylistElement: FC<PlaylistElementProps> = ({
  item,
  index,
  selectedInputLanguages,
  inputValueLanguages,
  onInputChangeLanguages,
  onChangeSelectLanguages,
}) => {
  const { t } = useTranslation()
  const { data: languages } = useGetAllLanguagesQuery()

  const formatOptionLabel = (
    data: SelectedLanguage,
    formatOptionLabelMeta: {
      inputValue: string
      selectValue: Options<SelectedLanguage>
    },
  ): ReactElement => (
    <OptionLabelLanguage
      search={formatOptionLabelMeta}
      language={data}
      inputValueLanguages={inputValueLanguages}
    />
  )

  return (
    <PlaylistContainer>
      <PlaylistTopContainer>
        <PlaylistCover
          src={item.playlist_cover}
          alt='playlist_cover'
          fallbackSrc={track}
        />
        <PlaylistInfo>
          <PlaylistName>
            {item.playlist_name.length > PLAYLIST_MAX_LENGTH_NAME
              ? item.playlist_name
                  .substring(0, PLAYLIST_MAX_LENGTH_NAME as number)
                  .concat('...')
              : item.playlist_name}
          </PlaylistName>
          <PlaylistScore>
            {t('playlistsPage.score')}:
            <PlaylistScoreNumber>
              {` ${item.score + item.extra_score}`}
            </PlaylistScoreNumber>
          </PlaylistScore>
        </PlaylistInfo>

        {selectedInputLanguages[index] &&
        Boolean(selectedInputLanguages[index].length) ? (
          <CheckMark>
            <GreenCheckMark />
          </CheckMark>
        ) : (
          <CheckMark>
            <GreyCheckMark />
          </CheckMark>
        )}
      </PlaylistTopContainer>
      <SelectWrapper>
        <TargetLanguageTitle>
          <div>{t('targetLanguages.targetLanguage')}</div>
        </TargetLanguageTitle>
        <div>
          <Select<SelectedLanguage, true, GroupBase<SelectedLanguage>>
            isMulti
            menuShouldBlockScroll
            styles={styledSelectCustomStyles}
            menuPlacement='auto'
            menuPosition='fixed'
            placeholder={t('createCampaignPage.noVocal')}
            classNamePrefix='multi-select'
            closeMenuOnSelect={false}
            options={optionsSelectLanguages(languages || [])}
            value={selectedInputLanguages[index]}
            inputValue={inputValueLanguages[index]}
            components={{ DropdownIndicator: null, MultiValueRemove }}
            formatOptionLabel={formatOptionLabel}
            onInputChange={(value: string, { action }) => {
              if (action === 'input-change') {
                onInputChangeLanguages(value, index)
              }
            }}
            onChange={(option, value) =>
              onChangeSelectLanguages(
                option as SelectedLanguage[],
                value as ActionMeta,
                index,
              )
            }
          />
        </div>
      </SelectWrapper>
    </PlaylistContainer>
  )
}
