import React, { FC, ReactNode } from 'react'

import styled from 'styled-components'

import { colors } from 'src/Styled/variables'

export interface CustomScrollbarWrapperProps
  extends React.HTMLAttributes<HTMLDivElement> {
  children: ReactNode
}

const Container = styled.div`
  height: fit-content;
  overflow-y: scroll;
  margin: 5px;
  background-color: ${colors.white};

  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: ${colors.greyBg};
    padding-right: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background: ${colors.greyTextBlackBg};
    border-radius: 7px;
    height: 60px;
  }
`

export const CustomScrollbarWrapper: FC<CustomScrollbarWrapperProps> = ({
  children,
  ...rest
}) => <Container {...rest}>{children}</Container>
