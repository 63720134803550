import { Dayjs } from 'dayjs'

import { DEFAULT_PERIOD_TO_START_CAMPAIGN } from 'src/Constants/constants'
import { formatDate } from 'src/Constants/formatDate'
import dayJs from 'src/Helpers/dayjs'

export const getStartDate = (
  date?: string | Date,
  isToday?: boolean,
): string => {
  if (date) {
    return dayJs(date).format(formatDate.formatDateMonthFull)
  }
  return dayJs()
    .add(isToday ? 0 : DEFAULT_PERIOD_TO_START_CAMPAIGN, 'days')
    .format(formatDate.formatDateMonthFull)
}
export const getDefaultStartDate = (isToday?: boolean): string =>
  dayJs()
    .add(isToday ? 0 : DEFAULT_PERIOD_TO_START_CAMPAIGN, 'days')
    .format(formatDate.formatDateMonthFull)

export const checkDisabledDate = (
  date: Dayjs,
  withCurrent: boolean,
): boolean => {
  if (withCurrent) {
    return dayJs().subtract(1, 'day') >= date || dayJs().add(1, 'month') <= date
  }
  return dayJs() >= date || dayJs().add(1, 'month') <= date
}
