import { FC, MouseEvent, ReactNode, useState } from 'react'

import styled from 'styled-components'

import CheckMarkIcon from 'src/Assets/Svg/checkmark-bold.svg?react'
import { colors } from 'src/Styled/variables'

interface CopyButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode
  value: string
  copiedText: ReactNode
  fill?: string
}

const StyledButton = styled.button<{ $fill?: string }>`
  display: flex;
  height: 40px;
  width: 100%;
  padding: 9.5px 32px;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border-radius: 27px;
  cursor: pointer;
  white-space: nowrap;
  border: 1px solid ${colors.green};
  background: ${({ $fill }) => $fill || '#fff'};
  color: ${({ $fill }) => ($fill ? '#fff' : colors.green)};
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`

const CopiedText = styled.div`
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: center;
  gap: 8px;
  align-self: stretch;
  color: ${colors.green};
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  padding: 9.5px 32px;
`

export const CopyButton: FC<CopyButtonProps> = (props) => {
  const {
    children,
    value,
    copiedText,
    fill,
    onClick,
    className,
    ...restProps
  } = props
  const [copied, setCopied] = useState(false)

  const handleClick = async (
    e: MouseEvent<HTMLButtonElement>,
  ): Promise<void> => {
    await window.navigator.clipboard.writeText(value)
    onClick && onClick(e)
    setCopied(true)
  }

  if (copied) {
    return (
      <CopiedText className={className}>
        <CheckMarkIcon data-testid='copied-icon' />
        {copiedText}
      </CopiedText>
    )
  }

  return (
    <StyledButton
      {...restProps}
      className={className}
      $fill={fill}
      onClick={handleClick}
    >
      {children}
    </StyledButton>
  )
}
