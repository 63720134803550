import { Input } from 'antd'
import styled from 'styled-components'

import { colors, device } from 'src/Styled/variables'

const { TextArea } = Input
export const StyledTextArea = styled(TextArea)`
  border: 1px solid #eee;
  &.ant-input-affix-wrapper:hover {
    border: 1px solid #eee;
  }
  &.ant-input-affix-wrapper:focus {
    border: 1px solid #eee;
    box-shadow: none;
  }
  &.ant-input-affix-wrapper-focused {
    border: 1px solid #eee;
    box-shadow: none;
  }
`

export const DateWrapper = styled.span`
  text-wrap: nowrap;
  color: ${colors.mainText};
  font-size: 14px;
  line-height: 21px;
`
export const RateFormHeaderContainer = styled.div`
  display: grid;
  align-items: center;
  width: 100%;
  grid-template-columns: 1fr 21px;
  column-gap: 8px;
  font-size: 18px;
  font-weight: 400;
  line-height: 150%;
  @media ${device.mobileMin} {
    font-size: 14px;
  }
`

export const RateFormHeader = styled.div`
  display: grid;
  grid-template-columns: 275px 144px;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  gap: 8px;
  margin-bottom: 0px;
  @media ${device.mobileMax} {
    grid-template-columns: 100%;
    flex-direction: column;
    gap: 16px;
    align-items: flex-start;
    background-color: ${colors.white};
    padding-top: 8px;
  }
`
export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: -8px;

  .ant-input {
    padding: 12px;
    color: ${colors.mainText} !important;
  }
  .ant-input-data-count {
    font-size: 10px;
    color: ${colors.mainText};
  }
`

export const ButtonWrapper = styled.div`
  div {
    margin: 0 auto;
    width: fit-content;
    .ant-btn {
      padding: 0 40px;
    }
  }
  @media ${device.mobileMax} {
    margin-bottom: 24px;
  }
`
