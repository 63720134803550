import React from 'react'

import { Dropdown } from 'antd'
import styled, { createGlobalStyle } from 'styled-components'

import User from 'src/Assets/Svg/header-icons/user.svg?react'
import { IconButton } from 'src/Components/Buttons/IconButton'

import { HeaderMenuItem } from '../HeaderMenuItem'

const dropdownClassName = 'header-menu-dropdown'

const StyledIconButton = styled(IconButton)``

const GlobalStyled = createGlobalStyle`
  .${dropdownClassName} {
    .ant-dropdown-menu {
      display: flex;
      padding: 8px !important;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      width: 200px;
      box-shadow: 0px 0px 12px 0px rgba(51, 51, 51, 0.12) !important;
      border-radius: 16px !important;
      border: ${({ theme }) => `1px solid ${theme.common.primaryBorder}`};
    }

    .ant-dropdown-menu-item {
      padding: 0 !important;
      width: 100%;
    }

    .ant-dropdown-menu-title-content {
      width: 100%;
    }

    .ant-dropdown-menu-item-active {
        outline: none !important;
    }
      

  }
`

const StyledDropdown = styled(Dropdown)`
  &.ant-dropdown-open {
    ${StyledIconButton} {
      svg {
        path {
          fill: ${({ theme }) => theme.button.fifthText};
        }
      }

      &:hover {
        box-shadow: none;
      }
    }
  }
`

interface MenuItem {
  title: string
  Icon: React.FC<React.SVGProps<SVGSVGElement>>
  onClick: () => void
}

export interface HeaderMenuProps {
  items: Array<MenuItem>
}

export const HeaderMenu: React.FC<HeaderMenuProps> = ({ items }) => {
  const menuItems = items.map((item) => ({
    key: item.title,
    label: <HeaderMenuItem Icon={item.Icon} title={item.title} />,
    onClick: item.onClick,
  }))

  return (
    <>
      <StyledDropdown
        menu={{ items: menuItems }}
        placement='bottomRight'
        trigger={['click']}
        overlayClassName={dropdownClassName}
      >
        <div>
          <StyledIconButton>
            <User />
          </StyledIconButton>
        </div>
      </StyledDropdown>
      <GlobalStyled />
    </>
  )
}
