import TagManager from 'react-gtm-module'

import { ID } from 'src/Types'

import { EventNameEnum, EventTypeEnum } from '../tagManagerEnums'

export const soundLinkClickEvent = ({
  userId,
  campaignNumber,
}: {
  userId?: ID | null
  campaignNumber?: number
}): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventTypeEnum.GA_EVENT,
      event_params: {
        event_name: EventNameEnum.SoundLinkClick,
        campaign_number: campaignNumber || 1,
      },
      user_properties: {
        ...(userId ? { user_id: userId } : {}),
        account_type: 'artist',
      },
    },
  })
}
