import React, { FC } from 'react'

import styled from 'styled-components'

import TrackCoverIcon from 'src/Assets/Svg/pages-icons/TrackCover.svg'
import { MAX_MOBILE_WIDTH } from 'src/Styled/variables'

import { CampaignPlatformLabel } from '../CampaignPlatformLabel'
import { SmartImage } from '../SmartImage'

import { ScheduleSettingsHeaderProps } from '.'

const TrackDetails = styled.div`
  display: flex;
  height: 70px;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  @media screen and (min-width: ${MAX_MOBILE_WIDTH}) {
    width: 100%;
  }
`

const TrackCover = styled(SmartImage)`
  width: 68px;
  height: 68px;
  border-radius: 16px;
`
const TrackInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
`
const TrackSubInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
`

const ArtistName = styled.div`
  color: ${(props) => props.theme.common.primaryText};
  font-family: 'Segoe UI';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`
const TrackName = styled(ArtistName)`
  font-weight: 600;
`

type Props = Pick<
  ScheduleSettingsHeaderProps,
  'artistName' | 'platform' | 'trackCover' | 'trackName'
>

export const ScheduleSettingsHeaderTrackDetails: FC<Props> = (props) => {
  const { trackCover, platform, artistName, trackName } = props
  return (
    <TrackDetails>
      <TrackCover
        src={trackCover}
        alt='track cover'
        fallbackSrc={TrackCoverIcon}
      />
      <TrackInfo>
        <TrackSubInfo>
          <TrackName>{trackName}</TrackName>
          <ArtistName>{artistName}</ArtistName>
        </TrackSubInfo>
        <CampaignPlatformLabel platform={platform} />
      </TrackInfo>
    </TrackDetails>
  )
}
