import React, { FC, useMemo } from 'react'

import styled from 'styled-components'

import { GenreOrLanguageItem } from 'src/Components/GenresList/GenreItem'
import { renderLanguageOrGenreName } from 'src/Components/GenresList/helpers'
import { filterAndSortGenres } from 'src/Constants/functions'
import { capitalizeFirstLetter } from 'src/Helpers/layoutHelpers'
import { Genre } from 'src/Types'
interface Props {
  searchValue: string
  selectedGenres: Genre[]
  genres: Genre[]
  onClick: (id: number) => void
}

const Label = styled.div`
  color: ${({ theme }) => theme.common.primaryText};
  font-family: 'Segoe UI';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin-top: 16px;
  margin-bottom: 16px;
`

const GenreListWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  gap: 8px;
`

export const TiktokScheduleGenresModalGenresList: FC<Props> = ({
  genres,
  selectedGenres,
  onClick,
  searchValue,
}) => {
  const filteredGenres = useMemo(
    () => filterAndSortGenres(genres, searchValue, 'genre_name'),
    [genres, searchValue],
  )

  return (
    <>
      {Boolean(selectedGenres.length) && (
        <GenreListWrapper>
          {selectedGenres.map((genre) => (
            <GenreOrLanguageItem
              onClick={() => onClick(Number(genre?.id))}
              hasCross
              name={capitalizeFirstLetter(genre.genre_name ?? '')}
              key={genre.id}
            />
          ))}
        </GenreListWrapper>
      )}
      <div>
        <Label>A-Z</Label>
        <GenreListWrapper>
          {filteredGenres.map((genre) => (
            <GenreOrLanguageItem
              onClick={() => onClick(Number(genre?.id))}
              selected={selectedGenres.some((item) => item.id === genre.id)}
              name={renderLanguageOrGenreName(
                genre?.genre_name ?? '',
                searchValue,
              )}
              key={genre.id}
            />
          ))}
        </GenreListWrapper>
      </div>
    </>
  )
}
