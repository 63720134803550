import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'

import { CampaignPlatformTypeEnum, StripePayment } from 'src/Types'
import { api } from 'src/Services/api'
import { APP } from 'src/Configs/App'

import { ErrorCode, NameSpace } from '../types'
import { getUserInfo } from '../auth-process/userSlice/api-actions'

interface ChargePaypalPaymentRequestI {
  amount: number
  campaigns: Array<{
    type: CampaignPlatformTypeEnum
    genres: number[]
    languages: number[]
    amount: number
    startDate: string
    campaignId: number
    isMedianTest?: boolean
  }>
  country: string
  couponValue?: string
  usedBalance: number
}

export const chargePaypalPayment = createAsyncThunk<
  { link: string },
  ChargePaypalPaymentRequestI
>(
  `${NameSpace.Payment}/chargePaypalPayment`,
  async (requestData, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await api.post<{ link: string }>(
        `${APP.TIKTOK_SERVER}/payment/paypal/charge/v2`,
        requestData,
      )

      await dispatch(getUserInfo())
      return data
    } catch (error) {
      const { message, response } = error as AxiosError
      return rejectWithValue({
        message,
        code: response?.status || ErrorCode.InternalServerError,
      })
    }
  },
)

export const submitStripePayment = createAsyncThunk<
  { link: string },
  StripePayment
>(
  `${NameSpace.Payment}/submitStripePayment`,
  async (requestData, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await api.post<{ link: string }>(
        `${APP.TIKTOK_SERVER}/payment/stripe/pay`,
        requestData,
      )
      await dispatch(getUserInfo())
      return data
    } catch (error) {
      const { message, response } = error as AxiosError
      return rejectWithValue({
        message,
        code: response?.status || ErrorCode.InternalServerError,
      })
    }
  },
)
