import { createDraftSafeSelector } from '@reduxjs/toolkit'

import { RootState } from '..'

import { WiseState } from '.'

const getState = (state: RootState): WiseState => state.transferwise

export const getCurrenciesList = createDraftSafeSelector(
  getState,
  ({ currencies }) => currencies,
)
export const getRequirements = createDraftSafeSelector(
  getState,
  ({ requirements }) => requirements,
)
export const getRecipient = createDraftSafeSelector(
  getState,
  ({ recipient }) => recipient,
)
export const getWiseLoading = createDraftSafeSelector(
  getState,
  ({ loading }) => loading,
)
export const getWiseError = createDraftSafeSelector(
  getState,
  ({ error }) => error,
)
