import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import { PrimaryButton } from 'src/Components/Buttons/PrimaryButton'
import { ScheduleSteps } from 'src/Components/ScheduleTabs'
import { getIsLanguageActive } from 'src/Containers/ScheduleSpotifyWrapper/components/SpotifySettings/helpers'
import { useAppSelector } from 'src/Hooks/redux'
import { useCheckoutEvents } from 'src/Hooks/useCheckoutEvents'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import {
  getCampaignToSchedule,
  getIsUpdateCampaignLoading,
} from 'src/Redux/campaign-process/spotifyCampaignSchedule-process/selectors'
import { useGetBudgetRangeQuery } from 'src/Redux/spotifyBudgetRangeApi'
import { CampaignPlatformTypeEnum } from 'src/Types'

const StyledPrimaryButton = styled(PrimaryButton)`
  margin: 0 auto;
  padding-left: 40px;
  padding-right: 40px;
  border: 1px solid ${({ theme }) => theme.button.primaryText};
`

export const SpotifySettingsContinueButton: FC = () => {
  const { t } = useTranslation()
  const { handleCheckoutStartEvent } = useCheckoutEvents()
  const { number: campaignId } = useParams()

  const isUpdateCampaignLoading = useAppSelector(getIsUpdateCampaignLoading)
  const campaignDetails = useAppSelector(getCampaignToSchedule)
  const { isError: budgetRangeError } = useGetBudgetRangeQuery(campaignId, {
    skip: !campaignId,
  })

  const { setSearch } = useCustomSearchParams()
  const isLanguageActive = getIsLanguageActive(campaignDetails.languages)
  if (!campaignDetails.genres.length || isLanguageActive) {
    return null
  }
  const handleClick = (): void => {
    setSearch({ step: ScheduleSteps.CHECKOUT })

    handleCheckoutStartEvent({
      platformName: CampaignPlatformTypeEnum.SPOTIFY,
      id: Number(campaignDetails.id),
      campaignType: campaignDetails.type,
      isFeed: campaignDetails.is_feed,
      genres: campaignDetails.genres.map((genre) => genre.name),
      languages: campaignDetails.languages.map((language) => language.name),
      startDate: (campaignDetails.start_date || '').toString(),
      resultPrice: campaignDetails.price,
    })
  }
  const hasPrice = campaignDetails.price > 0
  const isDisabled = budgetRangeError || !hasPrice || isUpdateCampaignLoading

  return (
    <StyledPrimaryButton onClick={handleClick} disabled={isDisabled}>
      {t('scheduleCampaignPage.continue')}
    </StyledPrimaryButton>
  )
}
