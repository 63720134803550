import { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'

import { formatDate } from 'src/Constants/formatDate'
import dayjs from 'src/Helpers/dayjs'
import { InfluencerEventStatus } from 'src/Types/index'

import { RewardCreatorComponentProps } from './RewardCreatorComponentProps'

import { Grid, Text } from './styles'

export const RewardCreatorComponent: FunctionComponent<
  RewardCreatorComponentProps
> = ({ reward, paymentReceivedDate, status, isPaymentReceived }) => {
  const { t } = useTranslation()

  const paymentReceivedFormattedDate = dayjs(paymentReceivedDate).format(
    formatDate.formatDate1,
  )

  const approvedStatuses = [
    InfluencerEventStatus.AWAITING_REVIEW,
    InfluencerEventStatus.AWAITING_MODERATOR_REVIEW,
    InfluencerEventStatus.APPROVED_BY_ARTIST,
    InfluencerEventStatus.APPROVED_AUTOMATICALLY,
    InfluencerEventStatus.APPROVED_BY_ADMIN,
  ]

  const canceledStatuses = [
    InfluencerEventStatus.CANCELED,
    InfluencerEventStatus.CANCELED_BY_ARTIST,
    InfluencerEventStatus.DECLINED,
    InfluencerEventStatus.CAMPAIGN_STOPED,
  ]

  const isApproved = approvedStatuses.includes(status as InfluencerEventStatus)
  const isCanceled = canceledStatuses.includes(status as InfluencerEventStatus)

  const REWARD_TEXT = t('influencer.reward')

  return (
    <Grid>
      <span>{REWARD_TEXT}:</span>
      {status === InfluencerEventStatus.ACCEPTED && <Text>&#36;{reward}</Text>}

      {isApproved && (
        <Text>
          &#36;{reward}&nbsp;(
          {REWARD_TEXT.toLowerCase()}&nbsp;
          {isPaymentReceived
            ? t('influencer.received')
            : t('influencer.willBeReceived')}
          &nbsp;
          {!isPaymentReceived && paymentReceivedFormattedDate})
        </Text>
      )}

      {isCanceled && (
        <Text>
          &#36;{reward}&nbsp;(
          {REWARD_TEXT.toLowerCase()}&nbsp;
          {t('influencer.notReceived')})
        </Text>
      )}
    </Grid>
  )
}
