import { FC } from 'react'

import dayjs from 'src/Helpers/dayjs'
import { formatDate } from 'src/Constants/formatDate'

import { CampaignEndedShowDateProps } from './CampaignEndedShowDateProps'

import { StyledCampaignEndedShowDate } from './styles'

export const CampaignEndedShowDate: FC<CampaignEndedShowDateProps> = ({
  startDate,
  endDate,
}) => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  return (
    <StyledCampaignEndedShowDate>
      {dayjs(startDate).tz(timezone).format(formatDate.formatDateMonthFull)}
      &nbsp;-&nbsp;
      {dayjs(endDate).tz(timezone).format(formatDate.formatDateMonthFull)}
    </StyledCampaignEndedShowDate>
  )
}
