import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import {
  PrimaryScheduleSteps,
  PrimaryScheduleTabs,
  ScheduleSteps,
} from 'src/Components/PrimaryScheduleTabs'
import { useScheduleTikTokCampaign } from 'src/Containers/ScheduleTiktokWrapper/useSchaduleTikTokCampaign'
import { ArtistRoutesEnum } from 'src/Router/ArtistComponentsNewUI'
import { ScheduleSuccessPage } from 'src/Components/ScheduleSuccessPage'
import { CampaignPlatformTypeEnum } from 'src/Types'
import { getScheduledTikTokCampaign } from 'src/Redux/schedule-process/tiktokCampaign/selectors'
import { useAppSelector } from 'src/Hooks/redux'

import { TiktokScheduleCheckoutPage } from './TiktokScheduleCheckoutPage'
import { TiktokScheduleSettingsPage } from './TiktokScheduleSettingsPage'

export const TiktokSchedulePage: FC = () => {
  const { t } = useTranslation()
  const { number } = useParams()
  useScheduleTikTokCampaign()
  const scheduledCampaign = useAppSelector(getScheduledTikTokCampaign)
  const steps: PrimaryScheduleSteps[] = [
    {
      title: t('spotifySchedulePage.campaigns'),
      path: ArtistRoutesEnum.CampaignsList,
    },
    {
      title: t('spotifySchedulePage.campaignSettings'),
      path: `${ArtistRoutesEnum.TiktokSchedule}/${number}?step=${ScheduleSteps.SETTINGS}`,
    },
    {
      title: t('spotifySchedulePage.campaignCheckout'),
      path: `${ArtistRoutesEnum.TiktokSchedule}/${number}?step=${ScheduleSteps.CHECKOUT}`,
    },
  ]

  return (
    <>
      <ScheduleSuccessPage
        startDate={scheduledCampaign.startDate}
        platformName={CampaignPlatformTypeEnum.SPOTIFY}
      />
      <PrimaryScheduleTabs steps={steps}>
        {/* first must be settings second checkout */}
        <TiktokScheduleSettingsPage />
        <TiktokScheduleCheckoutPage />
      </PrimaryScheduleTabs>
    </>
  )
}
