import { FC } from 'react'

import styled from 'styled-components'

import { colors } from 'src/Styled/variables'
import { TransactionTypeEnum } from 'src/Types'

import { getBalanceItemDescription, getMobileHeader } from '../halpers'

interface HeaderTitleProps {
  isBonusType: boolean
  packageName?: string
  firstName?: string

  transactionType: TransactionTypeEnum
  trackName: string
  trackAuthorName: string
}

const Title = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: ${colors.mainText};
`

export const HeaderTitle: FC<HeaderTitleProps> = (props) => {
  const {
    isBonusType,
    firstName,
    transactionType,
    trackName,
    trackAuthorName,
    packageName,
  } = props
  if (
    transactionType === TransactionTypeEnum.SUBSCRIPTION ||
    transactionType === TransactionTypeEnum.SUBSCRIPTION_PAYMENT_FAILED
  ) {
    const text = getBalanceItemDescription(
      transactionType,
      firstName,
      packageName,
    ).header
    return <Title>{text}</Title>
  }
  if (transactionType === TransactionTypeEnum.CHARGEBACK && packageName) {
    const text = getBalanceItemDescription(
      transactionType,
      firstName,
      packageName,
    ).header
    return <Title>{text}</Title>
  }

  if (isBonusType) {
    const text = getBalanceItemDescription(transactionType, firstName).header
    return <Title>{text}</Title>
  }
  const mobileText = getMobileHeader(transactionType)
  return <Title>{`${mobileText} ${trackName} by ${trackAuthorName}`}</Title>
}
