export const formatDate: Record<string, string> = {
  formatDateMonthFirstShortYear: 'M/DD/YY',
  formatDateMonthFirst: 'MM/DD/YYYY',
  formatDateWithTime: 'MM/DD/YYYY h:mm A',
  formatDateMonthFull: 'D MMM, YYYY',
  formatDate: 'MMM DD, YYYY',
  formatDate1: 'DD MMM, YYYY',
  formatDateWithTime2: 'MMM DD, YYYY h:mm A',
  formatDateFirst: 'MMM DD, YYYY',
  formatDateFirst2: 'DD MMM, YYYY h:mm A',
}
