import { FC } from 'react'

import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import Button from 'src/Components/Buttons/Button'
import { DotedButton } from 'src/Components/Buttons/DotedButton'
import { colors } from 'src/Styled/variables'
import { campaignsList } from 'src/Router/routes'

const Wrapper = styled.div`
  position: fixed;
  z-index: 1;
  bottom: 0;
  left: 0;
  display: flex;
  width: 100%;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  background-color: ${colors.white};
  border-top: 1px solid ${colors.greyStroke};
`

const StyledButton = styled(Button)`
  width: 124px;
  button {
    padding: 0 40px;
  }
`

interface FirstFeedbackButtonsProps {
  disabled?: boolean
  loading?: boolean
}

export const FirstFeedbackButtons: FC<FirstFeedbackButtonsProps> = ({
  disabled,
  loading,
}) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const handleSkip = (): void => {
    navigate(campaignsList)
  }
  return (
    <Wrapper>
      <DotedButton onClick={handleSkip} color={colors.mainText}>
        {t('feedBackPage.skipSurvey')}
      </DotedButton>
      <StyledButton
        disabled={disabled}
        loading={loading}
        type='green'
        htmlType='submit'
      >
        {t('feedBackPage.submit')}
      </StyledButton>
    </Wrapper>
  )
}
