import { FC } from 'react'

import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { login } from 'src/Router/routes'
import { Desktop, Mobile } from 'src/Components/Responsive'
import { useQuickLoginUTMParameters } from 'src/Hooks/useQuickLoginUTMParameters'
import { signupErrorToLogin } from 'src/Helpers/TagManager/AuthEvents'

import { ExistingEmailBlock, ExistingEmailText, RegisterLogin } from './styles'
interface EmailExistProps {
  emailExist: boolean
}

export const EmailExist: FC<EmailExistProps> = ({ emailExist }) => {
  const { t } = useTranslation()

  const utmParameters = useQuickLoginUTMParameters()
  const params = new URLSearchParams(utmParameters).toString()
  const loginWithParams = `${login}?${params}`

  if (!emailExist) {
    return null
  }
  return (
    <ExistingEmailBlock>
      <ExistingEmailText>
        {t('createCampaignPage.useAnotherEmailToRegisterOr')}
      </ExistingEmailText>
      &nbsp;
      <Link onClick={signupErrorToLogin} to={loginWithParams}>
        <Desktop>
          <RegisterLogin>
            {t('createCampaignPage.loginWithYourAccount')}
          </RegisterLogin>
        </Desktop>
        <Mobile>
          <RegisterLogin>{t('createCampaignPage.login')}</RegisterLogin>
        </Mobile>
      </Link>
    </ExistingEmailBlock>
  )
}
