import React, { FC } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import IconProtection from 'src/Assets/Svg/protection.svg?react'
import { colors, variables } from 'src/Styled/variables'

export const AlertProtectionWrapper = styled.div`
  display: grid;
  grid-template-columns: 21px 1fr;
  gap: 17.5px;
  border-radius: 4px;
  width: 100%;
  font-size: ${variables.fontSize14};
  color: ${colors.greyTextWhiteBg};
`

export const AlertProtectionWrapperText = styled.div`
  display: grid;
  line-height: 21px;
  gap: 4px;
  > span:first-of-type {
    font-weight: ${variables.fontWeight600};
  }
`

interface ArtistProtectionProps {
  children?: React.ReactNode
  className?: string
}
export const ArtistProtection: FC<ArtistProtectionProps> = ({
  children,
  className,
}) => {
  const { t } = useTranslation()
  return (
    <AlertProtectionWrapper className={className}>
      <IconProtection width={21} height={24} />
      <AlertProtectionWrapperText>
        <span>{t('scheduleCampaignPage.protectionTitle')}</span>
        <span>{children}</span>
      </AlertProtectionWrapperText>
    </AlertProtectionWrapper>
  )
}
