import React, { FC } from 'react'

import { useTranslation } from 'react-i18next'

import { ReviewsOrVideos } from './styles'

interface SpotifyReviewsProps {
  reviewedReviews?: number
  is_feed?: boolean
  allReview?: number
}

export const SpotifyReviews: FC<SpotifyReviewsProps> = ({
  reviewedReviews,
  is_feed,
  allReview,
}) => {
  const { t } = useTranslation()
  const spotifyReview = `${reviewedReviews || 0}${!is_feed ? '/' : ''}${
    !is_feed ? allReview || 0 : ''
  }`
  return (
    <ReviewsOrVideos>
      {spotifyReview}
      &nbsp;
      {t('campaignsPage.amountReviews')}
    </ReviewsOrVideos>
  )
}
