import { FC } from 'react'

import { Skeleton } from 'antd'
import styled from 'styled-components'
import { SkeletonNodeProps } from 'antd/es/skeleton/Node'

import { MAX_TABLET_WIDTH } from 'src/Styled/variables'
export interface SkeletonLoaderProps {
  count?: number
  height?: number
  className?: string
}

const DEFAULT_HEIGHT = 120
const DEFAULT_COUNT = 5

const StyledSkeleton = styled(({ className, ...rest }: SkeletonNodeProps) => (
  <Skeleton.Node {...rest} rootClassName={className} />
))<{ $height: number }>`
  .ant-skeleton {
    width: 100%;
    border-radius: 16px;
  }
  & > .ant-skeleton-image {
    width: 100% !important;
    height: ${({ $height }) => $height}px !important;
    border-radius: 16px !important;
    @media screen and (max-width: ${MAX_TABLET_WIDTH}) {
      height: ${({ $height }) => $height * 2}px !important;
    }
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
`

export const SkeletonLoader: FC<SkeletonLoaderProps> = (props) => {
  const { count = DEFAULT_COUNT, height = DEFAULT_HEIGHT, className } = props

  const arrayFromCount = Array.from({ length: count })
    .fill(0)
    .map((_, index) => index)
  return (
    <Container className={className}>
      {arrayFromCount.map((item) => (
        <StyledSkeleton key={item} active={true} $height={height}>
          <div />
        </StyledSkeleton>
      ))}
    </Container>
  )
}
