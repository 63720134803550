import styled from 'styled-components'

import { colors, device, variables } from 'src/Styled/variables'

export const Wrapper = styled.div<{ alertType: string }>`
  padding: 9.5px 16px;
  font-size: 12px;
  gap: 2px;
  text-align: center;
  background-color: ${({ alertType }) =>
    alertType === 'green' ? colors.green : colors.yellow1};
  color: ${colors.white};
  z-index: 0;
  line-height: 18px;

  font-weight: ${variables.fontWeight600};
  font-size: ${variables.fontSize12};

  @media ${device.mobileMax} {
    padding: 8px 24px;
  }
`

export const Link = styled.a`
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  text-decoration: underline;
  color: ${colors.white};

  &:hover {
    color: ${colors.white};
    text-decoration: underline !important;
  }

  &:active {
    color: ${colors.white};
    text-decoration: underline;
  }
`
