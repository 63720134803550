import { FC } from 'react'

import OutsideClickHandler from 'react-outside-click-handler'
import styled from 'styled-components'

import CheckTrackFromSearch from 'src/Components/CheckTrackFromSearch'
import { colors, device } from 'src/Styled/variables'
import { CampaignPlatformTypeEnum, TikTokTrack, Track } from 'src/Types'

interface SearchTrackFromResultProps {
  tracks: TikTokTrack[] | Track[]
  onCheckTrack: (url: string) => void
  onClearTracks: () => void
  platform: CampaignPlatformTypeEnum
}
interface Props {
  isAbsolutePosition?: boolean
}

export const WrapperRelative = styled.div<Props>`
  top: 60px;
  border-radius: 8px;
  width: 100%;
  background-color: ${colors.white};
  @media ${device.mobileMin} {
    top: 56px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  }
`
export const SearchTrackFormResult: FC<SearchTrackFromResultProps> = ({
  tracks,
  onClearTracks,
  onCheckTrack,
  platform,
}) => {
  if (!tracks?.length) {
    return null
  }
  return (
    <OutsideClickHandler onOutsideClick={onClearTracks}>
      <WrapperRelative>
        <CheckTrackFromSearch
          onCheckTrack={onCheckTrack}
          tracks={tracks}
          platform={platform}
        />
      </WrapperRelative>
    </OutsideClickHandler>
  )
}
