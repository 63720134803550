import { FC } from 'react'

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import IconSuccessfully from 'src/Assets/Svg/successfully.svg?react'
import { colors, variables } from 'src/Styled/variables'
import dayJs from 'src/Helpers/dayjs'

const Container = styled.div`
  display: flex;
  width: 320px;
  padding: 40px 32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;

  @media (min-width: 567px) {
    padding: 40px 16px;
    width: 560px;
  }
`
const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
`

const Title = styled.div`
  color: ${colors.mainText};
  text-align: center;
  font-size: ${variables.fontSize18};
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
`

const Description = styled.div`
  color: ${colors.mainText};
  text-align: center;
  font-size: ${variables.fontSize14};
  font-weight: 400;
  line-height: 150%; /* 21px */
`

interface UnsubscribedProps {
  date: string
}

export const Unsubscribed: FC<UnsubscribedProps> = ({ date }) => {
  const { t } = useTranslation()
  const cancelDate = dayJs(date).format('DD MMM, YYYY')
  const text = `${t('getPremiumPage.yourSubscription')} ${cancelDate}`
  return (
    <Container>
      <IconSuccessfully />
      <TitleContainer>
        <Title>{t('getPremiumPage.unsubscribed')}</Title>
        <Description>{text}</Description>
      </TitleContainer>
    </Container>
  )
}
