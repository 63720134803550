import React, { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { colors, variables } from 'src/Styled/variables'

import { MobileBlockWrapper, SmallText } from '../../styles'

type ViewsInfoProps = {
  views: string
}

export const ValuesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 96px;
`
export const NormalText = styled.span`
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight400};
  line-height: 150%;
  color: ${colors.mainText};
  white-space: nowrap;
`

export const ViewsInfo: FunctionComponent<ViewsInfoProps> = ({ views }) => {
  const { t } = useTranslation()

  return (
    <MobileBlockWrapper>
      <ValuesContainer>
        <SmallText>{t('soundsPage.views')}</SmallText>
        <NormalText>{views}</NormalText>
      </ValuesContainer>
    </MobileBlockWrapper>
  )
}
