import { useState, useRef, useEffect, FC } from 'react'

import { Skeleton } from 'antd'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import axios from 'axios'

import { DottedLink } from 'src/Components/DottedLink'

import {
  DescriptionWrapper,
  ErrorTitle,
  LinkTiktok,
  PlayerError,
  PlayerWrapper,
  Wrapper,
} from './styles'

export const TIKTOK_OEMBED_BASE_URL = 'https://www.tiktok.com/oembed'

interface TikTokResponse {
  html: string
  status_msg: string
}

export interface TikTokProps {
  url?: string
}

export const TikTokPlayer: FC<TikTokProps> = ({ url }) => {
  const { t } = useTranslation()
  const [loaded, setLoaded] = useState(false)
  const [error, setError] = useState<Record<string, string> | null>(null)
  const [scriptSrc, setScriptSrc] = useState<string>()
  const [html, setHTML] = useState<string>()

  const ref = useRef(null)

  useEffect(() => {
    if (!('MutationObserver' in window)) {
      return setLoaded(true)
    }
    const elem = ref.current

    const observer = new MutationObserver((mutationList) => {
      const iframeAdded = mutationList.reduce<Node | null>(
        (accumulator, value) => {
          const iframe = Array.from(value.addedNodes).find(
            (node) => node.nodeName === 'IFRAME',
          )
          if (iframe) {
            accumulator = iframe
          }
          return accumulator
        },
        null,
      )

      if (iframeAdded) {
        iframeAdded.addEventListener('load', () => setLoaded(true))
      }
    })

    if (elem) {
      observer.observe(elem, {
        childList: true,
        attributes: true,
        subtree: true,
      })
    }
    return () => {
      observer.disconnect()
    }
  }, [])

  useEffect(() => {
    const getTikTokEmbed = async (): Promise<void> => {
      try {
        const { data } = await axios.get<TikTokResponse>(
          `${TIKTOK_OEMBED_BASE_URL}?url=${url}`,
        )
        if (data && data.status_msg) {
          throw new Error(data.status_msg)
        }

        if (!data || !data.html) {
          throw new Error("API response doesn't look right")
        }

        const htmlString = data.html

        const tempElement = document.createElement('div')
        tempElement.innerHTML = htmlString

        const [scriptTag] = tempElement.getElementsByTagName('script')

        setScriptSrc(scriptTag && scriptTag.src)
        setHTML(htmlString.substring(0, htmlString.indexOf('<script')))
      } catch (err) {
        setError(err as Record<string, string>)
      }
    }
    if (url) {
      void getTikTokEmbed()
    }
  }, [url])

  if (error) {
    return (
      <PlayerError>
        <ErrorTitle>{t('campaignResultsPage.previewNotAvailable')}</ErrorTitle>
        <DescriptionWrapper>
          <span>{t('campaignResultsPage.orOpenVideoOn')} </span>
          <LinkTiktok target='_blank' href={url}>
            {t('influencer.tikTok')}
          </LinkTiktok>
        </DescriptionWrapper>
      </PlayerError>
    )
  }

  return (
    <>
      <Helmet>
        <script id='ttEmbedder' async src={scriptSrc} />
      </Helmet>
      <Wrapper>
        {!loaded && (
          <Skeleton.Node
            active={true}
            style={{ height: '565px', width: '300px', borderRadius: '16px' }}
          >
            <div data-testid='player-skeleton' />
          </Skeleton.Node>
        )}
        <PlayerWrapper
          ref={ref}
          style={{ display: loaded && html ? 'flex' : 'none' }}
          dangerouslySetInnerHTML={{ __html: html || '' }}
        />
        {!error && (
          <DescriptionWrapper>
            <DottedLink target='_blank' href={url}>
              {t('campaignDetailsPage.openVideoTikTok')}
            </DottedLink>
          </DescriptionWrapper>
        )}
      </Wrapper>
    </>
  )
}
