import { useEffect } from 'react'

import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import { getAutoselectedSpotifyPlaylistGenres } from 'src/Redux/genres-process/api-actions'
import { getPlaylistById } from 'src/Redux/playlists-process/selectors'
import { NewPlaylist } from 'src/Types'
import { getAutoSelectedGenres } from 'src/Redux/genres-process/selectors'

import { getGenres, getLanguages, getPlaylistId } from '../PlayList/halpers'
export const useEditPlaylist = (): NewPlaylist => {
  const dispatch = useAppDispatch()
  const { search } = useCustomSearchParams()
  const playlistId = Number(search.get('editPlaylist'))
  const playlist = useAppSelector(getPlaylistById(playlistId))
  const spotifyPlaylistId = getPlaylistId(playlist?.playlist_url)
  const autoselectedGenres = useAppSelector(getAutoSelectedGenres)
  useEffect(() => {
    if (spotifyPlaylistId) {
      void dispatch(getAutoselectedSpotifyPlaylistGenres(spotifyPlaylistId))
    }
  }, [dispatch, spotifyPlaylistId])

  const updatedPlaylist: NewPlaylist = {
    id: Number(playlist?.id),
    selectedGenres:
      playlist?.PlaylistGenres && getGenres(playlist?.PlaylistGenres),
    selectedLanguages:
      playlist?.PlaylistLanguages && getLanguages(playlist?.PlaylistLanguages),
    relevantGenres: autoselectedGenres,
    playListInfo: {
      name: playlist?.playlist_name,
      img: playlist?.playlist_cover,
    },
  }

  return updatedPlaylist
}
