import { useCustomSearchParams } from './useCustomSearchParams'

export const useQuickLoginUTMParameters = (): Record<string, string> => {
  const { search } = useCustomSearchParams()

  const params = [
    'utm_source',
    'utm_campaign',
    'utm_content',
    'utm_medium',
    'trigger_source',
    'trigger_subject',
  ]

  const result: Record<string, string> = {}

  params.forEach((param) => {
    const value = search.get(param)
    if (value) {
      result[param] = value
    }
  })

  return result
}
