import { useCallback, useEffect, useState } from 'react'

import { useNavigate, useParams } from 'react-router-dom'

import {
  setCampaignBudget,
  setStartDate,
} from 'src/Redux/campaign-process/spotifyCampaignSchedule-process'
import { roundPriceValue } from 'src/Containers/ScheduleSpotifyWrapper/components/SpotifyCheckout/helpers'
import { checkCoupon } from 'src/Redux/schedule-process/couponSlice/api-actions'
import { usePayPalTransactionPayment } from 'src/Hooks/usePayPalTransactionPayment'
import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { CampaignPlatformTypeEnum } from 'src/Types'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import { getCampaignToSchedule } from 'src/Redux/campaign-process/spotifyCampaignSchedule-process/selectors'
import { couponCheckStatusIsSucceededSelector } from 'src/Redux/schedule-process/couponSlice/selectors'
import { ScheduleSteps } from 'src/Components/ScheduleTabs'
import { useGetBillingAgreementQuery } from 'src/Redux/billingAgreementApi'

export const usePayPalFirstTransactionPaymentSpotify = (): void => {
  const [isCharged, setIsCharged] = useState(false)

  const dispatch = useAppDispatch()
  const params = useParams()
  const navigate = useNavigate()
  const { search } = useCustomSearchParams()

  const autopay = search.get('autopay')
  const startDate = search.get('startDate')
  const coupon = search.get('coupon')
  const price = search.get('price')
  const error = search.get('error')
  const token = Boolean(search.get('token'))

  const { pay } = usePayPalTransactionPayment(CampaignPlatformTypeEnum.SPOTIFY)

  const scheduledSpotifyCampaign = useAppSelector(getCampaignToSchedule)
  const { data: billingData } = useGetBillingAgreementQuery()
  const couponCheckStatusIsSucceeded = useAppSelector(
    couponCheckStatusIsSucceededSelector,
  )

  const campaignId = Number(params.number)
  const checkoutStepUrl = `/campaigns/schedule/${campaignId}?step=${ScheduleSteps.CHECKOUT}`
  const roundedValue = roundPriceValue(Number(price))

  const setInitCampaignDataFromUrl = useCallback(() => {
    // set start date from url
    startDate && dispatch(setStartDate(String(startDate)))

    // set budget from url
    dispatch(setCampaignBudget(roundedValue))

    // set coupon from url
    coupon &&
      !couponCheckStatusIsSucceeded &&
      void dispatch(checkCoupon(coupon))
  }, [coupon, couponCheckStatusIsSucceeded, dispatch, roundedValue, startDate])

  useEffect(() => {
    if (error === 'save_token_error_campaign_payment') {
      setInitCampaignDataFromUrl()
      navigate(
        `${checkoutStepUrl}&payment=cancelled&token=${token}&isPayPalSaved=true`,
      )
    }
  }, [checkoutStepUrl, error, navigate, setInitCampaignDataFromUrl, token])

  useEffect(() => {
    if (autopay) {
      setInitCampaignDataFromUrl()

      const isConditionsForPayment =
        Boolean(billingData?.id) &&
        scheduledSpotifyCampaign.id &&
        scheduledSpotifyCampaign.start_date

      if (isConditionsForPayment && !isCharged) {
        setIsCharged(true)
        pay()
      }
    }
  }, [
    autopay,
    billingData?.id,
    isCharged,
    pay,
    scheduledSpotifyCampaign.id,
    scheduledSpotifyCampaign.start_date,
    setInitCampaignDataFromUrl,
  ])
}
