import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { InfluencerEvent } from 'src/Types'

import { ApiError, NameSpace, SliceStatus } from '../types'

import { getTikTokCanceledEvents } from './api-actions'
import { getRecentCampaignsCanceled } from './helpers'

export interface CanceledAlertsState {
  error: ApiError | null
  status: SliceStatus
  data: InfluencerEvent[]
  closedCanceledAlerts: number[]
}
const initialState: CanceledAlertsState = {
  error: null,
  status: SliceStatus.Idle,
  data: [],
  closedCanceledAlerts: [],
}

export const canceledAlerts = createSlice({
  initialState,
  name: NameSpace.CANCELED_ALERTS,
  reducers: {
    closeCampaignAlert: (state, { payload }: PayloadAction<number>) => {
      state.closedCanceledAlerts = [...state.closedCanceledAlerts, payload]
    },
  },
  extraReducers(builder) {
    builder.addCase(getTikTokCanceledEvents.pending, (state) => {
      state.status = SliceStatus.Loading
    })
    builder.addCase(getTikTokCanceledEvents.fulfilled, (state, { payload }) => {
      state.status = SliceStatus.Succeeded

      const campaignsCanceled = payload.data
      const recentCampaignsCanceled = getRecentCampaignsCanceled(
        campaignsCanceled,
      ).map(({ id }) => id)

      const newClosedCanceledAlerts = state.closedCanceledAlerts.filter((id) =>
        recentCampaignsCanceled.includes(id),
      )
      state.data = campaignsCanceled
      state.closedCanceledAlerts = newClosedCanceledAlerts
    })
    builder.addCase(getTikTokCanceledEvents.rejected, (state, { payload }) => {
      state.status = SliceStatus.Failed
      state.error = payload as ApiError
    })
  },
})

export const { closeCampaignAlert } = canceledAlerts.actions
