import { createSlice } from '@reduxjs/toolkit'

import { Playlist } from 'src/Types'
import { setApiError } from 'src/Hooks/redux'

import { ApiError, NameSpace } from '../types'

import {
  changePlaylistInformation,
  checkPlaylist,
  editPlaylistsLanguages,
  getPlaylists,
} from './api-actions'

export interface PlaylistsState {
  playlists: Array<Playlist>
  loading: boolean
  error: ApiError | null
}

const initialState: PlaylistsState = {
  playlists: [],
  loading: false,
  error: null,
}
export const playlistsProcess = createSlice({
  initialState,
  name: NameSpace.Playlists,
  reducers: {
    updateStatePlaylists: (
      state,
      { payload }: { payload: { Playlists: Array<Playlist> } },
    ) => {
      state.playlists = payload.Playlists
    },
  },
  extraReducers: (builder) => {
    // getPlaylists
    builder.addCase(getPlaylists.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getPlaylists.fulfilled, (state, action) => {
      state.playlists = action.payload.Playlists
      state.loading = false
      state.error = null
    })
    builder.addCase(getPlaylists.rejected, (state, action) => {
      state.loading = false
      state.error = setApiError(action)
    })
    // checkPlaylist
    builder.addCase(checkPlaylist.pending, (state) => {
      state.loading = true
    })
    builder.addCase(checkPlaylist.fulfilled, (state, action) => {
      state.playlists = [...state.playlists, action.payload]
      state.loading = false
      state.error = null
    })
    builder.addCase(checkPlaylist.rejected, (state, action) => {
      state.loading = false
      state.error = setApiError(action)
    })
    // editPlaylistsLanguages
    builder.addCase(editPlaylistsLanguages.pending, (state) => {
      state.loading = true
    })
    builder.addCase(editPlaylistsLanguages.fulfilled, (state, action) => {
      state.playlists = state.playlists.map(
        (item) =>
          action.payload.find((payloadItem) => payloadItem.id === item.id) ||
          item,
      )
      state.loading = false
      state.error = null
    })
    builder.addCase(editPlaylistsLanguages.rejected, (state, action) => {
      state.loading = false
      state.error = setApiError(action)
    })

    // changePlaylistInformation
    builder.addCase(changePlaylistInformation.pending, (state) => {
      state.loading = true
    })
    builder.addCase(changePlaylistInformation.fulfilled, (state) => {
      state.loading = false
      state.error = null
    })
    builder.addCase(changePlaylistInformation.rejected, (state, action) => {
      state.loading = false
      state.error = setApiError(action)
    })
  },
})

export const { updateStatePlaylists } = playlistsProcess.actions
