import { ReactNode } from 'react'

import { LoginInitial } from './components/LoginInitial'
import { LoginIsNotExist } from './components/LoginIsNotExist'
import { LoginSpotify } from './components/LoginSpotify'
import { LoginWithoutSignup } from './components/LoginWithoutSignup'
import { RegularLogin } from './components/RegularLogin'

export const MAP_LOGIN_FORM: Record<string, ReactNode> = {
  login: <LoginInitial />,
  regular: <RegularLogin />,
  spotify: <LoginSpotify />,
  withoutSignup: <LoginWithoutSignup />,
  userIsNotExist: <LoginIsNotExist />,
}
