import React, { FC } from 'react'

import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import { useTranslation } from 'react-i18next'

import { colors, variables } from 'src/Styled/variables'

import { TrackDetailsBody } from './TrackDetailsBody'

const Container = styled.div`
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  border-radius: 4px;
  background-color: ${colors.greyBg};
  @media (min-width: 767px) {
    justify-content: center;
    align-items: flex-start;
    gap: 40px;
    flex: 1 0 0;
  }
`

const Header = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`

const HeaderTitle = styled.div`
  color: ${colors.mainText};
  font-size: ${variables.fontSize18};
  font-style: normal;
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
`

const Description = styled.div`
  font-weight: ${variables.fontWeight400};
  color: ${colors.mainText};
  font-size: ${variables.fontSize14};
  font-style: normal;
  font-weight: ${variables.fontWeight400};
  line-height: 150%;
`
const TabletText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`
export interface TrackDetailsProps {
  title: string
  Icon: React.FC<React.SVGProps<SVGSVGElement>>
  coverUrl?: string
  trackName?: string
  trackAuthorName?: string
  duration?: string
  trackPreviewUrl?: string
}

export const TrackDetails: FC<TrackDetailsProps> = ({
  title,
  Icon,
  coverUrl,
  trackName,
  trackAuthorName,
  duration,
  trackPreviewUrl,
}) => {
  const isTablet = useMediaQuery({ query: '(min-width: 767px)' })
  const { t } = useTranslation()
  if (isTablet) {
    return (
      <Container>
        <Icon />
        <TabletText>
          <HeaderTitle>{title}</HeaderTitle>
          <Description>{t('createCampaignPage.signUpText')}</Description>
        </TabletText>
        <TrackDetailsBody
          coverUrl={coverUrl}
          duration={duration}
          trackAuthorName={trackAuthorName}
          trackName={trackName}
          trackPreviewUrl={trackPreviewUrl}
        />
      </Container>
    )
  }
  return (
    <Container>
      <Header>
        <Icon />
        <HeaderTitle>{title}</HeaderTitle>
      </Header>
      <TrackDetailsBody
        coverUrl={coverUrl}
        duration={duration}
        trackAuthorName={trackAuthorName}
        trackName={trackName}
        trackPreviewUrl={trackPreviewUrl}
      />
    </Container>
  )
}
