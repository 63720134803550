import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Track } from 'src/Types/index'
import { setApiError } from 'src/Hooks/redux'

import { NameSpace, SliceState, SliceStatus } from '../types'

import { checkTrackToCreateCampaign, getSpotifyTracks } from './api-actions'

export interface TracksState extends SliceState {
  tracks: Array<Track> | null
  trackSearchByTypeStatus: {
    keyword: boolean
    trackId: boolean
  }
}

const initialState: TracksState = {
  tracks: null,
  trackSearchByTypeStatus: {
    keyword: false,
    trackId: false,
  },
  status: SliceStatus.Idle,
  error: null,
}

export const spotifyTracksProcess = createSlice({
  initialState,
  name: NameSpace.SpotifyTracks,
  reducers: {
    setTracksToInitialState: (state): void => {
      state.tracks = null
      state.error = null
      state.status = SliceStatus.Idle
    },
    setPlatformTypeStatus: (
      state,
      { payload }: PayloadAction<{ keyword: boolean; trackId: boolean }>,
    ): void => {
      state.trackSearchByTypeStatus = payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSpotifyTracks.pending, (state): void => {
      state.status = SliceStatus.Loading
    })
    builder.addCase(getSpotifyTracks.fulfilled, (state, action): void => {
      state.status = SliceStatus.Succeeded
      state.error = null
      state.tracks =
        'tracks' in action.payload
          ? action.payload.tracks.items
          : [action.payload]
    })
    builder.addCase(getSpotifyTracks.rejected, (state, action): void => {
      state.status = SliceStatus.Failed
      state.error = setApiError(action)
      state.tracks = null
    })
    builder.addCase(checkTrackToCreateCampaign.pending, (state) => {
      state.status = SliceStatus.Loading
    })
    builder.addCase(checkTrackToCreateCampaign.rejected, (state, action) => {
      state.status = SliceStatus.Failed
      state.error = setApiError(action)
    })
    builder.addCase(checkTrackToCreateCampaign.fulfilled, (state) => {
      state.error = null
      state.status = SliceStatus.Succeeded
    })
  },
})

export const { setTracksToInitialState, setPlatformTypeStatus } =
  spotifyTracksProcess.actions
