import { FC } from 'react'

import { UserNotification } from 'src/Types'
import { NewReleaseResponse } from 'src/Redux/newReleaseApi'

import { TopBarNewRelease } from '../TopBarNewRelease'
import { NotificationsWrapper } from '../../styles'
import { ArtistNotification } from '../ArtistNotification'
import { HaveNotNotifications } from '../HaveNotNotifications'

interface TopBarNotificationContentProps {
  handleCreateAndStartSchedule?: () => void
  notifications?: Array<UserNotification>
  releaseData?: NewReleaseResponse
}
export const TopBarNotificationContent: FC<TopBarNotificationContentProps> = ({
  handleCreateAndStartSchedule,
  notifications,
  releaseData,
}) => {
  if (!notifications?.length) {
    return (
      <>
        {releaseData && (
          <TopBarNewRelease
            handleCreateAndStartSchedule={handleCreateAndStartSchedule}
            releaseData={releaseData}
          />
        )}
        <HaveNotNotifications />
      </>
    )
  }

  return (
    <NotificationsWrapper>
      {notifications?.map((notification) =>
        notification.isRelease ? (
          <TopBarNewRelease
            key={notification.id}
            handleCreateAndStartSchedule={handleCreateAndStartSchedule}
            releaseData={releaseData}
          />
        ) : (
          <ArtistNotification
            key={notification.id}
            firstName={notification?.Playlist?.Curator?.first_name || ''}
            lastName={notification?.Playlist?.Curator?.last_name || ''}
            photo={notification?.Playlist?.Curator?.user_photo_url || ''}
            date={notification?.date}
            playlistName={notification?.Playlist?.playlist_name || ''}
            playlistUrl={notification?.Playlist?.playlist_url || ''}
          />
        ),
      )}
    </NotificationsWrapper>
  )
}
