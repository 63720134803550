import styled from 'styled-components'

import { variables, colors, device } from 'src/Styled/variables'

export const DropdownContainer = styled.div`
  position: relative;
`

export const Trigger = styled.div`
  position: relative;
`

export const Menu = styled.div<{
  topSeparated?: boolean
  isRightOriented?: boolean
}>`
  position: absolute;
  right: ${({ isRightOriented }) => (isRightOriented ? 'unset' : 0)}px;
  margin-top: ${({ topSeparated }) => (topSeparated ? '0px' : '8px')};
  padding: 8px 16px 4px 16px;
  display: grid;
  width: ${({ isRightOriented }) => (isRightOriented ? '198' : '188')}px;
  background: ${colors.white};
  border-radius: ${({ topSeparated }) =>
    topSeparated ? '0 0 8px 8px' : '8px'};

  box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
  z-index: 999;

  @media ${device.mobileMax} {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    border-radius: 8px 8px 0 0;
  }
`

export const MenuItem = styled.div`
  padding: 8px 16px 12px 16px;
  cursor: pointer;
  display: grid;
  grid-template-columns: 16px 1fr;
  grid-column-gap: 16px;

  & svg {
    align-self: center;
  }
  & svg {
    path {
      fill: ${colors.greyTextWhiteBg};
    }
  }

  &:visited {
    color: ${colors.greyTextWhiteBg};
    & svg {
      path {
        fill: ${colors.greyTextWhiteBg};
      }
    }
  }

  &.active {
    color: ${colors.mainText};
    & > span {
      color: ${colors.mainText};
    }
    & svg {
      path {
        fill: ${colors.mainText};
      }
    }
  }
  &:hover {
    color: ${colors.mainText};

    & svg {
      path {
        fill: ${colors.mainText};
      }
    }
  }
`

export const MenuItemText = styled.span`
  font-size: ${variables.fontSize14};
  line-height: 150%;
  font-weight: ${variables.fontWeight400};
  color: ${colors.greyTextWhiteBg};

  a {
    color: ${colors.greyTextWhiteBg};
  }
  &:hover {
    color: ${colors.mainText};

    & svg {
      path {
        fill: ${colors.mainText};
      }
    }
  }
  &.active {
    color: ${colors.mainText};

    & svg {
      path {
        fill: ${colors.mainText};
      }
    }
  }
`

export const MenuItemIcon = styled.div`
  display: grid;
`

export const Separator = styled.div`
  margin: 16px 0 16px 0;
  height: 1px;
  background: ${colors.greyBlue};
`
