import styled from 'styled-components'
import Arrow from 'mdi-react/ChevronRightIcon'
import ReactLoading from 'react-loading'

import { colors } from 'src/Styled/variables'

export const BlockOr = styled.div`
  text-align: center;
  width: 100%;
  margin: 16px 0;
  position: relative;

  p {
    color: black !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 24px */
  }

  &:before,
  &:after {
    content: '';
    height: 1px;
    width: calc(50% - 90px);
    background: #eee;
    position: absolute;
    top: 10px;
  }

  &:before {
    left: 0;
  }

  &:after {
    right: 0;
  }
`
export const TextEmail = styled.div`
  color: ${colors.mainText};
  text-align: center;
  font-family: Segoe UI;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`
export const Form = styled.form``
export const FieldWrapper = styled.div`
  display: flex;
  margin-bottom: 24px;
  width: 100%;
  position: relative;
  flex-direction: column;
  width: 100%;
  margin: auto;

  input {
    height: 43px;
    background-color: ${colors.greyBg};
    border: 2px solid ${colors.greyTextLight} !important;
    border-radius: 5px;
    width: 100%;
    padding: 5px 10px;
    font-size: 12px;
    transition: border 0.3s;
    color: #646877;
    &::-webkit-input-placeholder {
      color: $color-additional;
    }
    &::-moz-placeholder {
      color: $color-additional;
    }
    /* Firefox 19+ */
    &:-moz-placeholder {
      color: $color-additional;
    }
    /* Firefox 18- */
    &:-ms-input-placeholder {
      color: $color-additional;
    }

    &:focus,
    &:active {
      outline: none;
    }
  }
`
export const ForgotPassword = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-align: center;
  margin: 0 0 16px;

  a {
    color: #646877;

    &:hover {
      color: #1db954;
      text-decoration: none;
    }
  }
`
export const Loader = styled(ReactLoading)`
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: 24px;
    width: 24px;
    margin: 0;
  }
`
export const ArrowRight = styled(Arrow)`
  height: 24px !important;
  width: 24px !important;
  margin-right: 0 !important;
`
