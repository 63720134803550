import styled from 'styled-components'

export const CoverImageWrapper = styled.div`
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  margin-top: 5px;
`
export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;
  max-width: 320px;
`
export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`
export const ScoreInfo = styled.div`
  font-size: 10px;
`
