import styled from 'styled-components'

import { variables } from 'src/Styled/variables'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  line-height: 21px;
`
export const Title = styled.h4`
  font-weight: ${variables.fontWeight600};
  font-size: 18px;
  margin-bottom: 15px !important;
  text-align: center;
`
export const Text = styled.p`
  font-weight: ${variables.fontWeight400};
  font-size: ${variables.fontSize14};
  margin-bottom: 21px;
  margin-top: 0;
  &:nth-of-type(4) {
    margin-bottom: 35px;
  }
  text-align: center;
`
export const SubTitle = styled.h5`
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight600};
  margin-bottom: 3px;
  line-height: 18px;
  text-align: center;
`
