import styled from 'styled-components'

import { device } from 'src/Styled/variables'

export const TableHeaderContainer = styled.div`
  margin-bottom: 16px;
  display: grid;
  grid-column-gap: 16px;
  padding: 0 24px;
  grid-template-columns: 1fr 1fr 1fr 1.27fr;
`

export const TableList = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2px;

  @media ${device.mobileMax} {
    row-gap: 8px;
  }
`
export const Value = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`
export const LoaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`
