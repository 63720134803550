import { FC } from 'react'

import styled from 'styled-components'
import { Modal, ModalProps } from 'antd'

import { colors } from 'src/Styled/variables'

const CustomModal: FC<ModalProps> = ({ className, ...rest }) => (
  <Modal {...rest} rootClassName={className} />
)

export const AntdModal = styled(CustomModal)`
  .ant-modal {
    top: 50%;
    transform: translateY(-50%) !important;
  }
`

export const ContentContainer = styled.div`
  top: 50% !important;
  padding: 24px;
  background-color: ${colors.white};
`
