import ReactLoading from 'react-loading'
import styled from 'styled-components'

import { colors } from 'src/Styled/variables'

export const IconContainer = styled.div`
  display: flex;
`

export const Loading = styled(ReactLoading)`
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: 24px;
    width: 24px;
    margin: 0;
  }
`

export const RefreshBtnMobile = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  border: none;
  background-color: ${colors.white};
`
export const ButtonBody = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`
