import { FC } from 'react'

import styled from 'styled-components'

import { variables } from 'src/Styled/variables'

export const List = styled.ul`
  margin: 0;
  padding: 0 0 0 21px;
`
export const ListItem = styled.li`
  font-size: ${variables.fontSize14};
  line-height: 150%;
  font-weight: ${variables.fontWeight400};

  &::marker {
    font-size: 11px;
  }
`

interface ListProps {
  listItems: string[]
}

export const TextList: FC<ListProps> = ({ listItems }) => (
  <List>
    {listItems.map((item) => (
      <ListItem key={item}>{item}</ListItem>
    ))}
  </List>
)
