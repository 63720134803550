import styled from 'styled-components'

import { variables, colors } from 'src/Styled/variables'

export const ButtonWrapper = styled.div`
  margin: 20px auto;
`

interface Props {
  error?: boolean
}
export const StyledInput = styled.input<Props>`
  height: 47px !important;
  font-size: ${variables.fontSize14};
  border: ${({ error }) =>
    error
      ? `1px solid ${colors.messegesRed}`
      : `1px solid ${colors.greyStroke}`};
  border-radius: 8px;
  outline: none;
  padding: 16px;
  width: 100%;
  margin-bottom: ${({ error }) => (error ? '8px' : '24px')};
  background-color: ${colors.white};
  line-height: 21px;
  color: ${colors.mainText};

  &::-webkit-input-placeholder {
    color: ${colors.greyTextWhiteBg};
  }

  &:focus {
    background-color: ${colors.white};
    outline: none;
    color: ${colors.mainText};
  }
  &:active {
    background-color: ${colors.white};
    outline: none;
    color: ${colors.mainText};
  }
`

export const ErrorText = styled.div`
  text-align: left;
  color: ${colors.messegesRed};
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight400};
  margin-top: 0px;
  margin-bottom: 8px;
  margin-bottom: 24px;
`
