export const variables = {
  fontWeight600: '600',
  fontWeight400: '400',
  fontSize28: '28px',
  fontSize24: '24px',
  fontSize20: '20px',
  fontSize18: '18px',
  fontSize16: '16px',
  fontSize14: '14px',
  fontSize12: '12px',
  fontSize10: '10px',

  lineHeight150per: '150%',
}

export const colors = {
  black: '#000',
  green: '#1CB954',
  white: '#ffffff',
  green1Light: '#DDF5E5',
  yellow: '#FFDA5A',
  orange: '#FBBF00',
  yellow1: '#F19236',
  red: '#FD2C55',
  softOrange: '#FCE9D7',
  blue: '#0070BA',
  deepBlue: '#003fffdb',
  pink: '#ffbcbc',
  badgeGreen: '#1db954',
  badgeOrange: '#ff8d00',
  badgeRed: '#ff4861',
  rejectedRed: '#FFDFE5',
  errorRed: '#ad4444',
  mainBackground: '#f2f4f7',
  mainText: '#646877',
  greyText: '#dddddd',
  greyTextLight: '#eaeaea',
  greyTextWhiteBg: '#999999',
  greyTextBlackBg: '#CCCCCC',
  greyStroke: '#EEEEEE',
  greyBg: '#F9F9F9',
  greyBlue: '#F3F4F7',
  errorBg: '#FBFBFB',
  lightGreyButton: '#828282',

  messegesGreen1: '#58B660',
  messegesGreen2: '#67DD69',
  messegesYellow1: '#F19236',
  messegesYellow2: '#F4A85E',
  messegesRed: '#EC5765',

  playerTrackline: '#1F3532',
  playerBackground: '#25675f',

  progressMain: '#ddf5e5',
  progressActive: '#1cb954',
}

export const dimensions = {
  mobileBorder: 576,
}

const size = {
  mobileMinimum: '390px',
  mobileMin: '576px',
  tablet: '768px',
  smallScreen: '1024px',
  largeScreen: '1440px',
}

export const device = {
  mobileMinimumMin: `(min-width: ${size.mobileMinimum})`,
  mobileMinimumMax: `(max-width: ${size.mobileMinimum})`,
  mobileMin: `(min-width: ${size.mobileMin})`,
  mobileMax: `(max-width: ${size.mobileMin})`,
  tabletMin: `(min-width: ${size.tablet})`,
  tabletMax: `(max-width: ${size.tablet})`,
  smallScreen: `(min-width: ${size.smallScreen})`,
  smallScreenMax: `(max-width: ${size.smallScreen})`,
  largeScreen: `(min-width: ${size.largeScreen})`,
}

export const colorPalette = {
  lightGrey: '#E7E7E7',
  mediumGrey: '#CACACA',
  white: '#FFFFFF',
  brandGreenW: '#1CB954',
  darkGreen: '#12A044',
  darkGrey: '#333333',
  black: '#000000',
  brandGreenOnBlack: '#06D960',
  brandGreenLight: '#D3F8E0',
  mediumOrange: '#e69d15',
  slateGrey: '#A3A3A3',
  mediumRed: '#EC1E1E',
  pink: '#e0536f',
}
export const themeColors = {
  light: {
    progress: {
      primaryBg: colorPalette.lightGrey,
      secondaryBg: colorPalette.mediumGrey,
      thirdBg: colorPalette.brandGreenW,
    },
    button: {
      primaryBg: colorPalette.white,
      secondaryBg: colorPalette.lightGrey,
      thirdBg: colorPalette.brandGreenW,
      fourthBg: colorPalette.darkGreen,
      primaryBorder: colorPalette.lightGrey,
      secondaryBorder: colorPalette.mediumGrey,
      primaryText: colorPalette.brandGreenW,
      secondaryText: colorPalette.lightGrey,
      thirdText: colorPalette.darkGrey,
      fourthText: colorPalette.white,
      fifthText: colorPalette.mediumGrey,
      orangeText: colorPalette.mediumOrange,
      redText: colorPalette.mediumRed,
    },
    common: {
      primaryBg: colorPalette.white,
      secondaryBg: colorPalette.black,
      thirdBg: colorPalette.darkGrey,
      fourthBg: colorPalette.brandGreenLight,
      primaryText: colorPalette.darkGrey,
      secondaryText: colorPalette.mediumGrey,
      thirdText: colorPalette.lightGrey,
      fourthText: colorPalette.white,
      fifthText: colorPalette.mediumOrange,
      secondaryBorder: colorPalette.brandGreenW,
      primaryBorder: colorPalette.lightGrey,
      thirdBorder: colorPalette.mediumGrey,
      primaryDetails: colorPalette.brandGreenOnBlack,
      primaryPlaceholder: colorPalette.slateGrey,
      sixthText: colorPalette.brandGreenW,
      dangerBorder: colorPalette.mediumRed,
      dangerText: colorPalette.mediumRed,
      pink: colorPalette.pink,
    },
    alert: {
      success: colorPalette.brandGreenW,
      danger: colorPalette.mediumRed,
      warning: colorPalette.mediumOrange,
    },
  },
  dark: {
    button: {
      primaryBg: colorPalette.white,
      secondaryBg: colorPalette.lightGrey,
      thirdBg: colorPalette.brandGreenW,
      fourthBg: colorPalette.darkGreen,
      primaryBorder: colorPalette.lightGrey,
      primaryText: colorPalette.brandGreenW,
      secondaryText: colorPalette.lightGrey,
      thirdText: colorPalette.darkGrey,
      fourthText: colorPalette.white,
    },
    common: {
      primaryBg: colorPalette.white,
      secondaryBg: colorPalette.black,
      primaryText: colorPalette.darkGrey,
      secondaryText: colorPalette.mediumGrey,
      thirdText: colorPalette.lightGrey,
      fourthText: colorPalette.white,
      secondaryBorder: colorPalette.brandGreenW,
      primaryBorder: colorPalette.lightGrey,
      primaryDetails: colorPalette.brandGreenOnBlack,
    },
    alert: {
      success: colorPalette.brandGreenW,
      danger: colorPalette.mediumRed,
      warning: colorPalette.mediumOrange,
    },
  },
}

export const MAX_TABLET_WIDTH = '1023px'
export const MAX_MOBILE_WIDTH = '767px'
