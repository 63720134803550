import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import {
  Profile,
  EventDetails,
  Events,
  Pagination,
  InfluencerEvent,
  Meta,
  InfluencerEventStatus,
  CampaignVideo,
} from 'src/Types'
import { TabKeys } from 'src/Containers/Tracks/helpers'
import { setApiError } from 'src/Hooks/redux'

import { ApiError, NameSpace } from '../types'

import {
  acceptTrack,
  EventsStatistics,
  getEventById,
  getEventsStatistics,
  getInProgressTikTokInfluencerEvents,
  getNewTikTokInfluencerEvents,
  getSubmittedTikTokInfluencerEvents,
  uploadVideo,
} from './api-actions'

export interface InfluencerState {
  creatorEventsPageActiveTab: TabKeys
  newEvents: {
    events: Array<InfluencerEvent>
    meta: Meta | null
  }
  inProgressEvents: {
    events: Array<InfluencerEvent>
    meta: Meta | null
  }
  submittedEvents: {
    events: Array<InfluencerEvent>
    meta: Meta | null
  }
  profile: Profile | Record<string, never>
  closedCanceledAlerts: number[]
  campaignsPagination: Pagination
  eventStatistics: EventsStatistics
  eventDetails?: EventDetails | null
  events: Events | Record<string, never>
  openedCampaignId: number
  token: string
  eventDetailsId: number | null
  tikTokVideo: {
    campaignId: number
    paidAmount: number
    trackId: string
  } | null
  error: ApiError | null
  uploadVideoError: ApiError | null
  videos: CampaignVideo[]
  loading: boolean
}

const initialState: InfluencerState = {
  creatorEventsPageActiveTab: TabKeys.NEW,
  newEvents: {
    events: [],
    meta: null,
  },
  inProgressEvents: {
    events: [],
    meta: null,
  },
  submittedEvents: {
    events: [],
    meta: null,
  },
  tikTokVideo: null,
  profile: {},
  campaignsPagination: {
    pageNumber: 0,
    pageSize: 0,
    itemCount: 0,
    pageCount: 0,
    hasPreviousPage: false,
    hasNextPage: false,
  },
  eventStatistics: {
    new: 0,
    accepted: 0,
    approved_automatically: 0,
    approved_by_admin: 0,
    approved_by_artist: 0,
    awaiting_moderator_review: 0,
    awaiting_review: 0,
    campaign_stopped: 0,
    canceled: 0,
    canceled_by_artist: 0,
    declined: 0,
  },
  closedCanceledAlerts: [],
  events: {},
  openedCampaignId: 0,
  token: '',
  videos: [],
  eventDetailsId: null,
  loading: false,
  error: null,
  uploadVideoError: null,
}

export interface TData {
  message: string
  id: number | string
}

export const influencerProcess = createSlice({
  initialState,
  name: NameSpace.Influencer,
  reducers: {
    setOfferedCampaigns: (state, { payload }: PayloadAction<TData>) => {
      state.newEvents.events = state.newEvents.events.map((campaign) =>
        campaign.id === payload.id
          ? { ...campaign, isNoLongerAvailableTrack: payload.message }
          : campaign,
      )
    },
    openCampaignDetails: (state, { payload }: PayloadAction<number>) => {
      state.openedCampaignId = payload
    },
    setCreatorPageActiveTab: (state, { payload }: PayloadAction<TabKeys>) => {
      state.creatorEventsPageActiveTab = payload
    },

    setEventDetails: (
      state,
      { payload }: PayloadAction<EventDetails | null>,
    ) => {
      state.eventDetails = payload
    },
    setID: (state, { payload }: PayloadAction<number>) => {
      state.eventDetailsId = payload
    },
    closeCampaignDetails: (state) => {
      state.openedCampaignId = 0
    },
  },
  extraReducers: (builder) => {
    // getOfferedCampaigns
    builder.addCase(getNewTikTokInfluencerEvents.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      getNewTikTokInfluencerEvents.fulfilled,
      (state, { payload }) => {
        state.loading = false

        const mappedPayload = payload.data.map((item) => ({
          ...item,
          isNoLongerAvailableTrack: '',
          status: InfluencerEventStatus.NEW,
        }))

        if (payload.meta.pageNumber === 1) {
          state.newEvents.events = mappedPayload
          state.newEvents.meta = payload.meta
          return
        }
        state.newEvents.events = [...state.newEvents.events, ...mappedPayload]
        state.newEvents.meta = payload.meta
      },
    )
    builder.addCase(getNewTikTokInfluencerEvents.rejected, (state, action) => {
      state.loading = false
      state.error = setApiError(action)
    })

    // getEventsStatistics
    builder.addCase(getEventsStatistics.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getEventsStatistics.fulfilled, (state, { payload }) => {
      state.loading = false
      state.eventStatistics = payload
    })
    builder.addCase(getEventsStatistics.rejected, (state, action) => {
      state.loading = false
      state.error = setApiError(action)
    })

    // getEventById
    builder.addCase(getEventById.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getEventById.fulfilled, (state, { payload }) => {
      state.loading = false
      state.eventDetails = payload
    })
    builder.addCase(getEventById.rejected, (state, action) => {
      state.loading = false
      state.error = setApiError(action)
    })
    // uploadVideo
    builder.addCase(uploadVideo.pending, (state) => {
      state.loading = true
    })
    builder.addCase(uploadVideo.fulfilled, (state, { payload }) => {
      state.loading = false
      state.tikTokVideo = payload
      state.uploadVideoError = null
    })
    builder.addCase(uploadVideo.rejected, (state, action) => {
      state.loading = false
      state.uploadVideoError = setApiError(action)
    })

    // acceptTrack
    builder.addCase(acceptTrack.pending, (state) => {
      state.loading = true
    })
    builder.addCase(acceptTrack.fulfilled, (state) => {
      state.loading = false
    })
    builder.addCase(acceptTrack.rejected, (state, action) => {
      state.loading = false
      state.error = setApiError(action)
    })

    // getTikTokInfluencerEvents
    builder.addCase(getSubmittedTikTokInfluencerEvents.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      getSubmittedTikTokInfluencerEvents.fulfilled,
      (state, { payload }) => {
        state.loading = false

        if (payload.meta.pageNumber === 1) {
          state.submittedEvents.events = payload.data
          state.submittedEvents.meta = payload.meta
          return
        }
        state.submittedEvents.events = [
          ...state.submittedEvents.events,
          ...payload.data,
        ]
        state.submittedEvents.meta = payload.meta
      },
    )
    builder.addCase(
      getSubmittedTikTokInfluencerEvents.rejected,
      (state, action) => {
        state.loading = false
        state.error = setApiError(action)
      },
    )

    // getInProgressTikTokInfluencerEvents
    builder.addCase(getInProgressTikTokInfluencerEvents.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      getInProgressTikTokInfluencerEvents.fulfilled,
      (state, { payload }) => {
        state.loading = false

        if (payload.meta.pageNumber === 1) {
          state.inProgressEvents.events = payload.data
          state.inProgressEvents.meta = payload.meta
          return
        }
        state.inProgressEvents.events = [
          ...state.inProgressEvents.events,
          ...payload.data,
        ]
        state.inProgressEvents.meta = payload.meta
      },
    )
    builder.addCase(
      getInProgressTikTokInfluencerEvents.rejected,
      (state, action) => {
        state.loading = false
        state.error = setApiError(action)
      },
    )
  },
})

export const {
  setOfferedCampaigns,
  openCampaignDetails,
  closeCampaignDetails,
  setEventDetails,
  setID,
  setCreatorPageActiveTab,
} = influencerProcess.actions
