import { FunctionComponent, ReactElement } from 'react'

import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import Button from 'src/Components/Buttons/Button'
import { getEventDetails } from 'src/Redux/influencer-process/selectors'
import TikTokIcon from 'src/Assets/Svg/tiktok.svg?react'
import StarYellowIcon from 'src/Assets/Svg/star-yellow.svg?react'
import StarGreyIcon from 'src/Assets/Svg/star-grey.svg?react'

import { Container, ButtonsGroups } from '../styles'

import { CompleteProps } from './types'

import {
  Title,
  RatingWrapper,
  StyledInnerButton,
  Text,
  ReviewWrapper,
  StyledButton,
} from './styles'

const renderRating = (rating: number): ReactElement[] => {
  const ratingArray = Array.from({ length: 5 })
  for (let i = 0; i < Number(rating); i += 1) {
    ratingArray[i] = true
  }
  return ratingArray?.map((el, index) =>
    el ? (
      <StarYellowIcon key={`${index.toString()}`} />
    ) : (
      <StarGreyIcon key={`${index.toString()}`} />
    ),
  )
}

export const Complete: FunctionComponent<CompleteProps> = ({ t, onClose }) => {
  const eventDetails = useSelector(getEventDetails)
  const stars = renderRating(eventDetails?.stars || 0)
  return (
    <Container>
      <ReviewWrapper>
        <div>
          <Title>{t('influencer.respondFromArtist')}</Title>
          <RatingWrapper>{stars}</RatingWrapper>
        </div>
        <div>
          <Title>{t('influencer.feedback')}</Title>
          <Text>{eventDetails?.feedback}</Text>
        </div>
      </ReviewWrapper>
      <ButtonsGroups>
        <Link to={eventDetails?.tiktokVideoUrl || ''} target='_blank'>
          <StyledButton>
            <Button type='whiteWithGreenBorder'>
              <StyledInnerButton>
                <TikTokIcon />
                {t('influencer.videoOnTiktok')}
              </StyledInnerButton>
            </Button>
          </StyledButton>
        </Link>
        <StyledButton>
          <Button type='white' onClick={onClose}>
            {t('influencer.closeBtn')}
          </Button>
        </StyledButton>
      </ButtonsGroups>
    </Container>
  )
}
