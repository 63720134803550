import { createDraftSafeSelector } from '@reduxjs/toolkit'

import { RootState } from 'src/Redux'

import { CountryState } from '.'

const getState = (state: RootState): CountryState =>
  state.authentication.country

export const getCountry = createDraftSafeSelector(
  getState,
  (state) => state.country || 'US',
)
