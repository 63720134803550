import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { colors, variables } from 'src/Styled/variables'
import { Genre } from 'src/Types'
import { capitalizeFirstLetter } from 'src/Helpers/layoutHelpers'

import { SelectOption } from './props'

const ModalTitle = styled.h5`
  font-size: ${variables.fontSize14};
  color: ${colors.mainText};
  font-weight: ${variables.fontWeight400};
  margin-bottom: 8px;
  display: flex;
  line-height: 18px;
  justify-content: space-between;
`
const GenreButton = styled.button`
  color: ${colors.green};
  border-radius: 4px;
  font-size: ${variables.fontSize14};
  background-color: rgba(28, 185, 84, 0.15);
  padding: 11px 14px 9px 14px;
  line-height: 21px;
  animation: fadeIn 0.5s ease-in-out;
  margin-right: 8px;
  margin-bottom: 8px;
`

interface RelevantGenresProps {
  relevantGenres: Genre[]
  selectedInputGenres: SelectOption[]
  onCheckRelevantGenre: (item: Genre) => void
}

export const RelevantGenres: FC<RelevantGenresProps> = ({
  relevantGenres,
  selectedInputGenres,
  onCheckRelevantGenre,
}) => {
  const { t } = useTranslation()
  const inputRelevantGenres = relevantGenres.filter(
    (item) =>
      !selectedInputGenres.find((selected) => selected.value === item.id),
  )
  return (
    <>
      <ModalTitle>{t('scheduleCampaignPage.genresSelectLabel')}</ModalTitle>
      {inputRelevantGenres.map((item) => (
        <GenreButton
          type='button'
          key={item.id}
          onClick={() => onCheckRelevantGenre(item)}
        >
          {capitalizeFirstLetter(item.genre_name || '')}
        </GenreButton>
      ))}
    </>
  )
}
