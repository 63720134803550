import { FunctionComponent, useState } from 'react'

import { useSubmittedSoundsQuery } from 'src/Redux/creatorSubmittedSoundsApi'

import { SoundsList } from '../index'
import { SubmittedSoundsListItem } from '../../SoundsListItem/components/SubmittedSoundsListItem'

export const SubmittedSoundsList: FunctionComponent = () => {
  const [pageNumber, setPageNumber] = useState(1)

  const {
    data: submittedSoundsData,
    isLoading,
    isFetching,
  } = useSubmittedSoundsQuery(pageNumber)

  const submittedSounds = submittedSoundsData?.data || []
  const hasNextPage = submittedSoundsData?.meta.hasNextPage || false

  const loadMore = (): void => {
    setPageNumber(pageNumber + 1)
  }

  return (
    <SoundsList
      soundsAmount={submittedSounds.length}
      hasNextPage={hasNextPage}
      isLoading={isLoading || isFetching}
      onLoadMore={loadMore}
    >
      {submittedSounds.map((item) => (
        <SubmittedSoundsListItem
          key={item.id}
          id={item.id}
          trackAuthorName={item.trackAuthorName}
          trackCoverUrl={item.trackCoverUrl}
          trackTiktokId={item.trackTiktokId}
          trackTitle={item.trackTitle}
          currentViews={item.views}
          reward={item.reward}
          status={item.status}
          isPaymentReceived={item.isPaymentReceived}
          minViews={item.minViews}
        />
      ))}
    </SoundsList>
  )
}
