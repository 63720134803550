import { useEffect } from 'react'

import { useNavigate, useParams } from 'react-router-dom'

import { setPaymentAlertError } from 'src/Redux/payment-process'
import { PaymentErrors } from 'src/Components/PaymentErrorsAlert'
import { ScheduleSteps } from 'src/Components/ScheduleTabs'
import { roundPriceValue } from 'src/Containers/ScheduleSpotifyWrapper/components/SpotifyCheckout/helpers'
import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { updateCampaignPrice } from 'src/Redux/schedule-process/audienceTiktokSlice'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import { getScheduledTikTokCampaign } from 'src/Redux/schedule-process/tiktokCampaign/selectors'
import { checkCoupon } from 'src/Redux/schedule-process/couponSlice/api-actions'
import { couponCheckStatusIsSucceededSelector } from 'src/Redux/schedule-process/couponSlice/selectors'

import { useChangeStartDate } from './useChangeStartDate'

export enum PaymentStatusEnum {
  SUCCESS = 'success',
  FAILURE = 'failure',
  ERROR = 'error',
}

export const useBackFromPaymentPageTikTok = (): void => {
  const dispatch = useAppDispatch()
  const params = useParams()
  const navigate = useNavigate()
  const scheduledTikTokCampaign = useAppSelector(getScheduledTikTokCampaign)
  const couponCheckStatusIsSucceeded = useAppSelector(
    couponCheckStatusIsSucceededSelector,
  )
  const { search } = useCustomSearchParams()
  const { handleChangeDate } = useChangeStartDate()

  const urlStartDate = search.get('startDate')
  const errorType = search.get('errorType')
  const paymentStatus = search.get('paymentStatus')
  const urlBudget = search.get('budget')
  const baToken = search.get('ba_token')
  const coupon = search.get('coupon')
  const token = Boolean(search.get('token'))
  const priceFromQuery = String(search.get('price'))

  const campaignId = Number(params.number)

  const checkoutStepUrl = `/campaigns/scheduleTikTok/${campaignId}?step=${ScheduleSteps.CHECKOUT}`

  useEffect(() => {
    const isBackFromPaymentPageWithoutError =
      paymentStatus === PaymentStatusEnum.FAILURE && urlStartDate

    const isBackFromPaymentPageWithError =
      paymentStatus === PaymentStatusEnum.ERROR && !baToken && urlStartDate

    if (isBackFromPaymentPageWithoutError || isBackFromPaymentPageWithError) {
      isBackFromPaymentPageWithError &&
        dispatch(
          setPaymentAlertError(
            (errorType as PaymentErrors) || PaymentErrors.DEFAULT,
          ),
        )

      // set start date from url
      !scheduledTikTokCampaign.startDate &&
        urlStartDate &&
        handleChangeDate(urlStartDate)

      coupon &&
        !couponCheckStatusIsSucceeded &&
        void dispatch(checkCoupon(coupon))

      const roundedValue = roundPriceValue(urlBudget || 0)
      dispatch(updateCampaignPrice(roundedValue))
      navigate(checkoutStepUrl)
    }

    if (paymentStatus === PaymentStatusEnum.FAILURE) {
      const roundedValue = roundPriceValue(urlBudget || 0)
      dispatch(updateCampaignPrice(roundedValue))

      navigate(
        `${checkoutStepUrl}&payment=cancelled&price=${priceFromQuery}&token=${token}`,
      )
    }
  }, [
    baToken,
    campaignId,
    checkoutStepUrl,
    coupon,
    couponCheckStatusIsSucceeded,
    dispatch,
    errorType,
    handleChangeDate,
    navigate,
    paymentStatus,
    scheduledTikTokCampaign.startDate,
    urlBudget,
    urlStartDate,
    token,
    priceFromQuery,
  ])
}
