import styled from 'styled-components'

import { colors, variables } from 'src/Styled/variables'

export const CardsWrapper = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 20px;
  @media (max-width: 1023px) {
    overflow-x: scroll;
    flex-direction: row-reverse;
  }
`

export const BookingTotalDescription = styled.h5`
  font-weight: ${variables.fontWeight400};
  font-size: ${variables.fontSize14};
  color: ${colors.mainText};
  margin-bottom: 16px;
  line-height: 21px;
`
interface Props {
  green?: boolean
}
export const BookingTotalTitle = styled.h5<Props>`
  font-weight: ${variables.fontWeight600};
  font-size: ${variables.fontSize20};
  color: ${({ green }) => (green ? colors.green : colors.mainText)};
  white-space: nowrap;
  line-height: 30px;
`
export const BalanceCard = styled.div`
  background-color: ${colors.white};
  border-radius: 4px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (max-width: 1023px) {
    min-width: 228px;
    max-width: 228px;
  }
`
export const RequestPayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 24px 0px 24px;
  @media (max-width: 1023px) {
    min-width: 228px;
    max-width: 228px;
  }
`

export const MobileRequestPayout = styled.div`
  position: fixed;
  z-index: 5;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${colors.white};
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  gap: 35px;
  padding: 12px 16px;
`
