import { useEffect } from 'react'

import {
  useAddFeatureForUserMutation,
  useGetUserFeaturesQuery,
} from 'src/Redux/A-BFeaturesApi'
import {
  getIsArtistNotFromInvitedLink,
  useGetArtistBalanceHistoryQuery,
} from 'src/Redux/artistBalanceHistoryApi'

import {
  getHasPaidCampaignMetadata,
  useMetadataQuery,
} from '../Redux/metadataApi'

import { useCustomSearchParams } from './useCustomSearchParams'

export const QUICK_CHECKOUT_FEATURE_NAME = 'quick_checkout'
export const EXPIRED_TIMER_FEATURE_VALUE = 4

export const useCheckoutTimerFeatureFlag = (): void => {
  const { data } = useMetadataQuery()

  const { search } = useCustomSearchParams()

  const paymentStatus = search.get('paymentStatus')
  const isFirstPaid = getHasPaidCampaignMetadata(data)
  const { data: featureValue, isSuccess: featuresIsSucceeded } =
    useGetUserFeaturesQuery(null, {
      selectFromResult: (result) => ({
        ...result,
        data: result?.data?.features?.find(
          (item) => item?.name === QUICK_CHECKOUT_FEATURE_NAME,
        )?.variation,
      }),
    })
  const [addFeatureForUser] = useAddFeatureForUserMutation()
  const { isArtistNotFromInvitedLink } = useGetArtistBalanceHistoryQuery(null, {
    selectFromResult: getIsArtistNotFromInvitedLink,
  })

  const isNoFeature = featuresIsSucceeded && !featureValue && featureValue !== 0

  const isQuickCheckoutFeatureAvailable =
    (isNoFeature || featureValue === EXPIRED_TIMER_FEATURE_VALUE) &&
    isArtistNotFromInvitedLink &&
    !isFirstPaid

  const isSuccessPageVisible = paymentStatus === 'success'

  // In case payment is successful, we need to drop the feature for the user
  useEffect(() => {
    if (isSuccessPageVisible) {
      addFeatureForUser({
        featureName: QUICK_CHECKOUT_FEATURE_NAME,
        variation: 0,
      })
    }
  }, [addFeatureForUser, isSuccessPageVisible])

  useEffect(() => {
    if (isQuickCheckoutFeatureAvailable) {
      addFeatureForUser({
        featureName: QUICK_CHECKOUT_FEATURE_NAME,
        variation: 1,
      })
    }
  }, [addFeatureForUser, isQuickCheckoutFeatureAvailable])
}
