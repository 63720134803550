import { capitalizeFirstLetter } from 'src/Helpers/layoutHelpers'
import { Language, SelectedLanguage } from 'src/Types'

export const optionsSelectLanguages = (
  options: Array<Language>,
): Array<SelectedLanguage> => {
  if (!options) {
    return []
  }

  const languagesOptions = options.map((item) => ({
    value: item.id,
    label: capitalizeFirstLetter(item?.language_name || ''),
  }))
  return [...languagesOptions]
}
