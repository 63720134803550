import { FC, ReactNode } from 'react'

import styled from 'styled-components'

import CompleteIcon from 'src/Assets/Svg/pages-icons/CheckMark.svg?react'
import ActiveIcon from 'src/Assets/Svg/pages-icons/RightArrowLong.svg?react'

export enum StepButtonStatus {
  active = 'active',
  completed = 'completed',
  disabled = 'disabled',
}

interface StepButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  status: StepButtonStatus
  children: ReactNode
}

const MAP_STATUS_TO_ICON: { [key in StepButtonStatus]: FC | null } = {
  [StepButtonStatus.active]: ActiveIcon,
  [StepButtonStatus.completed]: CompleteIcon,
  [StepButtonStatus.disabled]: null,
}

const StyledButton = styled.button<StepButtonProps>`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-align: start;
  padding-left: 32px;
  background-color: transparent;
  cursor: pointer;
  color: ${({ status }) =>
    status === StepButtonStatus.active ? '#1CB954' : '#646877'};
  position: relative;
`

const IconWrapper = styled.div<{ status: StepButtonStatus }>`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ScheduleTrackStepButton: FC<StepButtonProps> = (props) => {
  const { status, children, ...rest } = props
  const Icon = MAP_STATUS_TO_ICON[status]
  return (
    <StyledButton {...rest} status={status}>
      {Icon && (
        <IconWrapper status={status}>
          <Icon />
        </IconWrapper>
      )}
      <span>{children}</span>
    </StyledButton>
  )
}
