import { useState } from 'react'

import { useNavigate } from 'react-router-dom'

import { useRelaunchEvent } from 'src/Hooks/useRelaunchEvent'
import { useRelaunchCampaignMutation } from 'src/Redux/relaunchCampaignApi'
import { RelaunchInvokePlacement } from 'src/Types'

type Relaunch = (campaignId: number | string) => void
type RelaunchState = {
  isLoading: boolean
  campaignId?: number | string
}

export const useRelaunchSpotifyCampaign = (): [Relaunch, RelaunchState] => {
  const navigate = useNavigate()
  const { handleRelaunchEvent } = useRelaunchEvent()
  const [campaignId, setCampaignId] = useState<
    RelaunchState['campaignId'] | undefined
  >()
  const [createRelaunchCampaign, { isLoading }] = useRelaunchCampaignMutation()

  const handleRelaunchCampaign = (id: RelaunchState['campaignId']): void => {
    const url = window.location.href

    if (id) {
      setCampaignId(id)
      createRelaunchCampaign({
        campaignId: Number(id),
        url,
      })
        .unwrap()
        .then((res) => {
          handleRelaunchEvent({
            campaignId: res.id,
            placement: RelaunchInvokePlacement.LIST,
          })
          navigate(`/campaigns/schedule/${res.id}`)
          return res
        })
    }
  }
  return [handleRelaunchCampaign, { isLoading, campaignId }]
}
