import React, { FC } from 'react'

import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'

import { PrimaryButton } from 'src/Components/Buttons/PrimaryButton'
import { FullScreenModal } from 'src/Components/FullScreenModal'
import { createdFirst, feedback } from 'src/Router/routes'
import SuccessIcon from 'src/Assets/Svg/pages-icons/Success.svg?react'
import { CampaignPlatformTypeEnum } from 'src/Types'
import { MAX_MOBILE_WIDTH } from 'src/Styled/variables'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
`

const Container = styled.div`
  display: flex;
  max-width: 358px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  @media screen and (min-width: ${MAX_MOBILE_WIDTH}) {
    max-width: 600px;
  }
`

const Title = styled.div`
  color: ${({ theme }) => theme.common.primaryText};
  text-align: center;
  font-family: 'Segoe UI';
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
`

const Text = styled(Title)`
  font-size: 14px;
  font-weight: 400;
`

const StyledPrimaryButton = styled(PrimaryButton)`
  padding-left: 40px;
  padding-right: 40px;
`

export const SuccessCreatedFirst: FC = () => {
  const navigate = useNavigate()
  const { campaignType } = useParams<{
    campaignType: CampaignPlatformTypeEnum
  }>()
  const { t } = useTranslation()

  const firstParagraph = t(
    'createCampaignPage.ourA&RTeamWillReviewYourCampaignStayTuned',
  )
  const secondParagraph = t('createCampaignPage.weWillNotify')

  const handleClick = (): void => {
    if (campaignType) {
      navigate(`${createdFirst}/${campaignType}${feedback}`)
    }
  }

  return (
    <FullScreenModal open>
      <Wrapper>
        <Container>
          <SuccessIcon width={40} height={40} />
          <Title>{t('createCampaignPage.youCreatedYourFirstCampaign!')}</Title>
          <div>
            <Text>{firstParagraph}</Text>
            <Text>{secondParagraph}</Text>
          </div>

          <div>
            <div>
              <StyledPrimaryButton onClick={handleClick}>
                {t('createCampaignPage.continue')}
              </StyledPrimaryButton>
            </div>
          </div>
        </Container>
      </Wrapper>
    </FullScreenModal>
  )
}
