import React, { FC } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { RadioProps } from 'antd'

import { PaymentMethodEnum } from 'src/Constants/enums'
import VisaLight from 'src/Assets/Svg/visa.svg?react'
import MastercardLight from 'src/Assets/Svg/masterCard.svg?react'
import DiscoverLight from 'src/Assets/Svg/discover.svg?react'
import JcbLight from 'src/Assets/Svg/jcb.svg?react'
import DinersLight from 'src/Assets/Svg/dinersClub.svg?react'
import ApplePay from 'src/Assets/Svg/applePay.svg?react'
import GooglePay from 'src/Assets/Svg/googlePay.svg?react'
import { MAX_TABLET_WIDTH } from 'src/Styled/variables'

import { PrimaryRadio } from './PrimaryRadio'

const cardIcons = [
  { id: 'visa', Component: VisaLight },
  { id: 'mastercard', Component: MastercardLight },
  { id: 'discover', Component: DiscoverLight },
  { id: 'jcb', Component: JcbLight },
  { id: 'diners', Component: DinersLight },
  { id: 'applepay', Component: ApplePay },
  { id: 'googlepay', Component: GooglePay },
]

const Text = styled.div`
  color: ${({ theme }) => theme.common.primaryText};
  font-family: 'Segoe UI';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  white-space: nowrap;
`
const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  @media screen and (min-width: ${MAX_TABLET_WIDTH}) {
    flex-direction: row;
    align-items: center;
    align-self: stretch;
  }
`
const ImagesContainer = styled.div`
  display: flex;
  min-width: 240px;
  align-items: center;
  align-content: center;
  gap: 4px;
  flex: 1 0 0;
  flex-wrap: wrap;
`
const ImagesCard = styled.div`
  display: flex;
  width: 48px;
  height: 24px;
  justify-content: center;
  align-items: center;
`

const StyledPrimaryRadio = styled(PrimaryRadio)`
  align-items: normal;

  &.ant-radio-wrapper-disabled ${ImagesContainer} {
    display: none;
  }
  &.ant-radio-wrapper .ant-radio {
    align-self: inherit;
    padding-top: 2px;
    @media screen and (min-width: ${MAX_TABLET_WIDTH}) {
      padding-top: 0;
      align-self: center;
    }
  }
`

type Props = RadioProps

export const StripeRadioButton: FC<Props> = (props) => {
  const { t } = useTranslation()
  return (
    <StyledPrimaryRadio {...props} value={PaymentMethodEnum.STRIPE}>
      <Container>
        <Text>{t('scheduleCampaignPage.creditAndDebitCards')}</Text>
        <ImagesContainer>
          {cardIcons.map(({ id, Component }) => (
            <ImagesCard key={id}>
              <Component />
            </ImagesCard>
          ))}
        </ImagesContainer>
      </Container>
    </StyledPrimaryRadio>
  )
}
