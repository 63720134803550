import { FC } from 'react'
interface Props {
  component: React.ComponentType<{ email: string }>
  email: string
}
export const InfluencerProtected: FC<Props> = ({
  component: Component,
  email,
  ...rest
}) => <Component email={email} {...rest} />
