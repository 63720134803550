import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'

import { APP } from 'src/Configs/App'
import { NameSpace, ErrorCode } from 'src/Redux/types'
import { api } from 'src/Services/api'
import { Artist } from 'src/Types'

interface GetArtistsResponse {
  artists: Array<Artist>
  usersCount: {
    totalCurators: number
    totalArtists: number
  }
}

export const getArtists = createAsyncThunk<GetArtistsResponse, number>(
  `${NameSpace.ArtistList}/getArtists`,

  async (page, { rejectWithValue }) => {
    try {
      const { data } = await api.get<GetArtistsResponse>(
        `${APP.SERVER}/admin/artists/${page}`,
      )

      return data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      return rejectWithValue({
        message: response?.data.message,
        code: response?.status || ErrorCode.InternalServerError,
      })
    }
  },
)
