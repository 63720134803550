import { FC } from 'react'

import { useTranslation } from 'react-i18next'

import PlusIcon from 'src/Assets/Svg/plus.svg?react'
import { ScheduleSteps } from 'src/Components/ScheduleTabs'
import { capitalizeFirstLetter } from 'src/Helpers/layoutHelpers'
import { ProperGenreOrLanguage } from 'src/Types'
import { ANY_LANGUAGE_ID } from 'src/Constants/constants'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import { useHideLanguageMatchingExperiment } from 'src/Hooks/Experiments/useHideLanguageMatchingExperiment'

import { ModalType } from '../../ModalsContainer'
import { getIsLanguageActive } from '../helpers'

import {
  LanguagesGenresContainer,
  LanguagesGenresList,
  ParametersButton,
  SubTitle,
} from './styles'

interface ScheduleLanguagesProps {
  languages: ProperGenreOrLanguage[]
  isAddButtonDisabled?: boolean
}

export const ScheduleLanguages: FC<ScheduleLanguagesProps> = ({
  languages,
  isAddButtonDisabled,
}) => {
  const { setSearch } = useCustomSearchParams()
  const { t } = useTranslation()
  const { isHideLanguageExperiment } = useHideLanguageMatchingExperiment()

  const isLanguageActive = getIsLanguageActive(languages)

  const handleClick = (): void => {
    const query = {
      step: ScheduleSteps.SETTINGS,
      modal: ModalType.Languages,
    }
    setSearch(query)
  }
  if (isLanguageActive) {
    return (
      <ParametersButton disabled={isAddButtonDisabled} onClick={handleClick}>
        <PlusIcon />
        {t('scheduleCampaignPage.addVocalLanguage')}
      </ParametersButton>
    )
  }

  const lang = languages.find((item) => item.id !== ANY_LANGUAGE_ID)
  const isHaveAnyLanguage = languages.some(
    (item) => item.id === ANY_LANGUAGE_ID,
  )
  const matchText = isHaveAnyLanguage
    ? t('scheduleCampaignPage.matchLowerOther')
    : t('scheduleCampaignPage.matchLowerOnly')

  const language = `${capitalizeFirstLetter(lang?.name || '')} ${
    isHideLanguageExperiment ? '' : matchText
  }`

  return (
    <LanguagesGenresContainer>
      <div>
        <SubTitle>{t('scheduleCampaignPage.vocalLanguage')}</SubTitle>
        <LanguagesGenresList translate='no'>{language}</LanguagesGenresList>
      </div>
      <ParametersButton onClick={handleClick}>
        {t('scheduleCampaignPage.edit')}
      </ParametersButton>
    </LanguagesGenresContainer>
  )
}
