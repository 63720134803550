import { FC } from 'react'

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { Text } from '../styles'

export const PlaylistStatsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 136px;
`

interface IStats {
  listeners: number
  streams: number
}

/* Temporary committed due to SFA not working */

// type Property = 'listeners' | 'streams'

// const calculateByProperty = (
//   stats: Array<IStats>,
//   property: Property,
// ): string => {
//   if (!stats || !stats.length) {
//     return 'no data'
//   }
//   const averageListeners =
//     stats.reduce((total, item) => total + (item[property] || 0), 0) /
//     stats.length
//   return `${averageListeners.toFixed()}`
// }

interface PlaylistStatsProps {
  followers: number
  stats?: IStats[]
}

export const PlaylistStats: FC<PlaylistStatsProps> = ({ followers }) => {
  const { t } = useTranslation()
  return (
    <PlaylistStatsContainer>
      <Text>
        <span>{t('playlistsPage.followers')}: </span>
        {followers}
      </Text>

      {/* Temporary committed due to SFA not working */}

      {/* <Text>
        <span>{t('playlistsPage.listeners')}: </span>
        {stats && calculateByProperty(stats, 'listeners')}
      </Text>
      <Text>
        <span>{t('playlistsPage.streams')}: </span>
        {stats && calculateByProperty(stats, 'streams')}
      </Text> */}
    </PlaylistStatsContainer>
  )
}
