import styled from 'styled-components'

import { colors } from 'src/Styled/variables'
export const Title = styled.h3`
  margin-bottom: 20px;
  svg {
    fill: #646877;
    height: 24px;
    width: 24px;
  }
`
export const Body = styled.div`
  background-color: ${colors.white};
  padding: 16px;
  border-radius: 5px;
`
