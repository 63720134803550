import { APP } from 'src/Configs/App'
import { currencySymbolConverter } from 'src/Constants/functions'
import { rtkApi } from 'src/Services/rtkApi'

interface GetCurrencyInfoResponse {
  currency_name: string
  currency_rate: number
}

interface TransformedCurrencyInfo {
  currencySymbol: string
  currencyRate: number
}

const currencyApi = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    getCurrencyInfo: builder.query<TransformedCurrencyInfo, void | null>({
      query: () => `${APP.SERVER}/user/exchange-rate`,
      transformResponse: (response: GetCurrencyInfoResponse) => ({
        currencySymbol:
          response.currency_rate > 0
            ? currencySymbolConverter(response && response.currency_name)
            : '$',
        currencyRate: response.currency_rate > 0 ? response.currency_rate : 1,
      }),
    }),
  }),
})

export const { useGetCurrencyInfoQuery } = currencyApi
