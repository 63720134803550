import { FC, ReactNode } from 'react'

import { Popover } from 'antd'
import { PopoverProps } from 'antd/lib'
import styled from 'styled-components'

import { SidebarMenuItemProps } from '.'

const Tooltip = styled.div<{ $isFeatured?: boolean }>`
  color: ${({ $isFeatured, theme }) =>
    $isFeatured ? theme.common.primaryDetails : theme.common.fourthText};
`

const StyledPopover = styled((props: PopoverProps) => (
  <Popover {...props} overlayClassName={props.className} />
))`
  .ant-popover-inner {
    padding: 9.5px 16px;
  }
`

type SidebarMenuItemPopoverProps = Pick<
  SidebarMenuItemProps,
  'isFeatured' | 'active' | 'collapsed' | 'disabled' | 'children'
> & {
  content: ReactNode
}

export const SidebarMenuItemPopover: FC<SidebarMenuItemPopoverProps> = (
  props,
) => {
  const { isFeatured, disabled, collapsed, active, children, content } = props
  return (
    <StyledPopover
      color='#333'
      arrow={false}
      placement='right'
      content={
        <Tooltip $isFeatured={Boolean(isFeatured) && !active}>
          {content}
        </Tooltip>
      }
      trigger={collapsed && !disabled ? ['hover', 'focus'] : 'contextMenu'}
    >
      {children}
    </StyledPopover>
  )
}
