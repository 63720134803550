import React, { FC } from 'react'

import styled from 'styled-components'

import CloseCross from 'src/Assets/Svg/close-cross.svg?react'
import { variables } from 'src/Styled/variables'

const CloseButton = styled.button`
  width: 40px;
  height: 40px;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  @media (min-width: 768px) {
    position: absolute;
    top: 76px;
    right: 16px;
  }
`

const Title = styled.h2`
  margin: 0;
  padding: 0;
  font-size: ${variables.fontSize20};
  line-height: 150%;
  font-weight: ${variables.fontWeight600};
`

const HeaderWrapper = styled.div`
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

interface HeaderTitleProps {
  onClose: () => void
  children: React.ReactNode
  className?: string
}
export const HeaderTitle: FC<HeaderTitleProps> = ({
  children,
  onClose,
  className,
}) => (
  <HeaderWrapper className={className}>
    <Title>{children}</Title>
    <CloseButton data-testid='close-button' onClick={onClose}>
      <CloseCross width='16px' height='16px' />
    </CloseButton>
  </HeaderWrapper>
)
