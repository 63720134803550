import { useEffect } from 'react'

import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { useScheduleEvents } from 'src/Hooks/useScheduleEvents'
import { CampaignPlatformTypeEnum, CampaignTypeEnum } from 'src/Types'
import { getCampaignToSchedule } from 'src/Redux/campaign-process/spotifyCampaignSchedule-process/selectors'

export const useScheduleStartEvent = (): void => {
  const { number: campaignIdParams } = useParams()
  const { handleScheduleStartEvent } = useScheduleEvents()
  const campaignDetails = useSelector(getCampaignToSchedule)
  const campaignId = campaignDetails?.id
  const campaignType = campaignDetails?.type || CampaignTypeEnum.BASIC
  const campaignGenres = campaignDetails?.genres
    ?.map((genre) => genre.name)
    .join(' ')

  const campaignLanguages = campaignDetails?.languages
    ?.map((language) => language.name)
    .join(' ')

  useEffect(() => {
    if (String(campaignId) !== String(campaignIdParams)) {
      return
    }

    handleScheduleStartEvent({
      campaignType,
      platformName: CampaignPlatformTypeEnum.SPOTIFY,
      campaignId: Number(campaignId),
      genreList: campaignGenres,
      languageList: campaignLanguages,
    })
  }, [
    handleScheduleStartEvent,
    campaignId,
    campaignIdParams,
    campaignType,
    campaignGenres,
    campaignLanguages,
  ])
}
