import styled, { css } from 'styled-components'

import { CustomScrollbarWrapper } from 'src/Components/CustomScrollbarWrapper'
import { colors, device, variables } from 'src/Styled/variables'
import Button from 'src/Components/Buttons/Button'

const FlexContainer = css`
  display: flex;
  align-items: center;
  gap: 8px;
`

const TextStyles = css`
  line-height: 150%;
`

const Box = css`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  border-radius: 4px;
  @media ${device.mobileMin} {
    display: grid;
    grid-template-columns: 1fr 110px;
  }
`

export const StyledTopBarNewRelease = styled.div`
  display: grid;
  grid-template-columns: 40px 1fr;
  grid-column-gap: 8px;
  align-items: start;
  margin-top: 3px;
  margin-bottom: 25px;
  color: ${({ theme }) => theme.common.primaryText};
`

export const ImgWrapper = styled.div`
  width: 40px;
  height: 40px;
  ${FlexContainer};
  justify-content: center;
  border-radius: 100px;
  background-color: ${colors.black};

  & img {
    width: 11.6px;
  }
`

export const ReleaseInfo = styled.div`
  font-size: ${variables.fontSize12};
  ${TextStyles};
`

export const ReleaseInfoTitle = styled.div`
  ${FlexContainer};
  font-size: ${variables.fontSize14};
  color: ${({ theme }) => theme.common.primaryText};
  font-weight: 600;
  margin-bottom: 6px;

  & img {
    width: 16px;
    height: 16px;
  }
`

export const ReleaseInfoTitleSpan = styled.span`
  color: ${colors.badgeGreen};
`

export const ReleaseInfoText = styled.div`
  ${TextStyles};
  margin-bottom: 16px;
  font-size: ${variables.fontSize12};
  color: ${({ theme }) => theme.common.thirdBg};
  line-height: 150%;
`

export const ImageInfo = styled.div`
  ${Box};
`

export const InnerImageInfo = styled.div`
  ${FlexContainer};
  display: grid;
  gap: 8px;
  grid-template-columns: 48px 1fr;
`
export const Container = styled.div`
  display: grid;
  align-items: center;
`

export const Name = styled.div`
  ${TextStyles};
  margin: 0;
  font-weight: ${variables.fontWeight600};
  color: ${({ theme }) => theme.common.thirdBg};
  font-size: ${variables.fontSize14};
  max-width: 211px;
`

export const Artist = styled.div`
  ${TextStyles};
  font-weight: ${variables.fontWeight400};
  font-size: ${variables.fontSize14};
`

export const ImageWrapper = styled.div`
  width: 48px;
  height: 48px;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 4px;

  & img {
    border-radius: 12px;
  }
`

export const InnerImageWrapper = styled.div`
  img {
    border-radius: 50%;
    height: 40px;
    width: 40px;
    object-fit: cover;
  }
`

export const ButtonWrapper = styled.div`
  width: 101px;
  ${FlexContainer};
  align-self: center;

  @media ${device.smallScreen} {
    align-self: self-start;
  }
`

export const DateWrapper = styled.div`
  ${TextStyles};
  color: ${({ theme }) => theme.common.primaryPlaceholder};
  font-size: ${variables.fontSize12};
`

export const PromoteBtn = styled.div`
  ${Box};
`

export const StyledPromoteButton = styled(Button)`
  > button {
    height: 24px !important;
    font-size: ${variables.fontSize10} !important;
  }
`
export const InnerTopBarNewRelease = styled.div`
  display: grid;
  grid-column-gap: 8px;
  align-items: start;
  color: ${({ theme }) => theme.common.primaryText};
`

export const InnerTop = styled.div`
  display: grid;
  grid-template-columns: 1fr 80px;
  grid-column-gap: 8px;
  align-items: start;
  margin-top: 3px;
  color: ${({ theme }) => theme.common.primaryText};
`

export const ImageCurator = styled.div`
  img {
    border-radius: 50%;
    height: 40px;
    width: 40px;
    object-fit: cover;
  }
`
export const NotificationsItemCurator = styled.div`
  display: grid;
  position: relative;
  grid-template-columns: 40px 1fr;
  gap: 8px;
  margin-bottom: 16px;
  color: #646877;
`

export const NotificationsWrapper = styled(CustomScrollbarWrapper)`
  margin-left: 0;
  @media ${device.smallScreen} {
    max-height: 252px;
  }
`

export const ArtistNotificationItemWrapper = styled.div`
  display: grid;
  position: relative;
  grid-template-columns: 40px 1fr 80px;
  gap: 8px;
  margin-bottom: 24px;
`

export const Message = styled.div`
  margin: 0;
  font-size: ${variables.fontSize14};
  line-height: 16px;
  color: ${({ theme }) => theme.common.primaryText};
  flex: 4 4;
`

export const HeaderMessage = styled.div`
  margin: 0;
  font-size: ${variables.fontSize14};
  line-height: 150%;
  font-weight: 600;
  display: block;
  a {
    color: ${colors.badgeGreen};
    line-height: 150%;
  }
`
export const TrackAddedToPlaylist = styled.div`
  margin-bottom: 4px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  line-height: 150%;
  color: ${({ theme }) => theme.common.primaryText};
  @media ${device.mobileMin} {
    display: block;
  }
`

export const TextName = styled.p`
  font-size: ${variables.fontSize12};
  color: ${({ theme }) => theme.common.thirdBg};
  margin: 0;
  line-height: 150%;
`

export const Text = styled.span`
  margin: 0;
  display: block;
  text-align: center;
`
