import { useNavigate } from 'react-router-dom'

import { setTrackToInitialState } from 'src/Redux/track-process'
import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { useCreateCampaignEvents } from 'src/Hooks/Analytics/useCreateCampaignEvents'
import { CampaignPlatformTypeEnum } from 'src/Types'
import { setSchedulePlatform } from 'src/Redux/campaign-process/spotifyCampaignSchedule-process'
import { setTracksToInitialState } from 'src/Redux/spotifyTracks-process'
import {
  setTiktokTracksToInitialState,
  setTiktokTrackToInitialState,
} from 'src/Redux/tiktokTrack-process'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import { landingPageUrl } from 'src/Constants/links'
import { NAVIGATE_BACK_INDEX } from 'src/Constants/constants'
import { getSpotifyTrackSelector } from 'src/Redux/track-process/selectors'
import { getTikTokTrackSelector } from 'src/Redux/tiktokTrack-process/selectors'

import { CreateCampaignSteps } from './components/StepsWrapper'

export const useCreateCampaignLayout = (): {
  totalCloseHandler: (isVariantB?: boolean) => void
  arrowBackHandler: () => void
  handleSpotifyToggle: (platformType: CampaignPlatformTypeEnum) => void
  resetSearchTracksToInitialState: () => void
} => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { search, setSearch } = useCustomSearchParams()
  const spotifyTrack = useAppSelector(getSpotifyTrackSelector)
  const tiktokTrack = useAppSelector(getTikTokTrackSelector)

  const { campaignCreationStartMpEventHandler } = useCreateCampaignEvents()

  const handleSpotifyToggle = (
    platformType: CampaignPlatformTypeEnum,
  ): void => {
    setSearch({
      step: CreateCampaignSteps.PLATFORM_SEARCH,
      platform: platformType,
    })
    dispatch(setSchedulePlatform(platformType))

    campaignCreationStartMpEventHandler({
      platformType,
    })
  }

  const resetSearchTracksToInitialState = (): void => {
    dispatch(setTracksToInitialState())
    dispatch(setTiktokTracksToInitialState())
    dispatch(setTrackToInitialState())
    dispatch(setTiktokTrackToInitialState())
  }

  const arrowBackHandler = (): void => {
    dispatch(setSchedulePlatform(null))

    resetSearchTracksToInitialState()
  }

  const totalCloseHandler = (isVariantB?: boolean): void => {
    const step = search.get('step')

    const isPlatformSelectMode = step === CreateCampaignSteps.PLATFORM_SELECT
    const isComeFromLandingAndFirstStep =
      (!document.referrer && isPlatformSelectMode) ||
      document.referrer === landingPageUrl

    resetSearchTracksToInitialState()

    dispatch(setSchedulePlatform(null))

    const hasTracks = spotifyTrack || tiktokTrack

    const isNavigateToTheLanding =
      isVariantB && step === CreateCampaignSteps.PLATFORM_SEARCH && !hasTracks

    if (isComeFromLandingAndFirstStep || isNavigateToTheLanding) {
      window.location.href = landingPageUrl
    } else {
      navigate(NAVIGATE_BACK_INDEX)
    }
  }

  return {
    totalCloseHandler,
    arrowBackHandler,
    handleSpotifyToggle,
    resetSearchTracksToInitialState,
  }
}
