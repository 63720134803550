import { createDraftSafeSelector } from '@reduxjs/toolkit'

import { RootState } from '../..'
import { SliceStatus } from '../../types'

import { ReferralListState } from '.'

const getState = (state: RootState): ReferralListState =>
  state.referral.referralList

export const getReferralError = createDraftSafeSelector(
  getState,
  ({ error }) => error,
)
export const getReferralLoading = createDraftSafeSelector(
  getState,
  ({ status }) => status === SliceStatus.Loading,
)

export const getReferralList = createDraftSafeSelector(
  getState,
  ({ referralList }) =>
    referralList.map((item, index) => ({ ...item, id: Date.now() + index })),
)
export const getInvitedArtists = createDraftSafeSelector(
  getState,
  ({ referralList }) => referralList.length,
)

export const getReferredArtists = createDraftSafeSelector(
  getState,
  ({ referralList }) =>
    referralList.filter(({ status }) => status === 'paid').length,
)

export const getTotalBonus = createDraftSafeSelector(
  getState,
  ({ referralList }) =>
    referralList.reduce((total, { amount, status }) => {
      if (status === 'paid') {
        return total + (Number(amount) || 0)
      }
      return total
    }, 0),
)

const MIN_BONUS = 10
export const getBonusPerCampaign = createDraftSafeSelector(
  getState,
  ({ referralList }) => {
    const sortedReferralList = [...referralList].sort(
      (a, b) => (Number(b?.amount) || 0) - (Number(a?.amount) || 0),
    )
    return sortedReferralList[0]?.amount || MIN_BONUS
  },
)
