import { useEffect } from 'react'

import { useParams } from 'react-router-dom'

import { useAppSelector } from 'src/Hooks/redux'
import { getScheduledTikTokCampaign } from 'src/Redux/schedule-process/tiktokCampaign/selectors'
import { useScheduleEvents } from 'src/Hooks/useScheduleEvents'
import { CampaignPlatformTypeEnum, CampaignTypeEnum } from 'src/Types'

export const useScheduleStartEvent = (): void => {
  const { number: campaignIdParams } = useParams()
  const { handleScheduleStartEvent } = useScheduleEvents()
  const scheduledTikTokCampaign = useAppSelector(getScheduledTikTokCampaign)
  const campaignId = scheduledTikTokCampaign?.id
  const campaignGenres =
    scheduledTikTokCampaign?.genres?.map((genre) => genre.label).join(' ') || ''
  const campaignLanguages =
    scheduledTikTokCampaign?.languages
      ?.map((language) => language.label)
      .join(' ') || ''
  const campaignType = scheduledTikTokCampaign?.type || CampaignTypeEnum.BASIC

  useEffect(() => {
    if (String(campaignId) !== String(campaignIdParams)) {
      return
    }
    handleScheduleStartEvent({
      campaignType,
      platformName: CampaignPlatformTypeEnum.TIKTOK,
      campaignId: Number(campaignId),
      genreList: campaignGenres,
      languageList: campaignLanguages,
    })
  }, [
    campaignGenres,
    campaignIdParams,
    campaignId,
    campaignLanguages,
    campaignType,
    handleScheduleStartEvent,
  ])
}
