import { FC, useMemo } from 'react'

import styled, { useTheme } from 'styled-components'
import { Progress } from 'antd'
import { useTranslation } from 'react-i18next'

import InfoIcon from 'src/Assets/Svg/common/info.svg?react'
import { variables } from 'src/Styled/variables'
import dayJs from 'src/Helpers/dayjs'
import { formatDate } from 'src/Constants/formatDate'
import { VideoStatus as VideoStatusEnum } from 'src/Constants/enums'
import { EventDismissReasonsEnum } from 'src/Redux/creatorSubmittedSoundsApi'
import { addSpaceToNumber } from 'src/Helpers/layoutHelpers'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  font-family: Segoe UI;
`

const ViewsWrapper = styled.div`
  display: flex;
  height: 27px;
  align-items: center;
  gap: 4px;
  align-self: stretch;
`

const DatesWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
`

const MessagesWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`

const IconWrapper = styled.div`
  padding-top: 4px;
`

const HighlightedText = styled.span<{
  $color?: string | null
}>`
  color: ${({ $color, theme }) => $color || theme.common.primaryText};
  font-size: ${variables.fontSize18};
  font-style: normal;
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
`

const Text = styled.span`
  color: ${({ theme }) => theme.common.primaryText};
  font-size: ${variables.fontSize14};
  font-style: normal;
  font-weight: ${variables.fontWeight400};
  line-height: 150%;
`

const SmallText = styled.span`
  color: ${({ theme }) => theme.common.primaryText};
  text-align: center;
  font-size: ${variables.fontSize12};
  font-style: normal;
  font-weight: ${variables.fontWeight400};
  line-height: 150%;
`

const ProgressContainer = styled.div`
  width: 100%;
`

export interface ViewsProgressBarProps {
  currentViews: number
  minViews: number
  videoPublishedDate: string
  milestoneDate: string
  status: VideoStatusEnum
  dismissReason?: EventDismissReasonsEnum
}

const wontChargedTextStatuses = [
  VideoStatusEnum.AWAITING_REVIEW,
  VideoStatusEnum.AWAITING_MODERATOR_REVIEW,
  VideoStatusEnum.APPROVED_AUTOMATICALLY,
  VideoStatusEnum.APPROVED_BY_ARTIST,
  VideoStatusEnum.APPROVED_BY_ADMIN,
]

const notAlightPoliciesDismissReasons = [
  EventDismissReasonsEnum.VIDEO_DELETED,
  EventDismissReasonsEnum.PROFILE_BUSINESS,
  EventDismissReasonsEnum.PROFILE_DELETED,
  EventDismissReasonsEnum.PROFILE_PRIVATE,
]

const hiddenProgressReasons = [
  EventDismissReasonsEnum.VIDEO_DELETED,
  EventDismissReasonsEnum.PROFILE_BUSINESS,
  EventDismissReasonsEnum.PROFILE_DELETED,
  EventDismissReasonsEnum.PROFILE_PRIVATE,
]

export const ViewsProgressBar: FC<ViewsProgressBarProps> = ({
  currentViews,
  minViews,
  milestoneDate,
  videoPublishedDate,
  status,
  dismissReason,
}) => {
  const theme = useTheme()

  const { t } = useTranslation()

  const progress = (currentViews / minViews) * 100
  const formattedMilestoneDate = dayJs(milestoneDate).format(
    formatDate.formatDate1,
  )
  const formattedVideoPublishedDate = dayJs(videoPublishedDate).format(
    formatDate.formatDate1,
  )
  const isRejected = status === VideoStatusEnum.REJECTED

  const isViewsFailed =
    isRejected && dismissReason === EventDismissReasonsEnum.VIEWS_FAILED

  const isViewsAchieved = currentViews >= minViews

  const formattedCurrentViews = addSpaceToNumber(currentViews, ',')
  const formattedMinViews = addSpaceToNumber(minViews, ',')

  const statusText = useMemo(() => {
    if (wontChargedTextStatuses.includes(status)) {
      return t('campaignDetailsPage.wontChargedText')
    }

    if (
      isRejected &&
      dismissReason &&
      notAlightPoliciesDismissReasons.includes(dismissReason)
    ) {
      return t('campaignDetailsPage.wontChargedRejectedText')
    }

    return t('campaignDetailsPage.wontChargedText')
  }, [dismissReason, isRejected, status, t])

  const isProgressHidden =
    isRejected && dismissReason && hiddenProgressReasons.includes(dismissReason)

  const viewsAmountColor = useMemo(() => {
    if (isViewsFailed) {
      return theme.common.dangerText
    }

    if (isViewsAchieved && currentViews > 0) {
      return theme.common.sixthText
    }

    return null
  }, [
    currentViews,
    isViewsAchieved,
    isViewsFailed,
    theme.common.dangerText,
    theme.common.sixthText,
  ])

  return (
    <Container>
      {!isProgressHidden && (
        <ProgressContainer>
          <ViewsWrapper>
            <HighlightedText $color={viewsAmountColor}>
              {formattedCurrentViews}
            </HighlightedText>
            <HighlightedText>/</HighlightedText>
            <Text>{formattedMinViews}</Text>
            <Text>{t('campaignDetailsPage.viewsProgress')}</Text>
          </ViewsWrapper>

          <Progress
            percent={progress}
            showInfo={false}
            trailColor={theme.progress.primaryBg}
            strokeColor={
              isViewsFailed
                ? theme.progress.secondaryBg
                : theme.progress.thirdBg
            }
            size={{ height: 6 }}
          />

          <DatesWrapper>
            <SmallText>{formattedVideoPublishedDate}</SmallText>
            <SmallText>{formattedMilestoneDate}</SmallText>
          </DatesWrapper>
        </ProgressContainer>
      )}

      <MessagesWrapper>
        <IconWrapper>
          <InfoIcon />
        </IconWrapper>

        <Text>{statusText}</Text>
      </MessagesWrapper>
    </Container>
  )
}
