import React, {
  ReactElement,
  ReactNode,
  useLayoutEffect,
  useState,
} from 'react'

import { BrowserHistory } from 'history'
import { Router } from 'react-router-dom'

export interface HistoryRouterProps {
  history: BrowserHistory
  basename?: string
  children?: ReactNode
}

function HistoryRouter({
  basename,
  children,
  history,
}: HistoryRouterProps): ReactElement {
  const [state, setState] = useState({
    action: history.action,
    location: history.location,
  })

  useLayoutEffect(() => {
    history.listen(setState)
  }, [history])

  return (
    <Router
      basename={basename}
      location={state.location}
      navigationType={state.action}
      navigator={history}
    >
      {children}
    </Router>
  )
}

export default HistoryRouter
