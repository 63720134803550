import { FC, PropsWithChildren, useState } from 'react'

import { Modal, Popover } from 'antd'
import styled, { css } from 'styled-components'
import { PopoverProps } from 'antd/lib'
import { useMediaQuery } from 'react-responsive'
import { useTranslation } from 'react-i18next'

import { MAX_TABLET_WIDTH } from 'src/Styled/variables'

import { QuestionButton } from '../QuestionButton'
import { PrimaryButton } from '../Buttons/PrimaryButton'

const StyledPrimaryButton = styled(PrimaryButton)`
  padding-left: 40px;
  padding-right: 40px;
`
const contentStyles = css`
  display: flex;
  width: 280px;
  padding: 24px;
  padding-bottom: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.common.primaryBorder};
  background-color: ${({ theme }) => theme.common.primaryBg};
  box-shadow: 0px 0px 12px 0px rgba(51, 51, 51, 0.12);
`

const StyledModal = styled(Modal)`
  ${contentStyles}
  &.ant-modal {
    padding-bottom: 24px;
  }
  & .ant-modal-content {
    border-radius: none;
    box-shadow: none;
    padding: 0;
  }
`
const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  padding-bottom: 24px;
`
const DefaultText = styled.div`
  color: ${({ theme }) => theme.common.primaryText};
  font-family: 'Segoe UI';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`

const StyledPopover = styled((props: PopoverProps) => (
  <Popover {...props} overlayClassName={props.className} />
))`
  .ant-popover-inner {
    ${contentStyles}
  }
`

export type PrimaryHintProps = PropsWithChildren

export const PrimaryHint: FC<PrimaryHintProps> = ({ children }) => {
  const { t } = useTranslation()
  const isDesktop = useMediaQuery({ minWidth: MAX_TABLET_WIDTH })
  const [isVisibleHint, setIsVisibleHint] = useState(false)

  const handleVisibleChange = (visible: boolean): void => {
    setIsVisibleHint(visible)
  }
  const content = (
    <ContentContainer>
      <DefaultText>{children}</DefaultText>
      <StyledPrimaryButton onClick={() => handleVisibleChange(false)}>
        {t('hint.gotIt')}
      </StyledPrimaryButton>
    </ContentContainer>
  )
  if (isDesktop) {
    return (
      <StyledPopover
        open={isVisibleHint}
        placement='topLeft'
        trigger={['click']}
        content={content}
        arrow={false}
        onOpenChange={handleVisibleChange}
      >
        <QuestionButton />
      </StyledPopover>
    )
  }
  return (
    <>
      <StyledModal
        open={isVisibleHint}
        footer={null}
        closeIcon={null}
        centered
        width={280}
        onCancel={() => handleVisibleChange(false)}
      >
        {content}
      </StyledModal>
      <QuestionButton onClick={() => handleVisibleChange(true)} />
    </>
  )
}
