import { FC } from 'react'

import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { useCreateCampaignEvents } from 'src/Hooks/Analytics/useCreateCampaignEvents'
import { usePageViewEvent } from 'src/Hooks/Analytics/usePageViewEvent'
import { pageInfo } from 'src/Helpers/TagManager/pageInfo'
import { useCreateCampaignStartEvent } from 'src/Hooks/Analytics/useCreateCampaignStartEvent'
import { CampaignPlatformTypeEnum } from 'src/Types'
import { ArtistRoutesEnum } from 'src/Router/ArtistComponentsNewUI'

import { CreateNewCampaignHelmet } from '../../Components/CreateNewCampaignHelmet'
import { CreateCampaignBreadcrumbsAuth } from '../../Components/CreateCampaignBreadcrumbsAuth'
import { PlatformButton } from '../../Components/PlatformButton'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  max-width: 960px;
`

const PlatformsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`

export const ChoosePlatformAuthPage: FC = () => {
  const navigate = useNavigate()
  const { campaignCreationStartMpEventHandler } = useCreateCampaignEvents()
  usePageViewEvent(pageInfo.createCampaign)
  useCreateCampaignStartEvent()

  const onSpotifyClick = (): void => {
    campaignCreationStartMpEventHandler({
      platformType: CampaignPlatformTypeEnum.SPOTIFY,
    })

    navigate(`${ArtistRoutesEnum.ChooseCreateCampaignPlatformSpotify}`)
  }
  const onTikTokClick = (): void => {
    campaignCreationStartMpEventHandler({
      platformType: CampaignPlatformTypeEnum.TIKTOK,
    })

    navigate(`${ArtistRoutesEnum.ChooseCreateCampaignPlatformTikTok}`)
  }

  return (
    <>
      <CreateNewCampaignHelmet />
      <Container>
        <CreateCampaignBreadcrumbsAuth />
        <PlatformsContainer>
          <PlatformButton
            platform={CampaignPlatformTypeEnum.SPOTIFY}
            onClick={onSpotifyClick}
          />
          <PlatformButton
            platform={CampaignPlatformTypeEnum.TIKTOK}
            onClick={onTikTokClick}
          />
        </PlatformsContainer>
      </Container>
    </>
  )
}
