import React from 'react'

import { useMediaQuery } from 'react-responsive'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { landingPageUrl } from 'src/Constants/links'
import logo from 'src/Assets/Png/logo.png'
import CloseIconWhite from 'src/Assets/Svg/closeWhite.svg?react'
import CloseIconGreen from 'src/Assets/Svg/closeGreen.svg?react'
import { getAuthType } from 'src/Redux/auth-process/authTypeSlice/selectors'

import { MAP_LOGIN_FORM } from './helpers'
import { useLogin } from './useLogin'
import { ChooseRoleModal } from './components/ChooseRoleModal'

import {
  Card,
  CloseButton,
  LoginContainer,
  Header,
  Logo,
  Wrapper,
} from './styles'

const LogIn: React.FC = () => {
  const { contextHolder } = useLogin()

  const { t } = useTranslation()

  const authType = useSelector(getAuthType)

  const onGoToLanding = (): void => {
    window.location.href = landingPageUrl
  }

  const isMobile = useMediaQuery({ maxWidth: 576 })
  return (
    <Wrapper>
      {contextHolder}
      <CloseButton data-testid='close-button' onClick={onGoToLanding}>
        {isMobile ? <CloseIconGreen /> : <CloseIconWhite />}
      </CloseButton>
      <ChooseRoleModal />
      <LoginContainer>
        <Logo>
          <img width={236} height={52} alt='logo' src={logo} />
        </Logo>
        <Card>
          <Header>{t('login.welcomeBack')}</Header>
          {MAP_LOGIN_FORM[authType] || null}
        </Card>
      </LoginContainer>
    </Wrapper>
  )
}
export default LogIn
