import { createSlice } from '@reduxjs/toolkit'

import { CreatorTransaction, Recipient } from 'src/Types'
import { setApiError } from 'src/Hooks/redux'

import { ApiError, NameSpace, SliceStatus } from '../types'

import { getCreatorTransactions, getTransactionsDetails } from './api-actions'

export interface TransactionsState {
  creatorTransactions: Array<CreatorTransaction> | null
  recipient: Recipient | null | boolean
  totalTransactions: number
  transactionsPayment: number
  transactionsPaidOut: number
  hasAwaiting: boolean
  status: SliceStatus
  error: ApiError | null
}

const initialState: TransactionsState = {
  creatorTransactions: null,
  recipient: null,
  totalTransactions: 0,
  transactionsPayment: 0,
  transactionsPaidOut: 0,
  hasAwaiting: false,
  status: SliceStatus.Idle,
  error: null,
}

export const transactionsProcess = createSlice({
  initialState,
  name: NameSpace.Transactions,
  reducers: {},
  extraReducers: (builder) => {
    // getCreatorTransactions
    builder.addCase(getCreatorTransactions.pending, (state) => {
      state.status = SliceStatus.Loading
    })
    builder.addCase(getCreatorTransactions.fulfilled, (state, action) => {
      state.status = SliceStatus.Succeeded
      state.creatorTransactions = action.payload.data
      state.totalTransactions = action.payload.meta.itemCount
      state.error = null
    })
    builder.addCase(getCreatorTransactions.rejected, (state, action) => {
      state.status = SliceStatus.Failed
      state.error = setApiError(action)
    })

    // getTransactionsDetails
    builder.addCase(getTransactionsDetails.pending, (state) => {
      state.status = SliceStatus.Loading
    })
    builder.addCase(getTransactionsDetails.fulfilled, (state, action) => {
      state.status = SliceStatus.Succeeded
      state.hasAwaiting = action.payload.hasAwaitingPayout
      state.transactionsPayment = action.payload.earnedTotalAmount
      state.transactionsPaidOut = action.payload.payoutTotalAmount
      state.recipient = action.payload.isRecipient
      state.error = null
    })
    builder.addCase(getTransactionsDetails.rejected, (state, action) => {
      state.status = SliceStatus.Failed
      state.error = setApiError(action)
    })
  },
})
