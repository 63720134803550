import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { SmartImage } from 'src/Components/SmartImage'
import { colors, variables } from 'src/Styled/variables'
import defaultAva from 'src/Assets/Png/ava.png'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'

const SearchResult = styled.div`
  padding: 8px 16px;
  background: ${colors.white};
  border-radius: 8px;
  border: 1px solid ${colors.greyStroke};
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 100%;
  align-items: center;
`
const SearchPhoto = styled(SmartImage)`
  border-radius: 60px;
  width: 40px;
  height: 40px;
  overflow: hidden;
`
const SearchText = styled.div`
  font-size: ${variables.fontSize14};
  line-height: 21px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  color: ${colors.mainText};
`
const SearchTextTitle = styled.div`
  font-weight: ${variables.fontWeight600};
`

export const UserDataInfo: FC = () => {
  const { t } = useTranslation()
  const { search } = useCustomSearchParams()
  const avatar = search.get('avatarUrl') || ''
  const nickname = search.get('nickname') || ''
  const displayName = search.get('displayName') || ''
  const followers = search.get('followers') || '0'
  const hasUserData =
    Boolean(nickname) && Boolean(displayName) && Boolean(followers)

  if (!hasUserData) {
    return null
  }

  return (
    <SearchResult>
      <SearchPhoto src={avatar} fallbackSrc={defaultAva} alt='photo' />
      <SearchText>
        <SearchTextTitle>{nickname}</SearchTextTitle>
        <div>
          {displayName}
          &nbsp;
          {followers}
          &nbsp;
          {Number(followers) !== 1
            ? t('influencer.followers')
            : t('influencer.follower')}
        </div>
      </SearchText>
    </SearchResult>
  )
}
