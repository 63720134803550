import { FC } from 'react'

import styled from 'styled-components'

import track from 'src/Assets/Png/track.png'
import { SmartImage } from 'src/Components/SmartImage'
import { colors, variables } from 'src/Styled/variables'
import { TrackAudioPlayer } from 'src/Components/TrackAudioPlayer'
import { TrackCoverWrapper } from 'src/Components/TrackAudioPlayer/TrackCoverWrapper'

import { TrackDetailsProps } from './TrackDetails'

const Body = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-radius: 4px;
`
const Cover = styled(SmartImage)`
  width: 80px;
  height: 80px;
  border-radius: 4px;
`

const TrackInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex: 1 0 0;
`

const TrackInfoText = styled.div`
  color: ${colors.mainText};
  font-size: ${variables.fontSize14};
  font-style: normal;
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
`
const TrackInfoNameText = styled(TrackInfoText)`
  font-weight: ${variables.fontWeight400};
`
const TrackInfoTimeText = styled(TrackInfoNameText)`
  color: ${colors.greyTextWhiteBg};
`
const StyledTrackCoverWrapper = styled(TrackCoverWrapper)`
  margin-right: 0;
`

type Props = Pick<
  TrackDetailsProps,
  'coverUrl' | 'trackName' | 'trackAuthorName' | 'duration' | 'trackPreviewUrl'
>

export const TrackDetailsBody: FC<Props> = ({
  coverUrl,
  trackName,
  trackAuthorName,
  trackPreviewUrl,
  duration,
}) => (
  <Body>
    <TrackAudioPlayer
      render={({ loading, currentTrackUrl, isPlaying, onClick }) => (
        <StyledTrackCoverWrapper
          show={Boolean(trackPreviewUrl)}
          iconSize={'32px'}
          loaderSize='50px'
          isPlaying={isPlaying && currentTrackUrl === trackPreviewUrl}
          loading={loading && currentTrackUrl === trackPreviewUrl}
          onClick={(e) => {
            onClick(e, trackPreviewUrl)
          }}
        >
          <Cover src={coverUrl} alt='track cover' fallbackSrc={track} />
        </StyledTrackCoverWrapper>
      )}
    />
    <TrackInfo>
      <TrackInfoText>{trackName}</TrackInfoText>
      <TrackInfoNameText>{trackAuthorName}</TrackInfoNameText>
      <TrackInfoTimeText>{duration}</TrackInfoTimeText>
    </TrackInfo>
  </Body>
)
