import { FC } from 'react'

import { useParams } from 'react-router-dom'

import { TiktokCampaignStatus } from 'src/Types'
import { useGetCampaignTiktokDetailsQuery } from 'src/Redux/campaignTiktokDetailsApi'

import { EngagementDataUpdatedAlert } from './components/EngagementDataUpdatedAlert'
import { DetailsCard } from './components/DetailsCard'
import DetailsHeader from './components/DetailsHeader'
import Block from './components/Block'
import { useTikTokCampaignDetails } from './useTikTokCampaignDetails'
import { EndedCampaignSurvey } from './components/SurveyForEndedCampaign'

import {
  BlockOfTikTokCards,
  DetailsContainer,
  StyledTabs,
  Videos,
} from './styles'

const TikTokCampaignDetails: FC = () => {
  const { campaignID } = useParams<{ campaignID: string }>()
  const { data: campaignDetails } = useGetCampaignTiktokDetailsQuery(campaignID)

  const { valuesBlocks, videoTableItems, isMonitoringVideosAvailable } =
    useTikTokCampaignDetails()

  return (
    <DetailsContainer>
      <EndedCampaignSurvey />
      <DetailsHeader />
      <DetailsCard />

      <EngagementDataUpdatedAlert
        status={campaignDetails?.status || TiktokCampaignStatus.AWAITING_REVIEW}
        dateInfo={campaignDetails?.updatedAt || ''}
        isMonitoringVideosAvailable={isMonitoringVideosAvailable}
      />

      <BlockOfTikTokCards>
        {valuesBlocks.map((item) => (
          <Block
            key={item.title}
            title={item.title}
            value={item.value}
            hintText={item.hintText}
          />
        ))}
      </BlockOfTikTokCards>

      <Videos>
        <StyledTabs items={videoTableItems} defaultActiveKey='all' />
      </Videos>
    </DetailsContainer>
  )
}

export default TikTokCampaignDetails
