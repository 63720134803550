import { Input } from 'antd'
import styled from 'styled-components'

import { colors, variables } from 'src/Styled/variables'

const { TextArea: TextAreaAntd } = Input
export const TextArea = styled(TextAreaAntd)<{
  $marginTop?: boolean
  $isError?: boolean
}>`
  width: 100%;
  padding: 12px 16px;
  font-size: ${variables.fontSize14};
  line-height: 21px;
  color: ${colors.mainText};
  border: 1px solid
    ${({ $isError }) => ($isError ? colors.messegesRed : colors.greyStroke)};
  border-radius: 4px;
  outline: none;
  margin-top: ${({ $marginTop }) => ($marginTop ? '16px' : '0px')};
  .${({ prefixCls }) => prefixCls || 'ant-input'} {
    color: ${colors.mainText};
  }

  &::placeholder {
    color: ${colors.greyTextWhiteBg};
  }

  &.${({ prefixCls }) => prefixCls || 'ant-input'}-affix-wrapper:hover {
    border: 1px solid ${colors.greyStroke};
  }
  &.${({ prefixCls }) => prefixCls || 'ant-input'}-affix-wrapper:focus {
    border: 1px solid ${colors.greyStroke};
    box-shadow: none;
  }
  &.${({ prefixCls }) => prefixCls || 'ant-input'}:hover,:focus {
    border: 1px solid #eee;
    box-shadow: none;
  }
  &.${({ prefixCls }) => prefixCls || 'ant-input'}:focus-within {
    border: 1px solid #eee;
    box-shadow: none;
  }
  &.${({ prefixCls }) => prefixCls || 'ant-input'}-affix-wrapper-focused {
    border: 1px solid ${colors.greyStroke};
    box-shadow: none;
  }
  .${({ prefixCls }) => prefixCls || 'ant-input'}-data-count {
    font-size: ${variables.fontSize10};
    font-weight: ${variables.fontWeight600};
    line-height: 150%;
    color: ${colors.mainText};
    bottom: -16px;
  }
`
