import { combineReducers } from '@reduxjs/toolkit'

import { referralProcess } from './referralList'
import { referralHashProcess } from './referralHash'
import { referralCodeProcess } from './referralCode'

export const referral = combineReducers({
  referralList: referralProcess.reducer,
  referralHash: referralHashProcess.reducer,
  referralCode: referralCodeProcess.reducer,
})
