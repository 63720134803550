import 'src/Localization/i18n'
import 'antd/dist/reset.css'
import 'src/Scss/app.scss'
import 'react-loading-skeleton/dist/skeleton.css'

import React from 'react'

import { ErrorBoundary } from '@sentry/react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import App from 'src/Containers/App/App'
import { setupStore } from 'src/Redux/index'
import TagManagerContextProvider from 'src/Helpers/TagManager/TagManagerContextProvider'
import { GrowthProvider } from 'src/Helpers/GrowthBook'

import { api } from './Services/api'
import browserHistory from './Router/browser-history'
import HistoryRouter from './Router/history-router'
import { ErrorPage } from './Components/ErrorBoundary'
import { StyleSheetManagerWrapper } from './Helpers/StyleSheetManagerWrapper'
import { IntercomWrapper } from './Containers/App/IntercomWrapper'

export const { store, persistor } = setupStore()
api.setStore && api.setStore(store)

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <StyleSheetManagerWrapper>
      <Provider store={store}>
        <TagManagerContextProvider>
          <PersistGate loading={null} persistor={persistor}>
            <HistoryRouter history={browserHistory}>
              <GrowthProvider>
                <ErrorBoundary fallback={<ErrorPage />}>
                  <IntercomWrapper>
                    <App />
                  </IntercomWrapper>
                </ErrorBoundary>
              </GrowthProvider>
            </HistoryRouter>
          </PersistGate>
        </TagManagerContextProvider>
      </Provider>
    </StyleSheetManagerWrapper>
  </React.StrictMode>,
)
