import { FC } from 'react'

import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useAppSelector } from 'src/Hooks/redux'
import { getCampaignToSchedule } from 'src/Redux/campaign-process/spotifyCampaignSchedule-process/selectors'
import { ScheduleSteps } from 'src/Components/ScheduleTabs'
import {
  ScheduleTrackStepButton,
  StepButtonStatus,
} from 'src/Containers/ui/ScheduleTrackStepButton'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import { useGetBudgetRangeQuery } from 'src/Redux/spotifyBudgetRangeApi'

import { Title } from '../styles'
import { ModalType } from '../../ModalsContainer'
import { SettingsBudget } from '../SettingsBudget'
import { getIsLanguageActive } from '../helpers'

import { CampaignStepsContainer, StepsButtonContainer } from './styles'

export const CampaignSteps: FC = () => {
  const { setSearch } = useCustomSearchParams()
  const { t } = useTranslation()
  const params = useParams()
  const campaignDetails = useAppSelector(getCampaignToSchedule)
  const { isError: budgetRangeError } = useGetBudgetRangeQuery(params.number, {
    skip: !params.number,
  })

  const isGenresActive = !campaignDetails.genres.length
  const isLanguageActive = getIsLanguageActive(campaignDetails.languages)

  const handleClick = (type: ModalType): void => {
    const query = {
      step: ScheduleSteps.SETTINGS,
      modal: type,
    }
    setSearch(query)
  }
  if (
    !isGenresActive &&
    !isLanguageActive &&
    !budgetRangeError &&
    Number(campaignDetails.id) === Number(params.number)
  ) {
    return <SettingsBudget />
  }
  const getLanguagesStatus = (): StepButtonStatus => {
    if (isGenresActive && isLanguageActive) {
      return StepButtonStatus.disabled
    }
    if (isLanguageActive) {
      return StepButtonStatus.active
    }
    return StepButtonStatus.completed
  }
  const genresStatus = isGenresActive
    ? StepButtonStatus.active
    : StepButtonStatus.completed
  return (
    <CampaignStepsContainer>
      <Title>{t('scheduleCampaignPage.ToSetUpBudget')}</Title>
      <StepsButtonContainer>
        <ScheduleTrackStepButton
          onClick={() => handleClick(ModalType.Genres)}
          status={genresStatus}
        >
          {t('scheduleCampaignPage.addTrackGenres').toLowerCase()}
        </ScheduleTrackStepButton>
        <ScheduleTrackStepButton
          onClick={() => handleClick(ModalType.Languages)}
          status={getLanguagesStatus()}
          disabled={isGenresActive}
        >
          {t('scheduleCampaignPage.addVocalLanguage').toLowerCase()}
        </ScheduleTrackStepButton>
      </StepsButtonContainer>
    </CampaignStepsContainer>
  )
}
