import i18n from 'src/Localization/i18n'
import { CampaignStatusEnum, SpotifyCampaign, TiktokCampaign } from 'src/Types'
import { formatDate } from 'src/Constants/formatDate'
import dayjs from 'src/Helpers/dayjs'

const getVideosText = (videos?: number): string => {
  const submittedVideo =
    videos === 1
      ? i18n.t('campaignsPage.amountVideo')
      : i18n.t('campaignsPage.amountVideos')
  return `${videos ?? 0} ${submittedVideo}`
}
const getReviewsText = (reviews?: number): string => {
  const submittedReview =
    reviews === 1
      ? i18n.t('campaignsPage.amountReview')
      : i18n.t('campaignsPage.amountReviews')
  return `${reviews ?? 0} ${submittedReview}`
}

const getReviewOrVideoText = (reviews?: number, videos?: number): string => {
  if (typeof reviews === 'number') {
    return getReviewsText(reviews)
  }
  if (typeof videos === 'number') {
    return getVideosText(videos)
  }
  return ''
}

type InitialCampaign = TiktokCampaign | SpotifyCampaign
type GetStatusTextParams = Pick<
  InitialCampaign,
  | 'status'
  | 'allReview'
  | 'platformType'
  | 'submittedVideosAmount'
  | 'startDate'
>

const withReviewStatuses = [
  CampaignStatusEnum.RUNNING,
  CampaignStatusEnum.ENDED,
  CampaignStatusEnum.STOPPED,
]

export const getStatusText = (params: GetStatusTextParams): string => {
  if (withReviewStatuses.includes(params.status as CampaignStatusEnum)) {
    return getReviewOrVideoText(params.allReview, params.submittedVideosAmount)
  }
  if (params.status === CampaignStatusEnum.PENDING_APPROVAL) {
    return i18n.t('campaignsListPage.takeUp')
  }
  if (params.status === CampaignStatusEnum.SCHEDULED) {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const date = dayjs(params.startDate)
      .tz(timezone)
      .format(formatDate.formatDateMonthFull)
    return i18n.t('campaignsListPage.willStart', { date })
  }

  return ''
}
