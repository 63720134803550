import React, { FunctionComponent, memo } from 'react'

import ChevronRight from 'src/Assets/Svg/chevronRight.svg?react'

import { StartCampaignButtonProps } from './StartCampaignButtonProps'

import {
  StartCampaignContainer,
  StartCampaignButtonContent,
  StyledArrow,
} from './styles'

export const StartCampaignButton: FunctionComponent<StartCampaignButtonProps> =
  memo(({ icon, text, openHandler }) => (
    <StartCampaignContainer onClick={openHandler}>
      <StartCampaignButtonContent>
        {icon}
        {text}
      </StartCampaignButtonContent>
      <StyledArrow>
        <ChevronRight width={24} height={18} />
      </StyledArrow>
    </StartCampaignContainer>
  ))
