import { FC } from 'react'

import { useNavigate } from 'react-router-dom'

import { ArtistHeader } from 'src/Components/Header/Components/ArtistHeader'
import { useAppSelector } from 'src/Hooks/redux'
import {
  getArtistBalance,
  getIsUserLoading,
} from 'src/Redux/auth-process/userSlice/selectors'
import { ArtistRoutesEnum } from 'src/Router/ArtistComponentsNewUI'
import { useLogOut } from 'src/Hooks/useLogOut'

import { HeaderContainerProps } from '../HeaderContainer'

import { useArtistNotifications } from './useArtistNotifications'

export const ArtistHeaderContainer: FC<HeaderContainerProps> = ({
  withShadow,
  onSidebarToggleClick,
}) => {
  const navigate = useNavigate()

  const balance = useAppSelector(getArtistBalance)
  const isBalanceLoading = useAppSelector(getIsUserLoading)

  const {
    sortedNotifications,
    hasNewNotifications,
    isHeaderNotificationsPopupVisible,
    onNotificationsButtonClick,
    releaseData,
    handleCreateAndStartSchedule,
  } = useArtistNotifications()

  const { handleLogOut } = useLogOut()

  const onSettingsClick = (): void => {
    navigate(ArtistRoutesEnum.Settings)
  }

  const onBalanceClick = (): void => {
    navigate(ArtistRoutesEnum.Balance)
  }

  const onCreateCampaignClick = (): void => {
    navigate(`${ArtistRoutesEnum.CampaignsList}${ArtistRoutesEnum.New}`)
  }

  return (
    <ArtistHeader
      onCreateCampaignButtonClick={onCreateCampaignClick}
      onSettingsClick={onSettingsClick}
      onLogoutClick={handleLogOut}
      withShadow={withShadow}
      balance={balance}
      isBalanceButtonLoading={isBalanceLoading}
      onSidebarToggleClick={onSidebarToggleClick}
      onBalanceButtonClick={onBalanceClick}
      hasNewNotifications={hasNewNotifications}
      releaseData={releaseData}
      notifications={sortedNotifications}
      isHeaderNotificationsPopupVisible={isHeaderNotificationsPopupVisible}
      onNotificationsButtonClick={onNotificationsButtonClick}
      handleCreateAndStartSchedule={handleCreateAndStartSchedule}
    />
  )
}
