import { FC, useEffect } from 'react'

import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { getIsShowLangPreferenceAlert } from 'src/Redux/playlists-process/selectors'
import {
  getIsLangModalShown,
  markLangModalAsShown,
} from 'src/Redux/sessionNotification-process'

import PlaylistsToSetModal from '../PlaylistsToSetModal'

export const LanguageSetModal: FC = () => {
  const dispatch = useAppDispatch()
  const { search, setSearch } = useCustomSearchParams()
  const showModal = Boolean(search.get('langModal'))
  const showLang = useAppSelector(getIsShowLangPreferenceAlert)
  const languageModalShown = useAppSelector(getIsLangModalShown)

  const handleCloseModal = (): void => {
    setSearch({ langModal: null })
  }

  useEffect(() => {
    if (!showModal && showLang && !languageModalShown) {
      dispatch(markLangModalAsShown())
      setSearch({ langModal: 'true' })
    }
  }, [dispatch, languageModalShown, setSearch, showLang, showModal])

  return <PlaylistsToSetModal toggle={handleCloseModal} modal={showModal} />
}
