import { BadgeType } from 'src/Components/Badge'
import i18n from 'src/Localization/i18n'

export const getBonusValue = (status: string, bonus?: number): string => {
  if (status === 'registered') {
    return i18n.t('referAndEarn.waitingForCreating')
  }
  if (status === 'pending') {
    return i18n.t('referAndEarn.waitingForBonus')
  }
  return `$${bonus || 0}`
}

export const getPlateType = (status: string): BadgeType => {
  if (status === 'registered') {
    return 'warning'
  }
  if (status === 'pending') {
    return 'disabled'
  }
  return 'regular'
}
