import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { TikTokTrack } from 'src/Types'
import { setApiError } from 'src/Hooks/redux'

import { NameSpace, SliceState, SliceStatus } from '../types'

import {
  getTikTokTrack,
  getTikTokTrackWithoutAuth,
  searchTikTokTrackByName,
} from './api-actions'

export interface TikTokTrackState extends SliceState {
  tikTokTrack: TikTokTrack | null
  tikTokTracks: Array<TikTokTrack> | null
}

const initialState: TikTokTrackState = {
  tikTokTrack: null,
  status: SliceStatus.Idle,
  tikTokTracks: null,
  error: null,
}

export const tikTokTrackProcess = createSlice({
  initialState,
  name: NameSpace.Artists,
  reducers: {
    setTiktokTrackToInitialState(state) {
      state.tikTokTrack = null
      state.error = null
      state.status = SliceStatus.Idle
    },

    setTiktokTracksToInitialState(state) {
      state.tikTokTracks = null
      state.error = null
      state.status = SliceStatus.Idle
    },
    setTikTokTrackFromSearchList(state, action: PayloadAction<TikTokTrack>) {
      state.tikTokTrack = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTikTokTrack.pending, (state) => {
      state.status = SliceStatus.Loading
    })
    builder.addCase(getTikTokTrack.rejected, (state, action) => {
      state.status = SliceStatus.Failed
      state.error = setApiError(action)
      state.tikTokTrack = null
    })
    builder.addCase(getTikTokTrack.fulfilled, (state, action) => {
      state.tikTokTracks = [action.payload]
      state.error = null
      state.status = SliceStatus.Succeeded
    })

    builder.addCase(searchTikTokTrackByName.pending, (state) => {
      state.status = SliceStatus.Loading
    })
    builder.addCase(searchTikTokTrackByName.rejected, (state, action) => {
      state.status = SliceStatus.Failed
      state.error = setApiError(action)
      state.tikTokTracks = null
    })
    builder.addCase(searchTikTokTrackByName.fulfilled, (state, action) => {
      state.tikTokTracks = action.payload.music || null
      state.error = null
      state.status = SliceStatus.Succeeded
    })

    builder.addCase(getTikTokTrackWithoutAuth.pending, (state) => {
      state.status = SliceStatus.Loading
    })
    builder.addCase(getTikTokTrackWithoutAuth.rejected, (state, action) => {
      state.status = SliceStatus.Failed
      state.error = setApiError(action)
      state.tikTokTrack = null
    })
    builder.addCase(getTikTokTrackWithoutAuth.fulfilled, (state, action) => {
      state.tikTokTracks = [action.payload]
      state.error = null
      state.status = SliceStatus.Succeeded
    })
  },
})

export const {
  setTiktokTrackToInitialState,
  setTiktokTracksToInitialState,
  setTikTokTrackFromSearchList,
} = tikTokTrackProcess.actions
