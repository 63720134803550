import React from 'react'

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import {
  PrimaryButton,
  PrimaryButtonProps,
} from 'src/Components/Buttons/PrimaryButton'
import { HUNDREDTHS } from 'src/Constants/numeric'
import { MAX_MOBILE_WIDTH } from 'src/Styled/variables'

const StyledBalanceButton = styled(PrimaryButton).attrs({
  className: 'balance-button',
})`
  &.balance-button {
    color: ${({ theme }) => theme.button.thirdText};

    svg {
      path {
        fill: ${({ theme, loading }) =>
          loading ? theme.button.fifthText : theme.button.thirdText};
      }
    }

    &:active {
      color: ${({ theme }) => theme.button.fifthText};
      border: ${({ theme }) => `1px solid ${theme.button.secondaryBorder}`};

      svg {
        path {
          fill: ${({ theme }) => theme.button.fifthText};
        }
      }

      &:hover {
        box-shadow: none;
      }
    }

    &:disabled {
      color: ${({ theme }) => theme.button.secondaryText};
      border: ${({ theme }) => `1px solid ${theme.button.primaryBorder}`};
      svg {
        path {
          fill: ${({ theme }) => theme.button.secondaryText};
        }
      }
    }

    @media (max-width: ${MAX_MOBILE_WIDTH}) {
      flex-grow: 1;
    }
  }
`

const BalanceDescription = styled.span`
  @media (max-width: ${MAX_MOBILE_WIDTH}) {
    display: none;
  }
`

export type BalanceButtonProps = Pick<
  PrimaryButtonProps,
  'loading' | 'onClick' | 'className' | 'disabled'
> & { balance: number }

export const BalanceButton: React.FC<BalanceButtonProps> = ({
  balance = 0,
  loading,
  onClick,
  className,
  disabled,
}) => {
  const { t } = useTranslation()

  const formattedBalance = balance.toFixed(HUNDREDTHS)

  return (
    <StyledBalanceButton
      loading={loading}
      disabled={disabled}
      onClick={onClick}
      className={className}
    >
      <BalanceDescription>{t('header.balanceButtonTitle')}</BalanceDescription>
      <span>{`$${formattedBalance}`}</span>
    </StyledBalanceButton>
  )
}
