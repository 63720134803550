import { FunctionComponent, useMemo, useState } from 'react'

import { PopupButton } from '@typeform/embed-react'
import { useSelector } from 'react-redux'

import { useAppDispatch } from 'src/Hooks/redux'
import {
  getClosedTypeformIds,
  setClosedTypeformList,
} from 'src/Redux/typeform-process'

import { SurveyForEndedCampaignProps } from './SurveyForEndedCampaignProps'

const TIMEOUT = 200
const INNER_WIDTH = 576
const CORRECT_INNER_WIDTH = 550
const DEFAULT_WIDTH = 320

const SurveyForEndedCampaign: FunctionComponent<
  SurveyForEndedCampaignProps
> = ({
  userEmail,
  campaignId,
  campaignType,
  campaignName,
  isFeed,
  id,
  text,
}) => {
  const dispatch = useAppDispatch()
  const [typeformHasBeenOpened, setTypeformHasBeenOpened] = useState<
    'load' | 'exit'
  >('load')

  const removeOverflowHidden = (): void => {
    setTimeout(() => document.body.removeAttribute('style'), TIMEOUT)
  }

  const res = useMemo(() => {
    if (window.innerWidth > INNER_WIDTH) {
      return CORRECT_INNER_WIDTH
    }
    return DEFAULT_WIDTH
  }, [])

  const closedIds = useSelector(getClosedTypeformIds)

  const closedTypeformIds = [...closedIds, String(campaignId)]

  const hiddenFields = useMemo(
    () => ({
      email: userEmail,
      campaign_id: campaignId,
      campaign_type: campaignType,
      campaign_name: campaignName,
      is_feed: isFeed ? 'yes' : 'no',
    }),
    [userEmail, campaignId, campaignType, campaignName, isFeed],
  )

  if (
    [userEmail, campaignId, campaignType, campaignName, id].some(
      (field) => !field,
    )
  ) {
    return null
  }

  return (
    <PopupButton
      size={70}
      hidden={hiddenFields}
      id={id}
      open={typeformHasBeenOpened}
      width={res}
      style={{
        visibility: 'hidden',
      }}
      onSubmit={() => {
        setTypeformHasBeenOpened('exit')
        removeOverflowHidden()
        dispatch(setClosedTypeformList(closedTypeformIds))
      }}
      onClose={() => {
        dispatch(setClosedTypeformList(closedTypeformIds))
      }}
    >
      {text}
    </PopupButton>
  )
}

export default SurveyForEndedCampaign
