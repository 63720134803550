import { capitalizeFirstLetter } from 'src/Helpers/layoutHelpers'
import { ProperGenreOrLanguage } from 'src/Types'

export const renderGenresLanguages = (
  items: Array<ProperGenreOrLanguage>,
): string[] =>
  items.map(
    (el, id) =>
      `${capitalizeFirstLetter(el.name)}${id === items.length - 1 ? '' : ', '}`,
  )
