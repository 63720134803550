import { ReactElement } from 'react'

import { components, MultiValueRemoveProps } from 'react-select'

import IconClose from 'src/Assets/Svg/close.svg?react'

import { IconCloseStyled } from './styles'

export const MultiValueRemove = <T,>(
  props: MultiValueRemoveProps<T>,
): ReactElement => (
  <components.MultiValueRemove {...props}>
    <IconCloseStyled>
      <IconClose width={10} height={10} />
    </IconCloseStyled>
  </components.MultiValueRemove>
)
