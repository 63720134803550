import { FC } from 'react'

import { Route, Routes } from 'react-router-dom'

import Tracks from 'src/Containers/Tracks'
import Settings from 'src/Containers/SettingsInfuencer'
import InfluencerBalancePage from 'src/Containers/InfluencerBalancePage'
import Rate from 'src/Containers/Rate'
import { Monetization } from 'src/Containers/Monetization'
import { SoundsPage } from 'src/Containers/SoundsPage'
import { NewSoundDetailsModal } from 'src/Containers/SoundsPage/components/SoundDetailsModal/components/NewSoundDetailsModal'
import { InProgressSoundDetailsModal } from 'src/Containers/SoundsPage/components/SoundDetailsModal/components/InProgressSoundDetailsModal'
import { SubmittedSoundDetailsModal } from 'src/Containers/SoundsPage/components/SoundDetailsModal/components/SubmittedSoundDetailsModal'

import {
  influencerBoard,
  settings,
  balance,
  rate,
  sounds,
  monetization,
} from './routes'

export const InfluencerComponents: FC = () => (
  <Routes>
    <Route Component={Tracks} path={influencerBoard} />
    <Route Component={Settings} path={settings} />
    <Route Component={InfluencerBalancePage} path={balance} />
    <Route Component={Rate} path={rate} />
    <Route Component={Monetization} path={monetization} />
    <Route Component={Tracks} path='*' />
    <Route Component={SoundsPage} path={sounds}>
      <Route Component={SoundsPage} path={':activeTab'}></Route>
    </Route>
    <Route Component={NewSoundDetailsModal} path={`${sounds}/new/:soundId`} />
    <Route
      Component={InProgressSoundDetailsModal}
      path={`${sounds}/inProgress/:soundId`}
    />
    <Route
      Component={SubmittedSoundDetailsModal}
      path={`${sounds}/submitted/:soundId`}
    />
  </Routes>
)
