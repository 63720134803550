import { createDraftSafeSelector } from '@reduxjs/toolkit'

import { TransactionsState } from 'src/Redux/transactions-process'

import { RootState } from '..'
import { SliceStatus } from '../types'

const getState = (state: RootState): TransactionsState =>
  state.transactions.transactions

export const getTransactionsLoading = createDraftSafeSelector(
  getState,
  ({ status }) => status === SliceStatus.Loading,
)

export const getCreatorTransactionsSelector = createDraftSafeSelector(
  getState,
  ({ creatorTransactions }) => creatorTransactions,
)

export const getTransactionsTotalCount = createDraftSafeSelector(
  getState,
  ({ totalTransactions }) => totalTransactions,
)

export const getIsRecipient = createDraftSafeSelector(
  getState,
  ({ recipient }) => recipient,
)

export const getHasAwaiting = createDraftSafeSelector(
  getState,
  ({ hasAwaiting }) => hasAwaiting,
)

export const getTransactionsPayment = createDraftSafeSelector(
  getState,
  ({ transactionsPayment }) => transactionsPayment,
)

export const getTransactionsPaidOut = createDraftSafeSelector(
  getState,
  ({ transactionsPaidOut }) => transactionsPaidOut,
)

export const getTransactionsError = createDraftSafeSelector(
  getState,
  ({ error }) => error,
)
