import { FC } from 'react'

import styled from 'styled-components'

const Rate = styled.span`
  white-space: nowrap;
`
interface SideBarBadgeProps {
  reviewsLength?: number
  balance?: string
  rank?: string
  level?: number | null
}

export const SideBarBadge: FC<SideBarBadgeProps> = ({
  reviewsLength,
  rank,
  level,
  balance,
}) => {
  if (reviewsLength) {
    return <span>{reviewsLength}</span>
  }
  if (balance) {
    return <span>${balance}</span>
  }
  if (rank) {
    return <span>{rank.toLocaleString()}</span>
  }
  if (level) {
    return <Rate>{`level ${level}`}</Rate>
  }
  return null
}
