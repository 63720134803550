import styled from 'styled-components'

import { colors, variables } from 'src/Styled/variables'

export const Wrapper = styled.li`
  display: flex;
  padding: 15px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 4px;
  background-color: ${colors.white};
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #f9f9f9;
  }
`

export const BonusContainer = styled.div`
  @media (min-width: 768px) {
    width: 200px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
`
export const PlateContainer = styled.div`
  @media (min-width: 768px) {
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  @media (min-width: 768px) {
    width: 360px;
  }
`

export const Avatar = styled.img`
  border-radius: 50%;
  height: 40px;
  width: 40px;
  object-fit: cover;
`

export const Name = styled.div`
  color: ${colors.mainText};
  font-size: ${variables.fontSize14};
  line-height: 150%;
`

export const Email = styled.div`
  color: ${colors.mainText};
  font-size: ${variables.fontSize10};
  line-height: 150%;
`

export const DateContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`

export const SubTitle = styled.div`
  color: #999;
  font-size: ${variables.fontSize10};
  line-height: 150%;
`

export const Value = styled.div`
  color: ${colors.mainText};
  font-size: ${variables.fontSize14};
  line-height: 150%;
`
