import { FC } from 'react'

import { useTranslation } from 'react-i18next'

import track from 'src/Assets/Png/track.png'
import { NewPlaylistToSet } from 'src/Types'

import {
  Playlist,
  PlaylistAuthor,
  PlaylistAvatar,
  PlaylistInfo,
  PlaylistTitle,
} from '../styles'

interface NewPlaylistProps {
  newPlaylistToSet?: NewPlaylistToSet | null
  onItemClickHandler: () => void
}

export const NewPlaylist: FC<NewPlaylistProps> = ({
  newPlaylistToSet,
  onItemClickHandler,
}) => {
  const { t } = useTranslation()
  if (newPlaylistToSet) {
    const playlistSrc = newPlaylistToSet?.images?.[0]?.url
    const src = typeof playlistSrc === 'string' ? playlistSrc : track
    return (
      <Playlist role='button' onClick={onItemClickHandler}>
        <PlaylistAvatar src={src} fallbackSrc={track} alt='avatar' />
        <PlaylistInfo>
          <PlaylistTitle>{newPlaylistToSet?.name}</PlaylistTitle>
          <PlaylistAuthor>{`${t('playlistsPage.by')} ${
            newPlaylistToSet?.owner?.display_name
          }`}</PlaylistAuthor>
        </PlaylistInfo>
      </Playlist>
    )
  }
  return null
}
