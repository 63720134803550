import { FC } from 'react'

import { RadioProps } from 'antd'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { PaymentMethodEnum } from 'src/Constants/enums'

import { PrimaryRadio } from './PrimaryRadio'
type Props = RadioProps

const Text = styled.div`
  color: ${({ theme }) => theme.common.primaryText};
  font-family: 'Segoe UI';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`
export const CreditsRadioButton: FC<Props> = (props) => {
  const { t } = useTranslation()
  return (
    <PrimaryRadio value={PaymentMethodEnum.Credits} {...props}>
      <Text>{t('schedulePaymentMethod.credits')}</Text>
    </PrimaryRadio>
  )
}
