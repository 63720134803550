import { FunctionComponent } from 'react'

import { useSelector } from 'react-redux'

import { getReviewedTracksSelector } from 'src/Redux/reviews-process/selectors'
import { getUserSelector } from 'src/Redux/auth-process/userSlice/selectors'

import { CuratorSurveyAfterPayoutRequestProps } from './props'

import { StyledSliderButton } from './styles'

export const CuratorSurveyAfterPayoutRequest: FunctionComponent<
  CuratorSurveyAfterPayoutRequestProps
> = ({ typeformRef }) => {
  const user = useSelector(getUserSelector)
  const reviewedCampaigns = useSelector(getReviewedTracksSelector)
  const reviewsMade = reviewedCampaigns?.length.toString()

  return (
    <StyledSliderButton
      hidden={{
        email: user.email,
        registration_date: user.createdAt,
        reviews_made: reviewsMade,
      }}
      id='ik6NwSyH'
      style={{
        position: 'absolute',
        visibility: 'hidden',
      }}
      ref={typeformRef}
    >
      Payout
    </StyledSliderButton>
  )
}
