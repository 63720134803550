import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'

import { APP } from 'src/Configs/App'
import { api } from 'src/Services/api'

import { NameSpace, ErrorCode } from '../types'

import { SimilarGenre } from '.'

export const getSimilarGenres = createAsyncThunk(
  `${NameSpace.SIMILAR_GENRES}/getSimilarGenres`,
  async (campaignId: number, { rejectWithValue }) => {
    try {
      const { data } = await api.get<SimilarGenre[]>(
        `${APP.TIKTOK_SERVER}/spotify-campaign/${campaignId}/similar-artist-genres`,
      )
      return data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      return rejectWithValue({
        message: response?.data.message,
        code: response?.status || ErrorCode.InternalServerError,
      })
    }
  },
)
