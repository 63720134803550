import styled from 'styled-components'

import { device } from 'src/Styled/variables'

export const SpotifyCheckoutContainer = styled.div`
  padding-top: 8px;
  display: grid;
  gap: 16px;
  grid-template-rows: auto auto;

  @media ${device.tabletMin} {
    grid-template-columns: 1fr 320px;
  }

  @media ${device.mobileMax} {
    flex-direction: column;
    padding-bottom: 64px;
  }
`
