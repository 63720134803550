import { FC, useEffect, useMemo, useState } from 'react'

import { useIntercom } from 'react-use-intercom'
import { useTranslation } from 'react-i18next'
import { ActionMeta } from 'react-select'

import Button from 'src/Components/Buttons/Button'
import { UPDATE_GENRES_LIMIT } from 'src/Constants/errorCodes'
import { Desktop, Mobile } from 'src/Components/Responsive'
import CloseIcon from 'src/Assets/Svg/close-cross-white.svg?react'
import { DottedBtn } from 'src/Components/DottedBtn/DottedBtn'
import {
  ActionsForSelectGenresAndLanguagesEnum,
  Genre,
  Language,
} from 'src/Types/index'
import { ApiError } from 'src/Redux/types'
import { capitalizeFirstLetter } from 'src/Helpers/layoutHelpers'

import { getSelectedGenres, optionsSelectLanguages } from './helpers'
import { PlaylistEditModalProps, SelectOption } from './props'
import { UpdateGenresLimitAlert } from './UpdateGenresLimitAlert'
import { PlayListInfo } from './PlayListInfo'
import { RelevantGenres } from './RelevantGenres'
import { PlaylistLanguagesSelect } from './PlaylistLanguagesSelect'
import { PlaylistGenresSelect } from './PlaylistGenresSelect'
import { ShowLoading } from './ShowLoading'
import { ShowIrrelevant } from './ShowIrrelevant'
import { MobilePlaylistEditModalWithTitle } from './MobilePlaylistEditModalWithTitle'
import { DesktopHintPortal } from './DesktopHintPortal'
import { usePlaylistEditHint } from './usePlaylistEditHint'
import { ShowSuccessContent } from './ShowSuccessContent'

import {
  ContentBlock,
  MobileButtonContainer,
  ButtonContainer,
  PlaylistWrapper,
  DesktopPlaylistWrapper,
  DesktopWrapper,
  DesktopGenresWrapper,
  DesktopBeCarefulWrapper,
  BeCarefulMobileButton,
  DesktopModal,
} from './styles'
const MAX_GENRES = 10

const PlaylistEditModal: FC<PlaylistEditModalProps> = ({
  item: { selectedGenres, selectedLanguages, id, relevantGenres, playListInfo },
  isOpen,
  toggle,
  handleSave,
  loading,
  isNew,
  languages,
}) => {
  const { t } = useTranslation()

  const [selectedInputGenres, setSelectedInputGenres] = useState<
    SelectOption[]
  >([])
  const [selectedInputLanguages, setSelectedLanguages] = useState<
    Array<Language>
  >([])
  const [hintGuide, onSetHint] = usePlaylistEditHint()
  const [successContent, setSuccessContent] = useState(false)
  const [isUpdateGenresLimitAlert, setIsUpdateGenresLimitAlert] =
    useState(false)

  const meetConditions = useMemo(
    () => Boolean(relevantGenres && relevantGenres.length),
    [relevantGenres],
  )

  const disabledButton =
    selectedInputLanguages.length === 0 ||
    !(
      selectedInputGenres.length >= 2 &&
      selectedInputGenres.length <= MAX_GENRES
    )

  const onModalClose = (): void => {
    setSuccessContent(false)
    toggle(false)
    setIsUpdateGenresLimitAlert(false)
  }
  const { show } = useIntercom()

  useEffect(() => {
    const selected = optionsSelectLanguages(selectedLanguages)
    setSelectedLanguages(selected)
  }, [selectedLanguages])

  const onChangeSelectLanguages = (
    value: unknown,
    actionMeta: ActionMeta<unknown>,
  ): void => {
    const action = actionMeta.action as ActionsForSelectGenresAndLanguagesEnum
    const option = actionMeta.option as SelectOption
    const selectedOptions = value as SelectOption[]
    if (action === ActionsForSelectGenresAndLanguagesEnum.SELECT_OPTION) {
      if (option.value === 1) {
        return setSelectedLanguages([option])
      }

      const selectedOptionsWithoutNoVocal = selectedOptions.filter(
        (lang) => lang.value !== 1,
      )

      setSelectedLanguages(selectedOptionsWithoutNoVocal)
    } else {
      setSelectedLanguages(selectedOptions)
    }
  }

  useEffect(() => {
    const selected = getSelectedGenres(selectedGenres)
    selected && setSelectedInputGenres(selected)
  }, [selectedGenres])

  const onChangeSelectGenres = (value: unknown): void => {
    const selectedOptions = value as SelectOption[]
    setSelectedInputGenres(selectedOptions)
  }

  const onCheckRelevantGenre = (genre: Genre): void => {
    const isChecked = selectedInputGenres.find(
      (item) => item.value === genre.id,
    )

    if (!isChecked) {
      const genres = [
        ...selectedInputGenres,
        {
          value: Number(genre.id),
          label: capitalizeFirstLetter(genre.genre_name || ''),
        },
      ]
      setSelectedInputGenres(genres)
    }
  }

  const onSubmit = async (): Promise<void> => {
    await handleSave(
      Number(id),
      selectedInputGenres.map((item) => ({
        id: Number(item.value),
        genre_name: item.label.toLowerCase(),
      })),
      selectedInputLanguages.map((item) => ({
        id: Number(item.value),
        language_name: item.label,
      })),
      [],
      (error: ApiError | null) => {
        if (error && error.message === UPDATE_GENRES_LIMIT) {
          setIsUpdateGenresLimitAlert(true)
        } else {
          setSuccessContent(true)
        }
      },
    )
  }

  return (
    <>
      <Desktop>
        <DesktopModal
          open={isOpen}
          title={
            isNew
              ? t('scheduleCampaignPage.addPlaylist')
              : t('scheduleCampaignPage.editPlaylist')
          }
          closeIcon={<CloseIcon width={10} height={10} />}
          footer={null}
          onCancel={onModalClose}
        >
          <ShowLoading loading={loading}>
            <ShowSuccessContent
              show={successContent}
              isNew={isNew}
              onClose={onModalClose}
            >
              <DesktopWrapper>
                <DesktopHintPortal
                  component={hintGuide.component}
                  onSetHint={onSetHint}
                />
                <PlayListInfo name={playListInfo.name} img={playListInfo.img} />
              </DesktopWrapper>
              <ShowIrrelevant meetCondition={meetConditions}>
                <div>
                  <DesktopPlaylistWrapper>
                    <RelevantGenres
                      relevantGenres={relevantGenres || []}
                      selectedInputGenres={selectedInputGenres}
                      onCheckRelevantGenre={onCheckRelevantGenre}
                    />
                  </DesktopPlaylistWrapper>
                  <DesktopGenresWrapper>
                    <PlaylistGenresSelect
                      selectedInputGenres={selectedInputGenres}
                      onChangeSelectGenres={onChangeSelectGenres}
                      onSetHint={onSetHint}
                      hintName={hintGuide && hintGuide.name}
                    />
                  </DesktopGenresWrapper>
                </div>
                <DesktopBeCarefulWrapper>
                  <DottedBtn
                    content={t('scheduleCampaignPage.haveIssuesWithGenres')}
                    onClick={show}
                  />
                </DesktopBeCarefulWrapper>
                <PlaylistLanguagesSelect
                  selectedInputLanguages={selectedInputLanguages}
                  hintName={hintGuide && hintGuide.name}
                  languages={languages}
                  handleSetHint={onSetHint}
                  onChangeSelectLanguages={onChangeSelectLanguages}
                />
              </ShowIrrelevant>
              <UpdateGenresLimitAlert show={isUpdateGenresLimitAlert} />
              <ButtonContainer>
                <Button
                  disabled={disabledButton}
                  type='green'
                  onClick={onSubmit}
                >
                  {t('targetGenres.save')}
                </Button>
              </ButtonContainer>
            </ShowSuccessContent>
          </ShowLoading>
        </DesktopModal>
      </Desktop>
      <Mobile>
        <ShowSuccessContent
          show={successContent}
          isNew={isNew}
          onClose={onModalClose}
        >
          <MobilePlaylistEditModalWithTitle
            isNew={isNew}
            onModalClose={onModalClose}
          >
            <ShowLoading loading={loading}>
              <PlayListInfo name={playListInfo.name} img={playListInfo.img} />
              <ShowIrrelevant meetCondition={meetConditions}>
                <ContentBlock last>
                  <div>
                    <PlaylistWrapper>
                      <RelevantGenres
                        relevantGenres={relevantGenres || []}
                        selectedInputGenres={selectedInputGenres}
                        onCheckRelevantGenre={onCheckRelevantGenre}
                      />
                    </PlaylistWrapper>
                    <PlaylistWrapper>
                      <PlaylistGenresSelect
                        selectedInputGenres={selectedInputGenres}
                        onChangeSelectGenres={onChangeSelectGenres}
                        onSetHint={onSetHint}
                        hintName={hintGuide && hintGuide.name}
                      />
                    </PlaylistWrapper>
                  </div>

                  <BeCarefulMobileButton>
                    <DottedBtn
                      content={t('scheduleCampaignPage.haveIssuesWithGenres')}
                      onClick={show}
                    />
                  </BeCarefulMobileButton>
                  <PlaylistLanguagesSelect
                    selectedInputLanguages={selectedInputLanguages}
                    hintName={hintGuide && hintGuide.name}
                    languages={languages}
                    handleSetHint={onSetHint}
                    onChangeSelectLanguages={onChangeSelectLanguages}
                  />
                  <UpdateGenresLimitAlert show={isUpdateGenresLimitAlert} />
                </ContentBlock>
              </ShowIrrelevant>
              <MobileButtonContainer>
                <Button
                  type='green'
                  disabled={disabledButton}
                  onClick={onSubmit}
                >
                  {t('targetGenres.save')}
                </Button>
              </MobileButtonContainer>
            </ShowLoading>
          </MobilePlaylistEditModalWithTitle>
        </ShowSuccessContent>
      </Mobile>
    </>
  )
}

export default PlaylistEditModal
