import { FC } from 'react'

import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { useIntercom } from 'react-use-intercom'
import { useTranslation } from 'react-i18next'

import { useAppSelector } from 'src/Hooks/redux'
import { getSubscriptionErrorSelector } from 'src/Redux/subscription-process/subscription/selectors'
import Alert from 'src/Components/Alert'
import { DotedButton } from 'src/Components/Buttons/DotedButton'
import { colors } from 'src/Styled/variables'
import { subscription } from 'src/Router/routes'

const AlertText = styled.div`
  color: ${colors.mainText};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`
const AlertTitle = styled.div`
  color: ${colors.mainText};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
`
const ButtonContainer = styled.div`
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 0px 16px;
  align-self: stretch;
  flex-wrap: wrap;
`
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  margin-bottom: 16px;
`
export const SubscriptionAlert: FC = () => {
  const error = useAppSelector(getSubscriptionErrorSelector)
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { show } = useIntercom()

  if (!error) {
    return null
  }

  const handleManageClick = (): void => {
    navigate(subscription)
  }
  const handleSupportClick = (): void => {
    show()
  }
  return (
    <Alert color='danger' icon>
      <Container>
        <AlertTitle>{t('getPremiumPage.subscriptionFailed')}</AlertTitle>
        <AlertText>{t('getPremiumPage.pleaseContact')}</AlertText>
      </Container>
      <ButtonContainer>
        <DotedButton onClick={handleManageClick} color={colors.messegesRed}>
          {t('getPremiumPage.manageYour')}
        </DotedButton>
        <DotedButton onClick={handleSupportClick} color={colors.mainText}>
          {t('campaignsPage.contactSupport')}
        </DotedButton>
      </ButtonContainer>
    </Alert>
  )
}
