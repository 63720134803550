import styled from 'styled-components'

import { colors, variables } from 'src/Styled/variables'

export const DateWrapper = styled.div`
  display: grid;
  max-width: 140px;
  color: ${colors.mainText};
  font-size: ${variables.fontSize10};
  font-weight: ${variables.fontWeight400};
  line-height: 150%;
`

export const NotStarted = styled.div`
  color: ${colors.mainText};
  font-size: ${variables.fontSize10};
  font-weight: ${variables.fontWeight400};
  line-height: 150%;
  padding-top: 4px;
`
