import { createDraftSafeSelector } from '@reduxjs/toolkit'

import { RootState } from 'src/Redux'
import { SliceStatus } from 'src/Redux/types'

import { SubscriptionState } from '.'

const getState = (state: RootState): SubscriptionState =>
  state.subscriptions.subscription

export const getSubscriptionSelector = createDraftSafeSelector(
  getState,
  (state) => state.data,
)
export const getHasSubscriptionSelector = createDraftSafeSelector(
  getState,
  (state) => {
    if (state.status !== SliceStatus.Succeeded) {
      return true
    }
    if (Boolean(state.data) && state.status === SliceStatus.Succeeded) {
      return Boolean(state.data)
    }
    return false
  },
)
export const getHasActiveOrCanceledSubscriptionSelector =
  createDraftSafeSelector(getState, ({ data }) =>
    Boolean(data?.status === 'active' || data?.status === 'canceled'),
  )
export const getHasActiveSubscriptionSelector = createDraftSafeSelector(
  getState,
  ({ data }) => data?.status === 'active',
)
export const getSubscriptionStatusSelector = createDraftSafeSelector(
  getState,
  (state) => state.status,
)
export const getSubscriptionErrorSelector = createDraftSafeSelector(
  getState,
  (state) => state.error,
)

export const isSubscriptionLoadingSelector = createDraftSafeSelector(
  getState,
  ({ status }) => status === SliceStatus.Loading,
)
export const getIsSubscriptionSucceededSelector = createDraftSafeSelector(
  getState,
  ({ status }) => status === SliceStatus.Succeeded,
)

export const getIsLoadingCancelSubscription = createDraftSafeSelector(
  getState,
  (state) => state.status === SliceStatus.Loading,
)

export const getPlanNameSelector = createDraftSafeSelector(
  getState,
  ({ data }) => data?.package?.name,
)
