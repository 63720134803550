import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { colors, variables } from 'src/Styled/variables'

const NewTitle = styled.div<{ $mb?: string }>`
  margin-bottom: ${({ $mb }) => $mb || '8px'};
  color: ${colors.mainText};
  font-size: ${variables.fontSize24};
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
  @media (max-width: 576px) {
    font-size: ${variables.fontSize20};
  }
`
const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 10px;
`

export const RegisterTitle: FC = () => {
  const { t } = useTranslation()

  return (
    <TitleWrapper>
      <NewTitle $mb={'0'}>{t('login.yourContactDetails')}</NewTitle>
    </TitleWrapper>
  )
}
