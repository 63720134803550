import { FC, useEffect, useMemo } from 'react'

import styled from 'styled-components'
import { useLocation, useParams } from 'react-router-dom'

import { CampaignPlatformTypeEnum, CampaignTypeEnum } from 'src/Types'
import { onPlaySearchedTrack } from 'src/Helpers/TagManager/TrackEvent'
import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { getUserId } from 'src/Redux/auth-process/userSlice/selectors'
import {
  getIsArtist,
  getUserRole,
} from 'src/Redux/auth-process/credentialsSlice/selectors'
import { useSearchTrack } from 'src/Containers/SearchNewCampaignByPlatform/useSearchTrack'
import { getSpotifyAccessToken } from 'src/Redux/spotifyToken-process/api-actions'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import { useSearchTrackEvents } from 'src/Hooks/Analytics/useSearchTrackEvents'
import { MAX_MOBILE_WIDTH } from 'src/Styled/variables'

import { SearchedTrack, TrackSearch } from '../../Components/TrackSearch'
import { TrackSearchError } from '../../Components/TrackSearchError'
import { CreateCampaignBreadcrumbsAuth } from '../../Components/CreateCampaignBreadcrumbsAuth'
import { mapSpotifyTrack, mapTikTokTrack } from '../../helper'
import { FaqComponent } from '../../Components/FaqComponent'

import { SuccessCreatedCampaign } from './SuccessCreatedCampaign'
import { SelectedNewTrack } from './SelectedNewTrack'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  gap: 40px;
  @media screen and (min-width: ${MAX_MOBILE_WIDTH}) {
    gap: 16px;
  }
`

const SearchContainer = styled.div`
  width: 100%;
  display: flex;
  max-width: 1280px;
  flex-direction: column;
  gap: 80px;
  align-items: center;
`

const StyledFaq = styled(FaqComponent)`
  align-self: center;
`

interface LocationState {
  successCreatedCampaign: boolean
}

export const SearchTrackAuth: FC = () => {
  const { platform } = useParams<{ platform: CampaignPlatformTypeEnum }>()
  const { search } = useCustomSearchParams()
  const { state } = useLocation() as {
    state: LocationState
  }
  const dispatch = useAppDispatch()

  const userRole = useAppSelector(getUserRole)
  const isArtist = useAppSelector(getIsArtist)
  const userId = useAppSelector(getUserId)
  const role = useAppSelector(getUserRole)

  const defaultSearchValue = search.get('trackName')

  const isTikTok = platform === CampaignPlatformTypeEnum.TIKTOK

  useSearchTrackEvents(CampaignTypeEnum.BASIC, platform)

  const {
    onTrackSearchHandler,
    loading,
    tracks,
    onCheckTrack,
    onClearTracks,
    hasSelectedTrack,
    error,
  } = useSearchTrack(platform)

  useEffect(() => {
    const adminItem = localStorage.getItem('admin')
    const isAdmin = adminItem ? Boolean(JSON.parse(adminItem)) : false

    void dispatch(getSpotifyAccessToken(isAdmin)).unwrap()
  }, [dispatch, isArtist, userRole])

  const formattedTracks = useMemo<SearchedTrack[]>(() => {
    if (platform === CampaignPlatformTypeEnum.SPOTIFY) {
      return tracks.map(mapSpotifyTrack)
    }

    if (platform === CampaignPlatformTypeEnum.TIKTOK) {
      return tracks.map(mapTikTokTrack)
    }

    return []
  }, [platform, tracks])

  if (!platform) {
    return null
  }

  const onPlayClick = (): void => {
    onPlaySearchedTrack(CampaignTypeEnum.BASIC, role, platform, userId)
  }

  const onItemClick = (trackId: string): void => {
    onCheckTrack(trackId)
  }

  const onSearch = (searchInput: string): void => {
    onTrackSearchHandler({ searchInput })
  }

  const onOutsideClick = (): void => {
    onClearTracks()
  }

  return (
    <Container>
      <CreateCampaignBreadcrumbsAuth />
      {!hasSelectedTrack && (
        <SearchContainer>
          <TrackSearch
            platform={platform}
            isLoading={loading}
            onPlayClick={onPlayClick}
            onItemClick={onItemClick}
            tracks={formattedTracks}
            onSearch={onSearch}
            onOutsideClick={onOutsideClick}
            defaultSearchValue={defaultSearchValue}
            error={error && <TrackSearchError>{error}</TrackSearchError>}
          />

          {isTikTok && <StyledFaq />}
        </SearchContainer>
      )}

      {hasSelectedTrack && (
        <SearchContainer>
          <SelectedNewTrack /> {isTikTok && <StyledFaq />}
        </SearchContainer>
      )}

      {Boolean(state?.successCreatedCampaign) && <SuccessCreatedCampaign />}
    </Container>
  )
}
