import { FC } from 'react'

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { StarIcon } from 'src/Assets/Svg'
import { useSubscription } from 'src/Hooks/useSubscription'
import { colors } from 'src/Styled/variables'
import { capitalizeFirstLetter } from 'src/Helpers/layoutHelpers'
import { getHasActiveOrCanceledSubscriptionSelector } from 'src/Redux/subscription-process/subscription/selectors'
import { useAppSelector } from 'src/Hooks/redux'

const Text = styled.div`
  color: ${colors.mainText};
`
const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

export const SubscriptionInfo: FC = () => {
  const { t } = useTranslation()
  const plan = useSubscription()
  const hasPlan = useAppSelector(getHasActiveOrCanceledSubscriptionSelector)
  const planName = plan?.package?.name
  if (!planName || !hasPlan) {
    return null
  }
  const text = `${capitalizeFirstLetter(planName)} ${t('getPremiumPage.plan')}`
  return (
    <Container>
      <StarIcon fill={colors.green} />
      <Text>{text}</Text>
    </Container>
  )
}
