import { useState, useCallback } from 'react'

import { useMetadataQuery } from 'src/Redux/metadataApi'
import { useAppSelector } from 'src/Hooks/redux'
import { getUserRole } from 'src/Redux/auth-process/credentialsSlice/selectors'
import { Roles } from 'src/Constants/enums'
import { useProductLiftScript } from 'src/Hooks/useProductLiftScript'

export const useRequestFeature = (): {
  showFeatureRequest: boolean
  attributeValue: string | undefined | null
  visited: boolean
  onFeatureClick: () => void
} => {
  useProductLiftScript()
  const userRole = useAppSelector(getUserRole)
  const { data } = useMetadataQuery(null, {
    skip: userRole !== Roles.artist,
  })

  const hasPayments = Boolean(
    data && data?.paymentsCountByStatus?.completed > 0,
  )

  const attributeValues: Record<string, string | undefined> = {
    [Roles.curator]: '1e5f5b2b-692b-4182-9e7e-e9444162f8f1',
    [Roles.artist]: 'd13cc2d8-6d70-4333-89b4-34c0d28d563d',
  }
  const attributeValue = userRole ? attributeValues[userRole] : null

  const [visited, setVisited] = useState(
    localStorage.getItem('productLift-sidebar') === attributeValue,
  )

  const onFeatureClick = useCallback(() => {
    if (visited || !attributeValue) {
      return
    }
    setVisited(true)
    localStorage.setItem('productLift-sidebar', attributeValue)
  }, [visited, attributeValue])

  return {
    showFeatureRequest: hasPayments,
    attributeValue,
    visited,
    onFeatureClick,
  }
}
