import styled from 'styled-components'

import { variables, colors } from 'src/Styled/variables'

export const TrackInfoContainer = styled.div`
  margin-bottom: 16px;
  padding: 24px;
  width: 100%;
  background-color: ${colors.white};
`
export const TrackInfoHeader = styled.div`
  display: flex;
  margin-bottom: 16px;
  align-items: center;
`

export const SpotifyIconContainer = styled.div`
  display: flex;
  align-items: center;
  width: 24px;
  height: 100%;

  @media (max-width: 576px) {
    height: 24px;
  }
`

export const TrackAvatar = styled.img`
  margin-left: 16px;
  width: 40px;
  height: 40px;
  border-radius: 4px;

  @media (max-width: 576px) {
    margin-left: 0;
    margin-bottom: 16px;
  }
`

export const TrackTitleContainer = styled.div`
  margin-left: 16px;
  padding: 2px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: 40px;
  flex: 1;
`

export const TrackTitle = styled.h5`
  margin: 0;
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight400};
  line-height: 21px;
  color: ${colors.mainText};
`

export const TrackAuthor = styled.div`
  font-size: ${variables.fontSize10};
  font-weight: ${variables.fontWeight400};
  color: ${colors.mainText};
  line-height: 15px;
`

export const CampaignCalculation = styled.div`
  height: 21px;
`
export const GenresAndLanguagesContainer = styled.div`
  padding: 8px 24px 0px 24px;
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 224px;
`
