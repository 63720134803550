import { FC, useState } from 'react'

import { useTranslation } from 'react-i18next'

import { Mobile } from 'src/Components/Responsive'
import IconSpotify from 'src/Assets/Svg/spotify.svg?react'
import { useSpotifyCampaignDetails } from 'src/Redux/campaignDetailsApi/useSpotifyCampaignDetails'

import { ProgressBarByBudget } from '../../ProgressBarByBudget'
import CampaignGenres from '../../CampaignGenres'
import CampaignLanguages from '../../CampaignLanguages'
import { CampaignCalculation } from '../CampaignCalculation'

import {
  GenresAndLanguagesContainer,
  SpotifyIconContainer,
  ToggleDetails,
  TrackAvatar,
  TrackAvatarMobile,
  TrackInfoColumnMobile,
  TrackInfoContainerMobile,
  TrackInfoRowMobile,
  TrackInfoTextMobile,
  TrackInfoWrapperMobile,
} from './styles'

export const CampaignMobileHeader: FC = () => {
  const { t } = useTranslation()
  const { campaignDetails } = useSpotifyCampaignDetails()
  const [showDetailsMobile, setShowDetailsMobile] = useState<boolean>(false)

  const toggleDetailsMobile = (): void =>
    setShowDetailsMobile(!showDetailsMobile)
  return (
    <Mobile>
      <TrackInfoContainerMobile>
        <TrackInfoWrapperMobile>
          <TrackAvatarMobile>
            <TrackAvatar
              src={campaignDetails?.Track.track_image}
              alt={campaignDetails?.Track.track_name}
            />
            <SpotifyIconContainer>
              <IconSpotify />
            </SpotifyIconContainer>
          </TrackAvatarMobile>
          <TrackInfoColumnMobile>
            <TrackInfoRowMobile>
              <TrackInfoTextMobile>
                {campaignDetails?.Track.track_name}
              </TrackInfoTextMobile>
            </TrackInfoRowMobile>
            <TrackInfoRowMobile>
              <TrackInfoTextMobile>
                {campaignDetails?.Track.artist_name}
              </TrackInfoTextMobile>
            </TrackInfoRowMobile>
            <GenresAndLanguagesContainer>
              <CampaignGenres genres={campaignDetails?.Track.TrackGenres} />
              <CampaignLanguages
                languages={campaignDetails?.Track.TrackLanguages}
              />
            </GenresAndLanguagesContainer>
          </TrackInfoColumnMobile>
        </TrackInfoWrapperMobile>
        {campaignDetails && campaignDetails.is_feed && (
          <ProgressBarByBudget campaignDetails={campaignDetails} />
        )}
        {showDetailsMobile && <CampaignCalculation />}
        <ToggleDetails onClick={toggleDetailsMobile}>
          {showDetailsMobile
            ? t('campaignResultsPage.hideDetails')
            : t('campaignResultsPage.showDetails')}
        </ToggleDetails>
      </TrackInfoContainerMobile>
    </Mobile>
  )
}
