import { useTranslation } from 'react-i18next'

import dayjs from 'src/Helpers/dayjs'
import {
  InfluencerCampaignStatus,
  InfluencerEventStatus,
} from 'src/Types/index'
import { colors } from 'src/Styled/variables'
import { calculateDaysLeft } from 'src/Constants/functions'
import i18n from 'src/Localization/i18n'

import { Tab } from '../components/TracksTable/types'

interface StatusView {
  text: string
  color: string
}

const RUNNING_STATUSES: string[] = [
  InfluencerCampaignStatus.RUNNING,
  InfluencerCampaignStatus.IN_PROCESS,
  InfluencerEventStatus.ACCEPTED,
]
const AWAITING_STATUSES: string[] = [
  InfluencerCampaignStatus.AWAITING_REVIEW,
  InfluencerEventStatus.SUBMISSION_AWAITING,
  InfluencerEventStatus.AWAITING_MODERATOR_REVIEW,
]

const APPROVED_STATUSES: string[] = [
  InfluencerEventStatus.APPROVED_BY_ARTIST,
  InfluencerEventStatus.APPROVED_AUTOMATICALLY,
  InfluencerEventStatus.APPROVED_BY_ADMIN,
]

const DECLINED_STATUSES: string[] = [
  InfluencerEventStatus.SUBMISSION_REJECTED,
  InfluencerCampaignStatus.DECLINED,
  InfluencerCampaignStatus.CANCELED,
  InfluencerEventStatus.CANCELED_BY_ARTIST,
  InfluencerEventStatus.CAMPAIGN_STOPED,
]
export const useStatusView = (
  status: InfluencerCampaignStatus | InfluencerEventStatus | string,
): StatusView => {
  const { t } = useTranslation()

  if (RUNNING_STATUSES.includes(status)) {
    return { text: t('influencer.running'), color: colors.messegesYellow1 }
  }
  if (AWAITING_STATUSES.includes(status)) {
    return {
      text: t('influencer.pendingApproval'),
      color: colors.messegesYellow1,
    }
  }

  if (APPROVED_STATUSES.includes(status)) {
    return { text: t('influencer.completed'), color: colors.messegesGreen1 }
  }
  if (DECLINED_STATUSES.includes(status)) {
    return { text: t('influencer.canceled'), color: colors.messegesRed }
  }

  return { text: t('influencer.new'), color: colors.messegesGreen1 }
}

export const calcTimeLeft = (endDate: string | Date): string => {
  const daysLeft = calculateDaysLeft(new Date(endDate))

  if (daysLeft && daysLeft === 1) {
    return `${daysLeft} ${i18n.t('influencer.dayLeft')}`
  }

  if (daysLeft && daysLeft > 0) {
    return `${daysLeft} ${i18n.t('influencer.daysLeft')}`
  }

  const hoursLeft = dayjs(endDate).diff(dayjs(), 'hours')

  if (hoursLeft && hoursLeft > 0) {
    return `${hoursLeft} ${i18n.t('influencer.hoursLeft')}`
  }

  return ''
}

export enum TabKeys {
  NEW = 'new',
  IN_PROGRESS = 'in_progress',
  SUBMITTED = 'submitted',
}

export const getTabs = (tubCounts: {
  newEvents: number
  inProgressEvents: number
  submittedEvents: number
}): Array<Tab> => [
  {
    key: TabKeys.NEW,
    title: 'influencer.allTracks',
    numbers: tubCounts.newEvents,
  },
  {
    key: TabKeys.IN_PROGRESS,
    title: 'influencer.inProgressTab',
    numbers: tubCounts.inProgressEvents,
  },
  {
    key: TabKeys.SUBMITTED,
    title: 'influencer.submitted',
    numbers: tubCounts.submittedEvents,
  },
]

export enum StatusTextEnum {
  NEW_TRACK = 'New track',
  IN_PROGRESS = 'In progress',
  AWAITING_REVIEW = 'Awaiting review',
  COMPLETE = 'Completed',
  CANCELED = 'Canceled',
}
