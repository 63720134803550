import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'

import { api } from 'src/Services/api'
import { APP } from 'src/Configs/App'

import { ErrorCode, NameSpace } from '../types'

interface ApplicantUpdateEmailBody {
  email: string
  authToken: string
}

export const applicantUpdateEmail = createAsyncThunk<
  unknown,
  ApplicantUpdateEmailBody
>(
  `${NameSpace.CRETOR_REGISTER}/applicantUpdateEmail`,
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await api.post<unknown>(
        `${APP.TIKTOK_SERVER}/auth/tiktok/applicant-update-email`,
        body,
      )
      return data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      return rejectWithValue({
        code: response?.status || ErrorCode.InternalServerError,
        message: response?.data?.message,
      })
    }
  },
)
