import { FC } from 'react'

import { useTranslation } from 'react-i18next'

import TikTokIcon from 'src/Assets/Svg/tiktok.svg?react'
import { lowercaseFirstLetter } from 'src/Helpers/layoutHelpers'
import { colors } from 'src/Styled/variables'
import { useAppSelector } from 'src/Hooks/redux'
import { getTransactionsLoading } from 'src/Redux/transactions-process/selectors'
import { MobileBalanceItems, TransactionTypeEnum } from 'src/Types'

import { LoadingSpinner } from '../../ui'
import { useArtistBalancePage } from '../../ArtistBalancePage/helpers'

import {
  Amount,
  HeaderTitle,
  MobileHeaderTitle,
  InfoText,
  MobileCard,
  MobileCardFooterWrapper,
  MobileCardHeaderWrapper,
  MobileCardInfoWrapper,
  MobileItemsWrapper,
  MobileLoadingWrapper,
  MobileNoItemsTextWrapper,
  MobileParamItem,
  MobileParamTitle,
  MobileInfoText,
} from './styles'

interface Props {
  items: Array<MobileBalanceItems>
}
export const MobileBalanceList: FC<Props> = ({ items }) => {
  const { t } = useTranslation()
  const isBalanceHistoryLoading = useAppSelector(getTransactionsLoading)
  const { getAmountValue } = useArtistBalancePage()

  if (!items || (items && items.length === 0)) {
    return (
      <MobileNoItemsTextWrapper>
        <MobileInfoText>{t('balancePage.youHaveNoRewardsYet')}</MobileInfoText>
      </MobileNoItemsTextWrapper>
    )
  }

  if (isBalanceHistoryLoading) {
    return (
      <MobileLoadingWrapper>
        <LoadingSpinner color={colors.green} size='large' />
      </MobileLoadingWrapper>
    )
  }
  return (
    <MobileItemsWrapper>
      {items.length === 0 && !isBalanceHistoryLoading && (
        <MobileInfoText>{t('balancePage.youHaveNoRewardsYet')}</MobileInfoText>
      )}
      {items.map((item) => (
        <MobileCard key={item.id}>
          <MobileCardHeaderWrapper>
            <MobileHeaderTitle>
              <TikTokIcon />
              {`${item.type} ${lowercaseFirstLetter(item.description.title)}`}
            </MobileHeaderTitle>
          </MobileCardHeaderWrapper>
          {item.amount.type === 'canceled' && (
            <MobileCardHeaderWrapper>
              <InfoText>Payout has been returned and canceled</InfoText>
            </MobileCardHeaderWrapper>
          )}

          <MobileCardInfoWrapper>
            <MobileCardFooterWrapper>
              <MobileParamItem>
                <MobileParamTitle>Amount</MobileParamTitle>
                <Amount green={item.amount.type === 'payment'}>
                  {getAmountValue(
                    item.amount.type as TransactionTypeEnum,
                    item.amount.amount,
                  )}
                </Amount>
              </MobileParamItem>
              <MobileParamItem>
                <MobileParamTitle>Date</MobileParamTitle>
                <HeaderTitle>{String(item.date)}</HeaderTitle>
              </MobileParamItem>
            </MobileCardFooterWrapper>
          </MobileCardInfoWrapper>
        </MobileCard>
      ))}
    </MobileItemsWrapper>
  )
}
