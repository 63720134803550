import styled from 'styled-components'

import { colors, variables } from 'src/Styled/variables'

export const DescriptionWrapper = styled.div`
  margin-bottom: 24px;
`
export const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 16px;
`
export const ListItem = styled.li`
  display: flex;
  gap: 16px;
  font-size: ${variables.fontSize14};
  line-height: 150%;
  font-weight: ${variables.fontWeight400};
`

export const NumberItem = styled.div`
  min-width: 24px;
  width: 24px;
  height: 24px;
  border: 1px solid ${colors.green};
  border-radius: 50%;
  font-size: ${variables.fontSize14};
  color: ${colors.green};
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`
