import { FC } from 'react'

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import DangerIcon from 'src/Assets/Svg/Danger.svg?react'
import { colors } from 'src/Styled/variables'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import { PaymentErrors } from 'src/Components/PaymentErrorsAlert'

const Text = styled.div`
  font-size: 14px;
  line-height: 21px;
  text-align: left;
  color: ${colors.messegesRed};
`

const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 8px;
`

export const PaymentError: FC = () => {
  const { t } = useTranslation()
  const { search } = useCustomSearchParams()
  const error = search.get('error')
  const errorType = search.get('errorType')

  if (!error && !errorType) {
    return null
  }

  const errorText =
    errorType === PaymentErrors.CHANGE_METHOD
      ? t('getPremiumPage.paymentProcessingError')
      : t('getPremiumPage.payPalError')

  return (
    <Container>
      <DangerIcon />
      <Text>{errorText}</Text>
    </Container>
  )
}
