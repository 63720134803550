import { FunctionComponent } from 'react'

import { Helmet } from 'react-helmet'

const LINK_HREF = 'https://app.soundcamps.com/create/'

export const CreateNewCampaignHelmet: FunctionComponent = () => (
  <Helmet>
    <meta charSet='utf-8' />
    <title>SoundCampaign - Create a new campaign</title>
    <link rel='canonical' href={LINK_HREF} />
  </Helmet>
)
