import React, { ReactElement } from 'react'

import { useNavigate } from 'react-router-dom'

import { sounds } from 'src/Router/routes'

import { SubmittedSoundDetails } from '../SubmittedSoundDetails'
import { CloseCrossButton, Wrapper } from '../../styles'

export const SubmittedSoundDetailsModal = (): ReactElement | null => {
  const navigate = useNavigate()

  const onClickCloseModal = (): void => {
    navigate(sounds)
  }

  return (
    <Wrapper>
      <SubmittedSoundDetails />
      <CloseCrossButton onClick={onClickCloseModal} />
    </Wrapper>
  )
}
