import styled from 'styled-components'

import { colors, variables } from 'src/Styled/variables'

export const PageContainer = styled.div`
  padding: 0;
  margin: 0 auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 16px;
`
export const Title = styled.h2`
  padding: 0;
  font-size: ${variables.fontSize20};
  line-height: 150%;
  font-weight: ${variables.fontWeight600};
  margin: 0 0 4px;
`
export const Container = styled.div<{ greenBoarder?: boolean }>`
  padding: 24px 25px 24px 24px;
  border-radius: 4px;
  background-color: ${colors.white};
  gap: 24px;
  display: flex;
  flex-direction: column;
  border-radius: ${({ greenBoarder }) => (greenBoarder ? '4px' : 'none')};
  border: ${({ greenBoarder }) =>
    greenBoarder ? `1px solid ${colors.green}` : 'none'};
`

export const SubTitle = styled.span`
  font-size: ${variables.fontSize18};
  line-height: 150%;
  font-weight: ${variables.fontWeight600};
`
export const LinkWrapper = styled.div`
  align-self: center;
  margin-top: 12px;
  margin-bottom: 9px;
`
export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
