import { createDraftSafeSelector } from '@reduxjs/toolkit'

import { RootState } from 'src/Redux'
import { SliceStatus } from 'src/Redux/types'
import { Roles } from 'src/Constants/enums'

import { CredentialsState } from '.'

const getState = (state: RootState): CredentialsState =>
  state.authentication.credentials

export const getIsAuth = createDraftSafeSelector(getState, ({ AccessToken }) =>
  Boolean(AccessToken),
)

export const getAccessToken = createDraftSafeSelector(
  getState,
  ({ AccessToken }) => AccessToken,
)

export const getLoading = createDraftSafeSelector(
  getState,
  ({ status }) => status === SliceStatus.Loading,
)

export const getUserRole = createDraftSafeSelector(getState, ({ role }) => role)
export const getIsArtist = createDraftSafeSelector(
  getState,
  ({ role }) => role === Roles.artist,
)
export const getIsCreator = createDraftSafeSelector(
  getState,
  ({ role }) => role === Roles.influencer,
)
export const getIsCurator = createDraftSafeSelector(
  getState,
  ({ role }) => role === Roles.curator,
)
