import styled from 'styled-components'

import { colors, device, variables } from 'src/Styled/variables'

export const Wrapper = styled.div`
  height: 100vh;
  width: 100%;
  min-height: 100vh;
  display: flex;
  overflow-y: auto;
  background-color: ${colors.black};
  position: absolute;
`
export const Container = styled.div`
  border-radius: 5px;
  margin: auto;
  padding: 10px;
  width: 90%;
`
export const Logo = styled.div`
  width: 250px;
  margin: 0 auto;
  padding: 20px;
`
export const Card = styled.div`
  margin: auto;
  background-color: ${colors.white};
  padding: 54px 72px;
  max-width: 600px;
  width: 100%;
  border-radius: 5px;
  @media ${device.mobileMax} {
    padding: 24px 16px;
  }
`
export const Header = styled.div`
  margin-bottom: 20px;
  text-align: center;

  h3 {
    color: ${colors.mainText} !important;
    font-weight: ${variables.fontWeight600};
    font-size: ${variables.fontSize24};
  }
`
export const Text = styled.div`
  display: flex;
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
  justify-content: space-around;

  a {
    color: ${colors.mainText} !important;
  }
`
