import { createDraftSafeSelector } from '@reduxjs/toolkit'

import { NewPlaylistToSet } from 'src/Types'

import { RootState } from '..'
import { SliceStatus } from '../types'

import { SearchPlaylistProcessState } from '.'

const getState = (state: RootState): SearchPlaylistProcessState =>
  state.searchPlaylist

const isMeetConditions = (playlist: NewPlaylistToSet): boolean => {
  const playlistRequirements = {
    min_followers_count: 1000,
    min_tracks_count: 20,
  }
  return (
    playlistRequirements.min_followers_count <= playlist.followers?.total &&
    playlistRequirements.min_tracks_count <= playlist.tracks?.total
  )
}

export const getSearchPlaylistData = createDraftSafeSelector(
  getState,
  ({ data }) => data,
)

export const getSearchPlaylistLoading = createDraftSafeSelector(
  getState,
  ({ status }) => status === SliceStatus.Loading,
)

export const getSearchPlaylistError = createDraftSafeSelector(
  getState,
  ({ data, error }) => {
    if (data && !isMeetConditions(data)) {
      return {
        code: 500,
        message: 'validation.notRequirementsPlaylist',
      }
    }
    return error
  },
)
