import React, { FC } from 'react'

import VisaLight from 'src/Assets/Svg/visa.svg?react'
import MastercardLight from 'src/Assets/Svg/masterCard.svg?react'
import DiscoverLight from 'src/Assets/Svg/discover.svg?react'
import JcbLight from 'src/Assets/Svg/jcb.svg?react'
import DinersLight from 'src/Assets/Svg/dinersClub.svg?react'
import ApplePay from 'src/Assets/Svg/applePay.svg?react'
import GooglePay from 'src/Assets/Svg/googlePay.svg?react'

import { CardsImgWrapper, Card } from './styles'
const cardIcons = [
  { id: 'visa', Component: VisaLight },
  { id: 'mastercard', Component: MastercardLight },
  { id: 'discover', Component: DiscoverLight },
  { id: 'jcb', Component: JcbLight },
  { id: 'diners', Component: DinersLight },
  { id: 'applepay', Component: ApplePay },
  { id: 'googlepay', Component: GooglePay },
]

export const StripeImages: FC = () => (
  <CardsImgWrapper>
    {cardIcons.map(({ id, Component }) => (
      <Card key={id}>
        <Component />
      </Card>
    ))}
  </CardsImgWrapper>
)
