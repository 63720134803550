import styled from 'styled-components'

import { colors, device } from 'src/Styled/variables'

export const TiktokSettingsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 16px;
  padding: 8px 0;
  max-width: 1120px;
`
export const ParamsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: ${colors.white};
  border-radius: 4px;
`
export const TiktokSettingsWrapper = styled.div`
  display: grid;
  gap: 16px;
  align-items: start;
  @media ${device.tabletMin} {
    grid-template-columns: 1fr 320px;
  }
`
