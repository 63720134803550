import styled from 'styled-components'

export const TopBarButtonDesktop = styled.button`
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  cursor: pointer;
  transition: 0.3s;
  color: #575757;

  &:focus {
    outline: none;
  }

  &:hover {
    background: rgba(77, 77, 77, 0.5);
  }

  .mdi-icon {
    height: 24px;
    width: 24px;
  }
`
