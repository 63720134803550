import React, { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'

import Button from 'src/Components/Buttons/Button'

import GenresSelectionGuide from '../GenresSelectionGuide'

import { ButtonWrapper, Modal } from './styles'

type GenresSelectionGuideModalProps = {
  toggle: (arg0: boolean) => void
  isOpen: boolean
}
const GenresSelectionGuideModal: FunctionComponent<
  GenresSelectionGuideModalProps
> = ({ toggle, isOpen }) => {
  const { t } = useTranslation()

  const onModalClose = (): void => {
    toggle(false)
  }

  return (
    <Modal open={isOpen} closable={false} footer={null} onCancel={onModalClose}>
      <GenresSelectionGuide />
      <ButtonWrapper>
        <Button type='white' onClick={onModalClose}>
          {t('scheduleCampaignPage.gotIt')}
        </Button>
      </ButtonWrapper>
    </Modal>
  )
}
export default GenresSelectionGuideModal
