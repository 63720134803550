import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { InfluencerRegisterEnum } from 'src/Types'
import Button from 'src/Components/Buttons/Button'
import { colors, variables } from 'src/Styled/variables'
import { useAppSelector } from 'src/Hooks/redux'
import { getApplicantUpdateEmailError } from 'src/Redux/creatorRegister-process/selectors'

import { UserDataInfo } from './UserDataInfo'

const Search = styled.div`
  width: 100%;
  margin-bottom: 24px;
`

const Text3 = styled.div`
  font-size: ${variables.fontSize14};
  color: ${colors.mainText};
  line-height: 21px;
  display: grid;
  gap: 16px;
  margin-bottom: 16px;
  @media (min-width: 576px) {
    margin-bottom: 16px;
  }
`
const Red = styled.div`
  font-weight: normal;
  font-size: ${variables.fontSize14};
  line-height: 21px;
  color: ${colors.messegesRed};
  margin-bottom: 16px;
  display: flex;
  align-self: flex-start;
  height: 42px;
`
const ButtonContainer = styled.div`
  width: 220px;
`

interface LoginCreatorConnectedProps {
  status: InfluencerRegisterEnum
  setRegisterState: (state: InfluencerRegisterEnum) => void
}

const translateInfluencer =
  'influencer.checkIfYouSelectedCorrectTikTokAccountAndPressContinue'
export const LoginCreatorConnected: FC<LoginCreatorConnectedProps> = ({
  status,
  setRegisterState,
}) => {
  const authError = useAppSelector(getApplicantUpdateEmailError)
  const { t } = useTranslation()

  if (status !== InfluencerRegisterEnum.CONNECTED) {
    return null
  }
  return (
    <>
      {authError && <Red>{t('influencer.authorizationFailed')}</Red>}
      {!authError && <Text3>{t(translateInfluencer)}</Text3>}
      <Search>
        <UserDataInfo />
      </Search>
      <ButtonContainer>
        <Button
          type='green'
          onClick={() => setRegisterState(InfluencerRegisterEnum.FORM)}
        >
          {t('influencer.continue')}
        </Button>
      </ButtonContainer>
    </>
  )
}
