import { FC } from 'react'

import { useTranslation } from 'react-i18next'

import { useAppSelector } from 'src/Hooks/redux'
import { getCampaignToSchedule } from 'src/Redux/campaign-process/spotifyCampaignSchedule-process/selectors'
import { ANY_LANGUAGE_ID } from 'src/Constants/constants'

import { Title } from '../styles'

import { ScheduleLanguages } from './ScheduleLanguages'
import { ScheduleGenres } from './ScheduleGenres'

import { ChooseTrackParametersContainer } from './styles'

export const TrackParameters: FC = () => {
  const { t } = useTranslation()
  const campaignDetails = useAppSelector(getCampaignToSchedule)

  const isGenresActive = !campaignDetails.genres.length

  const hasSelectedLanguages = Boolean(
    campaignDetails.languages.filter((lang) => lang.id !== ANY_LANGUAGE_ID)
      .length,
  )

  return (
    <ChooseTrackParametersContainer>
      <Title>{t('scheduleCampaignPage.chooseTrackParameters')}</Title>
      <ScheduleGenres
        hasSelectedLanguages={hasSelectedLanguages}
        genres={campaignDetails.genres}
      />
      <ScheduleLanguages
        isAddButtonDisabled={isGenresActive}
        languages={campaignDetails.languages}
      />
    </ChooseTrackParametersContainer>
  )
}
