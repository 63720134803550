import { FC } from 'react'

import { ModalProps } from 'antd'

import { StyledModal } from './styles'

export type Props = ModalProps & {
  showTopbar?: boolean
  showCloseButton?: boolean
}

export const FullScreenModal: FC<Props> = (props) => {
  const { children, showCloseButton, showTopbar, ...restProps } = props
  return (
    <StyledModal
      $showTopbar={showTopbar}
      $showCloseButton={showCloseButton}
      {...restProps}
    >
      {children}
    </StyledModal>
  )
}
