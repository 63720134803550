import styled from 'styled-components'

import { colors, variables } from 'src/Styled/variables'
import { CopyButton } from 'src/Containers/ui/CopyButton/CopyButton'

export const StyledCopyButton = styled(CopyButton)`
  height: 40px;
  @media (min-width: 1160px) {
    width: fit-content;
    width: calc(100% / 3 - 32px);
  }
  font-family: 'Segoe UI';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`

export const ReferralLink = styled.a`
  display: flex;
  height: 48px;
  width: 100%;
  padding: 9.5px 40px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 27px;
  border: 1px solid ${colors.greyStroke};
  background: ${colors.white};
  color: ${colors.mainText};
  font-size: ${variables.fontSize14};
  line-height: 150%;
  white-space: nowrap;
  &:hover {
    color: ${colors.mainText};
  }
  &:focus {
    color: ${colors.mainText};
  }
  @media (min-width: 1160px) {
    width: calc(100% / 3 - 32px);
    height: 40px;
  }
`

export const InviteBlockContainer = styled.div`
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  margin-bottom: 16px;
  width: 100%;
  border-radius: 16px;
  border: 1px solid ${(props) => props.theme.common.primaryBorder};
  background-color: ${(props) => props.theme.common.primaryBg};
  box-shadow: 0px 0px 32px 0px rgba(51, 51, 51, 0.08);
`
export const Description = styled.div`
  font-family: 'Segoe UI';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  color: ${colors.mainText};
  margin-bottom: 16px;
`
export const InviteBlockTitle = styled.div`
  color: ${colors.mainText};
  font-size: ${variables.fontSize18};
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
`
export const ButtonsBlock = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  width: 100%;

  @media (min-width: 1160px) {
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }
`
