import { TikTokTrack, Track } from 'src/Types'
import { getTimeByDuration } from 'src/Helpers/layoutHelpers'

import { SearchedTrack } from './Components/TrackSearch'

export const mapSpotifyTrack = (track: TikTokTrack | Track): SearchedTrack => {
  const typedTrack = track as Track
  return {
    id: String(typedTrack.id),
    name: typedTrack.name,
    artistName: typedTrack?.artists[0]?.name,
    trackCoverUrl: typedTrack.album.images[0]?.url,
    previewUrl: typedTrack.preview_url,
    trackDuration: getTimeByDuration(typedTrack.duration_ms / 1000),
  }
}

export const mapTikTokTrack = (track: TikTokTrack | Track): SearchedTrack => {
  const typedTrack = track as TikTokTrack
  return {
    id: typedTrack.trackTiktokId,
    name: typedTrack.trackTitle,
    artistName: typedTrack.trackAuthorName,
    trackCoverUrl: typedTrack.trackCoverUrl,
    previewUrl: typedTrack.trackPlayUrl,
    trackDuration: getTimeByDuration(typedTrack.trackDuration),
  }
}
