import TagManager from 'react-gtm-module'

import { ID } from 'src/Types'

import {
  EventNameEnum,
  EventTypeEnum,
  ReferralShareButtonTypeEnum,
} from '../tagManagerEnums'

export const copyInviteEvent = ({
  userId,
  eventName,
}: {
  userId?: ID | null
  eventName: 'link' | 'message'
}): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventTypeEnum.GA_EVENT,
      event_params: {
        event_name:
          eventName === 'link'
            ? EventNameEnum.CopyInviteLink
            : EventNameEnum.CopyInvitationMessage,
      },
      user_properties: {
        ...(userId ? { userID: userId } : {}),
      },
    },
  })
}

export const getReferralShareEvent = ({
  userId,
  buttonType,
}: {
  userId?: ID | null
  buttonType?: ReferralShareButtonTypeEnum
}): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventTypeEnum.GA_EVENT,

      event_params: {
        event_name: EventNameEnum.ReferralShare,
        button_type: buttonType,
      },
      user_properties: {
        ...(userId ? { user_id: userId } : {}),
      },
    },
  })
}
