import React, { FC } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { colors, variables } from 'src/Styled/variables'
import {
  InfluencerEventStatus,
  InfluencerCampaignStatus,
} from 'src/Types/index'

interface CanceledMessageProps {
  status: InfluencerCampaignStatus | InfluencerEventStatus
}

const Text = styled.p`
  font-weight: ${variables.fontWeight600};
  color: ${colors.messegesYellow1};
  text-align: center;
`

const STOPPED_STASUSES = [
  InfluencerCampaignStatus.CANCELED,
  InfluencerEventStatus.CAMPAIGN_STOPED,
]

export const CanceledMessage: FC<CanceledMessageProps> = ({ status }) => {
  const { t } = useTranslation()
  if (STOPPED_STASUSES.includes(status)) {
    return <Text>{t('influencer.canceledMessage')}</Text>
  }
  return null
}
