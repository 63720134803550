import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

import { PrimaryButton } from 'src/Components/Buttons/PrimaryButton'

const FooterContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 24px;
  position: fixed;
  bottom: 0;
  background-color: ${({ theme }) => theme.common.primaryBg};
  padding: 8px 16px;
  border-top: 1px solid ${({ theme }) => theme.common.primaryBorder};
  div:last-child {
    width: fit-content;
    .ant-btn.ant-btn-default {
      padding: 0 40px;
    }
  }
`
const TextWrapper = styled.div`
  div:first-child {
    font-size: 10px;
    color: ${({ theme }) => theme.common.primaryText};
    white-space: nowrap;
    line-height: 150%;
  }
  div:last-child {
    font-size: 14px;
    color: ${({ theme }) => theme.common.primaryText};
    white-space: nowrap;
  }
`

interface Props {
  isDisabled: boolean
  onClick: () => void
}

const StyledPrimaryButton = styled(PrimaryButton)`
  padding-left: 40px;
  padding-right: 40px;
  border: 1px solid ${({ theme }) => theme.button.primaryText};
`

export const GenresModalSpotifyButton: FC<Props> = ({
  isDisabled,
  onClick,
}) => {
  const { t } = useTranslation()

  const { pathname } = useLocation()
  const isSpotifyPlatform = pathname.split('/').includes('schedule')

  const bestResultGenresText = isSpotifyPlatform
    ? t('scheduleCampaignPage.upTo5Genres')
    : t('scheduleCampaignPage.minGenres')

  return (
    <FooterContainer translate='no'>
      <TextWrapper>
        <div>{t('scheduleCampaignPage.forBestResultChoose')}</div>
        <div>{bestResultGenresText}</div>
      </TextWrapper>
      <StyledPrimaryButton disabled={isDisabled} onClick={onClick}>
        {t('scheduleCampaignPage.addGenres')}
      </StyledPrimaryButton>
    </FooterContainer>
  )
}
