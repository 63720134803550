import { APP } from 'src/Configs/App'
import { rtkApi } from 'src/Services/rtkApi'
import { CampaignTypeEnum, TiktokCampaignStatus } from 'src/Types'

interface CampaignTiktokDetailsGenres {
  id: number
  name: string
}

interface CampaignTiktokDetailsStats {
  totalVideos: number
  totalLikes: number
  totalShares: number
  totalViews: number
  totalComments: number
}

interface CampaignTiktokDetailsTrack {
  authorName: string
  coverUrl: string
  playUrl: string
  title: string
  url: string
  duration: number
}

export interface CampaignTiktokDetails {
  amount: number
  comment: null | string
  createdAt: string
  currentAmount: number
  endDate: string
  expectedMaxVideos: number
  expectedMinVideos: number
  genres: CampaignTiktokDetailsGenres[]
  id: number
  languages: unknown[]
  status: TiktokCampaignStatus
  startDate: string
  statsUpdatedAt: string
  stats: CampaignTiktokDetailsStats
  track: CampaignTiktokDetailsTrack
  totalRefunded: number
  type: CampaignTypeEnum
  updatedAt: string
  userId: number
  reserved: number
  spent: number
  pricingModelVersion: number
  hasNotCompletedEvents: boolean
}

const campaignTiktokDetailsApi = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    getCampaignTiktokDetails: builder.query<
      CampaignTiktokDetails,
      string | undefined
    >({
      query: (tiktokCampaignId) =>
        `${APP.TIKTOK_SERVER}/artist/tiktok/campaigns/${tiktokCampaignId}`,
    }),
  }),
  overrideExisting: false,
})

export const { useGetCampaignTiktokDetailsQuery } = campaignTiktokDetailsApi
