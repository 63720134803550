import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import track from 'src/Assets/Png/track.png'
import { useAppSelector } from 'src/Hooks/redux'
import { getUserEmail } from 'src/Redux/auth-process/userSlice/selectors'
import { colors, variables } from 'src/Styled/variables'
import { SmartImage } from 'src/Components/SmartImage'

const PlayListCard = styled.div`
  display: flex;
  align-items: center;
  flex: 1 1;
  padding: 24px 16px;
  background-color: ${colors.white};
  border-radius: 4px;
`
const PlayListUrl = styled(SmartImage)`
  width: 40px;
  height: 40px;
  border-radius: 4px;
  margin-right: 16px;
  margin-bottom: 6px;
`
const PlayListName = styled.h4`
  font-weight: normal;
  font-size: ${variables.fontSize14};
  line-height: 25px !important;
  margin-top: -7px;
`
const PlayListOwner = styled.h5`
  font-weight: normal;
  font-size: ${variables.fontSize10};
`
interface PlayListInfoProps {
  img?: string
  name?: string
}
export const PlayListInfo: FC<PlayListInfoProps> = ({ name, img }) => {
  const { t } = useTranslation()
  const userEmail = useAppSelector(getUserEmail)
  return (
    <PlayListCard>
      <PlayListUrl alt='Playlist cover' src={img} fallbackSrc={track} />
      <span>
        <PlayListName>{name}</PlayListName>
        <PlayListOwner>
          {t('scheduleCampaignPage.by')}&nbsp;
          {userEmail}
        </PlayListOwner>
      </span>
    </PlayListCard>
  )
}
