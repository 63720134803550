import styled from 'styled-components'

import { variables, colors } from 'src/Styled/variables'

export const TrackDetailsTitle = styled.div`
  margin-top: 33.5px;
  font-weight: ${variables.fontWeight400};
  font-size: ${variables.fontSize10};
  line-height: 15px;
  color: ${colors.mainText};
  text-align: left;
`

export const SubTitle = styled.div`
  margin-top: 16px;
  font-weight: ${variables.fontWeight400};
  font-size: ${variables.fontSize10};
  line-height: 15px;
  color: ${colors.mainText};
  text-align: left;
`

export const InfoItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  line-height: 21px;
`

export const InfoItemWrapperTitle = styled.div`
  color: ${colors.greyTextWhiteBg};
  margin-right: 8px;
  min-width: 60px;
`
