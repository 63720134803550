import { FC } from 'react'

import { useTranslation } from 'react-i18next'

import { PlanItem } from '../PlanItem'
import { useSubscriptionPlan } from '../../useSubscriptionPlan'
import { Container, Title } from '../../styles'

import { List, TitleWrapper } from './styles'

export const PlansList: FC = () => {
  const { t } = useTranslation()

  const { plans } = useSubscriptionPlan()

  return (
    <Container>
      <TitleWrapper>
        <Title>{t('getPremiumPage.pageTitle')}</Title>
      </TitleWrapper>

      <List>
        {plans.map((plan, index) => (
          <PlanItem isBig={index === 1} plan={plan} key={plan.name} />
        ))}
      </List>
    </Container>
  )
}
