import { FC, useEffect } from 'react'

import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { setIsAlertMode } from 'src/Redux/sidebar-process'
import { TracksLevelsAlert } from 'src/Components/TracksLevelsAlert'
import { useAppDispatch } from 'src/Hooks/redux'
import {
  influencerBoard,
  levels,
  monetization,
  root,
  sounds,
  tracks,
} from 'src/Router/routes'
import { getUserRole } from 'src/Redux/auth-process/credentialsSlice/selectors'
import { Roles } from 'src/Constants/enums'
import { TracksAlert } from 'src/Components/TracksAlert'
import { SoundMonetizationAlert } from 'src/Components/SoundMonetizationAlert'

const layoutAlerts = [
  {
    component: <TracksLevelsAlert />,
    pages: [levels, tracks, sounds],
    rootPageRoles: [Roles.curator],
  },
  {
    component: <TracksAlert />,
    pages: [influencerBoard],
    rootPageRoles: [Roles.influencer],
  },
  {
    component: <SoundMonetizationAlert />,
    pages: [monetization, sounds],
    rootPageRoles: [Roles.influencer],
  },
]

export const TopBarAlert: FC = () => {
  const { pathname } = useLocation()
  const dispatch = useAppDispatch()
  const role = useSelector(getUserRole)

  const firstPathName = `/${pathname.split('/')[1]}`

  const showingAlert = layoutAlerts.find((alert) => {
    const isRootPage = firstPathName === root

    const isMatchedByPathnameAndRole =
      role &&
      alert.pages.includes(firstPathName) &&
      alert.rootPageRoles.includes(role)

    const isMatchedByRootAndRole =
      role && alert.rootPageRoles.includes(role) && isRootPage

    if (isMatchedByRootAndRole || isMatchedByPathnameAndRole) {
      return alert.component
    }
    return null
  })

  useEffect(() => {
    dispatch(setIsAlertMode(Boolean(showingAlert)))
  }, [showingAlert, dispatch])

  if (!showingAlert?.component) {
    return null
  }

  return <div>{showingAlert.component}</div>
}
