import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import IconSuccessfully from 'src/Assets/Svg/successfully.svg?react'
import { CampaignPlatformTypeEnum } from 'src/Types'
import Button from 'src/Components/Buttons/Button'
import { createdFirst, feedback } from 'src/Router/routes'

import {
  ButtonWrapper,
  SuccessBlock,
  SuccessButtonBlock,
  SuccessCircle,
  SuccessText,
  SuccessTitle,
  Wrapper,
} from './styles'

const SuccessCreatedFirstCampaign: FC = () => {
  const navigate = useNavigate()
  const { campaignType } = useParams<{
    campaignType: CampaignPlatformTypeEnum
  }>()
  const { t } = useTranslation()

  const firstParagraph = t(
    'createCampaignPage.ourA&RTeamWillReviewYourCampaignStayTuned',
  )
  const secondParagraph = t('createCampaignPage.weWillNotify')

  const handleClick = (): void => {
    if (campaignType) {
      navigate(`${createdFirst}/${campaignType}${feedback}`)
    }
  }

  return (
    <Wrapper>
      <SuccessBlock>
        <SuccessCircle>
          <IconSuccessfully />
        </SuccessCircle>
        <SuccessTitle>
          {t('createCampaignPage.youCreatedYourFirstCampaign!')}
        </SuccessTitle>

        <SuccessText>{firstParagraph}</SuccessText>
        <SuccessText>{secondParagraph}</SuccessText>

        <SuccessButtonBlock>
          <ButtonWrapper>
            <Button type='white' onClick={handleClick}>
              {t('createCampaignPage.continue')}
            </Button>
          </ButtonWrapper>
        </SuccessButtonBlock>
      </SuccessBlock>
    </Wrapper>
  )
}
export default SuccessCreatedFirstCampaign
