import React, { FC } from 'react'

import styled from 'styled-components'

import { SidebarMenuItemProps } from '.'

const Content = styled.div<{ $collapsed: boolean }>`
  display: flex;
  align-items: center;
  gap: ${({ $collapsed }) => ($collapsed ? '0px' : '16px')};
`

const Text = styled.div<{ $collapsed?: boolean }>`
  flex: 1;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  white-space: nowrap;
  width: ${({ $collapsed }) => ($collapsed ? '0px' : '160px')};
  overflow: hidden;
  opacity: ${({ $collapsed }) => ($collapsed ? '0' : '1')};
  transition: width 1.5s linear;
  transition: opacity 1s ease-in-out;
`
type SidebarMenuItemContentProps = Pick<
  SidebarMenuItemProps,
  'children' | 'collapsed' | 'icon'
>
export const SidebarMenuItemContent: FC<SidebarMenuItemContentProps> = (
  props,
) => {
  const { collapsed, children, icon: Icon } = props
  return (
    <Content $collapsed={collapsed}>
      <Icon />
      <Text $collapsed={collapsed}>{children}</Text>
    </Content>
  )
}
