import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'

import { api } from 'src/Services/api'
import { APP } from 'src/Configs/App'
import { CampaignPlatformTypeEnum, GetGtmInfoInterface } from 'src/Types'

import { NameSpace } from '../types'

interface PlatformEvent {
  campaignID: number
  platform: CampaignPlatformTypeEnum
}

interface PaymentEvent {
  campaignId: number | string
  transaction: { price: number; start_date: string | Date | undefined }
  url: string
}

interface PaymentPlatformEvent {
  campaignId: number | string
  transaction: { price: number; start_date: string | null }
  url: string
  platform: string
}

interface PaymentInfoEvent {
  campaignID: number | string
  url: string
}

interface PaymentInfoPlatformEvent {
  campaignID: number | string
  platform: string
  url: string
}

interface CreateTiktokCampaignEvent {
  campaignID: number | string
  url: string
  token?: string
}

interface GoogleAnalyticsResponse {
  errors: string[]
}

export const sendScheduleStartEvent = createAsyncThunk<void, number>(
  `${NameSpace.GoogleAnalytics}/sendScheduleStartEvent`,
  async (campaignID, { rejectWithValue }) => {
    try {
      const { data } = await api.post<void>(
        `${APP.SERVER}/campaign/schedule-start-event/${campaignID}`,
      )
      return data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      return rejectWithValue({
        message: response?.data.message,
        status: response?.status,
      })
    }
  },
)

export const sendScheduleStartPlatformEvent = createAsyncThunk<
  GoogleAnalyticsResponse,
  PlatformEvent
>(
  `${NameSpace.GoogleAnalytics}/sendScheduleStartPlatformEvent`,
  async ({ campaignID, platform }, { rejectWithValue }) => {
    try {
      const { data } = await api.post<GoogleAnalyticsResponse>(
        `${APP.SERVER}/campaign/schedule-start-event/${platform}/${campaignID}`,
      )
      return data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      return rejectWithValue({
        message: response?.data.message,
        status: response?.status,
      })
    }
  },
)

export const sendPaymentStartEvent = createAsyncThunk<void, PaymentEvent>(
  `${NameSpace.GoogleAnalytics}/sendPaymentStartEvent`,
  async ({ campaignId, transaction, url }, { rejectWithValue }) => {
    try {
      const { data } = await api.post<void>(
        `${APP.SERVER}/campaign/payment-start-event/${campaignId}`,
        {
          transaction,
          url,
        },
      )
      return data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      return rejectWithValue({
        message: response?.data.message,
        status: response?.status,
      })
    }
  },
)

export const sendPaymentStartPlatformEvent = createAsyncThunk<
  GoogleAnalyticsResponse,
  PaymentPlatformEvent
>(
  `${NameSpace.GoogleAnalytics}/sendPaymentStartPlatformEvent`,
  async ({ campaignId, platform, transaction, url }, { rejectWithValue }) => {
    try {
      const { data } = await api.post<GoogleAnalyticsResponse>(
        `${APP.SERVER}/campaign/payment-start-event/${platform}/${campaignId}`,
        {
          transaction,
          url,
        },
      )
      return data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      return rejectWithValue({
        message: response?.data.message,
        status: response?.status,
      })
    }
  },
)

export const sendPaymentCompletedPlatformEvent = createAsyncThunk<
  GoogleAnalyticsResponse,
  PaymentPlatformEvent
>(
  `${NameSpace.GoogleAnalytics}/sendPaymentCompletedPlatformEvent`,
  async ({ campaignId, platform, transaction, url }, { rejectWithValue }) => {
    try {
      const { data } = await api.post<GoogleAnalyticsResponse>(
        `${APP.SERVER}/campaign/payment-completed-event/${platform}/${campaignId}`,
        {
          transaction,
          url,
        },
      )
      return data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      return rejectWithValue({
        message: response?.data.message,
        status: response?.status,
      })
    }
  },
)

export const sendPaymentInfoEvent = createAsyncThunk<void, PaymentInfoEvent>(
  `${NameSpace.GoogleAnalytics}/sendPaymentInfoEvent`,
  async ({ campaignID, url }, { rejectWithValue }) => {
    try {
      const { data } = await api.post<void>(
        `${APP.SERVER}/campaign/payment-info/${campaignID}`,
        { url },
      )
      return data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      return rejectWithValue({
        message: response?.data.message,
        status: response?.status,
      })
    }
  },
)

export const sendPaymentInfoPlatformEvent = createAsyncThunk<
  GoogleAnalyticsResponse,
  PaymentInfoPlatformEvent
>(
  `${NameSpace.GoogleAnalytics}/sendPaymentInfoPlatformEvent`,
  async ({ campaignID, platform, url }, { rejectWithValue }) => {
    try {
      const { data } = await api.post<GoogleAnalyticsResponse>(
        `${APP.SERVER}/campaign/payment-info/${platform}/${campaignID}`,
        { url },
      )
      return data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      return rejectWithValue({
        message: response?.data.message,
        status: response?.status,
      })
    }
  },
)

export const createTiktokCampaignEvent = createAsyncThunk<
  GoogleAnalyticsResponse,
  CreateTiktokCampaignEvent
>(
  `${NameSpace.GoogleAnalytics}/createTiktokCampaignEvent`,
  async ({ campaignID, url, token }, { rejectWithValue }) => {
    try {
      const { data } = await api.post<GoogleAnalyticsResponse>(
        `${APP.SERVER}/campaign/create-tiktok-campaign-event/${campaignID}`,
        { url },
        {
          headers: token ? { Authorization: `Bearer ${token}` } : {},
        },
      )
      return data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      return rejectWithValue({
        message: response?.data.message,
        status: response?.status,
      })
    }
  },
)

export const getGtmPaymentPlatformsInfo = createAsyncThunk<
  GetGtmInfoInterface,
  {
    platform: string
    campaignId: number | string
  }
>(
  `${NameSpace.GoogleAnalytics}/getGtmPaymentPlatformsInfo`,
  async ({ platform, campaignId }, { rejectWithValue }) => {
    try {
      const { data } = await api.get<GetGtmInfoInterface>(
        `${APP.TIKTOK_SERVER}/analytic/payment-gtm/${platform}/${campaignId}`,
      )
      return data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      return rejectWithValue({
        message: response?.data.message,
        status: response?.status,
      })
    }
  },
)
