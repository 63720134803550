import styled from 'styled-components'

import { colors, device, variables } from 'src/Styled/variables'

export const MainWrapper = styled.div`
  background-color: ${colors.white};
  min-height: 100vh;

  @media ${device.mobileMin} {
    background-color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`

export const Header = styled.div`
  background-color: black;
  padding: 12px 16px;
  @media ${device.mobileMin} {
    padding: 0;
  }
`

export const Logo = styled.div`
  width: 149px;

  @media ${device.mobileMin} {
    width: 156px;
    margin-bottom: 16px;
  }
`

export const LoginFieldsWrapper = styled.div`
  background-color: ${colors.white};
  padding: 49px 16px 16px 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media ${device.mobileMin} {
    width: 400px;
    border-radius: 8px;
    padding: 16.5px 24px 24px 24px;
  }
`
interface Props {
  isConnecting?: boolean
}
export const TikTokBtnLink = styled.a<Props>`
  display: inline-flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  outline: none;
  box-sizing: border-box;
  border: ${(props: Props) =>
    props.isConnecting
      ? `1px solid ${colors.greyStroke}`
      : `1px solid ${colors.greyTextBlackBg}`};
  border-radius: 27px;
  height: 40px;
  padding: 9.5px 24px 9.5px 24px;
  color: ${(props: Props) =>
    props.isConnecting ? colors.greyTextBlackBg : colors.mainText};
  cursor: pointer;
  font-size: ${variables.fontSize14};
  background: ${colors.white};
  width: auto;
  justify-content: center;

  &:hover {
    background: ${colors.white};
    color: ${(props: Props) =>
      props.isConnecting ? colors.greyTextBlackBg : colors.mainText};
  }
  &:active {
    color: ${(props: Props) =>
      props.isConnecting ? colors.greyTextBlackBg : colors.mainText};
  }
  &:visited {
    background: ${colors.white};
    color: ${(props: Props) =>
      props.isConnecting ? colors.greyTextBlackBg : colors.mainText};
  }
`
