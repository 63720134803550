import { createDraftSafeSelector } from '@reduxjs/toolkit'

import { SliceStatus } from 'src/Redux/types'
import { RootState } from 'src/Redux'

import { CuratorBalanceHistoryState } from '.'

const getState = (state: RootState): CuratorBalanceHistoryState =>
  state.transactions.curatorBalanceList

export const getCuratorTransactions = createDraftSafeSelector(
  getState,
  ({ transactions }) => transactions,
)
export const getCuratorTransactionsMeta = createDraftSafeSelector(
  getState,
  ({ meta }) => meta,
)
export const getIsLoadingCuratorTransactions = createDraftSafeSelector(
  getState,
  ({ status }) => status === SliceStatus.Loading,
)
