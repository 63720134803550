import { FC } from 'react'

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import FilterIcon from 'src/Assets/Svg/pages-icons/Filter.svg?react'
import { MAX_MOBILE_WIDTH } from 'src/Styled/variables'

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

const Text = styled.span`
  overflow: hidden;
  color: ${({ theme }) => theme.common.primaryText};
  text-overflow: ellipsis;
  font-family: 'Segoe UI';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  display: none;
  @media screen and (min-width: ${MAX_MOBILE_WIDTH}) {
    display: block;
  }
`
const Count = styled.div`
  display: flex;
  width: 16px;
  height: 16px;
  flex-direction: column;
  justify-content: flex-end;
  flex-shrink: 0;
  color: ${({ theme }) => theme.common.primaryText};
  text-align: center;

  font-family: 'Segoe UI';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  border-radius: 24px;
  background-color: ${({ theme }) => theme.button.secondaryBg};
  @media screen and (max-width: ${MAX_MOBILE_WIDTH}) {
    position: absolute;
    top: 0px;
    right: 0px;
  }
`

const Container = styled.button`
  position: relative;
  display: inline-flex;
  height: 48px;
  padding: 0px 16px;
  gap: 8px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 40px;
  background-color: ${({ theme }) => theme.common.primaryBg};
  border: 1px solid transparent;
  cursor: pointer;
  @media screen and (max-width: ${MAX_MOBILE_WIDTH}) {
    gap: 0px;
  }
  svg {
    fill: ${({ theme }) => theme.common.primaryText};
  }
  &:hover:enabled {
    border-radius: 40px;
    border: 1px solid ${({ theme }) => theme.common.primaryBorder};
    background-color: ${({ theme }) => theme.common.primaryBg};
    box-shadow: 0px 0px 12px 0px rgba(51, 51, 51, 0.12);
  }
  &:focus-visible {
    border-radius: 40px;
    border: 1px solid ${({ theme }) => theme.common.secondaryBorder};
    outline: none;
    background-color: ${({ theme }) => theme.common.primaryBg};
  }
  &[disabled] {
    background-color: ${({ theme }) => theme.common.primaryBg};
    color: ${({ theme }) => theme.common.thirdText};
    cursor: not-allowed;
    svg {
      fill: ${({ theme }) => theme.common.thirdText};
    }
    ${Text} {
      color: ${({ theme }) => theme.common.thirdText};
    }
    ${Count} {
      color: ${({ theme }) => theme.common.thirdText};
    }
  }
  &.ant-popover-open {
    border-color: ${({ theme }) => theme.common.primaryBorder};

    ${Count} {
      background-color: ${({ theme }) => theme.common.primaryBg};
    }
    &:hover {
      border: 1px solid transparent;
      box-shadow: none;
      border: 1px solid ${({ theme }) => theme.common.primaryBorder};
    }
  }
`

interface FilterButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  count?: number
}

export const FilterButton: FC<FilterButtonProps> = ({
  count,
  ...restProps
}) => {
  const { t } = useTranslation()
  return (
    <Container {...restProps}>
      <FilterIcon />
      <TextContainer>
        <Text>{t('campaignsListPage.filter')}</Text>
        {Boolean(count) && <Count>{count}</Count>}
      </TextContainer>
    </Container>
  )
}
