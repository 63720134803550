import React, { FC } from 'react'

import { useTranslation } from 'react-i18next'

import { CampaignStatusEnum } from 'src/Types'
import { StatsBlock } from 'src/Components/StatsSectionBlock'

interface BudgetSectionProps {
  campaignStatus: CampaignStatusEnum
  budget?: string
  spent?: string
}

export const BudgetSection: FC<BudgetSectionProps> = ({
  campaignStatus,
  budget = '-',
  spent = '-',
}) => {
  const { t } = useTranslation()

  const budgetSectionVisible = ![
    CampaignStatusEnum.PENDING_APPROVAL,
    CampaignStatusEnum.APPROVED,
  ].includes(campaignStatus)

  if (!budgetSectionVisible) {
    return null
  }

  const sections = [
    {
      title: t('campaignDetailsPage.budget'),
      value: `$${budget}`,
    },
    {
      title: t('campaignDetailsPage.spent'),
      value: `$${spent}`,
    },
  ]

  return <StatsBlock sections={sections} />
}
