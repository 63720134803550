import React, { FC } from 'react'

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import Alert from 'src/Components/Alert'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import { variables } from 'src/Styled/variables'

export const AlertSubTitle = styled.div``
export const AlertTitle = styled.div`
  font-weight: ${variables.fontWeight600};
  margin-bottom: 5px;
`
export const AlertWrapper = styled.div`
  margin-bottom: -24px;
`

export const CampaignCanceledAlert: FC = () => {
  const { search } = useCustomSearchParams()
  const { t } = useTranslation()

  const artistName = search.get('artistName') || ''
  const trackName = search.get('trackName') || ''

  return (
    <AlertWrapper>
      <Alert color={'warning'} icon>
        <AlertTitle>
          {t('soundsPage.campaignCanceled', { trackName })}
        </AlertTitle>
        <AlertSubTitle>
          {t('soundsPage.cancelAlertDescription', { artistName })}
        </AlertSubTitle>
      </Alert>
    </AlertWrapper>
  )
}
