import styled from 'styled-components'

import { variables, colors, device } from 'src/Styled/variables'

export const PageContainer = styled.div`
  box-sizing: content-box;
  padding: 0;
  margin: 0 auto;
`

// Table styled

export const HeaderTitle = styled.span`
  font-weight: ${variables.fontWeight400};
  font-size: ${variables.fontSize14};
  line-height: 150%;
  color: ${colors.mainText};
`

export const Amount = styled.span<{ green?: boolean }>`
  font-weight: ${variables.fontWeight400};
  font-size: ${variables.fontSize14};
  line-height: 150%;
  color: ${({ green }) => (green ? colors.green : colors.mainText)};
`

export const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 24px;

  @media ${device.mobileMax} {
    position: fixed;
    bottom: 0;
    left: 0;
    background: ${colors.white};
    box-shadow: 0 6px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: row-reverse;
    padding: 12px 16px;
    z-index: 100;
    justify-content: space-between;
    align-items: center;
  }
`

export const Container = styled.div`
  box-sizing: border-box;
  width: 100%;

  @media screen and (max-width: 576px) {
    padding-left: 0;
    padding-right: 0;
  }
`
