import styled from 'styled-components'

import { colors, device } from 'src/Styled/variables'

export const ReferAndEarnContainer = styled.div`
  box-sizing: content-box;
  padding: 0;
  margin: 0 auto 70px;
  @media ${device.mobileMin} {
    margin: 0 auto;
  }
`

export const Container = styled.div`
  box-sizing: border-box;
  width: 100%;

  @media screen and (max-width: 576px) {
    padding-left: 0;
    padding-right: 0;
  }
`

export const Wrapper = styled.div`
  display: grid;
  gap: 16px;

  @media ${device.mobileMin} {
    grid-auto-flow: column;
  }
`

export const BackButton = styled.img`
  cursor: pointer;
  margin-right: 20px;
  display: flex;
  align-self: center;

  @media ${device.mobileMin} {
    display: none;
  }
`

export const ButtonWrapper = styled.div`
  width: 237px;
  margin: 16px auto 0;
`

export const ButtonsContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 16px;
  background: ${colors.white};
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  @media ${device.mobileMin} {
    display: none;
  }
`
