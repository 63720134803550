import { createSlice } from '@reduxjs/toolkit'

import { ApiError, NameSpace, SliceStatus } from 'src/Redux/types'
import { User } from 'src/Types'

import { searchUsers } from './api-actions'

export interface SearchUsersState {
  foundUsers: Array<User>

  status: SliceStatus
  error: ApiError | null
}

const initialState: SearchUsersState = {
  foundUsers: [],

  status: SliceStatus.Idle,
  error: null,
}

export const searchUsersSlice = createSlice({
  initialState,
  name: NameSpace.SearchUsers,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(searchUsers.pending, (state) => {
      state.status = SliceStatus.Loading
    })
    builder.addCase(searchUsers.fulfilled, (state, { payload }) => {
      state.status = SliceStatus.Succeeded
      state.foundUsers = payload.users
      state.error = null
    })
    builder.addCase(searchUsers.rejected, (state, { payload }) => {
      state.status = SliceStatus.Failed
      state.error = payload as ApiError
    })
  },
})
