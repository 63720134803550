import { FC } from 'react'

import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import { useGenresAndLanguagesAnalytics } from 'src/Hooks/useGenresAndLanguagesAnalytics'
import { changeCampaign } from 'src/Redux/campaign-process/spotifyCampaignSchedule-process/api-actions'
import { getCampaignToSchedule } from 'src/Redux/campaign-process/spotifyCampaignSchedule-process/selectors'
import { useLazyGetBudgetRangeQuery } from 'src/Redux/spotifyBudgetRangeApi'
import { Genre, CampaignPlatformTypeEnum } from 'src/Types'

import { GenresModalSpotify } from './GenresModalSpotify'

export const GenresModalSpotifyWrapper: FC = () => {
  const { setSearch } = useCustomSearchParams()
  const dispatch = useAppDispatch()
  const [getCampaignBudgetRange] = useLazyGetBudgetRangeQuery()
  const campaignDetails = useAppSelector(getCampaignToSchedule)
  const { handleGenreAddedEvent } = useGenresAndLanguagesAnalytics()

  const handleSave = (selectedGenres: Genre[]): void => {
    void dispatch(
      changeCampaign({
        campaign_id: campaignDetails?.id,
        languages_ids: campaignDetails?.languages.map((el) => Number(el.id)),
        genres_ids: selectedGenres.map((el) => Number(el.id)),
        moods_ids: null,
        max_price: null,
      }),
    ).then((res) => {
      if (campaignDetails?.languages.length) {
        getCampaignBudgetRange(String(campaignDetails?.id))
      }

      handleGenreAddedEvent({
        platformName: CampaignPlatformTypeEnum.SPOTIFY,
        genreId: selectedGenres.map((el) => Number(el.id)).join(),
        genreName: selectedGenres.map((el) => String(el.genre_name)).join(),
      })
      setSearch({ modal: null })
      return res
    })
  }
  return (
    <GenresModalSpotify
      onSave={handleSave}
      campaignId={Number(campaignDetails.id)}
      genres={campaignDetails.genres}
    />
  )
}
