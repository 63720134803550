import styled from 'styled-components'
import { Tabs } from 'antd'

import { variables, colors, device } from 'src/Styled/variables'

export const DetailsContainer = styled.div`
  box-sizing: content-box;
  max-width: 1120px;
  padding: 0;
  margin: 0 auto;
`

export const BlockOfTikTokCards = styled.div`
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  margin-bottom: 24px;
  @media ${device.smallScreen} {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 16px;
    margin-bottom: 16px;
  }
`

export const StyledTabs = styled(Tabs)`
  overflow: visible;

  padding: 24px;

  .ant-tabs-tab-btn {
    padding: 0 16px 0 24px;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-size: ${variables.fontSize14};
    line-height: 21px;
  }
  .ant-tabs-ink-bar {
    background: ${colors.green};
  }
  .ant-tabs-nav {
    margin-bottom: 26px;

    @media ${device.smallScreen} {
      margin-bottom: 24px;
    }
  }
`

export const TabName = styled.span`
  color: ${colors.mainText};
  margin-right: 8px;
`

export const StyledNumber = styled.span<{ isGreen: boolean }>`
  color: ${({ isGreen }) => (isGreen ? colors.green : colors.greyTextBlackBg)};
`

export const StyledList = styled.ul`
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .infinite-scroll-component {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }

  @media ${device.mobileMin} {
    gap: 2px;
  }
`

export const Videos = styled.div`
  border-radius: 4px;
  .ant-tabs-nav-more > span {
    display: none;
  }
  .ant-tabs-tab {
    padding: 9px 0;
  }

  .ant-tabs-nav-list {
    border-bottom: 1px solid ${colors.greyStroke};
  }
  .ant-tabs-card > .ant-tabs-nav::before {
    display: none;
  }
  .ant-tabs-card .ant-tabs-tab,
  [data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-tab {
    background: transparent;
    border-color: transparent;
  }
  .ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: none;
  }
  .ant-tabs-nav-list {
    box-shadow: none !important;
  }
  .ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::before {
    box-shadow: none !important;
  }
  .ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::after {
    box-shadow: none !important;
  }
  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0;
  }
  padding: 0;

  background-color: ${colors.white};
`
