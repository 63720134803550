import React, { FC, useEffect, useState } from 'react'

import { useGrowthBook } from '@growthbook/growthbook-react'

import { useAppSelector } from 'src/Hooks/redux'
import { getIsNewUserForRemovePaypalExp } from 'src/Redux/auth-process/userSlice/selectors'

interface RemovePaypalExperimentProps {
  children: [React.ReactNode, React.ReactNode]
}

export const RemovePaypalExperiment: FC<RemovePaypalExperimentProps> = ({
  children,
}) => {
  const isNewUser = useAppSelector(getIsNewUserForRemovePaypalExp)
  const [isSecondChild, setIsSecondChild] = useState(false)
  const growthBook = useGrowthBook()

  useEffect(() => {
    if (isNewUser) {
      const isRemove = growthBook.getFeatureValue('remove_paypal', 0)
      if (isRemove) {
        setIsSecondChild(true)
      }
    }
  }, [growthBook, isNewUser])

  if (isSecondChild) {
    return <div>{children[1]}</div>
  }
  return <div>{children[0]}</div>
}
