import React, { FunctionComponent, useEffect, useState } from 'react'

import { Radio, Space, RadioChangeEvent } from 'antd'

import { RadioProps } from './props'

import { StyledRadio, StyledRadioGroup, Content } from './styles'

export const RadioAntd: FunctionComponent<RadioProps> = ({
  options,
  direction,
  isInner,
  onChange,
  defaultValue,
  gapSize = 'md',
}) => {
  const [activeRadio, setActiveRadio] = useState<string | null>(null)

  const onClickRadio = (e: RadioChangeEvent): void => {
    setActiveRadio(e.target.value)
  }

  useEffect(() => {
    defaultValue && setActiveRadio(defaultValue)
  }, [defaultValue])

  return (
    <StyledRadioGroup
      isInner={isInner}
      isVertical={direction === 'vertical'}
      gapSize={gapSize}
    >
      <Radio.Group defaultValue={defaultValue} onChange={onChange}>
        <Space size={30} direction={direction}>
          {options.map((el) => (
            <div key={el.value}>
              <StyledRadio isInner={isInner}>
                <Radio value={el.value} onChange={onClickRadio}>
                  {el.label}
                </Radio>
              </StyledRadio>
              <Content active={el.value === activeRadio}>{el.content}</Content>
            </div>
          ))}
        </Space>
      </Radio.Group>
    </StyledRadioGroup>
  )
}
