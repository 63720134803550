import { FC } from 'react'

import dayJs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { ColumnsType } from 'antd/lib/table'
import { Table } from 'antd'

import InfinityScrollWrapper from 'src/Components/InfinityScrollWrapper'
import { formatDate } from 'src/Constants/formatDate'
import { Desktop, Mobile } from 'src/Components/Responsive'
import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import {
  getCuratorTransactions,
  getCuratorTransactionsMeta,
  getIsLoadingCuratorTransactions,
} from 'src/Redux/transactions-process/curatorBalanceHistorySlice/selectors'
import { getAllTransactions } from 'src/Redux/transactions-process/curatorBalanceHistorySlice/api-actions'
import { CuratorTransaction } from 'src/Redux/transactions-process/curatorBalanceHistorySlice'

import { AmountCell } from './AmountCell/AmountCell'
import { DescriptionCell } from './DescriptionCell/DescriptionCell'
import { StatusCell } from './StatusCell/StatusCell'
import { MobileBalanceList } from './MobileBalanceList/MobileBalanceList'

import {
  AmountHeaderCell,
  BalanceTableContainer,
  EmptyBalance,
  StyledDate,
} from './styles'

export const BalanceTable: FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const isBalanceListLoading = useAppSelector(getIsLoadingCuratorTransactions)
  const transactions = useAppSelector(getCuratorTransactions)
  const meta = useAppSelector(getCuratorTransactionsMeta)

  const onNextPageScroll = (): void => {
    if (meta?.pageNumber) {
      void dispatch(getAllTransactions(meta.pageNumber + 1))
    }
  }

  const columns: ColumnsType<CuratorTransaction> = [
    {
      title: t('balancePage.date'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text: string) => (
        <StyledDate>
          {dayJs(text).format(formatDate.formatDateMonthFull)}
        </StyledDate>
      ),
    },
    {
      title: t('campaignsPage.status'),
      dataIndex: 'type',
      key: 'type',
      render: (_, { type }) => <StatusCell type={type} />,
    },
    {
      title: t('balancePage.description'),
      dataIndex: 'description',
      key: 'description',
      render: (_, record) => (
        <DescriptionCell
          type={record.type}
          description={record.description}
          reviewDetails={record.reviewDetails}
        />
      ),
    },
    {
      title: <AmountHeaderCell>{t('balancePage.amount')}</AmountHeaderCell>,
      dataIndex: 'amount',
      key: 'amount',
      render: (_, { type, amount }) => (
        <AmountCell type={type} amount={Number(amount)} />
      ),
    },
  ]
  return (
    <>
      <Mobile>
        <MobileBalanceList onNextPageScroll={onNextPageScroll} />
      </Mobile>
      <Desktop>
        <InfinityScrollWrapper
          dataLength={transactions.length}
          isLoading={isBalanceListLoading}
          next={onNextPageScroll}
          hasMore={Boolean(meta?.hasNextPage)}
        >
          <BalanceTableContainer>
            <Table
              rowKey='id'
              pagination={false}
              columns={columns}
              dataSource={transactions}
              locale={{
                emptyText: !isBalanceListLoading && (
                  <EmptyBalance>
                    {t('balancePage.youHaveNoRewardsYet')}
                  </EmptyBalance>
                ),
              }}
            />
          </BalanceTableContainer>
        </InfinityScrollWrapper>
      </Desktop>
    </>
  )
}
