import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'

import { APP } from 'src/Configs/App'
import { NameSpace } from 'src/Redux/types'
import { api } from 'src/Services/api'
import { Pagination } from 'src/Types'

import { CuratorTransaction } from '.'

interface TransactionsData {
  data: Array<CuratorTransaction>
  meta: Pagination
}

export const getAllTransactions = createAsyncThunk<TransactionsData, number>(
  `${NameSpace.CuratorBalanceHistory}/getAllTransactions`,
  async (pageNumber: number, { rejectWithValue }) => {
    try {
      const { data } = await api.get<TransactionsData>(
        `${APP.TIKTOK_SERVER}/reviewer-balance-history/curator?pageNumber=${pageNumber}&pageSize=20`,
      )
      return data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      return rejectWithValue({
        message: response?.data?.message,
        code: response?.status,
      })
    }
  },
)
