import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { MAX_MOBILE_WIDTH } from 'src/Styled/variables'

import { PrimaryDottedButton } from '../Buttons/PrimaryDottedButton'
import { PrimaryButton } from '../Buttons/PrimaryButton'

const SuccessButtonBlock = styled.div`
  align-self: center;
  display: flex;
  flex-direction: row;
  gap: 16px;

  @media screen and (max-width: ${MAX_MOBILE_WIDTH}) {
    flex-direction: column;
  }
`

const StyledPrimaryButton = styled(PrimaryButton)`
  padding-left: 40px;
  padding-right: 40px;
`

interface Props {
  upsellButtonText: string
  hasUpsellButton: boolean
  hasBalanceButton: boolean
  onBalanceButtonClick: () => void
  onUpsellButtonClick: () => void
  onCampaignsButtonClick: () => void
}
export const ScheduleSuccessPageButtons: FC<Props> = (props) => {
  const {
    hasUpsellButton,
    upsellButtonText,
    hasBalanceButton,
    onBalanceButtonClick,
    onUpsellButtonClick,
    onCampaignsButtonClick,
  } = props
  const { t } = useTranslation()
  const goToCampaigns = t('scheduleCampaignPage.goToCampaigns')
  if (hasUpsellButton) {
    return (
      <SuccessButtonBlock>
        <PrimaryDottedButton onClick={onCampaignsButtonClick}>
          {goToCampaigns}
        </PrimaryDottedButton>
        <StyledPrimaryButton onClick={onUpsellButtonClick}>
          {upsellButtonText}
        </StyledPrimaryButton>
      </SuccessButtonBlock>
    )
  }
  if (hasBalanceButton) {
    return (
      <SuccessButtonBlock>
        <PrimaryDottedButton onClick={onBalanceButtonClick}>
          {t('scheduleCampaignPage.goToBalanceButton')}
        </PrimaryDottedButton>
        <StyledPrimaryButton onClick={onCampaignsButtonClick}>
          {goToCampaigns}
        </StyledPrimaryButton>
      </SuccessButtonBlock>
    )
  }
  return (
    <SuccessButtonBlock>
      <StyledPrimaryButton onClick={onCampaignsButtonClick}>
        {goToCampaigns}
      </StyledPrimaryButton>
    </SuccessButtonBlock>
  )
}
