import { APP } from 'src/Configs/App'
import { ANY_LANGUAGE_ID } from 'src/Constants/constants'
import { rtkApi } from 'src/Services/rtkApi'
import { CampaignStatusEnum, CampaignTypeEnum, TiktokCampaign } from 'src/Types'
import { MILLISECONDS_IN_SECONDS } from 'src/Constants/numeric'
import { clientId, gaSession } from 'src/Redux/ga4-process/helpers'

interface AddNewTikTokCampaignBody {
  trackUrl: string
  trackTiktokId: string
  trackTitle: string
  trackPlayUrl: string
  trackCoverUrl: string
  trackAuthorName: string
  initialVideoCount: number
  trackDuration: number
}

interface AddNewSpotifyCampaignResponse {
  id: number
  is_feed: boolean
  type: CampaignTypeEnum
  Track: {
    track_id: number | string
    track_image: string
    track_name: string
    artist_name: string
  }
  start_date: string
  end_date: string
  status: CampaignStatusEnum
}
interface AddNewSpotifyCampaignBody {
  artistName: string
  artistSpotifyId: string
  artistUrl: string
  trackSpotifyId: string
  trackImage: string
  trackLength: number
  trackName: string
  trackUrl: string
  pageUrl: string
  withApprovalStep?: boolean
}

const createNewCampaignApi = rtkApi.injectEndpoints({
  endpoints(build) {
    return {
      createNewSpotifyCampaign: build.mutation<
        AddNewSpotifyCampaignResponse,
        AddNewSpotifyCampaignBody
      >({
        query: (body) => ({
          url: `${APP.SERVER}/campaign`,
          method: 'POST',
          body: {
            track_name: body.trackName,
            artist_name: body.artistName,
            track_url: body.trackUrl,
            track_image: body.trackImage,
            artist_url: body.artistUrl,
            spotify_id: body.trackSpotifyId,
            artist_spotify_id: body.artistSpotifyId,
            track_length: body.trackLength,
            url: body.pageUrl,
            genre_ids: [],
            language_ids: [ANY_LANGUAGE_ID],
            mood_ids: [],
            withApprovalStep: body.withApprovalStep,
          },
        }),
      }),
      createNewTiktokCampaign: build.mutation<
        TiktokCampaign,
        AddNewTikTokCampaignBody
      >({
        query: (body) => ({
          url: `${APP.TIKTOK_SERVER}/artist/tiktok/campaigns`,
          method: 'POST',
          headers: {
            'x-scga-session': gaSession,
            'x-scga-client-id': clientId,
            'x-scga-timestamp': String(Date.now() * MILLISECONDS_IN_SECONDS),
            'x-scga-page-title': window.location.origin,
          },
          body: {
            ...body,
            languages: [],
            genres: [],
            isDanceRequested: false,
          },
        }),
      }),
    }
  },
})

export const {
  useCreateNewSpotifyCampaignMutation,
  useCreateNewTiktokCampaignMutation,
} = createNewCampaignApi
