import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError, AxiosResponse } from 'axios'
import { captureException } from '@sentry/react'

import { api } from 'src/Services/api'
import { APP } from 'src/Configs/App'
import { CampaignPlatformTypeEnum, Genre, Language } from 'src/Types'
import { getCookieByName, getUtmParameters } from 'src/Constants/functions'
import { MILLISECONDS_IN_SECONDS } from 'src/Constants/numeric'

import { NameSpace } from '../types'
import { sendSourceBusterEvent } from '../sourcebuster-process/api-actions'
import { getAccessToken } from '../auth-process/credentialsSlice/selectors'
import { RootState } from '..'
import { getSourceBusterData } from '../sourcebuster-process'

import { clientId, gaSession } from './helpers'

interface PaymentEvent {
  campaignId: number
  campaignPlatform: string
}

interface GA4Response {
  errors: string[]
}

interface Checkout {
  campaignId: number | string
  campaignPlatform: string
  paymentMethod: string
  price: number
}

interface FirstCampaignCreation {
  campaignPlatform: CampaignPlatformTypeEnum
  referrerUrl?: string | null
  utmCampaign?: string | null
  utmContent?: string | null
  utmMedium?: string | null
  utmSource?: string | null
  utmTerm?: string | null
}

interface NewCampaignCreation {
  campaignPlatform: CampaignPlatformTypeEnum
  campaignType: string
}

interface TrackAdding {
  campaignPlatform: CampaignPlatformTypeEnum
  trackId: string
  userId: number
  campaignId?: number
}

interface AddingGenreOrLanguage {
  campaignId: number
  campaignPlatform: string
}
interface AddedResponse {
  comment: string
  createdAt: string
  genres: Genre[]
  id: number
  languages: Language[]
  startDate: string
  status: string
  trackAuthorName: string
  trackCoverUrl: string
  trackPlayUrl: string
  trackTitle: string
  trackUrl: string
  updatedAt: string
  userId: number
}

interface ScheduleStart {
  campaignId: number
  campaignPlatform: string
}

const headers = {
  'x-scga-session': gaSession,
  'x-scga-client-id': clientId,
  'x-scga-timestamp': Date.now() * MILLISECONDS_IN_SECONDS,
  'x-scga-page-title': window.location.href,
}
const HEADER_ERROR = 'x-sca-header-error'

export const paymentEventCompletedGA4 = createAsyncThunk<
  GA4Response,
  PaymentEvent
>(
  `${NameSpace.GA4}/paymentEventCompletedGA4`,
  async (
    { campaignId, campaignPlatform },
    { rejectWithValue, dispatch, getState },
  ) => {
    try {
      const requestData = {
        campaignId,
        campaignPlatform,
      }

      const response: AxiosResponse<GA4Response> = await api.post<GA4Response>(
        `${APP.TIKTOK_SERVER}/analytic/payment-events/completed`,
        requestData,
        { headers },
      )

      // send sourcebuster event
      const token = getAccessToken(getState() as RootState)
      const utmObj = getUtmParameters(getCookieByName('soundCampaignUtm'))
      const savedData = getSourceBusterData(getState() as RootState)
      void dispatch(
        sendSourceBusterEvent({
          campaignId,
          token,
          savedData,
          campaignPlatform: campaignPlatform as CampaignPlatformTypeEnum,
          eventName: 'purchase',
          gclid: utmObj.gclid || '',
        }),
      )
      return response.data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      if (response?.headers) {
        const headerError: string | unknown = response?.headers[HEADER_ERROR]

        if (headerError) {
          captureException(new Error(JSON.stringify(headerError)), {
            tags: {
              section: HEADER_ERROR,
            },
          })
        }
      }
      return rejectWithValue({
        message: response?.data.message,
        status: response?.status,
      })
    }
  },
)

export const paymentEventCancelledGA4 = createAsyncThunk<
  GA4Response,
  PaymentEvent
>(
  `${NameSpace.GA4}/paymentEventCancelledGA4`,
  async ({ campaignId, campaignPlatform }, { rejectWithValue }) => {
    try {
      const requestData = {
        campaignId,
        campaignPlatform,
      }

      const response: AxiosResponse<GA4Response> = await api.post<GA4Response>(
        `${APP.TIKTOK_SERVER}/analytic/payment-events/cancelled`,
        requestData,
        { headers },
      )
      return response.data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      if (response?.headers) {
        const headerError: string | unknown = response?.headers[HEADER_ERROR]

        if (headerError) {
          captureException(new Error(JSON.stringify(headerError)), {
            tags: {
              section: HEADER_ERROR,
            },
          })
        }
      }
      return rejectWithValue({
        message: response?.data.message,
        status: response?.status,
      })
    }
  },
)

export const checkoutStartMpEvent = createAsyncThunk<GA4Response, Checkout>(
  `${NameSpace.GA4}/checkoutStartMpEvent`,
  async (
    { campaignId, campaignPlatform, paymentMethod, price },
    { rejectWithValue },
  ) => {
    try {
      const requestData = {
        campaignId,
        campaignPlatform,
        price,
        paymentMethod: paymentMethod.toLocaleLowerCase(),
      }

      const response: AxiosResponse<GA4Response> = await api.post<GA4Response>(
        `${APP.TIKTOK_SERVER}/analytic/payment-events/checkout-start`,
        requestData,
        { headers },
      )

      return response.data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      if (response?.headers) {
        const headerError: string | unknown = response?.headers[HEADER_ERROR]

        if (headerError) {
          captureException(new Error(JSON.stringify(headerError)), {
            tags: {
              section: HEADER_ERROR,
            },
          })
        }
      }
      return rejectWithValue({
        message: response?.data.message,
        status: response?.status,
      })
    }
  },
)

export const firstCampaignCreationStartMpEvent = createAsyncThunk<
  GA4Response,
  FirstCampaignCreation
>(
  `${NameSpace.GA4}/firstCampaignCreationStartMpEvent`,
  async (
    {
      campaignPlatform,
      referrerUrl,
      utmCampaign,
      utmContent,
      utmMedium,
      utmSource,
      utmTerm,
    },
    { rejectWithValue },
  ) => {
    try {
      const requestData = {
        campaignPlatform,
        isFeed: Boolean(campaignPlatform === CampaignPlatformTypeEnum.SPOTIFY),
        ...(referrerUrl ? { referrerUrl: document.referrer } : {}),
        ...(utmCampaign ? { utmCampaign } : {}),
        ...(utmContent ? { utmContent } : {}),
        ...(utmMedium ? { utmMedium } : {}),
        ...(utmSource ? { utmSource } : {}),
        ...(utmTerm ? { utmTerm } : {}),
      }

      const response: AxiosResponse<GA4Response> = await api.post<GA4Response>(
        `${APP.TIKTOK_SERVER}/analytic/campaign-events/first-campaign-creation-start`,
        requestData,
        { headers },
      )

      return response.data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      if (response?.headers) {
        const headerError: string | unknown = response?.headers[HEADER_ERROR]

        if (headerError) {
          captureException(new Error(JSON.stringify(headerError)), {
            tags: {
              section: HEADER_ERROR,
            },
          })
        }
      }
      return rejectWithValue({
        message: response?.data.message,
        status: response?.status,
      })
    }
  },
)

export const newCampaignCreationStartMpEvent = createAsyncThunk<
  GA4Response,
  NewCampaignCreation
>(
  `${NameSpace.GA4}/newCampaignCreationStartMpEvent`,
  async ({ campaignPlatform, campaignType }, { rejectWithValue }) => {
    try {
      const requestData = {
        campaignPlatform,
        campaignType,
        isFeed: Boolean(campaignPlatform === CampaignPlatformTypeEnum.SPOTIFY),
      }

      const response: AxiosResponse<GA4Response> = await api.post<GA4Response>(
        `${APP.TIKTOK_SERVER}/analytic/campaign-events/new-campaign-creation-start`,
        requestData,
        { headers },
      )
      return response.data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      if (response?.headers) {
        const headerError: string | unknown = response?.headers[HEADER_ERROR]

        if (headerError) {
          captureException(new Error(JSON.stringify(headerError)), {
            tags: {
              section: HEADER_ERROR,
            },
          })
        }
      }
      return rejectWithValue({
        message: response?.data.message,
        status: response?.status,
      })
    }
  },
)

export const genreAddedMpEvent = createAsyncThunk<
  AddedResponse,
  AddingGenreOrLanguage
>(
  `${NameSpace.GA4}/genreAddedMpEvent`,
  async ({ campaignId, campaignPlatform }, { rejectWithValue }) => {
    try {
      const requestData = {
        campaignId,
        campaignPlatform,
      }

      const response: AxiosResponse<AddedResponse> =
        await api.post<AddedResponse>(
          `${APP.TIKTOK_SERVER}/analytic/campaign-events/genre-added`,
          requestData,
          { headers },
        )
      return response.data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      if (response?.headers) {
        const headerError: string | unknown = response?.headers[HEADER_ERROR]

        if (headerError) {
          captureException(new Error(JSON.stringify(headerError)), {
            tags: {
              section: HEADER_ERROR,
            },
          })
        }
      }
      return rejectWithValue({
        message: response?.data.message,
        status: response?.status,
      })
    }
  },
)

export const languageAddedMpEvent = createAsyncThunk<
  AddedResponse,
  AddingGenreOrLanguage
>(
  `${NameSpace.GA4}/languageAddedMpEvent`,
  async ({ campaignId, campaignPlatform }, { rejectWithValue }) => {
    try {
      const requestData = {
        campaignId,
        campaignPlatform,
      }

      const response: AxiosResponse<AddedResponse> =
        await api.post<AddedResponse>(
          `${APP.TIKTOK_SERVER}/analytic/campaign-events/language-added`,
          requestData,
          { headers },
        )
      return response.data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      if (response?.headers) {
        const headerError: string | unknown = response?.headers[HEADER_ERROR]

        if (headerError) {
          captureException(new Error(JSON.stringify(headerError)), {
            tags: {
              section: HEADER_ERROR,
            },
          })
        }
      }
      return rejectWithValue({
        message: response?.data.message,
        status: response?.status,
      })
    }
  },
)

export const trackAddedMpEvent = createAsyncThunk<GA4Response, TrackAdding>(
  `${NameSpace.GA4}/trackAddedMpEvent`,
  async (
    { campaignPlatform, trackId, userId, campaignId },
    { rejectWithValue },
  ) => {
    try {
      const requestData = {
        ...(campaignId ? { campaignId } : {}),
        trackId,
        userId,
        campaignPlatform,
        isFeed: Boolean(campaignPlatform === CampaignPlatformTypeEnum.SPOTIFY),
      }

      const response: AxiosResponse<GA4Response> = await api.post<GA4Response>(
        `${APP.TIKTOK_SERVER}/analytic/campaign-events/track-added`,
        requestData,
        { headers },
      )
      return response.data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      if (response?.headers) {
        const headerError: string | unknown = response?.headers[HEADER_ERROR]

        if (headerError) {
          captureException(new Error(JSON.stringify(headerError)), {
            tags: {
              section: HEADER_ERROR,
            },
          })
        }
      }
      return rejectWithValue({
        message: response?.data.message,
        status: response?.status,
      })
    }
  },
)

export const scheduleStartMpEvent = createAsyncThunk<
  GA4Response,
  ScheduleStart
>(
  `${NameSpace.GA4}/scheduleStartMpEvent`,
  async ({ campaignId, campaignPlatform }, { rejectWithValue }) => {
    try {
      const requestData = {
        campaignId,
        campaignPlatform,
      }

      const response: AxiosResponse<GA4Response> = await api.post<GA4Response>(
        `${APP.TIKTOK_SERVER}/analytic/campaign-events/schedule-start`,
        requestData,
        { headers },
      )
      return response.data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      if (response?.headers) {
        const headerError: string | unknown = response?.headers[HEADER_ERROR]

        if (headerError) {
          captureException(new Error(JSON.stringify(headerError)), {
            tags: {
              section: HEADER_ERROR,
            },
          })
        }
      }
      return rejectWithValue({
        message: response?.data.message,
        status: response?.status,
      })
    }
  },
)
