import { createDraftSafeSelector } from '@reduxjs/toolkit'

import { Genre } from 'src/Types'
import { capitalize } from 'src/Helpers/layoutHelpers'

import { RootState } from '..'
import { getGenres } from '../genres-process/selectors'

import { SimilarGenre, SimilarGenresProcessState } from '.'

const getState = (state: RootState): SimilarGenresProcessState =>
  state.similarGenres

export const getSimilarGenres = createDraftSafeSelector(
  getState,
  (state) => state.genres,
)

interface AllAndSimilarGenres {
  allGenres: Genre[]
  similar: SimilarGenre[]
}

const getGenresStates = (state: RootState): AllAndSimilarGenres => ({
  similar: getSimilarGenres(state),
  allGenres: getGenres(state),
})

export const SORT_NEGATIVE = -1

export interface GroupedGenre {
  label: string
  options: SimilarGenre[]
}

export const getGroupedGenres = createDraftSafeSelector(
  getGenresStates,
  ({ similar, allGenres }) => {
    const filteredSimilar = similar.filter((item) =>
      allGenres.some((genre) => genre.id === item.id),
    )
    return [
      {
        label: 'Recommended',
        options: (filteredSimilar || []).map((item) => ({
          id: Number(item.id),
          genreName: capitalize(item.genreName || ''),
        })),
      },
      {
        label: 'A-Z',
        options: (allGenres || [])
          .map((item) => ({
            id: Number(item.id),
            genreName: capitalize(item.genre_name || ''),
          }))
          .sort((a, b) => {
            if (a.genreName < b.genreName) {
              return SORT_NEGATIVE
            }
            if (a.genreName > b.genreName) {
              return 1
            }
            return 0
          }),
      },
    ]
  },
)
