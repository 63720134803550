import { APP } from 'src/Configs/App'
import { rtkApi } from 'src/Services/rtkApi'

interface ProductLiftToken {
  token: string
}

const productLiftTokenApi = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    getProductLiftToken: builder.query<ProductLiftToken, void | null>({
      query: () => `${APP.TIKTOK_SERVER}/user/productlift/token`,
    }),
  }),
  overrideExisting: false,
})

export const { useGetProductLiftTokenQuery } = productLiftTokenApi
