import styled from 'styled-components'
import SelectField from 'react-select'

import { variables, colors } from 'src/Styled/variables'

import { ResponseTextOption } from './props'

export const Select = styled(SelectField<ResponseTextOption>)<{
  fill?: boolean
}>`
  ${({ fill }) => (fill ? 'flex: 1;' : 'width: 149px;')}

  @media (max-width: 576px) {
    margin-right: 0;
    margin-bottom: 8px;
    width: 100%;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;

    .review-response__menu {
      border-radius: 0;
      position: fixed;
      z-index: 1000;
      left: 0;
      top: calc(100% - 152px);
    }
  }

  .custom-dropdown-indicator {
    transform: rotate(0deg);
    transition: transform 0.3s ease-in-out;
    svg {
      fill: ${colors.greyTextWhiteBg};
    }
  }

  .custom-dropdown-indicator-open {
    transition: transform 0.3s ease-in-out;
    transform: rotate(180deg);

    svg {
      fill: ${colors.greyTextWhiteBg};
    }
  }
  .review-response__placeholder {
    margin-left: 8px;
    font-size: 14px;
  }
  .review-response__control {
    display: flex;
    align-items: center;
    height: 48px;
    border: 1px solid ${colors.greyStroke} !important;
    border-radius: 8px;
  }
  .review-response__input {
    display: flex;
    align-items: center;
  }
  .review-response__indicator-separator {
    margin: 0;
    background-color: ${colors.greyStroke};
  }
  .review-response__indicator {
    padding: 12px;
  }
  .review-response__control--is-focused {
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
    border: 1px solid ${colors.greyTextBlackBg} !important;

    .review-response__indicator-separator {
      margin: 0;
      background-color: ${colors.greyTextBlackBg};
    }
  }
  .review-response__menu-list {
    padding: 0 13px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.25);
    border: none;
    border-radius: 8px;
  }
  .review-response__option {
    display: flex;
    align-items: center;
    height: 48px;
    padding: 13px 0;
    background-color: ${colors.white};
    border-bottom: 1px solid ${colors.greyBlue};
    cursor: pointer;

    &:last-child {
      border-bottom: none;
    }
  }
`

export const SelectOption = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${colors.greyBlue};

  &:last-child {
    border-bottom: none;
  }
`
export const SelectOptionText = styled.span`
  display: flex;
  align-items: center;
  font-size: ${variables.fontSize14};
  color: ${colors.mainText};
`

export const SelectOptionIcon = styled.span`
  display: flex;
  align-items: center;
  margin-right: 8px;
`
