import TagManager from 'react-gtm-module'

import { CampaignPlatformTypeEnum, CampaignTypeEnum, ID } from 'src/Types'
import { Roles } from 'src/Constants/enums'

import {
  EventNameEnum,
  EventTypeEnum,
  ReferralShareButtonTypeEnum,
} from '../tagManagerEnums'

export const thankPageCreateTiktokCampaignEvent = ({
  userId,
}: {
  userId?: ID | null
  buttonType?: ReferralShareButtonTypeEnum
}): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventTypeEnum.GA_EVENT,

      event_params: {
        event_name: EventNameEnum.ThankPageCreateTiktokCampaign,
      },
      user_properties: {
        ...(userId ? { user_id: userId } : {}),
      },
    },
  })
}

export const discoverTiktokPromotionEvent = ({
  userId,
}: {
  userId?: ID | null
  buttonType?: ReferralShareButtonTypeEnum
}): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventTypeEnum.GA_EVENT,

      event_params: {
        event_name: EventNameEnum.DiscoverTiktokPromotion,
      },
      user_properties: {
        ...(userId ? { user_id: userId } : {}),
      },
    },
  })
}

export const upSellEvent = ({
  userId,
  campaignNumber,
  buttonType,
  campaignType,
}: {
  userId?: ID | null
  campaignNumber: number
  buttonType: 'details_tiktok' | 'schedule_subscription'
  campaignType: CampaignTypeEnum
}): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventTypeEnum.GA_EVENT,
      event_params: {
        event_name: EventNameEnum.UpsellClick,
        campaign_platform: CampaignPlatformTypeEnum.SPOTIFY,
        campaign_type: campaignType,
        campaign_number: campaignNumber,
        button_type: buttonType,
      },

      user_properties: {
        user_id: userId,
        account_type: Roles.artist,
      },
    },
  })
}
