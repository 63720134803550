import { FC } from 'react'

import { useTranslation } from 'react-i18next'

import { HUNDREDTHS } from 'src/Constants/numeric'
import { CampaignStatusEnum } from 'src/Types/index'
import { getValidNumber } from 'src/Helpers/layoutHelpers'
import { useSpotifyCampaignDetails } from 'src/Redux/campaignDetailsApi/useSpotifyCampaignDetails'

import { Block, CalculationWrapper, Card, Label, Value } from './styles'

export const CampaignCalculation: FC = () => {
  const { t } = useTranslation()
  const { campaignDetails } = useSpotifyCampaignDetails()

  const isEndedCampaign =
    campaignDetails?.status === CampaignStatusEnum.ENDED ||
    campaignDetails?.status === CampaignStatusEnum.STOPPED

  const budget = getValidNumber(campaignDetails?.budget).toFixed(HUNDREDTHS)
  const refund = getValidNumber(campaignDetails?.refund).toFixed(HUNDREDTHS)

  const reviewsOrCoveredCurators = getValidNumber(
    campaignDetails?.reviewed_count,
  )

  const placements = getValidNumber(campaignDetails?.playlists_adds)
  const currentAvailableCurators = getValidNumber(
    campaignDetails?.curators_count_max,
  )

  const availableCurators =
    currentAvailableCurators > getValidNumber(campaignDetails?.curators_count)
      ? currentAvailableCurators
      : getValidNumber(campaignDetails?.curators_count)

  const spent = isEndedCampaign
    ? (Number(budget) - Number(refund)).toFixed(HUNDREDTHS)
    : getValidNumber(campaignDetails?.spent).toFixed(HUNDREDTHS)

  return (
    <CalculationWrapper>
      <Block>
        <Card>
          <Label>{t('campaignResultsPage.budgetLabel')}: </Label>
          <Value>{`$${budget}`}</Value>
        </Card>
        <Card>
          <Label>{t('campaignResultsPage.spentLabel')}: </Label>
          <Value>{`$${spent}`}</Value>
        </Card>
      </Block>
      <Block>
        <Card>
          <Label>{t('campaignResultsPage.reviews')}</Label>
          <Value>{reviewsOrCoveredCurators}</Value>
        </Card>
        <Card>
          <Label>{t('campaignResultsPage.placements')}</Label>
          <Value>{placements}</Value>
        </Card>
      </Block>
      <Block>
        <Card>
          <Label>{t('campaignResultsPage.availableCurators')}</Label>
          <Value>{`Up to ${availableCurators}`}</Value>
        </Card>
        <Card>
          <Label>{t('campaignResultsPage.coveredCurators')}</Label>
          <Value>{reviewsOrCoveredCurators}</Value>
        </Card>
      </Block>
    </CalculationWrapper>
  )
}
