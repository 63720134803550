import React, { ReactElement } from 'react'

import { useNavigate } from 'react-router-dom'

import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import { NAVIGATE_BACK_INDEX } from 'src/Constants/constants'
import { useLazyNewSoundsQuery } from 'src/Redux/creatorNewSoundsApi'
import { useAppSelector } from 'src/Hooks/redux'
import { getInfluencerId } from 'src/Redux/auth-process/userSlice/selectors'

import { ConfirmRules } from '../ConfirmRules'
import { NewSoundDetails } from '../NewSoundDetails'
import { CloseCrossButton, Wrapper } from '../../styles'
import { SuccessAcceptModal } from '../../../SuccessAcceptModal'
import { useNewSoundDetails } from '../NewSoundDetails/useNewSoundDetails'

export const NewSoundDetailsModal = (): ReactElement => {
  const { search } = useCustomSearchParams()
  const navigate = useNavigate()
  const influencerId = useAppSelector(getInfluencerId)

  const isConfirmRulesState = search.get('confirmRules') === 'true'
  const soundStatus = search.get('soundStatus')

  const isSoundAccepted = soundStatus === 'accepted'

  const { monetizationErrors } = useNewSoundDetails()
  const [getSounds] = useLazyNewSoundsQuery()

  const onClickCloseModal = (): void => {
    navigate(NAVIGATE_BACK_INDEX)
    if (monetizationErrors.eligibleMedian) {
      getSounds({
        creatorId: influencerId,
        pageNumber: 1,
      })
    }
  }

  return (
    <Wrapper>
      {!isConfirmRulesState && <NewSoundDetails />}
      {isConfirmRulesState && <ConfirmRules />}
      {isSoundAccepted && <SuccessAcceptModal />}

      <CloseCrossButton onClick={onClickCloseModal} />
    </Wrapper>
  )
}
