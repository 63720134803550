import React, { Fragment } from 'react'

import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import Button from 'src/Components/Buttons/Button'
import { SmartInput } from 'src/Components/SmartInput'

import { BankDetails } from '../helpers'

import { BankDetailsFormProps } from './props'
import { RenderInput } from './RenderInput'
import { getSchemaBankDetails } from './schema'

import {
  FormGroup,
  FormGroupField,
  FormGroupLabel,
  Wrapper,
  Form,
  ButtonWrapper,
  RowWrapper,
} from './styles'

const BankDetailsForm: React.FunctionComponent<BankDetailsFormProps> = ({
  onSubmit,
  item: { fields },
  handleSpecifyRequirements,
  initialValues,
}) => {
  const { t } = useTranslation()

  const {
    register,
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useForm<Record<string, string>>({
    mode: 'all',
    defaultValues: initialValues as Record<string, string>,
    resolver: yupResolver(getSchemaBankDetails(fields)),
  })

  const onHandleSubmit = (data: Record<string, string>): void => {
    onSubmit(data as unknown as BankDetails)
  }

  return (
    <Form
      data-testid='bank-details-form'
      onSubmit={handleSubmit(onHandleSubmit)}
    >
      <RowWrapper>
        <Wrapper>
          <FormGroup>
            <FormGroupLabel>Account holder name</FormGroupLabel>
            <FormGroupField>
              <SmartInput
                data-testid='account-holder-name'
                {...register('accountHolderName')}
                error={errors.accountHolderName}
                type='text'
              />
            </FormGroupField>
          </FormGroup>
        </Wrapper>
        {fields.map(({ group, name }) => (
          <Fragment key={name}>
            {group.map((field) => (
              <RenderInput
                key={field.name}
                control={control}
                error={errors}
                name={field.name}
                valuesAllowed={field.valuesAllowed}
                example={field.example}
                refreshRequirementsOnChange={field.refreshRequirementsOnChange}
                type={field.type}
                propKey={field.key}
                handleSpecifyRequirements={handleSpecifyRequirements}
              />
            ))}
          </Fragment>
        ))}
      </RowWrapper>
      <ButtonWrapper>
        <Button disabled={isSubmitting} htmlType='submit' type='green'>
          {t('settingsPage.saveChanges')}
        </Button>
      </ButtonWrapper>
    </Form>
  )
}

export default BankDetailsForm
