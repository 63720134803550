import { FC, PropsWithChildren } from 'react'

import { SuccessEdit } from './SuccessEdit'

interface Props {
  show: boolean
  isNew: boolean
  onClose: () => void
}
type ShowSuccessContent = PropsWithChildren & Props

export const ShowSuccessContent: FC<ShowSuccessContent> = ({
  show,
  isNew,
  onClose,
  children,
}) => {
  if (show) {
    return <SuccessEdit isNew={isNew} onClose={onClose} />
  }
  return children
}
