import styled from 'styled-components'

import { colors, device, variables } from 'src/Styled/variables'

import { Container as BaseContainer } from '../styles'

export const Container = styled(BaseContainer)`
  > span {
    font-weight: ${variables.fontWeight400};
    line-height: 21px;
    text-align: left;
    margin-bottom: 16px;
  }
  color: ${colors.mainText};
  margin-top: 53px;

  @media ${device.mobileMin} {
    font-size: 1.125rem;
    margin-bottom: 15px;
    text-align: center;
  }
`

export const ErrorMessage = styled.p`
  margin: 8px 0 0 0;
  color: ${colors.messegesRed};
  font-size: ${variables.fontSize14};
  line-height: 1.5;
  text-align: left;
`
export const ErrorMessageText = styled.p`
  margin: 8px 0 0 0;
  font-weight: ${variables.fontWeight600};
  color: ${colors.messegesYellow1};
  text-align: center;
`

export const InsertAlertText = styled.div`
  font-size: ${variables.fontSize18};
  color: ${colors.mainText};
  font-weight: ${variables.fontWeight400};
  line-height: 27px;
  text-align: left !important;
  margin-bottom: 8px;
`

export const SuccessIcon = styled.img<{ mobile?: boolean }>`
  margin-right: 16px;
  margin-bottom: ${({ mobile }) => (mobile ? '16px' : '0px')};
  width: 40px;
  height: 40px;
  @media ${device.mobileMax} {
    margin-bottom: 18px;
  }
`

export const inlineStyles = {
  errorInputHighlight: {
    borderColor: colors.messegesRed,
  },
}

export const ButtonsGroups = styled.div`
  margin-bottom: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column-reverse;
  row-gap: 24px;
  div {
    width: fit-content;
    button {
      padding: 0 40px;
    }
  }

  @media ${device.mobileMin} {
    flex-direction: row;
    justify-content: center;
    column-gap: 1rem;
    align-items: flex-start;
  }
`
export const TimeLeft = styled.span`
  font-size: ${variables.fontSize14};
  color: ${colors.greyTextWhiteBg};
`
export const InputContainer = styled.div`
  margin-bottom: 48px;
  @media ${device.mobileMin} {
    margin-bottom: 40px;
  }
`

export const UseThisSound = styled.span`
  border-bottom: 1px solid ${colors.badgeRed};
`
export const WrapperWithIcon = styled.div`
  display: grid;
  grid-template-columns: 16px 1fr;
  grid-column-gap: 8px;
`

export const StyledImgWrapper = styled.div`
  margin-top: 11px;
`
