import React, { FC } from 'react'

import { useTranslation } from 'react-i18next'
import { useIntercom } from 'react-use-intercom'

import { Description, SupportButton, WithdrawText } from '../styles'

interface Props {
  reviewDetails: {
    trackName: string
    artistName: string
  } | null
  description: string
}

export const ErrorPayoutDescriptionCell: FC<Props> = ({
  reviewDetails,
  description,
}) => {
  const { t } = useTranslation()
  const { show } = useIntercom()
  const text = `Placement for “${reviewDetails?.trackName || ''}” by ${
    reviewDetails?.artistName || ''
  }`
  const story = reviewDetails ? text : description
  return (
    <Description>
      <div>
        <div>{story}</div>
        <WithdrawText>
          <span>{t('balancePage.payoutWasCanceled')}</span>
          <SupportButton onClick={() => show()}>
            {t('balancePage.support')}
          </SupportButton>
        </WithdrawText>
      </div>
    </Description>
  )
}
