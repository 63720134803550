import React, { FC } from 'react'

import { useTranslation } from 'react-i18next'

import {
  CampaignCalculation,
  CampaignCalculationLabel,
  CampaignCalculationValue,
} from '../styles'

interface CuratorsCalculationProps {
  curatorsCount?: number
  curatorsCountMax?: number
}

export const CuratorsCalculation: FC<CuratorsCalculationProps> = ({
  curatorsCount,
  curatorsCountMax,
}) => {
  const { t } = useTranslation()
  return (
    <CampaignCalculation>
      <CampaignCalculationLabel>
        {t('campaignResultsPage.curatorsLabel')}:{' '}
      </CampaignCalculationLabel>
      <CampaignCalculationValue>
        {curatorsCount}
        {curatorsCountMax ? `-${curatorsCountMax}` : ''}
      </CampaignCalculationValue>
    </CampaignCalculation>
  )
}
