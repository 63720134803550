import { FC } from 'react'

import { useTranslation } from 'react-i18next'

import avatar from 'src/Assets/Png/ava.png'
import { Badge } from 'src/Components/Badge'
import { capitalizeFirstLetter } from 'src/Helpers/layoutHelpers'
import dayjs from 'src/Helpers/dayjs'
import { formatDate } from 'src/Constants/formatDate'
import { Desktop, Mobile } from 'src/Components/Responsive'

import { getBonusValue, getPlateType } from './helpers'

import {
  Avatar,
  BonusContainer,
  DateContainer,
  Email,
  Header,
  Name,
  PlateContainer,
  SubTitle,
  Value,
  Wrapper,
} from './styles'

interface RewardsDetailsItemProps {
  name: string
  date: string
  email: string
  status: string
  bonus?: number
}

export const RewardsDetailsItem: FC<RewardsDetailsItemProps> = ({
  name,
  email,
  status,
  date,
  bonus,
}) => {
  const { t } = useTranslation()
  const registerDate = dayjs(date).format(formatDate.formatDateFirst)
  const bonusValue = getBonusValue(status, bonus)
  const plateType = getPlateType(status)
  return (
    <Wrapper>
      <Header>
        <Avatar src={avatar} alt='avatar' />
        <div>
          <Name>{name}</Name>
          <Email>{email}</Email>
        </div>
      </Header>
      <DateContainer>
        <div>
          <SubTitle>{t('referAndEarn.joined')}</SubTitle>
          <Value>{registerDate}</Value>
        </div>
        <Mobile>
          <Badge title={capitalizeFirstLetter(status)} type={plateType} />
        </Mobile>
      </DateContainer>
      <Desktop>
        <PlateContainer>
          <Badge title={capitalizeFirstLetter(status)} type={plateType} />
        </PlateContainer>
      </Desktop>
      <BonusContainer>
        <SubTitle>{t('referAndEarn.bonus')}</SubTitle>
        <Value>{bonusValue}</Value>
      </BonusContainer>
    </Wrapper>
  )
}
