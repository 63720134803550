import { FunctionComponent, Suspense, lazy } from 'react'

import { Route, Routes } from 'react-router-dom'

import { APP } from 'src/Configs/App'
import Settings from 'src/Containers/Settings'
import AdminPage from 'src/Containers/AdminPage'
import Balance from 'src/Containers/Balance/components/Balance'
import Rank from 'src/Containers/Rank'
import PlayLists from 'src/Containers/Playlists'

import { UserComponentsProps } from './props'
import {
  tracksFeed,
  settings,
  balance,
  playlists,
  rank,
  admin,
  reviewed,
  review,
} from './routes'

// please don't remove this  lazy import
const TracksFeed = lazy(() => import('src/Containers/TracksFeed'))
const TrackToReview = lazy(() => import('src/Containers/TrackToReview'))
const ReviewedTrack = lazy(() => import('src/Containers/ReviewedTrack'))

const CuratorComponents: FunctionComponent<UserComponentsProps> = ({
  email,
}) => (
  <Suspense fallback={<div></div>}>
    <Routes>
      <Route Component={TracksFeed} path={tracksFeed} />
      <Route Component={TracksFeed} path={'/'} />
      <Route
        Component={ReviewedTrack}
        path={`${tracksFeed}/:campaignId${reviewed}`}
      />
      <Route
        Component={TrackToReview}
        path={`${tracksFeed}/:campaignId${review}`}
      />
      <Route Component={Balance} path={balance} />
      <Route Component={PlayLists} path={playlists} />
      <Route Component={Rank} path={rank} />
      <Route Component={Settings} path={settings}>
        <Route path=':page' Component={Settings} />
      </Route>

      {APP?.admin_email?.includes(email) && (
        <Route Component={AdminPage} path={admin} />
      )}

      <Route Component={TracksFeed} path='*' />
    </Routes>
  </Suspense>
)
export default CuratorComponents
