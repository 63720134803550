import {
  ArtistRolesEnum,
  CampaignStatus,
  PaymentMethodEnum,
  PaymentStatusEnum,
  Roles,
  VideoStatus,
} from 'src/Constants/enums'
import { ErrorCode } from 'src/Redux/types'
import { EventDismissReasonsEnum } from 'src/Redux/creatorSubmittedSoundsApi'

export type ID = string | number

export interface StripePayment {
  amount: number
  campaignId: number
  campaignValue: number
  startDate: string | Date
  // TODO: remove this when median test is over
  isMedianTest?: boolean
  couponValue?: string
  type: CampaignPlatformTypeEnum
  usedBalance: number
}

export interface User {
  artist?: {
    is_tiktok_allowed?: boolean
    show_tiktok_alert?: boolean
    artist_role?: ArtistRolesEnum
  }
  intercomHash: string | null
  isBlocked: boolean
  first_name: string
  last_name: string
  ip: string
  last_activity: string
  isArtist: boolean
  user_photo_url?: string
  is_email_confirmed: boolean
  is_first_campaign: boolean
  is_new_term?: boolean
  release_notes: string | null
  score: number
  are_terms_accepted: boolean | null
  createdAt: string
  email: string
  gcid: string
  geo: {
    country: string
  }
  id: ID
  phone_number: string
  role: Roles
  terms_position: number | null
  rank: number
  playlist_requirement: boolean

  balance?: number
  complaints?: number
  country?: string | null
  // not checked params

  isDeactivated?: boolean

  created_at?: string
  spotify_id?: string
  access_token?: string
  refresh_token?: string
  curatorId?: ID | null
  is_new_feed?: boolean

  spotifyId: string | null
  influencerId: number
  updatedAt?: string
  isEmailConfirmed: boolean
  lastActivity: string
  isTiktokAllowed?: boolean
}

export type Curator = {
  are_terms_accepted: boolean
  autopay: boolean
  autopay_date: string | Date
  email: string
  createdAt: string
  id: number
  ip: string
  first_name: string
  last_activity: string
  last_name: string
  spotify_id: string
  user_photo_url: string
  verified: boolean
  terms_position: number
  is_new_feed: boolean
  isArtist: boolean
  username: string
  negative_responses: number
  playlist_followers: Array<number>
  followers_count: number
  playlists_count: number
  rating: number
  adding_rate: number
  Payouts: Array<Payout>
  Playlists: Array<Playlist>

  user_id: number

  User: User
  Recipient: {
    is_checking_needed: boolean
    country: string
  }
}

export type Payout = {
  id: number
  amount: number
  date: string | Date
  status: PayoutStatus
  transfer_id: number | null
  curator_id: number
  curator_complaints: number
  curator_payouts: number
  Curator: Curator
  total_payouts: number
  requests_since_autopayout: number
  autopayout_status: boolean
  autopay: boolean
  Recipient: {
    is_checking_needed: boolean
    country: string
  }
}

export interface NewPlaylistToSet {
  id: string
  images: Array<Image>
  name: string
  followers: {
    total: number
  }
  tracks: {
    total: number
  }
  owner: {
    display_name: string
    id: string
  }
}

export enum PayoutStatus {
  Paid = 'paid',
}

export enum CampaignStatusEnum {
  PAYMENT_PENDING = 'payment_pending',
  PENDING_APPROVAL = 'pending_approval',
  APPROVED = 'approved',
  SCHEDULED = 'scheduled',
  RUNNING = 'running',
  ENDED = 'ended',
  STOPPED_BY_ADMIN = 'stopped_by_admin',
  AWAITING_REVIEW = 'awaiting_review',
  PENDING = 'pending',
  INPROCESS = 'in_process',
  FINISHED = 'finished',
  CANCELED = 'canceled',
  CANCELED_BY_ADMIN = 'canceled_by_admin',
  STOPPED = 'stopped',
}

export enum CampaignTypeEnum {
  FIRST = 'first',
  BASIC = 'basic',
  RELAUNCHED = 'relaunched',
  NEW = 'new',
}

export interface UtmObj {
  referrer_url?: string
  fbp?: string
  fbc?: string
  client_user_agent?: string
  gclid?: string
  ref?: string
}

export enum TiktokCampaignStatus {
  AWAITING_REVIEW = 'awaiting_review',
  PENDING = 'pending',
  SCHEDULED = 'scheduled',
  RUNNING = 'running',
  IN_PROCESS = 'in_process',
  ENDED = 'ended',
  CANCELED = 'canceled',
  FINISHED = 'finished',
  STOPPED = 'stopped',
  STOPPED_BY_ADMIN = 'stopped_by_admin',
  CANCELED_BY_ADMIN = 'canceled_by_admin',
}

export enum CampaignPlatformTypeEnum {
  SPOTIFY = 'spotify',
  TIKTOK = 'tiktok',
  SPOTIFY_TIKTOK = 'spotify+tiktok',
}

export interface Genre {
  id?: number | string
  genre_name?: string
  Genre?: Genre
  playlists_count?: number
  placeholder?: string
  isDisabled?: boolean
  name?: string
  label?: string
  value?: string | number
  genre_id?: ID
  playlistsCount?: number
}

export interface SelectedLanguage {
  value?: number
  label: string
}
export interface ProperGenreOrLanguage {
  id: ID
  value: ID
  name: string
  playlistsCount?: number | string
}
export interface TrackGenre {
  genre_id?: ID
  Genre: {
    id: ID
    genre_name: string
    genre_id: ID
  }
}

export interface TrackLanguage {
  language_id?: number
  Language: {
    id: number
    language_name: string
  }
}
export interface Language {
  id?: number
  language_id?: ID
  language_name?: string
  playlists_count?: number
  value?: number
  label?: string
  name?: string
  Language?: {
    id: number
    language_name: string
  }
}

export interface Coupon {
  id: number
  discount: number
  value: string
}

export interface Mood {
  id: number
  mood_id: number
  Mood: {
    id: number
    mood_name: string
  }
}

export interface TiktokCampaign {
  hasNotCompletedEvents?: boolean
  id: string | number
  type: CampaignTypeEnum
  pricingModelVersion: TikTokCampaignPricingModelEnum
  trackCoverUrl?: string
  trackDuration: number
  trackTitle: string
  trackId: number
  trackAuthorName: string
  startDate?: string | Date
  endDate: string | Date
  status: TiktokCampaignStatus
  platformType: CampaignPlatformTypeEnum.TIKTOK
  genres: Array<Genre>
  languages: Array<Language>
  amount?: number
  isCurrent: boolean
  comment?: string
  audience?: {
    maxFollowers: number
    maxVideos: number
    minFollowers: number
    minVideos: number
  }
  is_feed?: boolean
  totalVideos: number
  totalViews: number
  trackNewVideosCount?: number
  trackCommentsCount?: number
  totalShares: number
  relaunched: boolean
  track?: {
    url?: string
    duration: number
    coverUrl?: string
    title?: string
    trackId: number
    authorName: string
    playUrl?: string
  }
  allReview?: number
  reviewedReviews?: number
  submittedVideosAmount: number
  influencersAcceptedAmount: number
  reviews: { done: number; influencersAcceptedCount: number }
  start_date?: string | Date
  trackTiktokId?: string | number
  createdAt?: string | number | Date
  updatedAt?: string
  expectedMaxVideos?: number
  expectedMinVideos?: number
  stats?: {
    totalVideos: number
    totalLikes: number
    totalShares: number
    totalViews: number
    totalComments: number
  }
  statsUpdatedAt?: string
  totalRefunded?: number
  currentAmount?: number
  userId?: number
  reserved: number
  spent: number
}

export interface SpotifyCampaign {
  createdAt?: string | number | Date
  id: number
  trackCoverUrl?: string
  trackTitle?: string
  endDate?: string | Date
  trackAuthorName?: string
  platformType: CampaignPlatformTypeEnum.SPOTIFY
  status: CampaignStatusEnum
  type: CampaignTypeEnum
  startDate?: string | Date
  trackId?: number
  genres?: Array<Genre>
  languages?: Array<Language>
  price?: number
  relaunched?: boolean
  amount?: string | null
  trackName?: string
  artistName?: string
  followers?: number
  max_followers?: number
  playlists?: number
  max_playlists?: number
  parent_campaign_id?: number
  isCurrent?: boolean
  comment?: string
  curators_count?: number
  isMinPrice?: boolean
  isMaxPrice?: boolean
  audience?: {
    followers: number
    curators: number
    maxCurators: number
    maxFollowers: number
    maxPlaylists: number
    minPlaylists: number
    playlists: number
  }
  loading?: boolean
  overrun_budget?: boolean
  reviewedReviews?: number
  allReview?: number
  is_feed?: boolean
  Track?: Track
  track?: Track
  start_date?: string | Date
  end_date?: string | Date
  current_amount?: string | null
  submittedVideosAmount?: number
  influencersAcceptedAmount?: number
}

export interface NewTrack {
  id: number
  startDate: string
  endDate: string
  trackName: string
  artistName: string
  trackUrl: string
  matchedGenres: Array<string>
  matchedLanguages: Array<string>
  matchedPlaylists: Array<string>
  matchedPercent: number
  trackImage: string
}

export interface Campaign {
  id: ID
  status: CampaignStatus
  start_date: string | Date
  end_date: string | Date
  endDate: string | Date
  startDate: string | Date
  type: CampaignTypeEnum
  Reviews: Array<Review>
  Track: Track
  PaypalPayments: Array<PaypalPayment>
  relaunched: boolean
  price?: number
  trackId?: number
  playlists?: number
  max_playlists?: number
  curators_count?: number
  followers?: number
  isCurrent?: boolean
  moods?: Array<number>
  artistName?: string
  trackName?: number
  category: string
  genres?: Array<Genre>
  languages?: Array<Language>
  created_at: string
  platformType: CampaignPlatformTypeEnum
  reviewedReviews: number
  allReview: number
  reviewed_reviews: number
  all_review: number
  trackCoverUrl: string
  trackTitle: string
  trackAuthorName: string
  is_feed?: boolean
  submittedVideosAmount?: number
  influencersAcceptedAmount?: number
  createdAt?: string | Date
  amount?: string
  current_amount?: string
}

export interface PaypalPayment {
  amount: number
}

export interface NewPlaylist {
  selectedGenres?: Array<Genre>
  selectedLanguages?: Array<Language>
  relevantGenres?: Array<Genre>
  playListInfo: {
    id?: number | null
    name?: string
    img?: string
  }
  id?: number | null
}

export interface Track {
  artist_spotify_id: string
  album: {
    album_type: string
    artists: Array<Artist>
    available_markets: Array<string>
    external_urls: { spotify: string }
    href: string
    id: string | number
    images: Array<Image>
    name: string
    release_date: string | Date
    release_date_precision: string
    total_tracks: number
    type: string
    uri: string
  }
  artists: Array<Artist>
  available_markets: Array<string>
  disc_number: number
  duration_ms: number
  explicit: boolean
  external_ids: { isrc: 'DECE72003523' }
  external_urls: { spotify: string }
  href: string
  id: string | number
  is_local: boolean
  name: string
  popularity: number
  preview_url: string
  track_number: number
  type: string
  uri: string
  track_name: string
  artist_name: string
  track_image: string
  track_url?: string
  track_id?: number
  TrackGenres: Array<Genre>
  TrackLanguages: Array<Language>
  TrackMoods: Array<Mood>
  spotify_id: string
  author?: string
  title?: string

  cover?: string
  spotify_popularity?: number
  spotify_popularity_result?: number
  withApprovalStep?: boolean
}

export enum ActionsForSelectGenresAndLanguagesEnum {
  SELECT_OPTION = 'select-option',
}

export interface Playlist {
  id: ID
  playlist_name: string
  playlist_url: string
  followers: number
  tracks: number
  discovered_on_artists: number
  discovered_on_updated_at: string | Date
  monthly_listeners: number
  meets_conditions: boolean
  playlist_cover: string
  addition_type: string
  is_blocked: boolean
  is_limit_exceeded: boolean
  score: number
  extra_score: number
  level: number
  isNew: boolean
  status: string
  is_deactivation_new: boolean
  reviews_amount: number
  curator_id: ID
  PlaylistGenres: Array<TrackGenre>
  PlaylistLanguages: Array<TrackLanguage>
  PlaylistMoods: Array<Mood>
  genres: Array<ProperGenreOrLanguage>
  languages: Array<ProperGenreOrLanguage>
  streams_count: number
  listeners_count: number
  ArtistStats: Array<{ streams: number; listeners: number }>
  setDeactivationAlertRead: (id: number) => void
  is_hidden: boolean
}

export interface MatchedPlaylist {
  id: ID
  playlist_name: string
  genres: Array<ProperGenreOrLanguage>
  languages: Array<ProperGenreOrLanguage>
}

export interface MatchedPlaylistReview {
  playlist_id: number
  playlist_name: string
}
export interface Review {
  id: ID
  curator_id: number
  curator_rating: number
  comment: string
  recommendations?: string
  rank: number
  review_date: Date
  review_feedback: ArtistFeedback
  review_refund: number
  playlist_id: ID
  review_status: ReviewStatus
  Curator: Curator
  Playlist?: Playlist
  matched?: Array<MatchedPlaylist>
  potential_reward: string
  rating: number
  reward_received: number
  Campaign: Campaign
  campaign: Campaign
}

export interface Artist {
  external_urls: { spotify: string }
  followers: { href: string; total: number }
  href: string
  id: string
  ip: string
  name: string
  first_name: string
  last_activity: string
  email: string
  last_name: string
  createdAt: string
  type: string
  uri: string
  popularity: number
  genres: Array<Genre>
  isArtist: boolean
  images: Array<Image>
}

export interface ArtistSpotify {
  external_urls: { spotify: string }
  followers: { href: string; total: number }
  href: string
  id: string
  name: string
  type: string
  uri: string
  popularity: number
  genres: Array<string>
  images: Array<Image>
}

export interface Dimensions {
  width: number
  height: number
}

interface Image extends Dimensions {
  url: string
}
export interface SpotifyTrack {
  album: {
    album_type: string
    artists: Array<Artist>
    available_markets: Array<string>
    external_urls: { spotify: string }
    href: string
    id: string
    images: Array<Image>
    name: string
    release_date: string | Date
    release_date_precision: string
    total_tracks: number
    type: string
    uri: string
  }
  artists: Array<Artist>
  available_markets: Array<string>
  disc_number: number
  duration_ms: number
  explicit: boolean
  external_ids: { isrc: string }
  external_urls: { spotify: string }
  href: string
  id: string
  is_local: boolean
  name: string
  popularity: number
  preview_url: string | null
  track_number: number
  type: string
  uri: string
}

export interface TikTokTrack {
  initialVideoCount: number
  isOriginalSound: boolean
  trackAuthorName: string
  trackCoverUrl: string
  trackDuration: number
  trackPlayUrl: string
  trackTiktokId: string
  trackTitle: string
  trackUrl: string
  music?: Array<TikTokTrack>
}

export enum ReviewStatus {
  awaiting_review = 'awaiting_review',
  reviewed = 'reviewed',
  missed = 'missed',
  canceled = 'canceled',
  skipped = 'skipped',
}

export interface ReviewPlaylist {
  id: number
  playlist_name: string
  percent: number
  playlist_cover: string
  score: number
}

export interface ReviewDetails {
  id: number
  campaign_id: number
  comment: string
  curator_rating: number
  potential_reward: string
  rank: number
  reject_reason_id: number
  review_feedback: string
  review_status: ReviewStatus
  playlists: ReviewPlaylist[]
  addedTo?: ReviewPlaylist
  daysLeft: number
  isRejectLastThree: boolean
  track: {
    id: number
    artist_name: string
    genres: ProperGenreOrLanguage[]
    languages: ProperGenreOrLanguage[]
    track_image: string
    track_name: string
    track_url: string
  }
  reward?: number
  stars?: number
  is_feed?: boolean
}

export interface FeedMatchedPlaylist {
  id: number
  playlist_name: string
  playlist_cover: string
  matched_percent: number
  score: number
  reward_with_placement?: number
  reward_without_placement?: number
  followers?: number
}

export interface FeedDetailsTrack {
  id: number
  track_name: string
  artist_name: string
  track_url: string
  genres: Array<string>
  languages: Array<string>
}

export interface TransferwiseRequirement {
  type: string
  title: string
  usageInfo: string
  fields: Array<TransferwiseRequirementField>
}
type InputType = 'radio' | 'select' | 'date' | 'text'
export interface TransferwiseRequirementGroup {
  example: string
  key: string
  name: string
  refreshRequirementsOnChange: boolean
  validationRegexp: string | null
  required: boolean
  valuesAllowed: TransferwiseRequirementValuesAllowed[] | null
  type: InputType
}

export interface TransferwiseRequirementValuesAllowed {
  key: string
  name: string
}
export interface TransferwiseRequirementField {
  name: string
  group: TransferwiseRequirementGroup[]
  valuesAllowed: TransferwiseRequirementValuesAllowed[]
}

export interface Recipient {
  accountHolderName: string
  currency: string
  type: string
  details: {
    address: RecipientAddress
  }
}

export interface RecipientAddress {
  country: string
  countryCode: string
  firstLine: string
  postCode: string
  city: string
  state: string
}

export interface CustomError {
  message?: string
  error?: {
    message: string
    status: ErrorCode.NotFound | ErrorCode.Unauthorized
  }
  statusCode?: number
  status?: number
}

export enum InfluencerRegisterEnum {
  JOIN = 'join',
  CONNECT = 'connect',
  CONNECTED = 'connected',
  FORM = 'form',
  CONFIRMATION = 'confirmation',
  COMPLETED = 'completed',
  ERROR = 'error_connect',
}

export enum ReviewType {
  add = 'add',
  reject = 'reject',
}

export interface ReviewSelectValue {
  type: ReviewType
  id: number
}

export enum FeedFilterType {
  date = 'date',
  genres = 'genres',
  playlists = 'playlists',
}

export enum FeedFilterDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum FlowType {
  create = 'create_campaign',
  scheduling = 'scheduling_campaign',
  share = 'share_review',
  similarArtist = 'similar_artist',
}

export interface CampaignToSchedule {
  id: string | number
  status: CampaignStatusEnum
  type: string
  genres: ProperGenreOrLanguage[]
  languages: ProperGenreOrLanguage[]
  start_date: Date | string
  moods?: Array<Mood>
  playlistsCount?: number
  curatorsCount?: number
  minPlaylistsCount?: number
  maxPlaylistsCount?: number
  minCuratorsCount?: number
  maxCuratorsCount?: number
  is_feed: boolean
  price: number
  track: {
    title: string
    author: string
    cover: string
  }
  payment?: unknown
  trackDuration?: number
}

export interface CampaignStatusResponse {
  campaign: CampaignToSchedule
  payment: Payment
}
export interface Currency {
  name: string
  rate: number
}

export type ArtistFeedback = 'thank' | 'offensive' | 'poor'

export enum TransactionTypeEnum {
  USED = 'used',
  RECEIVED = 'received',
  CHARGEBACK = 'chargeback',
  PAYMENT = 'payment',
  BONUS = 'bonus',
  REFERRAL_BONUS = 'referral_bonus',
  INVITED_BONUS = 'invited_bonus',
  SUBSCRIPTION = 'subscription',
  CASHBACK = 'cashback',
  SUBSCRIPTION_PAYMENT_FAILED = 'subscription_payment_failed',
  QUICK_CHECKOUT_BONUS_FUTURE = 'quick_checkout_bonus_future_payment',
  QUICK_CHECKOUT_BONUS_CURRENT = 'quick_checkout_bonus_current_payment',
}

export interface GetGtmInfoInterface {
  campaignType: string
  campaignId: number
  genresList: [string]
  languagesList: [string]
  transactionId: string
  value: number
  resultPrice: number
  campaignNumber: number
  purchaseType: string
  isFeed?: boolean
  isDanceRequired?: boolean
}

export interface Payment {
  id: number
  transaction_id: string
  paid_amount: string
  created_at: string
  balance_used: string
  status: PaymentStatusEnum
  campaigns_full_price: string
  type: PaymentMethodEnum
  confirmed_at: string
  charged_back_amount: string | null
  charged_back_at: string | null
  updated_at: string
  user_id: number
}

export enum TikTokCampaignPricingModelEnum {
  FollowersBased = 1,
  ViewsBased = 2,
}

export interface CampaignVideo {
  id: number
  createdAt: string
  updatedAt: string
  status: VideoStatus
  tiktokCampaignId: number
  videoLink: string
  videoUploadDate: string | Date
  nickname: string
  rate: number
  stars: number
  followerCount: number
  userPhotoUrl?: string
  tiktokProfileUrl: string
  feedback: string
  viewsCount?: number
  deadlineToLoadVideo?: string
  reward?: number
  views: number
  isConfirmed: boolean
  isAvailable: boolean
  preModerationDate: string
  minViewsDeadline: string
  minViews: number
  pricingModelVersion: TikTokCampaignPricingModelEnum
  dismissReason: EventDismissReasonsEnum
}

export interface MobileBalanceItems {
  id?: number | string
  date: string | Date
  type: string
  description: { title: string; description: string | undefined }
  amount: { amount: number; type: string }
}

export interface TikTokUpdateCampaign {
  id: number
  comment?: string
  languages?: Array<number>
  genres?: Array<number>
  startDate?: string | Date
}

export interface CampaignsFilters {
  isSpotify: boolean
  isTikTok: boolean
}

export enum VideoUploadError {
  NOT_OWNER_TIKTOK_LINK = 'not-owner-tiktok-link',
  VIDEO_NOT_AVAILABLE = 'video-not-available',
  NOT_TIKTOK_VIDEO_LINK = 'not-tiktok-video-link',
  NOT_TIKTOK_LINK = 'not-tiktok-link',
  INCORRECT_TRACK_IN_VIDEO = 'incorrect-track-in-video',
  SMALL_VIDEO_DURATION = 'small-video-duration',
  CAMPAIGN_DOES_NOT_EXIST = 'event-for-this-influencer-does-not-exists',
}

export interface Profile {
  tiktokId: string
  shortId: string
  uniqueId: string
  nickname: string
  avatar: string
  createTime: string
  followerCount: number
  followingCount: number
  heartCount: number
  videoCount: number
}

export enum InfluencerCampaignStatus {
  AWAITING_REVIEW = 'awaiting_review',
  PENDING = 'pending',
  SCHEDULED = 'scheduled',
  RUNNING = 'running',
  IN_PROCESS = 'in_process',
  ENDED = 'ended',
  CANCELED = 'canceled',
  FINISHED = 'finished',
  STOPPED = 'stopped',
  DECLINED = 'declined',
}

export enum InfluencerEventStatus {
  NEW = 'new',
  ACCEPTED = 'accepted',
  AWAITING_REVIEW = 'awaiting_review',
  AWAITING_MODERATOR_REVIEW = 'awaiting_moderator_review',
  APPROVED_BY_ARTIST = 'approved_by_artist',
  APPROVED_AUTOMATICALLY = 'approved_automatically',
  APPROVED_BY_ADMIN = 'approved_by_admin',
  CANCELED_BY_ARTIST = 'canceled_by_artist',
  CAMPAIGN_STOPED = 'campaign_stopped',
  DECLINED = 'declined',
  CANCELED = 'canceled',
  SUBMISSION_AWAITING = 'submission_awaiting',
  SUBMISSION_REJECTED = 'submission_rejected',
  VIDEO_DISMISSED = 'video_dismissed',
}

export interface InfluencerCampaign {
  id: number
  createdAt: string
  updatedAt: string
  trackTiktokId?: string

  deadlineToAccept?: string
  comment?: string
  trackTitle: string
  trackPlayUrl?: string
  trackCoverUrl?: string
  trackAuthorName: string
  trackUrl?: string
  trackDuration?: number
  genres?: string[]
  languages?: string[]
  status: InfluencerCampaignStatus | InfluencerEventStatus
  reward?: number
  paymentWillBeReceived?: string
  stars?: number
  deadlineToLoadVideo?: string
  isPaymentReceived?: boolean
  campaignArtistComment?: string
  isNoLongerAvailableTrack?: string
  videoUploadDate?: string | Date
  feedback?: string
}

export interface InfluencerEvent {
  id: number
  status: InfluencerEventStatus
  reward: number
  isPaymentReceived?: boolean
  campaignId: number
  trackTitle: string
  trackCoverUrl: string
  trackAuthorName: string
  uploadVideoDeadline: string
  paymentWillBeReceived: string
  deadlineToAccept: string
  trackTiktokId?: string
  isNoLongerAvailableTrack?: string
  startDate?: string
  createdAt?: string
  updatedAt: string
  deadlineToLoadVideo?: string
  videoUploadDate?: string
  stars?: number
}

export interface Pagination {
  pageNumber: number
  pageSize: number
  itemCount: number
  pageCount: number
  hasPreviousPage: boolean
  hasNextPage: boolean
}

export interface EventDetails {
  reward: number
  status: InfluencerEventStatus
  stars: number
  genres: string[]
  languages: string[]
  trackTitle: string
  trackPlayUrl: string
  trackCoverUrl: string
  trackAuthorName: string
  trackDuration: number
  trackUrl: string
  comment: string
  campaignArtistComment: string
  feedback?: string
  videoLink?: string
  tiktokVideoUrl?: string
  paymentWillBeReceived: string
  isPaymentReceived?: boolean
}

export interface CuratorStats {
  acceptanceRate: number
  missedCanceledCount: number
  missingRate: number
  receivedCount: number
  reviewRate: number
  reviewedCount: number
  trackAddedCount: number
}

export interface WiseCurrency {
  code: string
  hasBankDetails: boolean
  payInAllowed: boolean
  sampleBankDetails: null
}

export interface CreatorTransaction {
  id: number
  type: string
  payoutStatus: string
  createdAt: string
  description: string
  amount: number
}

export enum NotificationType {
  STAY_IN_PLAYLIST = 1,
  STAY_ON_TOP = 2,
  POSITIVE_REVIEW = 3,
  NEGATIVE_REVIEW = 4,
  SKIP_REVIEW = 5,
}

export interface UserNotification {
  Playlist?: {
    playlist_url?: string
    playlist_name?: string
    Curator: {
      first_name?: string
      last_name?: string
      user_photo_url?: string
    }
  }
  id?: string | number
  date: string | Date
  isNew?: boolean
  type_id?: NotificationType
  extra_score?: number
  artist_name?: string
  track_name?: string
  created_at?: string
  playlist_name?: string
  isRelease?: boolean
}

export type RejectReason = [number, string]

export type CampaignVideos = Array<CampaignVideo>
type CampaignId = string

export type Events = Record<CampaignId, CampaignVideo[]>

export interface Rate {
  rank: number
  rewardPerVideo: number
  minFollowersRequired: number
  maxFollowersRequired: number
  isUserPosition: boolean
}

export interface BalancePageFilters {
  transactionsTypeFilters: Array<TransactionTypeEnum>
  tracksIds: Array<number>
  dateRange?: Array<string>
}

export enum SharingImageDisplayModeEnum {
  square = 'square',
  full = 'full',
}

export enum PageTypeEnum {
  CreateCampaignWithoutSignup = 'createCampaignWithoutSignup',
}

export type UploadedErrorMessage = null | string | undefined

export type CustomEmbedRef = {
  open: () => void
}

export enum BonusStatus {
  INCREASED = 'balance-increased',
  OPENED = 'balance-was-not-changed',
  EXPIRED = 'link-expired',
}
export interface Meta {
  pageNumber: number
  pageSize: number
  itemCount: number
  pageCount: number
  hasPreviousPage: boolean
  hasNextPage: boolean
}

export enum RelaunchInvokePlacement {
  DETAILS = 'details',
  LIST = 'list',
}
export interface LanguageOption {
  value: number
  label: string
}
export interface GroupedOption {
  label: string
  options: LanguageOption[]
}

export interface UsersMeResponse {
  id: number
  createdAt: string
  updatedAt: string
  email: string
  role: Roles
  firstName: string
  lastName: string
  userPhotoUrl: string
  isEmailConfirmed: boolean
  lastActivity: string
  spotifyId: string
  intercomHash: string

  // influencer entity params
  metaData: {
    id: number
    createdAt: string
    updatedAt: string
    nickname: string
    followerCount: number
    rate: number
    isBlocked: boolean
    tiktokRefreshTokenExpiresIn: string
  }
}
