import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import IconDanger from 'src/Assets/Svg/Danger.svg?react'
import { playlistRequirementsLink } from 'src/Constants/links'
import { colors, device } from 'src/Styled/variables'

const IrrelevantContainer = styled.div`
  padding: 24px 48px 48px;

  @media ${device.mobileMax} {
    padding: 0 0 0 24px;
  }
`

const IrrelevantTitleRow = styled.div`
  position: relative;
  left: -24px;
  display: flex;
  align-items: center;
  margin-bottom: 18px;

  @media ${device.mobileMax} {
    align-items: flex-start;
  }
`

const IrrelevantIcon = styled.div`
  margin-right: 8px;
  width: 16px;
  height: 16px;
`
const IrrelevantTitleText = styled.span`
  color: ${colors.messegesRed};
`

const IrrelevantDescription = styled.span`
  color: ${colors.mainText};
`

const IrrelevantDescriptionUnderline = styled.a`
  color: ${colors.mainText};
  border-bottom: 1px dashed ${colors.mainText};

  &:visited {
    color: ${colors.mainText};
  }
`

export const Irrelevant: FC = () => {
  const { t } = useTranslation()
  return (
    <IrrelevantContainer>
      <IrrelevantTitleRow>
        <IrrelevantIcon>
          <IconDanger />
        </IrrelevantIcon>
        <IrrelevantTitleText>
          {t('targetGenres.noRelevantTitle')}
        </IrrelevantTitleText>
      </IrrelevantTitleRow>
      <IrrelevantDescription>
        <IrrelevantDescriptionUnderline
          href={playlistRequirementsLink}
          target='_blank'
        >
          {t('targetGenres.contactSupport')}
        </IrrelevantDescriptionUnderline>
        {t('targetGenres.toSolveThisIssue')}
      </IrrelevantDescription>
    </IrrelevantContainer>
  )
}
