import styled from 'styled-components'
import { Modal as MobileModal } from 'antd'

import { colors } from 'src/Styled/variables'

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  & > div {
    width: fit-content;
    button {
      padding: 0 40px !important;
    }
  }
`

export const Modal = styled(MobileModal)`
  position: fixed;
  top: 0 !important;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0;
  margin: 0;
  width: 100% !important;
  max-width: 100% !important;

  .ant-modal-content {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: ${colors.white};
    border-radius: 8px;

    .ant-modal-close {
      display: none;
    }

    .ant-modal-body {
      padding: 20px;
      width: 100%;
      border-radius: 0 0 8px 8px;
    }
    .ant-modal-footer {
      display: none;
    }
  }
`
