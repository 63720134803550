import { FunctionComponent } from 'react'

import { PageTitle } from './components/PageTitle'
import { ErrorBlock } from './components/ErrorBlock'
import { MainInfo } from './components/MainInfo'

import { Container, ReferAndEarnContainer } from './styles'

export const ReferAndEarnPage: FunctionComponent = () => (
  <ReferAndEarnContainer>
    <Container>
      <PageTitle />
      <ErrorBlock />
      <MainInfo />
    </Container>
  </ReferAndEarnContainer>
)
