import { ReactNode, FC } from 'react'

import { Tooltip as AntdTooltip } from 'antd'

import { GlobalStyles, StyledTooltip } from './styles'

export interface TooltipProps {
  children: ReactNode
  content: ReactNode
}

export const Tooltip: FC<TooltipProps> = ({ children, content }) => (
  <>
    <StyledTooltip>
      <AntdTooltip trigger={'hover'} overlay={content}>
        {children}
      </AntdTooltip>
    </StyledTooltip>
    <GlobalStyles />
  </>
)
