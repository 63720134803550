import React, { ReactElement } from 'react'

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import WarningIcon from 'src/Assets/Svg/warning-circle.svg?react'
import { colors, variables } from 'src/Styled/variables'
import Button from 'src/Components/Buttons/Button'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import { CrossButton } from 'src/Components/Buttons/CrossButton'
import { useLazyNewSoundsQuery } from 'src/Redux/creatorNewSoundsApi'
import { useAppSelector } from 'src/Hooks/redux'
import { getInfluencerId } from 'src/Redux/auth-process/userSlice/selectors'

export const Container = styled.div`
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${colors.white};
  padding: 124px 16px 40px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ContentWrapper = styled.div`
  display: flex;
  width: 560px;
  max-width: 560px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
`

export const Title = styled.h2`
  margin: 0;
  padding: 0;
  color: ${colors.mainText};
  font-size: ${variables.fontSize18};
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
`

export const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  text-align: center;
`
export const GoToSoundsButton = styled(Button)`
  width: 180px;
`
export const CloseCrossButton = styled(CrossButton)`
  position: absolute;
  top: 76px;
  right: 16px;
`
export const ErrorModal = (): ReactElement | null => {
  const { t } = useTranslation()
  const { search, setSearch } = useCustomSearchParams()
  const influencerId = useAppSelector(getInfluencerId)
  const [getNewSounds] = useLazyNewSoundsQuery()

  const isModalVisible = search.get('errorModalVisible') === 'true'

  const onGoToSoundsButtonClick = (): void => {
    setSearch({ errorModalVisible: null })
    getNewSounds({
      creatorId: influencerId,
      pageNumber: 1,
    })
  }

  if (!isModalVisible) {
    return null
  }

  return (
    <Container>
      <ContentWrapper>
        <WarningIcon width={40} height={40} />

        <DescriptionWrapper>
          <Title>{t('soundsPage.oopsTitle')}</Title>
          <span>{t('soundsPage.oopsDescription')}</span>
        </DescriptionWrapper>

        <GoToSoundsButton type={'white'} onClick={onGoToSoundsButtonClick}>
          {t('soundsPage.goToSoundsButtonText')}
        </GoToSoundsButton>
      </ContentWrapper>

      <CloseCrossButton onClick={onGoToSoundsButtonClick} />
    </Container>
  )
}
