import { FC } from 'react'

import { useTranslation } from 'react-i18next'

import AddPlaylistModal from '../AddPlaylistModal'

import {
  PlayListComponentEmptyPageContainer,
  PlayListEmptyPageContainer,
  PlayListEmptyPageText,
} from './styles'

const PlayListComponentEmptyPage: FC = () => {
  const { t } = useTranslation()

  return (
    <PlayListComponentEmptyPageContainer>
      <PlayListEmptyPageContainer>
        <PlayListEmptyPageText>
          <div>{t('playlistsPage.emptyPlaylists')}</div>
          <div>{t('playlistsPage.getStarted')}</div>
        </PlayListEmptyPageText>

        <AddPlaylistModal />
      </PlayListEmptyPageContainer>
    </PlayListComponentEmptyPageContainer>
  )
}

export default PlayListComponentEmptyPage
