import React from 'react'

import { StyledHintSign, StyledHintSignInner } from './Hint.styled'

export interface HintProps {
  children: React.ReactNode
  onClick?: (e?: React.MouseEvent) => void
}

export const Hint: React.FC<HintProps> = ({ children, ...props }) => (
  <StyledHintSign {...props}>
    <StyledHintSignInner>{children}</StyledHintSignInner>
  </StyledHintSign>
)
