import React, { FC } from 'react'

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import SecureIcon from 'src/Assets/Svg/pages-icons/Secure.svg?react'

const ProtectedContainer = styled.div`
  display: flex;
  max-width: 400px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  background: ${({ theme }) => theme.common.primaryBg};
`
const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
`

const TextTitle = styled.div`
  color: ${({ theme }) => theme.common.primaryText};
  font-family: 'Segoe UI';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
`

const Text = styled(TextTitle)`
  font-weight: 400;
`
export const ArtistProtectionProgram: FC = () => {
  const { t } = useTranslation()
  return (
    <ProtectedContainer>
      <SecureIcon />
      <TextContainer>
        <TextTitle>{t('scheduleSummary.protectionTitle')}</TextTitle>
        <Text>{t('scheduleSummary.protectionText')}</Text>
      </TextContainer>
    </ProtectedContainer>
  )
}
