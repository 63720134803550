import { useEffect } from 'react'

import { useFeatureValue } from '@growthbook/growthbook-react'

import {
  useAddFeatureForUserMutation,
  useGetUserFeaturesQuery,
} from 'src/Redux/A-BFeaturesApi'

const TIKTOK_MIN_BUDGET_FEATURE_NAME = 'tiktok_min_budget_decrease'
const TIKTOK_MIN_BUDGET_BE_FEATURE_NAME = 'tiktok_campaign_min_budget_group'

export const useTikTokCampaignMinBudgetExperiment = (): null => {
  const [addFeatureForUser] = useAddFeatureForUserMutation()
  const variant = useFeatureValue(TIKTOK_MIN_BUDGET_FEATURE_NAME, 0)

  const { data: featureValue, isSuccess: featuresIsSucceeded } =
    useGetUserFeaturesQuery(null, {
      selectFromResult: (result) => ({
        ...result,
        data: result?.data?.features?.find(
          (item) => item?.name === TIKTOK_MIN_BUDGET_BE_FEATURE_NAME,
        )?.variation,
      }),
    })

  useEffect(() => {
    const isFeatureNeeded = featuresIsSucceeded && !featureValue && variant
    if (isFeatureNeeded) {
      addFeatureForUser({
        featureName: TIKTOK_MIN_BUDGET_BE_FEATURE_NAME,
        variation: Number(variant),
      })
    }
  }, [addFeatureForUser, featureValue, featuresIsSucceeded, variant])

  return null
}
