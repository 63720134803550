import React from 'react'

import { Button as AntButton } from 'antd'
import { useDebouncedCallback } from 'use-debounce'

import { CloseIconGrey } from 'src/Assets/Svg'

import { StyledButton } from './styles'
interface CloseRoundedButtonProps {
  disabled?: boolean
  onClick?: () => void
}

export const CloseRoundedButton: React.FC<CloseRoundedButtonProps> = ({
  onClick,
  disabled,
}) => {
  const debouncedOnClick = useDebouncedCallback(
    () => {
      onClick && onClick()
    },
    0,
    {
      trailing: true,
    },
  )

  return (
    <StyledButton>
      <AntButton
        data-testid='close-rounded-button'
        disabled={disabled || false}
        onClick={debouncedOnClick}
      >
        <CloseIconGrey />
      </AntButton>
    </StyledButton>
  )
}
