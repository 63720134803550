import { FC } from 'react'

import { useTranslation } from 'react-i18next'

import Alert from 'src/Components/Alert'
import { ErrorCode } from 'src/Redux/types'
import { useSetCampaignVideoRateMutation } from 'src/Redux/campaignVideoApi'

interface CampaignVideoRateError {
  status: number
}

export const TiktokRateError: FC = () => {
  const [_, { error, isError }] = useSetCampaignVideoRateMutation({
    fixedCacheKey: 'setCampaignVideoRate',
  })
  const { t } = useTranslation()

  const errorStatus = (error as CampaignVideoRateError)?.status

  if (errorStatus === ErrorCode.Conflict) {
    return (
      <Alert smallGap color='danger' icon unclosable>
        <div>{t('campaignResultsPage.videoAlreadyRated')}</div>
      </Alert>
    )
  }
  if (isError) {
    return (
      <Alert smallGap color='danger' icon unclosable>
        <div>{t('campaignResultsPage.oops')}</div>
      </Alert>
    )
  }
  return null
}
