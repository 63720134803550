import { createDraftSafeSelector } from '@reduxjs/toolkit'

import { RootState } from '..'

import { FeedCampaignsState } from '.'

const getState = (state: RootState): FeedCampaignsState => state.feed

export const getFeedCampaignsLoading = createDraftSafeSelector(
  getState,
  ({ loading }) => loading,
)

export const getAwaitingTracksSelector = createDraftSafeSelector(
  getState,
  ({ tracks }) => tracks,
)

export const getReviewedTracksSelector = createDraftSafeSelector(
  getState,
  ({ reviewedTracks }) => reviewedTracks,
)

export const getTotalAwaitingTracksSelector = createDraftSafeSelector(
  getState,
  ({ totalFeed }) => totalFeed,
)

export const getTotalReviewedTracksSelector = createDraftSafeSelector(
  getState,
  ({ totalReviewed }) => totalReviewed,
)
export const getReviewedCurrentPageSelector = createDraftSafeSelector(
  getState,
  ({ reviewedCurrentPage }) => reviewedCurrentPage,
)

export const getIsLimitReached = createDraftSafeSelector(
  getState,
  ({ isLimitReached }) => isLimitReached,
)

export const getFeedReviewError = createDraftSafeSelector(
  getState,
  ({ error }) => error,
)
