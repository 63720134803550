import { useEffect } from 'react'

import { ScheduleSteps } from 'src/Components/ScheduleTabs'
import {
  getIsIdleTikTokCampaign,
  getScheduledTikTokCampaign,
} from 'src/Redux/schedule-process/tiktokCampaign/selectors'
import { useAppSelector } from 'src/Hooks/redux'
import { getTiktokPrice } from 'src/Redux/schedule-process/audienceTiktokSlice/selectors'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'

export const useCheckTikTokCheckoutStepIsAllowed = (): void => {
  const { search, setSearch } = useCustomSearchParams()

  const step = search.get('step')
  const paymentStatus = search.get('paymentStatus')
  const successPaymentStatus = paymentStatus === 'success'

  const scheduledTikTokCampaign = useAppSelector(getScheduledTikTokCampaign)
  const price = useAppSelector(getTiktokPrice)
  const isIdleTikTokCampaign = useAppSelector(getIsIdleTikTokCampaign)

  useEffect(() => {
    const isSpotifyCampaignIsNotValid =
      (!scheduledTikTokCampaign?.id || scheduledTikTokCampaign?.id === 0) &&
      price === 0

    if (
      step === ScheduleSteps.CHECKOUT &&
      isSpotifyCampaignIsNotValid &&
      !successPaymentStatus &&
      !isIdleTikTokCampaign
    ) {
      setSearch({ step: ScheduleSteps.SETTINGS })
    }
  }, [
    successPaymentStatus,
    price,
    step,
    setSearch,
    scheduledTikTokCampaign?.id,
    isIdleTikTokCampaign,
  ])
}
