import React, { FC } from 'react'

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

export const Countdown = styled.span`
  color: ${({ theme }) => theme.common.dangerText};
  font-family: 'Segoe UI';
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
`

export const Container = styled.div`
  position: relative;
  margin-top: 8px;
  background-color: ${({ theme }) => theme.common.secondaryBg};
  padding: 24px;
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  color: ${({ theme }) => theme.common.fourthText};
  font-family: 'Segoe UI';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  @media screen and (max-width: 768px) {
    margin: 16px;
  }
`
export const Arrow = styled.span`
  position: absolute;
  right: calc(50% - 9px);
  top: unset;
  bottom: -8px;
  transform: rotate(180deg);
  width: 0;
  height: 0;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 9px solid ${({ theme }) => theme.common.secondaryBg};
`

type Props = {
  countdown: string
}

export const ScheduleSummaryButtonBonusTooltip: FC<Props> = ({ countdown }) => {
  const { t } = useTranslation()
  return (
    <Container>
      <Countdown>{countdown}</Countdown>
      <span>{t('scheduleCampaignPage.offerDescriptionBonus20Next')}</span>
      <Arrow />
    </Container>
  )
}
