import { FC } from 'react'

import { useTranslation } from 'react-i18next'

import { TiktokCampaignStatus } from 'src/Types'

import { TikTokDetailsCampaignStatus } from './styles'

interface DetailsCampaignStatusProps {
  campaignStatus: string
}

const getTikTokCampaignStatusBadge = (
  campaignStatus: string,
): { title: string } => {
  switch (campaignStatus) {
    case TiktokCampaignStatus.RUNNING:
    case TiktokCampaignStatus.IN_PROCESS:
      return { title: 'campaignsPage.running' }
    case TiktokCampaignStatus.ENDED:
    case TiktokCampaignStatus.FINISHED:
      return { title: 'campaignsPage.ended' }
    case TiktokCampaignStatus.STOPPED:
    case TiktokCampaignStatus.STOPPED_BY_ADMIN:
      return { title: 'campaignsPage.stopped' }
    default:
      return {
        title: 'campaignsPage.unknownStatus',
      }
  }
}

export const DetailsCampaignStatus: FC<DetailsCampaignStatusProps> = ({
  campaignStatus,
}) => {
  const { t } = useTranslation()

  const { title } = getTikTokCampaignStatusBadge(campaignStatus)

  return (
    <TikTokDetailsCampaignStatus status={campaignStatus}>
      {t(title)}
    </TikTokDetailsCampaignStatus>
  )
}

export { getTikTokCampaignStatusBadge }
