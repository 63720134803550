import { RefObject, useEffect } from 'react'

interface OutsideToutch<T> {
  callback: () => void
  ref: RefObject<T>
}
export const useOutsideTouchHandle = <T extends HTMLElement>({
  ref,
  callback,
}: OutsideToutch<T>): null => {
  useEffect(() => {
    function handleToutchOutside(event: TouchEvent): void {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback()
      }
    }
    // Bind the event listener
    document.addEventListener('touchstart', handleToutchOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('touchstart', handleToutchOutside)
    }
  }, [callback, ref])
  return null
}
