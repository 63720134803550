import { FC } from 'react'

import { Desktop } from 'src/Components/Responsive'
import IconSpotify from 'src/Assets/Svg/spotify.svg?react'
import { useSpotifyCampaignDetails } from 'src/Redux/campaignDetailsApi/useSpotifyCampaignDetails'

import { ProgressBarByBudget } from '../../ProgressBarByBudget'
import CampaignGenres from '../../CampaignGenres'
import { CampaignCalculation } from '../CampaignCalculation'
import CampaignLanguages from '../../CampaignLanguages'

import {
  GenresAndLanguagesContainer,
  SpotifyIconContainer,
  TrackAuthor,
  TrackAvatar,
  TrackInfoContainer,
  TrackInfoHeader,
  TrackTitle,
  TrackTitleContainer,
} from './styles'

export const CampaignDesktopHeader: FC = () => {
  const { campaignDetails } = useSpotifyCampaignDetails()
  return (
    <Desktop>
      <TrackInfoContainer>
        <TrackInfoHeader>
          <SpotifyIconContainer>
            <IconSpotify />
          </SpotifyIconContainer>
          <TrackAvatar
            src={campaignDetails?.Track.track_image}
            alt={campaignDetails?.Track.track_name}
          />
          <TrackTitleContainer>
            <TrackTitle>{campaignDetails?.Track.track_name}</TrackTitle>
            <TrackAuthor>{campaignDetails?.Track.artist_name}</TrackAuthor>
          </TrackTitleContainer>
          <GenresAndLanguagesContainer>
            <CampaignGenres genres={campaignDetails?.Track?.TrackGenres} />
            <CampaignLanguages
              languages={campaignDetails?.Track.TrackLanguages}
            />
          </GenresAndLanguagesContainer>
          <div>
            {campaignDetails && (
              <ProgressBarByBudget campaignDetails={campaignDetails} />
            )}
          </div>
        </TrackInfoHeader>
        <CampaignCalculation />
      </TrackInfoContainer>
    </Desktop>
  )
}
