import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { useAppSelector } from 'src/Hooks/redux'
import Alert from 'src/Components/Alert'
import { getIsShowLangPreferenceAlert } from 'src/Redux/playlists-process/selectors'
import { colors, variables } from 'src/Styled/variables'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'

export const AlertText = styled.div`
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
`
export const AlertTitle = styled.div`
  font-weight: ${variables.fontWeight600};
`
export const AlertButton = styled.button`
  cursor: pointer;
  background-color: transparent;
  color: ${colors.messegesYellow1};
  font-weight: ${variables.fontWeight600};
  padding-left: 0;
  cursor: pointer;
  &:hover,
  &:active,
  &:visited {
    color: ${colors.messegesYellow1};
  }
`
export const LanguagePreferenceAlert: FC = () => {
  const { t } = useTranslation()
  const { setSearch } = useCustomSearchParams()
  const showAlert = useAppSelector(getIsShowLangPreferenceAlert)
  if (!showAlert) {
    return null
  }
  const handleOpen = (): void => {
    setSearch({ langModal: 'true' })
  }
  return (
    <Alert color='warning' icon unclosable>
      <AlertText>
        <AlertTitle>{t('reviewsPage.vocalSetUpTitle')}</AlertTitle>
        <div>
          {t('reviewsPage.vocalSetUpText1')} <br />
          {t('reviewsPage.vocalSetUpText2')}
        </div>
      </AlertText>
      <AlertButton onClick={handleOpen}>
        {t('reviewsPage.vocalSetUpBtn')}
      </AlertButton>
    </Alert>
  )
}
