import { useSelector } from 'react-redux'
import { useFeatureIsOn } from '@growthbook/growthbook-react'

import { Roles } from 'src/Constants/enums'
import {
  getIsAuth,
  getUserRole,
} from 'src/Redux/auth-process/credentialsSlice/selectors'

export const useNewUIExperiment = (): {
  isNewUI: boolean
  isNewUIArtist: boolean
} => {
  const isAuth = useSelector(getIsAuth)
  const role = useSelector(getUserRole)
  const isVariantB = useFeatureIsOn('new_ui_v1')

  const isNewUI = isVariantB
  const isNewUIArtist = isAuth && role === Roles.artist && isVariantB

  return { isNewUI, isNewUIArtist }
}
