import React, { FC, ReactNode } from 'react'

import CheckIcon from 'src/Assets/Svg/check-mark-green.svg?react'

import { CrossIconStyled, GenreItemStyled } from './styles'
interface Props {
  name: ReactNode
  selected?: boolean
  hasCross?: boolean
  onClick: () => void
}

export const GenreOrLanguageItem: FC<Props> = ({
  name,
  selected,
  onClick,
  hasCross,
}) => (
  <GenreItemStyled translate='no' onClick={onClick}>
    {name}
    {hasCross && <CrossIconStyled role='cross-icon' />}
    {!hasCross && selected && <CheckIcon role='check-icon' />}
  </GenreItemStyled>
)
