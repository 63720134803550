import { FunctionComponent } from 'react'

import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import Button from 'src/Components/Buttons/Button'
import { getIsLoadingTikTokVideo } from 'src/Redux/influencer-process/selectors'

import { ButtonWrapper } from '../styles'

import { TimeLeft } from './styles'

type UploadButtonProps = {
  disabled: boolean
  timeLeft: string
  showButtons: boolean
}

export const UploadButton: FunctionComponent<UploadButtonProps> = ({
  timeLeft,
  disabled,
  showButtons,
}) => {
  const { t } = useTranslation()
  const isLoadingVideoTikTok = useSelector(getIsLoadingTikTokVideo)

  if (!showButtons) {
    return null
  }

  return (
    <ButtonWrapper>
      <Button
        htmlType='submit'
        type='green'
        disabled={isLoadingVideoTikTok || disabled}
        loading={isLoadingVideoTikTok}
      >
        {t('influencer.uploadVideo')}
      </Button>
      <TimeLeft>{timeLeft}</TimeLeft>
    </ButtonWrapper>
  )
}
