import { FC, PropsWithChildren, useEffect } from 'react'

import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react'
import TagManager from 'react-gtm-module'
import { useSelector } from 'react-redux'

import { APP } from 'src/Configs/App'
import { getUserId } from 'src/Redux/auth-process/userSlice/selectors'
import { api } from 'src/Services/api'
import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { getUserCountry } from 'src/Redux/auth-process/countrySlice/api-actions'
import { getCountry } from 'src/Redux/auth-process/countrySlice/selectors'

const key = 'sc_gb_sessionId'
const generateSessionId = (): string => {
  const { localStorage } = window
  const now = String(Date.now())
  const session = localStorage.getItem(key)
  if (session) {
    return session
  }
  localStorage.setItem(key, now)
  return now
}

const getUserID = (): number | null => {
  const id = api.store?.getState().authentication.user.user.id
  if (!id) {
    return null
  }
  return id as number
}

const growthBook = new GrowthBook({
  apiHost: APP.GROWTHBOOK_API_HOST,
  clientKey: APP.GROWTHBOOK_CLIENT_KEY,
  enableDevMode: APP.ENABLED_DEV_MODE_GROWTHBOOK,
  trackingCallback: (experiment, result) => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'first_ab_test',
        experiment_id: experiment.key,
        variation_id: result.variationId,
        // userId from localStorage
        user_id: getUserID(),
      },
    })
  },
})

export const GrowthProvider: FC<PropsWithChildren> = ({ children }) => {
  const userId = useSelector(getUserId)
  const dispatch = useAppDispatch()
  const country = useAppSelector(getCountry)

  useEffect(() => {
    void growthBook.init()
  }, [])

  useEffect(() => {
    void dispatch(getUserCountry())
  }, [dispatch])

  useEffect(() => {
    void growthBook.setAttributes({
      id: userId || generateSessionId(),
      country,
    })
  }, [userId, country])
  return (
    <GrowthBookProvider growthbook={growthBook}>{children}</GrowthBookProvider>
  )
}
