import styled from 'styled-components'

export const SettingsContinueButtonWrapper = styled.div`
  display: flex;
  padding: 0px;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  width: 137px;
  margin: 0 auto;
  margin-top: 24px;
  div {
    .ant-btn.ant-btn-default {
      padding: 0px 40px;
    }
  }
`
