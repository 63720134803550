import { FC } from 'react'

import { useTranslation } from 'react-i18next'

import { MatchedPlaylist } from 'src/Types'

import { renderGenresLanguages } from '../renderGenresLanguages'
import {
  MatchesAndResultCol,
  ReviewMainSectionLabel,
  MatchesAndResultRow,
  ReviewSubSectionLabel,
  ReviewSubSectionText,
} from '../styles'

interface MatchedPlaylistsProps {
  matched?: MatchedPlaylist[]
}

export const MatchedPlaylists: FC<MatchedPlaylistsProps> = ({ matched }) => {
  const { t } = useTranslation()
  if (!matched?.length) {
    return null
  }
  return (
    <>
      {matched.map((el) => (
        <MatchesAndResultCol key={el.id}>
          <ReviewMainSectionLabel>
            {t('campaignResultsPage.trackMatches')}
          </ReviewMainSectionLabel>
          <MatchesAndResultRow>
            <ReviewSubSectionLabel short>
              {`${t('campaignResultsPage.playlist')}:`}
            </ReviewSubSectionLabel>
            <ReviewSubSectionText>{el.playlist_name}</ReviewSubSectionText>
          </MatchesAndResultRow>
          <MatchesAndResultRow>
            <ReviewSubSectionLabel short>
              {`${t('campaignResultsPage.genres')}:`}
            </ReviewSubSectionLabel>
            <ReviewSubSectionText>
              {renderGenresLanguages(el.genres)}
            </ReviewSubSectionText>
          </MatchesAndResultRow>
          <MatchesAndResultRow>
            <ReviewSubSectionLabel short>
              {`${t('campaignResultsPage.vocal')}:`}
            </ReviewSubSectionLabel>
            <ReviewSubSectionText>
              {renderGenresLanguages(el.languages)}
            </ReviewSubSectionText>
          </MatchesAndResultRow>
        </MatchesAndResultCol>
      ))}
    </>
  )
}
