import { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import Alert from 'src/Components/Alert'
import { getReferralHashError } from 'src/Redux/referral-process/referralHash'
import { getReferralError } from 'src/Redux/referral-process/referralList/selectors'

export const ErrorBlock: FunctionComponent = () => {
  const { t } = useTranslation()

  const referralListError = useSelector(getReferralError)
  const referralHashError = useSelector(getReferralHashError)

  if (!referralListError && !referralHashError) {
    return null
  }

  return (
    <Alert color='danger' icon unclosable>
      <div>
        <span>{t('referAndEarn.oops')}</span>
      </div>
    </Alert>
  )
}
