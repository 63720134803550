import { FC, useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Select } from 'antd'

import TransferwiseIcon from 'src/Assets/Svg/transferwise.svg?react'
import {
  getCurrenciesList,
  getRecipient,
  getRequirements,
} from 'src/Redux/wise-process/selectors'
import { getTransferwiseAccountRequirements } from 'src/Redux/wise-process/api-actions'
import { useAppDispatch } from 'src/Hooks/redux'

import { setCurrenciesOptions } from '../helpers'

import {
  SelectCurrency,
  FormGroupField,
  FormGroupLabel,
  Form,
  FormGroup,
} from './styles'

interface CurrencyFormProps {
  setCurrentCurrency: (value: string) => void
}

export const CurrencyForm: FC<CurrencyFormProps> = (props) => {
  const { setCurrentCurrency } = props
  const dispatch = useAppDispatch()
  const currencies = useSelector(getCurrenciesList)
  const recipient = useSelector(getRecipient)
  const requirements = useSelector(getRequirements)
  const { t } = useTranslation()
  const [value, setValue] = useState<string>()

  useEffect(() => {
    if (recipient?.currency) {
      setValue(recipient.currency)
    }
  }, [recipient?.currency])

  const handleChangeCurrency = (val: string): void => {
    setValue(val)
    setCurrentCurrency(val)
    void dispatch(getTransferwiseAccountRequirements(val))
  }
  return (
    <Form>
      <p>{t('settingsPage.selectCurrencyDesc')}</p>
      <SelectCurrency>
        <FormGroup>
          <FormGroupLabel>{t('settingsPage.currency')}</FormGroupLabel>
          <FormGroupField>
            <Select
              size='large'
              showSearch
              value={value}
              options={setCurrenciesOptions(currencies)}
              placeholder={t('settingsPage.selectCurrency')}
              onChange={handleChangeCurrency}
            />
          </FormGroupField>
        </FormGroup>
        <TransferwiseIcon width={150} height={62} />
      </SelectCurrency>
      {Boolean(requirements?.length) && (
        <p>{t('settingsPage.inOrderToComply')}</p>
      )}
    </Form>
  )
}
