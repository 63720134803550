import React, { FC, PropsWithChildren, ReactNode, useState } from 'react'

import styled, { useTheme } from 'styled-components'

import ExclamationIcon from 'src/Assets/Svg/pages-icons/Exclamation.svg?react'
import ErrorIcon from 'src/Assets/Svg/pages-icons/Error.svg?react'
import SuccessIcon from 'src/Assets/Svg/pages-icons/Success.svg?react'
import CloseIcon from 'src/Assets/Svg/pages-icons/Close.svg?react'
type AlertType = 'success' | 'danger' | 'warning'

type RenderButtonProps = {
  onClose: () => void
}

export type PrimaryAlertProps = PropsWithChildren & {
  type: AlertType
  hasCross?: boolean
  className?: string
  title?: string
  message?: string
  renderButtons?: (props: RenderButtonProps) => ReactNode
}

const Container = styled.div<{ $borderColor: string }>`
  display: flex;
  position: relative;
  padding: 24px;
  align-items: flex-start;
  gap: 16px;
  border-radius: 16px;
  border: 1px solid ${({ $borderColor }) => $borderColor};
  background: ${({ theme }) => theme.common.primaryBg};
  width: 100%;
`

const IconBlock = styled.div`
  display: flex;
  padding-top: 4px;
  align-items: flex-start;
  gap: 10px;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
`

const Title = styled.div`
  color: ${({ theme }) => theme.common.primaryText};
  font-family: 'Segoe UI';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
`
const Message = styled(Title)`
  font-weight: 400;
`

const CloseButton = styled.button`
  position: absolute;
  top: 24px;
  right: 24px;
  display: flex;
  height: 40px;
  width: 40px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 40px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.common.primaryBorder};
  }
`

const StyledCloseIcon = styled(CloseIcon)`
  fill: ${({ theme }) => theme.common.primaryText};
  width: 16px;
  height: 16px;
`

const StyledSuccessIcon = styled(SuccessIcon)`
  width: 16px;
  height: 16px;
`

const MAP_TYPE_ICON = {
  success: <StyledSuccessIcon />,
  warning: <ExclamationIcon />,
  danger: <ErrorIcon />,
}

export const PrimaryAlert: FC<PrimaryAlertProps> = ({
  type,
  title,
  message,
  hasCross = true,
  className,
  renderButtons,
}) => {
  const theme = useTheme()
  const [visible, setVisible] = useState(true)
  if (!visible) {
    return null
  }

  const MAP_TYPE_TO_COLOR = {
    success: theme.alert.success,
    danger: theme.alert.danger,
    warning: theme.alert.warning,
  }

  const handleClose = (): void => {
    setVisible(false)
  }
  return (
    <Container $borderColor={MAP_TYPE_TO_COLOR[type]} className={className}>
      <IconBlock>{MAP_TYPE_ICON[type]}</IconBlock>
      <Content>
        {title && <Title>{title}</Title>}
        {message && <Message>{message}</Message>}
        {renderButtons?.({ onClose: handleClose })}
      </Content>
      {hasCross && (
        <CloseButton onClick={handleClose}>
          <StyledCloseIcon />
        </CloseButton>
      )}
    </Container>
  )
}
