import { FC, useEffect, useState } from 'react'

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Dayjs } from 'dayjs'

import dayjs from 'src/Helpers/dayjs'
import { MAX_MOBILE_WIDTH } from 'src/Styled/variables'
import { formatDate } from 'src/Constants/formatDate'

import { PrimaryDottedButton } from '../Buttons/PrimaryDottedButton'
import { PrimaryDatePicker } from '../PrimaryDatePicker'

import { TimeSaveButton } from './TimeSaveButton'

import { ScheduleSettingsHeaderProps } from '.'

const TimeContainer = styled.div`
  display: flex;
  padding-left: 84px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  @media screen and (min-width: ${MAX_MOBILE_WIDTH}) {
    padding-left: 0px;
    align-items: flex-end;
  }
`
const DateText = styled.div`
  color: ${(props) => props.theme.common.primaryText};
  font-family: 'Segoe UI';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  white-space: nowrap;
`

const StyledPrimaryDottedButton = styled(PrimaryDottedButton)`
  padding: 0;
`

const Backdrop = styled.div<{ $visible: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: ${({ $visible }) => ($visible ? 'block' : 'none')};
  z-index: 999;
`

const Container = styled.div`
  position: relative;
  height: 0;
  z-index: 1000;
`
const MIN_DATE = dayjs().startOf('month')
const MAX_DATE = dayjs().add(1, 'month')
type Props = Pick<
  ScheduleSettingsHeaderProps,
  'startDate' | 'onChangeDate' | 'todayEnable'
>

export const ScheduleSettingsHeaderTime: FC<Props> = (props) => {
  const { startDate, todayEnable, onChangeDate } = props
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [value, setValue] = useState<Dayjs>(dayjs(startDate))

  const displayedStartDate = startDate.format(formatDate.formatDateMonthFull)

  useEffect(() => {
    setValue(dayjs(startDate))
  }, [startDate])

  const disabledStart = todayEnable ? dayjs().subtract(1, 'day') : dayjs()
  return (
    <TimeContainer>
      <Backdrop $visible={open} />
      <Container>
        <PrimaryDatePicker
          mode='date'
          needConfirm={true}
          minDate={MIN_DATE}
          maxDate={MAX_DATE}
          getPopupContainer={() => document.body}
          onOpenChange={(isOpen) => setOpen(isOpen)}
          onChange={(currentDate) => setValue(dayjs(currentDate))}
          open={open}
          style={{ visibility: 'hidden', width: 0, height: 0, padding: 0 }}
          disabledDate={(current) =>
            disabledStart >= current || MAX_DATE <= current
          }
          showNow={false}
          hideDisabledOptions
          defaultPickerValue={value}
          value={value}
          onOk={(data) => {
            onChangeDate(data)
          }}
          components={{
            button: TimeSaveButton,
          }}
        />
      </Container>
      <DateText>{`${t('scheduleCampaignPage.startDate')} ${displayedStartDate}`}</DateText>
      <StyledPrimaryDottedButton onClick={() => setOpen(true)}>
        Edit
      </StyledPrimaryDottedButton>
    </TimeContainer>
  )
}
