import { FC, PropsWithChildren, useEffect } from 'react'

import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'

import { PageLayout } from 'src/Components/PageLayout'
import { ArtistRoutesEnum } from 'src/Router/ArtistComponentsNewUI'
import {
  getSidebarVisibility,
  hideSidebar,
  toggleSidebarVisibility,
} from 'src/Redux/pageLayout-process'
import { useAppDispatch } from 'src/Hooks/redux'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import { getNewUIPopupState, setNewUIPopupState } from 'src/Redux/alert-process'
import { getIsNewUserForNewUI } from 'src/Redux/auth-process/userSlice/selectors'

import { MAX_MOBILE_WIDTH } from '../../Styled/variables'

import { SidebarContainer } from './Components/SidebarContainer'
import { HeaderContainer } from './Components/HeaderContainer'

const routesWithoutHeader = [
  ArtistRoutesEnum.TiktokSchedule,
  ArtistRoutesEnum.SpotifySchedule,
  ArtistRoutesEnum.ChooseCreateCampaignPlatform,
]
const routesWithCollapsedSidebar = [
  ArtistRoutesEnum.TiktokSchedule,
  ArtistRoutesEnum.SpotifySchedule,
  ArtistRoutesEnum.ChooseCreateCampaignPlatform,
]

const triggerSources = ['email-reminder', 'SMS-reminder']

export const PageLayoutContainer: FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useAppDispatch()
  const { search } = useCustomSearchParams()
  const isMobile = useMediaQuery({ maxWidth: MAX_MOBILE_WIDTH })
  const { pathname } = useLocation()

  const newUiPopup = useSelector(getNewUIPopupState)
  const isNewUser = useSelector(getIsNewUserForNewUI)
  const isSidebarCollapsed = useSelector(getSidebarVisibility)

  const triggerSource = search.get('trigger_source')

  const isUserFromEmailOrSms =
    triggerSource && triggerSources.includes(triggerSource)

  const isNewPopupHidden = !newUiPopup || isUserFromEmailOrSms || isNewUser

  const isHeaderHidden = Boolean(
    routesWithoutHeader.find((route) => pathname.includes(route)),
  )

  const onSidebarToggleClick = (): void => {
    dispatch(toggleSidebarVisibility())
  }

  const handleClickNewUIPopup = (): void => {
    dispatch(setNewUIPopupState(false))
  }

  const isPageWithCollapsedSidebar = routesWithCollapsedSidebar.find((route) =>
    pathname.includes(route),
  )
  useEffect(() => {
    if (isPageWithCollapsedSidebar && !isMobile) {
      dispatch(hideSidebar())
    }
  }, [dispatch, isPageWithCollapsedSidebar, isMobile])

  return (
    <PageLayout
      Header={HeaderContainer}
      Sidebar={SidebarContainer}
      isHeaderHidden={isHeaderHidden}
      onSidebarToggleClick={onSidebarToggleClick}
      isSidebarCollapsed={isSidebarCollapsed}
      newUIPopup={!isNewPopupHidden}
      handleClickNewUIPopup={handleClickNewUIPopup}
    >
      {children}
    </PageLayout>
  )
}
