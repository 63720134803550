import styled from 'styled-components'

import { variables, colors, device } from 'src/Styled/variables'

export const StartCampaignContainer = styled.button`
  width: 100%;
  display: block;
  border-radius: 4px;
  background: ${colors.white};
  border-radius: 4px;
  padding: 15px 30px 15px 16px;
  margin-bottom: 24px;
  font-style: normal;
  font-weight: ${variables.fontWeight400};
  font-size: ${variables.fontSize20};
  line-height: 150%;
  color: ${colors.mainText};
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  cursor: pointer;
  border-bottom: 2px solid ${colors.progressActive};
  transition:
    border-bottom 0.3s ease,
    box-shadow 0.3s ease;
  display: grid;
  grid-template-columns: 1fr 9.91px;
  @media ${device.mobileMin} {
    padding: 23px 53px 23px 40px;
  }
  &:hover {
    border-bottom: 2px solid ${colors.progressActive};
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.15);
  }
`
export const StartCampaignButtonContent = styled.div`
  display: grid;
  grid-template-columns: 24px 1fr;
  grid-gap: 12px;
  align-items: center;
  text-align: left;
  &img {
    align-self: center;
    width: 24px;
    height: 24px;
  }
`

export const StyledArrow = styled.div`
  svg {
    path {
      fill: ${colors.green};
    }

    &:hover {
      path {
        fill: ${colors.green};
        transition: fill 0.3s ease;
      }
    }
  }
`
