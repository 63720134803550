import { useNavigate } from 'react-router-dom'

import { CampaignPlatformTypeEnum } from 'src/Types'
import { getTiktokPrice } from 'src/Redux/schedule-process/audienceTiktokSlice/selectors'
import { getCampaignToSchedule } from 'src/Redux/campaign-process/spotifyCampaignSchedule-process/selectors'
import { getArtistBalance } from 'src/Redux/auth-process/userSlice/selectors'
import { getDefaultStartDate } from 'src/Constants/functions'
import { getScheduledTikTokCampaign } from 'src/Redux/schedule-process/tiktokCampaign/selectors'
import { ScheduleSteps } from 'src/Components/ScheduleTabs'
import { usePayByCreditsMutation } from 'src/Redux/creditsPaymentApi'
import { PaymentErrors } from 'src/Components/PaymentErrorsAlert'
import { setPaymentAlertError } from 'src/Redux/payment-process'
import { getUserInfo } from 'src/Redux/auth-process/userSlice/api-actions'

import { usePayment } from './usePayment'
import { useAppDispatch, useAppSelector } from './redux'

interface UseCreditsPayment {
  pay: () => void
}

export const useCreditsPayment = (
  platformType: CampaignPlatformTypeEnum,
): UseCreditsPayment => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const balance = useAppSelector(getArtistBalance)
  const { calculatePaymentAmounts } = usePayment()
  const tikTokPrice = useAppSelector(getTiktokPrice)
  const scheduledTikTokCampaign = useAppSelector(getScheduledTikTokCampaign)
  const scheduledSpotifyCampaign = useAppSelector(getCampaignToSchedule)
  const [payByCredits] = usePayByCreditsMutation()

  const isTikTokPlatform = platformType === CampaignPlatformTypeEnum.TIKTOK

  const defaultStartDate = getDefaultStartDate()
  const platformUrlPart = isTikTokPlatform ? 'scheduleTikTok' : 'schedule'
  const currentStartDate = isTikTokPlatform
    ? scheduledTikTokCampaign?.startDate?.toString() || defaultStartDate
    : scheduledSpotifyCampaign?.start_date?.toString() || defaultStartDate

  const currentPrice = isTikTokPlatform
    ? tikTokPrice
    : scheduledSpotifyCampaign?.price

  const pay = (): void => {
    const currentPaymentAmounts = calculatePaymentAmounts({
      price: currentPrice,
      balance,
    })
    const currentPlatformCampaign = isTikTokPlatform
      ? scheduledTikTokCampaign
      : scheduledSpotifyCampaign

    const campaignId = Number(currentPlatformCampaign.id)

    const campaign = {
      campaignId,
      type: platformType,
      amount: currentPaymentAmounts?.balanceUseAmount || 0,
      startDate: currentStartDate,
    }

    payByCredits({ campaigns: [campaign] })
      .unwrap()
      .then((res) => {
        const checkoutStepUrl = `/campaigns/${platformUrlPart}/${campaignId}?step=${
          ScheduleSteps.CHECKOUT
        }&paymentStatus=success&startDate=${encodeURIComponent(
          currentStartDate,
        )}`
        navigate(checkoutStepUrl)
        void dispatch(getUserInfo())
        return res
      })
      .catch(() => {
        dispatch(setPaymentAlertError(PaymentErrors.DEFAULT))
      })
  }

  return {
    pay,
  }
}
