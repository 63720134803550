import styled from 'styled-components'

import { colors, device, variables } from 'src/Styled/variables'

export const SpotifySettingsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 16px;
  padding: 8px 0;
  max-width: 1120px;
`
export const Title = styled.h3`
  font-size: ${variables.fontSize18};
  font-style: normal;
  font-weight: ${variables.fontWeight600};
  line-height: 27px;
  color: ${colors.mainText};
`
export const Wrapper = styled.div`
  display: grid;
  gap: 16px;
  @media ${device.tabletMin} {
    grid-template-columns: 1fr 320px;
  }
`

export const ParamsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`
