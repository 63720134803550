import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'

import { APP } from 'src/Configs/App'
import { logOut } from 'src/Redux/auth-process/credentialsSlice'
import { clearUserState } from 'src/Redux/auth-process/userSlice'
import { NameSpace } from 'src/Redux/types'
import { api } from 'src/Services/api'

export const deleteCuratorAccount = createAsyncThunk<unknown>(
  `${NameSpace.CuratorDeleteAccount}/deleteCuratorAccount`,
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await api.delete<unknown>(`${APP.SERVER}/curator`)
      dispatch(logOut())
      dispatch(clearUserState())

      return data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      return rejectWithValue({
        message: response?.data.message,
        status: response?.status,
      })
    }
  },
)
