import { createDraftSafeSelector } from '@reduxjs/toolkit'

import { GenresState } from 'src/Redux/genres-process/index'

import { RootState } from '..'
import { SORT_NEGATIVE } from '../similarGenres-process/selectors'

const getState = (state: RootState): GenresState => state.genres

export const getIsGenresLoading = createDraftSafeSelector(
  getState,
  ({ loading }) => loading,
)

export const getGenres = createDraftSafeSelector(
  getState,
  ({ genres }) => genres,
)

export const getSortedGenres = createDraftSafeSelector(getGenres, (genres) =>
  genres?.toSorted((a, b) => {
    if (!a?.genre_name || !b?.genre_name) {
      return 0
    }
    if (a?.genre_name < b?.genre_name) {
      return SORT_NEGATIVE
    }
    if (a?.genre_name > b?.genre_name) {
      return 1
    }
    return 0
  }),
)
const SLICE_INDEX = 10

export const getAutoSelectedGenres = createDraftSafeSelector(
  getState,
  ({ autoselectedGenres }) =>
    autoselectedGenres
      ?.map((el) => ({
        id: el.id,
        genre_name: el.genreName,
      }))
      .slice(0, SLICE_INDEX),
)
