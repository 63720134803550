import { object, string as yupString } from 'yup'

import { MAX_COMMENT_LENGTH } from 'src/Constants/numeric'
export interface CommentFormValues {
  comment: string
}

export const commentSchema = object().shape({
  comment: yupString().max(MAX_COMMENT_LENGTH).required('Comment is required'),
})
