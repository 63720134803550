import { FC, useMemo } from 'react'

import styled from 'styled-components'
import { Skeleton } from 'antd'
import { SkeletonNodeProps } from 'antd/es/skeleton/Node'
import { useTranslation } from 'react-i18next'

import { MAX_MOBILE_WIDTH } from 'src/Styled/variables'

import { CampaignListHeaderActionBlock } from './CampaignListHeaderActionBlock'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  align-self: stretch;
  position: relative;
`

const Title = styled.h1`
  color: ${(props) => props.theme.common.primaryText};
  font-family: 'Segoe UI';
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  white-space: nowrap;
`
const StyledSkeleton = styled(({ className, ...rest }: SkeletonNodeProps) => (
  <Skeleton.Input {...rest} rootClassName={className} active />
))`
  &.ant-skeleton-block {
    width: 120px;
    border-radius: 16px;
  }
  & > .ant-skeleton-input {
    min-width: 120px !important;
    width: 120px !important;
    height: 48px !important;
    border-radius: 16px !important;
    @media screen and (min-width: ${MAX_MOBILE_WIDTH}) {
      width: 240px !important;
    }
  }
`

interface CampaignListHeaderProps {
  loading: boolean
}
export const CampaignListHeader: FC<CampaignListHeaderProps> = ({
  loading,
}) => {
  const { t } = useTranslation()
  const actionBlock = useMemo(() => {
    if (loading) {
      return <StyledSkeleton />
    }
    return <CampaignListHeaderActionBlock />
  }, [loading])

  return (
    <Container>
      <Title>{t('campaignsListPage.campaigns')}</Title>
      {actionBlock}
    </Container>
  )
}
