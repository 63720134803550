import React, { ReactNode } from 'react'

import { StyledCloseBtn } from './CloseBtn.styled'

export interface CloseBtnProps {
  content: ReactNode
  onClick?: (e?: React.MouseEvent) => void
}

export const CloseBtn: React.FC<CloseBtnProps> = ({ content, ...props }) => (
  <StyledCloseBtn type='button' {...props}>
    {content}
  </StyledCloseBtn>
)
