import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { creatorRegistrationStartEvent } from 'src/Helpers/TagManager'
import { InfluencerRegisterEnum } from 'src/Types'
import Button from 'src/Components/Buttons/Button'
import { colors, variables } from 'src/Styled/variables'

const LoginTitle = styled.div`
  font-size: ${variables.fontSize18};
  color: ${colors.mainText};
  line-height: 27px;
  margin-bottom: 24px;
  text-align: center;
`
const TitleAccent = styled.span`
  color: ${colors.green};
`
const Text1 = styled.div`
  font-size: ${variables.fontSize14};
  color: ${colors.mainText};
  line-height: 21px;
  display: grid;
  gap: 16px;
  margin-bottom: 24px;
`
const ButtonContainer = styled.div`
  width: 220px;
`

interface LoginCreatorProps {
  setRegisterState: (status: InfluencerRegisterEnum) => void
  status: InfluencerRegisterEnum
}

export const LoginCreator: FC<LoginCreatorProps> = ({
  setRegisterState,
  status,
}) => {
  const { t } = useTranslation()

  if (status !== InfluencerRegisterEnum.JOIN) {
    return null
  }

  return (
    <>
      <LoginTitle>
        {t('influencer.becomeA')}&nbsp;
        <TitleAccent>{t('influencer.creator')}</TitleAccent>&nbsp;
        {t('influencer.atSoundCampaign')}
      </LoginTitle>
      <Text1>
        <div>{t('influencer.getRewardsByUsingTracksInYourVideos')}</div>
        <div>{t('influencer.toJoinAsACreator')}</div>
      </Text1>

      <ButtonContainer>
        <Button
          type='green'
          onClick={() => {
            setRegisterState(InfluencerRegisterEnum.CONNECT)
            creatorRegistrationStartEvent()
          }}
        >
          {t('influencer.joinAsACreator')}
        </Button>
      </ButtonContainer>
    </>
  )
}
