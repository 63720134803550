import React, { FC } from 'react'

import { useTranslation } from 'react-i18next'

import { ReviewsOrVideos } from './styles'
interface Props {
  videosAmount?: number
}

export const TiktokReviews: FC<Props> = ({ videosAmount }) => {
  const { t } = useTranslation()
  const submittedVideo =
    videosAmount === 1
      ? t('campaignsPage.amountVideo')
      : t('campaignsPage.amountVideos')
  const submittedVideosAmount = videosAmount || 0
  return (
    <ReviewsOrVideos>
      {submittedVideosAmount}
      &nbsp;
      {submittedVideo}
    </ReviewsOrVideos>
  )
}
