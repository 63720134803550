import { useState, useEffect, useRef, FunctionComponent } from 'react'

import WaveSurfer from 'wavesurfer.js'

import PlayIcon from 'src/Assets/Svg/play.svg?react'
import PauseIcon from 'src/Assets/Svg/pause.svg?react'
import TikTokIcon from 'src/Assets/Svg/tiktok.svg?react'
import track from 'src/Assets/Png/track.png'
import { colors } from 'src/Styled/variables'

import { SmartImage } from '../SmartImage'

import {
  WaveformContainer,
  CoverActionBlock,
  StyledWaveform,
  InfoActionBlockTop,
  InfoActionBlockTopNames,
  InfoActionBlockTopTrack,
  Wave,
  PlayButton,
  DurationDisplay,
} from './styles'

interface WaveData {
  playing: boolean
  duration: number | null
  currentTime: number
}

export interface PlayerProps {
  url?: string
  cover?: string
  authorName?: string
  trackName?: string
  link?: string
  className?: string
}

const SECONDS = 10
const DURATION = 60
const BAR_WIDTH = 1
const BAR_GAP = 2
const BAR_RADIUS = 2
const BAR_HEIGHT = 22
const CURSOR_WIDTH = 1
const WAVEFORM = '#waveform'
const WEB_AUDIO = 'WebAudio'

export const Player: FunctionComponent<PlayerProps> = ({
  url,
  cover,
  authorName,
  trackName,
  className,
}) => {
  const [waveData, setWaveData] = useState<WaveData>({
    playing: false,
    duration: null,
    currentTime: 0,
  })
  const [_, setAudioError] = useState(false)
  const waveformRef = useRef<WaveSurfer | null>(null)
  const trackRef = useRef<HTMLElement | null>(null)

  useEffect(() => {
    trackRef.current = document.querySelector<HTMLElement>('#track')
    waveformRef.current = WaveSurfer.create({
      barWidth: BAR_WIDTH,
      cursorWidth: CURSOR_WIDTH,
      container: WAVEFORM,
      backend: WEB_AUDIO,
      height: BAR_HEIGHT,
      progressColor: colors.green,
      waveColor: 'rgba(100, 104, 119, 0.4)',
      cursorColor: 'transparent',
      width: '100%',
      barGap: BAR_GAP,
      barRadius: BAR_RADIUS,
    })

    if (trackRef.current instanceof HTMLAudioElement) {
      waveformRef.current.load(trackRef.current.src).catch(() => {
        setAudioError(true)
      })

      waveformRef.current.on('ready', () => {
        const audioDuration = waveformRef.current?.getDuration() || 0
        setWaveData((prevData) => ({
          ...prevData,
          duration: audioDuration,
        }))
      })

      waveformRef.current.on('audioprocess', () => {
        const currentTime = waveformRef.current?.getCurrentTime() || 0
        setWaveData((prevData) => ({
          ...prevData,
          currentTime,
        }))
      })
    }

    return () => {
      if (waveformRef.current) {
        waveformRef.current.destroy()
      }
    }
  }, [url])

  const handlePlay = (): void => {
    setWaveData((prevData) => ({
      ...prevData,
      playing: true,
    }))
    if (waveformRef.current) {
      waveformRef.current.play().catch(() => {
        setAudioError(true)
      })
    }
  }

  const handlePause = (): void => {
    setWaveData((prevData) => ({
      ...prevData,
      playing: false,
    }))
    if (waveformRef.current) {
      waveformRef.current.pause()
    }
  }

  const formatDuration = (duration: number): string => {
    const minutes = Math.floor(duration / DURATION)
    const seconds = Math.floor(duration % DURATION)
    return `${minutes}:${seconds < SECONDS ? '0' : ''}${seconds}`
  }
  return (
    <WaveformContainer className={className}>
      <CoverActionBlock>
        <SmartImage src={cover} alt='Track cover' fallbackSrc={track} />
        <PlayButton
          style={{ pointerEvents: waveData.duration ? 'auto' : 'none' }}
          onClick={waveData.playing ? handlePause : handlePlay}
        >
          {waveData.playing ? <PauseIcon /> : <PlayIcon />}
        </PlayButton>
      </CoverActionBlock>
      <div>
        <InfoActionBlockTop>
          <InfoActionBlockTopNames>
            <InfoActionBlockTopTrack>
              {trackName ?? 'No data'}
            </InfoActionBlockTopTrack>
            <div>{authorName ?? 'No data'}</div>
          </InfoActionBlockTopNames>
          <TikTokIcon />
        </InfoActionBlockTop>
        <StyledWaveform>
          <Wave id='waveform' style={{ width: '100%', height: '22px' }} />
          <audio id='track' src={url}>
            <track kind='track' />
          </audio>
        </StyledWaveform>
        {waveData.duration !== null && (
          <DurationDisplay>
            <div>{formatDuration(waveData.currentTime)}</div>
            <div>{formatDuration(waveData.duration)}</div>
          </DurationDisplay>
        )}
      </div>
    </WaveformContainer>
  )
}
