import { useEffect } from 'react'

import { useGetProductLiftTokenQuery } from 'src/Redux/productLiftTokenApi'

const SCRIPT_ID = 'productlift-script'

export const useProductLiftScript = (): void => {
  const { data: tokenData } = useGetProductLiftTokenQuery()

  useEffect(() => {
    const scriptElement = document.getElementById(SCRIPT_ID)
    if (tokenData && !scriptElement) {
      const script = document.createElement('script')
      script.id = SCRIPT_ID
      script.src = `https://soundcampaign.productlift.dev/widgets_sdk?sso=${tokenData.token}`
      script.async = true
      document.body.appendChild(script)
    }
  }, [tokenData])
}
