import { CSSObjectWithLabel, StylesConfig } from 'react-select'

import { colors } from './variables'

const ONE = 1
const HALF = 0.5
export const styledSelectCustomStyles: StylesConfig = {
  option: (styles: CSSObjectWithLabel) => ({
    borderBottom: '1px solid #F3F4F7',
    fontSize: '14px',
    color: colors.mainText,
    ':active': {
      ...styles[':active'],
      backgroundColor: 'transparent',
      color: colors.black,
    },
  }),
  dropdownIndicator: (
    base: CSSObjectWithLabel,
    state: { selectProps: { menuIsOpen: boolean } },
  ): CSSObjectWithLabel => ({
    ...base,
    display: !state.selectProps.menuIsOpen ? 'none' : 'block',
    position: state.selectProps.menuIsOpen ? 'absolute' : 'relative',
  }),

  multiValueRemove: (base: CSSObjectWithLabel): CSSObjectWithLabel => ({
    ...base,
    ':hover': {
      color: '#1cb954',
      fontWeight: '400',
    },
  }),
  singleValue: (
    base: CSSObjectWithLabel,
    state: { isDisabled: boolean },
  ): CSSObjectWithLabel => {
    const opacity = state.isDisabled ? HALF : ONE
    const transition = 'opacity 500ms'

    return { ...base, opacity, transition }
  },
  menuList: (base: CSSObjectWithLabel) => ({
    ...base,
    '::-webkit-scrollbar': {
      width: '4px',
      paddingTop: '14px',
    },
    '::-webkit-scrollbar-track': {
      background: '#F9F9F9',
      borderRadius: '7px',
      paddingTop: '40px',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#CCCCCC',
      height: '49px',
      borderRadius: '4px',
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: '#CCCCCC',
    },
  }),
}
