import { Modal } from 'antd'
import Select from 'react-select'
import styled from 'styled-components'

import { colors, variables } from 'src/Styled/variables'

export const StyledSelect = styled(Select)`
  .language-select {
    &__input {
      color: ${colors.mainText} !important;
    }
    &__value-container {
      &--has-value {
        padding: 1px !important;
      }
    }
    &__control {
      min-height: 48px !important;
      outline: none !important;
      box-shadow: none !important;
      border: 1px solid ${colors.greyStroke} !important;
      margin-bottom: 12px !important;
      color: ${colors.mainText} !important;
    }
    &__placeholder {
      margin-left: 8px !important;
      color: ${colors.greyTextWhiteBg} !important;
      line-height: 21px !important;
    }
  }

  .language-select__multi-value {
    color: ${colors.green} !important;
    border-radius: 4px !important;
    font-size: ${variables.fontSize14} !important;
    font-weight: 400 !important;
    height: 90%;
    background-color: rgba(28, 185, 84, 0.15) !important;
    padding: 5px 5px 5px 6px !important;
    line-height: 21px;
    animation: fadeIn 0.5s ease-in-out;
  }

  .language-select__multi-value__label {
    color: ${colors.green} !important;
    font-size: ${variables.fontSize14};
    font-family: 'Segoe UI', 'Roboto', sans-serif !important;
  }

  .language-select__clear-indicator {
    display: none !important;
  }

  .language-select__indicator-separator {
    display: none !important;
  }

  .language-select__menu {
    padding: 8px 4px 8px 16px;
    filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.25));
    border-radius: 8px !important;
    position: relative !important;
    border: none;
  }

  .language-select__dropdown-indicator {
    display: none;
  }

  .language-select__option {
    border-bottom: 1px solid #f3f4f7;
    font-size: ${variables.fontSize14};
    padding: 13px 0;
    cursor: pointer;
  }

  .language-select__multi-value__remove {
    font-weight: 200 !important;
    background-position: center center;
    padding: 15px;
    & svg {
      display: none !important;
    }
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath opacity='0.5' d='M5.91615 4.99995L9.80995 1.10605C10.0634 0.852766 10.0634 0.443244 9.80995 0.189962C9.55667 -0.0633206 9.14714 -0.0633206 8.89386 0.189962L4.99994 4.08387L1.10614 0.189962C0.85274 -0.0633206 0.443335 -0.0633206 0.190051 0.189962C-0.0633505 0.443244 -0.0633505 0.852766 0.190051 1.10605L4.08385 4.99995L0.190051 8.89386C-0.0633505 9.14714 -0.0633505 9.55666 0.190051 9.80994C0.316278 9.93629 0.482246 9.99976 0.648097 9.99976C0.813947 9.99976 0.979797 9.93629 1.10614 9.80994L4.99994 5.91604L8.89386 9.80994C9.0202 9.93629 9.18605 9.99976 9.3519 9.99976C9.51775 9.99976 9.6836 9.93629 9.80995 9.80994C10.0634 9.55666 10.0634 9.14714 9.80995 8.89386L5.91615 4.99995Z' fill='%231CB954'/%3e%3c/svg%3e");
  }
  .genres {
    &__input {
      color: ${colors.mainText} !important;
    }
    &__value-container {
      &--has-value {
        padding: 1px !important;
      }
    }
    &__control {
      min-height: 48px !important;
      outline: none !important;
      box-shadow: none !important;
      border: none !important;
      margin-bottom: 12px !important;
      color: ${colors.mainText} !important;
    }
    &__placeholder {
      margin-left: 8px !important;
      color: #999999 !important;
      line-height: 21px !important;
    }
  }

  .genres__multi-value {
    color: ${colors.green} !important;
    border-radius: 4px !important;
    font-size: ${variables.fontSize14} !important;
    font-weight: 400 !important;
    height: 90%;
    background-color: rgba(28, 185, 84, 0.15) !important;
    padding: 5px 5px 5px 6px !important;
    line-height: 21px;
    animation: fadeIn 0.5s ease-in-out;
  }

  .genres__multi-value__label {
    color: ${colors.green} !important;
    font-size: ${variables.fontSize14};
    font-family: 'Segoe UI', 'Roboto', sans-serif !important;
  }

  .genres__clear-indicator {
    display: none !important;
  }

  .genres__indicator-separator {
    display: none !important;
  }

  .genres__menu {
    padding: 8px 4px 8px 16px;
    filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.25));
    border-radius: 8px !important;
    position: relative !important;
    border: none;
  }

  .genres__dropdown-indicator {
    display: none;
  }

  .genres__option {
    border-bottom: 1px solid #f3f4f7;
    font-size: ${variables.fontSize14};
    padding: 13px 0;
    cursor: pointer;
  }

  .genres__multi-value__remove {
    cursor: pointer;
    font-weight: 200 !important;
    background-position: center center;
    padding: 15px;
    & svg {
      display: none !important;
    }
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath opacity='0.5' d='M5.91615 4.99995L9.80995 1.10605C10.0634 0.852766 10.0634 0.443244 9.80995 0.189962C9.55667 -0.0633206 9.14714 -0.0633206 8.89386 0.189962L4.99994 4.08387L1.10614 0.189962C0.85274 -0.0633206 0.443335 -0.0633206 0.190051 0.189962C-0.0633505 0.443244 -0.0633505 0.852766 0.190051 1.10605L4.08385 4.99995L0.190051 8.89386C-0.0633505 9.14714 -0.0633505 9.55666 0.190051 9.80994C0.316278 9.93629 0.482246 9.99976 0.648097 9.99976C0.813947 9.99976 0.979797 9.93629 1.10614 9.80994L4.99994 5.91604L8.89386 9.80994C9.0202 9.93629 9.18605 9.99976 9.3519 9.99976C9.51775 9.99976 9.6836 9.93629 9.80995 9.80994C10.0634 9.55666 10.0634 9.14714 9.80995 8.89386L5.91615 4.99995Z' fill='%231CB954'/%3e%3c/svg%3e");
  }
`

export const ContentBlock = styled.div<{ last?: boolean }>`
  margin-bottom: ${({ last }) => (last ? '112px' : '10px')};
  padding: 24px 16px;
  background-color: ${colors.white};
  border-radius: 4px;
`

export const MobileButtonContainer = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 72px;
  background-color: ${colors.white};
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.3);
  & > div {
    width: 120px;
  }
`
export const ButtonContainer = styled.div`
  margin: 50px auto 24px;
  width: 137px;
`
export const PlaylistWrapper = styled.div`
  margin-bottom: 18px;
`
export const DesktopPlaylistWrapper = styled.div`
  padding: 15px 24px 0px 25px;
  margin-bottom: 32px;
`
export const DesktopWrapper = styled.div`
  padding: 34px 10px 0px 23px;
  margin-bottom: 19px;
`
export const DesktopGenresWrapper = styled.div`
  padding: 19px 24px 0px 25px;
  margin-bottom: 14px;
`

export const DesktopBeCarefulWrapper = styled.div`
  padding: 28px 24px 0px 24px;
  margin-bottom: 26px;
  display: flex;
  flex-direction: row;
`
export const BeCarefulMobileButton = styled.div`
  margin-bottom: 74px;
  font-size: ${variables.fontSize14};
`

export const DesktopModal = styled(Modal)`
  top: 10% !important;
  .ant-modal-body {
    padding: 24px;
  }
  min-width: 600px;
  .ant-modal-content {
    padding: 0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .ant-modal-header {
    padding: 16.5px 24px;
    background-color: ${colors.green};
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  .ant-modal-title {
    color: ${colors.white};
    font-size: 18px;
    line-height: 150%;
  }
  .ant-modal-close-x {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
