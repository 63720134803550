import { FC } from 'react'

import { useTranslation } from 'react-i18next'

import { CampaignDetails } from 'src/Redux/campaignDetailsApi'

import {
  CampaignCalculation,
  CampaignCalculationLabel,
  CampaignCalculationValue,
  CampaignCalculationsContainer,
  GenresAndLanguagesContainer,
} from '../styles'
import CampaignGenres from '../../../CampaignGenres'
import CampaignLanguagesComponent from '../../../CampaignLanguages'
import { ProgressBarByTime } from '../../../ProgressBarByTime'
import { CuratorsCalculation } from '../CuratorsCalculation/CuratorsCalculation'
import { Credits } from '../Credits/Credits'

interface DetailsMobileProps {
  campaignDetails?: CampaignDetails
}
export const DetailsMobile: FC<DetailsMobileProps> = ({ campaignDetails }) => {
  const { t } = useTranslation()
  return (
    <>
      <CampaignCalculationsContainer>
        <CampaignCalculation>
          <CampaignCalculationLabel>
            {t('campaignResultsPage.budgetLabel')}:{' '}
          </CampaignCalculationLabel>
          <CampaignCalculationValue>{`$${String(
            campaignDetails?.budget,
          )}`}</CampaignCalculationValue>
        </CampaignCalculation>
        <CuratorsCalculation
          curatorsCount={campaignDetails?.curators_count}
          curatorsCountMax={campaignDetails?.curators_count_max}
        />

        <CampaignCalculation>
          <CampaignCalculationLabel>
            {t('campaignResultsPage.playlistsLabel')}:{' '}
          </CampaignCalculationLabel>
          <CampaignCalculationValue>
            {campaignDetails?.playlists_count}
            {campaignDetails?.playlists_count_max
              ? `-${campaignDetails?.playlists_count_max}`
              : ''}
          </CampaignCalculationValue>
        </CampaignCalculation>
        <Credits
          status={campaignDetails?.status}
          refund={campaignDetails?.refund}
        />
      </CampaignCalculationsContainer>
      {campaignDetails && (
        <ProgressBarByTime campaignDetails={campaignDetails} />
      )}

      <GenresAndLanguagesContainer>
        <CampaignGenres genres={campaignDetails?.Track.TrackGenres} />
        <CampaignLanguagesComponent
          languages={campaignDetails?.Track.TrackLanguages}
        />
      </GenresAndLanguagesContainer>
    </>
  )
}
