import { FC } from 'react'

import styled from 'styled-components'

import Logo from 'src/Assets/Svg/Logo/Logo.svg?react'
import LogoText from 'src/Assets/Svg/Logo/LogoTextBlack.svg?react'
import { MAX_MOBILE_WIDTH, MAX_TABLET_WIDTH } from 'src/Styled/variables'

const Container = styled.div<{
  withShadow: boolean
}>`
  display: flex;
  width: 100%;
  height: 72px;
  padding: 16px;
  align-items: center;
  gap: 8px;
  background: ${({ theme }) => theme.common.primaryBg};
  grid-area: header;
  box-shadow: ${({ withShadow }) =>
    withShadow ? '0px 0px 12px 0px rgba(51, 51, 51, 0.12)' : 'none'};
  transition: box-shadow 0.3s ease-in-out;
  z-index: 1;

  @media (max-width: ${MAX_TABLET_WIDTH}) {
    padding: 16px 24px;
  }

  @media (max-width: ${MAX_MOBILE_WIDTH}) {
    padding: 16px;
  }
`

const Link = styled.a`
  display: flex;
  align-items: center;
  gap: 8px;
`

export interface CreateCampaignHeaderProps {
  withShadow: boolean
  logoLinkUrl?: string
}

export const CreateCampaignHeader: FC<CreateCampaignHeaderProps> = ({
  withShadow,
  logoLinkUrl,
}) => (
  <Container withShadow={withShadow}>
    <Link target='_blank' rel='noopener noreferrer' href={logoLinkUrl}>
      <Logo />
      <LogoText />
    </Link>
  </Container>
)
