import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

import IconSuccessfully from 'src/Assets/Svg/successfully.svg?react'
import { colors, device, variables } from 'src/Styled/variables'
import Button from 'src/Components/Buttons/Button'
import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import {
  clearSubscriptionFromLocation,
  getSubscriptionFromLocation,
} from 'src/Redux/subscription-process/subscriptionFromLocation'
import { FullScreenModal } from 'src/Components/FullScreenModal'

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`
const Wrapper = styled.div`
  display: flex;
  padding: 40px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  @media ${device.mobileMin} {
    width: 560px;
  }
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
`

const Title = styled.div`
  color: ${colors.mainText};
  text-align: center;
  font-size: 18px;
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
`

const Description = styled.div`
  color: ${colors.mainText};
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  .ant-btn.ant-btn-default {
    width: fit-content;
    padding: 0 40px;
  }
`

export const SuccessPaymentSubscription: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const fromLocation = useAppSelector(getSubscriptionFromLocation)
  const handleClose = (): void => {
    if (fromLocation) {
      navigate(fromLocation)
      dispatch(clearSubscriptionFromLocation())
      return
    }
    navigate('/')
  }

  return (
    <FullScreenModal open>
      <Container>
        <Wrapper>
          <IconSuccessfully />
          <TextWrapper>
            <Title>{t('subscriptionSuccessPayment.title')}</Title>
            <Description>
              {t('subscriptionSuccessPayment.description')}
            </Description>
          </TextWrapper>
          <StyledButton onClick={handleClose} type='whiteWithGreenBorder'>
            {t('subscriptionSuccessPayment.button')}
          </StyledButton>
        </Wrapper>
      </Container>
    </FullScreenModal>
  )
}
