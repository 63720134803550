import styled from 'styled-components'

import { colors, device } from 'src/Styled/variables'

export const RightSideContainer = styled.div`
  flex-shrink: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 24px;
  background: ${colors.white};
  border-radius: 4px;

  @media ${device.mobileMax} {
    width: 100%;
  }
`
