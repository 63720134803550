import { createDraftSafeSelector, createSlice } from '@reduxjs/toolkit'

import { ApiError, NameSpace, SliceStatus } from 'src/Redux/types'
import { RootState } from 'src/Redux'

import { getReferralHash } from './api-actions'

interface ReferralHash {
  referralHash: string
  status: SliceStatus
  error: ApiError | null
}
const initialState: ReferralHash = {
  referralHash: '',
  status: SliceStatus.Idle,
  error: null,
}

export const referralHashProcess = createSlice({
  initialState,
  name: NameSpace.REFERRAL_HASH,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getReferralHash.pending, (state) => {
      state.status = SliceStatus.Loading
    })
    builder.addCase(getReferralHash.fulfilled, (state, action) => {
      state.status = SliceStatus.Succeeded
      state.referralHash = action.payload.referralCode
    })
    builder.addCase(getReferralHash.rejected, (state, action) => {
      state.status = SliceStatus.Failed
      state.error = action.payload as ApiError
    })
  },
})

const getState = (state: RootState): ReferralHash => state.referral.referralHash

export const getReferralHashValue = createDraftSafeSelector(
  getState,
  ({ referralHash }) => referralHash,
)

export const getReferralHashError = createDraftSafeSelector(
  getState,
  ({ error }) => error,
)
