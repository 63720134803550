import { FC, useMemo } from 'react'

import { Genre } from 'src/Types'
import { capitalizeFirstLetter } from 'src/Helpers/layoutHelpers'
import { filterAndSortGenres } from 'src/Constants/functions'

import { GenreOrLanguageItem } from './GenreItem'
import { renderLanguageOrGenreName } from './helpers'

import { GenreListWrapper, Separator } from './styles'
interface Props {
  searchValue: string
  selectedGenres: Genre[]
  genres: Genre[]
  onClick: (id: number) => void
}

export const GenresList: FC<Props> = ({
  genres,
  selectedGenres,
  onClick,
  searchValue,
}) => {
  const filteredGenres = useMemo(
    () => filterAndSortGenres(genres, searchValue, 'genre_name'),
    [genres, searchValue],
  )

  return (
    <>
      {Boolean(selectedGenres.length) && (
        <>
          <GenreListWrapper>
            {selectedGenres.map((genre) => (
              <GenreOrLanguageItem
                onClick={() => onClick(Number(genre?.id))}
                hasCross
                name={capitalizeFirstLetter(genre.genre_name || '')}
                key={genre.id}
              />
            ))}
          </GenreListWrapper>
          <Separator />
        </>
      )}
      <GenreListWrapper>
        {filteredGenres.map((genre) => (
          <GenreOrLanguageItem
            onClick={() => onClick(Number(genre?.id))}
            selected={selectedGenres.some((item) => item.id === genre.id)}
            name={renderLanguageOrGenreName(
              genre?.genre_name || '',
              searchValue,
            )}
            key={genre.id}
          />
        ))}
      </GenreListWrapper>
    </>
  )
}
