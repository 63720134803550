import { FC, Fragment } from 'react'

import { Radio } from 'antd'
import { Controller, useForm } from 'react-hook-form'
import styled from 'styled-components'
import { yupResolver } from '@hookform/resolvers/yup'

import { colors, device, variables } from 'src/Styled/variables'

import { CustomRadio } from '../ui/CustomRadio'
import { TextArea } from '../ui/TextArea'

import {
  FIRST_FEEDBACK_FORM_LIST,
  FirstFeedbackFormValues,
  firstFeedbackFormSchema,
} from './helpers'
import { FirstFeedbackButtons } from './FirstFeedbackButtons'
import { useSendFeedBack } from './useSendFeedBack'

const Form = styled.form`
  display: flex;
  max-width: 600px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  padding-bottom: 60px;
  @media ${device.tabletMin} {
    margin: 0 auto;
    max-width: 600px;
    min-width: 600px;
    gap: 16px;
  }
`

const StyledRadioGroup = styled(Radio.Group)`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`

const StyledRadio = styled(CustomRadio)`
  color: ${colors.mainText};
  font-size: 14px;
  line-height: 150%;
  display: inline-flex;
  padding: 17px 0px 2px;

  align-items: center;
  gap: 8px;
  align-self: stretch;
  span {
    padding-inline-start: 0 !important;
    padding-inline-end: 0 !important;
  }
`
const Label = styled.div`
  color: ${colors.mainText};
  font-size: 14px;
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
`

const RadioWrapper = styled.div`
  display: flex;
  padding: 0px 16px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`

const TextAreaWrapper = styled.div`
  padding-top: 16px;
  width: 100%;
`

export const FirstFeedbackForm: FC = () => {
  const { loading, onSendFeedback } = useSendFeedBack()
  const {
    control,
    formState: { isValid },
    handleSubmit,
  } = useForm<FirstFeedbackFormValues>({
    resolver: yupResolver(firstFeedbackFormSchema),
  })
  const handleSave = async (data: FirstFeedbackFormValues): Promise<void> => {
    await onSendFeedback(data)
  }
  return (
    <Form onSubmit={handleSubmit(handleSave)} translate='no'>
      <Controller
        control={control}
        name='resource'
        render={({ field: { onChange, value } }) => (
          <StyledRadioGroup onChange={onChange} value={value}>
            {FIRST_FEEDBACK_FORM_LIST.map(({ label, values }) => (
              <div key={label}>
                <Label>{label}</Label>
                <RadioWrapper>
                  {values.map((item) => {
                    const {
                      radioLabel,
                      radioValue,
                      inputPlaceholder,
                      rows,
                      commentLength,
                    } = item
                    const hasInput =
                      Boolean(inputPlaceholder) && value === radioValue
                    return (
                      <Fragment key={radioValue}>
                        <StyledRadio value={radioValue}>
                          {radioLabel}
                        </StyledRadio>
                        {hasInput && (
                          <Controller
                            control={control}
                            name='other'
                            render={({ field }) => (
                              <TextAreaWrapper>
                                <TextArea
                                  {...field}
                                  placeholder={inputPlaceholder}
                                  rows={rows}
                                  maxLength={commentLength}
                                  showCount={{
                                    formatter: (count) =>
                                      count.value
                                        ? `${count.count}/${commentLength || 0}`
                                        : '',
                                  }}
                                  style={{ resize: 'none' }}
                                  prefixCls='FirstFeedbackForm-comment'
                                />
                              </TextAreaWrapper>
                            )}
                          />
                        )}
                      </Fragment>
                    )
                  })}
                </RadioWrapper>
              </div>
            ))}
          </StyledRadioGroup>
        )}
      />
      <FirstFeedbackButtons disabled={!isValid} loading={loading} />
    </Form>
  )
}
