import { FC } from 'react'

import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import {
  PrimaryScheduleSteps,
  PrimaryScheduleTabs,
} from 'src/Components/PrimaryScheduleTabs'
import { useScheduleSpotifyCampaign } from 'src/Containers/ScheduleSpotifyWrapper/useSchaduleSpotifyCampaign'
import { ScheduleSteps } from 'src/Components/ScheduleTabs'
import { ArtistRoutesEnum } from 'src/Router/ArtistComponentsNewUI'
import { CampaignPlatformTypeEnum } from 'src/Types'
import { useAppSelector } from 'src/Hooks/redux'
import { getCampaignToSchedule } from 'src/Redux/campaign-process/spotifyCampaignSchedule-process/selectors'
import { ScheduleSuccessPage } from 'src/Components/ScheduleSuccessPage'

import { SpotifyScheduleCheckoutPage } from './SpotifyScheduleCheckoutPage'
import { SpotifyScheduleSettingsPage } from './SpotifyScheduleSettingsPage'

export const SpotifySchedulePage: FC = () => {
  useScheduleSpotifyCampaign()
  const { t } = useTranslation()
  const { number } = useParams()
  const campaignDetails = useAppSelector(getCampaignToSchedule)
  const steps: PrimaryScheduleSteps[] = [
    {
      title: t('spotifySchedulePage.campaigns'),
      path: ArtistRoutesEnum.CampaignsList,
    },
    {
      title: t('spotifySchedulePage.campaignSettings'),
      path: `${ArtistRoutesEnum.SpotifySchedule}/${number}?step=${ScheduleSteps.SETTINGS}`,
    },
    {
      title: t('spotifySchedulePage.campaignCheckout'),
      path: `${ArtistRoutesEnum.SpotifySchedule}/${number}?step=${ScheduleSteps.CHECKOUT}`,
    },
  ]
  return (
    <>
      <ScheduleSuccessPage
        startDate={campaignDetails.start_date}
        platformName={CampaignPlatformTypeEnum.SPOTIFY}
      />
      <PrimaryScheduleTabs steps={steps}>
        {/* first must be settings second checkout */}
        <SpotifyScheduleSettingsPage />
        <SpotifyScheduleCheckoutPage />
      </PrimaryScheduleTabs>
    </>
  )
}
