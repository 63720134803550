import { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'

import ButtonClose from 'src/Assets/Svg/cross-icon.svg?react'

import { StyledSoundLinkHint, StyledCloseBtn } from './styles'
interface SoundLinkHintProps {
  onClick?: () => void
}

export const SoundLinkHint: FunctionComponent<SoundLinkHintProps> = ({
  onClick,
}) => {
  const { t } = useTranslation()
  return (
    <StyledSoundLinkHint>
      <>{t('sidebar.collectAllLinks')}</>
      <StyledCloseBtn onClick={onClick}>
        <ButtonClose />
      </StyledCloseBtn>
    </StyledSoundLinkHint>
  )
}
