import styled from 'styled-components'

import { variables, colors } from 'src/Styled/variables'

interface Props {
  collapse?: boolean
}

export const SidebarContentWrapper = styled.div<Props>`
  transform: ${({ collapse }) =>
    collapse ? 'translateY(-235px)' : 'translateY(0)'};
  transition: all 1s ease-out;
  z-index: 1000;
`

export const SidebarList = styled.ul`
  padding: 0;
  display: flex;
  flex-direction: column;
  margin-bottom: 0px;
  max-height: -webkit-fill-available;
  @media (min-width: 576px) {
    padding-top: 20px;
  }
`

export const SidebarLinkItem = styled.li<Props>`
  color: ${colors.mainText};
  height: 40px;
  transition: all 0.3s;
  cursor: pointer;
  align-items: center;
  background: transparent;
  border: none;
  display: flex;
  padding: ${({ collapse }) => (!collapse ? '12px 16px' : '12px 20px')};
  transition: all 0.2s ease-out;
  &:hover {
    background-color: ${colors.greyBg};
  }
`

export const SidebarLinkText = styled.div<Props>`
  margin: 0;
  font-size: ${variables.fontSize14};
  line-height: 21px;
  display: flex;
  left: 10px;
  width: 160px;
  color: ${colors.greyTextWhiteBg};
  opacity: ${({ collapse }) => (collapse ? '1' : '0')};
  transition: all 0.2s ease-out;
  white-space: nowrap;
  overflow: hidden;
  &:hover {
    color: ${colors.mainText};
  }
`

export const SidebarIconBlock = styled.div`
  margin-right: 16px;
  color: ${colors.greyTextWhiteBg};
  svg {
    vertical-align: middle;
  }

  img {
    width: 16px;
  }
  & a:visited {
    color: ${colors.greyTextWhiteBg};
  }
`

export const StyledNavLink = styled.a`
  color: ${colors.greyTextWhiteBg};
  cursor: pointer;
  transition: all 0.2s ease-out;
  & svg {
    path {
      fill: ${colors.greyTextWhiteBg};
    }
  }

  &:visited {
    color: ${colors.greyTextWhiteBg};
    & svg {
      path {
        fill: ${colors.greyTextWhiteBg};
      }
    }
  }

  &.active {
    color: ${colors.mainText};
    background-color: ${colors.greyBg};

    & svg {
      path {
        fill: ${colors.mainText};
      }
    }
  }
  &:hover {
    color: ${colors.mainText};
    background-color: ${colors.greyBg};

    & svg {
      path {
        fill: ${colors.mainText};
      }
    }
  }
`
