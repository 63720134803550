import { FC, HTMLProps, MouseEvent } from 'react'

import { useDebouncedCallback } from 'use-debounce'
import styled from 'styled-components'

import RefreshIcon from 'src/Assets/Svg/refresh.svg?react'
import { colors } from 'src/Styled/variables'

interface RoundedButtonProps extends HTMLProps<HTMLButtonElement> {
  loading?: boolean
}

export const RoundBtn = styled.button`
  background: ${colors.white};
  border: 1px solid ${colors.greyStroke};
  box-sizing: border-box;
  border-radius: 24px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.2s all linear;

  svg {
    color: ${colors.greyTextBlackBg};
    transition: 0.2s all linear;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &:hover {
    border: 1px solid ${colors.green};
    svg {
      color: ${colors.green};
    }
  }
`

export const RoundedButton: FC<RoundedButtonProps> = (props) => {
  const { onClick } = props

  const debouncedClick = useDebouncedCallback(
    (event) => {
      onClick?.(event as MouseEvent<HTMLButtonElement>)
    },
    0,
    { trailing: true },
  )

  return (
    <RoundBtn onClick={debouncedClick}>
      <RefreshIcon width={16} height={16} />
    </RoundBtn>
  )
}
