import { FC } from 'react'

import { Input, InputProps } from 'antd'
import styled from 'styled-components'

import CloseIcon from 'src/Assets/Svg/pages-icons/Close.svg?react'

const StyledInput = styled(Input)`
  padding: 0;
  border: none;
`

export type MobileSearchInputProps = InputProps

export const MobileSearchInput: FC<MobileSearchInputProps> = (props) => (
  <StyledInput
    allowClear={{ clearIcon: <CloseIcon /> }}
    {...props}
  ></StyledInput>
)
