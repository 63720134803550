import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import { message } from 'antd'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { landingPageUrl } from 'src/Constants/links'
import Button from 'src/Components/Buttons/Button'
import { SmartInput } from 'src/Components/SmartInput'
import {
  getAuthEmail,
  getIsAuthTypeLoading,
} from 'src/Redux/auth-process/authTypeSlice/selectors'
import { checkUserEmailToSignIn } from 'src/Redux/auth-process/authTypeSlice/api-actions'
import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { ApiError } from 'src/Redux/types'
import { FullWideNotification } from 'src/Components/FullWideNotification'
import { colors } from 'src/Styled/variables'

import { PlatformLinks } from '../PlatformLinks/PlatformLinks'

import { schemaForInitialLogin } from './schema'

import {
  AccountOr,
  ArrowRight,
  FieldWrapper,
  Form,
  Loader,
  Text,
  Wrapper,
} from './styles'

interface FormState {
  email: string
}

export const LoginInitial: FC = () => {
  const dispatch = useAppDispatch()
  const loading = useAppSelector(getIsAuthTypeLoading)
  const [messageApi, contextHolder] = message.useMessage()
  const { t } = useTranslation()
  const defaultEmail = useAppSelector(getAuthEmail)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormState>({
    defaultValues: {
      email: defaultEmail,
    },
    resolver: yupResolver(schemaForInitialLogin),
  })

  const onSubmit = ({ email }: FormState): void => {
    void dispatch(checkUserEmailToSignIn(email))
      .unwrap()
      .catch((error: ApiError) => {
        if (error.message === 'Curator in blacklist') {
          void messageApi.open({
            content: (
              <FullWideNotification
                message={t('login.authErrorBlacklistRegistered')}
              />
            ),
            duration: 3,
            style: { backgroundColor: colors.badgeRed },
            icon: null,
          })
        }
      })
  }
  return (
    <>
      {contextHolder}
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Wrapper>
          <FieldWrapper>
            <SmartInput
              {...register('email')}
              placeholder={String(t('login.email'))}
              error={errors.email}
              type='email'
              data-cy='input-render-field-email'
              autoFocus
            />
          </FieldWrapper>
        </Wrapper>
        <Button type='green' htmlType='submit'>
          {loading ? (
            <Loader height={24} type='bars' />
          ) : (
            <>
              {t('login.continue')} <ArrowRight />
            </>
          )}
        </Button>
      </Form>
      <AccountOr data-cy='account__or-login-easily-using'>
        <p data-cy='login-form-p'>{t('login.easilyUsing')}</p>
      </AccountOr>
      <PlatformLinks />
      <Text>
        <a href={landingPageUrl}>
          {t('login.dontHaveAnAccount')}&#8194;
          <span data-cy='login-form-sign-up-link'>{t('login.signUp')}</span>
        </a>
      </Text>
    </>
  )
}
