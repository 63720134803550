import { createDraftSafeSelector } from '@reduxjs/toolkit'

import { calculateDaysLeft } from 'src/Constants/functions'

import { RootState } from '..'

import { InfluencerState } from '.'

const getState = (state: RootState): InfluencerState => state.influencer

export const getInProgressEvents = createDraftSafeSelector(
  getState,
  ({ inProgressEvents }) => inProgressEvents,
)
export const getIsEventsLoading = createDraftSafeSelector(
  getState,
  ({ loading }) => loading,
)

export const getSubmittedEvents = createDraftSafeSelector(
  getState,
  ({ submittedEvents }) => submittedEvents,
)

export const getIsLoadingTikTokVideo = createDraftSafeSelector(
  getState,
  ({ loading }) => loading,
)

export const getNewTikTokEvents = createDraftSafeSelector(
  getState,
  ({ newEvents }) => {
    const { events: currentEvents } = newEvents
    const events = [...currentEvents]?.sort(
      (a, b) =>
        calculateDaysLeft(a.deadlineToAccept) -
        calculateDaysLeft(b.deadlineToAccept),
    )
    return { events, meta: newEvents.meta }
  },
)

export const getNewTikTokEventsCount = createDraftSafeSelector(
  getState,
  ({ eventStatistics }) => eventStatistics.new,
)

export const getInProgressTikTokEventsCount = createDraftSafeSelector(
  getState,
  ({ eventStatistics }) => eventStatistics.accepted,
)

export const getSubmittedTikTokEventsCount = createDraftSafeSelector(
  getState,
  ({ eventStatistics }) =>
    eventStatistics.awaiting_review +
    eventStatistics.awaiting_moderator_review +
    eventStatistics.approved_by_artist +
    eventStatistics.approved_automatically +
    eventStatistics.approved_by_admin +
    eventStatistics.declined +
    eventStatistics.submission_awaiting +
    eventStatistics.submission_rejected,
)

export const getOpenedCampaign = createDraftSafeSelector(
  getState,
  ({ newEvents, openedCampaignId }) =>
    newEvents.events.find(({ id }) => openedCampaignId === id),
)

export const getEventDetails = createDraftSafeSelector(
  getState,
  ({ eventDetails }) => eventDetails,
)

export const getEventId = createDraftSafeSelector(
  getState,
  ({ eventDetailsId }) => eventDetailsId,
)

export const getUploadVideoError = createDraftSafeSelector(
  getState,
  ({ uploadVideoError }) => uploadVideoError,
)

export const getCreatorEventsPageActiveTab = createDraftSafeSelector(
  getState,
  ({ creatorEventsPageActiveTab }) => creatorEventsPageActiveTab,
)
