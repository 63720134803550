import styled from 'styled-components'

import { colors, device, variables } from 'src/Styled/variables'

export const CloseButton = styled.div`
  cursor: pointer;
  width: 32px;
  height: 32px;
  margin-left: auto;
  margin-right: 16px;
  margin-top: 16px;
  & svg {
    width: 20px;
    height: 20px;
  }

  @media (min-width: 768px) {
    position: absolute;
    width: 24px;
    height: 24px;
    top: 45.5px;
    right: 44.5px;
    & svg {
      width: 16px;
      height: 16px;
    }
  }
`
export const Logo = styled.div`
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: auto;
  }
`
export const Header = styled.div`
  color: ${colors.black};
  text-align: center;
  font-family: Segoe UI;
  font-size: 28px;
  font-style: normal;
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
  margin-bottom: 16px;
`
export const LoginContainer = styled.div`
  border-radius: 5px;
  margin: 18px 14px 0px 18px;
  padding: 10px 0px;
  width: 90%;
  @media ${device.mobileMin} {
    margin: auto;
  }
`

export const Wrapper = styled.div`
  height: 100vh;
  width: 100%;
  min-height: 100vh;
  display: flex;
  overflow-y: auto;
  background-color: black;
  position: relative;
  flex-direction: column;
`
export const Card = styled.div`
  background-color: ${colors.white};
  width: auto;
  padding: 16px 16px 24px 16px;
  margin: auto;
  max-width: 600px;
  border-radius: 5px;

  @media ${device.mobileMin} {
    padding: 24px 72px;
  }
`
