import {
  CampaignPlatformTypeEnum,
  SpotifyCampaign,
  TikTokCampaignPricingModelEnum,
  TiktokCampaign,
} from 'src/Types'

import {
  SpotifyCampaignsListResponse,
  TikTokCampaignsListResponse,
} from './api-actions'

export const mapSpotifyCampaign = (
  spotifyData: SpotifyCampaignsListResponse,
): Array<SpotifyCampaign> => {
  const spotifyCampaigns: Array<SpotifyCampaign> = spotifyData?.map(
    (campaign) => ({
      id: Number(campaign.id),
      type: campaign.type,
      trackCoverUrl: campaign.Track?.track_image,
      trackTitle: campaign.Track?.track_name,
      trackId: campaign.Track?.track_id,
      trackAuthorName: campaign.Track?.artist_name,
      startDate: campaign.start_date,
      endDate: campaign.end_date,
      status: campaign.status,
      is_feed: campaign.is_feed,
      platformType: CampaignPlatformTypeEnum.SPOTIFY,
      relaunched: campaign.relaunched,
      createdAt: campaign.created_at,
      reviewedReviews: campaign.reviewed_reviews,
      allReview: campaign.all_review,
      amount: campaign.amount,
      current_amount: campaign.current_amount,
    }),
  )

  return spotifyCampaigns
}

export const mapTiktokCampaign = (
  tiktokData: TikTokCampaignsListResponse,
): Array<TiktokCampaign> => {
  const tiktokCampaigns: Array<TiktokCampaign> = tiktokData?.campaigns?.map(
    (campaign) => ({
      id: String(campaign.id),
      type: campaign.type,
      trackCoverUrl: campaign.trackCoverUrl,
      trackTitle: campaign.trackTitle,
      trackAuthorName: campaign.trackAuthorName,
      startDate: campaign.startDate,
      endDate: campaign.endDate,
      status: campaign.status,
      platformType: CampaignPlatformTypeEnum.TIKTOK,
      createdAt: campaign.createdAt,
      submittedVideosAmount: campaign.submittedVideosAmount,
      influencersAcceptedAmount: campaign.influencersAcceptedAmount,
      trackDuration: 0,
      genres: [],
      languages: [],
      trackId: 0,
      price: 0,
      isCurrent: false,
      expectedMinVideos: 0,
      expectedMaxVideos: 0,
      refund: 0,
      totalVideos: 0,
      totalViews: 0,
      totalShares: 0,
      relaunched: false,
      reviews: { done: 0, influencersAcceptedCount: 0 },
      pricingModelVersion: TikTokCampaignPricingModelEnum.FollowersBased,
      spent: 0,
      reserved: 0,
    }),
  )

  return tiktokCampaigns
}
