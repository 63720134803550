import styled from 'styled-components'

import { variables, colors, device } from 'src/Styled/variables'

export const ExpectationsFromArtist = styled.div`
  color: ${colors.mainText};
  text-align: left;
`

export const ExpectationsTitle = styled.div`
  margin-top: 24px;
  font-size: ${variables.fontSize10};
  color: inherit;
  line-height: 15px;

  @media ${device.mobileMin} {
    margin-top: 16px;
  }
`

export const ExpectationsText = styled.div`
  font-weight: ${variables.fontWeight400};
  font-size: ${variables.fontSize14};
  line-height: 21px;
  color: ${colors.mainText};
`
