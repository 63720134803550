import { FC } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { Form } from 'antd'
import { string as yupString, object } from 'yup'

import Button from 'src/Components/Buttons/Button'
import { SmartInput } from 'src/Components/SmartInput'
import { getAuthEmail } from 'src/Redux/auth-process/authTypeSlice/selectors'
import { useAppSelector } from 'src/Hooks/redux'

import { Wrapper, FieldWrapper } from '../LoginInitial/styles'

import { AccountOr, ButtonsWrapper, TextBlack, TextRed } from './styles'

export const LoginIsNotExist: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const defaultEmail = useAppSelector(getAuthEmail)

  const {
    formState: { errors },
    register,
  } = useForm<{ email: string }>({
    defaultValues: {
      email: defaultEmail,
    },
    mode: 'all',
    resolver: yupResolver(
      object({
        email: yupString().email().required(),
      }),
    ),
  })
  return (
    <>
      <Form>
        <Wrapper>
          <FieldWrapper>
            <SmartInput
              {...register('email')}
              error={errors.email}
              placeholder={t('login.email') || ''}
              type='email'
              data-cy='input-render-field-email'
              autoFocus
            />
          </FieldWrapper>
        </Wrapper>
      </Form>
      <TextRed data-cy='text_red-login-form-user-not-exist-no-account'>
        <p data-cy='login-form-user-not-exist-no-account'>
          {t('login.noAccount')}
        </p>
      </TextRed>
      <TextBlack>
        <p data-cy='login-form-user-not-exist-check'>{t('login.checkIf')}</p>
      </TextBlack>
      <AccountOr data-cy='account__or-user-not-exist-easily-using'>
        <p data-cy='login-form-user-not-exist-easily-using'>
          {t('login.easilyUsing')}
        </p>
      </AccountOr>
      <TextBlack>
        <p data-cy='login-form-user-not-exist-create-new'>
          {t('login.createANew')}
        </p>
      </TextBlack>
      <ButtonsWrapper>
        <Button
          htmlType='button'
          type='green'
          data-cy='login-form-btn-without-signup-artist'
          onClick={() => navigate('/create')}
        >
          {t('login.iAmArtist')}
        </Button>
        <Button
          type='green'
          htmlType='button'
          data-cy='login-form-btn-without-signup-curator'
          onClick={() => navigate('/signup-curator')}
        >
          {t('login.iAmCurator')}
        </Button>
      </ButtonsWrapper>
    </>
  )
}
