import styled from 'styled-components'

import { variables, colors, device } from 'src/Styled/variables'

export const DescriptionCardText = styled.div`
  display: flex;
  flex-direction: column;
`

export const PageContentContainer = styled.div`
  box-sizing: content-box;
  background-color: ${colors.white};
  padding: 24px 32px 24px 24px;
  margin-bottom: 16px;
  position: relative;
  margin-top: 24px;
  @media ${device.mobileMin} {
    margin-top: 16px;
  }

  .ant-table-thead .ant-table-cell {
    padding: 8px 16px 13px 24px !important;
  }

  .ant-tabs-nav::before {
    display: none;
  }

  .ant-tabs-tab {
    padding-left: 20px;
    padding-right: 20px;
  }

  .ant-tabs-tab-btn {
    color: ${colors.greyTextWhiteBg};
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${colors.greyTextWhiteBg};
  }

  .ant-tabs-nav-list {
    border-bottom: 2px solid ${colors.greyStroke};
  }

  .ant-tabs-nav {
    height: 35px;
    margin-bottom: 24px;

    .ant-tabs-ink-bar {
      background: ${colors.green};
      height: 2px;
      border-radius: 1px;
      bottom: -1px;
    }
  }

  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0;
  }

  .ant-table-column-title {
    font-weight: ${variables.fontWeight400};
    font-size: ${variables.fontSize14};
    line-height: 150%;
    color: ${colors.mainText};
  }

  .ant-table-cell {
    font-weight: ${variables.fontWeight400};
    font-size: ${variables.fontSize14};
    line-height: 150%;
    color: ${colors.mainText};
    padding: 16px 16px 16px 24px !important;
  }

  .ant-table-cell {
    background: none !important;
    border-bottom: none !important;

    &:before {
      display: none;
    }
  }

  .ant-table-row {
    background: #f9f9f9;
    margin-bottom: 2px;
    min-height: 53px;
    margin-bottom: 2px;
  }

  .ant-table-content table {
    border-spacing: 0 2px;
  }

  .ant-pagination {
    display: none;
  }
`

export const MobileInfoText = styled.span`
  font-weight: ${variables.fontWeight400};
  font-size: ${variables.fontSize14};
  line-height: 150%;
  color: ${colors.greyTextWhiteBg};
  margin-top: 20px;
  text-align: center;
`

export const HeaderTitle = styled.span`
  font-weight: ${variables.fontWeight400};
  font-size: ${variables.fontSize14};
  line-height: 150%;
  color: ${colors.mainText};
`

export const InfoText = styled.span`
  font-weight: ${variables.fontWeight400};
  font-size: ${variables.fontSize14};
  line-height: 150%;
  color: ${colors.greyTextWhiteBg};
`

export const DescriptionCard = styled.div`
  display: grid;
  column-gap: 16px;
  grid-template-columns: 14.4px 1fr;

  & svg {
    width: 14.4px;
    height: 16px;
    margin-top: 4px;
  }
`

export const Amount = styled.span<{ green?: boolean }>`
  font-weight: ${variables.fontWeight400};
  font-size: ${variables.fontSize14};
  line-height: 150%;
  color: ${({ green }) => (green ? colors.green : colors.mainText)};
`
