import { FC, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'

import { DottedBtn } from 'src/Components/DottedBtn/DottedBtn'

import PlaylistCard from '../PlaylistCard'
import { PlaylistIconData } from '../../types'

import {
  HideButtonWrapper,
  NoPlaylistsMessage,
  PlaylistsContainer,
} from './styles'

interface PlaylistsBlockProps {
  playlists: Array<PlaylistIconData>
}

const OPENER_INDEX_MOB = 5
const OPENER_INDEX_DESC = 4

const PlaylistsBlock: FC<PlaylistsBlockProps> = ({ playlists }) => {
  const { t } = useTranslation()
  const isMobile = useMediaQuery({ maxWidth: 576 })

  const [isShortList, setIsShortList] = useState(true)
  const [isDetails, setIsDetails] = useState(false)

  const openerIndex = isMobile ? OPENER_INDEX_MOB : OPENER_INDEX_DESC

  const isPlaylistsExisted = playlists.length > 0

  const showMorePlaylistsCount = playlists.length - openerIndex - 1

  const onOpenerClick = (): void => {
    setIsShortList((prevState) => !prevState)
  }

  const onCloseLongList = (): void => {
    setIsShortList((prevState) => !prevState)
  }

  const onDetailsClick = (): void => {
    setIsDetails((prevState) => !prevState)
  }

  if (!isPlaylistsExisted) {
    return (
      <NoPlaylistsMessage>{t('levelsPage.noPlaylists')}</NoPlaylistsMessage>
    )
  }
  const showCount = isShortList ? openerIndex + 1 : playlists.length
  return (
    <div>
      <PlaylistsContainer isDetailsMode={isDetails}>
        {[...playlists.slice(0, showCount)].map((item, index) => {
          const isOpener =
            index === showCount - 1 && index !== playlists.length - 1
          return (
            <PlaylistCard
              isDetailsMode={isDetails}
              key={item.id}
              playlist={item}
              isOpener={isOpener}
              onOpenerClick={onOpenerClick}
              showMorePlaylistsCount={showMorePlaylistsCount}
              isShortList={isShortList}
            />
          )
        })}
      </PlaylistsContainer>

      {!isShortList && (
        <HideButtonWrapper onClick={onCloseLongList}>
          <DottedBtn content={t('levelsPage.hide')} />
        </HideButtonWrapper>
      )}

      {isMobile && (
        <HideButtonWrapper onClick={onDetailsClick}>
          <DottedBtn
            content={
              isDetails
                ? t('levelsPage.hideDetails')
                : t('levelsPage.showDetails')
            }
          />
        </HideButtonWrapper>
      )}
    </div>
  )
}

export default PlaylistsBlock
