import React from 'react'

import Spotify from 'src/Assets/Svg/SpotifyAlert.svg?react'
import Success from 'src/Assets/Svg/SuccessAlert.svg?react'
import Danger from 'src/Assets/Svg/Danger.svg?react'
import Warning from 'src/Assets/Svg/warning.svg?react'
import Protection from 'src/Assets/Svg/ProtectionAlert.svg?react'

const iconsForAlerts = {
  success: Success,
  spotify: Spotify,
  danger: Danger,
  warning: Warning,
  protection: Protection,
}

export type IconTypeForAlerts = keyof typeof iconsForAlerts

interface IIconForAlertsProps {
  icon: IconTypeForAlerts
}
export const Icon: React.FC<IIconForAlertsProps> = ({ icon }) => {
  const IconCurrent = iconsForAlerts[icon]

  return <IconCurrent height='16' width='16' data-testid={icon} />
}
