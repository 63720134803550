import { APP } from 'src/Configs/App'
import { PaymentStatusEnum } from 'src/Constants/enums'
import { formatDate } from 'src/Constants/formatDate'
import _dayjs from 'src/Helpers/dayjs'
import { rtkApi } from 'src/Services/rtkApi'
import { CampaignPlatformTypeEnum, TransactionTypeEnum } from 'src/Types'

interface BalanceTrack {
  trackName: string
  trackAuthorName: string
}

interface ArtistBalanceItemResponse {
  amount: string
  createdAt: string
  trackName: string
  campaignId?: number
  typeTransaction: string
  packageName?: string
  artistName: string
  platformType: CampaignPlatformTypeEnum
  referralData?: {
    firstName: string
    trackName: string
  }
  invitedData?: {
    firstName: string
  }
}

export interface ArtistBalanceItem {
  id: string
  date: string
  createdAt?: string
  transactionType: TransactionTypeEnum
  packageName?: string
  track: BalanceTrack
  amount: number
  platformType: CampaignPlatformTypeEnum
  referralData?: {
    firstName: string
    trackName: string
  }
  invitedData?: {
    firstName: string
  }
}

const artistBalanceHistoryApi = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    getArtistBalanceHistory: builder.query<ArtistBalanceItem[], void | null>({
      query: () => `${APP.TIKTOK_SERVER}/artist/balance/details`,
      transformResponse: (response: ArtistBalanceItemResponse[]) => {
        if (!response) {
          return []
        }
        return response.map((item, index) => {
          let transactionType: TransactionTypeEnum
          if (item.typeTransaction === 'refunded') {
            transactionType = TransactionTypeEnum.RECEIVED
          } else if (
            item.typeTransaction === PaymentStatusEnum.PAYED ||
            item.typeTransaction === PaymentStatusEnum.COMPLETED
          ) {
            transactionType = TransactionTypeEnum.PAYMENT
          } else if (item.typeTransaction === PaymentStatusEnum.CHARGED_BACK) {
            transactionType = TransactionTypeEnum.CHARGEBACK
          } else {
            transactionType = item.typeTransaction as TransactionTypeEnum
          }

          return {
            transactionType,
            id: `${item.campaignId ?? 0}${item.typeTransaction}${index}`,
            date: _dayjs(item.createdAt).format(formatDate.formatDateFirst),
            createdAt: item.createdAt,
            track: {
              trackName: item.trackName,
              trackAuthorName: item.artistName,
            },
            amount: Math.abs(Number(item.amount)),
            packageName: item.packageName,
            platformType: item.platformType,
            referralData: item.referralData,
            invitedData: item.invitedData,
          }
        })
      },
    }),
  }),
})
const typeOrder: { [key in TransactionTypeEnum]: number } = {
  [TransactionTypeEnum.CASHBACK]: 1,
  [TransactionTypeEnum.PAYMENT]: 2,
  [TransactionTypeEnum.USED]: 3,
  [TransactionTypeEnum.RECEIVED]: 4,
  [TransactionTypeEnum.BONUS]: 5,
  [TransactionTypeEnum.CHARGEBACK]: 6,
  [TransactionTypeEnum.REFERRAL_BONUS]: 7,
  [TransactionTypeEnum.INVITED_BONUS]: 8,
  [TransactionTypeEnum.SUBSCRIPTION]: 9,
  [TransactionTypeEnum.SUBSCRIPTION_PAYMENT_FAILED]: 10,
  [TransactionTypeEnum.QUICK_CHECKOUT_BONUS_FUTURE]: 11,
  [TransactionTypeEnum.QUICK_CHECKOUT_BONUS_CURRENT]: 12,
}

export const getArtistBalanceHistory = (
  artistBalanceHistory?: ArtistBalanceItem[],
): ArtistBalanceItem[] => {
  if (!artistBalanceHistory) {
    return []
  }
  return artistBalanceHistory
    ?.filter((transaction) => transaction.amount !== 0)
    ?.sort((a, b) => {
      const dateDifference =
        new Date(b.createdAt ?? '').getTime() -
        new Date(a.createdAt ?? '').getTime()
      if (dateDifference !== 0) {
        return dateDifference
      }

      return typeOrder[a.transactionType] - typeOrder[b.transactionType]
    })
}

interface GetIsArtistNotFromInvitedLink {
  data?: ArtistBalanceItem[]
  isSuccess: boolean
}

export const getIsArtistNotFromInvitedLink = ({
  data,
  isSuccess,
}: GetIsArtistNotFromInvitedLink): { isArtistNotFromInvitedLink: boolean } => {
  if (!isSuccess) {
    return { isArtistNotFromInvitedLink: false }
  }

  const isInvitedBonus = data?.some(
    (item) => item.transactionType === TransactionTypeEnum.INVITED_BONUS,
  )

  return { isArtistNotFromInvitedLink: !isInvitedBonus }
}

export const { useGetArtistBalanceHistoryQuery } = artistBalanceHistoryApi
