import { useParams } from 'react-router-dom'

import { CampaignTiktokDetails, useGetCampaignTiktokDetailsQuery } from '.'

type TikTokCampaignDetails = {
  campaignDetails: CampaignTiktokDetails | undefined
  isLoading: boolean
  isError: boolean
  isSuccess: boolean
}

export const useTiktokCampaignDetails = (): TikTokCampaignDetails => {
  const { campaignID } = useParams<{ campaignID: string }>()
  const { data: campaignDetails, ...restData } =
    useGetCampaignTiktokDetailsQuery(campaignID)

  return { campaignDetails, ...restData }
}
