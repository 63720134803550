import styled from 'styled-components'

export const BlockOfCards = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;
  margin-bottom: 20px;
  overflow-x: scroll;
`
