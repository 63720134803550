import styled, { createGlobalStyle } from 'styled-components'

import { device, variables } from 'src/Styled/variables'
import { CrossButton } from 'src/Components/Buttons/CrossButton'
export const RelativeWrapper = styled.div`
  position: relative;
`
export const NotificationsTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 4px;
  padding-left: 5px;
`

export const Title = styled.p`
  font-size: ${variables.fontSize14};
  line-height: 16px;
`

export const TopBarNewLabel = styled.div`
  position: absolute;
  right: 9px;
  top: 20px;

  & > div {
    position: relative;

    &:before {
      background-color: rgba(224, 83, 111, 0.2);
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      border-radius: 50%;
      transform: translate(-50%, -50%);
      animation: beforePulse 1.5s infinite;
    }

    &:after {
      height: 7px;
      width: 7px;
      background-color: #e0536f;
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      border-radius: 50%;
      transform: translate(-50%, -50%);
    }
  }
`
export const TopBarButton = styled.button`
  font-size: 18px;
  height: 100%;
  padding: 0 10px;
  cursor: pointer;
  position: relative;
  display: flex;
  border: none;
  background: transparent;
  transition: all 0.3s;

  &:hover {
    background: rgba(77, 77, 77, 0.5);
  }

  svg {
    margin: auto;
    height: 18px;
    width: 18px;
    fill: white;
  }
`

export const GlobalStyled = createGlobalStyle`


.notifications .ant-popover-content {
  inset-inline-start: 0;
  width: 100% !important;
  padding: 0px 16px 16px 16px !important;
  @media ${device.mobileMin} {
    width: 512px !important;
  }
}

.notifications .ant-popover-title {
  margin-bottom: 0px;
}
.notifications .ant-popover-inner {
  border-radius: 4px !important;
  width: 100% !important;
  padding: 8px 4px 16px 11px !important;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
  @media ${device.mobileMin} {
    width: 480px !important;
  }
}

.notifications .ant-popover-arrow{
  display: none !important;
}

  
.ant-modal {
  top: 25%;
}
  
`

export const CloseButton = styled(CrossButton)`
  width: 40px;
  height: 40px;
  > svg {
    height: 12px !important;
  }
`
