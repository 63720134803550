import styled from 'styled-components'

import { colors, variables } from 'src/Styled/variables'

export const Divider = styled.div`
  margin-bottom: 24px;
  height: 1px;
  background-color: ${colors.greyStroke};
`

export const DividerWithBottomMargin = styled(Divider)`
  margin-top: 24px;
`

export const CampaignSummaryTitle = styled.h3`
  margin: 0 0 16px 0;
  font-size: ${variables.fontSize18};
  line-height: 27px;
  font-weight: ${variables.fontWeight600};
  color: ${colors.mainText};
`
export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  line-height: 150%;
`

export const TopRow = styled(Row)`
  margin-bottom: 12px;
`

export const BottomRow = styled(Row)`
  margin-top: 16px;
`

export const RowText = styled.span<{ bold?: boolean; isGreen?: boolean }>`
  font-size: ${variables.fontSize14};
  line-height: 21px;
  font-weight: ${({ bold }) =>
    bold ? variables.fontWeight600 : variables.fontWeight400};
  color: ${({ isGreen }) => (isGreen ? colors.green : colors.mainText)};
`

export const CampaignsTracksPromocode = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: ${variables.fontWeight600};
  margin-bottom: 16px;
`

export const CampaignsTracksPromocodeDiscount = styled.div`
  color: ${colors.green};
  font-weight: ${variables.fontWeight400};
`

export const CampaignsTracksPromocodeExist = styled.div`
  color: ${colors.greyTextBlackBg};
  text-decoration-line: line-through;
  text-decoration-color: ${colors.messegesRed};
`
