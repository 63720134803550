import { useCallback } from 'react'

import { useSelector } from 'react-redux'
import { DebouncedState, useDebouncedCallback } from 'use-debounce'
import { useParams } from 'react-router-dom'

import { getFreeSpotifyAccessToken } from 'src/Redux/spotifyToken-process/api-actions'
import { useAppDispatch } from 'src/Hooks/redux'
import {
  CampaignPlatformTypeEnum,
  CustomError,
  PageTypeEnum,
  Track,
} from 'src/Types'
import { setTracksToInitialState } from 'src/Redux/spotifyTracks-process'
import {
  setTikTokTrackFromSearchList,
  setTiktokTracksToInitialState,
} from 'src/Redux/tiktokTrack-process'
import { DEBOUNCE_TIMER_1000 } from 'src/Constants/constants'
import { usePageViewEvent } from 'src/Hooks/Analytics/useTrackEvents'
import { getSpotifyToken } from 'src/Redux/spotifyToken-process/selectors'
import { getSpotifyTrack } from 'src/Redux/track-process/api-actions'
import {
  submitTikTokSearchTrackError,
  searchTikTokTrackByName,
  getTikTokTrackWithoutAuth,
} from 'src/Redux/tiktokTrack-process/api-actions'
import { setSchedulePlatform } from 'src/Redux/campaign-process/spotifyCampaignSchedule-process'
import { getSpotifyTracks } from 'src/Redux/spotifyTracks-process/api-actions'

const isTikTokTrackUrl = (value?: string): boolean => {
  if (!value) {
    return false
  }

  return (
    value.startsWith('https://vm.tiktok.com/') ||
    value.startsWith('https://www.tiktok.com/music/') ||
    value.startsWith('https://vt.tiktok.com/') ||
    value.startsWith('https://www.tiktok.com/t/')
  )
}

export const useTrackSearch = (): {
  getTokenHandler: () => void
  setTracksToInitialStateHandler: () => void
  debouncedFindTrack: DebouncedState<(currentText: string) => void>
  debouncedOnCheckTrack: DebouncedState<(url: string) => void>
  trackSearchHandler: ({ searchInput }: { searchInput: string }) => void
} => {
  const dispatch = useAppDispatch()
  const { platform } = useParams<{
    platform: CampaignPlatformTypeEnum
  }>()
  const accessToken = useSelector(getSpotifyToken)

  const {
    enteredTrackUrlEventHandler,
    startedEnterTrackEventHandler,
    trackAddedEventHandler,
  } = usePageViewEvent()

  const setTracksToInitialStateHandler = useCallback((): void => {
    if (platform === CampaignPlatformTypeEnum.SPOTIFY) {
      dispatch(setTracksToInitialState())
    }
    if (platform === CampaignPlatformTypeEnum.TIKTOK) {
      dispatch(setTiktokTracksToInitialState())
    }
  }, [dispatch, platform])

  const getTokenHandler = (): void => {
    void dispatch(getFreeSpotifyAccessToken())
  }

  const trackSearchHandler = ({
    searchInput: searchInputValue,
  }: {
    searchInput: string
  }): void => {
    if (
      platform === CampaignPlatformTypeEnum.SPOTIFY &&
      accessToken &&
      searchInputValue
    ) {
      void dispatch(
        getSpotifyTracks({ search: searchInputValue, token: accessToken }),
      )

      return
    }

    if (platform === CampaignPlatformTypeEnum.TIKTOK) {
      if (!isTikTokTrackUrl(searchInputValue)) {
        void dispatch(
          searchTikTokTrackByName({
            searchText: searchInputValue,
          }),
        )
      } else {
        void dispatch(
          getTikTokTrackWithoutAuth({
            searchText: searchInputValue,
          }),
        )
      }
    }
  }

  const debouncedFindTrack = useDebouncedCallback((currentText: string) => {
    platform && trackSearchHandler({ searchInput: currentText })

    if (isTikTokTrackUrl(currentText)) {
      enteredTrackUrlEventHandler()
    } else {
      startedEnterTrackEventHandler()
    }
  }, DEBOUNCE_TIMER_1000)

  const onSuccessCheckSpotifyTrack = ({
    error,
    track,
    url,
  }: {
    error?: CustomError
    track?: Track
    url: string
  }): void => {
    if (error) {
      getTokenHandler()
      return
    }

    if (track) {
      trackAddedEventHandler({
        artistName: track.artists[0].name,
        platformType: CampaignPlatformTypeEnum.SPOTIFY,
        trackDuration: track.duration_ms,
        trackId: track.id,
        trackName: track.name,
      })

      localStorage.setItem('trackURL', url)
      dispatch(setTracksToInitialState())
      dispatch(setSchedulePlatform(null))
    }
  }

  const debouncedOnCheckTrack = useDebouncedCallback((url: string) => {
    const isSpotifyPlatform =
      platform === CampaignPlatformTypeEnum.SPOTIFY && accessToken
    const isTikTokPlatform = platform === CampaignPlatformTypeEnum.TIKTOK

    if (isSpotifyPlatform) {
      void dispatch(getSpotifyTrack({ url, token: accessToken }))
        .unwrap()
        .then((res) => {
          onSuccessCheckSpotifyTrack({ track: res, url })
          return res
        })
        .catch((err) => {
          onSuccessCheckSpotifyTrack({ error: err as CustomError, url })
        })
    }

    if (isTikTokPlatform) {
      void dispatch(getTikTokTrackWithoutAuth({ searchText: url }))
        .unwrap()
        .then((payload) => {
          trackAddedEventHandler({
            artistName: payload.trackAuthorName,
            platformType: CampaignPlatformTypeEnum.TIKTOK,
            trackDuration: payload.trackDuration,
            trackId: payload.trackTiktokId,
            trackName: payload.trackTitle,
          })
          dispatch(setTikTokTrackFromSearchList(payload))
          dispatch(setSchedulePlatform(null))
          return payload
        })
        .catch((error: { message: string }) => {
          void dispatch(
            submitTikTokSearchTrackError({
              platformType: CampaignPlatformTypeEnum.TIKTOK,
              pageType: PageTypeEnum.CreateCampaignWithoutSignup,
              errorMessage: error?.message || '',
              trackUrl: url,
            }),
          )
        })
      dispatch(setTiktokTracksToInitialState())
    }
  }, DEBOUNCE_TIMER_1000)

  return {
    getTokenHandler,
    setTracksToInitialStateHandler,
    debouncedFindTrack,
    debouncedOnCheckTrack,
    trackSearchHandler,
  }
}
