import { useEffect } from 'react'

import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { getIsPageVisited } from 'src/Redux/sidebar-process/selectors'
import { setVisitedNewPages } from 'src/Redux/sidebar-process'
import { useAppDispatch } from 'src/Hooks/redux'

interface UseSidebarLinkProps {
  route: string
  isNew: boolean
}

export const useSidebarLink = ({ route, isNew }: UseSidebarLinkProps): void => {
  const { pathname } = useLocation()
  const isVisitedNewPage = useSelector(getIsPageVisited(route))

  const dispatch = useAppDispatch()

  useEffect(() => {
    pathname === route &&
      isNew &&
      !isVisitedNewPage &&
      dispatch(setVisitedNewPages(pathname))
  }, [pathname, isVisitedNewPage, dispatch, isNew, route])
}
