import { FC } from 'react'

import i18n from 'src/Localization/i18n'

import { PrimaryAlert } from '../PrimaryAlert'
import { PaymentErrors } from '../PaymentErrorsAlert'

type ScheduleCheckoutErrorsProps = {
  name: PaymentErrors
}

const renderRespondError = (
  errorName: PaymentErrors,
): {
  title: string
  message: string
} => {
  switch (errorName) {
    case PaymentErrors.ACCOUNT_BALANCE:
      return {
        title: i18n.t('paymentError.balanceTitle'),
        message: i18n.t('paymentError.balanceMessage'),
      }
    case PaymentErrors.PAYPAL_FUNDS:
      return {
        title: i18n.t('paymentError.ppbalanceTitle'),
        message: i18n.t('paymentError.ppbalanceMessage'),
      }
    case PaymentErrors.PROMO_CODE:
      return {
        title: i18n.t('paymentError.couponTitle'),
        message: i18n.t('paymentError.couponMessage'),
      }
    case PaymentErrors.REFERENCE_TRANSACTION:
      return {
        title: i18n.t('paymentError.referenceTransactionTitle'),
        message: i18n.t('paymentError.referenceTransactionMessage'),
      }
    default:
      return {
        title: i18n.t('paymentError.defaultTitle'),
        message: i18n.t('paymentError.defaultMessage'),
      }
  }
}

export const ScheduleCheckoutErrors: FC<ScheduleCheckoutErrorsProps> = ({
  name,
}) => (
  <PrimaryAlert type='danger' hasCross={false} {...renderRespondError(name)} />
)
