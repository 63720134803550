import React from 'react'

import { useTranslation } from 'react-i18next'

import { AccentText, PlacementContainer, ReviewPlaylist } from '../ReviewBlock'
import { PlaylistInfo } from '../PlaylistInfo'

export const AddedPlaylistInfo: React.FC<ReviewPlaylist> = ({
  playlistName,
  playlistGenres,
  playlistUrl,
}) => {
  const { t } = useTranslation()

  return (
    <PlacementContainer>
      <AccentText>{t('campaignDetailsPage.trackAdded')}</AccentText>

      <PlaylistInfo
        playlistGenres={playlistGenres}
        playlistName={playlistName}
        playlistUrl={playlistUrl}
      />
    </PlacementContainer>
  )
}
