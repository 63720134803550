import styled from 'styled-components'

import { variables, colors } from 'src/Styled/variables'

interface Props {
  invalid?: boolean
}

export const ChangeEmailFormContainer = styled.form`
  padding: 0px 0px 24px 24px;
  width: 100%;
  max-width: 424px;
`

export const ChangeEmailFormHeader = styled.div`
  line-height: 21px;
  color: ${colors.mainText};
  font-weight: ${variables.fontWeight400};
  font-size: ${variables.fontSize14};
  margin-bottom: 16px;
`

export const AlertTitle = styled.div`
  font-weight: ${variables.fontWeight600};
`

export const ChangeEmailInput = styled.input<Props>`
  height: 47px !important;
  font-size: ${variables.fontSize14};
  border: 1px solid ${colors.greyStroke};
  border-radius: 8px;
  outline: none;
  padding: 16px;
  width: 100%;
  margin-bottom: ${(props: Props) => (props.invalid ? '8px' : '24px')};
  background-color: ${colors.white};
  line-height: 21px;
  color: ${colors.greyTextWhiteBg};
  &:focus {
    background-color: ${colors.white};
    outline: none;
    color: ${colors.mainText};
  }
  &:active {
    background-color: ${colors.white};
    outline: none;
    color: ${colors.mainText};
  }
  &::placeholder {
    color: ${colors.greyTextWhiteBg};
  }
  &:-webkit-text-fill-color {
    color: ${colors.mainText} !important;
  }
  &:selected {
    color: ${colors.mainText} !important;
  }
  &:-webkit-autofill,
  -webkit-autofill:active,
  -webkit-autofill:focus,
  -webkit-autofill:hover {
    -webkit-text-fill-color: ${colors.mainText} !important;
    color: ${colors.mainText} !important;
  }
`

export const ErrorText = styled.div`
  text-align: left;
  color: ${colors.messegesRed};
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight400};
  margin-top: 0px;
  margin-bottom: 16px;
`
