import { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { useBonusTooltipTimer } from 'src/Hooks/useBonusTooltip'
import { CampaignPlatformTypeEnum } from 'src/Types'

import { Mobile } from '../Responsive'
import { ArtistProtection } from '../ArtistProtection'

import { ProtectionAlertMobileProps } from './ProtectionAlertMobileProps'

const StyledArtistProtection = styled(ArtistProtection)<{
  $isNextTimeBonus?: boolean
}>`
  padding-bottom: ${({ $isNextTimeBonus }) =>
    $isNextTimeBonus ? '65px' : '0'};
`

export const ProtectionAlertMobile: FunctionComponent<
  ProtectionAlertMobileProps
> = ({ platform }) => {
  const { t } = useTranslation()

  const { isNextTimeBonus } = useBonusTooltipTimer()

  const protectionText =
    platform === CampaignPlatformTypeEnum.SPOTIFY
      ? t('scheduleCampaignPage.artistProtectionSpotify')
      : t('scheduleCampaignPage.artistProtectionTikTok')

  return (
    <Mobile>
      <StyledArtistProtection $isNextTimeBonus={isNextTimeBonus}>
        {protectionText}
      </StyledArtistProtection>
    </Mobile>
  )
}
