import TagManager from 'react-gtm-module'

import { CampaignPlatformTypeEnum, CampaignTypeEnum } from 'src/Types'
import { PaymentMethodEnum, TagManagerEnum } from 'src/Constants/enums'
import { PAYPAL_SAVED } from 'src/Constants/constants'

import { EventNameEnum, EventTypeEnum } from '../tagManagerEnums'

export const checkoutViewEvent = ({
  campaignType,
  genreList,
  languageList,
  campaignId,
  resultPrice,
  platformName,
  userId,
  campaignNumber,
  paymentMethod,
  is_feed,
  subscriptionPlan,
}: {
  campaignType: string
  genreList: string
  languageList: string
  campaignId: number | string
  resultPrice: number
  userId?: number | string
  campaignNumber?: number
  platformName: string
  paymentMethod: PaymentMethodEnum
  is_feed?: boolean
  subscriptionPlan?: string
}): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventTypeEnum.CheckoutView,
      event_name: EventNameEnum.CheckoutView,
      'campaign-type': campaignType,
      'campaign-platform': platformName,
      'campaign-number':
        campaignType === CampaignTypeEnum.FIRST ? 1 : campaignNumber,
      'campaign-id': campaignId,
      ...(userId ? { userID: userId } : {}),
      'genre-list': genreList,
      language_list: languageList,
      'result-price': resultPrice,
      payment_method:
        paymentMethod === PaymentMethodEnum.PAYPAL_BILLING_AGREEMENT
          ? PAYPAL_SAVED
          : paymentMethod,
      is_feed,
      ...(subscriptionPlan ? { subscription_plan: subscriptionPlan } : {}),
    },
  })
}

export const checkoutConfirmedPayment = ({
  platformName,
  campaignType,
  campaignNumber,
  campaignId,
  userId,
  resultPrice,
  genreList,
  languageList,
  paymentMethod,
  isRememberPayPalForFuture,
  isDanceRequired,
  subscriptionPlan,
}: {
  platformName: string
  campaignType: string
  campaignNumber: number
  campaignId: number | string
  userId: number | string
  resultPrice: number
  genreList?: string
  languageList?: string
  paymentMethod: PaymentMethodEnum | string
  isRememberPayPalForFuture?: boolean
  isDanceRequired?: boolean
  subscriptionPlan?: string
}): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventTypeEnum.CheckoutConfirmedPayment,
      event_name: EventNameEnum.CheckoutConfirmedPayment,
      flow: 'checkout',
      'campaign-type': campaignType,
      'campaign-platform': platformName,
      'campaign-number':
        campaignType === CampaignTypeEnum.FIRST ? 1 : campaignNumber,
      'campaign-id': campaignId,
      ...(userId ? { userID: userId } : {}),
      ...(resultPrice ? { 'result-price': resultPrice } : {}),
      'genre-list': genreList,
      language_list: languageList,
      payment_method:
        campaignType === 'first' &&
        paymentMethod === PaymentMethodEnum.PAYPAL &&
        isRememberPayPalForFuture
          ? PAYPAL_SAVED
          : paymentMethod,
      is_feed: platformName === CampaignPlatformTypeEnum.SPOTIFY,
      ...(isDanceRequired ? { dance_required: isDanceRequired } : {}),
      ...(subscriptionPlan ? { subscription_plan: subscriptionPlan } : {}),
    },
  })
}

export const campaignBudgetUpdateEvent = ({
  campaignType,
  platformName,
  campaignNumber,
  campaignId,
  userId,
  resultPrice,
  genreList,
  languageList,
  is_feed,
  valueInput,
  subscriptionPlan,
}: {
  campaignType: string
  platformName: string
  campaignNumber: number
  campaignId: number
  userId: number | string
  resultPrice: number
  genreList: string
  languageList: string
  is_feed?: boolean
  valueInput?: string | null
  subscriptionPlan?: string
}): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventTypeEnum.CampaignBudgetUpdateEvent,
      event_name: EventNameEnum.CampaignBudgetUpdateEvent,
      flow: 'scheduling_campaign',
      'campaign-type': campaignType,
      'campaign-platform': platformName,
      'campaign-number':
        campaignType === CampaignTypeEnum.FIRST ? 1 : campaignNumber,
      'campaign-id': campaignId,
      ...(userId ? { userID: userId } : {}),
      'result-price': resultPrice,
      'genre-list': genreList,
      language_list: languageList,
      is_feed,
      ...(valueInput ? { value_input: valueInput } : {}),
      ...(subscriptionPlan ? { subscription_plan: subscriptionPlan } : {}),
    },
  })
}

export const campaignBudgetReceived = ({
  campaignType,
  platformName,
  campaignNumber,
  campaignId,
  userId,
  resultPrice,
  genreList,
  languageList,
  is_feed,
  isAnyLanguage,
  valueInput,
  subscriptionPlan,
}: {
  campaignType: string
  platformName: string
  campaignNumber: number
  campaignId: number
  userId: number | string
  resultPrice: number
  genreList: string
  languageList: string
  is_feed?: boolean
  isAnyLanguage?: boolean
  valueInput?: string | null
  subscriptionPlan?: string
}): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventTypeEnum.CampaignBudgetReceived,
      event_name: EventNameEnum.CampaignBudgetReceived,
      flow: 'scheduling_campaign',
      'campaign-type': campaignType,
      'campaign-platform': platformName,
      'campaign-number':
        campaignType === CampaignTypeEnum.FIRST ? 1 : campaignNumber,
      'campaign-id': campaignId,
      ...(userId ? { userID: userId } : {}),
      'result-price': resultPrice,
      'genre-list': genreList,
      language_list: languageList,
      is_feed,
      ...(platformName === CampaignPlatformTypeEnum.SPOTIFY
        ? {
            language_options: isAnyLanguage
              ? TagManagerEnum.WIDER_LANGUAGES_MATCHING
              : TagManagerEnum.SELECTED_LANGUAGES_ONLY,
          }
        : {}),
      ...(valueInput ? { value_input: valueInput } : {}),
      ...(subscriptionPlan ? { subscription_plan: subscriptionPlan } : {}),
    },
  })
}
