import styled from 'styled-components'

import { colors } from 'src/Styled/variables'

export const StepsButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`
export const CampaignStepsContainer = styled.div`
  display: flex;
  height: fit-content;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  border-radius: 4px;
  background-color: ${colors.white};
`
