import styled from 'styled-components'

import { colors, device, variables } from 'src/Styled/variables'

export const ButtonsBlockButtonsContainer = styled.div`
  display: none;
  @media ${device.tabletMin} {
    display: grid;
    align-self: center;
    padding-top: 16px;
  }
`

export const ContinueButtonWrapper = styled.div`
  > div {
    width: 170px;
    margin: 0 auto;
  }
`

export const PromoCodeButton = styled.div`
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 17.5px 0 7.5px;
  width: fit-content;
  font-size: ${variables.fontSize14};
  color: ${colors.mainText};
  border-bottom: 1px dashed ${colors.mainText};
  background-color: transparent;
  cursor: pointer;
  white-space: nowrap;
  line-height: 150%;
`
export const BonusAlertWrapper = styled.div`
  margin-bottom: 15px;
`
export const SecureContainer = styled.div`
  display: grid;
  grid-template-columns: 16px auto;
  gap: 4px;
  justify-content: center;
  padding-top: 8px;
`

export const SecureLabel = styled.div`
  color: ${colors.greyTextWhiteBg};
  font-size: ${variables.fontSize10};
  line-height: 150%;
`
