import React, { FC, ReactNode, useState } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { ActionMeta } from 'react-select'
import { useMediaQuery } from 'react-responsive'

import { DropdownIndicator } from 'src/Components/DropdownIndicator'
import { styledSelectCustomStyles } from 'src/Styled/StyledSelectCustomStyles'
import { colors, device, variables } from 'src/Styled/variables'
import { HintGuide } from 'src/Components/HintGuide/HintGuide'
import { Language } from 'src/Types'
import { HINT_LANGUAGES } from 'src/Constants/hints'

import PlaylistVocalSettingsModal from '../PlaylistVocalSettingsModal'

import { optionsSelectLanguages } from './helpers'
import { SelectOption } from './props'

import { StyledSelect } from './styles'

const LanguageTitle = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: ${variables.fontSize14};
  color: ${colors.mainText};
  font-weight: ${variables.fontWeight400};
  margin-bottom: 16px;
  @media ${device.mobileMin} {
    margin-bottom: 16px;
  }
`
const DesktopLanguageWrapper = styled.div`
  padding: 39px 24px 38px 25px;
  @media ${device.mobileMax} {
    margin-bottom: 13px;
    padding: 0;
  }
`

const LanguagesSelectItem = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight400};
  line-height: 21px;
`
interface PlaylistLanguagesSelectProps {
  languages: Language[]
  selectedInputLanguages: Language[]
  onChangeSelectLanguages: (
    value: unknown,
    actionMeta: ActionMeta<unknown>,
  ) => void
  handleSetHint: (hintName: string) => void
  hintName: string | null
}
export const PlaylistLanguagesSelect: FC<PlaylistLanguagesSelectProps> = ({
  languages,
  onChangeSelectLanguages,
  selectedInputLanguages,
  handleSetHint,
  hintName,
}) => {
  const { t } = useTranslation()
  const isMobile = useMediaQuery({ query: device.mobileMax })
  const [inputValueLanguages, setInputValueLanguages] = useState<string>('')
  const [modalVocal, setModalValueVocal] = useState<boolean | string>('')
  const handleInputChangeLanguages = (characterEntered: string): void => {
    setInputValueLanguages(characterEntered)
  }
  const formatOptionLabelLanguages = (
    data: unknown,
    formatOptionLabelMeta: unknown,
  ): ReactNode => {
    const { label } = data as SelectOption
    const { inputValue, selectValue } = formatOptionLabelMeta as {
      inputValue: string
      selectValue: SelectOption[]
    }
    const inputLength = inputValue.length

    const index = label.toLowerCase().indexOf(inputValue.toLowerCase())
    const selectValueLabel = selectValue.map((item) => item.label)

    if (selectValueLabel.includes(label)) {
      return <div>{label}</div>
    }

    return (
      <LanguagesSelectItem>
        <span>
          {label.slice(0, index)}
          <strong style={index === 0 ? { textTransform: 'capitalize' } : {}}>
            {inputValueLanguages}
          </strong>
          {label.slice(index + inputLength)}
        </span>
        <span />
      </LanguagesSelectItem>
    )
  }
  const handleHint = (name: string): void => {
    if (isMobile) {
      setModalValueVocal(name)
    } else {
      handleSetHint(name)
    }
  }
  return (
    <DesktopLanguageWrapper>
      <LanguageTitle>
        <div>{t('scheduleCampaignPage.targetLanguages')}</div>
        <HintGuide
          hintName={HINT_LANGUAGES}
          setHintName={handleHint}
          currentHint={hintName}
        />
        <PlaylistVocalSettingsModal
          isOpen={Boolean(modalVocal)}
          toggle={setModalValueVocal}
        />
      </LanguageTitle>
      <div>
        <StyledSelect
          isMulti
          placeholder={t('createCampaignPage.noVocal')}
          styles={styledSelectCustomStyles}
          classNamePrefix='language-select'
          closeMenuOnSelect={false}
          options={optionsSelectLanguages(languages)}
          value={selectedInputLanguages}
          inputValue={inputValueLanguages}
          components={{ DropdownIndicator }}
          formatOptionLabel={formatOptionLabelLanguages}
          onInputChange={handleInputChangeLanguages}
          onChange={onChangeSelectLanguages}
        />
      </div>
    </DesktopLanguageWrapper>
  )
}
