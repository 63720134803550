import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'

import { CampaignStatusResponse, Genre, Language, Mood } from 'src/Types'
import { api } from 'src/Services/api'
import { APP } from 'src/Configs/App'

import { ErrorCode, NameSpace } from '../../types'

interface CampaignStatusBody {
  campaignId: number
}

export const getCampaignStatus = createAsyncThunk<
  CampaignStatusResponse,
  CampaignStatusBody
>(
  `${NameSpace.Campaign}/getCampaignStatus`,
  async ({ campaignId }, { rejectWithValue }) => {
    try {
      const { data } = await api.get<CampaignStatusResponse>(
        `${APP.SERVER}/campaign/status/${campaignId}`,
      )
      return data
    } catch (error) {
      const { message, response } = error as AxiosError
      return rejectWithValue({
        message,
        code: response?.status ?? ErrorCode.InternalServerError,
      })
    }
  },
)

interface ChangeCampaignBody {
  campaign_id: number | string
  genres_ids: Array<number>
  languages_ids: Array<number | undefined>
  moods_ids?: Array<number> | null
  max_price: number | null
}

interface ChangeCampaignResponse {
  curatorsCount: number
  playlistsCount: number
  price: number
  genres: Array<Genre>
  languages: Array<Language>
  moods: Array<Mood>
  minPlaylistsCount: number
  maxPlaylistsCount: number
  minCuratorsCount: number
  maxCuratorsCount: number
}

export const changeCampaign = createAsyncThunk<
  ChangeCampaignResponse,
  ChangeCampaignBody
>(
  `${NameSpace.Campaign}/changeCampaign`,
  async (
    { campaign_id, genres_ids, languages_ids, moods_ids, max_price },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await api.put<ChangeCampaignResponse>(
        `${APP.SERVER}/campaign/update-campaign`,
        {
          campaign_id,
          genres_ids,
          languages_ids,
          moods_ids,
          max_price,
        },
      )
      return { ...data, price: max_price ?? data.price }
    } catch (error) {
      const { message, response } = error as AxiosError
      return rejectWithValue({
        message,
        code: response?.status ?? ErrorCode.InternalServerError,
      })
    }
  },
)
