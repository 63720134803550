import React, { FC } from 'react'

import { useTranslation } from 'react-i18next'

import { numberFormatSpaces } from 'src/Constants/functions'

import {
  Card,
  CardAdditionalInfo,
  CardBody,
  CardMainValue,
  CardTitle,
  ConnectSFA,
  ConnectedSFA,
  ConnectedSFAContainer,
  DotConnectedSFA,
} from '../../styles'
import { DataNotReceivedBlock } from '../DataNotReceivedBlock'

interface ListenersCardProps {
  isSfaConnected: boolean
  openConnectSFAModal: () => void
  count: number
  title: string
}

export const ListenersCard: FC<ListenersCardProps> = (props) => {
  const { title, isSfaConnected, openConnectSFAModal, count } = props
  const { t } = useTranslation()
  return (
    <Card>
      <CardBody>
        <CardTitle>{title}</CardTitle>
        {!isSfaConnected ? (
          <DataNotReceivedBlock />
        ) : (
          <CardMainValue>{numberFormatSpaces(count)}</CardMainValue>
        )}
        <CardAdditionalInfo>
          {!isSfaConnected ? (
            <ConnectSFA onClick={openConnectSFAModal}>
              {t('campaignResultsPage.connectSpotifyForArtists')}
            </ConnectSFA>
          ) : (
            <ConnectedSFAContainer>
              <DotConnectedSFA />
              <ConnectedSFA>
                {t('campaignResultsPage.connectedSFA')}
              </ConnectedSFA>
            </ConnectedSFAContainer>
          )}
        </CardAdditionalInfo>
      </CardBody>
    </Card>
  )
}
