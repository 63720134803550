import { useState, FunctionComponent, useMemo, useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import { ReviewStatus } from 'src/Types/index'
import { useSpotifyCampaignDetails } from 'src/Redux/campaignDetailsApi/useSpotifyCampaignDetails'

import { CampaignResultsTabsProps, Tabs } from './props'

import { TabButton, TabButtonCount, TabTitles } from './styles'

export const CampaignResultsTabs: FunctionComponent<
  CampaignResultsTabsProps
> = ({ setFilteredReviews }) => {
  const { t } = useTranslation()
  const [active, setActiveLink] = useState<Tabs>(Tabs.all)

  const { campaignDetails } = useSpotifyCampaignDetails()

  const isMissedTabAvailable = campaignDetails?.is_feed

  useEffect(() => {
    campaignDetails?.Reviews && setFilteredReviews(campaignDetails?.Reviews)
  }, [campaignDetails, setFilteredReviews])

  const memoReviewedReviews = useMemo(
    () =>
      campaignDetails?.Reviews
        ? campaignDetails?.Reviews.filter(
            (i) => i.review_status === ReviewStatus.reviewed,
          )
        : [],
    [campaignDetails],
  )

  const memoAddedReviews = useMemo(
    () =>
      campaignDetails?.Reviews
        ? campaignDetails?.Reviews.filter((i) => i.playlist_id)
        : [],
    [campaignDetails],
  )

  const memoMissedReviews = useMemo(
    () =>
      campaignDetails?.Reviews
        ? campaignDetails?.Reviews.filter(
            (i) => i.review_status !== ReviewStatus.reviewed,
          )
        : [],
    [campaignDetails],
  )

  const toggle = (string: Tabs): void => {
    setActiveLink(string)

    switch (string) {
      case Tabs.all:
        campaignDetails?.Reviews && setFilteredReviews(campaignDetails?.Reviews)
        break
      case Tabs.added:
        setFilteredReviews(memoAddedReviews)
        break
      case Tabs.reviewed:
        setFilteredReviews(memoReviewedReviews)
        break
      case Tabs.missed:
        setFilteredReviews(memoMissedReviews)
        break
      default:
        campaignDetails?.Reviews && setFilteredReviews(campaignDetails?.Reviews)
    }
  }
  return (
    <TabTitles>
      <TabButton
        type='button'
        active={active === Tabs.all}
        onClick={() => toggle(Tabs.all)}
      >
        {t('campaignResultsPage.all')}
        <TabButtonCount>{campaignDetails?.Reviews.length}</TabButtonCount>
      </TabButton>
      <TabButton
        type='button'
        active={active === Tabs.reviewed}
        onClick={() => toggle(Tabs.reviewed)}
      >
        {t('campaignResultsPage.reviewed')}
        <TabButtonCount>{memoReviewedReviews.length}</TabButtonCount>
      </TabButton>
      <TabButton
        type='button'
        active={active === Tabs.added}
        onClick={() => toggle(Tabs.added)}
      >
        {t('campaignResultsPage.added')}
        <TabButtonCount green>{memoAddedReviews.length}</TabButtonCount>
      </TabButton>
      {isMissedTabAvailable && (
        <TabButton
          type='button'
          active={active === Tabs.missed}
          onClick={() => toggle(Tabs.missed)}
        >
          {t('campaignResultsPage.missed')}
          <TabButtonCount red>{memoMissedReviews.length}</TabButtonCount>
        </TabButton>
      )}
    </TabTitles>
  )
}
