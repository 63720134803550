import React, { FC, useState } from 'react'

import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import { Control, Controller } from 'react-hook-form'

import CloseIcon from 'src/Assets/Svg/common/cross.svg?react'
import Arrow from 'src/Assets/Svg/common/arrowLeft.svg?react'
import { MAX_MOBILE_WIDTH, variables } from 'src/Styled/variables'
import { FullScreenModal } from 'src/Components/FullScreenModal'
import { IconButton } from 'src/Components/Buttons/IconButton'
import { TrackAudioPlayer } from 'src/Components/TrackAudioPlayer'

import { MobileSearchInput } from '../MobileSearchInput'
import { SearchTrackFormData, TrackSearchProps } from '../../index'
import { Loading } from '../Loading'
import { TrackItem } from '../TrackItem'

const TracksContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  padding: 8px 16px 8px 8px;
`

const StyledIconButton = styled(IconButton)`
  border: none;
`

const MobileInputContainer = styled.div`
  display: flex;
  width: 100%;
  height: 56px;
  padding: 0px 8px;
  align-items: center;
  gap: 4px;
`

const MobileInputButton = styled.div`
  border-radius: 16px;
  padding: 16px;
  border: 1px solid ${({ theme }) => theme.common.primaryBorder};
  background: ${({ theme }) => theme.common.primaryBg};
  width: 100%;

  font-family: 'Segoe UI';
  font-size: ${variables.fontSize14};
  font-style: normal;
  font-weight: ${variables.fontWeight400};
  line-height: 150%;
  color: ${({ theme }) => theme.common.primaryPlaceholder};
`

type MobileTrackSearchProps = Pick<
  TrackSearchProps,
  | 'isLoading'
  | 'onItemClick'
  | 'onPlayClick'
  | 'tracks'
  | 'onSearch'
  | 'error'
  | 'defaultSearchValue'
> & {
  placeholder: string
  control: Control<SearchTrackFormData>
}

export const MobileTrackSearch: FC<MobileTrackSearchProps> = ({
  isLoading,
  onItemClick,
  onPlayClick,
  tracks = [],
  onSearch,
  error,
  defaultSearchValue,
  placeholder,
  control,
}) => {
  const [isMobileSearch, setIsMobileSearch] = useState(false)

  const isMobile = useMediaQuery({ maxWidth: MAX_MOBILE_WIDTH })

  if (!isMobile) {
    return null
  }

  const onEnterToMobileSearch = (): void => {
    setIsMobileSearch(true)
  }

  const onMobileBackButtonClick = (): void => {
    setIsMobileSearch(false)
  }

  return (
    <>
      <MobileInputButton onClick={onEnterToMobileSearch}>
        {placeholder}
      </MobileInputButton>
      <FullScreenModal open={isMobileSearch}>
        <MobileInputContainer>
          <StyledIconButton onClick={onMobileBackButtonClick}>
            <Arrow />
          </StyledIconButton>

          <Controller
            name='searchInput'
            control={control}
            defaultValue={defaultSearchValue || ''}
            render={({ field: { onChange, onBlur, value } }) => (
              <MobileSearchInput
                placeholder={placeholder}
                allowClear={{ clearIcon: <CloseIcon /> }}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                  onSearch(e.target.value)
                }
              />
            )}
          />
        </MobileInputContainer>
        {isLoading && (
          <TracksContainer>
            <Loading />
          </TracksContainer>
        )}

        {error && <TracksContainer>{error}</TracksContainer>}

        {tracks?.length && !error ? (
          <TracksContainer>
            <TrackAudioPlayer
              render={({ loading, isPlaying, onClick, currentTrackUrl }) => (
                <>
                  {tracks.map((item) => (
                    <TrackItem
                      key={item.id}
                      onItemClick={() => onItemClick(item.id)}
                      trackName={item.name}
                      artistName={item.artistName}
                      trackDuration={item.trackDuration}
                      trackCoverUrl={item.trackCoverUrl}
                      loading={loading && currentTrackUrl === item.previewUrl}
                      onTrackControlClick={(e) => {
                        onClick(e, item.previewUrl)
                        if (!isPlaying) {
                          onPlayClick && onPlayClick()
                        }
                      }}
                      isPlaying={
                        isPlaying && currentTrackUrl === item.previewUrl
                      }
                      show={Boolean(item.previewUrl)}
                    />
                  ))}
                </>
              )}
            />
          </TracksContainer>
        ) : null}
      </FullScreenModal>
    </>
  )
}
