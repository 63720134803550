import { createDraftSafeSelector, createSlice } from '@reduxjs/toolkit'

import dayJs from 'src/Helpers/dayjs'
import { SpotifyCampaign, TiktokCampaign } from 'src/Types'
import { AppSelector, setApiError } from 'src/Hooks/redux'

import { ApiError, NameSpace, SliceStatus } from '../types'
import { RootState } from '..'

import { getCampaignsList } from './api-actions'
interface CampaignListState {
  error: ApiError | null
  campaigns: Array<TiktokCampaign | SpotifyCampaign>
  campaignsSpotify: Array<SpotifyCampaign>
  campaignsTiktok: Array<TiktokCampaign>
  amountOfCampaigns: number
  status: SliceStatus
}

const initialState: CampaignListState = {
  campaigns: [],
  campaignsSpotify: [],
  campaignsTiktok: [],
  amountOfCampaigns: 0,
  status: SliceStatus.Idle,
  error: null,
}

export const campaignListProcess = createSlice({
  initialState,
  name: NameSpace.CampaignList,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getCampaignsList.pending, (state) => {
      state.status = SliceStatus.Loading
    })
    builder.addCase(getCampaignsList.rejected, (state, action) => {
      state.status = SliceStatus.Failed
      state.error = setApiError(action)
    })
    builder.addCase(getCampaignsList.fulfilled, (state, action) => {
      state.campaigns = action.payload.campaigns
      state.campaignsSpotify = action.payload.spotifyCampaigns
      state.campaignsTiktok = action.payload.tiktokCampaigns
      state.amountOfCampaigns = action.payload.campaigns.length
      state.error = null
      state.status = SliceStatus.Succeeded
    })
  },
})
const getState = (state: RootState): CampaignListState => state.campaignList

export const getCampaignsLoader = createDraftSafeSelector(
  getState,
  ({ status }) => status === SliceStatus.Loading,
)
export const getCampaigns = createDraftSafeSelector(
  getState,
  ({ campaigns }) => campaigns,
)

export const getEndedCompaniesAfterDate = (
  date: string = dayJs().toISOString(),
): AppSelector<boolean> =>
  createDraftSafeSelector(getCampaigns, (campaigns) => {
    const campaign = campaigns
      ?.filter((item) => item.status === 'ended' && item?.endDate)
      ?.find((item) => {
        const endDate = dayJs(item?.endDate)
        const closedDate = dayJs(date)
        return endDate.isAfter(closedDate)
      })
    return Boolean(campaign)
  })

export const getAmountOfCampaigns = createDraftSafeSelector(
  getState,
  ({ amountOfCampaigns }) => amountOfCampaigns,
)
export const getSpotifyCampaigns = createDraftSafeSelector(
  getState,
  ({ campaignsSpotify }) => campaignsSpotify,
)

export const getTiktokCampaigns = createDraftSafeSelector(
  getState,
  ({ campaignsTiktok }) => campaignsTiktok,
)
