import React, { FC, useEffect, useState } from 'react'

import { useGrowthBook } from '@growthbook/growthbook-react'

interface VisualBlockExperimentProps {
  children: [React.ReactNode, React.ReactNode]
}

export enum VisualBlockVariant {
  TrackDetailsVariant = 0,
  VisualBlockDeskMob = 1,
  VisualBlockDesk = 2,
}

export const SIGNUP_VISUAL_BLOCK_FEATURE_NAME = 'signup_visual_block_v1'

export const VisualBlockExperiment: FC<VisualBlockExperimentProps> = ({
  children,
}) => {
  const [isSecondChild, setIsSecondChild] = useState(false)
  const growthBook = useGrowthBook()

  useEffect(() => {
    const variant = growthBook.getFeatureValue(
      SIGNUP_VISUAL_BLOCK_FEATURE_NAME,
      VisualBlockVariant.TrackDetailsVariant,
    ) as VisualBlockVariant

    if (
      variant === VisualBlockVariant.VisualBlockDeskMob ||
      variant === VisualBlockVariant.VisualBlockDesk
    ) {
      setIsSecondChild(true)
    }
  }, [growthBook])

  if (isSecondChild) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children[1]}</>
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children[0]}</>
}
