import {
  StringSchema,
  string as yupString,
  object,
  AnyObject,
  ObjectSchema,
} from 'yup'

import {
  TransferwiseRequirementField,
  TransferwiseRequirementGroup,
} from 'src/Types/index'

const getFieldList = (
  fields: TransferwiseRequirementField[],
): TransferwiseRequirementGroup[] =>
  fields.reduce(
    (accumulator, property) => [...accumulator, ...property.group],
    [] as TransferwiseRequirementGroup[],
  )

const getNestedFieldsSchema = (
  fieldList: TransferwiseRequirementGroup[],
): Record<string, ObjectSchema<AnyObject>> => {
  const accumulator: Record<string, Record<string, StringSchema>> = {}

  for (const field of fieldList) {
    const path = field.key.split('.')
    if (path.length === 2) {
      const [outerKey, nestedKey] = path
      const nesting = accumulator[outerKey] || {}
      let validator = yupString()

      if (field.required) {
        validator = validator.required('Field is required.')
      }
      nesting[nestedKey] = validator.test(nestedKey, 'Invalid value', (value) =>
        new RegExp(field.validationRegexp || '').test(value || ''),
      )
      accumulator[outerKey] = nesting
    }
  }
  const result: Record<string, ObjectSchema<AnyObject>> = {}
  for (const property in accumulator) {
    if (Object.prototype.hasOwnProperty.call(accumulator, property)) {
      result[property] = object().shape(accumulator[property])
    }
  }

  return result
}

const getFieldsSchema = (
  fieldList: TransferwiseRequirementGroup[],
): Record<string, StringSchema> => {
  const accumulator: Record<string, StringSchema> = {}
  for (const field of fieldList) {
    const isNestedKey = field.key.includes('.')
    if (!isNestedKey) {
      let validator = yupString()
      if (field.required) {
        validator = validator.required('Field is required.')
      }
      accumulator[field.key] = validator.test(
        field.key,
        'Invalid value',
        (value) => new RegExp(field.validationRegexp || '').test(value || ''),
      )
    }
  }
  return accumulator
}

export const getSchemaBankDetails = (
  fields: TransferwiseRequirementField[],
): ObjectSchema<AnyObject, AnyObject, AnyObject> => {
  const fieldList = getFieldList(fields)
  const nestedSchema = getNestedFieldsSchema(fieldList)
  const fieldsSchema = getFieldsSchema(fieldList)

  const obj: Record<string, StringSchema> = {
    accountHolderName: yupString().required(
      'Field account holder name is required',
    ),
    ...fieldsSchema,
    ...nestedSchema,
  }

  return object().shape(obj)
}
