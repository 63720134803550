import { createSlice } from '@reduxjs/toolkit'

import { setApiError } from 'src/Hooks/redux'

import { ApiError, NameSpace } from '../types'

import {
  paymentEventCompletedGA4,
  paymentEventCancelledGA4,
  checkoutStartMpEvent,
  firstCampaignCreationStartMpEvent,
  newCampaignCreationStartMpEvent,
  genreAddedMpEvent,
  languageAddedMpEvent,
  trackAddedMpEvent,
  scheduleStartMpEvent,
} from './api-actions'

export interface GA4State {
  loading: boolean
  error: ApiError | null
}

const initialState: GA4State = {
  loading: false,
  error: null,
}

export const googleAnalytics4Process = createSlice({
  initialState,
  name: NameSpace.GA4,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(paymentEventCompletedGA4.pending, (state) => {
      state.loading = true
    })
    builder.addCase(paymentEventCompletedGA4.rejected, (state, action) => {
      state.loading = false
      state.error = setApiError(action)
    })
    builder.addCase(paymentEventCompletedGA4.fulfilled, (state) => {
      state.error = null
      state.loading = false
    })

    builder.addCase(paymentEventCancelledGA4.pending, (state) => {
      state.loading = true
    })
    builder.addCase(paymentEventCancelledGA4.rejected, (state, action) => {
      state.loading = false
      state.error = setApiError(action)
    })
    builder.addCase(paymentEventCancelledGA4.fulfilled, (state) => {
      state.error = null
      state.loading = false
    })

    builder.addCase(checkoutStartMpEvent.pending, (state) => {
      state.loading = true
    })
    builder.addCase(checkoutStartMpEvent.rejected, (state, action) => {
      state.loading = false
      state.error = setApiError(action)
    })
    builder.addCase(checkoutStartMpEvent.fulfilled, (state) => {
      state.error = null
      state.loading = false
    })

    builder.addCase(firstCampaignCreationStartMpEvent.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      firstCampaignCreationStartMpEvent.rejected,
      (state, action) => {
        state.loading = false
        state.error = setApiError(action)
      },
    )
    builder.addCase(firstCampaignCreationStartMpEvent.fulfilled, (state) => {
      state.error = null
      state.loading = false
    })

    builder.addCase(newCampaignCreationStartMpEvent.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      newCampaignCreationStartMpEvent.rejected,
      (state, action) => {
        state.loading = false
        state.error = setApiError(action)
      },
    )
    builder.addCase(newCampaignCreationStartMpEvent.fulfilled, (state) => {
      state.error = null
      state.loading = false
    })

    builder.addCase(genreAddedMpEvent.pending, (state) => {
      state.loading = true
    })
    builder.addCase(genreAddedMpEvent.rejected, (state, action) => {
      state.loading = false
      state.error = setApiError(action)
    })
    builder.addCase(genreAddedMpEvent.fulfilled, (state) => {
      state.error = null
      state.loading = false
    })

    builder.addCase(languageAddedMpEvent.pending, (state) => {
      state.loading = true
    })
    builder.addCase(languageAddedMpEvent.rejected, (state, action) => {
      state.loading = false
      state.error = setApiError(action)
    })
    builder.addCase(languageAddedMpEvent.fulfilled, (state) => {
      state.error = null
      state.loading = false
    })

    builder.addCase(trackAddedMpEvent.pending, (state) => {
      state.loading = true
    })
    builder.addCase(trackAddedMpEvent.rejected, (state, action) => {
      state.loading = false
      state.error = setApiError(action)
    })
    builder.addCase(trackAddedMpEvent.fulfilled, (state) => {
      state.error = null
      state.loading = false
    })

    builder.addCase(scheduleStartMpEvent.pending, (state) => {
      state.loading = true
    })
    builder.addCase(scheduleStartMpEvent.rejected, (state, action) => {
      state.loading = false
      state.error = setApiError(action)
    })
    builder.addCase(scheduleStartMpEvent.fulfilled, (state) => {
      state.error = null
      state.loading = false
    })
  },
})
