import styled from 'styled-components'

import { colors, variables } from 'src/Styled/variables'

export const FormContainer = styled.div`
  width: 100%;
  min-height: 274px;
`

export const Label = styled.p`
  width: 100%;
  color: ${colors.mainText};
  line-height: 150%;
`

export const FieldContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 16px 0;
  width: 100%;
`
interface Props {
  error?: boolean
}

export const InputField = styled.input<Props>`
  width: 100%;
  padding: 12px 16px;
  height: 48px !important;
  border: 1px solid ${colors.greyStroke};
  border-radius: 8px;
  outline: none;
  margin-bottom: ${(props: Props) => (props.error ? '0' : '37px')};
`

export const Placeholder = styled.span`
  position: absolute;
  top: -7px;
  left: 8px;
  padding: 0 4px;
  font-size: ${variables.fontSize10};
  color: ${colors.greyTextWhiteBg};
  background-color: ${colors.white};
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px 0 24px;
  & > div {
    width: 168px;
  }
`

export const MeetConditionsBtn = styled.a`
  color: ${colors.messegesYellow1} !important;
  border-bottom: 1px dashed ${colors.messegesYellow1};
  text-decoration: none;
  text-align: center;
  margin-left: 5px;
  margin-top: 17.5px;

  &:hover {
    color: ${colors.messegesYellow1};
  }
`

export const AlertWrapper = styled.div`
  display: flex;
  align-items: center;
`
