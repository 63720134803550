import { FC } from 'react'

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import trackCover from 'src/Assets/Png/track.png'
import { Track } from 'src/Redux/firstTrackApi'
import { colors, device, variables } from 'src/Styled/variables'
import { CampaignPlatformTypeEnum, CampaignTypeEnum } from 'src/Types'
import { IconComponent } from 'src/Components/IconComponent'
import { DottedBtn } from 'src/Components/DottedBtn/DottedBtn'
import { SmartImage } from 'src/Components/SmartImage'
import { TrackAudioPlayer } from 'src/Components/TrackAudioPlayer'
import { TrackCoverWrapper } from 'src/Components/TrackAudioPlayer/TrackCoverWrapper'
import { onPlayAddedTrack } from 'src/Helpers/TagManager/TrackEvent'

type TrackCardProps = Omit<Track, 'originalSound'> & {
  platform?: CampaignPlatformTypeEnum
  onChangeClick: () => void
}

const Container = styled.div`
  background-color: ${colors.white};
  padding: 20px 25.5px 24px;
  margin-bottom: 16px;
  border-radius: 4px;
  @media ${device.mobileMin} {
    padding: 20px 24px;
    margin-bottom: 8px;
  }
`
const TrackContainer = styled.div`
  display: grid;
  grid-row-gap: 20px;
`
const Platforms = styled.div`
  display: grid;
  grid-template-columns: 24px 1fr;
  grid-gap: 8px;
  align-items: center;
  text-align: left;
  font-weight: ${variables.fontWeight400};
  font-size: ${variables.fontSize18};
  line-height: 150%;
  color: ${colors.mainText};
  &img {
    align-self: center;
  }
`

const TrackBlock = styled.div`
  min-height: 48px;
  display: flex;
  flex-direction: column;

  @media ${device.mobileMin} {
    justify-content: space-between;
    flex-direction: row;
    gap: 16px;
    align-items: center;
  }
`

const TrackBlockLeft = styled.div`
  display: grid;
  grid-template-columns: 64px 1fr;
  grid-column-gap: 16px;
  & img {
    align-self: center;
  }
  @media ${device.mobileMin} {
    justify-items: center;
  }
`
const CenteredWrapper = styled.div`
  min-width: 82px;
  margin: 0 auto;
  margin-top: 25.5px;
  @media ${device.mobileMin} {
    margin: inherit;
    justify-items: center;
  }
`

const TrackImg = styled(SmartImage)`
  width: 64px;
  height: 64px;
  border-radius: 4px;
`
const StyledTrackCoverWrapper = styled(TrackCoverWrapper)`
  margin-right: 0;
`

const TrackText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  text-align: left;
  line-height: 21px;
  font-size: ${variables.fontSize14};
`
const TrackDuration = styled.div`
  font-weight: ${variables.fontWeight400};
  line-height: 21px;
  color: ${colors.greyTextWhiteBg};
`
const TrackDescription = styled.div`
  font-weight: ${variables.fontWeight400};
  line-height: 21px;
  color: ${colors.mainText};
`
const TrackTitle = styled.div`
  color: ${colors.mainText};
  font-weight: ${variables.fontWeight600};
  font-size: ${variables.fontSize14};
  line-height: 21px;
  @media ${device.mobileMin} {
    margin-bottom: 4px;
  }
`
export const TrackCard: FC<TrackCardProps> = ({
  platform,
  onChangeClick,
  trackPreviewUrl,
  trackName,
  trackDuration,
  trackCoverUrl,
  artistName,
}) => {
  const { t } = useTranslation()
  return (
    <Container>
      <TrackContainer>
        <Platforms>
          <IconComponent platform={platform} width={24} height={24} />
          {platform === CampaignPlatformTypeEnum.SPOTIFY
            ? t('createCampaignPage.spotifyCampaign')
            : t('createCampaignPage.tikTokCampaign')}
        </Platforms>
        <TrackBlock>
          <TrackBlockLeft>
            <TrackAudioPlayer
              render={({ loading, currentTrackUrl, isPlaying, onClick }) => (
                <StyledTrackCoverWrapper
                  show={Boolean(trackPreviewUrl)}
                  iconSize={'25px'}
                  loaderSize='50px'
                  isPlaying={isPlaying && currentTrackUrl === trackPreviewUrl}
                  loading={loading && currentTrackUrl === trackPreviewUrl}
                  onClick={(e) => {
                    onClick(e, trackPreviewUrl)
                    if (!isPlaying && platform) {
                      onPlayAddedTrack(CampaignTypeEnum.FIRST, null, platform)
                    }
                  }}
                >
                  <TrackImg
                    src={trackCoverUrl}
                    alt={trackName}
                    fallbackSrc={trackCover}
                    width='64'
                  />
                </StyledTrackCoverWrapper>
              )}
            />
            <TrackText>
              <TrackTitle>{trackName}</TrackTitle>
              <TrackDescription>{artistName}</TrackDescription>
              <TrackDuration>{trackDuration}</TrackDuration>
            </TrackText>
          </TrackBlockLeft>
          <CenteredWrapper>
            <DottedBtn
              content={t('createCampaignPage.changeTrack')}
              onClick={onChangeClick}
            />
          </CenteredWrapper>
        </TrackBlock>
      </TrackContainer>
    </Container>
  )
}
