import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

import { NewPlaylistToSet } from 'src/Types'

import { NameSpace } from '../types'
import { getSpotifyToken } from '../spotifyToken-process/selectors'
import { RootState } from '..'
import { getAllPlaylistsSpotifyIds } from '../playlists-process/selectors'
import { getCuratorSpotifyId } from '../auth-process/userSlice/selectors'

const getIsValidPlaylistName = (name: string): boolean =>
  Boolean(name.length) && !name.match(/^[ \t\u3164]+$/gm)

const FROM_INDEX = 9
const TO_INDEX = 22

export const cutPlaylistId = (searchValue: string): string => {
  const index = searchValue.indexOf('playlist')
  return searchValue.substring(index).slice(FROM_INDEX).substring(0, TO_INDEX)
}

interface FindPlaylistArgsType {
  playlistId: string
}

interface SpotifyError {
  message?: string
  response?: { status: number }
}

export const findPlaylist = createAsyncThunk<
  NewPlaylistToSet,
  FindPlaylistArgsType
>(
  `${NameSpace.SEARCH_PLAYLIST}/findPlaylist`,
  async ({ playlistId }, { rejectWithValue, getState }) => {
    const state = getState() as RootState
    const accessToken = getSpotifyToken(state)
    const allPlaylistsSpotifyIds = getAllPlaylistsSpotifyIds(state)
    const spotifyId = getCuratorSpotifyId(state)
    try {
      if (playlistId && !playlistId.match(/playlist/g)) {
        throw new Error('validation.incorrectLink')
      }
      if (!accessToken) {
        throw new Error('No access token')
      }
      const config = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
      const { data } = await axios.get<NewPlaylistToSet>(
        `https://api.spotify.com/v1/playlists/${cutPlaylistId(playlistId)}`,
        config,
      )

      const isPlaylistAlreadyAdded = allPlaylistsSpotifyIds?.includes(data?.id)
      if (isPlaylistAlreadyAdded) {
        throw new Error('validation.playlistAdded')
      }
      if (data.owner?.id !== spotifyId) {
        throw new Error('validation.notYourPlaylist')
      }
      if (!getIsValidPlaylistName(data.name)) {
        throw new Error('playlistsPage.emptyPlaylistNameError')
      }

      return data
    } catch (error) {
      const { message, response } = error as SpotifyError
      if (response?.status) {
        return rejectWithValue({
          error: response?.status,
          message: 'validation.incorrectLink',
        })
      }

      return rejectWithValue({
        error: 500,
        message: message || 'Something went wrong',
      })
    }
  },
)
