import { AxiosError } from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'

import { APP } from 'src/Configs/App'
import { api } from 'src/Services/api'
import {
  FeedFilterDirection,
  FeedFilterType,
  Meta,
  NewTrack,
  Review,
} from 'src/Types'

import { NameSpace } from '../types'

interface FeedTracksBody {
  filterDirection: FeedFilterDirection
  filterType: FeedFilterType
}

interface FeedTracksResponse {
  data: {
    reviews: Array<{
      campaign: NewTrack
    }>
    isLimitReached: boolean
  }
  meta: Meta
}

export const getFeedTracks = createAsyncThunk<
  FeedTracksResponse,
  FeedTracksBody
>(
  `${NameSpace.Feed}/getFeedTracks`,
  async (
    {
      filterDirection = FeedFilterDirection.ASC,
      filterType = FeedFilterType.date,
    },
    { rejectWithValue },
  ) => {
    const pageNumber = 1
    const pageSize = 10
    const sortBy = `${
      filterType === 'date' ? 'startDate' : filterType
    }.${filterDirection}`

    try {
      const { data } = await api.get<FeedTracksResponse>(
        `${APP.TIKTOK_SERVER}/curator/reviews/available`,
        { params: { pageNumber, pageSize, sortBy } },
      )
      return data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      return rejectWithValue({
        message: response?.data.message,
        status: response?.status,
      })
    }
  },
)

interface ReviewedFeedTracks {
  page: number
  isNewTracksPage?: boolean
}

interface ReviewedFeedTracksResponse {
  reviews: Array<Review>
  pageCount: number
  total: number
  currentPage: number
}
export const getReviewedFeedTracks = createAsyncThunk<
  ReviewedFeedTracksResponse,
  ReviewedFeedTracks
>(
  `${NameSpace.Feed}/getReviewedFeedTracks`,
  async ({ page, isNewTracksPage }, { rejectWithValue }) => {
    try {
      const isNew = isNewTracksPage ? '&is_new=1' : ''

      const { data } = await api.get<ReviewedFeedTracksResponse>(
        `${APP.SERVER}/curator/feed-reviewed/?page=${page}&elem_count=10${isNew}`,
      )
      return data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      return rejectWithValue({
        message: response?.data.message,
        status: response?.status,
      })
    }
  },
)

interface SendFeedReviewBody {
  campaign_id: string
  position: number

  comment: string
  recommendations: string | null
  value: { id: number; type: string }
}
export const sendFeedReview = createAsyncThunk<unknown, SendFeedReviewBody>(
  `${NameSpace.Feed}/sendFeedReview`,
  async (
    { campaign_id, comment, recommendations, value, position },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await api.post<unknown>(`${APP.SERVER}/review/feed`, {
        campaign_id,
        comment,
        value,
        recommendations,
        position,
      })
      return data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      return rejectWithValue({
        message: response?.data.message,
        status: response?.status,
      })
    }
  },
)

export const skipFeedReview = createAsyncThunk<unknown, string>(
  `${NameSpace.Feed}/skipFeedReview`,
  async (campaignId, { rejectWithValue }) => {
    try {
      const { data } = await api.post<unknown>(
        `${APP.SERVER}/review/feed-skip/${campaignId}`,
      )
      return data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      return rejectWithValue({
        message: response?.data.message,
        status: response?.status,
      })
    }
  },
)

export const reserveReview = createAsyncThunk<unknown, string>(
  `${NameSpace.Feed}/reserveReview`,
  async (campaignId, { rejectWithValue }) => {
    try {
      const { data } = await api.post<unknown>(
        `${APP.SERVER}/review/feed-reserve/${campaignId}`,
      )
      return data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      return rejectWithValue({
        message: response?.data.message,
        status: response?.status,
      })
    }
  },
)
