import React, { FC } from 'react'

import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import { PayPalTransactionProcessingModal } from 'src/Components/PayPalTransactionProcessingModal'

import { usePayPalFirstTransactionPaymentSpotify } from '../../../helpers/usePayPalFirstTransactionPaymentSpotify'

export const PayPalProcessingModalSpotify: FC = () => {
  const { search } = useCustomSearchParams()
  const autopay = search.get('autopay')

  usePayPalFirstTransactionPaymentSpotify()

  if (!autopay) {
    return null
  }

  return <PayPalTransactionProcessingModal />
}
