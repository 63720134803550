import { FC } from 'react'

import styled from 'styled-components'
import { Dayjs } from 'dayjs'

import { CampaignPlatformTypeEnum } from 'src/Types'
import { MAX_MOBILE_WIDTH } from 'src/Styled/variables'

import { ScheduleSettingsHeaderTrackDetails } from './ScheduleSettingsHeaderTrackDetails'
import { ScheduleSettingsHeaderTime } from './ScheduleSettingsHeaderTime'

const Container = styled.div`
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid ${(props) => props.theme.common.primaryBorder};
  background-color: ${(props) => props.theme.common.primaryBg};
  box-shadow: 0px 0px 32px 0px rgba(51, 51, 51, 0.08);
  @media screen and (min-width: ${MAX_MOBILE_WIDTH}) {
    flex-direction: row;
  }
`

export interface ScheduleSettingsHeaderProps {
  trackCover?: string
  trackName?: string
  todayEnable?: boolean
  artistName?: string
  startDate: Dayjs
  platform: CampaignPlatformTypeEnum
  onChangeDate: (date: Dayjs) => void
}

export const ScheduleSettingsHeader: FC<ScheduleSettingsHeaderProps> = (
  props,
) => {
  const {
    trackCover,
    platform,
    artistName,
    trackName,
    startDate,
    todayEnable,
    onChangeDate,
  } = props
  return (
    <Container>
      <ScheduleSettingsHeaderTrackDetails
        trackCover={trackCover}
        trackName={trackName}
        artistName={artistName}
        platform={platform}
      />
      <ScheduleSettingsHeaderTime
        onChangeDate={onChangeDate}
        todayEnable={todayEnable}
        startDate={startDate}
      />
    </Container>
  )
}
