import { FC } from 'react'

import { components, DropdownIndicatorProps } from 'react-select'

import { CloseBtn } from 'src/Components/CloseBtn/CloseBtn'
import { Desktop } from 'src/Components/Responsive'
import ButtonClose from 'src/Assets/Svg/buttonClose.svg?react'

export const DropdownIndicator: FC<DropdownIndicatorProps> = (props) => (
  <components.DropdownIndicator {...props}>
    <Desktop>
      <CloseBtn content={<ButtonClose width={10} height={10} />} />
    </Desktop>
  </components.DropdownIndicator>
)
