import styled from 'styled-components'

import { colors, device, variables } from '../../Styled/variables'

export const RadioLabel = styled.span`
  color: ${colors.mainText};
  font-size: ${variables.fontSize14};
  line-height: 1.5;
  font-weight: 400;
  @media ${device.mobileMin} {
    white-space: nowrap;
  }
`

export const RadioSubLabel = styled(RadioLabel)`
  color: ${colors.greyTextWhiteBg};
`
