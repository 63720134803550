import styled from 'styled-components'

import { device } from 'src/Styled/variables'

export const List = styled.div`
  display: flex;
  flex-direction: row;
  display: flex;
  flex-direction: row;
  align-items: center;

  justify-content: center;

  @media ${device.mobileMax} {
    flex-direction: column;
    gap: 4px;
  }
`
export const TitleWrapper = styled.div`
  margin-bottom: 24px;
`
