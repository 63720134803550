import { FC } from 'react'

import styled from 'styled-components'

import avatar from 'src/Assets/Png/avatar.png'

import { SmartImage } from '../SmartImage'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
`

const StyledImage = styled(SmartImage)<{
  width: number
  height: number
}>`
  border-radius: 50%;
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
`
export interface AvatarProps {
  src?: string
  width?: number
  height?: number
}
const DEFAULT_SIZE = 120
export const Avatar: FC<AvatarProps> = ({
  src,
  width = DEFAULT_SIZE,
  height = DEFAULT_SIZE,
}) => (
  <Container>
    <StyledImage
      width={width}
      height={height}
      src={src}
      alt='user avatar'
      fallbackSrc={avatar}
    />
  </Container>
)
