import { FC, ReactNode } from 'react'

import styled from 'styled-components'

import CrossIcon from 'src/Assets/Svg/common/fullCross.svg?react'
import CheckMarkIcon from 'src/Assets/Svg/pages-icons/CheckMark.svg?react'

type Props = {
  name: ReactNode
  selected?: boolean
  hasCross?: boolean
  onClick: () => void
}

const Container = styled.div`
  display: flex;
  padding: 9.5px 8px 9.5px 12px;
  align-items: center;
  width: fit-content;
  gap: 8px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.common.fourthBg};
`

const Text = styled.span`
  color: ${({ theme }) => theme.common.sixthText};
  font-family: 'Segoe UI';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`
export const PrimaryGenreOrLanguageItem: FC<Props> = ({
  name,
  selected,
  onClick,
  hasCross,
}) => (
  <Container translate='no' onClick={onClick}>
    <Text>{name}</Text>
    {hasCross && <CheckMarkIcon role='check-icon' />}
    {!hasCross && selected && (
      <CrossIcon role='cross-icon' width={16} height={16} />
    )}
  </Container>
)
