import styled from 'styled-components'

import { variables, colors } from 'src/Styled/variables'

export const Text = styled.div`
  font-size: ${variables.fontSize10};
  line-height: 15px;
  color: ${colors.mainText};
`

export const CardButtonWrapper = styled.div`
  min-width: 75%;
  max-width: 75%;
  display: grid;
  grid-template-columns: 138px 40px;
  grid-column-gap: 16px;
`

export const StyledBtn = styled.div`
  width: 208px;
  display: flex;
  align-items: center;
  gap: 8px;
`

export const StyledDetailsButton = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  grid-column-gap: 1px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  border: 1px solid ${colors.green};
  cursor: pointer;
  &:hover {
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  }
`
