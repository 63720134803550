import styled from 'styled-components'

import { device, variables } from 'src/Styled/variables'

export const TiktokDetailsHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  line-height: 150%;
  padding-left: 16px;
  padding-top: 24px;

  @media ${device.mobileMin} {
    flex-direction: row;
    align-items: center;
    padding-left: 0px;
    padding-top: 16px;
  }
`
export const TiktokDetailsTitle = styled.p`
  font-size: ${variables.fontSize20};
  font-weight: ${variables.fontWeight600};
  line-height: 30px;
`
