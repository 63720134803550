import React, { FC } from 'react'

import styled from 'styled-components'
import { Skeleton } from 'antd'
import { SkeletonNodeProps } from 'antd/es/skeleton/Node'

import { MAX_MOBILE_WIDTH } from 'src/Styled/variables'
import { CampaignStatusEnum } from 'src/Types'
import { CampaignPreModerationVideo } from 'src/Redux/campaignVideosApi/mapVideoToPreModerationStatus'

import { StatsBlockContainer } from '../StatsBlockContainer'
import { InfoBlock } from '../InfoBlock'
import { VideosList } from '../VideosList'

const ResultWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
  align-self: stretch;

  @media (max-width: ${MAX_MOBILE_WIDTH}) {
    gap: 48px;
  }
`

const StatsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
  align-self: stretch;
`

const StyledSkeleton = styled(({ className, ...rest }: SkeletonNodeProps) => (
  <Skeleton.Input {...rest} rootClassName={className} active />
))`
  &.ant-skeleton-block {
    border-radius: 16px;
  }
  &.ant-skeleton {
    flex-grow: 1 !important;
    @media (max-width: ${MAX_MOBILE_WIDTH}) {
      width: 100% !important;
    }
  }
  & > .ant-skeleton-input {
    width: 100% !important;

    height: 240px !important;
    border-radius: 16px !important;

    @media (max-width: ${MAX_MOBILE_WIDTH}) {
      width: 100% !important;
    }
  }
`

interface ResultSectionProps {
  loading: boolean
  viewsCount?: number
  commentsCount?: number
  campaignStatus?: CampaignStatusEnum
  likesCount?: number
  videosCount?: number
  videos?: Array<CampaignPreModerationVideo>
  startDate?: string
  onStartCampaignClick?: () => void
}

export const ResultSection: FC<ResultSectionProps> = ({
  loading,
  viewsCount,
  commentsCount,
  campaignStatus,
  likesCount,
  videosCount,
  videos,
  startDate,
  onStartCampaignClick,
}) => {
  if (loading) {
    return <StyledSkeleton />
  }

  if (!campaignStatus) {
    return null
  }

  return (
    <ResultWrapper>
      <StatsWrapper>
        <StatsBlockContainer
          viewsCount={viewsCount}
          commentsCount={commentsCount}
          likesCount={likesCount}
          videosCount={videosCount}
          campaignStatus={campaignStatus}
        />
        <InfoBlock
          startDate={startDate}
          campaignStatus={campaignStatus}
          videosCount={videosCount}
          onStartCampaignClick={onStartCampaignClick}
        />
      </StatsWrapper>

      <VideosList campaignStatus={campaignStatus} videos={videos} />
    </ResultWrapper>
  )
}
