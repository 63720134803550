import styled, { css } from 'styled-components'
import { PopupButton } from '@typeform/embed-react'

import { variables, colors } from 'src/Styled/variables'

interface StyledComponentsProps {
  collapse: boolean
}

export const SidebarLinkItem = styled.li`
  color: ${colors.greyStroke};
  height: 40px;
  transition: all 0.3s;
  cursor: pointer;
  align-items: center;
  background: transparent;
  border: none;
  display: flex;
  overflow: hidden;

  ${({ collapse }: { collapse: boolean }) =>
    collapse
      ? css`
          padding: 12px 20px;
        `
      : css`
          padding: 12px 16px;
        `};

  &:hover {
    background-color: ${colors.greyBg};
  }
`

export const StyledPopupButton = styled(PopupButton)`
  background-color: transparent;
  padding: 0;
  display: flex;
  cursor: pointer;
`

export const SidebarLinkText = styled.div<StyledComponentsProps>`
  position: relative;
  color: ${colors.greyTextWhiteBg};
  margin: 0;
  font-size: ${variables.fontSize14};
  line-height: 21px;
  display: flex;
  left: 10px;
  width: 160px;
  &:hover {
    color: ${colors.mainText};
  }
  opacity: ${({ collapse }) => (collapse ? '1' : '0')};
  transition: all 0.2s ease-out;
  overflow: hidden;
`

export const SidebarIconBlock = styled.div`
  margin-right: 6px;
  color: ${colors.greyTextWhiteBg};

  & a:visited {
    color: ${colors.greyTextWhiteBg};
  }

  & img {
    width: 16px;
    height: 16px;
  }
`
