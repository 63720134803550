import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { SmartImage } from 'src/Components/SmartImage'
import track from 'src/Assets/Png/track.png'
import TikTokIcon from 'src/Assets/Svg/tiktok.svg?react'
import { formattedNumber, getValueOrZero } from 'src/Constants/functions'
import { TiktokCampaignStatus } from 'src/Types/index'
import { useGetCampaignTiktokDetailsQuery } from 'src/Redux/campaignTiktokDetailsApi'

import { DetailsProgress } from './DetailsProgress'
import { DetailsStatus } from './DetailsStatus/DetailsStatus'

import {
  StyledDetailsCard,
  StyledIcon,
  ImgWrapper,
  IconWrapper,
  NamesWrapper,
  Name,
  Artist,
  BudgetWrapper,
  Row,
  Key,
  Value,
} from './styles'

const ENDED_CAMPAIGNS_STATUSES = [
  TiktokCampaignStatus.ENDED,
  TiktokCampaignStatus.FINISHED,
  TiktokCampaignStatus.STOPPED,
  TiktokCampaignStatus.STOPPED_BY_ADMIN,
  TiktokCampaignStatus.CANCELED_BY_ADMIN,
]
export const DetailsCard: FC = () => {
  const { campaignID } = useParams()
  const { data: campaignDetails } = useGetCampaignTiktokDetailsQuery(campaignID)
  const { t } = useTranslation()

  const isEnded =
    campaignDetails?.status &&
    ENDED_CAMPAIGNS_STATUSES.includes(campaignDetails?.status || '')

  const creditsValue =
    getValueOrZero(campaignDetails?.amount) -
    getValueOrZero(campaignDetails?.spent)

  const isCreditsVisible =
    creditsValue !== 0 && isEnded && !campaignDetails.hasNotCompletedEvents
  return (
    <StyledDetailsCard>
      <StyledIcon>
        <ImgWrapper>
          <SmartImage
            alt='trackCoverUrl'
            src={campaignDetails?.track?.coverUrl}
            fallbackSrc={track}
          />
        </ImgWrapper>
        <IconWrapper
          href={campaignDetails?.track?.url}
          target={'_blank'}
          rel='noopener noreferrer'
        >
          <TikTokIcon />
        </IconWrapper>
      </StyledIcon>

      <NamesWrapper>
        <Name>{campaignDetails?.track?.title}</Name>
        <Artist>{campaignDetails?.track?.authorName}</Artist>
      </NamesWrapper>

      <BudgetWrapper>
        <Row>
          <Key>{t('campaignResultsPage.budgetLabel')}:</Key>
          <Value>{formattedNumber(campaignDetails?.amount ?? 0)}</Value>
        </Row>

        {!isEnded && (
          <Row>
            <Key>{t('campaignResultsPage.reserved')}:</Key>

            <Value>
              {formattedNumber(getValueOrZero(campaignDetails?.reserved))}
            </Value>
          </Row>
        )}
        <Row>
          <Key>{t('campaignResultsPage.spent')}:</Key>
          <Value>
            {formattedNumber(getValueOrZero(campaignDetails?.spent))}
          </Value>
        </Row>

        {isCreditsVisible && (
          <Row>
            <Key>{t('campaignResultsPage.creditsLabel')}:</Key>

            <Value refund>{formattedNumber(creditsValue)}</Value>
          </Row>
        )}
      </BudgetWrapper>
      {isEnded ? <DetailsStatus /> : <DetailsProgress />}
    </StyledDetailsCard>
  )
}
